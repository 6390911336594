import { Alert, Button, Card, Col, InputNumber, message, Modal, Radio, Row, Segmented, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { AMOUNT_ROUNDING_SCALE, RUPEE_SYMBOL } from '../../../Constants';
import { INITIATE_CLEARANCE, INITIATE_CREDIT_CLEARANCE } from '../../../http/EndPoints';
import { postApiCall, ResponseHandler } from '../../../http/HttpClient';
import { PaymentClearanceRequest, PaymentClearanceResponse } from '../../../types/CreditPassbook';
import { Tender, TenderConfig, TenderType } from '../../../types/Tender';
import { roundTo2Decimal } from '../../../utilities/NumbersUtil';
import { PayuIntent } from '../upi/PayuIntent';
import { QRCodeGeneration } from '../QRCodeGeneration';
import { RendererType } from '../../../utilities/datumRenderer/type';
import { CollectionSource, CollectionSourceType } from '../../finance/CollectionSource';
import CollectionModalRow from '../../finance/collections/CollectionModalRow';
import { formatCurrency } from '../../../Utils';

export enum CollectionModalMode {
    NEW_COLLECTION = 'NEW_COLLECTION',
    PENDING_COLLECTION = 'PENDING_COLLECTION',
}

export interface ClearanceAmounts {
    defaultValue: number;
    totalOutstanding: number;
    overdue: number;
}

export interface CollectionClearanceModalProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    clearanceModalVisible: boolean;
    setClearanceModalVisible: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    mode: CollectionModalMode;
    clearanceAmounts: ClearanceAmounts;
    tenders: Array<Tender>;
    collectionSource: CollectionSourceType

    establishmentUuid?: string;
    orderUuid?: string;
    refreshPage?: () => void;
}

enum AmountType {
    TOTAL_OUTSTANDING = 'TOTAL_OUTSTANDING',
    OVER_DUE = 'OVER_DUE',
    CUSTOM = 'CUSTOM',
}

interface CreditClearanceRadioInput {
    type: AmountType;
    value: number;
}
interface CollectButtonValidity {
    enabled: boolean;
    displayMessage: string | undefined;
}

function CollectionClearanceModal(props: CollectionClearanceModalProps) {

    const [clearanceInProgress, setClearanceInProgress] = useState<boolean>(false);
    const [selectedTender, setSelectedTender] = useState<Tender>();
    const [selectedAmountType, setSelectedAmountType] = useState<AmountType>();
    const [selectedAmount, setSelectedAmount] = useState<number>(0);
    const [customAmountEntered, setCustomAmountEntered] = useState<number>(0);
    const [collectButtonValidity, setCollectButtonValidity] = useState<CollectButtonValidity>({
        enabled: false, displayMessage: 'Please select a valid amount',
    });
    const [paymentModalVisibility, setPaymentModalVisibility] = useState<boolean>(false);
    const [creditClearanceResponse, setCreditClearanceResponse] = useState<PaymentClearanceResponse>();
    const [qrCode, setQrCode] = useState<string>();
    const [qrCodeModalVisibility, setQrCodeModalVisibility] = useState<boolean>(false);

    const initializeUPIPayment = () => {
        setPaymentModalVisibility(true);
        props.setClearanceModalVisible(false);
    };

    const handleCollectionInitiation = (response: PaymentClearanceResponse) => {
        if (response.payment_deposit_allowed) {
            props.setLoading(true);
            initializeUPIPayment();
        } else {
            const tenderResponse = response?.payment_response.tender_response;
            if (tenderResponse.next_step == 'verify') {
                const qrCodeValue = tenderResponse.data.find(datum => datum.type === TenderType.QRCODE)?.value;
                if (qrCodeValue) {
                    setQrCode(qrCodeValue);
                    setQrCodeModalVisibility(true);
                } else {
                    message.error('Could not find the relevant data to proceed. Please contact the team');
                }
            } else {
                message.success(response?.payment_response.tender_response.message);
                if (props.refreshPage) {
                    props.refreshPage();
                }
            }
        }
    };

    const getInitiateClearanceEndPoint = (collectionSource: CollectionSourceType): string => {
        if (collectionSource === CollectionSourceType.CREDIT) {
            return INITIATE_CREDIT_CLEARANCE(props?.establishmentUuid!);
        } else if (collectionSource === CollectionSourceType.ORDER) {
            return INITIATE_CLEARANCE;
        }

        return '';
    };

    const getInitiateClearanceRequestBody = (collectionSource: CollectionSourceType) => {
        if (collectionSource === CollectionSourceType.CREDIT) {
            return {
                tender_type: selectedTender?.type,
                amount: selectedAmount ?? 0,
                data: selectedTender?.configs?.map(config => {
                    return { name: config.name, value: config.value };
                }),
            } as PaymentClearanceRequest;
        } else if (collectionSource === CollectionSourceType.ORDER) {
            return {
                tender_type: selectedTender?.type,
                amount: selectedAmount,
                source: CollectionSourceType.ORDER,
                touch_point: 'DASHBOARD',
                source_reference_id: props?.orderUuid,
                initiate_payment: true,
            };
        }
    };

    async function initiateClearance() {

        setClearanceInProgress(true);

        const responseHandler: ResponseHandler<PaymentClearanceResponse> = {
            onResponseSuccess(response: PaymentClearanceResponse): void {

                setCreditClearanceResponse(response);

                handleCollectionInitiation(response);

                setClearanceInProgress(false);
                props.setClearanceModalVisible(false);
            },
            onResponseFailed(): void {
                setClearanceInProgress(false);
            },
            onResponseError(): void {
                setClearanceInProgress(false);
            },
        };

        await postApiCall(getInitiateClearanceEndPoint(props.collectionSource), getInitiateClearanceRequestBody(props.collectionSource), responseHandler);
    }

    function setProcessingAmount(input: CreditClearanceRadioInput) {
        setSelectedAmountType(input.type);
        setSelectedAmount(input.value);
    }

    function setCustomAmountSelected(amount: number) {
        setSelectedAmount(amount);
        setCustomAmountEntered(amount);
    }

    const getRadioInput = (input: CreditClearanceRadioInput, editable: boolean, placeHolder: string) => {
        return (
            <Card
                className="collection-modal-radio-item"
                cover={
                    <div className="collection-modal-radio-content">
                        <Row gutter={[24, 24]}>
                            <Col xl={12} xs={15}>
                                <Radio
                                    value={input.type}
                                    onChange={event => event.target.checked && setProcessingAmount(input)}>
                                    {placeHolder}
                                </Radio>
                            </Col>
                            {editable ? (
                                <Col xl={12} xs={9}>
                                    <Row gutter={[24, 24]} justify="start">
                                        <Col xl={15} xs={20} className="margin-top-negative-4">
                                            <InputNumber
                                                value={customAmountEntered}
                                                controls={false}
                                                min={selectedTender?.minimum_amount}
                                                max={selectedTender?.maximum_amount}
                                                disabled={selectedAmountType != input.type}
                                                onChange={value => setCustomAmountSelected(value ?? selectedTender?.minimum_amount)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                <Col xl={12} xs={9}>
                                    <span>{`${RUPEE_SYMBOL} ${input.value.toFixed(AMOUNT_ROUNDING_SCALE)}`}</span>
                                </Col>
                            )}
                        </Row>
                    </div>
                }
            />
        );
    };

    function checkCollectButtonValidity() {
        if ((selectedAmount === 0 || !selectedAmountType) && props.mode != CollectionModalMode.PENDING_COLLECTION) {
            setCollectButtonValidity({
                enabled: false,
                displayMessage: 'Please select a valid amount',
            });
        } else if (selectedTender && selectedAmount < selectedTender.minimum_amount!!) {
            setCollectButtonValidity({
                enabled: false,
                displayMessage: `Please collect a minimum of ₹${selectedTender.minimum_amount!!} by ${selectedTender.name}`,
            });
        } else if (selectedTender && selectedAmount > selectedTender.maximum_amount!!) {
            setCollectButtonValidity({
                enabled: false,
                displayMessage: `Please collect upto ₹${selectedTender.maximum_amount!!} by ${selectedTender.name}`,
            });
        } else if (
            selectedTender &&
            selectedTender?.configs?.filter(config => config.value == undefined || config.value.length <= 0)?.length > 0
        ) {
            setCollectButtonValidity({
                enabled: false,
                displayMessage: 'Please provide all the necessary details required for processing collection',
            });
        } else {
            setCollectButtonValidity({ enabled: true, displayMessage: undefined });
        }
    }


    function onPaymentPayLater() {
        if (creditClearanceResponse) {
            message.success(creditClearanceResponse?.payment_response.tender_response.message);
        }
        if (props.refreshPage) {
            props.refreshPage();
        }
    }

    function onPaymentCompletion() {
        if (props.refreshPage) {
            props.refreshPage();
        }
    }

    const updateConfig = (config: TenderConfig, value: any) => {
        const updatedConfig = selectedTender?.configs.find(selectedConfig => selectedConfig.name === config.name);
        if (updatedConfig) {
            updatedConfig.value = value;
        }
        checkCollectButtonValidity();
        setSelectedTender(selectedTender);
    };

    function isCaptureBasedConfigPresent(): boolean | undefined {
        return props.tenders?.find(tender => tender.type === selectedTender?.type)?.configs?.some(config => config.function === 'CAPTURE');
    }

    useEffect(() => {
        if (props.tenders && !selectedTender) {
            setSelectedTender(props.tenders.at(0));
        }
    }, [props.tenders]);

    useEffect(() => {
        checkCollectButtonValidity();
    }, [selectedAmount, selectedTender, props.clearanceModalVisible]);

    useEffect(() => {
        if (props.clearanceModalVisible) {
            setCustomAmountSelected(props.clearanceAmounts.defaultValue);
        }
    }, [props.clearanceModalVisible]);
    return (
        <>
            <Modal
                className="transaction-action-modal"
                open={props.clearanceModalVisible}
                maskClosable={false}
                destroyOnClose
                title={CollectionSource[props.collectionSource].clearanceModalTitle}
                onCancel={() => {
                    props.setClearanceModalVisible(false);
                    setSelectedAmount(0);
                    setCustomAmountEntered(0);
                    setSelectedAmountType(undefined);
                }}
                footer={
                    <div className="flex-center">
                        <Button
                            type="primary"
                            onClick={() => {
                                initiateClearance();
                            }}
                            disabled={!collectButtonValidity.enabled}
                            loading={clearanceInProgress}
                        >
                            Collect
                        </Button>
                    </div>
                }>
                <Spin spinning={clearanceInProgress} tip="Loading..." size="small" className="height-100-percen">
                    <Title level={5} className="margin-top-10px">
                        Payment Mode
                    </Title>
                    {
                        props.tenders && <Segmented
                            block
                            options={props.tenders.map(item => {
                                return { label: item.name, value: item.type };
                            })}
                            defaultValue={selectedTender?.type}
                            onChange={value => {
                                const chosenTender = props.tenders.find(tender => tender.type === value);
                                setSelectedTender(chosenTender);
                            }}
                        />
                    }


                    <Row gutter={[24, 24]} className="margin-top-20px">
                        <Col xl={12} xs={12}>
                            <Title level={5}>Amount to Collect</Title>
                        </Col>
                        {props.mode === CollectionModalMode.PENDING_COLLECTION && (
                            <Col xl={12} xs={12}>
                                <Title level={5}>
                                    {formatCurrency(selectedAmount)}
                                </Title>
                            </Col>
                        )}
                    </Row>

                    {props.mode === CollectionModalMode.NEW_COLLECTION && (
                        <Card
                            bodyStyle={{ padding: '0px' }}
                            className="collection-modal-radio-card"
                            cover={
                                <Radio.Group>
                                    {getRadioInput(
                                        {
                                            type: AmountType.TOTAL_OUTSTANDING,
                                            value: roundTo2Decimal(props.clearanceAmounts.totalOutstanding),
                                        },
                                        false,
                                        'Total Outstanding',
                                    )}

                                    {props.clearanceAmounts.overdue > 0 &&
                                        getRadioInput(
                                            {
                                                type: AmountType.OVER_DUE,
                                                value: roundTo2Decimal(props.clearanceAmounts.overdue),
                                            },
                                            false,
                                            'Over Due',
                                        )}

                                    {getRadioInput({ type: AmountType.CUSTOM, value: customAmountEntered }, true, 'Custom')}
                                </Radio.Group>
                            }
                        />
                    )}

                    {isCaptureBasedConfigPresent() && (
                        <Card
                            bodyStyle={{ padding: '0px' }}
                            className="collection-modal-tender-config-card padding-top-10px"
                            cover={
                                props.tenders
                                    .find(tender => tender.type === selectedTender?.type)
                                    ?.configs
                                    .filter(eachItem => eachItem.function === 'CAPTURE')
                                    .map((eachItem, index) => {
                                        return (
                                            <CollectionModalRow
                                                key={index}
                                                config={eachItem}
                                                displayName={eachItem.display_name}
                                                name={eachItem.name}
                                                value={eachItem.value}
                                                type={eachItem.type}
                                                editable
                                                updateConfig={updateConfig}
                                                rendererType={RendererType.component}
                                            />
                                        );
                                    })
                            }
                        />
                    )}
                    {!collectButtonValidity.enabled && collectButtonValidity.displayMessage && (
                        <Alert
                            type="error"
                            showIcon
                            message={collectButtonValidity.displayMessage}
                            className="collection-modal-alert"
                        />
                    )}
                </Spin>
            </Modal>
            <PayuIntent
                title='Would it be possible for you to deposit the money now?'
                setLoading={props.setLoading}
                collectionUuids={[creditClearanceResponse?.uuid!]}
                totalDueAmount={selectedAmount}
                modalVisibility={paymentModalVisibility}
                setModalVisibility={setPaymentModalVisibility}
                onPayLater={onPaymentPayLater}
                onPaymentCompletion={onPaymentCompletion}
            />
            <QRCodeGeneration
                qrCode={qrCode}
                setQrCode={setQrCode}
                selectedAmount={selectedAmount}
                setSelectedAmount={setSelectedAmount}
                setCustomAmountEntered={setCustomAmountEntered}
                onPaymentCompletion={onPaymentCompletion}
                qrCodeModalVisibility={qrCodeModalVisibility}
                paymentClearanceResponse={creditClearanceResponse}
                setQrCodeModalVisibility={setQrCodeModalVisibility}
            />
        </>
    );
}

export default CollectionClearanceModal;