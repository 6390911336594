export class EstablishmentSaveMode {
    static readonly CREATE = new EstablishmentSaveMode('CREATE', 'Save', true, true);

    static readonly UPDATE = new EstablishmentSaveMode('UPDATE', 'Update', false, true);

    static readonly SHOW = new EstablishmentSaveMode('SHOW', null, false, false);

    private constructor(
        public readonly mode: string,
        public readonly saveButtonText: string | null,
        public readonly showResetButton: boolean,
        public readonly showSaveButton: boolean,
    ) {}
}
