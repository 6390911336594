import { EstablishmentSaveMode } from '../../../types/EstablishmentSaveMode';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { Path } from '../../router/Path';
import { PathConfig } from '../PathConfigService';
import {
    EstablishmentDetailsConfig,
    EstablishmentsConfig,
    OrderDetailsConfig,
    OrderListConfig,
    PurchaseOrderDetailsConfig,
    UserListingConfig,
} from '../types/ConfigTypes';

const VendorConfigs: PathConfig[] = [
    {
        path: Path.HOME,
        accessible: true,
    },
    {
        path: Path.LOGOUT,
        accessible: true,
    },
    {
        path: Path.ORDER_LIST,
        accessible: true,
        config: {
            hubOrders: {
                show: false,
                showOrders: false,
                showPurchaseOrders: false,
            },
            outletOrders: {
                show: true,
            },
            sellerHubOrders: {
                show: false,
                showOrders: false,
                showPurchaseOrders: false,
            },
        } as OrderListConfig,
    },
    {
        path: Path.HUB_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.OUTLET_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.SELLER_HUB_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.ORDER_DETAILS,
        accessible: true,
        config: {
            stateChangeConfig: {
                allowedStates: [],
            },
            allowToViewSellerOrders: false,
            allowEditOrderDetails: false,
        } as OrderDetailsConfig,
    },
    // Purchase Orders
    {
        path: Path.PURCHASE_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.HUB_PURCHASE_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.SELLER_HUB_PURCHASE_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.PURCHASE_ORDER_DETAILS,
        accessible: false,
        config: {
            show: false,
            stateChangeConfig: {
                allowedStates: [],
            },
            paymentConfig: {
                allowMakePayment: false,
            },
        } as PurchaseOrderDetailsConfig,
    },
    {
        path: Path.ESTABLISHMENTS,
        accessible: true,
        config: {
            shouldShowHubs: false,
            shouldShowOutlets: true,
            shouldShowSellerHubs: false,
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.HUB_ESTABLISHMENT_LIST,
        accessible: false,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.OUTLET_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.SELLER_HUB_ESTABLISHMENT_LIST,
        accessible: false,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.ESTABLISHMENT_DETAILS,
        accessible: true,
        config: {
            shouldShowInfo: true,
            products: {
                sellerHub: {
                    show: false,
                    allowProductCreation: false,
                    allowProductListing: false,
                },
                hub: {
                    show: false,
                    allowProductListing: false,
                },
            },
            users: {
                show: true,
                allowCreation: false,
            },
            showReceivables: false,
            infoConfig: {
                showConfigLayout: false,
                showBrandsDiscountList: false,
                allowBrandDiscountsUpdate: false,
                saveMode: EstablishmentSaveMode.SHOW,
            },
        } as EstablishmentDetailsConfig,
    },
    {
        path: Path.USER_LISTING,
        accessible: false,
        config: {
            primaryEstablishmentType: EstablishmentType.OUTLET,
            associatedEstablishmentTypes: [EstablishmentType.OUTLET],

        } as UserListingConfig,
    },
    {
        path: Path.USER_PROFILE,
        accessible: true,
    },
];

export default VendorConfigs;
