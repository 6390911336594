
export enum HubProductListingType {
    EXISTING_ON_TOP = 'EXISTING_ON_TOP',
    NEW_ON_TOP = 'NEW_ON_TOP',
    EXISTING = 'EXISTING',
}

export const HubProductListing = {
    EXISTING_ON_TOP: {
        key: HubProductListingType.EXISTING_ON_TOP,
        display: 'Existing on top',
    },
    NEW_ON_TOP: {
        key: HubProductListingType.NEW_ON_TOP,
        display: 'New on top',
    },
};