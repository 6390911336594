/* eslint react/prop-types: 0 */

import { Col, Row, Divider } from 'antd';
import * as React from 'react';

interface FieldLayoutProps {
    children?: any,
    halveSecondSection?: boolean,
    show?: boolean,
    startDivider?: boolean,
    styleClass?: string,
    endDivider?: boolean,
    lastElement?: boolean,
}

function FieldLayout(props: FieldLayoutProps) {
    const { 
        children,
        halveSecondSection,
        show,
        startDivider,
        styleClass,
        endDivider,
        lastElement,
    } = props;
      
    const childrenSize = children.length;
    const childrenPerPartition = childrenSize / 2;
    const lg1: number = 8 / childrenPerPartition;
    let lg2: number = 16 / childrenPerPartition;
    if (halveSecondSection) {
        lg2 /= 2;
    }

    {
        if (show == undefined || show) {
            return (
                <>
                    {startDivider && <Divider />}
                    <Row
                        className={styleClass}
                        gutter={[24, 24]}
                        style={{
                            marginLeft: '0 !important',
                            marginRight: '0 !important',
                            marginTop: 10,
                        }}>
                        {props.children.map((child, index) => {
                            if (index < childrenPerPartition) {
                                return (
                                    <Col key={index} lg={lg1} md={lg1} sm={lg1} xs={lg1}>
                                        {child}
                                    </Col>
                                );
                            }
                            return (
                                <Col key={index} lg={lg2} md={lg2} sm={lg2} xs={lg2}>
                                    {child}
                                </Col>
                            );
                        })}
                    </Row>
                    {endDivider && <Divider style={{ margin: '0 0 20px 0' }} />}
                    {!endDivider && !lastElement && <div style={{ paddingTop: 5 }} />}
                </>
            );
        }
        return <></>;
    }
}

export default FieldLayout;
