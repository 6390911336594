import { Button, Col, Drawer, Image, InputNumber, Row, Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { CartonRenderer } from '..';
import { layout, style } from '../../../../../../styles/css/style';
import { ProductAggregate, SellerOrderStates } from '../../../../../../types/SellerOrder';
import { addKeyToDataList } from '../../../../../../Utils';
import { MeraaiTable } from '../../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../../common/templates/meraaiTable/types';
import { Currency, ProductNameInfo, Quantity, TotalSummary } from '../../hubsAndOutlets/utils';
import { SellerHubAggregateOrderDrawerProps } from './types';

export const SellerHubAggregateOrderDrawer = (props: SellerHubAggregateOrderDrawerProps) => {

    const changeAvailableQuantityForAnItem = (product: ProductAggregate, availableQuantity: number) => {
        if (availableQuantity >= 0 && availableQuantity <= product.quantity) {
            product.available_quantity = availableQuantity;
        } else if (!availableQuantity) {
            product.available_quantity = 0;
        }
        props.setSelectedOrder(_.cloneDeep(props.selectedOrder));

    };

    const orderItemsTotal = {
        data: [{
            label: <label className='font-semibold'>Subtotal</label>,
            value: <Currency styleClass='pl-4' value={props.computeTotalAmount()!} />,
        },
        {
            label: <label className='font-semibold'>No of Items</label>,
            value: <Quantity styleClass='pl-4' quantity={props.selectedOrder?.aggregate.products.length} />,
        }],
        divider: true,
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image_url',
            rowVisibility: [Device.DESKTOP],
            render: image => <Image width={30} height={30} src={image} />,
        },
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            width: 200,
            render: (__, record: ProductAggregate) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'Ordered QTY',
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP],
            render: quantity => <Quantity quantity={quantity} />,
        },
        {
            title: 'Available QTY',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (__, record: ProductAggregate) => {
                return (
                    props.confirmationPreviewDrawerVisibility ? <Quantity quantity={record.available_quantity || 0} />
                        : <InputNumber
                            key={record.product_id}
                            value={record.available_quantity || 0}
                            controls={false}
                            type="number"
                            min={0}
                            max={record.quantity}
                            placeholder="Item count"
                            onBlur={event => {
                                changeAvailableQuantityForAnItem(record, parseInt(event.target.value));
                            }}
                        />
                );
            },
        },
        {
            title: 'Carton(s)',
            width: 150,
            rowVisibility: [Device.DESKTOP],
            render: (__, aggregate: ProductAggregate) => <CartonRenderer quantity={aggregate.available_quantity || 0} orderQuantityMultiple={aggregate.order_quantity_multiple} />,
        },
        {
            title: 'Total Price',
            rowVisibility: [Device.DESKTOP],
            render: (__, record: ProductAggregate) => <Currency value={record.available_quantity ? record.available_quantity * record.price_per_unit : 0} />,
        },
    ];

    return (
        <>
            {/* Review order item quantity */}
            <Drawer
                className='meraai-drawer'
                open={props.sellerOrderDrawerVisibility}
                title={
                    <>
                        <h3>Generate Purchase Order</h3>
                        <span className='text-sm'>Review the items and adjust the quantity that you can fulfil.</span>
                    </>
                }
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    props.setSellerOrderItemDrawerVisibility(false);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        props.setSellerOrderItemDrawerVisibility(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        if (props.selectedOrder.aggregate.products.some(product => product.available_quantity !== product.quantity)) {
                                            props.setSellerOrderItemDrawerVisibility(false);
                                            props.setConfirmationPreviewDrawerVisibility(true);
                                        } else {
                                            props.handleSellerHubOrderApproval(SellerOrderStates.ACCEPTED);
                                        }
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Spin spinning={props.loading} size='default'>
                    <MeraaiTable
                        columns={columns}
                        dataSource={addKeyToDataList(props.selectedOrder?.aggregate.products!)}
                        pagination={false}
                        size='small'
                    />
                    <TotalSummary {...orderItemsTotal} />
                </Spin>
            </Drawer>

            {/* Preview shortages in order item quantity */}
            <Drawer
                className='meraai-drawer'
                open={props.confirmationPreviewDrawerVisibility}
                title={
                    <>
                        <h3>Shortages</h3>
                        <span className='text-sm'>These are all the items which cannot be fulfilled completely. Click on <b>Proceed</b> to generate a purchase order and <b>X</b> if the items have to be re-reviewed.</span>
                    </>
                }
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    if (!props.loading) {
                        props.setConfirmationPreviewDrawerVisibility(false);
                        props.setSellerOrderItemDrawerVisibility(true);
                    }
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[24, 24]} className={`${layout.flex.end}`}>
                            <Col className={`w-full xs:w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        props.handleSellerHubOrderApproval(SellerOrderStates.ACCEPTED);
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Spin spinning={props.loading} size='default'>
                    <MeraaiTable
                        columns={columns}
                        dataSource={addKeyToDataList(props.selectedOrder?.aggregate?.products.filter(product => product.available_quantity !== product.quantity))}
                        pagination={false}
                        size='small'
                    />
                    <TotalSummary {...orderItemsTotal} />
                </Spin>
            </Drawer>

            {/* Cancel seller hub aggregate order */}
            <Drawer
                className='meraai-drawer'
                open={props.rejectionDrawerVisibility}
                title={
                    <>
                        <h2>Order Rejection</h2>
                    </>
                }
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    props.setRejectionDrawerVisibility(false);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={'w-1/2 sm:w-1/3 lg:w-1/4'}>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        props.setRejectionDrawerVisibility(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        props.handleSellerHubOrderApproval(SellerOrderStates.REJECTED);
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Row className={`h-full w-full ${layout.flex.center}`}>
                    <Col className='w-full'>
                        <Row className='p-6'>
                            <Col className={`w-full  ${layout.flex.center}`}>
                                <label className='text-lg font-semibold'>Are you sure you want to reject the order?</label>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Drawer>
        </>
    );
};