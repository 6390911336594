import { Field, UpdateProperties, UpdateRequestGroupingKey } from '../../../../../types/Field';

export class BankAccountProperties {
    accountNumber?: Field<string>;

    accountHolderName?: Field<string>;

    ifsc?: Field<string>;

    constructor(bankAccountProperties: BankAccountProperties) {
        this.accountNumber = new Field(bankAccountProperties.accountNumber);
        this.accountHolderName = new Field(bankAccountProperties.accountHolderName);
        this.ifsc = new Field(bankAccountProperties.ifsc);
    }

    public setValues?(establishment: EstablishmentResponse) {
        const accountDetails = establishment.metadata?.account_details;

        this.accountNumber?.setInitialValue?.(
            accountDetails?.account_number,
            new UpdateProperties('Account number', 'account_number', UpdateRequestGroupingKey.BANK_ACCOUNT_DETAILS),
        );
        this.accountHolderName?.setInitialValue?.(
            accountDetails?.account_holder_name,
            new UpdateProperties(
                'Account Holder Name',
                'account_holder_name',
                UpdateRequestGroupingKey.BANK_ACCOUNT_DETAILS,
            ),
        );
        this.ifsc?.setInitialValue?.(
            accountDetails?.ifsc_code,
            new UpdateProperties('IFSC Code', 'ifsc_code', UpdateRequestGroupingKey.BANK_ACCOUNT_DETAILS),
        );
    }
}
