import { BaseType } from '../type';


export enum Type {
    DATE = 'DATE',
    FILE_URL = 'FILE_URL',
    IMAGE_URL = 'IMAGE_URL',
    REMARK = 'REMARK',
    DEFAULT = 'DEFAULT',
    STRING = 'STRING',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
    OPTIONS = 'OPTIONS',
    PRODUCT = 'PRODUCT',
}

export interface ValueComponentProps {
    value: string,
    record: any,
    onBlur?: (value: any, record: any) => void,
    onChange?: (value: any, record: any) => void,
    setBlur?: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    rulePassed?: boolean,
    placeholder?: string,
}

export interface DatumConfig {
    type: Type;
    valueRenderer?: (value: string, ...args: any[]) => React.ReactNode;

    valueComponent?: (valueComponentType, config?: BaseType) => React.ReactElement;

    valueConverter?: (val: any) => number | string;
}