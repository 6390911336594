/* eslint @typescript-eslint/no-unused-vars: 0 */

import * as React from 'react';
import { FinanceLayerConfig } from '../config/types/ConfigTypes';
import ConfigContext from '../config/ConfigContext';
import { MeraaiTabs } from '../common/tabs/MeraaiTabs';
import Receivables from './receivables/Receivables';
import { OrderExporter } from './orders/OrderExporter';
import PageTopSection from '../common/PageTopSection';
import { FinancePurchaseOrder } from './purchase_orders/FinancePurchaseOrder';
import { Deposits } from './deposits/Deposits';
import Collections from './collections/Collections';

const breadcrumb = [{ name: 'Finance' }, { name: 'List' }];

function FinanceLayer() {
    const config = React.useContext(ConfigContext) as FinanceLayerConfig;

    return (
        <>
            <PageTopSection firstElement={false} menuList={breadcrumb} pageTitle="Finance" />
            <MeraaiTabs
                tabs={[
                    {
                        key: 'COLLECTIONS',
                        name: 'Collections',
                        accessible: config?.collections?.show,
                        component: active => <Collections active={active} />,
                    },
                    {
                        key: 'RECEIVABLES',
                        name: 'Credit Receivables',
                        accessible: config?.receivables?.show,
                        component: active => <Receivables active={active} />,
                    },
                    {
                        key: 'DEPOSITS',
                        name: 'Deposits',
                        accessible: config?.deposits?.show,
                        component: active => <Deposits active={active} />,
                    },
                    {
                        key: 'ORDER',
                        name: 'Orders',
                        accessible: config?.exportOrders,
                        component: active => <OrderExporter active={active} />,
                    },
                    {
                        key: 'PURCHASE_ORDER',
                        name: 'Purchase Order',
                        accessible: config?.showPurchaseOrders,
                        component: active => <FinancePurchaseOrder active={active} />,

                    },
                ]}
            />
        </>
    );
}

export default FinanceLayer;
