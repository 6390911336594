import React from 'react';
import { Button, Col, Row, Select } from 'antd';
import { layout, style } from '../../../../../../styles/css/style';
import { addKeyToDataList, isArrayEmpty } from '../../../../../../Utils';
import { SearchInput } from '../../../../../common/SearchInput';
import { DataNotFound } from '../../../../../common/templates/dataNotFound';
import { ListingPage } from '../../../../../common/templates/listingPage';
import { MeraaiTable } from '../../../../../common/templates/meraaiTable';
import { InitiateReturnOrderDrawer } from '../initiateReturns';
import { ProductInvoiceList } from '../productInvoicesDrawer';
import { usePurchasedProductList } from './hooks/usePurchasedProductList';
import { PurchasedProductListProps } from './types';
import { OpenNotification } from '../../../../../common/notifications/Notifications';

export const PurchasedProductList = (props: PurchasedProductListProps) => {


    const {

        loading,
        columns,
        purchasedProductResponse: dataSource,
        searhProductName,
        setSearchProductName,
        setFetchPurchasedProductList,
        brandId,
        setBrandId,
        purchasedBrandResponse,
        selectedPurchaseProduct,
        setSelectedPurchaseProduct,
        savedReturnOrders,
        initiateReturnDrawerVisibility,
        setInitiateReturnDrawerVisibility,
        setInitialValue,
        pageNo,
        size,
        setPageNo,
        setSize,

    } = usePurchasedProductList({ ...props });

    const selectedItemTotalCount = savedReturnOrders?.selectedItemsCount ?? 0;


    const TotalItemCountLabel = () => {
        let displayText = '';
        if (selectedItemTotalCount > 1) {
            displayText = 'Total no of items: ';
        } else {
            displayText = 'Total no of item: ';
        }
        return <label className={'font-medium w-max px-2 py-0.5 text-meraai-orange bg-meraai-lightorange'}>{displayText}<span className='font-semibold underline'>{selectedItemTotalCount}</span></label>;
    };


    return (
        <>
            <ListingPage
                hideBreadcrumb
                topRowStyleClass='!p-0'
                bottomRowStyleClass='!p-0 !pt-5'
                header={
                    <Row gutter={[12, 12]}>

                        <Col className='w-full sm:w-1/2 xl:w-1/3 2xl:w-1/4'>
                            <SearchInput
                                value={searhProductName}
                                setSearchText={setSearchProductName}
                                searchPlaceholder={'Search product name'}
                                searchButtonOnClick={() => {
                                    setPageNo(0);
                                    setFetchPurchasedProductList(true);
                                }}
                            />
                        </Col>

                        <Col className='w-full xs:w-1/2 sm:w-1/4 xl:w-1/5 2xl:w-1/6'>
                            <Select
                                showArrow
                                allowClear
                                placeholder='Select brand'
                                className='w-full'
                                onChange={(value) => {
                                    if (value !== undefined) {
                                        setBrandId(value);
                                    } else {
                                        setBrandId(undefined);
                                        setFetchPurchasedProductList(true);
                                    }
                                }}
                            >
                                {
                                    purchasedBrandResponse?.data.map((datum, index) => <Select.Option key={index} value={datum.id}>
                                        {datum.name}
                                    </Select.Option>)
                                }

                            </Select>
                        </Col>

                        <Col className='w-full xs:w-1/2 sm:w-1/4 xl:w-1/5 2xl:w-1/6'>
                            <Button
                                className={`${style.meraaiOrangeButton}`}
                                disabled={!brandId}
                                onClick={() => {
                                    setPageNo(0);
                                    setFetchPurchasedProductList(true);
                                }}
                            >
                                Get product list
                            </Button>
                        </Col>

                    </Row>
                }

                body={

                    isArrayEmpty(dataSource?.purchases) ?

                        <DataNotFound loading={loading} title='No purchase products found' />

                        :
                        <Row>
                            <Col className='w-full'>
                                <MeraaiTable
                                    loading={loading}
                                    columns={columns}
                                    dataSource={addKeyToDataList(dataSource?.purchases!)}
                                    pagination={{
                                        current: pageNo + 1,
                                        pageSize: size,
                                        total: dataSource?.page_count! * size,
                                        onChange: (page, pageSize) => {
                                            setPageNo(page - 1);
                                            setSize(pageSize);
                                            setFetchPurchasedProductList(true);
                                        },
                                    }}
                                />
                            </Col>
                            <Col className='w-full py-5'>
                                <Row gutter={[12, 12]} className={`w-full ${layout.flex.end}`}>
                                    <Col className={`w-full xs:w-1/3 md:w-1/4 xl:w-1/6 ${layout.flex.center}`}>
                                        <TotalItemCountLabel />
                                    </Col>
                                    <Col className='w-full xs:w-1/3 md:w-1/4 xl:w-1/6'>
                                        <Button
                                            className={`${style.meraaiOrangeButton}`}
                                            disabled={!(savedReturnOrders?.selectedItemsCount > 0)}
                                            onClick={() => {
                                                setInitiateReturnDrawerVisibility(true);
                                            }}
                                        >
                                            Place return
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                }
            />

            {/* Product invoice list drawer */}
            <ProductInvoiceList
                title={
                    <>
                        <h3>{selectedPurchaseProduct?.name}</h3>

                        <span className='text-sm'>
                            {`Please enter the quantities for each invoice so that the total equals ${selectedPurchaseProduct?.returnQuantity} Units.`}
                        </span>
                    </>
                }
                open={selectedPurchaseProduct !== undefined}
                savedPurchaseProduct={savedReturnOrders?.selectedProducts.find(products => products.id === selectedPurchaseProduct?.product_id)}
                establishmentUuid={props.establishmentUuid}
                selectedPurchaseProduct={{
                    id: selectedPurchaseProduct?.product_id ?? 0,
                    returnQuantity: selectedPurchaseProduct?.returnQuantity ?? 0,
                    name: selectedPurchaseProduct?.name ?? '',
                    brand_name: selectedPurchaseProduct?.brand_name ?? '',
                }}
                onClose={() => {
                    setSelectedPurchaseProduct(undefined);
                    setInitialValue(dataSource!);
                }}
                onSuccess={() => {
                    setSelectedPurchaseProduct(undefined);
                }}
                onFailureStoreIntoLocal={() => {
                    OpenNotification(
                        'Oops!!',   //Title


                        <label>Please enter the quantities for each invoice so that the total equals <span className='font-semibold underline'>{selectedPurchaseProduct?.returnQuantity}</span> Units
                        </label>,   // Message

                        'warning',   // Type
                    );
                }}
            />

            <InitiateReturnOrderDrawer
                open={initiateReturnDrawerVisibility}
                establishmentUuid={props.establishmentUuid}
                savedReturnOrders={savedReturnOrders}
                onClose={() => {
                    setInitiateReturnDrawerVisibility(false);
                    setInitialValue(dataSource!);
                }}
                onSuccess={() => {
                    setFetchPurchasedProductList(true);
                    setInitiateReturnDrawerVisibility(false);
                }}
            />
        </>
    );
};