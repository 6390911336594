import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

// This hook is used to dispatch actions to update the value in the redux store.
// How to use this:
// import { useAppDispatch } from ./hooks
// const dispatch = useAppDispatch()
// dispatch(*Any action that you have created in the reducers*)
export const useAppDispatch = () => useDispatch<AppDispatch>();

// This is hook is used to read value from the redux store. The data gets updated as and when the data is updated in the store.
// How to use this:
// import { useAppSelector } from ./hooks
// const value = useAppSelector((state) => state.<Slice name>.<Parameter name>)
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
