import React from 'react';
import PageTopSection from '../common/PageTopSection';
import { MeraaiTabs } from '../common/tabs/MeraaiTabs';
import ConfigContext from '../config/ConfigContext';
import { InventoryConfig } from '../config/types/ConfigTypes';
import { InventoryProductList } from './InventoryProductList';

const breadcrumb = [{ name: 'Inventory' }, { name: 'List' }];

export const InventoryLayer = () => {
    const config = React.useContext(ConfigContext) as InventoryConfig;
    return (
        <>
            <PageTopSection firstElement={false} menuList={breadcrumb} pageTitle="Inventory" />
            <MeraaiTabs
                tabs={[
                    {
                        key: 'products',
                        name: 'Products',
                        accessible: config?.show && config.products.showListing, 
                        component: () => <InventoryProductList config={config} />,
                    },
                ]}
            />
        </>
    );
};