/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { RoleDataMap } from '../../../types/RoleData';
import { PathConfigData } from '../../config/PathConfigService';
import { Path } from '../../router/Path';

export const EstablishmentLayer = () => {
    const navigate = useNavigate();

    const currentRole = useAppSelector(state => state.login.role);
    const pageConfigs = new PathConfigData(currentRole);

    useEffect(() => {
        navigate(
            pageConfigs.isPathAccessible(Path.HUB_ESTABLISHMENT_LIST) ? Path.HUB_ESTABLISHMENT_LIST :
                pageConfigs.isPathAccessible(Path.OUTLET_ESTABLISHMENT_LIST) ? Path.OUTLET_ESTABLISHMENT_LIST :
                    pageConfigs.isPathAccessible(Path.SELLER_HUB_ESTABLISHMENT_LIST) ? Path.SELLER_HUB_ESTABLISHMENT_LIST :
                        RoleDataMap.get(currentRole)?.basePath!,
        );
    }, []);

    return (
        <></>
    );
};