export class PurchaseOrderState {
    static readonly GENERATED = new PurchaseOrderState('GENERATED', 'Generated', false, ['dispatched_quantity', 'received_quantity'], false, { previousState: 'Accepted QTY', currentState: 'Available QTY' }, 'gold');

    static readonly DISPATCHED = new PurchaseOrderState('DISPATCHED', 'Dispatched', false, ['received_quantity'], false, { previousState: 'Dispatched QTY', currentState: 'Received QTY' }, 'green');

    static readonly HUB_RECEIVED = new PurchaseOrderState('HUB_RECEIVED', 'Hub received', true, [], true, undefined, 'blue');

    private constructor(
        public readonly state: string,
        public readonly display: string,
        public readonly enableDateFilterPicker: boolean,
        public readonly excludedDataIndicesForItemsTable: string[],
        public readonly showPaymentColumn?: boolean,
        public readonly purchaseOrderQuantityTitles?: {
            previousState: string,
            currentState: string,
        },
        public readonly tagColor?: string,

    ) { }

    static valueOf(state: string) {
        if (state === 'GENERATED') return PurchaseOrderState.GENERATED;
        if (state === 'DISPATCHED') return PurchaseOrderState.DISPATCHED;
        if (state === 'HUB_RECEIVED') return PurchaseOrderState.HUB_RECEIVED;
    }
}
