import { message } from 'antd';
import { AxiosRequestConfig } from 'axios';
import { store } from '../store';
import client from './ApiClient';

export interface ResponseHandler<T> {
    onResponseSuccess: (value: T) => void;
    onResponseFailed?: (errors: string[]) => void;
    onResponseError?: () => void;
}

declare global {
    interface Window {
        __RUNTIME_CONFIG__: any;
    }
}

const baseAxiosConfig: AxiosRequestConfig = {
    baseURL: window.__RUNTIME_CONFIG__.API_BASE_URL,
};

export function getApiCall<T>(endPoint, responseHandler: ResponseHandler<T>, headers = {}, userConfig = {}, disableFailureNotification: boolean = false) {
    const config: AxiosRequestConfig = {
        ...baseAxiosConfig,
        ...userConfig,
        headers: {
            ...headers,
            Authorization: `Bearer ${store.getState().auth.authToken}`,
        },
    };
    const promise = client.get(endPoint, config);
    promise
        .then(response => {
            const { data } = response;
            responseHandler.onResponseSuccess(data);
        })
        .catch(err => {
            if (err?.response?.data?.errors) {
                const { data } = err.response;
                if (!disableFailureNotification) {
                    message.error(`${data.errors[0]}`, 5, () => { });
                }
                if (responseHandler.onResponseFailed) {
                    responseHandler.onResponseFailed(data.errors);
                }
            } else {
                if (!disableFailureNotification) {
                    message.error('Something went wrong!', 3, () => { });
                }

                if (responseHandler.onResponseError) {
                    responseHandler.onResponseError();
                }
            }
        });
}

export function postApiCall<T>(endPoint, body, responseHandler: ResponseHandler<T>, headers = {}, userConfig = {}, disableFailureNotification: boolean = false) {
    const config: AxiosRequestConfig = {
        ...baseAxiosConfig,
        ...userConfig,
        headers: {
            ...headers,
            Authorization: `Bearer ${store.getState().auth.authToken}`,
        },
    };
    const promise = client.post(endPoint, body, config);
    promise
        .then(response => {
            const { data } = response;
            responseHandler.onResponseSuccess(data);
        })
        .catch(err => {
            if (err?.response?.data?.errors) {
                const { data } = err.response;
                if (!disableFailureNotification) {
                    message.error(`${data.errors[0]}`, 5, () => { });
                }
                if (responseHandler.onResponseFailed) {
                    responseHandler.onResponseFailed(data.errors);
                }
            } else {
                if (!disableFailureNotification) {
                    message.error('Something went wrong!', 3, () => { });
                }
                if (responseHandler.onResponseError) {
                    responseHandler.onResponseError();
                }
            }
        });
}

export async function putApiCall<T>(
    endPoint,
    body,
    responseHandler: ResponseHandler<T>,
    headers = {},
    userConfig = {},
    disableFailureNotification: boolean = false,
): Promise<any> {
    const config: AxiosRequestConfig = {
        ...baseAxiosConfig,
        ...userConfig,
        headers: {
            ...headers,
            Authorization: `Bearer ${store.getState().auth.authToken}`,
        },
    };
    const promise = client.put(endPoint, body, config);
    promise
        .then(response => {
            const { data } = response;
            responseHandler.onResponseSuccess(data);
            return Promise.resolve();
        })
        .catch(err => {
            if (err?.response?.data?.errors) {
                const { data } = err.response;
                message.error(`${data.errors[0]}`, 5, () => { });
                if (responseHandler.onResponseFailed) {
                    responseHandler.onResponseFailed(data.errors);
                }
            } else {
                if (!disableFailureNotification) {
                    message.error('Something went wrong!', 3, () => { });
                }
                if (responseHandler.onResponseError) {
                    responseHandler.onResponseError();
                }
            }
            return Promise.reject();
        });
}

export function deleteApiCall<T>(endPoint, responseHandler: ResponseHandler<T>, headers = {}, userConfig = {}, disableFailureNotification: boolean = false) {
    const config: AxiosRequestConfig = {
        ...baseAxiosConfig,
        ...userConfig,
        headers: {
            ...headers,
            Authorization: `Bearer ${store.getState().auth.authToken}`,
        },
    };
    const promise = client.delete(endPoint, config);
    promise
        .then(response => {
            const { data } = response;
            responseHandler.onResponseSuccess(data);
        })
        .catch(err => {
            if (err?.response?.data?.errors) {
                const { data } = err.response;
                message.error(`${data.errors[0]}`, 5, () => { });
                if (responseHandler.onResponseFailed) {
                    responseHandler.onResponseFailed(data.errors);
                }
            } else {
                if (!disableFailureNotification) {
                    message.error('Something went wrong!', 3, () => { });
                }
                if (responseHandler.onResponseError) {
                    responseHandler.onResponseError();
                }
            }
        });
}
