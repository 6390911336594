import { Breadcrumb } from 'antd';
import React from 'react';
import { PageBreadcrumbHeaderProps } from './types';

export const PageBreadcrumbHeader = (props: PageBreadcrumbHeaderProps) => {

    return (
        <>
            <Breadcrumb className='text-lg sm:text-2xl font-semibold'>
                {props.items.map((item, index) => (
                    <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </>
    );
};
