import React from 'react';
import { Form, Input } from 'antd';
import { BankAccountLayoutProps } from './types';
import { BankAccountProperties } from './utils';
import { DetailsCardProps } from '../../../../common/detailsCard/types';
import { DetailsCard } from '../../../../common/detailsCard';


function BankDetailLayout(props: BankAccountLayoutProps) {
    const { bankAccountProperties: bap, setBankAccountProperties: setBap, form } = props;

    const changeValue = (property: keyof BankAccountProperties, value: any) => {
        const updated = {
            ...bap,
            [property]: { ...bap[property], value, changed: true },
        };
        setBap(updated);
    };


    // Variables
    const bankAccountDetails: DetailsCardProps =
    {
        data: [{
            show: bap.accountNumber?.show,
            label: 'Account number',
            value: <Form.Item
                name="accountNumber"
                rules={[
                    {
                        required: bap.accountNumber?.required,
                        message: 'Please enter the account number',
                    },
                ]}>
                <Input
                    disabled={bap.accountNumber?.disabled}
                    style={{ width: '100%' }}
                    placeholder="Account number"
                    value={bap.accountNumber?.value}
                    onChange={event => changeValue('accountNumber', event.currentTarget.value)}
                />
            </Form.Item>,
        },
        {
            show: bap.accountHolderName?.show,
            label: 'Account Holder Name',
            value: <Form.Item
                name="accountHolderName"
                rules={[
                    {
                        required: bap.accountHolderName?.required,
                        message: 'Please enter the account holder name',
                    },
                ]}>
                <Input
                    type="text"
                    disabled={bap.accountHolderName?.disabled}
                    placeholder="Account Holder Name"
                    value={bap.accountHolderName?.value}
                    onChange={event => changeValue('accountHolderName', event.currentTarget.value)}
                />
            </Form.Item>,
        },
        {
            show: bap.ifsc?.show,
            label: 'IFSC Code',
            value: <Form.Item
                name="ifsc"
                rules={[
                    {
                        required: bap.ifsc?.required,
                        message: 'Please enter the account number',
                    },
                ]}>
                <Input
                    type="text"
                    disabled={bap.ifsc?.disabled}
                    placeholder="IFSC Code"
                    value={bap.ifsc?.value}
                    onChange={event => changeValue('ifsc', event.currentTarget.value)}
                />
            </Form.Item>,
        }],
    };

    // useEffects
    React.useEffect(() => {
        form.setFieldsValue({
            accountNumber: bap.accountNumber?.value,
            accountHolderName: bap.accountHolderName?.value,
            ifsc: bap.ifsc?.value,
        });
    }, [bap]);

    return (
        <>
            <DetailsCard
                removePadding
                labelStyleClass='xs:w-full lg:w-1/3'
                valueStyleClass='xs:w-full lg:w-1/3'
                data={
                    bankAccountDetails?.data?.filter((datum) => datum?.show !== false).map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey font-semibold'>{cardDatum.label}</label>,
                            value: cardDatum.value,
                        };
                    })
                }
            />
        </>
    );
}

export default BankDetailLayout;
