import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { addKeyToDataList, formatCurrency, isArrayEmpty } from '../../Utils';
import { useNavigate } from 'react-router';
import { EstablishmentsDropDown, EstablishmentSelectionMode, getEncodedStringFromUuidList, getUuidListFromEncodedString } from '../common/establishments/EstablishmentsDropDown';
import { ReturnOrderListResponse, ReturnOrderSummary } from './types';
import { getApiCall, ResponseHandler } from '../../http/HttpClient';
import { GET_RETURN_ORDERS } from '../../http/EndPoints';
import { DataNotFound } from '../common/templates/dataNotFound';
import { ListingPage } from '../common/templates/listingPage';
import { PageBreadcrumbHeader } from '../common/pageBreadcrumbHeader';
import React from 'react';
import { Button, Col, Row, Select, Tag } from 'antd';
import { EstablishmentType } from '../../types/EstablishmentType';
import TimeFilterPicker from '../common/TimeFilterPicker';
import { layout, style } from '../../styles/css/style';
import { MeraaiTable } from '../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../common/templates/meraaiTable/types';
import { getPropertyFromState, getReturnOrderStateOptions } from './Util';
import { getISTFromEpoch } from '../../utilities/Date';

const RETURN_ORDERS_PAGE_SIZE = 10;

export const ReturnsListing = () => {

    const isMobileDevice = useAppSelector((state) => state.sideMenuCollapsed.collapsed);
    const navigate = useNavigate();

    const [establishmentUuids, setEstablishmentUuids] = useState<string[] | undefined>(undefined);
    const [returnOrderState, setReturnOrderState] = useState<string>();
    const [fromDate, setFromDate] = useState<number>();
    const [toDate, setToDate] = useState<number>();
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(RETURN_ORDERS_PAGE_SIZE);
    const [returnOrdersResponse, setReturnOrdersResponse] = useState<ReturnOrderListResponse | undefined>();
    const [loading, setLoading] = useState<boolean>();
    const [bodyResultMsg, setBodyResultMsg] = useState('Search for the data using filters mentioned above.');

    const setStateFromQueryParams = (queryParams: URLSearchParams, param: string, stateSetter: React.Dispatch<React.SetStateAction<any>>) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (!isArrayEmpty(uuidList)) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const pageNo = parseInt(queryParams.get(param)!);
            stateSetter(pageNo);
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams: URLSearchParams, param: string, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                } else {
                    queryParams.delete(param);
                }
            } else if (param == 'from-date' || param == 'to-date') {
                if (returnOrderState === 'PLACED') {
                    queryParams.delete(param);
                } else {
                    queryParams.set(param, state);
                }
            } else {
                queryParams.set(param, state);
            }
        }
    };

    const setStatesFromUrl = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'state', setReturnOrderState);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setStateFromQueryParams(queryParams, 'size', setPageSize);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'state', returnOrderState);
        setQueryParamsFromState(queryParams, 'page', page);
        setQueryParamsFromState(queryParams, 'size', pageSize);
        /**
          * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
          * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
          * So we are replacing the page with the help of replace property of navigate method.
        * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const getReturnOrders = () => {
        setLoading(true);

        const returnOrdersEndPoint = GET_RETURN_ORDERS(
            establishmentUuids ? establishmentUuids : [],
            returnOrderState ?? '',
            page,
            pageSize,
            returnOrderState === 'PLACED' ? undefined : fromDate,
            returnOrderState === 'PLACED' ? undefined : toDate,
        );

        const responseHandler: ResponseHandler<ReturnOrderListResponse> = {
            onResponseSuccess(response: ReturnOrderListResponse): void {
                setLoading(false);
                if (response.return_orders && response.return_orders.length > 0) {
                    setReturnOrdersResponse(response);
                } else {
                    setReturnOrdersResponse(undefined);
                    setBodyResultMsg('No return orders can be found in the specified time period.');
                }

            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
                setBodyResultMsg('Something went wrong!');
            },
        };
        getApiCall(returnOrdersEndPoint, responseHandler);
    };

    const onSelectedStatesChanged = (value: string) => {
        setReturnOrderState(value);
    };

    const getUserNameForState = (data: ReturnOrderSummary): string => {
        return data.state_metadata.find(item => item.state == data.state)?.user?.name ?? '';
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Outlet Name.',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderSummary) => {
                return (<label className={'cursor-pointer whitespace-pre-line'}>
                    {record.sink_establishment.name}
                </label>);

            },
        },
        {
            title: 'Sales agent name',
            rowVisibility: [Device.DESKTOP],
            render: (_, record: ReturnOrderSummary) => {

                return (<label className={'cursor-pointer whitespace-pre-line'}>
                    {getUserNameForState(record)}
                </label>);
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (amount) => <label className='font-semibold'>{formatCurrency(amount)}</label>,
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            rowVisibility: [Device.DESKTOP],
            render: (created_at) => <label>{getISTFromEpoch(created_at)}</label>,
        },
        {
            title: 'Updated at',
            dataIndex: 'state_updated_at',
            rowVisibility: [Device.DESKTOP],
            render: (state_updated_at) => <label>{getISTFromEpoch(state_updated_at)}</label>,
        },
        {
            title: 'State',
            rowVisibility: [Device.DESKTOP],
            dataIndex: 'state',
            render: (state: string) => {
                const property = getPropertyFromState(state);
                return (
                    <Col className='w-full'>
                        {
                            <Tag
                                className={`w-full py-2 ${layout.flex.spaceEvenly}`}
                                color={property.color}
                            >
                                <label className={`cursor-pointer whitespace-pre-line ${layout.flex.center} `}>
                                    {property.display}
                                </label>
                            </Tag>
                        }
                    </Col >
                );
            },

        },

        {
            title: isMobileDevice ? '' : 'Action',
            dataIndex: 'uuid',
            mobileWidth: 'FULL',
            rowVisibility: [Device.DESKTOP],
            render: (uuid: string) => <Button
                className={`w-full ${style.meraaiOrangeButton}`}
                onClick={() => { navigate(`${uuid}`); }}
            >
                {isMobileDevice ? 'View Return Order' : 'View'}
            </Button>,
        },

    ];

    const enableGetOrdersButton = (): boolean => {
        return establishmentUuids != undefined
            && establishmentUuids.length > 0
            && returnOrderState != undefined
            && (returnOrderState === 'PLACED' || (toDate != undefined && fromDate != undefined));
    };

    useEffect(() => {
        if (returnOrderState === 'PLACED') {
            setFromDate(undefined);
            setToDate(undefined);
        }
        setUrlParamsFromState();
    }, [establishmentUuids, returnOrderState, fromDate, toDate, page, pageSize]);

    useEffect(() => {
        setStatesFromUrl();
    }, []);

    useEffect(() => {
        if (enableGetOrdersButton()) {
            getReturnOrders();
        }
    }, [page, pageSize]);

    return (
        <>
            <ListingPage
                breadcrumb={
                    <>
                        <PageBreadcrumbHeader items={[
                            'Return Orders',
                        ]} />
                    </>
                }
                header={
                    <>
                        <Row>
                            <Col className={'p-2 w-full xs:w-1/2 lg:w-1/5'}>
                                <EstablishmentsDropDown
                                    // disabled={props.selectedEstablishmentUuid !== undefined}
                                    setLoading={setLoading}
                                    type={EstablishmentType.valueOf('HUB')!}
                                    setSelectedUuids={setEstablishmentUuids}
                                    selectedUuids={establishmentUuids}
                                    selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                                    fetchEstablishmentList={true}
                                />
                            </Col>

                            <Col className={'p-2 w-full xs:w-1/2 lg:w-1/5'}>
                                <Select
                                    value={returnOrderState}
                                    placeholder="Select state"
                                    className="filter-drop-down"
                                    maxTagCount={1}
                                    showArrow
                                    showSearch={false}
                                    onChange={onSelectedStatesChanged}>
                                    {
                                        getReturnOrderStateOptions().map(state => (

                                            <Select.Option value={state.state} key={state.state}>
                                                {state.display}
                                            </Select.Option>

                                        ))
                                    }
                                </Select>
                            </Col>

                            <Col className={'p-2 w-full md:w-1/2 lg:w-1/3'}>
                                <TimeFilterPicker
                                    enableFilterPicker={returnOrderState != undefined && returnOrderState !== 'PLACED'}
                                    fromDateEpoch={fromDate!}
                                    toDateEpoch={toDate!}
                                    setFromDateEpoch={setFromDate}
                                    setToDateEpoch={setToDate}
                                />
                            </Col>

                            <Col className={'p-2 w-full md:w-1/2 lg:w-1/6'}>
                                <Button
                                    className={`p-0 w-full ${style.meraaiOrangeButton} ${layout.flex.center}`}
                                    size="large"
                                    onClick={() => {
                                        getReturnOrders();
                                    }}
                                    disabled={!enableGetOrdersButton()}>
                                    Get Orders
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                body={
                    <>
                        {
                            returnOrdersResponse && returnOrdersResponse.return_orders.length > 0 && !loading ?

                                <MeraaiTable
                                    loading={loading}
                                    dataSource={addKeyToDataList(returnOrdersResponse?.return_orders)}
                                    columns={columns}
                                    pagination={{
                                        pageSize,
                                        current: page + 1,
                                        total: returnOrdersResponse.page_count * pageSize,
                                        onChange: (pageNo, size) => {
                                            setLoading(true);
                                            setPage(pageNo - 1);
                                            setPageSize(size);
                                        },
                                    }}
                                />

                                :

                                <DataNotFound loading={loading} title={bodyResultMsg} />
                        }
                    </>
                }
            />
        </>
    );

};