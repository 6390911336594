import * as React from 'react';

function OrderIcon() {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="url(#paint0_linear_140_441)"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.1429 15.9062C22.1429 14.6118 23.1027 13.5625 24.2857 13.5625H25.7143C26.8973 13.5625 27.8571 14.6118 27.8571 15.9062V33.0938C27.8571 34.3877 26.8973 35.4375 25.7143 35.4375H24.2857C23.1027 35.4375 22.1429 34.3877 22.1429 33.0938V15.9062ZM15 25.2812C15 23.9873 15.9594 22.9375 17.1429 22.9375H18.5714C19.7545 22.9375 20.7143 23.9873 20.7143 25.2812V33.0938C20.7143 34.3877 19.7545 35.4375 18.5714 35.4375H17.1429C15.9594 35.4375 15 34.3877 15 33.0938V25.2812ZM32.8571 16.6875C34.0402 16.6875 35 17.7373 35 19.0312V33.0938C35 34.3877 34.0402 35.4375 32.8571 35.4375H31.4286C30.2455 35.4375 29.2857 34.3877 29.2857 33.0938V19.0312C29.2857 17.7373 30.2455 16.6875 31.4286 16.6875H32.8571Z"
                fill="#A95013"
            />
            <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_140_441)" fillOpacity="0.65" />
            <defs>
                <linearGradient
                    id="paint0_linear_140_441"
                    x1="41"
                    y1="50"
                    x2="-11.5"
                    y2="15.5"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A95013" />
                    <stop offset="1" stopColor="#A95013" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default OrderIcon;
