import { Col, Form, Row } from 'antd';
import React from 'react';
import { layout } from '../../../../../styles/css/style';
import { ExpandableDataViewProps } from './types';

export const ExpandableDataView = (props: ExpandableDataViewProps) => {
    const getWidth = (datum) => {
        if (datum.width === 'FULL' || props.data?.expandableColumnData?.length === 1) {
            return 'w-full';
        }
        if (props.data?.expandableColumnData?.length === 2) {
            return 'w-full xs:w-1/2';
        }
        return 'w-full xs:w-1/2 sm:w-1/3';
    };

    const displayStyleMap = new Map<'SHOW' | 'EDIT', JSX.Element>(
        [
            [
                'SHOW', <Row className='p-10 border border-solid border-meraai-orange rounded-lg'>
                    {
                        props?.data?.expandableColumnData?.map((datum, index) =>

                            <Col
                                key={index}
                                className={`${getWidth(datum)} lg:w-1/4 pb-6 px-3 ${layout.flexCol.center}`}
                            >
                                <Col className='w-fit text-sm pb-2 w-max'>{datum.title}</Col>
                                <Col className='w-fit text-sm font-semibold'>{datum.value}</Col>
                            </Col>,
                        )
                    }
                </Row>,
            ],
            [
                'EDIT', <>
                    <Form
                        form={props.data?.formRef}
                        className={'p-10 border border-solid border-meraai-orange rounded-lg'}
                    >

                        <Row>
                            {
                                props?.data?.expandableColumnData?.filter(datum => !datum.actionable).map((datum, index) =>
                                    <Col key={index} className='px-1 w-full xs:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3'>
                                        <Col className='w-full'>
                                            <h4>{datum.title}</h4>
                                        </Col>
                                        <Col className='w-full lg:w-2/3 xl:w-3/4 2xl:w-2/3 h-[50px]'>
                                            {datum.value}
                                        </Col>
                                    </Col>,
                                )
                            }
                        </Row>
                        <Row className='mt-10'>
                            {
                                props?.data?.expandableColumnData?.filter(datum => datum.actionable).map((datum, index) =>
                                    <Col key={index} className={`w-full xs:w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 p-2 ${layout.flex.start}`}>
                                        <Col className={`w-full ${layout.flex.start}`}>
                                            {datum.value}
                                        </Col>
                                    </Col>,
                                )
                            }
                        </Row>
                    </Form>

                </>,
            ],
        ],
    );

    return (
        <>
            {displayStyleMap.get(props.expandableRowModificationType ?? 'SHOW')}
        </>
    );
};