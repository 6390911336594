import moment, { Moment } from 'moment-timezone';
import { TimeFilterMode } from '../components/common/TimeFilterPicker';

export const TIMEZONE = 'Asia/kolkata';
export const DateFormat = 'YYYY-MM-DD';

type TimeRange = {
    fromDate: Moment | undefined;
    toDate: Moment | undefined;
};

export class TimeFilter {
    static readonly TODAY = new TimeFilter('today', 'Today', false, [TimeFilterMode.DATE, TimeFilterMode.DATE_TIME]);

    static readonly YESTERDAY = new TimeFilter('yesterday', 'Yesterday', false, [
        TimeFilterMode.DATE,
        TimeFilterMode.DATE_TIME,
    ]);

    static readonly LAST_HOUR = new TimeFilter('last_hour', 'Last Hour', false, [TimeFilterMode.DATE_TIME]);

    static readonly LAST_12_HOURS = new TimeFilter('last_12_hours', 'Last 12 hours', false, [TimeFilterMode.DATE_TIME]);

    static readonly THIS_WEEK = new TimeFilter('this_week', 'This Week', false, [
        TimeFilterMode.DATE,
        TimeFilterMode.DATE_TIME,
    ]);

    static readonly LAST_7_DAYS = new TimeFilter('last_7_days', 'Last 7 Days', false, [
        TimeFilterMode.DATE,
        TimeFilterMode.DATE_TIME,
    ]);

    static readonly LAST_14_DAYS = new TimeFilter('last_14_days', 'Last 14 Days', false, [
        TimeFilterMode.DATE,
        TimeFilterMode.DATE_TIME,
    ]);

    static readonly LAST_MONTH = new TimeFilter('last_month', 'Last Month', false, [
        TimeFilterMode.DATE,
        TimeFilterMode.DATE_TIME,
    ]);

    private constructor(
        public readonly value: string,
        public readonly display: string,
        public readonly dateRangePickerEnabled: boolean,
        public readonly modesAvailableIn: TimeFilterMode[],
    ) { }

    static list(): Array<TimeFilter> {
        return Object.values(TimeFilter);
    }

    static presets(timeFilterMode: TimeFilterMode): {} {
        return TimeFilter.list()
            .filter(item => item.modesAvailableIn.includes(timeFilterMode))
            .reduce((presets, filter) => {
                const timeRange = filter.timeRange();
                presets[filter.display] = [timeRange.fromDate, timeRange.toDate];
                return presets;
            }, {});
    }

    timeRange(): TimeRange {
        switch (this) {
            case TimeFilter.TODAY:
                return {
                    fromDate: moment.tz(TIMEZONE).startOf('day'),
                    toDate: moment.tz(TIMEZONE).endOf('day'),
                };

            case TimeFilter.YESTERDAY:
                return {
                    fromDate: moment.tz(TIMEZONE).startOf('day').subtract(1, 'days'),
                    toDate: moment.tz(TIMEZONE).endOf('day').subtract(1, 'days'),
                };

            case TimeFilter.LAST_HOUR:
                return {
                    fromDate: moment.tz(TIMEZONE).subtract(1, 'hours'),
                    toDate: moment.tz(TIMEZONE),
                };

            case TimeFilter.LAST_12_HOURS:
                return {
                    fromDate: moment.tz(TIMEZONE).subtract(12, 'hours'),
                    toDate: moment.tz(TIMEZONE),
                };

            case TimeFilter.THIS_WEEK:
                return {
                    fromDate: moment.tz(TIMEZONE).startOf('week'),
                    toDate: moment.tz(TIMEZONE).endOf('day'),
                };

            case TimeFilter.LAST_7_DAYS:
                return {
                    fromDate: moment.tz(TIMEZONE).startOf('day').subtract(7, 'days'),
                    toDate: moment.tz(TIMEZONE).endOf('day'),
                };

            case TimeFilter.LAST_14_DAYS:
                return {
                    fromDate: moment.tz(TIMEZONE).startOf('day').subtract(14, 'days'),
                    toDate: moment.tz(TIMEZONE).endOf('day'),
                };

            case TimeFilter.LAST_MONTH:
                return {
                    fromDate: moment.tz(TIMEZONE).startOf('day').subtract(1, 'month'),
                    toDate: moment.tz(TIMEZONE).endOf('day'),
                };
            default:
                return { fromDate: undefined, toDate: undefined };
        }
    }
}

// Convert epoch to human-readable date
export const getISTFromEpoch = (utc: number, format?: 'DATETIME' | 'DATE' | 'TIME') => {

    let dateFormat = 'DD MMM, YYYY hh:mm A';

    if (format === 'DATE') {
        dateFormat = 'MMMM DD, YYYY';
    } else if (format === 'TIME') {
        dateFormat = 'hh:mm A';
    }

    return moment.utc(utc).tz('Asia/Kolkata').format(dateFormat);

};

export const differenceInDays = (date1: number, date2: number): number => {
    return (date1 - date2) / (1000 * 60 * 60 * 24);
};
