import { Col, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { ResponseHandler } from '../../../http/HttpClient';
import { FileIdentifier, FileUploadResponse, upload } from '../../../utilities/UploadFile';
import { layout } from '../../../styles/css/style';
import DocumentImageRenderer from '../DocumentImageRenderer';
import DocumentRenderer from '../../DocumentRenderer';
import { documentConfigs, DocumentType, MetadataDocument, MetadataUploaderProps, multipleValuesDocTypes } from './types';
import { createDocument, DocUploader, ImageUploader, MeraaiFormItem, TextEditor } from './utils';
import { MdDelete } from 'react-icons/md';
import { RiAddFill } from 'react-icons/ri';


export const MetadataUploader = (props: MetadataUploaderProps) => {

    const { documents, updateDocuments } = props;
    const [loading, setLoading] = React.useState<boolean>(false);

    const addDocument = (type: DocumentType) => {
        updateDocuments([createDocument(type, false, undefined, undefined, multipleValuesDocTypes.includes(type)), ...documents]);
    };

    const removeDocument = (document: MetadataDocument) => {
        updateDocuments(documents.filter(doc => doc.id !== document.id));
    };

    const setTypeInDocument = (document: MetadataDocument, type: DocumentType) => {
        document.type = type;
        document.values = undefined;
        if (!multipleValuesDocTypes.includes(type)) {
            document.multiple = false;
        }
        updateDocuments([...documents]);
    };

    const setPurposeInDocument = (document: MetadataDocument, purpose: string) => {
        document.purpose = purpose;
        updateDocuments([...documents]);
    };

    const removeValueFromDocument = (document: MetadataDocument, value: string | undefined) => {
        if (value) {
            document.values = document.values?.filter(item => item.value !== value);
        }
        updateDocuments([...documents]);
    };

    const setValueInDocument = (document: MetadataDocument, value: string | undefined, name?: string) => {
        if (value) {
            if (!document.values || !document.multiple) {
                document.values = [];
            }
            document.values.push({ name: name ?? document.name, value: value });
        }
        updateDocuments([...documents!!]);
    };

    const setNameInDocument = (document: MetadataDocument, name: string) => {
        document.name = name;
        updateDocuments([...documents!!]);
    };

    const uploadFile = (document: MetadataDocument, file: RcFile): Promise<string> => {
        setLoading(true);
        setNameInDocument(document, file.name);
        const responseHandler: ResponseHandler<FileUploadResponse> = {
            onResponseSuccess(response: FileUploadResponse): void {
                setValueInDocument(document, response.url);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        upload(document.type, file, responseHandler, FileIdentifier.OPERATIONS);
        return Promise.reject('NO ACTION');
    };



    function isUploadAllowed(document: MetadataDocument): boolean {
        const maxValues = document.multiple ? document.maxFiles ?? 20 : 1;
        return document?.values != undefined && document.values?.length > 0 && document.values?.length < maxValues;
    }

    function updateDropdownProperty(document: MetadataDocument, isInputCustom: boolean) {
        if (document.dropdownProperties) {
            document.dropdownProperties.isInputCustom = isInputCustom;
            updateDocuments([...documents]);
        }
    }

    return (
        <>
            <a
                className={`w-max text-meraai-brown hover:text-meraai-brown text-[1rem] font-semibold pt-2 ${layout.flex.start}`}
                onClick={() => addDocument('IMAGE')}
            >
                <RiAddFill />
                Add supporting images/docs/remarks
            </a>
            <Spin spinning={loading}>
                {documents?.map((document: MetadataDocument, index: number) => {
                    return (
                        <div key={index} className='my-6 border-solid border rounded-lg border-stone-400'>
                            <div className='p-5'>
                                {
                                    !document?.required &&

                                    (
                                        <>
                                            <Row className={`${layout.flex.end}`}>
                                                <Col className={`mx-2 ${layout.flex.center}`}>
                                                    <MdDelete
                                                        className='cursor-pointer text-meraai-brown text-xl'
                                                        onClick={() => removeDocument(document)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row key={index}> <Col className='w-full'>
                                                <Form
                                                    layout='vertical'
                                                >
                                                    <Form.Item
                                                        label={
                                                            <label className='font-semibold'>Type</label>
                                                        }
                                                        initialValue={document?.type}
                                                        name="type"
                                                    >
                                                        <Select
                                                            placeholder='Select Type'
                                                            value={document?.type}
                                                            onChange={type => setTypeInDocument(document, type)}
                                                            disabled={document?.required}>
                                                            {documentConfigs
                                                                .filter(documentConfig =>
                                                                    documentConfig?.type === 'DROP_DOWN'
                                                                        ? document?.dropdownProperties?.values ?? false
                                                                        : documentConfig?.global,
                                                                )
                                                                .map(documentConfig => (
                                                                    <Select.Option
                                                                        key={documentConfig.type}
                                                                        value={documentConfig.type}>
                                                                        {documentConfig.display}
                                                                    </Select.Option>
                                                                ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label={<label className='font-semibold'>Purpose</label>}
                                                        name="purpose"
                                                    >
                                                        <Input.TextArea
                                                            value={document?.purpose}
                                                            rows={3}
                                                            style={{ resize: 'none' }}
                                                            disabled={document?.required}
                                                            placeholder={`Purpose of the ${document?.type.toLowerCase()}`}
                                                            maxLength={25}
                                                            onChange={event =>
                                                                setPurposeInDocument(document, event.target.value)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Form> </Col>
                                            </Row>
                                        </>
                                    )
                                }
                                {
                                    document?.type === 'IMAGE' &&

                                    (
                                        <MeraaiFormItem
                                            document={document}
                                            label={
                                                <label className='font-semibold'>{document.purpose}</label>
                                            }
                                            value={
                                                <>
                                                    <Row key={document.id}>
                                                        {
                                                            (!document.values || document.values?.length == 0) &&
                                                            <Col xl={12} xs={24}>
                                                                <ImageUploader
                                                                    document={document}
                                                                    uploadFile={uploadFile}
                                                                    displayValue={'Upload an Image'}
                                                                />
                                                            </Col>
                                                        }
                                                        {
                                                            isUploadAllowed(document) &&
                                                            <Col xl={12} xs={24}>
                                                                <ImageUploader
                                                                    document={document}
                                                                    uploadFile={uploadFile}
                                                                    displayValue={'Upload More'}
                                                                />
                                                            </Col>

                                                        }
                                                    </Row>
                                                    <Row className='flex justify-around'>
                                                        {document.values && (
                                                            <DocumentImageRenderer
                                                                height={90}
                                                                width={90}
                                                                images={document.values}
                                                                deleteImage={(image: string) => removeValueFromDocument(document, image)}
                                                            />

                                                        )}
                                                    </Row>
                                                </>
                                            }
                                        />
                                    )
                                }

                                {
                                    document?.type === 'DOC' &&

                                    (
                                        <MeraaiFormItem
                                            document={document}
                                            label={
                                                <label className='font-semibold'>{document.purpose}</label>
                                            }
                                            value={
                                                <>
                                                    <Row key={document.id}>
                                                        {
                                                            (!document.values || document.values?.length == 0) &&

                                                            <Col className='w-full'>
                                                                <DocUploader
                                                                    document={document}
                                                                    uploadFile={uploadFile}
                                                                    displayValue={'Upload Document'}
                                                                />
                                                            </Col>
                                                        }
                                                        {
                                                            isUploadAllowed(document) &&
                                                            <Col className='w-full'>
                                                                <DocUploader
                                                                    document={document}
                                                                    uploadFile={uploadFile}
                                                                    displayValue={'Upload More'}
                                                                />
                                                            </Col>

                                                        }
                                                    </Row>
                                                    <Row className="flex-justify-center">
                                                        {document.values && (
                                                            <DocumentRenderer
                                                                documents={document.values}
                                                                deleteDocument={(documentValue: string) => removeValueFromDocument(document, documentValue)}
                                                            />
                                                        )}
                                                    </Row>
                                                </>
                                            }
                                        />
                                    )
                                }

                                {
                                    document?.type === 'REMARK' &&

                                    (
                                        <MeraaiFormItem
                                            document={document}
                                            label={
                                                <label className='font-semibold'>{document.purpose}</label>
                                            }
                                            value={

                                                <TextEditor
                                                    document={document}
                                                    setValueInDocument={setValueInDocument}
                                                />
                                            }
                                        />
                                    )
                                }

                                {
                                    document?.type === 'DROP_DOWN' &&

                                    (
                                        <MeraaiFormItem
                                            document={document}
                                            label={
                                                <label className='font-semibold'>{document.purpose}</label>
                                            }
                                            value={

                                                <Row gutter={[20, 20]}>
                                                    <Col xs={24}>
                                                        <Select
                                                            className="filter-drop-down"
                                                            placeholder="Select reason"
                                                            defaultValue={document?.values?.at(0)?.value}
                                                            onChange={value => {
                                                                setValueInDocument(document, value);

                                                                if (document.dropdownProperties?.allowCustomInput) {
                                                                    if (value === 'other') {
                                                                        updateDropdownProperty(document, true);
                                                                        removeValueFromDocument(document, value);
                                                                    } else {
                                                                        updateDropdownProperty(document, false);
                                                                    }
                                                                }
                                                            }}>
                                                            {document.dropdownProperties?.values?.map((dropdownValue, i) => (
                                                                <Select.Option key={i} value={dropdownValue}>
                                                                    {dropdownValue}
                                                                </Select.Option>
                                                            ))}
                                                            {
                                                                document.dropdownProperties?.allowCustomInput && <Select.Option key={document.dropdownProperties?.values?.length} value={'other'}>
                                                                    {'other'}
                                                                </Select.Option>
                                                            }

                                                        </Select>
                                                    </Col>
                                                    {
                                                        document.dropdownProperties?.isInputCustom && <Col xs={24}>
                                                            <Input.TextArea
                                                                style={{ resize: 'none' }}
                                                                placeholder="Enter a reason"
                                                                onBlur={(event) => {
                                                                    if (event.currentTarget.value.trim() !== '') {
                                                                        setValueInDocument(document, event.currentTarget.value.trim());
                                                                    } else {
                                                                        removeValueFromDocument(document, document.values?.at(0)?.value.trim());
                                                                    }
                                                                }}
                                                            />
                                                        </Col>
                                                    }
                                                </Row>
                                            }
                                        />

                                    )
                                }

                                {
                                    document?.type === 'AMOUNT' &&

                                    (
                                        <MeraaiFormItem
                                            document={document}
                                            label={
                                                <label className='font-semibold'>{document.purpose}</label>
                                            }
                                            value={
                                                <InputNumber
                                                    type='number'
                                                    style={{ width: '100%' }}
                                                    controls={false}
                                                    disabled={!document?.required}
                                                    maxLength={25}
                                                    placeholder={'Enter the amount'}
                                                    onChange={amount =>
                                                        setValueInDocument(document, amount.toString())
                                                    }
                                                />
                                            }
                                        />
                                    )
                                }

                                {
                                    document?.type === 'TRANSACTION_ID' &&

                                    (
                                        <MeraaiFormItem
                                            document={document}
                                            label={
                                                <label className='font-semibold'>{document.purpose}</label>
                                            }
                                            value={
                                                <Input
                                                    className='w-full'
                                                    disabled={!document?.required}
                                                    maxLength={25}
                                                    placeholder={'Enter transaction id'}
                                                    onChange={event =>
                                                        setValueInDocument(document, event?.currentTarget.value)
                                                    }
                                                />
                                            }
                                        />
                                    )
                                }
                            </div>
                        </div>
                    );
                })}
            </Spin>
        </>
    );
};
