import { Col, Row } from 'antd';
import React from 'react';
import { DataListingProps } from './types';

export const ListingPage = (props: DataListingProps) => {
    return (
        <Row className='h-screen'>
            <Col className='w-full flex flex-column'>

                <Row gutter={[0, 24]} className={`px-3 md:px-6 py-5 ${props.topRowStyleClass}`}>

                    {
                        props.hideBreadcrumb ? <></> : <Col className='w-full'>
                            {props.breadcrumb}
                        </Col>
                    }
                    {
                        props.header ? <Col className='w-full'>
                            {props.header}
                        </Col> : <></>
                    }

                </Row>

                <Row className={`w-full h-full bg-white px-3 md:px-6 py-5 ${props.bottomRowStyleClass}`}>
                    <Col className='w-full '>
                        {props.body}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};