import { ImageFile } from '../../components/common/ImageRenderer';
import { FileUploadResponse } from '../UploadFile';

export enum RendererType {
    value = 'value',
    component = 'component',
}
export interface BaseType {
    ref?: any;
}

export interface ImageType extends BaseType {
    loading: boolean;
    imageUrl: FileUploadResponse | undefined;
    setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
    uploadImage: (imageFile: ImageFile) => void;
}
export interface DateType extends BaseType {
    startDate: number;
    restriction: boolean;
    backDatedSelectionInDays: number;
    futureDateSelectionAbility: boolean;
}

export interface OptionType extends BaseType {
    options: string[];
}

export interface DatumRendererProps<T> {
    type: string;
    datumValue: any;
    record: T;
    onBlur?: (value: any, record: T) => void;
    onChange?: (value: any, record: T) => void;
    config?: BaseType;
    rendererType?: RendererType;
    ruleValidator?: () => boolean;
    triggerRule?: boolean;
    placeholder?: string;
}
