import { Alert, Button, Col, Row, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { GET_USER_ESTABLISHMENT_DETAILS, UPATE_ESTABLISHMENT } from '../../../../http/EndPoints';
import { getApiCall, putApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { EstablishmentDataResponse, ResponseMessage } from '../../../../Types';
import { EstablishmentType } from '../../../../types/EstablishmentType';
import EstablishmentList from './listing';
import EstablishmentListDrawer from './drawer';
import { UserEstablishmentDetailsProps } from './types';
import { layout, style } from '../../../../styles/css/style';
import { isArrayEmpty } from '../../../../Utils';
import { DataNotFound } from '../../../common/templates/dataNotFound';
import { ResponseDrawer } from '../../../common/templates/responseDrawer';
import { Operation } from '../types';

const UserEstablishmentDetails = (props: UserEstablishmentDetailsProps) => {

    // Hooks
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [userEstablishment, setUserEstablishment] = useState<EstablishmentDataResponse>();
    const [establishmentDrawerVisibility, setEstablishmentDrawerVisibility] = useState<boolean>(false);
    const [establishmentType, setEstablishmentType] = useState<EstablishmentType>();
    const [establishmentListRequested, setEstablishmentListRequested] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<string>();
    // Method
    const getEstablishmentDetails = () => {

        props.setLoading(true);

        const responseHandler: ResponseHandler<EstablishmentDataResponse> = {
            onResponseSuccess(response: EstablishmentDataResponse): void {
                response.establishments.forEach(establishment => {
                    establishment.checked = false;
                    establishment.key = establishment.uuid;
                });
                setUserEstablishment(response);
                props.setLoading(false);
            },
            onResponseFailed() {
                props.setLoading(false);
            },
            onResponseError() {
                props.setLoading(false);
            },
        };

        getApiCall(GET_USER_ESTABLISHMENT_DETAILS(props.username!, props.establishmentType!, page - 1, pageSize), responseHandler);
    };

    const updateEstablishments = (establishmentListData: EstablishmentDataResponse, operation: Operation) => {
        props.setLoading(true);

        const requestBody = {
            establishments: establishmentListData?.establishments.map((eachItem) => {
                if (eachItem.checked === true) {
                    return {
                        uuid: eachItem.uuid,
                        operation: operation,
                    };
                }
                return undefined;
            }).filter((eachItem) => eachItem),
        };

        const responseHandler: ResponseHandler<ResponseMessage> = {
            onResponseSuccess(response: ResponseMessage): void {
                setResponseMessage(response.message);
                props.setLoading(false);
            },
            onResponseFailed() {
                props.setLoading(false);
            },
            onResponseError() {
                props.setLoading(false);
            },
        };

        putApiCall(UPATE_ESTABLISHMENT(props.username!), requestBody, responseHandler);
    };

    const getEstablishmentCount = (): number => {
        if (props.userDetails.establishment_aggregate) {

            const establishmentCount = props.userDetails.establishment_aggregate[props.establishmentType?.type!];

            return establishmentCount > 0 ? establishmentCount : 0;

        }
        return 0;
    };

    useEffect(() => {
        if (props.active) {
            if (!userEstablishment) {
                getEstablishmentDetails();
            }
            setEstablishmentType(props.establishmentType);
        }
    }, [props.active]);


    useEffect(() => {
        if (establishmentListRequested) {
            getEstablishmentDetails();
            setEstablishmentListRequested(false);
        }
    }, [establishmentListRequested]);

    return (
        <>
            {
                userEstablishment ? <Space direction='vertical'>
                    <Row gutter={[12, 12]}>
                        <Col className={`w-full ${layout.flexCol.center} `}>

                            {
                                getEstablishmentCount() > 0 && <Alert
                                    showIcon
                                    type="success"
                                    className='w-full sm:w-max mb-5 sm:m-0'
                                    message={<label className={layout.flex.center}>No. of {props.establishmentType?.display} - {getEstablishmentCount()}</label>}
                                />
                            }


                            {
                                props.config.mapEstablishments &&
                                <Button
                                    className={`w-full sm:w-1/4 md:w-1/5 xl:w-1/6 ${style.meraaiOrangeButton} sm:absolute sm:right-0`}
                                    size='large'
                                    onClick={() => {
                                        setEstablishmentDrawerVisibility(true);
                                    }}
                                    disabled={userEstablishment.establishments.some(item => item.checked)}
                                >
                                    Assign {props.establishmentType?.display}
                                </Button>
                            }
                        </Col>
                    </Row>

                    {
                        isArrayEmpty(userEstablishment.establishments) ?
                            <DataNotFound title='No data found!' />

                            :

                            <EstablishmentList
                                config={props.config}
                                operation={Operation.REMOVE}
                                datasource={userEstablishment}
                                setDatasource={setUserEstablishment}
                                onClick={() => { updateEstablishments(userEstablishment!, Operation.REMOVE); }}
                                buttonText='Remove'
                                pagination={
                                    {
                                        pageSize: pageSize,
                                        current: page,
                                        total: userEstablishment?.page_count!! * pageSize,
                                        onChange: (pageValue, pageSizeValue) => {
                                            setPage(pageValue);
                                            setPageSize(pageSizeValue);
                                            setEstablishmentListRequested(true);
                                        },
                                    }
                                }
                            />
                    }

                    <EstablishmentListDrawer
                        username={props.username}
                        updateEstablishments={updateEstablishments}
                        establishmentType={establishmentType!}
                        establishmentDrawerVisibility={establishmentDrawerVisibility}
                        setEstablishmentDrawerVisibility={setEstablishmentDrawerVisibility}
                    />

                    <ResponseDrawer
                        type={'SUCCESS'}
                        message={responseMessage}
                        open={responseMessage !== undefined}
                        onClose={() => {
                            setResponseMessage(undefined);
                            setEstablishmentDrawerVisibility(false);
                            getEstablishmentDetails();
                        }}
                    />

                </Space> : <Skeleton active paragraph={{ rows: 4 }} />
            }
        </>

    );
};

export default UserEstablishmentDetails;