import { Tabs } from 'antd';
import React from 'react';

const { TabPane } = Tabs;

export interface TabComponentProperties {
    name: string;
    key: string;
    allowAccess: boolean;
    component: React.ReactElement;
}

type TabRendererProps = {
    activeKey: string;
    components: TabComponentProperties[];
    onKeyChange: (key: string) => void;
};

function TabRenderer(props: TabRendererProps) {
    const allowedTabs = props.components.filter(tab => tab.allowAccess);

    return (
        <>
            {allowedTabs.length === 1 ? (
                allowedTabs.at(0)?.component
            ) : (
                <Tabs
                    className="main-nested-tab-renderer"
                    tabPosition="top"
                    defaultActiveKey={props.activeKey || props.components[0].key}
                    activeKey={props.activeKey || props.components[0].key}
                    onChange={key => {
                        props.onKeyChange(key);
                    }}>
                    {allowedTabs.map(item => {
                        return (
                            <TabPane tab={item.name} key={item.key}>
                                {item.component}
                            </TabPane>
                        );
                    })}
                </Tabs>
            )}
        </>
    );
}

export default TabRenderer;
