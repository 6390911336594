import * as React from 'react';
import { Button, Col, Form, Input, InputNumber, message, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { RiMapPin2Fill } from 'react-icons/ri';
import { ESTABLISHMENTS } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { Establishment, EstablishmentDataResponse } from '../../../../../Types';
import ImageRenderer, { ImageFile, imageValidationRule, UploaderTypes } from '../../../../common/ImageRenderer';
import CloseableImage from '../../../../common/CloseableImage';
import { EstablishmentType } from '../../../../../types/EstablishmentType';
import { getCoordinates } from '../../../../map/Geocode';
import Map from '../../../../map/Map';
import { EstablishmentSaveMode } from '../../../../../types/EstablishmentSaveMode';
import { DetailsCard } from '../../../../common/detailsCard';
import { EstablishmentLayoutProps } from './types';
import { EstablishmentProperties } from './utils';
import { DetailsCardProps } from '../../../../common/detailsCard/types';

// Used in EstablishmentProperties while setting up values.
export let getSourcingEstablishment: (uuid: string) => string | void;


const EstablishmentLayout = (props: EstablishmentLayoutProps) => {
    // State variables
    const [hubs, setHubs] = useState<Establishment[]>();

    const { establishmentProperties: estP, setEstablishmentProperties: setEstP, establishmentType: et, form } = props;
    const states = [
        { name: 'Karnataka', code: 'KA' },
        { name: 'AndhraPradesh', code: 'AP' },
    ];

    // Methods
    getSourcingEstablishment = (sourcingEstablishmentUuid) => {
        for (const hub in hubs) {
            if (hubs[hub].uuid === sourcingEstablishmentUuid) {
                return hubs[hub].name;
            }
        }
    };

    const getSourcingHubs = () => {
        props.setLoading(true);
        const responseHandler: ResponseHandler<EstablishmentDataResponse> = {
            onResponseSuccess(value: EstablishmentDataResponse): void {
                setHubs(value.establishments);
                props.setLoading(false);
            },
            onResponseFailed(): void {
                props.setLoading(false);
            },
            onResponseError(): void {
                props.setLoading(false);
            },
        };
        getApiCall(ESTABLISHMENTS(EstablishmentType.HUB, 0, {}, 100), responseHandler);
    };

    const changeValue = (property: keyof EstablishmentProperties, value: any) => {
        const updatedEstP = {
            ...estP,
            [property]: { ...estP[property], value, changed: true },
        };
        setEstP(updatedEstP);
    };

    const changeLatitudeAndLongitude = (latitude: number, longitude: number) => {
        setEstP({
            ...estP,
            latitude: { ...estP.latitude, value: latitude, changed: true },
            longitude: { ...estP.longitude, value: longitude, changed: true },
        });
    };

    const updateCoordinates = (address: string) => {
        if (!address) {
            return;
        }
        getCoordinates(address).then(latlng => {
            if (latlng.latitude && latlng.longitude) {
                changeLatitudeAndLongitude(latlng.latitude, latlng.longitude);
            } else {
                message.warn("Couldn't find coordinates for the address mentioned. Please enter them manually");
            }
        });
    };

    // Variables
    const establishmentDetails: DetailsCardProps =
    {
        data: [{
            show: estP.establishmentName?.show,
            label: 'Name',
            value: <Form.Item
                name="establishmentName"
                rules={[
                    {
                        required: estP.establishmentName?.required,
                        message: `Please input the ${et?.display}'s name!`,
                    },
                ]}
                validateTrigger="onBlur">
                <Input
                    disabled={estP.establishmentName?.disabled}
                    placeholder="Enter establishment's name"
                    type="text"
                    value={estP.establishmentName?.value}
                    onChange={event => changeValue('establishmentName', event.currentTarget.value)}
                />
            </Form.Item>,
        },
        {
            show: estP.establishmentImage?.show,
            label: <>
                <span className='font-semibold'>Establishment's picture</span>
                {estP.establishmentImage?.value && (
                    <CloseableImage
                        styleClass='py-3 lg:pt-5'
                        src={estP.establishmentImage.value.url}
                        onClose={() => changeValue('establishmentImage', undefined)}
                    />
                )}
            </>,
            value: <Form.Item
                name="establishmentImage"
                rules={[
                    () => imageValidationRule(estP.establishmentImage?.required!, estP.establishmentImage?.value!),
                ]}>
                <ImageRenderer
                    type={UploaderTypes.UPLOAD_DRAG_AND_DROP}
                    disabled={estP.establishmentImage?.disabled}
                    setFileState={(file: ImageFile) => changeValue('establishmentImage', file)}
                />
            </Form.Item>,
        },
        {
            show: estP.address?.show,
            label: 'Address',
            value: <Form.Item
                name="address"
                rules={[
                    {
                        required: estP.address?.required,
                        message: 'Please input the address',
                    },
                ]}>
                <Input.TextArea
                    disabled={estP.address?.disabled}
                    placeholder="Enter Address"
                    value={estP.address?.value}
                    onChange={event => changeValue('address', event.currentTarget.value)}
                    onBlur={event => {
                        const address = event.currentTarget.value;
                        if (props.mode === EstablishmentSaveMode.CREATE) {
                            updateCoordinates(address);
                        }
                    }}
                    size="large"
                    style={{ height: '100px' }}
                />
                {props.mode === EstablishmentSaveMode.UPDATE && (
                    <Button
                        disabled={estP.address?.disabled}
                        icon={<RiMapPin2Fill color="#F69620" />}
                        className="secondary-button"
                        style={{ float: 'right', marginTop: '1rem' }}
                        onClick={() => updateCoordinates(estP.address?.value!!)}>
                        &nbsp;Update Coordinates
                    </Button>
                )}
            </Form.Item>,
        },
        {
            show: estP.stateCode?.show,
            label: 'State',
            value: <Form.Item
                name="stateCode"
                rules={[
                    {
                        required: estP.stateCode?.required,
                        message: 'Please select a state',
                    },
                ]}>
                <Select
                    disabled={estP.stateCode?.disabled}
                    placeholder="Select a state"
                    style={{ width: '100%' }}
                    onChange={value => changeValue('stateCode', value)}>
                    {states.map((state, index) => (
                        <Select.Option key={index} value={state.code}>
                            {state.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>,
        },
        {
            show: estP.pinCode?.show,
            label: 'Pin code',
            value: <Form.Item
                name="pinCode"
                rules={[
                    {
                        required: estP.pinCode?.required,
                        message: `Please enter the pincode of the ${et?.display}`,
                    },
                ]}
                validateTrigger="onBlur">
                <InputNumber
                    disabled={estP.pinCode?.disabled}
                    placeholder="Enter pin code"
                    maxLength={6}
                    style={{ width: '100%' }}
                    controls={false}
                    onChange={value => {
                        changeValue('pinCode', value.toString());
                    }}
                />
            </Form.Item>,
        },
        {
            show: estP.longitude?.show && estP.latitude?.show,
            label: 'Location',
            value: <>
                <Row gutter={[12, 0]}>
                    <Col className='w-full sm:w-1/2'>
                        <Form.Item
                            name="latitude"
                            rules={[
                                {
                                    required: estP.latitude?.required,
                                    message: 'Please enter the latitude',
                                },
                            ]}>
                            <InputNumber
                                disabled={estP.latitude?.disabled}
                                placeholder="Enter latitude"
                                controls={false}
                                style={{ width: '100%' }}
                                onChange={value => {
                                    changeValue('latitude', value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col className='w-full sm:w-1/2'>
                        <Form.Item
                            name="longitude"
                            rules={[
                                {
                                    required: estP.longitude?.required,
                                    message: 'Please enter the longitude',
                                },
                            ]}>
                            <InputNumber
                                disabled={estP.longitude?.disabled}
                                placeholder="Enter longitude"
                                controls={false}
                                style={{ width: '100%' }}
                                onChange={value => {
                                    changeValue('longitude', value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    estP.latitude?.value && estP.longitude?.value &&
                    <Row className='w-full h-[250px] mb-5'>
                        <Map
                            center={{
                                latitude: estP.latitude?.value,
                                longitude: estP.longitude?.value,
                            }}
                            zoomLevel={17}
                            onMarkerDrag={changeLatitudeAndLongitude}
                            disabled={estP.latitude.disabled || estP.longitude.disabled}
                        />
                    </Row>
                }

            </>,
        },
        {
            show: estP.sourcingHubUuid?.show,
            label: 'Sourcing Hub',
            value: <Form.Item
                name="sourcingHubUuid"
                rules={[
                    {
                        required: estP.sourcingHubUuid?.required,
                        message: 'Please select the sourcing hub',
                    },
                ]}>
                <Select
                    disabled={estP.sourcingHubUuid?.disabled}
                    placeholder="Select a hub"
                    onChange={value =>
                        changeValue('sourcingHubUuid', {
                            key: value,
                            val: getSourcingEstablishment(value),
                        })
                    }>
                    {hubs?.map((hub, index) => (
                        <Select.Option key={index} value={hub.uuid}>
                            {hub.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>,
        }],
    };

    // useEffects
    useEffect(() => {
        if (props.establishmentType?.type == EstablishmentType.OUTLET.type) {
            getSourcingHubs();
        }
    }, [props.establishmentType]);

    useEffect(() => {
        /**
             * The values here correspond to the values of elements in the form.
             * Each key has a corresponding FormItem with the same name
             * When using name field on the Form.Item, it means that the Form component
             * will handle the value and handleChange on the field from then on.
             * Without the name field, rules provided in the FormItem don't work.
             */
        form.setFieldsValue({
            establishmentName: estP.establishmentName?.value,
            establishmentImage: estP.establishmentImage?.value,
            address: estP.address?.value,
            stateCode: estP.stateCode?.value,
            pinCode: estP.pinCode?.value,
            latitude: estP.latitude?.value,
            longitude: estP.longitude?.value,
            sourcingHubUuid: estP.sourcingHubUuid?.value,
        });
    }, [estP]);

    return (
        <>
            <DetailsCard
                removePadding
                labelStyleClass='xs:w-full lg:w-1/3'
                valueStyleClass='xs:w-full lg:w-2/3'
                data={
                    establishmentDetails.data?.filter((datum) => datum?.show !== false)?.map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey font-semibold'>{cardDatum.label}</label>,

                            value: cardDatum.value,
                        };
                    })
                }
            />
        </>
    );
};

export default EstablishmentLayout;
