/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */

import React, { ReactNode } from 'react';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

import { RcFile } from 'antd/lib/upload';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { layout } from '../../styles/css/style';

const { Dragger } = Upload;

export class ImageFile {
    url: string | undefined;

    name?: string;

    file?: RcFile;
}

type ImageRendererProps = {
    styleClass?: string;
    setFileState: (val: ImageFile) => void;
    type: UploaderTypes;
    draggerText?: string;
    draggerHint?: string;
    cropperTitle?: string;
    cropperShape?: 'rect' | 'round';
    disabled?: boolean;
    uploadText?: ReactNode;
    uploadIcon?: ReactNode;
    size?: SizeType
};

export enum UploaderTypes {
    UPLOAD_DRAG_AND_DROP = 'UPLOAD_DRAG_AND_DROP',
    UPLOAD_PNG = 'UPLOAD_PNG',
}

const ACCEPT_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

function ImageRenderer(props: ImageRendererProps) {
    const beforeUpload = (file: RcFile) => {
        const isAllowed = ACCEPT_IMAGE_TYPES.includes(file.type);
        if (!isAllowed) {
            message.error(`${file.name} is not a png/jpeg file`);
            return isAllowed || Upload.LIST_IGNORE;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            props.setFileState({ url: reader.result?.toString(), name: file.name, file });
        };

        return false;
    };

    const daggerProps = {
        disabled: props.disabled,
        name: 'file',
        multiple: false,
        beforeUpload,
        showUploadList: false,
        maxCount: 1,
    };

    const uploadProps = {
        disabled: props.disabled,
        name: 'file',
        multiple: false,
        beforeUpload,
        maxCount: 1,
        showUploadList: false,
    };

    const UploaderTypes = {
        UPLOAD_DRAG_AND_DROP: (
            <Dragger {...daggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    {props.draggerText ?? 'Click or drag file to this area to upload'}
                </p>
                <p className="ant-upload-hint">
                    {props.draggerHint ?? 'Supports only images (png/jpg/jpeg)'}
                </p>
            </Dragger>
        ),

        UPLOAD_PNG: (
            <Upload {...uploadProps}>
                <Button
                    className={`${layout.flex.center}`}
                    disabled={props.disabled}
                    size={props.size ?? 'large'}
                    icon={props.uploadIcon ?? <UploadOutlined />}
                >
                    {props.uploadText ?? 'Upload'}
                </Button>
            </Upload>
        ),
    };

    return (
        <div className={props.styleClass}>
            <ImgCrop
                modalTitle={
                    props.cropperTitle ?? 'Edit Image. Only the part with in the box will be selected'
                }
                rotate
                minZoom={0.5}
                shape={props.cropperShape}
                cropperProps={{
                    restrictPosition: false,
                }}>
                {UploaderTypes[props.type]}
            </ImgCrop>
        </div>
    );
}

export const imageValidationRule = (required: boolean, file: ImageFile) => {
    if (required) {
        return {
            validator(_, __) {
                if (file) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('Please add a picture'));
            },
        };
    }
    return { required: false };
};

export default ImageRenderer;
