import EstablishmentList from '../listing';
import { Button, Col, Drawer, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { ESTABLISHMENTS } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { EstablishmentDataResponse } from '../../../../../Types';
import { layout, style } from '../../../../../styles/css/style';
import { AiOutlineClose } from 'react-icons/ai';
import { EstablishmentListDrawerProps } from './types';
import { Operation } from '../../types';

const EstablishmentListDrawer = (props: EstablishmentListDrawerProps) => {

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>();
    const [searchName, setSearchName] = useState<string>();
    const [establishmentList, setEstablishmentList] = useState<EstablishmentDataResponse>();
    const [establishmentListRequested, setEstablishmentListRequested] = useState<boolean>(false);


    // Method

    const searchButtonOnClick = () => {
        setPage(1);
        setEstablishmentListRequested(true);
    };

    const resetStatesAndCloseDrawer = () => {
        establishmentList?.establishments.forEach(establishment => establishment.checked = false);
        setSearchName('');
        searchButtonOnClick();
    };

    const getUserEstablishmentList = () => {
        if (!props.establishmentType) {
            return;
        }
        setLoading(true);

        const responseHandler: ResponseHandler<EstablishmentDataResponse> = {
            onResponseSuccess(response: EstablishmentDataResponse): void {
                response.establishments.forEach(establishment => {
                    establishment.checked = false;
                    establishment.key = establishment.uuid;
                });
                setEstablishmentList(response);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        getApiCall(ESTABLISHMENTS(props.establishmentType, page - 1, { name: searchName }, pageSize), responseHandler);
    };

    useEffect(() => {
        getUserEstablishmentList();
    }, [props.establishmentType]);

    useEffect(() => {
        if (establishmentListRequested) {
            getUserEstablishmentList();
            setEstablishmentListRequested(false);
        }
    }, [establishmentListRequested]);



    return (
        <Drawer
            className='meraai-drawer'
            open={props.establishmentDrawerVisibility}
            title={<h3>{props.establishmentType?.display}</h3>}
            extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                props.setEstablishmentDrawerVisibility(false);
                resetStatesAndCloseDrawer();
            }} />}
            closable={false}

            destroyOnClose
            maskClosable={false}
            keyboard={false}
            footer={
                <>
                    <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                        <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                            <Button
                                className={`w-full ${style.meraaiPlainButton}`}
                                disabled={loading}
                                onClick={() => {
                                    props.setEstablishmentDrawerVisibility(false);
                                    resetStatesAndCloseDrawer();
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                            <Button
                                className={`w-full ${style.meraaiOrangeButton}`}
                                disabled={!establishmentList?.establishments.some(item => item.checked) || loading}
                                onClick={() => {
                                    props.updateEstablishments(establishmentList!, Operation.ADD);
                                    resetStatesAndCloseDrawer();
                                }}
                            >
                                Proceed
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        >
            <Spin spinning={loading} tip='Loading' className='h-full'>
                {
                    <EstablishmentList
                        nameSearch={
                            {
                                searchButtonOnClick: () => {
                                    searchButtonOnClick();
                                },
                                searchPlaceholder: `Search ${props.establishmentType?.display}`,
                                setSearchText: setSearchName,
                            }
                        }

                        datasource={establishmentList}
                        setDatasource={setEstablishmentList}
                        operation={Operation.ADD}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: establishmentList?.page_count!! * pageSize,
                            onChange: (pageValue, pageSizeValue) => {
                                setPage(pageValue);
                                setPageSize(pageSizeValue);
                                setEstablishmentListRequested(true);
                            },
                        }}
                    />
                }

            </Spin>
        </Drawer>
    );
};

export default EstablishmentListDrawer;