import { Button, Col, Image, InputNumber, message, Modal, Row, Table, TablePaginationConfig } from 'antd';
import Tooltip from 'antd/es/tooltip';
import React, { useRef, useState } from 'react';
import { BsCartPlusFill } from 'react-icons/bs';
import { useAppDispatch } from '../../../../../hooks';
import InputQuantityUpdateForm from '../../../../cart/InputQuantityUpdateCart';
import { SkuInfo } from '../../../../orders/layer/details/hubsAndOutlets/utils';
import { PurchaseOrderCartItem } from './PurchaseOrderCartItem';
import { updatePurchaseOrderCartItem } from './PurchaseOrderCartSlice';

interface ProductListForCreatePOProps {
    dataSource: PurchaseOrderCartItem[];
    pagination: false | TablePaginationConfig;
    styleClass?: string;
    height: string;
}

export const PurchaseOrderProductList = (props: ProductListForCreatePOProps) => {
    const { dataSource, pagination, styleClass, height } = props;
    const bulkInputQuantityRef = useRef<HTMLInputElement>(null);
    const [bulkInputQuantity, setBulkInputQuantity] = useState<number>();
    const [showBulkInputQuantityModal, setShowBulkInputQuantityModal] = useState<boolean>();
    const dispatch = useAppDispatch();

    function updateCart(item: PurchaseOrderCartItem, quantity: number) {
        item = new PurchaseOrderCartItem(item);
        item.setQuantityValue!(quantity);
        dispatch(
            updatePurchaseOrderCartItem({
                cartReferenceId: item.reference_id!,
                cartItem: item,
            }),
        );
    }

    function bulkInputQuantityIconOnClick() {
        setShowBulkInputQuantityModal(true);
    }

    function resetBulkQuantityParameters() {
        setBulkInputQuantity(undefined);
        setShowBulkInputQuantityModal(false);
    }

    function addAllItemsToCart() {
        if (!bulkInputQuantity || bulkInputQuantity <= 0) {
            return;
        }

        dataSource.forEach((item: PurchaseOrderCartItem) => {
            updateCart(item, bulkInputQuantity!);
        });
        resetBulkQuantityParameters();
        message.success(`${dataSource.length} product(s) have been added/updated in the cart with quantity as ${bulkInputQuantity} each`,
            4);
    }

    const QuantityTitle = () => {
        return (
            <div
                style={{ cursor: 'pointer' }}
                onClick={bulkInputQuantityIconOnClick}
                className="flex-align-center"
            >
                <Tooltip title='Click to add in bulk' >
                    <span className="padding-right-5px">Quantity</span>
                </Tooltip>
                <BsCartPlusFill
                    color="#7E4D2B"
                    size={15}
                />
            </div>

        );
    };

    const productColumns = [
        {
            title: 'Image',
            dataIndex: 'image',
            render: (image) => <Image width={30} height={30} src={image} />,
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            render: (brand) => <label>{brand}</label>,
        },
        {
            title: 'Name',
            render: (_, item: PurchaseOrderCartItem) => <SkuInfo name={item.name} sku={item.ean} />,
        },
        {
            title: <QuantityTitle />,
            render: (_, item: PurchaseOrderCartItem) => (
                <InputQuantityUpdateForm item={item} updateCart={updateCart} />
            ),
        },
    ];

    return (
        <>
            <Table
                columns={productColumns}
                dataSource={dataSource}
                scroll={{ x: '450px', y: height }}
                className={styleClass}
                bordered
                pagination={pagination}
            />
            <Modal
                className=""
                title={
                    <h5>
                        All products in the page will be added to the cart with the input quantity
                    </h5>
                }
                open={showBulkInputQuantityModal}
                onCancel={resetBulkQuantityParameters}
                footer={<></>}
                maskClosable={false}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={12}>
                        <InputNumber
                            ref={bulkInputQuantityRef}
                            style={{ float: 'right' }}
                            controls={false}
                            placeholder="Quantity"
                            min={1}
                            max={999}
                            value={bulkInputQuantity}
                            onChange={(quantity) => setBulkInputQuantity(quantity)}
                            onPressEnter={addAllItemsToCart}
                        />
                    </Col>
                    <Col xs={12}>
                        <Button
                            className="default-button"
                            onClick={addAllItemsToCart}
                            disabled={!bulkInputQuantity || bulkInputQuantity <= 0}
                        >
                            Apply
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
