import 'antd/dist/antd.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../HomePage';
import AppLayout from '../layout';
import ErrorResult from '../errorHandling/ErrorResult';
import ApplicantListBody from '../applicant/ApplicantListBody';
import ApplicantDetailsBody from '../applicant/ApplicantDetailsBody';
import ProtectedRoute from './ProtectedRoute';
import { Path } from './Path';
import { EstablishmentSaveMode } from '../../types/EstablishmentSaveMode';
import EstablishmentDetailsLayer from '../establishments/EstablishmentDetailsLayer';
import FinanceLayer from '../finance/FinanceLayer';
import { InventoryLayer } from '../inventory/InventoryLayer';
import { Analytics } from '../analytics/Analytics';
import CreateOrder from '../cart/CreateOrder';
import { EDIT } from '../../types/OrderCreationMode';
import UserListing from '../user/listing';
import { EstablishmentType } from '../../types/EstablishmentType';
import { OrderListing } from '../orders/layer/listing/hubsAndOutlets';
import { PurchaseOrderListing } from '../purchaseOrder/layer/listing';
import { PurchaseOrderLayer } from '../purchaseOrder/layer';
import { OrderLayer } from '../orders/layer';
import { OrderDetails } from '../orders/layer/details/hubsAndOutlets';
import { SellerHubOrderListing } from '../orders/layer/listing/sellerHubs';
import { PurchaseOrderDetails } from '../purchaseOrder/layer/details';
import { Login } from '../authentication/login';
import { VerifyLogin } from '../authentication/login/verify';
import { EstablishmentListing } from '../establishments/layer/listing';
import { EstablishmentLayer } from '../establishments/layer';
import { CreateEstablishment } from '../establishments/layer/details/information';
import { UserProfile } from '../user/profile';
import { Category } from '../category';
import { ReturnsListing } from '../returns';
import { ReturnOrderDetails } from '../returns/details';

function AppRoutes() {
    return (
        <Routes>
            <Route path={Path.HOME} element={<ProtectedRoute />}>
                <Route path={Path.HOME} element={<HomePage />} />
                <Route path={Path.ANALYTICS} element={<AppLayout content={<Analytics />} />} />

                <Route path={Path.APPLICANTS} element={<AppLayout content={<ApplicantListBody />} />} />
                <Route path={Path.APPLICANTS_DETAIL} element={<AppLayout content={<ApplicantDetailsBody />} />} />

                {/****************************************  Orders  ****************************************/}

                <Route path={Path.ORDER_LIST} element={<AppLayout content={<OrderLayer />} />} />
                <Route path={Path.HUB_ORDER_LIST} element={
                    // Added a key attribute to <OrderListing /> component so that we can distinguish between sibling components and create a new instance.
                    <AppLayout content={<><OrderListing key={EstablishmentType.HUB.type} type={EstablishmentType.HUB.type} /></>} />
                } />
                <Route path={Path.OUTLET_ORDER_LIST} element={
                    <AppLayout content={<><OrderListing key={EstablishmentType.OUTLET.type} type={EstablishmentType.OUTLET.type} /></>} />
                } />
                <Route path={Path.SELLER_HUB_ORDER_LIST} element={<AppLayout content={<SellerHubOrderListing />} />} />
                <Route path={Path.ORDER_DETAILS} element={<AppLayout content={<OrderDetails />} />} />
                <Route path={Path.ORDER_EDIT} element={<AppLayout content={<CreateOrder mode={EDIT} />} />} />

                {/**************************************  Purchar Order  **************************************/}

                <Route path={Path.PURCHASE_ORDER_LIST} element={<AppLayout content={<PurchaseOrderLayer />} />} />

                <Route path={Path.HUB_PURCHASE_ORDER_LIST} element={
                    // Added a key attribute to <PurchaseOrderListing /> component so that we can distinguish between sibling components and create a new instance.
                    <AppLayout content={<PurchaseOrderListing key={EstablishmentType.HUB.type} type={EstablishmentType.HUB} />} />
                }
                />
                <Route path={Path.SELLER_HUB_PURCHASE_ORDER_LIST} element={
                    <AppLayout content={<PurchaseOrderListing key={EstablishmentType.OUTLET.type} type={EstablishmentType.SELLER_HUB} />} />
                }
                />
                <Route
                    path={Path.PURCHASE_ORDER_DETAILS}
                    element={<AppLayout content={<PurchaseOrderDetails />} />}
                />

                {/**************************************  Establishments  **************************************/}

                <Route path={Path.ESTABLISHMENTS} element={<AppLayout content={<EstablishmentLayer />} />} />
                <Route path={Path.HUB_ESTABLISHMENT_LIST} element={<AppLayout content={<EstablishmentListing type={EstablishmentType.HUB} />} />} />
                <Route path={Path.OUTLET_ESTABLISHMENT_LIST} element={<AppLayout content={<EstablishmentListing type={EstablishmentType.OUTLET} />} />} />
                <Route path={Path.SELLER_HUB_ESTABLISHMENT_LIST} element={<AppLayout content={<EstablishmentListing type={EstablishmentType.SELLER_HUB} />} />} />
                <Route
                    path={Path.CREATE_ESTABLISHMENTS}
                    element={<AppLayout content={<CreateEstablishment mode={EstablishmentSaveMode.CREATE} />} />}
                />
                <Route
                    path={Path.ESTABLISHMENT_DETAILS}
                    element={<AppLayout content={<EstablishmentDetailsLayer />} />}
                />
                {/**************************************  Finance  **************************************/}

                <Route path={Path.FINANCE} element={<AppLayout content={<FinanceLayer />} />} />

                {/**************************************  Inventory  **************************************/}

                <Route path={Path.INVENTORY} element={<AppLayout content={<InventoryLayer />} />} />

                {/**************************************  Category  **************************************/}

                <Route path={Path.CATEGORY} element={<AppLayout content={<Category />} />} />

                {/**************************************  Returns  **************************************/}

                <Route path={Path.RETURNS} element={<AppLayout content={<ReturnsListing />} />} />
                <Route path={Path.RETURN_DETAILS} element={<AppLayout content={<ReturnOrderDetails />} />} />

                {/**************************************  Users  **************************************/}

                <Route path={Path.USER_LISTING} element={<AppLayout content={<UserListing active={true} />} />} />
                <Route path={Path.USER_PROFILE} element={<AppLayout content={<UserProfile />} />} />
            </Route>

            {/**************************************  Login  **************************************/}

            <Route path={Path.LOGIN} element={<Login />} />
            <Route path={Path.LOGIN_VERIFY} element={<VerifyLogin />} />

            <Route path="*" element={<ErrorResult />} />
        </Routes>
    );
}

export default AppRoutes;
