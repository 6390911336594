import { Button, Divider, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { LIST_BRANDS } from '../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../http/HttpClient';
import { style } from '../../styles/css/style';
import { SearchInput } from '../common/SearchInput';
import { Brand, BrandListResponse } from '../orders/listing/ListingData';
import { BrandListSelectProps } from './types';

export const SelectBrandList = (props: BrandListSelectProps) => {

    // Items per page. 
    const SIZE = 5;

    // State variables
    const firstRender = useRef(true);
    const [page, setPage] = useState<number>(0);
    const [brandResponse, setBrandResponse] = useState<BrandListResponse>();
    const [fetchBrands, setFetchBrands] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [brandName, setBrandName] = useState<string>();

    // Methods
    // GET BRAND {{API CALL}}
    const getBrands = () => {
        if (props.setLoading) {
            props.setLoading(true);
        }
        setLoading(true);
        const endPoint = LIST_BRANDS(props.eUuid, brandName!, page, SIZE);

        const responseHandler: ResponseHandler<BrandListResponse> = {
            onResponseSuccess(response: BrandListResponse): void {

                // Selected brand shouldn't get undefined while rendering for the first time.
                if (firstRender.current) {
                    firstRender.current = false;
                } else {
                    props.setSelectedBrand(undefined);
                }

                if (brandResponse === undefined) {
                    setBrandResponse(response); // Replace with the whole new response.
                } else {
                    const finalBrandList = brandResponse.brands.concat(response.brands);
                    // Append a new brand list from the response to the existing brandList.
                    setBrandResponse({ ...response, brands: finalBrandList });
                }


                if (props.setLoading) {
                    props.setLoading(false);
                }
                setLoading(false);

            },
            onResponseFailed(): void {
                if (props.setLoading) {
                    props.setLoading(false);
                }
                setLoading(false);
            },
            onResponseError(): void {
                if (props.setLoading) {
                    props.setLoading(false);
                }
                setLoading(false);
            },
        };

        getApiCall(endPoint, responseHandler);
    };

    const getValue = () => {
        if (props.mode === 'multiple' && Array.isArray(props.selectedBrand)) {
            // TODO naming convention
            return (props.selectedBrand as Brand[])?.map((eachBrand) => eachBrand.id) ?? [];
        } else if (!Array.isArray(props.selectedBrand)) {
            return props.selectedBrand?.id;
        }
    };

    const fetchBrandList = () => {
        setFetchBrands(prevValue => !prevValue);
    };

    // useEffects
    useEffect(() => {
        if (props.eUuid) {
            // Clearing the data which is present in `setBrandResponse` to render new brand list.
            setBrandResponse(undefined);
            setBrandName(undefined);
            if (page !== 0) {
                setPage(0);
            } else {
                fetchBrandList();
            }
        }
    }, [props.eUuid]);

    useEffect(() => {
        if (props.eUuid) {
            fetchBrandList();
        }
    }, [page]);

    useEffect(() => {
        if (props.eUuid) {
            getBrands();
        }
    }, [fetchBrands]);


    return (
        <>
            <Select
                {...props}
                loading={loading}
                value={getValue()}
                placeholder='Select Brand'
                onChange={(value) => {
                    if (props.mode === 'multiple') {
                        props.setSelectedBrand(brandResponse?.brands.filter(brand => value.includes(brand.id)));
                    } else {
                        props.setSelectedBrand(brandResponse?.brands.find(brand => brand.id === value));
                    }

                }}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider className='mt-2 mb-1' />

                        <div className='p-2'>
                            {
                                brandResponse?.next_page &&
                                <Button
                                    className={`${style.meraaiPlainButton} mb-2`}
                                    disabled={!brandResponse?.next_page || loading}
                                    onClick={() => {
                                        if (brandResponse?.next_page) {
                                            setPage(prevPage => prevPage + 1);
                                        }
                                    }}>
                                    Load more
                                </Button>
                            }

                            <SearchInput
                                value={brandName}
                                enableAutoSearch
                                hideSearchButton
                                searchButtonOnClick={() => {
                                    if (props.eUuid) {
                                        setBrandResponse(undefined);

                                        if (page !== 0) {
                                            setPage(0);
                                        } else {
                                            fetchBrandList();

                                        }
                                    }
                                }}
                                setSearchText={setBrandName}
                                searchPlaceholder={'Search brand'}
                            />
                        </div>
                    </>
                )}
            >
                {
                    loading ?

                        <Select.Option
                            className='select-none text-meraai-orange font-semibold text-center'
                        >
                            Loading...
                        </Select.Option>

                        :
                        brandResponse?.brands?.map((brand, index) => <>
                            <Select.Option
                                key={index}
                                value={brand.id}
                                label={brand.name}
                            >
                                {brand.name}

                            </Select.Option>
                        </>)
                }
            </Select>
            <span className='text-sm mx-2 text-red-500'>
                {brandResponse?.brands.length === 0 && 'No brands available.'}
            </span>
        </>
    );
};