import React from 'react';
import { Establishment, StateMetaDatum } from '../Types';
import { Datum } from './Datum';
import { PurchaseOrderState } from './PurchaseOrderState';
import { TenderStatus } from './Tender';

export type PurchaseOrderDetailResponse = {
    invoice_no: string;
    uuid: string;
    establishment: Establishment;
    hub: Establishment;
    items: PurchaseOrderItem[];
    state: string;
    amount: number;
    manual?: boolean;
    message: string;
    ordered_amount: number;
    accepted_amount: number;
    dispatched_amount: number;
    received_amount: number;
    payment_status: string;
    payment_status_metadata: StateMetaDatum[];
    state_metadata: StateMetaDatum[];
    valid_next_states: string[];
};

export type PurchaseOrderItem = {
    accepted_quantity: number;
    brand_name: string;
    ean: string;
    id: number;
    image_url: string;
    name: string;
    order_quantity_multiple?: number;
    ordered_quantity: number;
    availableQuantity: number;
    price_per_unit: number;
    quantity: number;
    sku: string;
    total_amount: number;
};

// Purchase Order List

export type PurchaseOrderListResponse = {
    purchase_orders: SummarisedPurchaseOrders[];
    page_count: number;
    page_size: number;
    next_page: boolean;
};

export type SummarisedPurchaseOrders = {
    invoice_no: string;
    uuid: string;
    establishment: Establishment;
    hub: Establishment;
    state: string;
    manual: boolean;
    created_at: string;
    amount: number;
    payment_status: string;
};

export interface OrderStateTimelineDetails {
    color?: string | undefined,
    icon?: React.ReactNode,
    display: React.ReactNode,
    amountKey: string,
    state: PurchaseOrderState,
    stateMetadata?: StateMetaDatum,
}

export interface BrandsAggregateQunatity { 
    key: number, 
    brand_name: string, 
    quantity: any 
}

export enum PurchaseOrderCreateType {
    CUSTOM = 'Custom placed',
    AGGREGATE = 'Placed via aggregate',
}

export interface UpdatePurchaseOrderRequest {
    state_request?: UpdatePurchaseOrderStateRequest;
    payment?: {
        status: TenderStatus,
        data: Datum[]
    }
    
}

export interface UpdatePurchaseOrderStateRequest {
    state: string;
    data: Datum[];
    items?: UpdatePurchaseOrderItem[];
}

export interface UpdatePurchaseOrderItem {
    item_id: number;
    quantity?: number;
}