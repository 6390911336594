import { Button, Col, Empty, Modal, Row, Skeleton, Spin } from 'antd';
import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TiShoppingCart } from 'react-icons/ti';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { IoTrashBin } from 'react-icons/io5';
import { PurchaseOrderProductList } from './PurchaseOrderProductList';
import { CREATE_PURCHASE_ORDER } from '../../../../../http/EndPoints';
import { postApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { clearPurchaseOrderCart } from './PurchaseOrderCartSlice';
import { PurchaseOrderDetailResponse } from '../../../../../types/PurchaseOrders';
import { PurchaseOrderCartItem } from './PurchaseOrderCartItem';

interface PurchaseOrderCartProps {
    sellerHubUuid: string;
    hubUuid: string;
    productsLoading: boolean;
    cartModalVisible: boolean;
    cartReferenceId: string;
    setCartModalVisible: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setBrandsDrawerVisibility: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}
type PurchaseOrderInfo = {
    product_id: number;
    quantity: number;
};
interface CreatePurchaseOrderRequestBody {
    seller_hub_uuid: string,
    hub_uuid: string,
    products: PurchaseOrderInfo[],
}

function getCartOrderItems(cartItems: Array<PurchaseOrderCartItem>): PurchaseOrderInfo[] {
    return cartItems.map(item => {
        return { product_id: item.id!, quantity: item.quantity! };
    });
}

export const PurchaseOrderCart = (props: PurchaseOrderCartProps) => {

    // Hooks
    const [purchaseOrderCreationInProgress, setPurchaseOrderCreationInProgress] = useState<boolean>(false);
    const [postPurchaseOrderCreationModalVisibility, setPostPurchaseOrderCreationModalVisibility] = useState<boolean>(false);
    const [purchaseOrderResponse, setPurchaseOrderResponse] = useState<PurchaseOrderDetailResponse>();

    const dispatch = useAppDispatch();
    const cartData = useAppSelector(state => state.purchaseOrderCart.cart[props.cartReferenceId]) ?? {
        selectedItems: [],
        selectedItemsCount: 0,
        lastUpdatedAt: 0,
    };


    //Method
    const performClearCart = () => {
        Modal.confirm({
            title: 'Delete all items ?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action will clear your cart items.',
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => {
                props.setCartModalVisible(false);
                dispatch(clearPurchaseOrderCart(props.cartReferenceId));
            },
        });
    };


    const createPurchaseOrder = async () => {
        setPurchaseOrderCreationInProgress(true);

        const purchaseOrderRequestBody: CreatePurchaseOrderRequestBody = {
            seller_hub_uuid: props.sellerHubUuid,
            hub_uuid: props.hubUuid,
            products: getCartOrderItems(cartData.selectedItems),
        };

        const responseHandler: ResponseHandler<PurchaseOrderDetailResponse> = {
            onResponseSuccess(value: PurchaseOrderDetailResponse): void {
                setPurchaseOrderResponse(value);
                props.setCartModalVisible(false);
                setPurchaseOrderCreationInProgress(false);
                setPostPurchaseOrderCreationModalVisibility(true);
                dispatch(clearPurchaseOrderCart(props.cartReferenceId));
            },
            onResponseFailed(): void {
                setPurchaseOrderCreationInProgress(false);
            },
            onResponseError(): void {
                setPurchaseOrderCreationInProgress(false);
            },
        };

        await postApiCall(CREATE_PURCHASE_ORDER, purchaseOrderRequestBody, responseHandler);
    };


    const getFooter = () => {
        return (<div>
            <Button
                onClick={() => {
                    performClearCart();
                }}
                disabled={cartData.selectedItems.length == 0}>
                <div className="flex-align-center">
                    <IoTrashBin /> &ensp; Clear Cart
                </div>
            </Button>
            <Button
                className="default-button align-right"
                onClick={() => {
                    createPurchaseOrder();
                }}
                disabled={cartData.selectedItems.length == 0}>
                Create Purchase Order
            </Button>
        </div>);
    };

    return (
        <>

            <Modal
                className="responsive-modal"
                title={
                    <Row gutter={[12, 12]} className="flex-center flex-column-mid">
                        <Col xs={24} sm={12} className="flex-align-center">
                            <TiShoppingCart /> &ensp; <span className="bold-label">Cart</span>
                        </Col>
                    </Row>
                }
                open={props.cartModalVisible}
                onCancel={() => {
                    props.setCartModalVisible(false);
                }}
                onOk={() => props.setCartModalVisible(false)}
                maskClosable={false}
                footer={getFooter()}>
                {
                    props.productsLoading ? <Skeleton active /> : <Spin spinning={purchaseOrderCreationInProgress} tip="Loading..." size="small">
                        <Row>
                            <Col xs={24}>
                                {cartData.selectedItems.length <= 0 ? (
                                    <Empty
                                        style={{ height: '150px' }}
                                        description={
                                            <>
                                                <h3 style={{ color: 'red' }}>Oops! Your cart is empty!</h3>
                                                <div className="flex-column-mid">
                                                    <label>Looks like you haven't added anything to your cart yet</label>
                                                </div>
                                            </>
                                        }
                                        className="meraai-border"
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                ) : (
                                    <PurchaseOrderProductList
                                        dataSource={cartData.selectedItems}
                                        pagination={false}
                                        styleClass="meraai-border"
                                        height="35vh"
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="flex-row-sb-center padding-top-10px padding-left-50percent padding-right-10px">
                            <Col>
                                <span className="item-total-text">No of items :</span>
                            </Col>
                            <Col>
                                <span className="bold-label">{`x ${cartData.selectedItemsCount}`}</span>
                            </Col>
                        </Row>
                    </Spin>
                }
            </Modal>

            {
                // After the order is created successfully popup this modal
                purchaseOrderResponse && (
                    <Modal
                        className="success-modal"
                        open={postPurchaseOrderCreationModalVisibility}
                        title={<h3 className="title">Purchase Order placed successfully. </h3>}
                        onCancel={() => {
                            setPostPurchaseOrderCreationModalVisibility(false);
                        }}
                        footer={[
                            <Button
                                onClick={() => {
                                    props.setCartModalVisible(false);
                                    setPostPurchaseOrderCreationModalVisibility(false);
                                }}>
                                Create a new one
                            </Button>,
                            <Button type="primary" target='_blank' href={`/purchase-order/${purchaseOrderResponse?.uuid}`}>
                                View details
                            </Button>,
                        ]}
                        maskClosable={false}>
                        <label className="content">
                            - The Purchase order no. is <span>{`${purchaseOrderResponse?.invoice_no}`}</span>
                        </label>
                    </Modal>
                )
            }

        </>
    );
};