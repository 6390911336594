import { Button, Col, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { EstablishmentsDropDown, EstablishmentSelectionMode, getEncodedStringFromUuidList, getUuidListFromEncodedString } from '../../../common/establishments/EstablishmentsDropDown';
import { SellerHubOrderProps } from './types';
import { PurchaseOrderState } from '../../../../types/PurchaseOrderState';
import { DataNotFound } from '../../../common/templates/dataNotFound';
import { PageBreadcrumbHeader } from '../../../common/pageBreadcrumbHeader';
import TimeFilterPicker from '../../../common/TimeFilterPicker';
import { PurchaseOrderCreateType, PurchaseOrderListResponse, SummarisedPurchaseOrders } from '../../../../types/PurchaseOrders';
import { addKeyToDataList, formatCurrency, isArrayEmpty } from '../../../../Utils';
import { EstablishmentType } from '../../../../types/EstablishmentType';
import { FcInfo } from 'react-icons/fc';
import { getISTFromEpoch } from '../../../../utilities/Date';
import { useNavigate } from 'react-router-dom';
import { getApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { PURCHASE_ORDER_LIST } from '../../../../http/EndPoints';
import { layout, style } from '../../../../styles/css/style';

import { useAppSelector } from '../../../../hooks';
import { Device, MeraaiTableColumnType } from '../../../common/templates/meraaiTable/types';
import { ListingPage } from '../../../common/templates/listingPage';
import { MeraaiTable } from '../../../common/templates/meraaiTable';


export const PurchaseOrderListing = (props: SellerHubOrderProps) => {
    const isMobileDevice = useAppSelector((state) => state.sideMenuCollapsed.collapsed);

    // States
    const navigate = useNavigate();
    const isRendered = useRef<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [establishmentUuids, setEstablishmentUuids] = useState<string[]>();

    const [enableDateFilterPicker, setEnableDateFilterPicker] = useState<boolean>(false);
    const [purchaseOrderRequested, setPurchaseOrderRequested] = useState<boolean>(false);

    // Message 
    const [resultMessages, setResultMessages] = useState('Search for the data using filters mentioned above.');

    // DateTime 
    const [fromDate, setFromDate] = useState<number>();
    const [toDate, setToDate] = useState<number>();

    // Pagination
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState(10);

    const [selectedPOState, setSelectedPOState] = useState<PurchaseOrderState>();
    const [purchaseOrderResponse, setPurchaseOrderResponse] = useState<PurchaseOrderListResponse>();


    // START: URL search params
    const setQueryParamsFromState = (queryParams: URLSearchParams, param: string, state) => {
        if (state) {
            if (param == 'state') {
                if (!PurchaseOrderState.valueOf(state)?.enableDateFilterPicker) {
                    if (queryParams.has('from-date') || queryParams.has('to-date')) {
                        queryParams.delete('from-date');
                        queryParams.delete('to-date');
                    }
                }
            }
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                } else {
                    queryParams.delete(param);
                }
            } else {
                queryParams.set(param, state);
            }
        }
    };
    const setStateFromQueryParams = (queryParams: URLSearchParams, param: string, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const pageNumber = parseInt(queryParams.get(param)!);
            stateSetter(pageNumber);
        } else if (param == 'state') {
            stateSetter(PurchaseOrderState.valueOf(queryParams.get(param)!));
        } else {
            stateSetter(queryParams.get(param));
        }
    };
    const setStateFromURLParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'state', setSelectedPOState);
        setStateFromQueryParams(queryParams, 'page', setPage);
    };
    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'state', selectedPOState?.state);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'page', page);

        /**
          * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
          * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
          * So we are replacing the page with the help of replace property of navigate method.
        * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };
    // END: URL search params    


    // Methods
    const isPurchasrOrderListingAllowed = (): boolean => {
        return !(
            establishmentUuids && establishmentUuids.length > 0 &&
            selectedPOState &&
            (!selectedPOState.enableDateFilterPicker || (fromDate && toDate))
        );
    };

    const onPurchaseOrderStateChange = (state: string) => {
        const purchaseOrderState: PurchaseOrderState = PurchaseOrderState.valueOf(state)!;
        setFromDate(undefined);
        setToDate(undefined);
        setSelectedPOState(purchaseOrderState);
        setEnableDateFilterPicker(purchaseOrderState.enableDateFilterPicker);
    };

    // API CALL - Get purchase orders
    const getPurchaseOrders = () => {
        setLoading(true);
        const endpoint = PURCHASE_ORDER_LIST(PurchaseOrderState.valueOf(selectedPOState?.state!), establishmentUuids!, fromDate, toDate, page - 1, pageSize);

        const responseHandler: ResponseHandler<PurchaseOrderListResponse> = {
            onResponseSuccess: (response: PurchaseOrderListResponse) => {
                if (isArrayEmpty(response.purchase_orders.length)) {
                    setResultMessages('No purchase orders found');
                }
                setPurchaseOrderResponse(response);
                setPageSize(response.page_size);
                setLoading(false);
            },
            onResponseFailed: () => {
                setResultMessages('Couldn\'t get the purchase orders. Please try again in some time.');
                setLoading(false);
            },
            onResponseError: () => {
                setLoading(false);
            },
        };

        getApiCall(endpoint, responseHandler);
    };

    const getPurchaseOrderAndSetUrlParams = () => {
        getPurchaseOrders();
        setUrlParamsFromState();
    };


    // Table Columns
    const purchaseOrdersColumn: MeraaiTableColumnType[] = [
        {
            title: 'Invoice no.',
            rowVisibility: [Device.DESKTOP],
            render: (_, purchaseOrder: SummarisedPurchaseOrders) => <div className='flex-align-.center'>
                <label>{purchaseOrder.invoice_no}</label>  &ensp;
                {
                    purchaseOrder?.manual && <Tooltip title={PurchaseOrderCreateType.CUSTOM}>
                        <FcInfo
                            cursor={'pointer'}
                        />
                    </Tooltip>
                }

            </div>,
        },
        {
            title: props.type === EstablishmentType.HUB ? 'Seller Hub' : 'Hub',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, purchaseOrder: SummarisedPurchaseOrders) =>
                props.type === EstablishmentType.HUB ? (
                    <span> {purchaseOrder.establishment.name} </span>
                ) : (
                    <span> {purchaseOrder.hub.name} </span>
                ),
        },
        {
            title: 'Amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'amount',
            render: amount => formatCurrency(amount),
        },
        {
            title: 'Created at',
            rowVisibility: [Device.DESKTOP],
            render: (_, purchaseOrder: SummarisedPurchaseOrders) => (
                <div className="body-div-tag">
                    <span>{getISTFromEpoch(parseInt(purchaseOrder.created_at))}</span>
                </div>
            ),
        },
        {
            title: isMobileDevice ? '' : 'Action',
            dataIndex: 'uuid',
            mobileWidth: 'FULL',
            rowVisibility: [Device.DESKTOP],
            render: (uuid) => <div className={`${layout.flex.center}`}>
                <Button
                    className={`w-full xs:w-1/2 sm:w-1/3 lg:w-full ${style.meraaiOrangeButton}`}
                    onClick={() => {
                        navigate(`/purchase-order/${uuid}`);
                    }}
                >
                    {isMobileDevice ? 'View Order Details' : 'View'}
                </Button>
            </div>,
        },
    ];

    // UseEffects

    useEffect(() => {
        //If the establishmentUuid is set, it signifies that something is present in the state.
        if (establishmentUuids) {
            setUrlParamsFromState();
        } else {
            setStateFromURLParams();
        }
        setEnableDateFilterPicker(selectedPOState?.enableDateFilterPicker!);
    }, [establishmentUuids]);

    //This runs whenever a page change happens.
    useEffect(() => {
        if (isRendered.current) {
            getPurchaseOrderAndSetUrlParams();
        } else {
            isRendered.current = true;
        }
    }, [page]);

    useEffect(() => {
        if (!purchaseOrderRequested) {
            return;
        }
        if (page !== 1) {
            setPage(1);
        } else {
            getPurchaseOrderAndSetUrlParams();
        }
        setPurchaseOrderRequested(false);
    }, [purchaseOrderRequested]);

    return (

        <ListingPage
            breadcrumb={
                <>
                    <PageBreadcrumbHeader items={[
                        'Purchase Order',
                        props?.type?.display,
                    ]} />
                </>
            }
            header={
                <>
                    <Row gutter={[12, 12]}>
                        <Col className='w-1/2 lg:w-1/3 xl:w-1/5'>
                            <EstablishmentsDropDown
                                setLoading={setLoading}
                                type={props.type}
                                setSelectedUuids={setEstablishmentUuids}
                                selectedUuids={establishmentUuids}
                                selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                                fetchEstablishmentList={true}
                            />
                        </Col>
                        <Col className='w-1/2 lg:w-1/3 xl:w-1/5'>
                            <Select
                                value={selectedPOState ? selectedPOState.state : undefined}
                                placeholder='Select state'
                                className='w-full'
                                onChange={onPurchaseOrderStateChange}>
                                {
                                    Object.values(PurchaseOrderState).map((state, index) => <Select.Option key={index} value={state.state}>
                                        {state.display}
                                    </Select.Option>)
                                }
                            </Select>
                        </Col>
                        <Col className='w-full xs:w-1/2 sm:w-2/3 lg:w-1/3 xl:w-2/5'>
                            <TimeFilterPicker
                                enableFilterPicker={enableDateFilterPicker}
                                fromDateEpoch={fromDate!}
                                toDateEpoch={toDate!}
                                setFromDateEpoch={setFromDate}
                                setToDateEpoch={setToDate}
                            />
                        </Col>
                        <Col className={`w-full xs:w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 ${layout.flex.center}`}>
                            <Button
                                className={`w-full ${style.meraaiOrangeButton}`}
                                size="large"
                                onClick={() => {
                                    setPurchaseOrderRequested(true);
                                }}
                                disabled={isPurchasrOrderListingAllowed()}>
                                Get Purchase Orders
                            </Button>
                        </Col>
                    </Row>
                </>
            }
            body={
                <>
                    {
                        purchaseOrderResponse && purchaseOrderResponse.purchase_orders.some(purchaseOrder => purchaseOrder.state === selectedPOState?.state) ?

                            <MeraaiTable
                                loading={loading}
                                columns={purchaseOrdersColumn}
                                dataSource={addKeyToDataList(purchaseOrderResponse?.purchase_orders)}
                                pagination={{
                                    pageSize: purchaseOrderResponse.page_size,
                                    current: page,
                                    total: purchaseOrderResponse.page_count * purchaseOrderResponse.page_size,
                                    onChange: (pageNo, size) => {
                                        setLoading(true);
                                        setPage(pageNo);
                                        setPageSize(size);
                                        if (page === pageNo) {
                                            setPurchaseOrderRequested(true);
                                        }
                                    },
                                }}
                            />

                            :

                            <DataNotFound loading={loading} title={resultMessages} />
                    }
                </>
            }
        />
    );
};