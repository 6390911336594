/* eslint @typescript-eslint/no-use-before-define: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */

import { Tabs, TabsProps } from 'antd';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isArrayEmpty } from '../../../Utils';
import TabRenderer from '../TabRenderer';

const { TabPane } = Tabs;

export interface MeraaiTabsProps extends TabsProps {
    tabs: MeraaiTab[];
    hideDefaultStyle?: boolean;
}

export interface MeraaiTab {
    key: string;
    name: string;
    accessible: boolean;
    component?: (active: boolean) => ReactElement;
    subTabs?: MeraaiTab[];
}

function getTabName(meraaiTab: MeraaiTab) {
    const allowedSubTabs = meraaiTab.subTabs?.filter(subtab => subtab.accessible);
    if (allowedSubTabs == undefined || allowedSubTabs.length > 1) {
        return meraaiTab.name;
    }
    return `${meraaiTab.name} (${allowedSubTabs.at(0)?.name})`;
}

interface TabSubTab {
    tabKey: string;
    subTabKey: string;
}

/**
 * Inherits all the props supported by Tabs
 * @param props
 * @returns
 */
export function MeraaiTabs(props: MeraaiTabsProps) {
    const rendered = useRef<boolean>();
    const [activeTabKey, setActiveTabKey] = useState<string>();

    /**
     * Used to pull variables off props, and put the remaining props into a `rest` variable.
     * https://reactjs.org/warnings/unknown-prop.html
     * */
    const { hideDefaultStyle, ...rest } = props;


    /**
     * If there are no subtabs, this would be an array of undefined
     */
    const [tabWiseActiveSubTab, setTabWiseActiveSubTab] = useState<(TabSubTab | undefined)[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const onTabChange = activeKey => {
        setActiveTabKey(activeKey);
    };

    const initializeActiveTab = (type?: string) => {
        const activeKey =
            props.tabs.find(tab => tab.key === type && tab.accessible)?.key ||
            props.tabs.find(tab => tab.accessible)?.key;

        if (activeKey) {
            setActiveTabKey(activeKey);
        }
    };

    /**
     * Add the first accessible subTab that it finds for each tab in the tabWiseActiveSubTab list.
     */
    const initializeActiveSubTabForAllTabs = (activeTabFromUrl: string | null, activeSubTabFromUrl: string | null) => {
        const tabWiseActiveSubTab: (TabSubTab | undefined)[] = props.tabs
            .filter(tab => tab.accessible)
            .map(tab => {
                if (tab.subTabs) {
                    let defaultActiveSubTabKey = tab.subTabs.find(subTab => subTab.accessible)?.key;
                    if (
                        activeTabFromUrl &&
                        tab.key === activeTabFromUrl &&
                        activeSubTabFromUrl &&
                        tab.subTabs.some(subTab => subTab.accessible && subTab.key === activeSubTabFromUrl)
                    ) {
                        defaultActiveSubTabKey = activeSubTabFromUrl;
                    }

                    return {
                        tabKey: tab.key,
                        subTabKey: defaultActiveSubTabKey,
                    } as TabSubTab;
                }
            })
            .filter(tabSubTab => tabSubTab);

        if (tabWiseActiveSubTab.length > 0) {
            setTabWiseActiveSubTab(tabWiseActiveSubTab);
        }
    };

    const setActiveSubTab = (tabKey: string, subTabKey: string, updateUrl: boolean) => {
        if (tabWiseActiveSubTab.length > 0) {
            setTabWiseActiveSubTab([
                ...tabWiseActiveSubTab.map(tabSubTab => {
                    if (tabSubTab?.tabKey == tabKey) {
                        tabSubTab.subTabKey = subTabKey;
                    }
                    return tabSubTab;
                }),
            ]);
            if (updateUrl) {
                updateWindowUrl(tabKey, subTabKey);
            }
        }
    };

    const getActiveSubTabForTab = (tabKey: string) => {
        return tabWiseActiveSubTab?.find(tabSubTab => tabSubTab?.tabKey == tabKey)?.subTabKey!;
    };

    const updateWindowUrl = (tabKey: string, subTabKey?: string, replace?: boolean) => {
        if (!tabKey) {
            return;
        }
        location.search = '';
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('type', tabKey);
        if (!subTabKey) {
            subTabKey = tabWiseActiveSubTab?.find(tabSubTab => tabSubTab?.tabKey === tabKey)?.subTabKey;
        }
        if (subTabKey) {
            queryParams.set('sub-type', subTabKey);
        }

        navigate(`?${queryParams.toString()}`, { replace: replace });
    };

    const getTabStyleClass = (): string => {
        return hideDefaultStyle ? '' : 'main-body';
    };
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (rendered.current) {
            if (queryParams.has('type')) {
                setActiveTabKey(queryParams.get('type')!);
            } else {
                initializeActiveTab();
            }
            if (queryParams.has('sub-type')) {
                setActiveSubTab(queryParams.get('type') ?? activeTabKey!, queryParams.get('sub-type')!, false);
            }
        } else {
            initializeActiveTab(queryParams.get('type')!);
            initializeActiveSubTabForAllTabs(queryParams.get('type'), queryParams.get('sub-type'));

            rendered.current = true;
        }
    }, [rendered.current]);


    useEffect(() => {
        // If there are no params present in the url then we are setting the 1st tab and subTab as active by default.
        if (isArrayEmpty(location.search)) {
            const firstAvailableTab = props.tabs.find(tab => tab.accessible)?.key;
            const firstAvailableSubTab = props.tabs.find(tab => tab.accessible)?.subTabs?.find(subTab => subTab.accessible)?.key;
            if (firstAvailableTab) {

                setActiveTabKey(firstAvailableTab);
                if (firstAvailableSubTab) {
                    setActiveSubTab(firstAvailableTab ?? activeTabKey!, firstAvailableSubTab, false);
                }
                updateWindowUrl(firstAvailableTab, firstAvailableSubTab, true);
            }
        } else {
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.has('type') && queryParams.get('type') !== activeTabKey) {
                setActiveTabKey(queryParams.get('type')!);
            }
            if (queryParams.has('sub-type') && getActiveSubTabForTab(queryParams.get('type')!) !== queryParams.get('sub-type')) {
                setActiveSubTab(queryParams.get('type') ?? activeTabKey!, queryParams.get('sub-type')!, false);
            }
        }

    }, [location]);

    return (
        <div className={getTabStyleClass()}>
            <Tabs
                {...rest}
                className="main-body-tabs"
                activeKey={activeTabKey}
                onChange={onTabChange}
                onTabClick={tabKey => {
                    setActiveTabKey(tabKey);
                    updateWindowUrl(tabKey);
                }}>
                {props.tabs
                    .filter(tab => tab.accessible)
                    .map(tab => (
                        <TabPane tab={getTabName(tab)} key={tab.key}>
                            {activeTabKey && tab.subTabs && (
                                <TabRenderer
                                    activeKey={getActiveSubTabForTab(tab.key)}
                                    components={tab.subTabs.map(subTab => {
                                        return {
                                            key: subTab.key,
                                            name: subTab.name,
                                            allowAccess: subTab.accessible,
                                            component: subTab.component!(activeTabKey === tab.key && subTab.key === getActiveSubTabForTab(tab.key)),
                                        };
                                    })}
                                    onKeyChange={(subTabKey: string) => {
                                        setActiveSubTab(tab.key, subTabKey, true);
                                    }}
                                />
                            )}
                            {tab.component && tab.component(tab.key === activeTabKey)}
                        </TabPane>
                    ))}
            </Tabs>
        </div>
    );
}
