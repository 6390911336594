import * as React from 'react';
import { Row, Col, DatePicker, Segmented } from 'antd';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import moment from 'moment-timezone';
import { TimeFilter } from '../../utilities/Date';

const { RangePicker } = DatePicker;

export interface DateFilterParam {
    label: string;
    value: string;
    additionalInfo?: any;
}

export enum TimeFilterMode {
    DATE = 'DATE',
    DATE_TIME = 'DATETIME',
}

export type DateFilterSort = 'asc' | 'desc';
export interface TimeFilterPickerProps {
    placeholder?: string;
    enableFilterPicker: boolean | true;
    fromDateEpoch: number | undefined;
    toDateEpoch: number | undefined;
    setFromDateEpoch: React.Dispatch<React.SetStateAction<number | undefined>>;
    setToDateEpoch: React.Dispatch<React.SetStateAction<number | undefined>>;
    dateFilterOptions?: DateFilterParam[];
    dateFilterParam?: DateFilterParam | undefined;
    setDateFilterParam?: React.Dispatch<React.SetStateAction<DateFilterParam>>;
    dateFilterSort?: DateFilterSort;
    setDateFilterSort?: React.Dispatch<React.SetStateAction<DateFilterSort>>;
    timeFilterMode?: TimeFilterMode;
}

const dateFilterSortOptions: { label: string; value: DateFilterSort }[] = [
    {
        label: 'Ascending',
        value: 'asc',
    },
    {
        label: 'Descending',
        value: 'desc',
    },
];

function TimeFilterPicker(props: TimeFilterPickerProps) {
    const {
        enableFilterPicker,
        fromDateEpoch,
        toDateEpoch,
        setFromDateEpoch,
        setToDateEpoch,
        dateFilterOptions,
        dateFilterParam,
        setDateFilterParam,
        dateFilterSort,
        setDateFilterSort,
        timeFilterMode = TimeFilterMode.DATE,
    } = props;

    const handleDateRangePickerChange = dates => {
        setFromDateEpoch(
            timeFilterMode === TimeFilterMode.DATE_TIME
                ? dates[0].valueOf()
                : moment(dates[0].valueOf()).startOf('day').valueOf(),
        );
        setToDateEpoch(
            timeFilterMode === TimeFilterMode.DATE_TIME
                ? dates[1].valueOf()
                : moment(dates[1].valueOf()).endOf('day').valueOf(),
        );
    };

    return (
        <div className="date-range flex-column">
            <RangePicker
                value={fromDateEpoch && toDateEpoch ? [moment(fromDateEpoch), moment(toDateEpoch)] : undefined}
                showTime={
                    timeFilterMode === TimeFilterMode.DATE_TIME && {
                        format: 'HH:mm a',
                        use12Hours: true,
                    }
                }
                ranges={TimeFilter.presets(timeFilterMode)}
                disabled={!enableFilterPicker}
                className="date-range-picker"
                onChange={handleDateRangePickerChange}
                renderExtraFooter={() =>
                    dateFilterOptions && (
                        <>
                            <Row>
                                <Col xs={4}>
                                    <span>Filter On: </span>
                                </Col>
                                <Col xs={20}>
                                    <Segmented
                                        options={dateFilterOptions}
                                        value={dateFilterParam?.value}
                                        onChange={value =>
                                            setDateFilterParam!(
                                                dateFilterOptions.find(param => param.value === value) ??
                                                    dateFilterParam!,
                                            )
                                        }
                                    />
                                </Col>
                            </Row>

                            {setDateFilterSort && <Row>
                                <Col xs={4}>
                                    <span>Sort: </span>
                                </Col>
                                <Col xs={20}>
                                    <Segmented
                                        options={dateFilterSortOptions}
                                        value={dateFilterSort}
                                        onChange={value => setDateFilterSort!(value == 'asc' ? 'asc' : 'desc')}
                                    />
                                </Col>
                            </Row> }
                        </>
                    )
                }
            />
            {dateFilterParam && (
                <span className="date-range-span flex-justify-center">
                    Filtering On: {dateFilterParam.label}
                    {dateFilterSort === 'asc' ? <BiSortDown /> : <BiSortUp />}
                </span>
            )}
        </div>
    );
}

export default TimeFilterPicker;
