import { Breadcrumb } from 'antd';
import ApplicantDetails from './ApplicantDetails';

export default function ApplicantDetailsBody() {
    return (
        <>
            <Breadcrumb className="main-nav">
                <Breadcrumb.Item>..</Breadcrumb.Item>
                <Breadcrumb.Item>Applicant List</Breadcrumb.Item>
                <Breadcrumb.Item>Applicant Details</Breadcrumb.Item>
            </Breadcrumb>

            <ApplicantDetails />
        </>
    );
}
