import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row, Select, Button, Spin } from 'antd';
import { Option } from 'rc-select';
import { useLocation, useNavigate } from 'react-router-dom';
import TimeFilterPicker from '../../common/TimeFilterPicker';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { COLLECTION_LIST } from '../../../http/EndPoints';
import { EstablishmentType } from '../../../types/EstablishmentType';
import CollectionList from './CollectionList';
import { CollectionResponse } from '../../../types/FinanceCredit';
import {
    EstablishmentsDropDown,
    EstablishmentSelectionMode,
    getEncodedStringFromUuidList,
    getUuidListFromEncodedString,
} from '../../common/establishments/EstablishmentsDropDown';
import { isArrayEmpty } from '../../../Utils';
import { CollectionState, CollectionComponentConfig } from '../CollectionState';
import { CollectionSource, CollectionSourceType } from '../CollectionSource';


interface CollectionProps {
    active: boolean;
}

function Collections(props: CollectionProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const isRendered = React.useRef(false);
    const [collectionRequested, setCollectionRequested] = useState<boolean>(false);
    const [collectionList, setCollectionList] = useState<CollectionResponse>();
    const [enableDateFilterPicker, setEnableDateFilterPicker] = useState<boolean | undefined | null>(false);
    const [collectionState, setCollectionState] = useState<CollectionState | null>(null);
    const [establishmentUuids, setEstablishmentUuids] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<number>();
    const [toDate, setToDate] = useState<number>();
    const [page, setPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageSize] = useState(10);
    const [selectedCollectionSources, setSelectedCollectionSources] = useState<CollectionSourceType[]>([
        CollectionSourceType.CREDIT,
        CollectionSourceType.ORDER,
    ]);
    const [fetchEstablishmentList, setFetchEstablishmentList] = useState<boolean>();

    const getCollections = () => {
        setLoading(true);
        const endpoint = COLLECTION_LIST(
            establishmentUuids!,
            collectionState!,
            fromDate,
            toDate,
            page - 1,
            pageSize,
            false,
            selectedCollectionSources,
        );

        const responseHandler: ResponseHandler<CollectionResponse> = {
            onResponseSuccess(response: CollectionResponse): void {
                setCollectionList(response);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        getApiCall(endpoint, responseHandler);
    };

    const setStateFromQueryParams = (queryParams, param, stateSetter) => {
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const value = parseInt(queryParams.get(param));
            if (value) {
                stateSetter(value);
            }
        } else if (param === 'collection-source') {
            if (queryParams.get(param)) {
                stateSetter(queryParams.get(param).split(','));
            }
        } else if (queryParams.has(param)) {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams, param, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                }
            } else {
                queryParams.set(param, state);
            }
        }
    };

    const setStateFromUrlParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setFetchEstablishmentList(true);
        if (!props.active) {
            return;
        }
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setStateFromQueryParams(queryParams, 'collection-state', setCollectionState);
        setStateFromQueryParams(queryParams, 'collection-source', setSelectedCollectionSources);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'page', setPage);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'collection-state', collectionState);
        setQueryParamsFromState(queryParams, 'collection-source', selectedCollectionSources);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'page', page);

        /**
          * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
          * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
          * So we are replacing the page with the help of replace property of navigate method.
        * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const isCollectionListingAllowed = (): boolean => {
        let collectionListingAllowed = collectionState != undefined && establishmentUuids.length > 0 && selectedCollectionSources.length > 0;
        if (CollectionComponentConfig.valueOf(collectionState!)?.config.collectionConfig?.dateFilterSelectionRequired) {
            collectionListingAllowed = collectionListingAllowed && fromDate != undefined && toDate != undefined;
        }
        return collectionListingAllowed;
    };

    const getCollectionsAndUpdateURLParams = () => {
        getCollections();
        setUrlParamsFromState();
    };

    useEffect(() => {
        if (props.active) {
            /**
             * If the establishmentUuid is set, it signifies that something is present in the state.
             */
            if (establishmentUuids) {
                setUrlParamsFromState();
            } else {
                setStateFromUrlParams();
            }
        }
    }, [props.active, establishmentUuids]);

    useEffect(() => {
        if (collectionState) {
            setEnableDateFilterPicker(CollectionComponentConfig.valueOf(collectionState)?.config.collectionConfig?.dateFilterSelectionRequired);
            if (!CollectionComponentConfig.valueOf(collectionState)?.config.collectionConfig?.dateFilterSelectionRequired) {
                setFromDate(undefined);
                setToDate(undefined);
            }
        }
    }, [collectionState]);

    useEffect(() => {
        if (isRendered.current) {
            getCollectionsAndUpdateURLParams();
        } else {
            isRendered.current = true;
        }
    }, [page]);

    useEffect(() => {
        if (!collectionRequested) {
            return;
        }

        if (page == 1) {
            getCollectionsAndUpdateURLParams();
        } else {
            setPage(1);
        }
        setCollectionRequested(false);
    }, [collectionRequested]);


    return (
        <Spin spinning={loading} tip="Loading..." size="small">
            <Row gutter={[12, 12]} className="main-body-filters">
                <Col xs={24} sm={12} xl={4}>
                    <EstablishmentsDropDown
                        setLoading={setLoading}
                        type={EstablishmentType.HUB}
                        setSelectedUuids={setEstablishmentUuids}
                        selectedUuids={establishmentUuids}
                        selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                        fetchEstablishmentList={fetchEstablishmentList}
                    />
                </Col>
                <Col xs={24} sm={12} xl={4}>
                    <Select
                        value={collectionState || undefined}
                        placeholder="Select state"
                        className="filter-drop-down"
                        onChange={value => {
                            setCollectionState(value);
                        }}>
                        {
                            Object.values(CollectionComponentConfig).filter((type: CollectionComponentConfig) => type.config.collectionConfig?.display).map((type: CollectionComponentConfig, index) => <Option
                                key={index}
                                value={type.config.collectionConfig?.state}
                            >
                                {type.config.collectionConfig?.display}
                            </Option>)
                        }
                    </Select>
                </Col>
                <Col xs={24} sm={12} xl={4}>
                    <Select
                        value={selectedCollectionSources}
                        className='remove-select-background-tag'
                        maxTagCount={1}
                        maxTagTextLength={15}
                        placeholder='Select Source'
                        mode='multiple'
                        style={{ width: '100%' }}
                        showArrow
                        showSearch={false}
                        onChange={(value) => {
                            setSelectedCollectionSources(value);
                        }}
                    >
                        {Object.values(CollectionSource).map((source: CollectionSource, index) => <Select.Option key={index} value={source.type}>
                            {source.display}
                        </Select.Option>,
                        )}

                    </Select>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <TimeFilterPicker
                        enableFilterPicker={enableDateFilterPicker!}
                        fromDateEpoch={fromDate!}
                        toDateEpoch={toDate!}
                        setFromDateEpoch={setFromDate}
                        setToDateEpoch={setToDate}
                    />
                </Col>

                <Col lg={4} md={24} className="flex-center">
                    <Button
                        className="default-button"
                        size="large"
                        disabled={!isCollectionListingAllowed()}
                        onClick={() => {
                            setCollectionRequested(true);
                        }}>
                        Get Collections
                    </Button>
                </Col>
            </Row>

            <CollectionList
                page={page}
                setPage={setPage}
                collectionResponse={collectionList!!}
                getCollections={getCollections}
            />
        </Spin>
    );
}

export default Collections;
