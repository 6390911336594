import { Col, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsDash } from 'react-icons/bs';
import { StateMetaDatum, UserDatum } from '../../Types';
import { Datum } from '../../types/Datum';
import { getISTFromEpoch } from '../../utilities/Date';
import { DatumRenderer } from '../../utilities/datumRenderer';

const createColumns = (stateMetadata: StateMetaDatum[]) => {
    const showUserMetadata = stateMetadata?.length > 0 && stateMetadata.some(datum => datum?.user !== undefined);

    const columns: ColumnsType<StateMetaDatum> = [
        {
            key: 1,
            title: 'State',
            render: (_, record: StateMetaDatum) => (record.display ? record.display : record.state),
        },
        {
            key: 3,
            title: 'Date',
            render: (_, record) => `${record.date ? getISTFromEpoch(record.date) : getISTFromEpoch(record.timestamp!)}`,
        },
    ];

    if (showUserMetadata) {
        columns.splice(1, 0, {
            key: 2,
            dataIndex: 'user',
            title: 'User',
            render: (user: UserDatum) => {
                return (
                    <>
                        {
                            user ? <>
                                <span style={{ paddingRight: '2px' }}>{user.name}</span>
                                <Tooltip
                                    title={<>Role: {user.role} {user.contact_info ? ` Contact:${user.contact_info}` : ''}</>}>
                                    <AiOutlineInfoCircle cursor={'pointer'} />
                                </Tooltip>
                            </> : <BsDash />

                        }
                    </>
                );
            },
        });
    }

    return columns;
};

function renderData(datum: Datum): React.ReactNode {
    if (datum.value) {
        return <DatumRenderer
            type={datum.type}
            datumValue={datum.value}
            record={datum}
        />;
    } else if (datum.values) {
        return datum.values?.map((eachValue, index) => {
            return <Row key={index} style={{ width: '100%' }}>
                <DatumRenderer
                    type={datum.type}
                    datumValue={eachValue}
                    record={datum}
                />
            </Row>;
        });
    }
}

function isSingleValuePresent(datum: Datum): boolean {
    return datum.value != undefined || (datum.values != undefined && datum.values.length == 1);
}

export const expandedRowRender = (stateMetadatum: StateMetaDatum) => {
    return (
        <div className="expanded-row-renderer">
            {stateMetadatum.data
                ?.filter(datum => datum.function === 'DISPLAY')
                .map((datum, index) => {
                    return (
                        <Row key={index} gutter={[24, 24]} className={!isSingleValuePresent(datum) ? 'align-content-flex-start' : ''}>
                            <Col xs={12} md={12} className="expanded-row-renderer-key">
                                <span>{datum.display_name}</span>
                            </Col>
                            <Col xs={12} md={12} className="expanded-row-renderer-key">
                                {renderData(datum)}
                            </Col>
                        </Row>
                    );
                })}
        </div>
    );
};

const rowExpandable = (stateMetadatum: StateMetaDatum) => {
    return stateMetadatum.data?.filter(datum => datum.function === 'DISPLAY').length!! > 0;
};

interface StateMetadataRendererProps {
    stateMetadata: StateMetaDatum[];
    defaultExpandedRowKey?: string;
    scroll?: {
        x?: number | string;
        y?: number | string;
    };
}

export function StateMetadataRenderer(props: StateMetadataRendererProps) {
    const { stateMetadata, defaultExpandedRowKey, scroll } = props;

    // Sorts the stateMetadata according to date/timestamp in ascending order
    // CreditTransaction's statemetadatum sends a date property, while the rest send a timestamp property.
    stateMetadata?.sort((sm1, sm2) => (sm1.date ? sm1.date - sm2.date! : sm1.timestamp! - sm2.timestamp!));

    const prepareStateMetadataForTableRender = () => {
        return stateMetadata?.map((datum) => {
            datum['key'] = datum.state;
            return datum;
        });
    };

    return (
        <Table
            columns={createColumns(stateMetadata)}
            expandable={{
                expandedRowRender,
                rowExpandable,
                defaultExpandedRowKeys: [defaultExpandedRowKey!],
            }}
            scroll={scroll || { x: 'fit-content' }}
            dataSource={prepareStateMetadataForTableRender()}
            pagination={false}
        />
    );
}
