import { EstablishmentSaveMode } from '../../../types/EstablishmentSaveMode';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { OrderStates } from '../../orders/hubsAndOutlets/utils';
import { Path } from '../../router/Path';
import { PathConfig } from '../PathConfigService';
import {
    EstablishmentDetailsConfig,
    EstablishmentsConfig,
    FinanceLayerConfig,
    OrderDetailsConfig,
    PurchaseOrderDetailsConfig,
    UserListingConfig,
} from '../types/ConfigTypes';

const GroundOpsConfigs: PathConfig[] = [
    {
        path: Path.HOME,
        accessible: true,
    },
    {
        path: Path.LOGOUT,
        accessible: true,
    },
    {
        path: Path.ORDER_DETAILS,
        accessible: true,
        config: {
            stateChangeConfig: {
                allowedStates: [OrderStates.NOT_DELIVERED, OrderStates.DELIVERY_REJECTED, OrderStates.DELIVERED, OrderStates.PAYMENT_COLLECTION_INITIATED],
            },
            allowToViewSellerOrders: false,
        } as OrderDetailsConfig,
    },
    {
        path: Path.PURCHASE_ORDER_DETAILS,
        accessible: false,
        config: {
            show: false,
            stateChangeConfig: {
                allowedStates: [],
            },
            paymentConfig: {
                allowMakePayment: false,
            },
        } as PurchaseOrderDetailsConfig,
    },
    {
        path: Path.ESTABLISHMENTS,
        accessible: true,
        config: {
            shouldShowHubs: false,
            shouldShowOutlets: true,
            shouldShowSellerHubs: false,
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.HUB_ESTABLISHMENT_LIST,
        accessible: false,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.OUTLET_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.SELLER_HUB_ESTABLISHMENT_LIST,
        accessible: false,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.ESTABLISHMENT_DETAILS,
        accessible: true,
        config: {
            shouldShowInfo: true,
            products: {
                sellerHub: {
                    show: false,
                    allowProductCreation: false,
                    allowProductListing: false,
                },
                hub: {
                    show: false,
                    allowProductListing: false,
                },
            },
            users: {
                show: true,
                allowCreation: false,
            },
            showReceivables: true,
            infoConfig: {
                selectiveUpdateAllowedFields: ['gstin'],
                showConfigLayout: true,
                showBrandsDiscountList: true,
                allowBrandDiscountsUpdate: false,
                saveMode: EstablishmentSaveMode.UPDATE,
            },
            ordersConfig: {
                listOrders: {
                    show: true,
                    allowedOrderStates: [
                        OrderStates.OUT_FOR_DELIVERY,
                        OrderStates.DELIVERED,
                        OrderStates.NOT_DELIVERED,
                    ],
                    getOrdersOnLaunch: true,
                },
                allowOrderCreation: true,
            },
            allowStatusChange: false,
            returns: {
                outlet: {
                    showList: true,
                    placeReturn: true,
                },
            },
        } as EstablishmentDetailsConfig,
    },
    {
        path: Path.FINANCE,
        accessible: true,
        config: {
            collections: {
                show: false,
            },
            receivables: {
                show: true,
            },
            deposits: {
                show: true,
            },
            exportOrders: false,
        } as FinanceLayerConfig,
    },
    {
        path: Path.USER_LISTING,
        accessible: false,
        config: {
            primaryEstablishmentType: EstablishmentType.HUB,
            associatedEstablishmentTypes: [EstablishmentType.HUB, EstablishmentType.OUTLET],

        } as UserListingConfig,
    },
    {
        path: Path.USER_PROFILE,
        accessible: true,
    },
];

export default GroundOpsConfigs;
