import { Drawer, Image, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { QUANTITY_SYMBOL } from '../../../../../../Constants';
import { VIEW_SELLER_ORDERS } from '../../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../../http/HttpClient';
import { OrderItem } from '../../../../../../types/Order';
import { addKeyToDataList } from '../../../../../../Utils';
import { MeraaiTable } from '../../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../../common/templates/meraaiTable/types';
import { SkuInfo } from '../utils';
import { SellerOrderDetails, SellerOrderListProps, SellerOrderResponse } from './types';

export const SellerOrderList = (props: SellerOrderListProps) => {

    const [selectedSellerOrder, setSelectedSellerOrder] = useState<SellerOrderDetails>();
    const [sellerOrderResponse, setSellerOrderResponse] = useState<SellerOrderResponse>();
    const [itemDrawerVisibility, setItemDrawerVisibility] = useState<boolean>(false);

    // Seller List
    const colums: MeraaiTableColumnType[] = [
        {
            title: 'Seller',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: SellerOrderDetails) => <label>{record.establishment.name}</label>,
        },
        {
            title: 'Status',
            rowVisibility: [Device.DESKTOP],
            render: (_, record: SellerOrderDetails) => <label>{record.state}</label>,
        },
        {
            title: 'Items',
            rowVisibility: [Device.DESKTOP],
            render: (_, record: SellerOrderDetails) => <div className='flex items-center cursor-pointer'
                onClick={() => {
                    setItemDrawerVisibility(true);
                    setSelectedSellerOrder(record);
                }}
            >
                <label className='font-semibold cursor-pointer'>
                    {record?.items.length === 1 ? '1 Item' : record?.items.length + ' Items'}
                </label>
                <FiChevronDown className='text-2xl' />
            </div>,
        },
        {
            title: 'Purchase Order',
            mobileWidth: 'FULL',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: SellerOrderDetails) => record.purchase_order_generated ? <a
                className='text-meraai-orange hover:text-meraai-orange'
                target="_blank"
                href={`/purchase-order/${record.purchase_order_uuid}`}
            >
                View order details
            </a> : <label className='text-meraai-lightgrey cursor-pointer'>Not generated yet</label>,
        },
    ];

    const getSellerOrderDetails = () => {

        const responseHandler: ResponseHandler<SellerOrderResponse> = {
            onResponseSuccess(response: SellerOrderResponse): void {
                setSellerOrderResponse(response);
            },
        };

        getApiCall(VIEW_SELLER_ORDERS(props.orderUuid!), responseHandler);
    };

    useEffect(() => {
        if (!sellerOrderResponse) {
            getSellerOrderDetails();
        }
    }, []);

    // Items Drawer
    const ordersItemsColumn: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image',
            rowVisibility: [Device.DESKTOP],
            render: (imageUrl: string) => <Image width={30} height={30} src={imageUrl} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: OrderItem) => <SkuInfo name={record.name} sku={record.sku} />,
        },
        {
            title: 'Ordered QTY',
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (quantity: number) => `${QUANTITY_SYMBOL} ${quantity}`,
        },
    ];

    return (
        <>
            {
                sellerOrderResponse ? <>
                    <MeraaiTable
                        columns={colums}
                        dataSource={addKeyToDataList(sellerOrderResponse?.orders!)}
                        pagination={false}
                    />

                    <Drawer
                        className="meraai-drawer"
                        title={`Items summary - ${selectedSellerOrder?.establishment.name}`}
                        open={itemDrawerVisibility}
                        onClose={() => { setItemDrawerVisibility(false); }}
                    >
                        <MeraaiTable
                            columns={ordersItemsColumn}
                            dataSource={addKeyToDataList(selectedSellerOrder?.items!)}
                            pagination={false}
                        />

                    </Drawer>
                </> : <Skeleton paragraph={{ rows: 2 }} />
            }

        </>
    );
};