import { Establishment, StateMetaDatum } from '../../Types';

export interface ReturnOrderListResponse {
    return_orders: ReturnOrderSummary[];
    page_count: number;
    page_size: number;
    next_page: boolean;
}

export interface ReturnOrderSummary {
    uuid: string;
    created_at: number;
    state_updated_at: number;
    state: string;
    state_metadata: StateMetaDatum[];
    sink_establishment: Establishment;
    source_establishment: Establishment;
    amount: number;
    rejected_amount: number;
    final_amount: number;
    valid_next_states: string[];
}

export enum ReturnOrderState {
    PLACED = 'PLACED',
    REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
}

export interface ReturnOrderStateProperty {
    state: ReturnOrderState;
    display: string;
    color: string;
}