import { Button, Form, Image } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import Input from 'antd/lib/input/Input';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { TbCameraPlus } from 'react-icons/tb';
import { REGEX } from '../../../../../../Constants';
import { ResponseHandler } from '../../../../../../http/HttpClient';
import { FileIdentifier, FileUploadResponse, upload } from '../../../../../../utilities/UploadFile';
import ImageRenderer, { ImageFile, UploaderTypes } from '../../../../../common/ImageRenderer';
import { BrandFormProps } from './type';
import { formItem } from './utils';

export const BrandForm = (props: BrandFormProps) => {
    const [selectedImageFile, setSelectedImageFile] = React.useState<ImageFile>();
    const [brandImageUrl, setBrandImageUrl] = React.useState<string>('');

    const setFieldError = (name: NamePath, errors: string[] | undefined) => {
        props.brandForm.setFields([
            {
                name: name,
                errors: errors,
            },
        ]);
    };

    const uploadBrandPictureAndSetState = () => {
        props.setLoading(true);

        const responseHandler: ResponseHandler<FileUploadResponse> = {
            onResponseSuccess(response: FileUploadResponse): void {
                setBrandImageUrl(response.url);
                props.brandForm.setFieldValue('image_url', response.url);
                props.setLoading(false);
                setFieldError('image_url', ['']);
            },
            onResponseFailed() {
                props.setLoading(false);
            },
            onResponseError() {
                props.setLoading(false);
            },
        };
        upload('IMAGE', selectedImageFile?.file!, responseHandler, FileIdentifier.PROFILE);
    };

    const validateFields = (): boolean => {

        if (props.brandForm.getFieldValue('image_url')) {
            if (!brandImageUrl) {
                setFieldError('image_url', ['Picture is not uploaded correctly. Please upload again']);
                return false;
            } else {
                setFieldError('image_url', ['']);
            }
        } else {
            setFieldError('image_url', ['Please upload brand image']);
            return false;
        }
        return true;
    };

    React.useEffect(() => {
        if (selectedImageFile) {
            uploadBrandPictureAndSetState();
        }
    }, [selectedImageFile]);

    React.useEffect(() => {
        if (props.mode.initializeFormFields) {
            props.brandForm.setFieldsValue({
                active: props.selectedBrand?.active,
                name: props.selectedBrand?.name,
                title: props.selectedBrand?.title,
                description: props.selectedBrand?.description,
                image_url: props.selectedBrand?.image_url,
            });
            setBrandImageUrl(props.selectedBrand?.image_url!);
        }
    }, []);

    return (
        <>
            <Form
                form={props.brandForm}
                name='brandForm'
                onFinish={(values) => {

                    if (validateFields()) {
                        props.onFormSubmit(values);
                    }
                }}
                onFinishFailed={validateFields}
                scrollToFirstError
            >

                <Form.Item
                    {...formItem}
                    required={true}
                    label={<b style={{ color: '#4f4f4f' }}>Name</b>}
                    labelAlign='left'
                    name="name"
                    rules={[
                        {
                            validator(_, value) {
                                return new Promise((resolve, reject) => {
                                    if (!value) {
                                        reject('Please enter brand name.');
                                    }
                                    if (REGEX.BRAND_NAME.test(value)) {
                                        resolve(true);
                                    } else {
                                        reject('Please enter a valid brand name.');
                                    }
                                });
                            },
                        },
                    ]}
                >
                    <Input
                        showCount
                        maxLength={50}
                        placeholder="Name of your brand"
                    />
                </Form.Item>


                <Form.Item
                    {...formItem}
                    label={<b style={{ color: '#4f4f4f' }}>Title</b>}
                    labelAlign='left'
                    name="title"
                >
                    <Input
                        showCount
                        maxLength={25}
                    />
                </Form.Item>

                <Form.Item
                    {...formItem}
                    label={<b style={{ color: '#4f4f4f' }}>Description</b>}
                    labelAlign='left'
                    name="description"
                    style={{ resize: 'none' }}
                >
                    <TextArea
                        showCount
                        maxLength={255}
                        style={{ resize: 'none', height: 120 }}
                    />
                </Form.Item>


                <Form.Item
                    {...formItem}
                    required={true}
                    label={<b style={{ color: '#4f4f4f' }}>Image</b>}
                    labelAlign='left'
                    name="image_url"
                >
                    <div className='flex-column-mid flex-center' >
                        <ImageRenderer
                            uploadText={brandImageUrl ? 'Replace' : 'Add'}
                            uploadIcon={brandImageUrl ? <BiEditAlt /> : <TbCameraPlus />}
                            setFileState={(file: ImageFile) => {
                                setSelectedImageFile(file);
                            }}
                            type={UploaderTypes.UPLOAD_PNG}
                        />
                        {
                            brandImageUrl && <div className="profile-photo margin-top-10px" >
                                <Image
                                    className="profile-photo"
                                    style={{ borderRadius: '50%', width: '150px' }}
                                    src={brandImageUrl}
                                />
                            </div>
                        }
                    </div>
                </Form.Item>

                <Form.Item
                    className='flex-center remove-margin'
                >
                    <Button
                        size='large'
                        className='default-button'
                        htmlType="submit"
                    >
                        {props.mode.saveButtonText}
                    </Button>
                </Form.Item>

            </Form>
        </>
    );
};