import { Card, Image } from 'antd';
import React from 'react';
import { Brand } from '../orders/listing/ListingData';

export interface BrandItemProps {
    brand: Brand;
    onBrandItemClick: (brand: Brand) => void;
}

function BrandItem(props: BrandItemProps) {
    return (
        <Card
            onClick={() => {
                props.onBrandItemClick(props.brand);
            }}
            hoverable
            bodyStyle={{ padding: '0' }}
            style={{
                width: 150,
                height: 180,
                border: '1px solid #D9D9D9',
                borderRadius: '10px',
                paddingTop: '10px',
            }}
            actions={[
                <div className="card-view-div">
                    <span className="span-brand-name">{props.brand.name}</span>
                </div>,
            ]}
            cover={
                <Image className="profile-photo" width={140} height={120} preview={false} src={props.brand.image_url} />
            }
        />
    );
}

export default BrandItem;
