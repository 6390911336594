import { Button, Menu, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Role } from '../../../types/Role';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { GET_USER_LIST } from '../../../http/EndPoints';
import { User, UserListingProps, UserListResponse, userStatusProperties } from './types';
import { Filter } from './Filter';
import { addKeyToDataList, isArrayEmpty } from '../../../Utils';
import { useNavigate } from 'react-router-dom';
import {
    getEncodedStringFromUuidList,
    getUuidListFromEncodedString,
} from '../../common/establishments/EstablishmentsDropDown';
import { AiOutlineLine } from 'react-icons/ai';
import { ListingPage } from '../../common/templates/listingPage';
import { PageBreadcrumbHeader } from '../../common/pageBreadcrumbHeader';
import { MeraaiTable } from '../../common/templates/meraaiTable';
import { DataNotFound } from '../../common/templates/dataNotFound';
import { Device, MeraaiTableColumnType } from '../../common/templates/meraaiTable/types';
import { layout, style } from '../../../styles/css/style';
import { ModifyUser } from '../createAndUpdate';
import { ModifyUserMode } from '../createAndUpdate/types';
import { BsThreeDots } from 'react-icons/bs';
import { MenuInfo } from 'rc-menu/lib/interface';
import { updateUserProfilePictureOrStatus } from '../utils';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const UserListing = (props: UserListingProps) => {

    const establishmentUserProfile = props.chosenEstablishment ? true : false;

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchName, setSearchName] = useState<string>();
    const [selectedRole, setSelectedRole] = useState<Role>();
    const [selectedUserName, setSelectedUserName] = useState<string>();
    const [accessibleRoles, setAccessibleRoles] = useState<Role[]>(Role.ALL_ROLES);
    const [establishmentUuids, setEstablishmentUuids] = useState<string[]>();
    const [userListRequested, setUserListRequested] = useState<boolean>(false);
    const [userListDetails, setUserListDetails] = useState<UserListResponse>();
    const [prevEstablishmentUuids, setPrevEstablishmentUuids] = useState<string[]>();
    const [modifyUserDrawerVisibility, setModifyUserDrawerVisibility] = useState<boolean>(false);

    const productListMessage = useRef<string>('Search for the data using filters mentioned above.');

    const navigate = useNavigate();

    // METHODS
    const setStateFromQueryParams = (queryParams, param, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList?.length! > 0) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'pageSize'].includes(param)) {
            const values = parseInt(queryParams.get(param));
            if (values) {
                stateSetter(values);
            }
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams, param, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                } else {
                    queryParams.delete(param);
                }
            } else {
                queryParams.set(param, state);
            }
        } else {
            queryParams.delete(param);
        }
    };

    const setStateFromUrlParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setStateFromQueryParams(queryParams, 'role', setSelectedRole);
        setStateFromQueryParams(queryParams, 'first-name', setSearchName);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setStateFromQueryParams(queryParams, 'size', setPageSize);
        setUserListRequested(true);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'role', selectedRole);
        setQueryParamsFromState(queryParams, 'first-name', searchName);
        setQueryParamsFromState(queryParams, 'page', page);
        setQueryParamsFromState(queryParams, 'size', pageSize);

        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const getUserList = () => {
        if (!selectedRole) {
            return;
        }
        setLoading(true);

        const responseHandler: ResponseHandler<UserListResponse> = {
            onResponseSuccess(value: UserListResponse) {
                setLoading(false);
                setUrlParamsFromState();
                setUserListDetails(value);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        getApiCall(GET_USER_LIST(selectedRole!, page - 1, pageSize, searchName, establishmentUuids), responseHandler);
    };

    const resetFilter = () => {
        setPage(1);
        setSearchName(undefined);
        if (!props.chosenEstablishment) {
            setEstablishmentUuids([]);
            setPrevEstablishmentUuids([]);
        }
    };

    const onClickMenuOption = (event: MenuInfo, username) => {

        const userProfileUpdateResponseHandler: ResponseHandler<any> = {
            onResponseSuccess: function (): void {
                setLoading(false);
                getUserList();
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        if (event.key === 'edit') {

            setSelectedUserName(username);
            setModifyUserDrawerVisibility(true);

        } else if (['active', 'inactive'].includes(event.key)) {

            updateUserProfilePictureOrStatus({
                username: username,
                requestBody: { active: event.key === 'active' },
                responseHandler: userProfileUpdateResponseHandler,
            });
        }
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: User) => `${record.first_name} ${record.last_name}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            rowVisibility: [Device.DESKTOP],
            render: (email) => email ? email : <AiOutlineLine />,
        },
        {
            title: 'Mobile number',
            dataIndex: 'mobile_number',
            rowVisibility: [Device.DESKTOP],
            render: (mobile_number) => mobile_number ? mobile_number : <AiOutlineLine />,
        },
        {
            title: 'Status',
            dataIndex: 'active',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (active) => {
                const statusProperty = Object.values(userStatusProperties).find((property) => property.status === active);

                return <Tag
                    className={`w-full py-2 ${layout.flex.spaceEvenly}`}
                    color={statusProperty.color}
                >
                    <label className={`font-semibold text-sm cursor-pointer whitespace-pre-line ${layout.flex.center}`}>
                        {statusProperty.display}
                    </label>
                </Tag>;
            },
        },
        {
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: User) => <Button
                className={`w-full ${style.meraaiPlainButton}`}
                onClick={() => {
                    navigate(`/users/${record.username}`);
                }}
            >
                {'View'}
            </Button>,
        },
        {
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'username',
            render: (username, record: User) => {

                const menuItems: ItemType[] = [
                    {
                        key: 'options',
                        icon: <BsThreeDots />,
                        children: [{
                            key: 'edit',
                            label: 'Edit',
                        },
                        {
                            key: record.active ? 'inactive' : 'active',
                            label: record.active ? 'Deactivate' : 'Activate',
                        }],
                    },
                ];

                return <div className={`${layout.flex.center}`}>
                    <Menu
                        mode='inline'
                        className={`bg-[#ffffff00] m-0 !w-max !h-[10px] ${layout.flex.center}`}
                        inlineCollapsed
                        onClick={(event) => {
                            onClickMenuOption(event, username);
                        }}
                        items={menuItems} />
                </div>;
            },
        },
    ];


    useEffect(() => {
        if (userListRequested) {
            getUserList();
            setUserListRequested(false);
        }
    }, [userListRequested]);


    useEffect(() => {
        if (props.active) {
            setStateFromUrlParams();
        }
    }, [props.active]);

    return (
        <div className='h-screen overflow-auto'>
            <ListingPage
                topRowStyleClass={establishmentUserProfile ? '!p-0 !pt-5' : ''}
                bottomRowStyleClass={establishmentUserProfile ? '!p-0 !pt-5' : ''}
                // If the user listing page opens in establishment section
                // Hide the breadcrumb
                hideBreadcrumb={establishmentUserProfile}
                breadcrumb={
                    <PageBreadcrumbHeader items={[
                        'Users', 'Listing',
                    ]} />
                }
                header={
                    <Filter
                        chosenEstablishment={props.chosenEstablishment}
                        accessibleRoles={accessibleRoles}
                        setAccessibleRoles={setAccessibleRoles}
                        setPage={setPage}
                        resetFilter={resetFilter}
                        setLoading={setLoading}
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                        setUserListRequested={setUserListRequested}
                        establishmentUuids={establishmentUuids!}
                        setEstablishmentUuids={setEstablishmentUuids}
                        prevEstablishmentUuids={prevEstablishmentUuids!}
                        setPrevEstablishmentUuids={setPrevEstablishmentUuids}
                        nameSearch={{
                            value: searchName,
                            searchButtonOnClick: () => {
                                setPage(1);
                                setUserListRequested(true);
                            },
                            setSearchText: setSearchName,
                            searchPlaceholder: 'First Name',

                        }}
                        userListDetails={userListDetails}
                    />
                }
                body={
                    <>
                        {
                            isArrayEmpty(userListDetails?.users) || loading ?
                                <DataNotFound loading={loading} title={productListMessage.current} />
                                :
                                <MeraaiTable
                                    columns={columns}
                                    dataSource={addKeyToDataList(userListDetails?.users!)}
                                    pagination={{
                                        pageSize,
                                        current: page,
                                        total: userListDetails?.page_count!! * pageSize,
                                        onChange: (pageValue, pageSizeValue) => {
                                            setPage(pageValue);
                                            setPageSize(pageSizeValue);
                                            setUserListRequested(true);
                                        },
                                    }}
                                />
                        }
                    </>
                }
            />

            {
                selectedUserName ?
                    /* Edit User */
                    <ModifyUser
                        mode={ModifyUserMode.EDIT}
                        username={selectedUserName}
                        accessibleRoles={accessibleRoles}
                        chosenEstablishment={props.chosenEstablishment}
                        modifyUserDrawerVisibility={modifyUserDrawerVisibility}
                        setModifyUserDrawerVisibility={setModifyUserDrawerVisibility}
                        selectedRole={selectedRole}
                        setUserListRequested={setUserListRequested}
                    /> : <></>
            }

        </div>
    );
};

export default UserListing;