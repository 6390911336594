/* eslint react/prop-types: 0 */

import { Col, Divider, Row } from 'antd';
import React from 'react';

interface RowColDataProps {
    column?: {
        display: string
    }[];
    data: {
        value: React.ReactNode;
        styleName: string;
        style?: React.CSSProperties | undefined;
        xsSize?: number;
        mdSize?: number;

    }[];
}

function RowColData(props: RowColDataProps) {
    return (
        <>
            {
                props?.column && <Row>
                    {
                        props.column.map((eachItem, index) => <Col key={index} xs={8} className="bold-label">
                            {eachItem.display}
                        </Col>)
                    }
                    <Divider style={{ margin: '10px 0 0px 0' }} />
                </Row>
            }

            <Row gutter={[12, 12]} className="order-details-table-content">
                {
                    props.data.map((datum, index) => <Col key={index} xs={datum.xsSize ?? 24} style={datum.style} className={datum.styleName} md={datum.mdSize || 12} >
                        {datum.value}
                    </Col>)
                }
            </Row>
        </>

    );
}

export default RowColData;
