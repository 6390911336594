import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import ConfigContext from '../config/ConfigContext';
import { PathConfigData } from '../config/PathConfigService';
import { Path } from './Path';

function ProtectedRoute() {
    const location = useLocation();
    const path = location.pathname;
    const currentRole = useAppSelector(state => state.login.role);
    const authToken = useAppSelector(state => state.auth.authToken);
    const validAuth = authToken.length > 0;
    const pageConfigs = new PathConfigData(currentRole);
    const pageConfig = pageConfigs.getConfigForPath(path);
    return validAuth ? (
        pageConfigs.isPathAccessible(path) ? (
            <ConfigContext.Provider value={pageConfig}>
                <Outlet />
            </ConfigContext.Provider>
        ) : (
            <Navigate to={Path.LOGIN} />
        )
    ) : (
        <Navigate to={Path.LOGIN} />
    );
}
export default ProtectedRoute;
