import React from 'react';

interface IframeProps {
    src: string;
    width: string | number;
    height: string | number;

}

export const Iframe = (props: IframeProps) => {
    return (
        <iframe className='meraai-border' src={props.src} height={props.height} width={props.width} />
    );
};