import { OrderStates } from '../../../hubsAndOutlets/utils';

export interface OrderListingProps {
    type: string;
    selectedEstablishmentUuid?: string;
    states?: OrderStates[];
    defaultState?: OrderStates;
    getOrdersOnLaunch?: boolean;
    fromDate?: number;
    toDate?: number;
}

export const orderStatus = 'CONFIRMED';
export const listingType = 'SOURCED';