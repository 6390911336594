import { CategoryDetails } from '../components/category/types';
import { Field } from '../components/orders/listing/ListingData';
import { Establishment } from '../Types';

export type BulkProductCreationResponse = {
    id: number;
    sku: string;
    name: string;
    price: number;
    maximum_retail_price: number;
    brand: string;
    image: string;
};

/*
This type represents the data that comes from the excel sheet
*/
export type CreateProductDetails = {
    key?: number;
    id: number;
    name: string;
    price: number;
    mrp: number;
    sku: string;
    hsn: string;
    ean: string;
    gst: number;
    cess: number;
    brand: string;
    categories?: CategoryDetails[];
    carton_capacity: number | undefined;
    image: string;
    metadata: Metadata
};

export interface ProductListResponse {
    products: ListProductDetail[];
    establishment: Establishment;
    page_count: number;
    page_size: number;
    next_page: boolean;
}

export interface Metadata {
    app_display_label?: string;
    days_until_returnable?: number;
}

export interface ListProductDetail {
    id: number;
    active: boolean;
    existing?: boolean;
    brand: string;
    categories?: CategoryDetails[];
    fields: Field[];
    hsn: string;
    ean: string;
    gst: number;
    cess: number;
    image: string;
    maximum_retail_price: number;
    order_quantity_multiple: number;
    name: string;
    price: number;
    seller_price: number;
    sku: string;
    metadata: {
        app_display_label: string;
        days_until_returnable: number;
    };
}

export enum ProductListingType {
    NAME = 'NAME',
    BRAND = 'BRAND',
}