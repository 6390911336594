import React from 'react';
import { Form, InputNumber, Tag } from 'antd';
import { ConfigDetailsProps } from './types';
import { ConfigProperties } from './utils';
import { DetailsCard } from '../../../../common/detailsCard';
import { DetailsCardProps } from '../../../../common/detailsCard/types';


export function ConfigDetails(props: ConfigDetailsProps) {
    const { configProperties: cp, setConfigProperties: setCp, form } = props;

    const changeValue = (property: keyof ConfigProperties, value: any) => {
        const updated = {
            ...cp,
            [property]: { ...cp[property], value, changed: true },
        };
        setCp(updated);
    };


    // Variables
    const configDetails: DetailsCardProps =
    {
        data: [{
            show: cp.mrpDiscountPercentage?.show,
            label: 'MRP Discount (%)',
            value: <Form.Item name="mrpDiscountPercentage">
                <InputNumber
                    disabled={cp.mrpDiscountPercentage?.disabled}
                    maxLength={3}
                    max={100}
                    min={0}
                    controls={false}
                    addonAfter={'%'}
                    style={{ width: '100%' }}
                    placeholder="MRP Discount Percentage"
                    value={cp.mrpDiscountPercentage?.value}
                    onChange={value => changeValue('mrpDiscountPercentage', value)}
                />
            </Form.Item>,
        },
        {
            show: cp.creditLimit?.show,
            label: 'Credit Limit',
            value: <>
                <Form.Item name="creditLimit">
                    <InputNumber
                        disabled={cp.creditLimit?.disabled}
                        min={0}
                        max={50000}
                        style={{ width: '100%' }}
                        placeholder="Credit Limit"
                        controls={false}
                        value={cp.creditLimit?.value}
                        onChange={value => changeValue('creditLimit', value)}
                    />
                </Form.Item>
                <span className='font-semibold'>
                    Available Credit: <Tag color="gold">{cp.credit?.value}</Tag>
                </span>
            </>,
        },
        {
            show: cp.creditCyclePeriod?.show,
            label: 'Credit Cycle Period (in days)',
            value: <Form.Item name="creditCyclePeriod">
                <InputNumber
                    disabled={cp.creditCyclePeriod?.disabled}
                    min={0}
                    max={50000}
                    style={{ width: '100%' }}
                    placeholder="Credit Cycle Period"
                    controls={false}
                    value={cp.creditCyclePeriod?.value}
                    onChange={value => changeValue('creditCyclePeriod', value)}
                />
            </Form.Item>,
        }],
    };


    // useEffects
    React.useEffect(() => {
        form.setFieldsValue({
            mrpDiscountPercentage: cp.mrpDiscountPercentage?.value,
            creditLimit: cp.creditLimit?.value,
            creditCyclePeriod: cp.creditCyclePeriod?.value,
        });
    }, [cp]);

    return (
        <>
            <DetailsCard
                removePadding
                labelStyleClass='xs:w-full lg:w-1/3'
                valueStyleClass='xs:w-full lg:w-1/3'
                data={
                    configDetails?.data?.filter((datum) => datum?.show !== false).map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey font-semibold'>{cardDatum.label}</label>,

                            value: cardDatum.value,
                        };
                    })
                }
            />
        </>
    );
}
