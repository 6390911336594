import { Field } from '../../../../../types/Field';
import { ImageFile } from '../../../../common/ImageRenderer';
import { UpdateProperties } from '../../../../../types/Field';
import { getSourcingEstablishment } from '.';

export class EstablishmentProperties {
    establishmentName?: Field<string>;

    establishmentImage?: Field<ImageFile>;

    address?: Field<string>;

    stateCode?: Field<string>;

    pinCode?: Field<string>;

    latitude?: Field<number>;

    longitude?: Field<number>;

    sourcingHubUuid?: Field<KeyVal>;

    constructor(establishmentProperties: EstablishmentProperties) {
        this.establishmentName = new Field(establishmentProperties.establishmentName);
        this.establishmentImage = new Field(establishmentProperties.establishmentImage);
        this.address = new Field(establishmentProperties.address);
        this.stateCode = new Field(establishmentProperties.stateCode);
        this.pinCode = new Field(establishmentProperties.pinCode);
        this.latitude = new Field(establishmentProperties.latitude);
        this.longitude = new Field(establishmentProperties.longitude);
        this.sourcingHubUuid = new Field(establishmentProperties.sourcingHubUuid);
    }

    public setValues?(establishment: EstablishmentResponse) {
        let sourcingEstablishmentUuid;
        let sourcingEstablishmentName;

        if (establishment.sourcing_establishments === null) {
            sourcingEstablishmentUuid = sourcingEstablishmentName = '';
        } else {
            sourcingEstablishmentUuid = establishment.sourcing_establishments?.[0].uuid;
            sourcingEstablishmentName = getSourcingEstablishment(sourcingEstablishmentUuid);
        }

        this.establishmentName?.setInitialValue?.(establishment.name, new UpdateProperties('Name', 'name'));
        this.establishmentImage?.setInitialValue?.(
            { url: establishment.metadata?.image_url },
            new UpdateProperties('Establishment Image', 'establishment_image'),
        );
        this.address?.setInitialValue?.(establishment.address, new UpdateProperties('Address', 'address'));
        this.stateCode?.setInitialValue?.(establishment.state_code, new UpdateProperties('State', 'state_code'));
        this.pinCode?.setInitialValue?.(establishment.pin_code, new UpdateProperties('Pin Code', 'pin_code'));
        this.latitude?.setInitialValue?.(
            establishment.location_latitude,
            new UpdateProperties('Location Lat', 'latitude'),
        );
        this.longitude?.setInitialValue?.(
            establishment.location_longitude,
            new UpdateProperties('Location Lng', 'longitude'),
        );
        this.sourcingHubUuid?.setInitialValue?.(
            { key: sourcingEstablishmentUuid!!, val: sourcingEstablishmentName },
            new UpdateProperties('Sourcing Hub', 'sourcing_establishments'),
        );
    }
}
