import React, { ReactNode } from 'react';
import { BsCardChecklist, BsShopWindow } from 'react-icons/bs';
import { FaMoneyBill, FaUserFriends } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import { Path } from '../../../router/Path';
import { RiShieldUserFill, RiStackFill } from 'react-icons/ri';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { AiOutlineRotateLeft } from 'react-icons/ai';

export enum MenuItems {
    ANALYTICS = 'analytics',
    APPLICANTS = 'applicants',
    ORDERS = 'orders',
    HUBS_ORDER = 'hubs-orders',
    OUTLETS_ORDER = 'outlets-orders',
    SELLER_HUBS_ORDER = 'seller-hubs-orders',

    PURCHASE_ORDERS = 'purchase-orders',
    HUBS_PURCHASE_ORDERS = 'hubs-purchase-orders',
    SELLER_HUBS_PURCHASE_ORDERS = 'seller-hubs-purchase-orders',

    ESTABLISHMENTS = 'establishments',
    HUB_ESTABLISHMENT = 'hubs-establishments',
    OUTLET_ESTABLISHMENT = 'outlets-establishments',
    SELLER_HUB_ESTABLISHMENT = 'seller-hubs-establishments',

    FINANCE = 'finance',
    INVENTORY = 'inventory',
    CATEGORY = 'categories',
    RETURNS = 'returns',

    USER_LISTING = 'users',

    NOTIFICATIONS = 'notifications',
    SETTINGS = 'settings',
}


export interface MenuItemDetailsType {
    key: MenuItems;
    path?: Path;
    icon?: React.ReactNode;
    label: ReactNode;
    placement?: 'TOP' | 'BOTTOM';
    children?: MenuItemDetailsType[];
}

export const MenuItemList = {
    ANALYTICS: {
        key: MenuItems.ANALYTICS,
        display: 'Analytics',
    },
    APPLICANTS: {
        key: MenuItems.APPLICANTS,
        display: 'Applicants',
    },
    ORDERS: {
        key: MenuItems.ORDERS,
        display: 'Orders',
        children: {
            HUBS: {
                key: MenuItems.HUBS_ORDER,
                display: 'Hubs',
            },
            OUTLETS: {
                key: MenuItems.OUTLETS_ORDER,
                display: 'Outlets',
            },
            SELLER_HUBS: {
                key: MenuItems.SELLER_HUBS_ORDER,
                display: 'Seller Hubs',
            },
        },
    },
    PURCHASE_ORDERS: {
        key: MenuItems.PURCHASE_ORDERS,
        display: 'Purchase Orders',
        children: {
            HUBS: {
                key: MenuItems.HUBS_PURCHASE_ORDERS,
                display: 'Hubs',
            },
            SELLER_HUBS: {
                key: MenuItems.SELLER_HUBS_PURCHASE_ORDERS,
                display: 'Seller Hubs',
            },
        },
    },
    ESTABLISHMENTS: {
        key: MenuItems.ESTABLISHMENTS,
        display: 'Establishments',
        children: {
            HUBS: {
                key: MenuItems.HUB_ESTABLISHMENT,
                display: 'Hubs',
            },
            OUTLETS: {
                key: MenuItems.OUTLET_ESTABLISHMENT,
                display: 'Outlets',
            },
            SELLER_HUBS: {
                key: MenuItems.SELLER_HUB_ESTABLISHMENT,
                display: 'Seller Hubs',
            },
        },
    },
    FINANCE: {
        key: MenuItems.FINANCE,
        display: 'Finance',
    },
    INVENTORY: {
        key: MenuItems.INVENTORY,
        display: 'Inventory',
    },
    CATEGORY: {
        key: MenuItems.CATEGORY,
        display: 'Categories',
    },
    RETURNS: {
        key: MenuItems.RETURNS,
        display: 'Returns',
    },
    USER_LISTING: {
        key: MenuItems.USER_LISTING,
        display: 'Users',
    },
    NOTIFICATIONS: {
        key: MenuItems.NOTIFICATIONS,
        display: 'Notifications',
    },
    SETTINGS: {
        key: MenuItems.SETTINGS,
        display: 'Settings',
    },
};

export const MenuItemDetails: MenuItemDetailsType[] = [
    {
        key: MenuItemList.ANALYTICS.key,
        path: Path.ANALYTICS,
        icon: <MdDashboard />,
        label: MenuItemList.ANALYTICS.display,
        placement: 'TOP',
    },
    {
        key: MenuItemList.APPLICANTS.key,
        path: Path.APPLICANTS,
        icon: <RiShieldUserFill />,
        label: MenuItemList.APPLICANTS.display,
        placement: 'TOP',
    },
    {
        key: MenuItemList.USER_LISTING.key,
        path: Path.USER_LISTING,
        icon: <FaUserFriends />,
        label: MenuItemList.USER_LISTING.display,
        placement: 'TOP',
    },
    {
        key: MenuItemList.ORDERS.key,
        path: Path.ORDER_LIST,
        icon: <HiOutlineClipboardList />,
        label: MenuItemList.ORDERS.display,
        placement: 'TOP',
        children: [
            {
                key: MenuItemList.ORDERS.children.HUBS.key,
                path: Path.HUB_ORDER_LIST,
                label: MenuItemList.ORDERS.children.HUBS.display,
            },
            {
                key: MenuItemList.ORDERS.children.OUTLETS.key,
                path: Path.OUTLET_ORDER_LIST,
                label: MenuItemList.ORDERS.children.OUTLETS.display,
            },
            {
                key: MenuItemList.ORDERS.children.SELLER_HUBS.key,
                path: Path.SELLER_HUB_ORDER_LIST,
                label: MenuItemList.ORDERS.children.SELLER_HUBS.display,
            },
        ],
    },
    {
        key: MenuItemList.PURCHASE_ORDERS.key,
        path: Path.PURCHASE_ORDER_LIST,
        icon: <HiOutlineClipboardList />,
        label: MenuItemList.PURCHASE_ORDERS.display,
        placement: 'TOP',
        children: [
            {
                key: MenuItemList.PURCHASE_ORDERS.children.HUBS.key,
                path: Path.HUB_PURCHASE_ORDER_LIST,
                label: MenuItemList.PURCHASE_ORDERS.children.HUBS.display,
            },
            {
                key: MenuItemList.PURCHASE_ORDERS.children.SELLER_HUBS.key,
                path: Path.SELLER_HUB_PURCHASE_ORDER_LIST,
                label: MenuItemList.PURCHASE_ORDERS.children.SELLER_HUBS.display,
            },
        ],
    },
    {
        key: MenuItemList.ESTABLISHMENTS.key,
        path: Path.ESTABLISHMENTS,
        icon: <BsShopWindow />,
        label: MenuItemList.ESTABLISHMENTS.display,
        placement: 'TOP',
        children: [
            {
                key: MenuItemList.ESTABLISHMENTS.children.HUBS.key,
                path: Path.HUB_ESTABLISHMENT_LIST,
                label: MenuItemList.ESTABLISHMENTS.children.HUBS.display,
            },
            {
                key: MenuItemList.ESTABLISHMENTS.children.OUTLETS.key,
                path: Path.OUTLET_ESTABLISHMENT_LIST,
                label: MenuItemList.ESTABLISHMENTS.children.OUTLETS.display,
            },
            {
                key: MenuItemList.ESTABLISHMENTS.children.SELLER_HUBS.key,
                path: Path.SELLER_HUB_ESTABLISHMENT_LIST,
                label: MenuItemList.ESTABLISHMENTS.children.SELLER_HUBS.display,
            },
        ],
    },
    {
        key: MenuItemList.FINANCE.key,
        path: Path.FINANCE,
        icon: <FaMoneyBill />,
        label: MenuItemList.FINANCE.display,
        placement: 'TOP',
    },
    {
        key: MenuItemList.INVENTORY.key,
        path: Path.INVENTORY,
        icon: <BsCardChecklist />,
        label: MenuItemList.INVENTORY.display,
        placement: 'TOP',
    },
    {
        key: MenuItemList.CATEGORY.key,
        path: Path.CATEGORY,
        icon: <RiStackFill />,
        label: MenuItemList.CATEGORY.display,
        placement: 'TOP',
    },
    {
        key: MenuItemList.RETURNS.key,
        path: Path.RETURNS,
        icon: <AiOutlineRotateLeft />,
        label: MenuItemList.RETURNS.display,
        placement: 'TOP',
    },

    // TODO
    /** Once you have implemented the functionality, you should uncomment this code.*/
    // {
    //     key: MenuItemList.NOTIFICATIONS.key,
    //     path: Path.NOTIFICATIONS,
    //     icon: <FaRegBell />,
    //     label: MenuItemList.NOTIFICATIONS.display,
    //     placement: 'BOTTOM',
    // },
    // {
    //     key: MenuItemList.SETTINGS.key,
    //     path: Path.SETTINGS,
    //     icon: <IoSettingsSharp />,
    //     label: MenuItemList.SETTINGS.display,
    //     placement: 'BOTTOM',
    // },
];


export interface AppSideMenuProps {
    toggleSider: () => void;
    itemPlacement: 'TOP' | 'BOTTOM';
}
