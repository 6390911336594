export enum CollectionSourceType {
    CREDIT = 'CREDIT',
    ORDER = 'ORDER',
}

export class CollectionSource {

    static CREDIT = new CollectionSource({
        type: CollectionSourceType.CREDIT,
        display: 'Credit',
        url(uuid) {
            return `/establishments/${uuid}/OUTLET?type=RECEIVABLES`;
        },
        paymentApprovalText: 'Activate Credit',
        clearanceModalTitle: 'Credit Collection',
    });

    static ORDER = new CollectionSource({
        type: CollectionSourceType.ORDER,
        display: 'Order',
        url(uuid) {
            return `/order/${uuid}`;
        },
        rejectMessage: 'Clicking on Yes would create a credit based receivable for the outlet',
        paymentApprovalText: 'Approve Payment',
        clearanceModalTitle: 'Collect Payment',
    });

    type: CollectionSourceType;

    display: string;

    rejectMessage?: string;

    paymentApprovalText?: string;

    clearanceModalTitle?: string;

    url: (uuid: string) => string;

    constructor(source: CollectionSource) {
        this.type = source.type;
        this.display = source.display;
        this.rejectMessage = source.rejectMessage;
        this.paymentApprovalText = source.paymentApprovalText;
        this.clearanceModalTitle = source.clearanceModalTitle;
        this.url = source.url;
    }
}