import { Brand } from '../../../../../orders/listing/ListingData';

export enum BrandStatusType {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export interface BrandCardProps {
    brand: Brand;
    setSelectedBrand: React.Dispatch<React.SetStateAction<Brand | undefined>>;
}

export type LocationState = {
    createBrand: boolean;
};