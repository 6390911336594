import { RcFile } from 'antd/lib/upload';

export interface DocumentProps {
    document: MetadataDocument;
    displayValue?: string;
    setValueInDocument?: (document: MetadataDocument, value: string) => void;
    uploadFile?: (document: MetadataDocument, file: RcFile) => void;
}

export interface MetadataUploaderProps {
    documents: MetadataDocument[];
    updateDocuments: React.Dispatch<React.SetStateAction<MetadataDocument[]>>;
}

export type DocumentType = 'IMAGE' | 'DOC' | 'REMARK' | 'DROP_DOWN' | 'AMOUNT' | 'TRANSACTION_ID';

export const multipleValuesDocTypes: DocumentType[] = ['IMAGE', 'DOC'];

export interface DocumentConfig {
    global: boolean;
    type: DocumentType;
    display: string;
    datumName: string;
    datumType: string;
}

export interface DropDownProperties {
    values: Array<string>,
    allowCustomInput: boolean,
    isInputCustom?: boolean,
}

export interface DocumentValue {
    name?: string;
    value: string;
}

export const documentConfigs: DocumentConfig[] = [
    {
        global: true,
        type: 'IMAGE',
        display: 'Image',
        datumName: 'image',
        datumType: 'IMAGE_URL',
    },
    {
        global: true,
        type: 'DOC',
        display: 'Document',
        datumName: 'file',
        datumType: 'FILE_URL',
    },
    {
        global: true,
        type: 'REMARK',
        display: 'Remarks',
        datumName: 'remark',
        datumType: 'STRING',
    },
    {
        global: true,
        type: 'DROP_DOWN',
        display: 'Options',
        datumName: 'option',
        datumType: 'STRING',
    },
    {
        global: false,
        type: 'AMOUNT',
        display: 'Amount',
        datumName: 'amount',
        datumType: 'FLOAT',
    },
    {
        global: false,
        type: 'TRANSACTION_ID',
        display: 'Transaction ID',
        datumName: 'transactionId',
        datumType: 'STRING',
    },
];

export interface MetadataDocument {
    id: string;
    type: DocumentType; // Should be equal to one of the fileTypes in DocumentConfig
    name?: string; // name of the document
    purpose?: string;
    values?: DocumentValue[]; // For images and files, this is a url, while for text it is a string
    required: boolean;
    dropdownProperties?: DropDownProperties;
    multiple?: boolean;
    maxFiles?: number;
}