import React from 'react';
import { BsDot } from 'react-icons/bs';
import { CarouselContentType } from './types';

export const carouselContent: CarouselContentType[] = [
    {
        title: 'B2B Buyers & Sellers marketplace for Bharat.',
        subTitle: <>
            <span>Launch</span>
            <BsDot />
            <span>Build</span>
            <BsDot />
            <span>Scale</span>
        </>,
    },
    {
        title: 'Reimagining how businesses buy from SMEs.',
    },
    {
        title: 'On a mission to create wealth for SMEs and SMBs.',
    },
];