import React from 'react';

export const EmptyIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="188" height="188" viewBox="0 0 188 188">
                <g id="Group_27827" data-name="Group 27827" transform="translate(-109.371 -224.371)">
                    <g id="Rectangle_19586" data-name="Rectangle 19586" transform="translate(109.371 224.371)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                        <rect width="188" height="188" stroke="none" />
                        <rect x="0.5" y="0.5" width="187" height="187" fill="none" />
                    </g>
                    <g id="Group_27737" data-name="Group 27737" transform="translate(116.975 249.254)">
                        <g id="Group_27745" data-name="Group 27745" transform="translate(14.35 2.72)">
                            <path id="Path_24432" data-name="Path 24432" d="M744.937-124.4a68.421,68.421,0,0,0-50.958,22.632H774.6v2.5H691.831A67.806,67.806,0,0,0,682.412-84H774.6v2.5H681.357a66.847,66.847,0,0,0-4.1,15.261H790.707v2.5H676.942a67.523,67.523,0,0,0-.392,7.245,67.98,67.98,0,0,0,.489,8.017h72.337v2.5H677.367a67.011,67.011,0,0,0,4.3,15.261H774.6v2.5H682.767a67.908,67.908,0,0,0,9.7,15.261H774.6v2.5H694.688a68.418,68.418,0,0,0,50.249,21.857c37.768,0,68.387-30.4,68.387-67.9S782.7-124.4,744.937-124.4Z" transform="translate(-676.55 124.399)" fill="#fff2e3" />
                        </g>
                        <g id="Group_27756" data-name="Group 27756" transform="translate(82.492 41.189)">
                            <g id="Group_27746" data-name="Group 27746">
                                <circle id="Ellipse_238" data-name="Ellipse 238" cx="32.032" cy="32.032" r="32.032" transform="translate(1.333 1.333)" fill="#fff" />
                                <path id="Path_24433" data-name="Path 24433" d="M735.727-42.776a33.354,33.354,0,0,1-27.583-14.515,33.122,33.122,0,0,1-5.25-24.982,33.142,33.142,0,0,1,13.952-21.379,33.1,33.1,0,0,1,24.985-5.249A33.141,33.141,0,0,1,763.209-94.95h0a33.394,33.394,0,0,1-8.7,46.361A33.173,33.173,0,0,1,735.727-42.776Zm-.116-64.062a30.493,30.493,0,0,0-17.27,5.368,30.51,30.51,0,0,0-12.848,19.683,30.5,30.5,0,0,0,4.839,23,30.741,30.741,0,0,0,42.681,8.012,30.742,30.742,0,0,0,8.012-42.683h0A30.487,30.487,0,0,0,741.341-106.3,30.959,30.959,0,0,0,735.61-106.838Z" transform="translate(-702.311 109.486)" fill="#f69621" />
                            </g>
                            <g id="Group_27747" data-name="Group 27747" transform="translate(9.717 9.716)">
                                <path id="Path_24434" data-name="Path 24434" d="M729.682-58.517a23.762,23.762,0,0,1-4.411-.413,23.487,23.487,0,0,1-15.153-9.89A23.487,23.487,0,0,1,706.4-86.526a23.492,23.492,0,0,1,9.89-15.15,23.455,23.455,0,0,1,17.706-3.723,23.488,23.488,0,0,1,15.153,9.89,23.669,23.669,0,0,1-6.168,32.856A23.453,23.453,0,0,1,729.682-58.517Zm-.1-44.649a20.847,20.847,0,0,0-11.809,3.672A20.867,20.867,0,0,0,709-86.037,20.853,20.853,0,0,0,712.3-70.312a20.855,20.855,0,0,0,13.454,8.782,20.852,20.852,0,0,0,15.725-3.3,21.019,21.019,0,0,0,5.477-29.181h0A20.853,20.853,0,0,0,733.5-102.8,21.166,21.166,0,0,0,729.586-103.166Z" transform="translate(-705.983 105.814)" fill="#f69621" />
                            </g>
                            <g id="Group_27748" data-name="Group 27748" transform="translate(49.341 61.66)">
                                <rect id="Rectangle_19572" data-name="Rectangle 19572" width="15.367" height="32.198" rx="7" transform="translate(0 8.675) rotate(-34.369)" fill="#f69621" />
                            </g>
                            <g id="Group_27751" data-name="Group 27751" transform="translate(20.425 25.127)">
                                <g id="Group_27749" data-name="Group 27749" transform="translate(0 0.165)">
                                    <rect id="Rectangle_19573" data-name="Rectangle 19573" width="1.322" height="8.665" transform="matrix(0.684, -0.729, 0.729, 0.684, 0, 0.964)" fill="#f69621" />
                                </g>
                                <g id="Group_27750" data-name="Group 27750" transform="translate(0.165)">
                                    <path id="Path_24435" data-name="Path 24435" d="M711.059-92.766l-.966-.9,5.927-6.319.966.9Z" transform="translate(-710.093 99.989)" fill="#f69621" />
                                </g>
                            </g>
                            <g id="Group_27754" data-name="Group 27754" transform="translate(36.832 24.604)">
                                <g id="Group_27752" data-name="Group 27752" transform="translate(0 0.165)">
                                    <rect id="Rectangle_19574" data-name="Rectangle 19574" width="1.322" height="8.663" transform="matrix(0.684, -0.729, 0.729, 0.684, 0, 0.964)" fill="#f69621" />
                                </g>
                                <g id="Group_27753" data-name="Group 27753" transform="translate(0.165)">
                                    <path id="Path_24436" data-name="Path 24436" d="M717.26-92.964l-.966-.9,5.927-6.319.966.9Z" transform="translate(-716.294 100.187)" fill="#f69621" />
                                </g>
                            </g>
                            <g id="Group_27755" data-name="Group 27755" transform="translate(25.134 40.356)">
                                <path id="Path_24437" data-name="Path 24437" d="M712.686-90.007,711.81-91c.082-.069,7.956-6.892,16.206-.519l-.809,1.045C719.838-96.161,712.98-90.261,712.686-90.007Z" transform="translate(-711.81 94.233)" fill="#f69621" />
                            </g>
                        </g>
                        <g id="Group_27757" data-name="Group 27757" transform="translate(139.79 28.533)">
                            <path id="Path_24438" data-name="Path 24438" d="M724.386-114.564h3.567l-.525,3.776h-3.462Z" transform="translate(-723.966 114.564)" fill="#dcdedd" />
                        </g>
                        <g id="Group_27758" data-name="Group 27758" transform="translate(7.534 20.948)">
                            <path id="Path_24439" data-name="Path 24439" d="M671.839-113.652h3.567l-.525,3.776H671.42Z" transform="translate(-671.42 113.652)" fill="#dcdedd" />
                        </g>
                        <g id="Group_27759" data-name="Group 27759" transform="translate(133.279 0)">
                            <path id="Path_24440" data-name="Path 24440" d="M726.245-115.944a4.745,4.745,0,0,1-4.741-4.741,4.745,4.745,0,0,1,4.741-4.741,4.746,4.746,0,0,1,4.739,4.741A4.746,4.746,0,0,1,726.245-115.944Zm0-6.837a2.1,2.1,0,0,0-2.1,2.1,2.1,2.1,0,0,0,2.1,2.1,2.1,2.1,0,0,0,2.093-2.1A2.1,2.1,0,0,0,726.245-122.781Z" transform="translate(-721.504 125.427)" fill="#f69621" />
                        </g>
                        <g id="Group_27762" data-name="Group 27762" transform="translate(162.58 35.624)">
                            <g id="Group_27760" data-name="Group 27760" transform="translate(0 3.242)">
                                <line id="Line_189" data-name="Line 189" x2="9.283" y2="1.742" transform="translate(0.123 0.65)" fill="#f69621" />
                                <rect id="Rectangle_19575" data-name="Rectangle 19575" width="1.322" height="9.446" transform="matrix(0.185, -0.983, 0.983, 0.185, 0, 1.299)" fill="#dcdedd" />
                            </g>
                            <g id="Group_27761" data-name="Group 27761" transform="translate(3.238)">
                                <line id="Line_190" data-name="Line 190" y1="9.283" x2="1.742" transform="translate(0.655 0.122)" fill="#f69621" />
                                <rect id="Rectangle_19576" data-name="Rectangle 19576" width="9.445" height="1.325" transform="translate(0 9.282) rotate(-79.325)" fill="#dcdedd" />
                            </g>
                        </g>
                        <g id="Group_27765" data-name="Group 27765" transform="translate(0 61.944)">
                            <g id="Group_27763" data-name="Group 27763" transform="translate(0 3.244)">
                                <line id="Line_191" data-name="Line 191" x2="9.283" y2="1.742" transform="translate(0.122 0.649)" fill="#dcdedd" />
                                <rect id="Rectangle_19577" data-name="Rectangle 19577" width="1.322" height="9.446" transform="translate(0 1.3) rotate(-79.376)" fill="#f69621" />
                            </g>
                            <g id="Group_27764" data-name="Group 27764" transform="translate(3.238)">
                                <line id="Line_192" data-name="Line 192" y1="9.283" x2="1.742" transform="translate(0.654 0.123)" fill="#dcdedd" />
                                <rect id="Rectangle_19578" data-name="Rectangle 19578" width="9.445" height="1.325" transform="translate(0 9.282) rotate(-79.325)" fill="#f69621" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

        </>
    );
};