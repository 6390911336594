import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Establishment } from '../Types';

export interface InventoryProductListResponse {
    products: InventoryProduct[];
    establishment: Establishment;
    page_count: number;
    page_size: number;
    next_page: boolean;
}

export interface InventoryProduct {
    id: number;
    sku: string;
    name: string;
    price: number;
    brand: string;
    image: string | undefined;
    stock: number,
    blocked_stock: number,
    available_stock: number,
    required_stock: number,
    diff_stock: number,
    free_stock: number,
    add_to_free_stock: boolean,
    flow: FLOW,
    flowOption: FLOWOPTIONS | undefined,
    message: string,
}

interface StockFlowProperties {
    label: (diffStock?, productName?) => React.ReactNode,
    value: FLOW | FLOWOPTIONS,
    checked?: boolean
    visibility: boolean,
}

export enum FLOW {
    INWARD = 'INWARDS',
    OUTWARD = 'OUTWARDS',
}

export enum FLOWOPTIONS {
    NONE = 'NONE',
    FREE_STOCK = 'FREE_STOCK',
    BLOCK_STOCK = 'BLOCK_STOCK',
    DECREASE_BLOCKED_STOCK = 'DECREASE_BLOCKED_STOCK',
}

export namespace FLOWOPTIONS {
    const reverseMap = new Map<string, FLOWOPTIONS>();
    Object.keys(FLOWOPTIONS).forEach((s: FLOWOPTIONS) => {
        reverseMap.set(s.toString(), s);
    });
    export function valueOf(str: string): FLOWOPTIONS | undefined {
        return reverseMap.get(str);
    }
}

export class InventoryFlow {

    static readonly INWARDS = new InventoryFlow(
        { label: () => <label>Add</label>, value: FLOW.INWARD, visibility: true },
        [
            {
                label: (diffStock: number, productName: string) => <>
                    <label>Buffer stock</label>&nbsp;
                    <Tooltip title={`If checked, ${diffStock} units of ${productName} will be utilized for the next order placed by the outlets and won't be sent to the sellers.`} className="pointer">
                        <ExclamationCircleOutlined className="pointer" />
                    </Tooltip>

                </>,
                value: FLOWOPTIONS.FREE_STOCK,
                visibility: false,
                checked: false,
            },
            { label: () => <label>Block Stock</label>, value: FLOWOPTIONS.BLOCK_STOCK, visibility: false },
        ],
        'Stock to add',
    );

    static readonly OUTWARDS = new InventoryFlow(
        { label: () => <label>Remove</label>, value: FLOW.OUTWARD, visibility: true },
        [
            { label: () => <label>Decrease Blocked stock</label>, value: FLOWOPTIONS.DECREASE_BLOCKED_STOCK, visibility: false },
        ],
        'Stock to remove',
    );


    private constructor(

        public readonly stockFlow: StockFlowProperties,
        
        /*
        Not in use any more in terms of the UI, because free stock is managed automatically based on the demand.
        */
        public readonly stockFlowOptions: StockFlowProperties[],


        public readonly stockColDisplay: string,
    ) { }

    public static getStockFlows = () => {
        return [
            InventoryFlow.INWARDS.stockFlow,
            InventoryFlow.OUTWARDS.stockFlow,
        ];
    };

    public static getStockFlowsOptions = (stockFlow: FLOW) => {

        if (stockFlow == FLOW.INWARD) {
            return InventoryFlow.INWARDS.stockFlowOptions.filter(option => option.visibility);
        } else if (stockFlow == FLOW.OUTWARD) {
            return InventoryFlow.OUTWARDS.stockFlowOptions.filter(option => option.visibility);
        } else {
            return [];
        }
    };
}

