/* eslint react/prop-types: 0 */

import React from 'react';
import { Button, Image } from 'antd';
import { Establishment } from '../../../../../Types';
import { useNavigate } from 'react-router-dom';
import { MeraaiTable } from '../../../../common/templates/meraaiTable';
import { layout, style } from '../../../../../styles/css/style';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import { isArrayEmpty } from '../../../../../Utils';
import { EstablishmentListProps } from './types';

const EstablishmentList = (props: EstablishmentListProps) => {

    const navigate = useNavigate();

    const updateEstablishmentCheckedProperty = (establishmentList: Establishment[]) => {

        if (props.datasource) {
            const selectedEstablishmentUuids = establishmentList.map((eachItem) => eachItem.uuid);

            props.datasource.establishments.forEach(establishment => establishment.checked = selectedEstablishmentUuids.includes(establishment.uuid));
            props.setDatasource({ ...props.datasource, establishments: props.datasource.establishments });
        }
    };

    const defaultColumns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image',
            rowVisibility: [Device.DESKTOP],
            render: (image) => <Image src={image} width={50} height={50} />,
        },
        {
            title: 'Name',
            enableSearch: props.nameSearch ? true : false,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, establishment: Establishment) => <a
                onClick={() => {
                    navigate(
                        `/establishments/${establishment.uuid}/${establishment.type}?type=INFO`,
                    );
                }}
            >{establishment.name}</a>,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            rowVisibility: [Device.DESKTOP],
            render: (address) => <label>{address}</label>,
        },
    ];

    return (
        <>
            <MeraaiTable
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: props.datasource?.establishments.filter(establishment => establishment.checked).map(establishment => establishment.key!),
                    onChange(_, selectedRows) {
                        updateEstablishmentCheckedProperty(selectedRows);
                    },
                }}
                scroll={{ x: 'fit-content' }}
                searchFilter={props.nameSearch}
                columns={defaultColumns}
                dataSource={props.datasource?.establishments!}
                pagination={props.pagination}
            />
            {
                props.config?.mapEstablishments &&

                <div className={`${layout.flex.end} ${props.onClick ? 'flex' : 'hidden'}`}>
                    {
                        !isArrayEmpty(props.datasource?.establishments) && <Button
                            className={`w-full xs:w-1/2 md:w-1/4 lg:w-1/5 xl:w-1/6 ${style.meraaiDangerButton}`}
                            size='large'
                            disabled={!props.datasource?.establishments.some(item => item.checked)}
                            onClick={props.onClick}
                        >
                            {props.buttonText}
                        </Button>
                    }
                </div>
            }
        </>
    );
};

export default EstablishmentList;