import { Field, UpdateProperties } from '../../../../../types/Field';
import { ImageFile } from '../../../../common/ImageRenderer';

export class UserEstablishmentProperties {
    gstin?: Field<string>;

    aadhaar?: Field<number>;

    aadhaarFrontImage?: Field<ImageFile>;

    aadhaarBackImage?: Field<ImageFile>;

    pan?: Field<string>;

    panImage?: Field<ImageFile>;

    constructor(userEstablishmentProperties: UserEstablishmentProperties) {
        this.gstin = new Field(userEstablishmentProperties.gstin);
        this.aadhaar = new Field(userEstablishmentProperties.aadhaar);
        this.aadhaarFrontImage = new Field(userEstablishmentProperties.aadhaarFrontImage);
        this.aadhaarBackImage = new Field(userEstablishmentProperties.aadhaarBackImage);
        this.pan = new Field(userEstablishmentProperties.pan);
        this.panImage = new Field(userEstablishmentProperties.panImage);
    }

    public setValues?(establishment: EstablishmentResponse) {
        const userEstablishmentDetails = establishment.metadata;

        this.gstin?.setInitialValue?.(userEstablishmentDetails?.gst_in, new UpdateProperties('GSTIN', 'gst_in'));
        this.aadhaar?.setInitialValue?.(
            userEstablishmentDetails?.aadhaar_number,
            new UpdateProperties('Aadhaar', 'aadhaar_number'),
        );
        this.aadhaarFrontImage?.setInitialValue?.(
            { url: userEstablishmentDetails?.aadhaar_front_image_url },
            new UpdateProperties('Aadhaar front side image', 'aadhaar_front_image'),
        );
        this.aadhaarBackImage?.setInitialValue?.(
            { url: userEstablishmentDetails?.aadhaar_back_image_url },
            new UpdateProperties('Aadhaar back side image', 'aadhaar_back_image'),
        );
        this.pan?.setInitialValue?.(
            userEstablishmentDetails?.pan_number,
            new UpdateProperties('Pan Number', 'pan_number'),
        );
        this.panImage?.setInitialValue?.(
            { url: userEstablishmentDetails?.pan_image_url },
            new UpdateProperties('Pan image', 'pan_image'),
        );
    }
}
