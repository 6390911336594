import { Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { layout, style } from '../../styles/css/style';

export const MandatoryTitle = ({ title, description }: { title: string, description?: string }) => {
    return (
        <Tooltip title={description ?? undefined}>
            <label>
                {title}
                <span className="danger-color">*</span>
            </label>
        </Tooltip>
    );
};

export const OptionalTitle = ({ title, description }: { title: ReactNode, description?: ReactNode }) => {
    return (
        <div className={`${layout.flex.start}`}>
            <label>{title}</label>
            {
                description ? <Tooltip title={description}>
                    <FaInfoCircle className={`${style.infoIcon}`} />
                </Tooltip> : <></>
            }

        </div>
    );
};