/* eslint @typescript-eslint/no-shadow: 0 */
import { Table, Modal, Card, Empty, Form, Button, message, Col, Row, Tag, Spin } from 'antd';
import * as React from 'react';
import { MdSettings } from 'react-icons/md';
import {
    CollectionResponse,
    RequestedByPropsType,
    CollectionDetail,
    PaymentResponse,
} from '../../../types/FinanceCredit';
import CollectionModalRow from './CollectionModalRow';
import { putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { COLLECTION_APPROVE, COLLECTION_UPDATE } from '../../../http/EndPoints';
import { AMOUNT_ROUNDING_SCALE, RUPEE_SYMBOL } from '../../../Constants';
import { StateMetadataRenderer } from '../../common/StateMetadataRenderer';
import { FinanceLayerConfig } from '../../config/types/ConfigTypes';
import ConfigContext from '../../config/ConfigContext';
import { addKeyToDataList } from '../../../Utils';
import { CollectionState } from '../CollectionState';
import { formatValue } from '../../../utilities/datumRenderer/config';
import { BsDash } from 'react-icons/bs';
import { ResponseMessage } from '../../../Types';
import { AlignType } from 'rc-table/lib/interface';
import { useNavigate } from 'react-router-dom';
import { CollectionSource } from '../CollectionSource';
import RejectionModal from './rejectionModal';

class CollectionMode {
    static UPDATE = new CollectionMode(
        () => 'Update',
        'transaction-action-modal-card-update-button',
    );

    static REJECT = new CollectionMode(
        () => 'Reject',
        'transaction-action-modal-card-reject-button',
    );

    static APPROVE = new CollectionMode(
        (name) => name ?? 'NA',
        'transaction-action-modal-card-activate-credit-button',
    );

    private constructor(
        readonly displayName: (name?: string) => string,
        readonly buttonClassName: string,
    ) { }
}

type CollectionListProps = {
    page: number;
    setPage: (val: number) => void;
    collectionResponse: CollectionResponse;
    getCollections: () => void;
};

function CollectionList(props: CollectionListProps) {
    const { page, setPage, collectionResponse, getCollections } = props;

    const navigate = useNavigate();

    const [selectedCollection, setSelectedCollection] = React.useState<CollectionDetail>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [allowPaymentApproval, setAllowPaymentApproval] = React.useState<boolean>(false);
    const [actionModalVisible, setActionModalVisible] = React.useState(false);
    const [stateCollectionModalVisible, setStateCollectionModalVisible] = React.useState(false);
    const [updateButtonDisabled, setUpdateButtonDisabled] = React.useState<boolean>(true);
    const [popRejectionPrompt, setPopRejectionPrompt] = React.useState<boolean>(false);

    const financeConfig = React.useContext(ConfigContext) as FinanceLayerConfig;

    function RequestedBy(props: RequestedByPropsType) {
        if (!(props.contact_info && props.name)) {
            return (<BsDash />);
        }
        return (
            <>
                <span>{props.name ?? <BsDash />}</span>
                <br />
                <span>{props.contact_info ?? <BsDash />}</span>
            </>
        );
    }

    const openActionButtonModal = () => {
        setActionModalVisible(true);
    };

    const openShowStateMetaDataModal = () => {
        setStateCollectionModalVisible(true);
    };

    const openModal = (record, source) => {
        setSelectedCollection(collectionResponse?.collections?.find(item => item.uuid === record.uuid));
        if (source === 'TAKE_ACTION') {
            openActionButtonModal();
        } else if (source === 'SHOW_STATE_METADATA') {
            openShowStateMetaDataModal();
        }
    };

    const getCollectionInitiatedDate = () => {
        const initiateDateObj = selectedCollection?.state_metadata.find(
            stateMetadatum => stateMetadatum.state === 'INITIATED',
        );
        return initiateDateObj?.date;
    };

    const handleCancel = () => {
        setActionModalVisible(false);
        setStateCollectionModalVisible(false);
        setUpdateButtonDisabled(true);
        setLoading(false);
        setAllowPaymentApproval(false);
    };

    // If any of the object in config array doesn't hold the value key, it will return that object in an array.
    const getUpdateableConfigsForTheSelectedCollection = () => {
        return selectedCollection?.tender?.configs?.filter(config => !config.value);
    };


    const performUpdateMetaData = updateRequestBody => {
        setLoading(true);

        const responseHandler: ResponseHandler<ResponseMessage> = {
            onResponseSuccess(response: ResponseMessage): void {
                message.success(response.message, 2);
                getCollections();
                if (getUpdateableConfigsForTheSelectedCollection()!.length === updateRequestBody.metadata.length) {
                    setAllowPaymentApproval(true);
                } else {
                    setActionModalVisible(false);
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        putApiCall(COLLECTION_UPDATE(selectedCollection?.uuid), updateRequestBody, responseHandler);
    };

    const handleMetaDataUpdate = formValues => {
        const configNameMap: {} | undefined = selectedCollection?.tender.configs!.reduce((map, config) => {
            map[config.name] = config;
            return map;
        }, {});
        const requestUpdateMetaData = Object.keys(formValues)
            .filter(key => formValues[key] !== undefined)
            .map(key => ({
                name: key,
                value: formatValue(configNameMap![key].type, formValues[key]),
            }));

        const requestBody = {
            metadata: requestUpdateMetaData,
        };
        performUpdateMetaData(requestBody);
    };


    const performActivateCreditTransaction = approvedRequestBody => {
        setLoading(true);
        const endpoint = COLLECTION_APPROVE(
            selectedCollection?.uuid,
            selectedCollection?.payment_identifier,
        );

        const responseHandler: ResponseHandler<PaymentResponse> = {
            onResponseSuccess(response: PaymentResponse): void {
                if (response.source_state === 'CONFIRMED') {
                    message.success(response.tender_response.message, 2);
                } else {
                    message.error(response.tender_response.message, 2);
                }
                setAllowPaymentApproval(false);
                getCollections();
                setLoading(false);
                setActionModalVisible(false);
                setUpdateButtonDisabled(true);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        putApiCall(endpoint, approvedRequestBody, responseHandler);
    };

    const handleMetadataApprove = () => {
        const requestBody = {
            data: [],
        };

        performActivateCreditTransaction(requestBody);
    };

    const findCollectionMode = () => {
        if (allowPaymentApproval) {
            return CollectionMode.APPROVE;
        }
        if (getUpdateableConfigsForTheSelectedCollection()?.length! > 0) {
            return CollectionMode.UPDATE;
        }
        return CollectionMode.APPROVE;
    };

    const collectionTableColumns = [
        {
            title: 'Store Name',
            render: establishment => `${establishment.name}`,
            width: '12%',
            dataIndex: 'establishment',
            align: 'center' as AlignType,
        },
        {
            title: 'Address',
            render: establishment => `${establishment.address}`,
            dataIndex: 'establishment',
            width: '20%',
            align: 'center' as AlignType,
        },
        {
            title: 'Requested by',
            width: '15%',
            render: (_, record: CollectionDetail) => (
                <RequestedBy name={record?.user_metadata?.name} contact_info={record?.user_metadata?.contact_info} />
            ),
            dataIndex: 'requestedBy',
            align: 'center' as AlignType,
        },
        {
            title: 'Source',
            render: (source, record: CollectionDetail) => <a onClick={() => {
                navigate(CollectionSource[source].url(record.source_reference_id));
            }} >
                {CollectionSource[source].display}
            </a >,
            dataIndex: 'source',
            align: 'center' as AlignType,
        },
        {
            title: 'Amount',
            render: amount => `${RUPEE_SYMBOL} ${(amount).toFixed(AMOUNT_ROUNDING_SCALE)}`,
            dataIndex: 'amount',
            align: 'center' as AlignType,
        },
        {
            title: 'Tender',
            render: tender => `${tender.name}`,
            dataIndex: 'tender',
            align: 'center' as AlignType,
        },
        {
            title: '',
            dataIndex: 'uuid',
            width: '25%',
            align: 'center' as AlignType,
            render: (_, record) => (
                <div className="action-col">
                    {record.state === CollectionState.INITIATED && (
                        <Button className="action-col-button" onClick={() => openModal(record, 'TAKE_ACTION')}>
                            Take Action
                        </Button>
                    )}

                    <MdSettings
                        className="action-col-button-info"
                        onClick={() => {
                            openModal(record, 'SHOW_STATE_METADATA');
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <Row className="main-body-content">
                {collectionResponse ? (
                    <Table
                        className="default-table"
                        columns={collectionTableColumns}
                        dataSource={addKeyToDataList(collectionResponse?.collections)}
                        scroll={{ y: 900, x: 900 }}
                        pagination={{
                            pageSize: collectionResponse.page_size,
                            current: page,
                            total: collectionResponse.page_count!! * collectionResponse.page_size,
                            onChange: (page) => {
                                if (page) {
                                    setPage(page);
                                }
                            },
                        }}
                    />
                ) : (
                    <Empty
                        className="main-body-aggregate-empty"
                        image={Empty.PRESENTED_IMAGE_DEFAULT}
                        description='Search for the data using filters mentioned above.'
                    />
                )}
            </Row>

            {/* This modal open up when the user clicks on setting icon.   */}
            <Modal
                className="transaction-state-modal"
                title="State Collection Details"
                open={stateCollectionModalVisible}
                onCancel={handleCancel}
                footer={null}>
                <StateMetadataRenderer stateMetadata={selectedCollection?.state_metadata!} />
            </Modal>

            {/* This modal open up when the user clicks on take action button.   */}
            <Modal
                className="transaction-action-modal"
                title={
                    selectedCollection && (
                        <>
                            <span>{selectedCollection?.tender.name}</span>
                            {findCollectionMode() === CollectionMode.UPDATE && (
                                <Tag className="transaction-action-modal-title" color="cyan">
                                    Note: Fields once updated cannot be modified
                                </Tag>
                            )}
                        </>
                    )
                }
                open={actionModalVisible}
                onCancel={handleCancel}
                destroyOnClose
                footer={null}>
                <Spin spinning={loading}>
                    <div>
                        <Card className="transaction-action-modal-card">
                            {selectedCollection && (
                                <Form
                                    onFinish={
                                        findCollectionMode() === CollectionMode.UPDATE
                                            ? handleMetaDataUpdate
                                            : handleMetadataApprove
                                    }
                                    onValuesChange={(_, allValues) => {
                                        if (Object.values(allValues).filter(value => value).length > 0) {
                                            setUpdateButtonDisabled(false);
                                        } else {
                                            setUpdateButtonDisabled(true);
                                        }
                                    }}>
                                    {selectedCollection?.tender?.configs
                                        ?.filter(eachItem => eachItem.function === 'CAPTURE')
                                        .map((eachItem, index) => {
                                            return (
                                                <CollectionModalRow
                                                    key={index}
                                                    displayName={eachItem.display_name}
                                                    name={eachItem.name}
                                                    value={eachItem.value}
                                                    type={eachItem.type}
                                                    initiateDate={getCollectionInitiatedDate()}
                                                    restrictDateSelecton={
                                                        financeConfig.collections.restrictDateSelection
                                                    }
                                                />
                                            );
                                        })}

                                    <div className="transaction-action-modal-card-update">
                                        <Row gutter={[24, 24]}>
                                            <Col>
                                                <Button
                                                    className={findCollectionMode().buttonClassName}
                                                    htmlType="submit"
                                                    disabled={
                                                        CollectionMode.UPDATE === findCollectionMode() &&
                                                        updateButtonDisabled
                                                    }>
                                                    {findCollectionMode().displayName(CollectionSource[selectedCollection?.source].paymentApprovalText)}
                                                </Button>
                                            </Col>
                                            {findCollectionMode() === CollectionMode.UPDATE && (
                                                <Col>
                                                    <Button
                                                        className={CollectionMode.REJECT.buttonClassName}
                                                        onClick={() => {
                                                            setPopRejectionPrompt(true);
                                                        }}>
                                                        {CollectionMode.REJECT.displayName()}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </Form>
                            )}
                        </Card>
                    </div>
                </Spin>
            </Modal>
            {
                popRejectionPrompt && <RejectionModal
                    collection = {selectedCollection!}
                    getCollections = {getCollections}
                    loading = {loading}
                    setLoading = {setLoading}
                    setActionModalVisible = {setActionModalVisible}
                    popRejectionPrompt = {popRejectionPrompt}
                    setPopRejectionPrompt = {setPopRejectionPrompt}
                />
            }
        </>
    );
}

export default CollectionList;
