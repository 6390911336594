import { RcFile } from 'antd/lib/upload';
import { UPLOAD_FILE } from '../http/EndPoints';
import { postApiCall, ResponseHandler } from '../http/HttpClient';

export enum FileIdentifier {
    OPERATIONS = 'OPERATIONS',
    PROFILE = 'PROFILE',
    BRAND = 'BRAND',
    PRODUCT = 'PRODUCT',
    CATEGORY = 'CATEGORY',
}

export interface FileUploadResponse {
    url: string;
}

export const upload = (type: string, file: RcFile, responseHandler: ResponseHandler<FileUploadResponse>, fileIdentifier: FileIdentifier) => {
    const formData = new FormData();
    formData.append('file', new Blob([file] as any), file.name);
    postApiCall(UPLOAD_FILE(fileIdentifier, type.toUpperCase()), formData, responseHandler);
}; 