export class PurchaseOrderCartItem {
    key?: number;

    id: number;

    brand: string;

    ean: string;

    name: string;

    quantity: number;

    image: string | undefined;

    reference_id: string;

    public constructor(item: PurchaseOrderCartItem) {
        this.key = item.id;
        this.id = item.id;
        this.ean = item.ean;
        this.brand = item.brand;
        this.name = item.name;
        this.quantity = item.quantity;
        this.image = item.image;
        this.reference_id = item.reference_id;
    }

    public setQuantityValue?(quantity: number) {
        this.quantity = quantity;
    }
}
