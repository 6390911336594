import React from 'react';
import { BrandForm } from './BrandForm';
import { Form, Modal, Spin, Switch } from 'antd';
import { BrandModalProps } from './type';

export const BrandModal = (props: BrandModalProps) => {
    return (
        <>
            <Modal
                title={<div className='flex-row-left-align'>
                    {props.mode.modalTitle}
                    {
                        props.mode.showActiveToggle && <Form
                            form={props.brandForm}
                        >
                            <Form.Item
                                name='active'
                                labelAlign='left'
                                className='remove-margin'
                                style={{ paddingLeft: '20px' }}
                            >
                                <Switch
                                    style={{ backgroundColor: '#00b000' }}
                                    checkedChildren={'Active'}
                                    unCheckedChildren={'Inactive'}
                                    defaultChecked={props.selectedBrand?.active}
                                />
                            </Form.Item>
                        </Form>
                    }

                </div>}
                open={props.brandModalVisibility}
                onCancel={props.onModalClose}
                maskClosable={false}
                destroyOnClose={true}
                keyboard={false}
                footer={null}
            >
                <Spin spinning={props.loading} tip='Loading...'>
                    <BrandForm
                        mode={props.mode}
                        selectedBrand={props.selectedBrand}
                        setLoading={props.setLoading}
                        brandForm={props.brandForm}
                        onFormSubmit={props.onFormSubmit}
                    />
                </Spin>
            </Modal>
        </>
    );
};