export interface Tender {
    type: TenderType;
    name: string;
    minimum_amount: number | undefined;
    maximum_amount: number | undefined;
    configs: Array<TenderConfig>;
}

export interface TenderConfig {
    name: string;
    display_name: string;
    function: string;
    type: TenderConfigType | string;
    value: string;
}

export enum TenderConfigType {
    STRING = 'STRING',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
}

export enum TenderType {
    PAYU_UPI = 'PAYU_UPI',
    CREDIT = 'CREDIT',
    PAY_ON_DELIVERY = 'PAY_ON_DELIVERY',
    CASH_CREDIT = 'CASH_CREDIT',
    CHEQUE_CREDIT = 'CHEQUE_CREDIT',
    EDC_CREDIT = 'EDC_CREDIT',
    QRCODE = 'QRCODE',
}

export const TenderTypeProperties = {
    CREDIT: {
        type: TenderType.CREDIT,
        display: 'Credit',
    },
    PAY_ON_DELIVERY: {
        type: TenderType.PAY_ON_DELIVERY,
        display: 'Pay on delivery',
    },
};

export enum TenderStatus {
    PAID = 'PAID',
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    FAILED = 'FAILED',
}

