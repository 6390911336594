/* eslint @typescript-eslint/no-use-before-define: 0 */
import { Button, Card, Col, Drawer, Row, Table } from 'antd';
import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../../../../styles/css/style';
import { DataNotFound } from '../../../../../common/templates/dataNotFound';
import { MeraaiTable } from '../../../../../common/templates/meraaiTable';
import { TotalSummary } from '../../../../../orders/layer/details/hubsAndOutlets/utils';
import { useInitiateReturnOrder } from './hooks/useInitiateReturnOrder';
import { InitiateReturnOrderDrawerProps } from './types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ResponseDrawer } from '../../../../../common/templates/responseDrawer';
import { useProductInvoiceList } from '../productInvoicesDrawer/hooks/useProductInvoiceList';
import { isArrayEmpty } from '../../../../../../Utils';
import { removeProductFromCart } from '../slice';
import { useAppDispatch } from '../../../../../../hooks';
import { SelectedProductWithItems } from '../slice/type';

export const InitiateReturnOrderDrawer = (props: InitiateReturnOrderDrawerProps) => {
    const dispatch = useAppDispatch();

    const { establishmentUuid, savedReturnOrders, onClose, ...restProps } = props;
    const [selectedProduct, setSelectedProduct] = useState<SelectedProductWithItems>();

    const {
        loading,
        columns,
        returnProductList: dataSource,
        returnProductsSummary,
        initiateCreateReturnOrder, //create return order API call
        navigate,
        createReturnOrderResponse,
        onResponseDrawerClose,
        updateReturnableProductListOnCart,

    } = useInitiateReturnOrder({ ...props });

    const {
        loading: returnableLogLoading,
        columns: returnableLogColumn,
        returnableLogResponse: returnableLogDataSource,
        storeReturnableDetailsIntoLocal,

    } = useProductInvoiceList({
        establishmentUuid: establishmentUuid,
        selectedPurchaseProduct: selectedProduct,
        savedPurchaseProduct: savedReturnOrders?.selectedProducts?.find(product => product?.id === selectedProduct?.id),
        inputOnBlur() {
            updateReturnableProductListOnCart();
            storeReturnableDetailsIntoLocal(isDataValidToStoreInLocal());
        },
        onFailureStoreIntoLocal() {
            // Remove a product from local
            dispatch(removeProductFromCart({ establishmentUuid: establishmentUuid, productId: selectedProduct?.id! }));
        },
    });

    // Methods
    function isDataValidToStoreInLocal() {
        const totalReturnItemsCount = returnableLogDataSource?.data.reduce((total, currentValue) => total += currentValue.returnQuantity, 0) ?? 0;

        if (selectedProduct) {
            selectedProduct!.returnQuantity = totalReturnItemsCount!;
        }
        return totalReturnItemsCount > 0;
    }

    const getExpandableColumn = () => {
        columns.push(Table.EXPAND_COLUMN);
        return columns;
    };

    return (
        <>

            <Drawer
                {...restProps}
                className='meraai-drawer'
                title={<h3>Place return</h3>}
                extra={<AiOutlineClose className='cursor-pointer' onClick={(event) => {
                    if (onClose) {
                        onClose(event);
                    }
                    setSelectedProduct(undefined);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={(event) => {
                                        if (onClose) {
                                            onClose(event);
                                            setSelectedProduct(undefined);
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={isArrayEmpty(dataSource)}
                                    onClick={() => {
                                        initiateCreateReturnOrder();
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                {
                    isArrayEmpty(dataSource) ?

                        <DataNotFound loading={loading} title='Your cart is empty' />

                        :

                        <div className='outlet-return-cart'>
                            <MeraaiTable
                                rowKey={(record: SelectedProductWithItems) => record.id}
                                loading={loading}
                                columns={getExpandableColumn()}
                                dataSource={dataSource}
                                pagination={false}
                                scroll={{ x: 'fit-content' }}
                                expandable={{
                                    columnWidth: 1,
                                    expandedRowKeys: [selectedProduct?.id ?? 0],
                                    onExpand(expanded, record: SelectedProductWithItems) {
                                        setSelectedProduct(expanded ? record : undefined);
                                    },
                                    expandedRowRender: () => {
                                        return (
                                            isArrayEmpty(returnableLogDataSource?.data!) ?

                                                <DataNotFound
                                                    loading={true}
                                                    title='No data found!'
                                                />

                                                :

                                                <Card className='border border-meraai-lightgrey'>
                                                    <MeraaiTable
                                                        loading={returnableLogLoading}
                                                        columns={returnableLogColumn}
                                                        dataSource={returnableLogDataSource?.data}
                                                        scroll={{ x: 'fit-content' }}
                                                        pagination={false}
                                                    />
                                                </Card>
                                        );
                                    },

                                    expandIcon: ({ expanded, onExpand, record }) => expanded ?
                                        <UpOutlined
                                            className='text-meraai-orange'
                                            onClick={(event) => {
                                                onExpand(record, event);
                                            }}
                                        />

                                        :

                                        <DownOutlined
                                            className='text-meraai-orange'
                                            onClick={(event) => {
                                                onExpand(record, event);
                                            }}
                                        />,
                                }}
                            />

                            <div className='pt-5'>
                                <TotalSummary {...returnProductsSummary} />
                            </div>
                        </div>

                }
            </Drawer>

            <ResponseDrawer
                type='SUCCESS'
                open={createReturnOrderResponse !== undefined}
                onClose={() => {
                    onResponseDrawerClose();
                }}
                message={<p className='font-semibold text-center'>{createReturnOrderResponse?.message}</p>}
                body={
                    <Button
                        className={`m-2 w-1/2 xs:w-1/3 md:w-1/4 lg:w-1/5 ${style.meraaiOrangeButton}`}
                        onClick={() => {
                            onResponseDrawerClose();

                            //Naviagte to the details page of return order.
                            navigate(`/returns/${createReturnOrderResponse?.uuid}`);
                        }}
                    >
                        View Details
                    </Button>
                }
            />
        </>
    );
};