import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { LatLng } from './Geocode';

const containerStyle = {
    width: '100%',
    height: '100%',
};

export interface MapProps {
    center?: LatLng;
    zoomLevel?: number;
    onMarkerDrag?: (lat: number, lng: number) => void;
    disabled?: boolean;
}

function MapComponent(props: MapProps) {
    const center = { lat: props.center?.latitude!!, lng: props.center?.longitude!! };
    return (
        <LoadScript googleMapsApiKey={window.__RUNTIME_CONFIG__.GMAPS_API_KEY}>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={props.zoomLevel}>
                <Marker
                    position={center}
                    clickable
                    draggable={props.onMarkerDrag != undefined && !props.disabled}
                    onDragEnd={({ latLng }) => {
                        if (props.onMarkerDrag) props.onMarkerDrag(latLng?.lat()!!, latLng?.lng()!!);
                    }}
                />
            </GoogleMap>
        </LoadScript>
    );
}

export default React.memo(MapComponent);
