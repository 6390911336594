export interface UserProfileDetails {
    first_name?: string,
    last_name?: string,
    country_code?: string,
    mobile_number?: string,
    email?: string,
    active?: boolean,
    role?: string,
    image_url?: string,
    establishment_aggregate?: {
        OUTLET?: number,
        HUB?: number,
        SELLER_HUB?: number
    }
}


export enum Operation {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}