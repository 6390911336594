/* eslint react/prop-types: 0 */

import * as React from 'react';

function TabLayout(props) {
    return (
        <div
            style={{
                background: 'white',
                border: '1px solid #D9D9D9',
                borderRadius: 10,
                paddingBottom: 15,
                ...props.style,
            }}>
            {props.children}
        </div>
    );
}

export default TabLayout;
