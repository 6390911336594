import { message } from 'antd';
import axios from 'axios';
import { clearLocalStorageAndLogout } from '../components/authentication/logout';

const ACCESS_DENIED_CODE = 410;

axios.defaults.timeout = 30000;

axios.interceptors.request.use(config => {

    if (!config.headers) {
        config.headers = {};
    }

    config.headers['source'] = 'DASHBOARD';
    config.headers['url'] = location.host;
    return config;
});


axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.code === 'ECONNABORTED') {
            message.error('Your request has timed out. Please check your internet connection and try again', 5);
        } else if (error.response.status === ACCESS_DENIED_CODE) {
            message.info('Access has been denied. Please login to continue', 3, () => {});
            clearLocalStorageAndLogout();
            return Promise.reject(error);
        }
        throw error;
    // return error
    },
);

export default axios;
