import { Form, message } from 'antd';
import React from 'react';
import { UPDATE_BRAND } from '../../../../../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../../../../../http/HttpClient';
import { Brand } from '../../../../../orders/listing/ListingData';
import { BrandModal } from '../common/BrandModal';
import { BrandSaveMode } from '../common/BrandSaveMode';
import { UpdateBrandProps } from './type';

export const UpdateBrand = (props: UpdateBrandProps) => {

    const [updateBrandForm] = Form.useForm();

    const [loading, setLoading] = React.useState<boolean>(false);

    const resetFormAndCloseModal = () => {
        setLoading(false);
        updateBrandForm.resetFields();
        props.setUpdateBrandModalVisibility(false);
        props.setSelectedBrand(undefined);
    };

    const onModalClose = () => {
        resetFormAndCloseModal();
    };

    const updateAndSetState = (updatedBrand: Brand) => {

        let brandIndex = props.brandList?.brands.findIndex((brand) => brand.id === updatedBrand.id);
        if (brandIndex !== -1) {
            // At position - brandIndex, remove 1 elements, add 1 - updatedBrand: 
            props.brandList?.brands.splice(brandIndex!, 1, updatedBrand);
            props.setBrandList({ ...props.brandList!, brands: props.brandList?.brands! });
        }
    };

    const makeRequestBody = (brandDetails): Brand => {
        let requestBody = {} as Brand;

        Object.keys(brandDetails).forEach((key) => {
            if (props.selectedBrand[key] != brandDetails[key]) {
                requestBody[key] = brandDetails[key];
            }
        });

        return requestBody;
    };

    const updateBrandDetails = (brandDetails) => {

        const requestBody = makeRequestBody(brandDetails);

        if (Object.keys(requestBody).length > 0) {
            setLoading(true);

            const responseHandler: ResponseHandler<Brand> = {
                onResponseSuccess(response: Brand): void {
                    updateAndSetState(response);
                    resetFormAndCloseModal();
                },
                onResponseFailed() {
                    setLoading(false);
                },
                onResponseError() {
                    setLoading(false);
                },
            };

            putApiCall(UPDATE_BRAND(props.selectedBrand.id), requestBody, responseHandler);
        } else {
            message.destroy();
            message.warning('You have not updated anything');
        }
    };

    return (
        <>
            <BrandModal
                mode={BrandSaveMode.UPDATE}

                selectedBrand={props.selectedBrand}

                brandModalVisibility={props.updateBrandModalVisibility}

                loading={loading}
                setLoading={setLoading}

                brandForm={updateBrandForm}
                onFormSubmit={updateBrandDetails}

                onModalClose={onModalClose}
            />
        </>
    );
};