import { OrderStates } from '../components/orders/hubsAndOutlets/utils';
import { Establishment, Agent, StateMetaDatum } from '../Types';
import { Datum } from './Datum';


export interface OrderDetailsResponse {
    uuid: string;
    id: number;
    receipt_no: string;
    invoice_no?: string;
    invoice_date?: string;
    child_orders?: MiniOrder[];
    parent_order?: MiniOrder;
    establishment: Establishment;
    agent_establishment: Establishment;
    agent: Agent;
    allow_changing_partial_deliverability: boolean;
    final_amount: number;
    partial_order_amount: number;
    partially_deliverable?: boolean;
    payment_type?: string;
    amount: number;
    rejected_amount: number;
    source: Source;
    source_display: string;
    state: OrderStates;
    message: string;
    status: String;
    items: OrderItem[];
    confirmed_at: number;
    state_updated_at: number;
    created_at: number;
    payments: Payments[];
    state_metadata: StateMetaDatum[];
    valid_next_states: ValidNextStateProperties[];
}

export interface ValidNextStateProperties {
    state: string,
    require_items_during_update: boolean,
}

export interface Payments {
    uuid: string;
    status: string;
    type: string;
    name: string;
    amount: number;
    refunded_amount?: number;
}
export interface OrderItem {
    id: number;
    product_id: number;
    brand_name: string;
    name: string;
    sku: string;
    price_per_unit: number;
    seller_price_per_unit: number;
    maximum_retail_price_per_unit: number,
    deliverable: boolean,
    quantity: number;
    deliverable_quantity: number,
    actual_quantity: number;
    availableQuantity: number;
    rejected_quantity: number;
    availableQuantitySource: 'SELF' | 'INVENTORY' | undefined;
    amount: number;
    image: string;
    state: string;
    state_metadata: StateMetaDatum[];
}

export interface MiniOrder {
    receipt_no: string;
    uuid: string;
    created_at: number;
    confirmed_at: number;
    source: Source;
    source_display: string;
    new_order: boolean;
}

export interface UpdateOrderRequest {
    state_request: UpdateOrderStateRequest;
}

export interface UpdateOrderStateRequest {
    state: string;
    data: Datum[];
    items?: UpdateOrderItem[];
}

export interface UpdateOrderItem {
    item_id: number;
    quantity?: number;
}

export interface OrderInventoryResponse {
    available: boolean,
    items: {
        item_id: number,
        quantity: number,
        available_stock: number,
    }[]
}

export interface SellerOrderForOrderDetails {
    orders: SellerOrderForOrderDetailsOrder[];
    page_count: number;
    page_size: number;
    next_page: boolean;
}
export interface SellerOrderForOrderDetailsOrder {
    uuid: string;
    establishment: Establishment;
    state: OrderStates;
    items: OrderItem[];
    state_metadata: StateMetaDatum[];
    created_at: number;
    amount: number;
    purchase_order_generated: boolean;
    purchase_order_uuid: string;
}

export enum Source {
    BILLING = 'BILLING',
    EDIT = 'EDIT',
    PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
}