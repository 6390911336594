import { Button, message, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { QR_PAYMENT_VERIFICATION_INTERVAL, RUPEE_SYMBOL } from '../../Constants';
import { COLLECTION_APPROVE } from '../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../http/HttpClient';
import { PaymentClearanceResponse } from '../../types/CreditPassbook';
import { PaymentResponse } from '../../types/FinanceCredit';
import { TenderStatus } from '../../types/Tender';
import { useCallbackPrompt } from '../common/warning/useCallbackPrompt';

const API_CALL_LIMIT = 10;

interface QRCodeGenerationProps {
    qrCode: string | undefined;
    onPaymentCompletion(): void
    selectedAmount: number;
    setSelectedAmount: React.Dispatch<React.SetStateAction<number>>;
    setCustomAmountEntered: React.Dispatch<React.SetStateAction<number>>;
    qrCodeModalVisibility: boolean | undefined;
    setQrCode: React.Dispatch<React.SetStateAction<string | undefined>>;
    paymentClearanceResponse: PaymentClearanceResponse | undefined;
    setQrCodeModalVisibility: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const QRCodeGeneration = (props: QRCodeGenerationProps) => {
    const [paymentModalLoading, setPaymentModalLoading] = useState<boolean>(false);
    const [verifyText, setVerifyText] = useState<string>('');
    const [startAutoVerification, setStartAutoVerification] = useState<boolean>(true);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const [autoVerifyLimit, setAutoVerifyLimit] = useState<number>(API_CALL_LIMIT);
    /**
     * useCallbackPrompt hook ->
     * It shows a prompt asking whether user wants to leave or reload the page.
     */
    useCallbackPrompt(props.qrCodeModalVisibility!);


    const resetTimeEvents = () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
    };

    const closeModalAndResetStates = () => {
        props.setQrCode(undefined);
        props.onPaymentCompletion();
        props.setSelectedAmount(0);
        props.setCustomAmountEntered(0);
        props.setQrCodeModalVisibility(false);
        setVerifyText('');
        resetTimeEvents();
        setStartAutoVerification(true);
        setPaymentModalLoading(false);
    };


    const modalConfig = (content: string, onOk: () => void) => {
        return {
            maskClosable: false,
            closable: false,
            keyboard: false,
            content: content,
            onOk: onOk,
        };
    };


    const paymentSuccess = (response: PaymentResponse) => {
        closeModalAndResetStates();
        Modal.success(modalConfig(response.tender_response.message, () => {
            props.onPaymentCompletion();
        }));
    };


    const paymentFailure = (error: string) => {
        closeModalAndResetStates();
        Modal.error(modalConfig(error, () => {
            props.onPaymentCompletion();
        }));
    };


    const verifyPayment = () => {
        setPaymentModalLoading(true);
        message.destroy();

        const responseHandler: ResponseHandler<PaymentResponse> = {
            onResponseSuccess(response: PaymentResponse): void {
                if (response.tender_response.status === TenderStatus.CONFIRMED) {
                    paymentSuccess(response);
                } else if (response.tender_response.status === TenderStatus.PENDING) {
                    message.warning(response.tender_response.message);
                    resetTimeEvents();
                    setPaymentModalLoading(false);
                    setStartAutoVerification(true);
                } else if (response.tender_response.status === TenderStatus.FAILED) {
                    paymentFailure('Payment has been failed.');
                }
            },
            onResponseFailed(error: string[]) {
                paymentFailure(error[0]);
            },
            onResponseError() {
                closeModalAndResetStates();
            },
        };

        putApiCall(COLLECTION_APPROVE(props.paymentClearanceResponse?.uuid!, props.paymentClearanceResponse?.payment_response.uuid!), {}, responseHandler, {}, {}, true);
    };


    const onAutoVerifyPayment = () => {
        // Initializing
        let haltTime = QR_PAYMENT_VERIFICATION_INTERVAL;
        const suspendApiCall = setInterval(() => {
            setVerifyText(`Auto Verifying in ${haltTime} secs.`);
            haltTime -= 1;
        }, 1000);

        setIntervalId(suspendApiCall);

        const id = setTimeout(() => {
            // This code will execute after 30secs.
            clearInterval(suspendApiCall);
            setAutoVerifyLimit(previousVal => previousVal - 1);
            verifyPayment();
            setVerifyText('Verifying...');

        }, QR_PAYMENT_VERIFICATION_INTERVAL * 1000);
        setTimeoutId(id);
    };

    useEffect(() => {
        if (props.qrCodeModalVisibility && startAutoVerification) {
            setStartAutoVerification(false);
            if (autoVerifyLimit > 0) {
                onAutoVerifyPayment();
            } else {
                resetTimeEvents();
                setVerifyText('Auto verification has been stopped.');
            }
        }
    }, [props.qrCodeModalVisibility, startAutoVerification]);

    return (
        <>
            <Modal
                title='Do not close the tab and do not press the back button.'
                open={props.qrCodeModalVisibility}
                maskClosable={false}
                closable={false}
                keyboard={false}
                onCancel={() => {
                    closeModalAndResetStates();
                }}
                footer={[
                    <div className='flex-center'>

                        <Button
                            size='large'
                            className='basic-button'
                            disabled={paymentModalLoading}
                            style={{ backgroundColor: 'grey' }}
                            onClick={() => {
                                closeModalAndResetStates();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            size='large'
                            className='basic-button'
                            loading={paymentModalLoading}
                            style={{ backgroundColor: 'green' }}
                            onClick={() => {
                                verifyPayment();
                            }}
                        >
                            Verify
                        </Button>

                    </div>,

                ]}
            >
                <Spin spinning={paymentModalLoading} tip='Verifying your payment...'>
                    <h2 className='flex-center'>Scan QR Code</h2>
                    <h2 className='flex-center' style={{ color: '#549482' }}>{RUPEE_SYMBOL} {props.selectedAmount}/-</h2>
                    <h3 className='flex-center'>{verifyText} </h3>
                    <div className='flex-center'>
                        {props.qrCode && <QRCode value={props.qrCode} />}
                    </div>
                </Spin>

            </Modal>
        </>

    );
};