import { DateFilterParam } from '../common/TimeFilterPicker';


export enum CollectionState {
    REQUEST_FOR_COLLECTION = 'REQUEST_FOR_COLLECTION',
    INITIATED = 'INITIATED',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
}

export enum ReceivableState {
    OVERDUE = 'OVERDUE',
    DUE_TODAY = 'DUE_TODAY',
    GENERATED = 'GENERATED',
    FULFILLED = 'FULFILLED',
}

export interface CollectionConfig {
    state: CollectionState,
    display: string,
    dateFilterSelectionRequired: boolean,
}

export interface ReceivablesConfig {
    state: ReceivableState,
    display: string,
    dateFilterSelectionRequired: boolean,
    filterParams?: DateFilterParam[];
}

export interface DepositConfig {
    state: CollectionState,
    display: string,
    dateFilterSelectionRequired: boolean,
    allowPayment: boolean,
    wrapIntoCollapsiblePanel: boolean,
    pageSize: number,
}

export class CollectionComponentConfig {

    static readonly REQUEST_FOR_COLLECTION = new CollectionComponentConfig(
        {
            collectionConfig: {
                state: CollectionState.REQUEST_FOR_COLLECTION,
                display: 'Request For Collection',
                dateFilterSelectionRequired: false,

            },
        },
    );

    static readonly INITIATED = new CollectionComponentConfig(
        {
            collectionConfig: {
                state: CollectionState.INITIATED,
                display: 'Waiting for Approval',
                dateFilterSelectionRequired: false,
            },
            depositConfig: {
                state: CollectionState.INITIATED,
                display: 'Pending',
                dateFilterSelectionRequired: false,
                allowPayment: true,
                wrapIntoCollapsiblePanel: true,
                pageSize: 100,
            },
        },
    );

    static readonly CONFIRMED = new CollectionComponentConfig(
        {
            collectionConfig: {
                state: CollectionState.CONFIRMED,
                display: 'Confirmed',
                dateFilterSelectionRequired: true,
            },
            depositConfig: {
                state: CollectionState.CONFIRMED,
                display: 'Approved',
                dateFilterSelectionRequired: true,
                allowPayment: false,
                wrapIntoCollapsiblePanel: false,
                pageSize: 6,
            },
        },
    );

    static readonly CANCELLED = new CollectionComponentConfig(
        {
            collectionConfig: {
                state: CollectionState.CANCELLED,
                display: 'Cancelled',
                dateFilterSelectionRequired: true,
            },
            depositConfig: {
                state: CollectionState.CANCELLED,
                display: 'Rejected',
                dateFilterSelectionRequired: true,
                allowPayment: false,
                wrapIntoCollapsiblePanel: false,
                pageSize: 6,
            },
        },
    );

    static readonly OVERDUE = new CollectionComponentConfig(
        {
            receivablesConfig: {
                state: ReceivableState.OVERDUE,
                display: 'Over Due',
                dateFilterSelectionRequired: false,
            },
        },
    );

    static readonly DUE_TODAY = new CollectionComponentConfig(
        {
            receivablesConfig: {
                state: ReceivableState.DUE_TODAY,
                display: 'Due Today',
                dateFilterSelectionRequired: false,
            },
        },
    );

    static readonly GENERATED = new CollectionComponentConfig(
        {
            receivablesConfig: {
                state: ReceivableState.GENERATED,
                display: 'Generated',
                dateFilterSelectionRequired: true,
                filterParams: [
                    {
                        value: 'dueDate',
                        label: 'Due Date',
                    },
                    {
                        value: 'receivedDate',
                        label: 'Delivery Date',
                    },
                ],
            },
        },
    );

    static readonly FULFILLED = new CollectionComponentConfig(
        {
            receivablesConfig: {
                state: ReceivableState.FULFILLED,
                display: 'Fulfilled',
                dateFilterSelectionRequired: true,
                filterParams: [
                    {
                        value: 'stateUpdatedAt',
                        label: 'Fulfillment Date',
                    },
                    {
                        value: 'dueDate',
                        label: 'Due Date',
                    },
                    {
                        value: 'receivedDate',
                        label: 'Delivery Date',
                    },
                ],
            },
        },
    );

    public constructor(
        public readonly config: {
            collectionConfig?: CollectionConfig,
            receivablesConfig?: ReceivablesConfig,
            depositConfig?: DepositConfig,
        },
    ) { }

    static valueOf(type: CollectionState | ReceivableState): CollectionComponentConfig | undefined {
        if (type === CollectionState.REQUEST_FOR_COLLECTION) return CollectionComponentConfig.REQUEST_FOR_COLLECTION;
        if (type === CollectionState.INITIATED) return CollectionComponentConfig.INITIATED;
        if (type === CollectionState.CONFIRMED) return CollectionComponentConfig.CONFIRMED;
        if (type === CollectionState.CANCELLED) return CollectionComponentConfig.CANCELLED;
        if (type === ReceivableState.DUE_TODAY) return CollectionComponentConfig.DUE_TODAY;
        if (type === ReceivableState.OVERDUE) return CollectionComponentConfig.OVERDUE;
        if (type === ReceivableState.GENERATED) return CollectionComponentConfig.GENERATED;
        if (type === ReceivableState.FULFILLED) return CollectionComponentConfig.FULFILLED;
    }

}