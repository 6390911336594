import { Button, Col, message, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { RUPEE_SYMBOL } from '../../../Constants';
import { UPDATE_PURCHASE_ORDER } from '../../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { setMargin } from '../../../styles/css/react/MeraaiCssProperties';
import { PurchaseOrderDetailResponse, UpdatePurchaseOrderRequest } from '../../../types/PurchaseOrders';
import { TenderStatus } from '../../../types/Tender';
import { createDocument, documentConfigs, MetadataDocument, MetadataUploader } from '../../common/MetadataUploader';
import RowColData from '../../common/RowColData';


interface PurchaseOrderPaymentModalProps {
    purchaseOrderUuid: string;
    purchaseOrderDetails: PurchaseOrderDetailResponse | undefined;
    setPurchaseOrderDetails: React.Dispatch<React.SetStateAction<PurchaseOrderDetailResponse | undefined>>
    paymentModalVisibility: boolean;
    setPaymentModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PurchaseOrderPaymentModal = (props: PurchaseOrderPaymentModalProps) => {
    const {
        purchaseOrderUuid,
        purchaseOrderDetails,
        setPurchaseOrderDetails,
        paymentModalVisibility,
        setPaymentModalVisibility,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [paymentDocuments, setPaymentDocuments] = useState<MetadataDocument[]>([]);


    const makePaymentRequestBody = (): UpdatePurchaseOrderRequest => {
        return {
            payment:
            {
                status: TenderStatus.PAID,
                data: paymentDocuments.map((document) => {
                    const documentConfig = documentConfigs.find(eachConfig => eachConfig.type === document.type);
                    return {
                        name: documentConfig?.datumName!,
                        display_name: document.purpose!,
                        function: 'DISPLAY',
                        type: documentConfig?.datumType!,
                        value: document?.values![0]?.value,
                    };
                }),
            },
        };
    };

    const performPaymentUpdate = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess(response: any): void {
                message.success(response.message);
                setPurchaseOrderDetails(response);
                setPaymentModalVisibility(false);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        putApiCall(UPDATE_PURCHASE_ORDER(purchaseOrderUuid), makePaymentRequestBody(), responseHandler);

    };

    const RowColDataProps = {
        key: purchaseOrderDetails?.invoice_no,
        data: [
            {
                value: 'Invoice No.',
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: `#${purchaseOrderDetails?.invoice_no!}`,
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: 'Name',
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: purchaseOrderDetails?.establishment.name!,
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: 'Contact',
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: purchaseOrderDetails?.establishment?.contact?.mobile_number!,
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: 'Amount to pay',
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: `${RUPEE_SYMBOL} ${purchaseOrderDetails?.received_amount}/-`,
                styleName: 'white-space',
                xsSize: 12,
            },
            {
                value: <MetadataUploader documents={paymentDocuments} updateDocuments={setPaymentDocuments} />,
                styleName: 'white-space',
                mdSize: 24,
            },

        ],
    };

    useEffect(() => {
        if (paymentModalVisibility) {
            setPaymentDocuments([
                createDocument('AMOUNT', true, 'Amount'),
                createDocument('TRANSACTION_ID', true, 'Transaction Id'),
                createDocument('IMAGE', true, 'Proof of transaction', undefined, true),
            ]);
        }
    }, [paymentModalVisibility]);
    return (
        <>
            <Modal
                title='Payment'
                className='responsive-modal'
                open={paymentModalVisibility}
                onCancel={() => {
                    setPaymentDocuments([]);
                    setPaymentModalVisibility(false);
                }}
                footer={[
                    <div className='flex-center'>
                        <Button
                            className='default-button'
                            loading={loading}
                            disabled={paymentDocuments.find(document => !document.values) !== undefined || loading}
                            onClick={performPaymentUpdate}
                        >
                            Update
                        </Button>
                    </div>,
                ]}
            >
                <Spin spinning={loading} size='small' tip='Updating...'>
                    <Row style={setMargin(0, 30, 0, 30)} className='meraai-border'>
                        <Col xs={24}>
                            <RowColData {...RowColDataProps} />
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </>
    );
};