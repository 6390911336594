/* eslint @typescript-eslint/no-unused-vars: 0 */
import { Button, Col, Drawer, Popconfirm, Row, Select, Tooltip, Upload } from 'antd';
import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsUpload } from 'react-icons/bs';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { layout, style } from '../../../../../../../styles/css/style';
import { BulkProductCreationResponse, CreateProductDetails } from '../../../../../../../types/Product';
import { ProductProperties } from '../../properties/ProductProperties.';
import { ACCEPTED_CSV_COLUMNS, ACCEPTED_FILES, ACCEPTED_FILE_FORMAT } from '../Constant';
import { CreateProductsProps } from './types';
import { initializeCreateProductProperties } from './utils';
import { OpenNotification } from '../../../../../../common/notifications/Notifications';
import { ExcelRendererComponent } from './excel/ExcelFileRenderer';
import { RcFile } from 'antd/lib/upload';
import { Brand, Field, FieldVisibility } from '../../../../../../orders/listing/ListingData';
import { getApiCall, postApiCall, ResponseHandler } from '../../../../../../../http/HttpClient';
import download from 'downloadjs';
import { CREATE_BULK_ORDER, DOWNLOAD_PRODUCT_SAMPLE_FILE } from '../../../../../../../http/EndPoints';
import { useParams } from 'react-router-dom';
import { MeraaiTable } from '../../../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../../../common/templates/meraaiTable/types';
import { MandatoryTitle, OptionalTitle } from '../../../../../../common/TableTitle';
import { isArrayEmpty, MeraaiLabel } from '../../../../../../../Utils';
import loadash from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Type } from '../../../../../../../utilities/datumRenderer/config/type';
import ImageRenderer, { ImageFile, UploaderTypes } from '../../../../../../common/ImageRenderer';
import { FileIdentifier, FileUploadResponse, upload } from '../../../../../../../utilities/UploadFile';
import CloseableImage from '../../../../../../common/CloseableImage';
import { ResponseDrawer } from '../../../../../../common/templates/responseDrawer';
import { CreateRequestGroupingKey } from '../../../../../../common/templates/meraaiTable/EditableField';
import { DataNotFound } from '../../../../../../common/templates/dataNotFound';
import { ProductPropertyDrawer } from '../fieldsDrawer';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { SelectCategoryList } from '../../../../../../common/selectCategory';
import { ProductMetadataDrawer } from '../metadataDrawer';

export const CreateProducts = (props: CreateProductsProps) => {
    const { establishmentUuid } = useParams();

    // State variables
    const [loading, setLoading] = useState<boolean>(false);

    const [dataSource, setDataSource] = useState<ProductProperties[]>([]);
    const [lastRecordKey, setLastRecordKey] = useState<number>(0);

    // Product Property Related States
    const [productFile, setProductFile] = useState<any[]>();
    const [selectedField, setSelectedField] = useState<Field[]>();
    const [selectedProductId, setSelectedProductId] = useState<number>();
    const [productPropertyDrawerVisibility, setProductPropertyDrawerVisibility] = useState<boolean>(false);
    const [metadataDrawerVisibility, setMetadataDrawerVisibility] = useState<boolean>(false);

    // Category 
    const [selectedCategories, setSelectedCategories] = useState<number[]>();

    const [responseDetails, setResponseDetails] = useState<{
        type: 'SUCCESS' | 'FAILURE' | 'WARNING' | 'INFO';
        message: string,
    }>();

    // Methods
    /**
     * When the image is uploaded this `changeValue` method help us to update the latest data into dataSource state.
     * Now we have to call the Upload method to upload the image onto the cloud and in the response we will get the https url.
     * The url needs to be passed in the create product request.
     **/
    const updateValue = (property: keyof ProductProperties, value: any, key: number) => {
        const productDetails = dataSource.find(product => product.id!.value === key);
        productDetails![property]!['value'] = value;
        setDataSource([...dataSource]);
    };

    const uploadImage = (imageFile: ImageFile, record: ProductProperties) => {

        const key = record.id?.value!;
        record.image.setProcessingStatus!(true);
        setDataSource([...dataSource]);
        const responseHandler: ResponseHandler<FileUploadResponse> = {
            onResponseSuccess(productImage: FileUploadResponse): void {
                updateValue('image', { webUrl: productImage.url }, key);
                record.image.setProcessingStatus!(false);
                setDataSource([...dataSource]);
            },
            onResponseFailed() {
                updateValue('image', { webUrl: '' }, key);
                record.image.setProcessingStatus!(false);
                setDataSource([...dataSource]);
            },
            onResponseError() {
                updateValue('image', { webUrl: '' }, key);
                record.image.setProcessingStatus!(false);
                setDataSource([...dataSource]);
            },
        };
        upload('IMAGE', imageFile.file!, responseHandler, FileIdentifier.PRODUCT);

    };

    const resetStates = () => {
        setDataSource([]);
        setProductFile([]);
    };

    /** Download file */
    const downloadSampleFile = async () => {
        setLoading(true);
        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess(data: any): void {
                if (data) {
                    download(new Blob([data]), 'meraai-create-product-sample-file.csv', 'application/csv');
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(DOWNLOAD_PRODUCT_SAMPLE_FILE(establishmentUuid), responseHandler, {}, { responseType: 'blob' });
    };

    /** Handling uploaded file */
    const fileHandler = (fileObj: RcFile) => {
        resetStates();
        if (!fileObj) {
            OpenNotification('No file uploaded!', 'Please make sure you have selected a file.', 'error');
            return false;
        }
        if (!ACCEPTED_FILE_FORMAT.includes(fileObj.type)) {
            OpenNotification('Unknown file format', `Only ${ACCEPTED_FILES} files are accepted!`, 'error');
            return false;
        }
        ExcelRendererComponent(
            fileObj,
            dataSource,
            setDataSource,
            setLastRecordKey,
            ACCEPTED_CSV_COLUMNS.map(column => column.columnName),
            setProductFile,
            props.brandList!,
        );
        return false;
    };

    /** Adding a new product */
    const handleAdd = () => {
        const product = initializeCreateProductProperties();
        const newData: CreateProductDetails = {
            id: lastRecordKey,
            name: '',
            price: 0,
            mrp: 0,
            sku: '',
            hsn: '',
            ean: '',
            gst: 0,
            cess: 0,
            carton_capacity: undefined,
            brand: '',
            categories: [],
            image: '',
            metadata: {
                app_display_label: '',
                days_until_returnable: 0,
            },
        };
        product.setValuesForProductCreation!(newData);
        setLastRecordKey(key => key + 1);
        setDataSource(prevProducts => [...prevProducts, product]);
    };

    const handleDelete = (key: number) => {
        const rows = [...dataSource];
        setDataSource(rows.filter(item => item.id!.value !== key));
    };

    const createBulkProduct = async () => {

        const valuesAcceptable = (): boolean => {

            return dataSource.every(productRow => ProductProperties.getFilteredProperties!(productRow).every(field => {
                if (field.isValueAcceptable) {
                    return field.isValueAcceptable();
                }
            }));
        };

        // Creating request body for bulk creation of products
        const makeRequestBody = () => {

            const products = dataSource.map((productRow) => {
                const productRequest = {};

                ProductProperties.getFilteredProperties!(productRow).forEach((field) => {

                    const { createProperties } = field;

                    if (createProperties?.groupingKey) {
                        if (createProperties?.groupingKey === CreateRequestGroupingKey.TAXES) {
                            if (!productRequest[createProperties?.groupingKey]) {
                                productRequest[createProperties?.groupingKey] = [];
                            }
                            productRequest[createProperties?.groupingKey].push({
                                type: createProperties?.requestKey,
                                percentage: field.value,
                            });
                        }
                    } else if (createProperties?.requestKey === 'categories') {

                        productRequest[createProperties?.requestKey] = field.value?.map((categoryId) => {
                            return {
                                category_id: categoryId,
                            };
                        });
                    } else {
                        //This check is to signify that if orderQuantityMultiple (carton_capacity) is emptied, then
                        //send it in the data as 0. On the backend side if orderQuantityMultiple comes in as 0, it is marked
                        //as null
                        if (createProperties?.requestKey === 'order_quantity_multiple' && field?.value?.toString()?.trim() === '') {
                            productRequest[createProperties?.requestKey] = 0;
                        } else {
                            if (createProperties?.requestKey == 'image_url') {
                                productRequest[createProperties?.requestKey] = field.value?.webUrl ?? null;
                            } else {
                                productRequest[createProperties?.requestKey!] = field.value ?? null;
                            }
                        }
                    }
                });
                return productRequest;
            });
            return {
                products: products,
            };
        };

        if (!valuesAcceptable()) {
            OpenNotification(
                'Cannot Proceed',
                'Please fill in all the required fields and upload images for each row.',
                'error',
            );
            return;
        }

        setLoading(true);
        const endpoint = CREATE_BULK_ORDER(establishmentUuid!);

        const responseHandler: ResponseHandler<BulkProductCreationResponse[]> = {
            onResponseSuccess: (response: BulkProductCreationResponse[]): void => {
                if (response.length > 0) {
                    setResponseDetails({ type: 'SUCCESS', message: 'Products have been created successfully' });
                    if (props.setProductCreatedFlag) {
                        props.setProductCreatedFlag(true);
                    }
                }
                setLoading(false);
            },
            onResponseFailed: (): void => {
                setLoading(false);
            },
            onResponseError: (): void => {
                setLoading(false);
            },
        };

        await postApiCall(endpoint, makeRequestBody(), responseHandler);
    };

    // Create product column
    const columns: MeraaiTableColumnType[] = [
        {
            title: <MandatoryTitle title='Name' />,
            dataIndex: 'name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: name => <MeraaiLabel>{name.value}</MeraaiLabel>,
        },
        {
            title: <OptionalTitle title='SKU' description='Leaving it blank will automatically create a new sku' />,
            dataIndex: 'sku',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: sku => <MeraaiLabel>{sku.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='HSN' />,
            dataIndex: 'hsn',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: hsn => <MeraaiLabel>{hsn.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='Barcode' />,
            dataIndex: 'ean',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: ean => <MeraaiLabel>{ean.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='Price' />,
            dataIndex: 'price',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: price => <MeraaiLabel>{price.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='MRP' />,
            dataIndex: 'mrp',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: mrp => <MeraaiLabel>{mrp.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='GST' />,
            dataIndex: 'gst',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: gst => <MeraaiLabel>{gst.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='CESS' />,
            dataIndex: 'cess',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: cess => <MeraaiLabel>{cess.value}</MeraaiLabel>,
        },
        {
            title: <OptionalTitle title='Carton capacity' description='No of items that would be sent in a carton. 0 or empty would mean, carton size has not been standardized' />,
            dataIndex: 'orderQuantityMultiple',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: orderQuantityMultiple => <MeraaiLabel>{orderQuantityMultiple.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='Brand' />,
            dataIndex: 'brand',
            width: '210px',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render(brand, record: ProductProperties) {
                return <Select
                    status={`${brand.isValueAcceptable!() ? '' : 'error'}`}
                    className='w-full'
                    placeholder='Select Brand'
                    onChange={(id) => {
                        const selectedBrand = props.brandList?.find(eachBrand => eachBrand.id == id);
                        updateValue('brand', selectedBrand?.name, record.id?.value!);
                    }}
                >
                    {
                        props.brandList?.map((eachBrand: Brand) => {
                            return <Select.Option key={eachBrand.id} value={eachBrand.id}>
                                {eachBrand.name}
                            </Select.Option>;
                        })
                    }
                </Select>;
            },
        },
        {
            title: <MandatoryTitle title='Category' />,
            dataIndex: 'categories',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render(categories, record: ProductProperties) {

                const updateCategoryValueAndSetState = (categoryIdList) => {
                    updateValue('categories', categoryIdList, record.id?.value!);
                    setSelectedCategories(categoryIdList);
                };

                return <SelectCategoryList
                    status={`${categories.isValueAcceptable!() ? '' : 'error'}`}
                    virtual={false}
                    mode='multiple'
                    className='w-full'
                    value={categories.value}
                    maxTagCount={1}
                    maxTagTextLength={12}
                    setSelectedCategories={setSelectedCategories}
                    onChange={(categoryIdList) => {
                        updateCategoryValueAndSetState(categoryIdList);
                    }}
                    onOk={() => {
                        updateValue('categories', selectedCategories, record.id?.value!);
                    }}
                />;
            },
        },
        {
            title: <OptionalTitle title='Info' description='The information present here is shown on the app' />,
            dataIndex: 'info',
            actionable: true,
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render: (_, record: ProductProperties) => {
                // Finding the currently selected brand for the product row on which the user has just clicked.
                const brandFields = props.brandList?.find(brand => brand.name == record.brand.value)?.fields ?? [{
                    id: uuidv4(),
                    visibility: [FieldVisibility.DETAILED],
                    mandatory: true,
                    elements: [{
                        id: uuidv4(),
                        key: undefined,
                        value: undefined,
                        value_type: Type.STRING,
                        deletable: true,
                    }],
                }];
                const productFields = record.fields?.value;
                const productId = record.id?.value;

                return <Button
                    className={`w-full ${style.meraaiPlainButton}`}
                    disabled={record.brand.value === ''}
                    onClick={() => {

                        if (isArrayEmpty(productFields)) {
                            setSelectedField(loadash.cloneDeep(brandFields));
                        } else {
                            setSelectedField(productFields);
                        }
                        // Will use `selectedProductId` to fetch or update the details of the selected product property.
                        setSelectedProductId(productId);
                        setProductPropertyDrawerVisibility(true);
                    }}
                >
                    {isArrayEmpty(productFields) ? 'Add more info' : 'Modify info'}
                </Button>;
            }
            ,
        },
        {
            title: <MandatoryTitle title='Image' />,
            dataIndex: 'image',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render: (image, record: ProductProperties) => {

                return (
                    <div className="image-uploader-small">
                        <ImageRenderer
                            uploadText={image.isValueAcceptable!() ? 'Replace' : 'Upload'}
                            disabled={record?.image?.processingStatus}
                            styleClass={`mr-5 ${record.image.isValueAcceptable!() ? '' : 'border border-solid border-red-500 rounded-lg'}`}
                            setFileState={(file: ImageFile) => {
                                uploadImage(file, record);
                            }}
                            type={UploaderTypes.UPLOAD_PNG}
                        />
                        <CloseableImage
                            src={record.image.value?.webUrl}
                            onClose={() => {
                                updateValue('image', { webUrl: '' }, record.id!.value!);
                            }}
                            width={50}
                            height={50}
                        />
                    </div>
                );
            },
        },
        {
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ProductProperties) =>
                dataSource.length > 0 ? (
                    <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id!.value!)}>
                        <RiDeleteBin7Line className='text-xl cursor-pointer' />
                    </Popconfirm>

                ) : (
                    <></>
                ),
        },
        {
            title: <OptionalTitle title='Info' description='The information present here is shown on the app' />,
            dataIndex: 'info',
            actionable: true,
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render: (_, record: ProductProperties) => {

                const productMetadata = record.metadata?.value;
                const productId = record.id?.value;

                return <Button
                    className={`w-full ${style.meraaiPlainButton}`}
                    onClick={() => {

                        // Will use `selectedProductId` to fetch or update the details of the selected product property.
                        setSelectedProductId(productId);
                        setMetadataDrawerVisibility(true);
                    }}
                >
                    {Object.values(productMetadata ?? {}).every(metadatum => !metadatum) ? 'Add metadata' : 'Modify metadata'}
                </Button>;
            }
            ,
        },
    ];

    return (
        <>
            <Drawer
                placement='bottom'
                open={props.open}
                title={<h3>Create Products</h3>}
                extra={<>
                    {/* Cancel icon */}
                    <AiOutlineClose className='cursor-pointer text-xl' onClick={() => {
                        props.onClose();
                    }} />
                </>}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
            >
                <>
                    <Row gutter={[12, 12]} className={`${layout.flex.spaceBetween}`}>
                        <Col className='w-full sm:w-1/3 md:w-1/2 xl:w-1/3'>
                            <Row>
                                <Col className='w-full xs:w-1/2 lg:w-1/3'>
                                    <a className='text-meraai-orange hover:text-meraai-orange active:text-meraai-orange' onClick={downloadSampleFile}>Download Sample File.</a>
                                </Col>
                                <Col className='w-full xs:w-1/2 lg:w-2/3'>
                                    <Upload
                                        accept={ACCEPTED_FILES}
                                        name="file"
                                        beforeUpload={fileHandler}
                                        onRemove={() => {
                                            resetStates();
                                        }}
                                        showUploadList
                                        fileList={productFile}
                                        maxCount={1}>
                                        <Button
                                            className='w-full'
                                        >
                                            <Tooltip title={`Except only ${ACCEPTED_FILES}`}>
                                                <BsUpload />
                                                &ensp;&ensp;
                                                <label className='cursor-pointer'>
                                                    Click to Upload File &ensp; <ExclamationCircleOutlined />
                                                </label>
                                            </Tooltip>
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='w-full sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6'>
                            {/* Add a product */}
                            <Button
                                className={`${style.meraaiOrangeButton}`}
                                onClick={handleAdd}
                            >
                                Add a Product
                            </Button>
                        </Col>
                    </Row>
                    {
                        isArrayEmpty(dataSource) ?

                            <DataNotFound loading={loading} title={responseDetails?.message ?? 'Start adding your products'} />

                            :

                            <Row>
                                <Col className='w-full py-10 seller-hub-product-table'>
                                    <MeraaiTable
                                        loading={loading}
                                        rowClassName={(record) => {
                                            const validRow = ProductProperties.getFilteredProperties!(record).every(field => {
                                                if (field.isValueAcceptable) {
                                                    return field.isValueAcceptable();
                                                }
                                            });
                                            if (validRow) {
                                                return 'bg-green-100';
                                            } else {
                                                return 'bg-red-100';
                                            }
                                        }}
                                        defaultExpandableKey={dataSource.at(0)?.key}
                                        expandableRowModificationType='EDIT'
                                        columns={columns}
                                        dataSource={dataSource}
                                        setDataSource={setDataSource}
                                        pagination={false}
                                    />
                                </Col>
                                <Col className={`w-full ${layout.flex.end}`}>
                                    <Button
                                        size='large'
                                        className={`w-full xs:w-1/2 md:w-1/4 lg:w-1/6 ${style.meraaiOrangeButton}`}
                                        onClick={createBulkProduct}
                                    >
                                        Create Products
                                    </Button>
                                </Col>

                            </Row>

                    }
                </>

            </Drawer>


            <ProductPropertyDrawer
                /**
                 * selectedProductId : Since we are using updateValue method in
                 * ProductPropertyModal, we need this id to update the dataSource state
                 * when user clicks on the proceed button.
                 **/
                selectedProductId={selectedProductId!}
                selectedProductFields={selectedField}
                updateValue={updateValue!}
                productPropertyDrawerVisibility={productPropertyDrawerVisibility}
                setProductPropertyDrawerVisibility={setProductPropertyDrawerVisibility}
            />

            <ProductMetadataDrawer
                open={metadataDrawerVisibility}
                onClose={() => {
                    setMetadataDrawerVisibility(false);
                }}
                onProceed={(values) => {
                    setMetadataDrawerVisibility(false);
                    updateValue('metadata', values, selectedProductId!);
                }}
            />

            <ResponseDrawer
                type={responseDetails?.type!}
                message={responseDetails?.message}
                open={responseDetails !== undefined}
                onClose={() => {
                    setDataSource([]);
                    setLastRecordKey(0);
                    setProductFile([]);
                    setResponseDetails(undefined);
                }}
            />
        </>
    );
};