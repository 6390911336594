import { Button, Col, Drawer, Row } from 'antd';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../../../../styles/css/style';
import { addKeyToDataList, isArrayEmpty } from '../../../../../../Utils';
import { DataNotFound } from '../../../../../common/templates/dataNotFound';
import { MeraaiTable } from '../../../../../common/templates/meraaiTable';
import { TotalSummary } from '../../../../../orders/layer/details/hubsAndOutlets/utils';
import { useProductInvoiceList } from './hooks/useProductInvoiceList';
import { ProductInvoiceListProps } from './types';

export const ProductInvoiceList = (props: ProductInvoiceListProps) => {

    const { onClose, ...restProps } = props;

    const {

        columns,
        returnableLogResponse: dataSource,
        loading: loadingReturnablelogs,
        storeReturnableDetailsIntoLocal,
        productInvoiceSummary,
        resetProductValueFromLocal,

    } = useProductInvoiceList({ ...props });

    const isDataValidToStoreInLocal = () => {
        const totalReturnItemsCount = dataSource?.data.reduce((total, currentValue) => total += currentValue.returnQuantity, 0) ?? 0;
        return totalReturnItemsCount === props.selectedPurchaseProduct?.returnQuantity;
    };

    return (
        <>
            <Drawer
                {...restProps}
                className='meraai-drawer'
                extra={<AiOutlineClose className='cursor-pointer' onClick={(event) => {
                    if (onClose) {
                        onClose(event);
                    }
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    disabled={loadingReturnablelogs}
                                    onClick={(event) => {
                                        if (onClose) {
                                            onClose(event);
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={loadingReturnablelogs}
                                    onClick={() => {
                                        const totalReturnQuntity = dataSource?.data.reduce((total, currentValue) => total += currentValue.returnQuantity, 0) ?? 0;
                                        if (totalReturnQuntity > 0) {
                                            storeReturnableDetailsIntoLocal(isDataValidToStoreInLocal());
                                        } else {
                                            resetProductValueFromLocal(props.selectedPurchaseProduct?.id!);
                                        }
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >

                <>
                    {
                        isArrayEmpty(dataSource?.data) ?

                            <DataNotFound loading={loadingReturnablelogs} title={dataSource?.message ?? 'No data found!'} />

                            :

                            <>
                                <MeraaiTable
                                    loading={loadingReturnablelogs}
                                    columns={columns}
                                    dataSource={addKeyToDataList(dataSource?.data!)}
                                    scroll={{ x: 'fit-content' }}
                                    pagination={false}
                                />
                                <div className='pt-5'>
                                    <TotalSummary {...productInvoiceSummary} />
                                </div>
                            </>

                    }
                </>
            </Drawer>
        </>
    );

};