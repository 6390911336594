/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */
/* eslint @typescript-eslint/no-use-before-define: 0 */

import { Alert, Button, Card, Col, Progress, Row, Spin, Table, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { AMOUNT_ROUNDING_SCALE, RUPEE_SYMBOL } from '../../../../../Constants';
import { GET_RECEIVABLES_DATA } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { CreditPassbookResponse, EstablishmentCreditInfo, PendingCollectionRequest } from '../../../../../types/CreditPassbook';
import { ReceivableResponse } from '../../../../../types/FinanceCredit';
import { Tender } from '../../../../../types/Tender';
import { isArrayEmpty } from '../../../../../Utils';
import { OptionalTitle } from '../../../../common/TableTitle';
import { differenceInDays, getISTFromEpoch } from '../../../../../utilities/Date';
import { CollectionSourceType } from '../../../../finance/CollectionSource';
import CollectionClearanceModal, { CollectionModalMode } from '../../../../payments/collections/CollectionClearanceModal';

export interface OutletReceivablesProps {
    establishmentUuid: string;
    active: boolean;
}

function OutletReceivables(props: OutletReceivablesProps) {
    const [receivablesLoading, setReceivablesLoading] = useState<boolean>();
    const [receivablesList, setReceivablesList] = useState<Array<ReceivableResponse>>([]);
    const [creditInfo, setCreditInfo] = useState<EstablishmentCreditInfo>();
    const [creditClearanceTenders, setCreditClearanceTenders] = useState<Array<Tender>>([]);
    const [pendingCollectionRequest, setPendingCollectionRequest] = useState<PendingCollectionRequest | undefined>(
        undefined,
    );
    const [newCollectionModalVisible, setNewCollectionModalVisible] = useState<boolean>(false);
    const [pendingCollectionModalVisible, setPendingCollectionModalVisible] = useState<boolean>(false);

    const getReceivables = async () => {
        setReceivablesLoading(true);

        const createOrderEndPoint = GET_RECEIVABLES_DATA(props.establishmentUuid);

        const responseHandler: ResponseHandler<CreditPassbookResponse> = {
            onResponseSuccess(value: CreditPassbookResponse): void {
                setReceivablesLoading(false);
                setReceivablesList(value.receivables);
                setCreditInfo({
                    allowCreditClearance: value.allow_credit_clearance,
                    credit: value.credit,
                    creditLimit: value.credit_limit,
                    creditUsed: value.credit_used,
                    totalOutstandingAmount: value.total_outstanding_amount,
                });
                setCreditClearanceTenders(value.tenders);
                setPendingCollectionRequest(value.collection_request_transaction);
            },
            onResponseFailed(): void {
                setReceivablesLoading(false);
            },
            onResponseError(): void {
                setReceivablesLoading(false);
            },
        };

        await getApiCall(createOrderEndPoint, responseHandler);
    };

    useEffect(() => {
        if (props.active) {
            refreshPage();
        }
    }, [props.active]);

    function refreshPage() {
        getReceivables();
    }

    const tableColumns = [
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            width: '20%',
            render: (date: number) => <span>{getISTFromEpoch(date)}</span>,
        },

        {
            title: `Due Amount (in ${RUPEE_SYMBOL})`,
            dataIndex: 'outstanding_amount',
            render: (outstanding_amount: number) => <span>{outstanding_amount.toFixed(AMOUNT_ROUNDING_SCALE)}</span>,
            width: '20%',
        },

        {
            title: `Credit Value (in ${RUPEE_SYMBOL})`,
            dataIndex: 'amount',
            render: (amount: number) => <span>{amount.toFixed(AMOUNT_ROUNDING_SCALE)}</span>,
            width: '20%',
        },

        {
            title: 'Reference',
            dataIndex: 'reference_number',
            width: '20%',
            render: (referenceNumber: string, record: ReceivableResponse) => (
                
                ['ORDER', 'ORDER_COLLECTION_REJECTION'].includes(record.reference_type) ? 
                    <OptionalTitle title = {<Link to={`/order/${record?.reference_id}`} className="order-details-link">
                        {referenceNumber}
                    </Link>} description = {record.reference_remark} />
                    : <span>{record.reference_remark}</span>

            ),
            
        },
        {
            title: 'Generation Date',
            dataIndex: 'received_date',
            width: '20%',
            render: (date: number) => <span>{getISTFromEpoch(date)}</span>,
        },
    ];

    const addOverdueAmout = (overdue: number, receivable: ReceivableResponse) => {
        return (overdue += differenceInDays(Date.now(), receivable.due_date) > 0 ? receivable.outstanding_amount : 0);
    };

    function getOverdueAmount(receivablesList: Array<ReceivableResponse>): number {
        return receivablesList.reduce(addOverdueAmout, 0);
    }

    return (
        <Spin spinning={receivablesLoading} tip="Loading..." size="small" className="height-100-percent">
            {receivablesLoading && !creditInfo && <Card className="main-body-empty-card" />}
            <Row gutter={[24, 24]} justify="center">
                {creditInfo && (
                    <Col xl={12} xs={24}>
                        <Card
                            className="outlet-receivables-body"
                            hoverable
                            bodyStyle={{ padding: '0px' }}
                            cover={
                                <div>
                                    <Row justify="center">
                                        <Title level={4}>Info</Title>
                                    </Row>
                                    <Row gutter={[24, 24]} justify="space-between" className="horizontal-padding-15px">
                                        <Col xl={12} className="margin-top-10px">
                                            <Title level={5}>{`${RUPEE_SYMBOL} 0`}</Title>
                                        </Col>
                                        <Col xl={12} className="margin-top-10px">
                                            <Title
                                                level={5}
                                                className="text-align-right"
                                            >
                                                {`${RUPEE_SYMBOL} ${creditInfo?.creditLimit.toFixed(AMOUNT_ROUNDING_SCALE)}`}
                                            </Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px">
                                        <Col xs={24}>
                                            <Progress showInfo={false} percent={60} />
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px" justify="space-between">
                                        <Col xs={12}>
                                            <span>Credits Used</span>
                                        </Col>
                                        <Col xs={12}>
                                            <Title level={5} className="text-align-right">
                                                {`${RUPEE_SYMBOL}  ${creditInfo?.creditUsed.toFixed(AMOUNT_ROUNDING_SCALE)}`}
                                            </Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px" justify="space-between">
                                        <Col xs={12}>
                                            <span>Balance</span>
                                        </Col>
                                        <Col xs={12}>
                                            <Title level={5} className="text-align-right">
                                                {`${RUPEE_SYMBOL} ${creditInfo?.credit.toFixed(AMOUNT_ROUNDING_SCALE)}`}
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        />
                    </Col>
                )}

                {creditInfo && receivablesList && (
                    <Col xl={12} xs={24}>
                        <Card
                            className="outlet-receivables-body"
                            hoverable
                            bodyStyle={{ padding: '0px' }}
                            cover={
                                <div>
                                    <Row justify="center">
                                        <Title level={4}>Dues</Title>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px">
                                        <Col xs={12}>
                                            <span>Total Outstanding</span>
                                        </Col>
                                        <Col xs={12}>
                                            <Title level={5} className="text-align-right">
                                                {`${RUPEE_SYMBOL} ${creditInfo?.totalOutstandingAmount.toFixed(AMOUNT_ROUNDING_SCALE)}`}
                                            </Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px">
                                        <Col xl={12} xs={12}>
                                            <span>Overdue</span>
                                        </Col>
                                        <Col xs={12}>
                                            <Title level={5} className="text-align-right">
                                                {`${RUPEE_SYMBOL} ${getOverdueAmount(receivablesList).toFixed(AMOUNT_ROUNDING_SCALE)}`}
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            actions={[
                                <>
                                    {!creditInfo?.allowCreditClearance && (
                                        <Alert
                                            type="error"
                                            showIcon
                                            message="Collections are disabled since a request is already waiting for approval"
                                            style={{
                                                textAlign: 'center',
                                                margin: '0 10px 10px 10px',
                                            }}
                                        />
                                    )}

                                    <Row gutter={[24, 24]} justify="center">
                                        <Button
                                            className="default-button"
                                            disabled={!creditInfo?.allowCreditClearance}
                                            onClick={() => setNewCollectionModalVisible(true)}
                                        >
                                            Collect Dues
                                        </Button>
                                    </Row>
                                </>,
                            ]}
                        />
                    </Col>
                )}
            </Row>

            {pendingCollectionRequest && (
                <Row gutter={[24, 24]}>
                    <Col xl={12} xs={24}>
                        <Card
                            hoverable
                            bodyStyle={{ padding: '0px' }}
                            className="outlet-receivables-body-with-top-margin-15px"
                            cover={
                                <div>
                                    <Row justify="center">
                                        <Title level={4}>Pending Collection</Title>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px">
                                        <Col xl={12} xs={12}>
                                            <span>Amount</span>
                                        </Col>
                                        <Col xl={12} xs={12}>
                                            <Title level={5} className="text-align-right">{`${RUPEE_SYMBOL} ${pendingCollectionRequest?.amount.toFixed(AMOUNT_ROUNDING_SCALE)}`}</Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} className="horizontal-padding-15px">
                                        <Col xl={12} xs={12}>
                                            <span>Payment Mode</span>
                                        </Col>
                                        <Col xl={12} xs={12}>
                                            <Title level={5} className="text-align-right">
                                                {pendingCollectionRequest?.tender?.name}
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            actions={[
                                <Row gutter={[24, 24]} justify="center">
                                    <Button
                                        className="default-button"
                                        onClick={() => setPendingCollectionModalVisible(true)}>
                                        Collect Pending
                                    </Button>
                                </Row>,
                            ]}
                        />
                    </Col>
                </Row>
            )}

            {!isArrayEmpty(receivablesList) && (
                <Card
                    bodyStyle={{ padding: '0px' }}
                    className="outlet-receivables-body-with-top-margin-15px"
                    cover={
                        <div>
                            <Row justify="center">
                                <Title level={3} type="secondary">
                                    Receivables
                                </Title>
                            </Row>
                        </div>
                    }
                />
            )}

            {!isArrayEmpty(receivablesList) && (
                <div className="outlet-receivables-table">
                    <Table
                        columns={tableColumns}
                        rowKey={record => record.id}
                        dataSource={!isArrayEmpty(receivablesList) ? receivablesList : []}
                        scroll={{ x: 350 }}
                        pagination={false}
                    />
                </div>
            )}

            <CollectionClearanceModal
                clearanceModalVisible={newCollectionModalVisible}
                setClearanceModalVisible={setNewCollectionModalVisible}
                establishmentUuid={props.establishmentUuid}
                mode={CollectionModalMode.NEW_COLLECTION}
                clearanceAmounts={{
                    defaultValue: 0,
                    totalOutstanding: creditInfo?.totalOutstandingAmount ?? 0,
                    overdue: getOverdueAmount(receivablesList),
                }}
                // Cloning the tenders because we do not want any change being done
                // in the modal to propogate inside the existing tender config.
                tenders={_.cloneDeep(creditClearanceTenders)}
                refreshPage={refreshPage}
                setLoading={setReceivablesLoading}
                collectionSource={CollectionSourceType.CREDIT}
            />

            {pendingCollectionRequest && (
                <CollectionClearanceModal
                    clearanceModalVisible={pendingCollectionModalVisible}
                    setClearanceModalVisible={setPendingCollectionModalVisible}
                    establishmentUuid={props.establishmentUuid}
                    mode={CollectionModalMode.PENDING_COLLECTION}
                    clearanceAmounts={{
                        defaultValue: pendingCollectionRequest.amount,
                        totalOutstanding: creditInfo?.totalOutstandingAmount ?? 0,
                        overdue: getOverdueAmount(receivablesList),
                    }}
                    // Cloning the tender because we do not want any change being done
                    // in the modal to propogate inside the existing tender config.
                    tenders={_.cloneDeep([pendingCollectionRequest.tender])}
                    refreshPage={refreshPage}
                    setLoading={setReceivablesLoading}
                    collectionSource={CollectionSourceType.CREDIT}
                />
            )}
        </Spin>
    );
}

export default OutletReceivables;
