import { OrderDetailsResponse } from '../../../../../types/Order';

export enum BillType {
    RECEIPT = 'RECEIPT',
    INVOICE = 'INVOICE',
    PACKING_LIST = 'PACKING_LIST',
}

export namespace BillType {
    export function getFileName(type: BillType, order: OrderDetailsResponse): string {
        if (BillType.RECEIPT === type) {
            return `meraai-receipt-${order.id}.pdf`;
        } else if (BillType.PACKING_LIST === type) {
            return `meraai-packing-list-${order.id}.pdf`;
        } else {
            return `meraai-invoice-${order.invoice_no}.pdf`;
        }
    }
}

export type ProductNameInfoProps = {
    name: React.ReactNode;
    brand: React.ReactNode;
};