import { Form, Input } from 'antd';
import React from 'react';
import { PurchaseOrderCartItem } from '../establishments/layer/details/purchaseOrder/PurchaseOrderCartItem';
import { CartItem } from './CartItem';

export interface InputQuantityUpdateFormProps {
    item: CartItem | PurchaseOrderCartItem;
    updateCart: (item: CartItem | PurchaseOrderCartItem, quantity: number) => void;
}

function InputQuantityUpdateForm(props: InputQuantityUpdateFormProps) {

    const { item } = props;

    return (
        <Form
            name="form_name"
            className="create-order-item-page-list-form"
            fields={[
                {
                    name: ['fieldName'],
                    value: item.quantity,
                },
            ]}>
            <Form.Item name="fieldName" className="remove-margin">
                <Input
                    type="number"
                    min="0"
                    placeholder="Quantity"
                    onBlur={event =>
                        props.updateCart(
                            item, event.currentTarget.valueAsNumber >= 0 ? event.currentTarget.valueAsNumber : item.quantity!,
                        )
                    }
                />
            </Form.Item>
        </Form>
    );
}

export default InputQuantityUpdateForm;
