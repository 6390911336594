/* eslint @typescript-eslint/no-unused-vars: 0 */
import { Button, Card, message, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { AMOUNT_ROUNDING_SCALE, UPI_VERIFICATION_INTERVAL, RUPEE_SYMBOL } from '../../../Constants';
import { CREATE_DEPOSIT, VERIFY_DEPOSIT_PAYMENT } from '../../../http/EndPoints';
import { postApiCall, putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { DepositResponse } from '../../../types/CreditPassbook';
import { TenderStatus, TenderType } from '../../../types/Tender';
import RowColData from '../../common/RowColData';
import { DeepLinker } from '../../common/payu_upi/deeplinking/DeeplinkFromBrowser';
import { PaymentResponse } from '../../../types/FinanceCredit';
import { isMobile } from '../../common/MobileDetect';

export interface DepositRequest {
    collection_uuids: string[],
    tender: {
        type: TenderType
    },
    amount: number,
}

interface PayuIntentProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    title?: string;
    allowPayLater?: boolean;
    collectionUuids: string[];
    totalDueAmount: number;
    modalVisibility: boolean;
    setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    onPaymentCompletion: () => void;
    onPayLater?: () => void;
    handleWebViewAction?: () => void;
}

export const PayuIntent = (props: PayuIntentProps) => {
    // Props
    const {
        setLoading,
        title = 'Deposit',
        collectionUuids,
        totalDueAmount,
        modalVisibility,
        setModalVisibility,
        onPayLater,
        onPaymentCompletion,
        handleWebViewAction,
    } = props;

    // Hooks
    const [deposit, setDeposit] = useState<DepositResponse>();
    const [paymentModalLoading, setPaymentModalLoading] = useState<boolean>(false);
    const [loaderText, setLoaderText] = useState<string>('Please select a UPI app.');
    const [userReturn, setUserReturn] = useState<boolean>(false);
    const [paymentModalVisibility, setPaymentModalVisibility] = useState<boolean>(false);
    const [verifyModalVisibility, setVerifyModalVisibility] = useState<boolean>(false);
    const [verifyPaymentLoading, setVerifyPaymentLoading] = useState<boolean>(false);
    const [verifyButtonText, setVerifyButtonText] = useState<string>('Verify');


    // Methods
    const closePaymentModal = (paymentSuccessful?: boolean) => {
        setModalVisibility(false);
        setPaymentModalVisibility(false);
        setVerifyModalVisibility(false);
        if (paymentSuccessful) {
            onPaymentCompletion();
        } else {
            if (onPayLater) {
                onPayLater();
            }
        }
    };

    const toggleVerifyModalVisibility = () => {
        setVerifyModalVisibility(!verifyModalVisibility);
    };

    /**
     *  VerifyPayment method is handling all the 3 cases of payment i.e,
     * PENDING - Open verify modal
     * CONFIRM - Close all modal and Refresh the page 
     * FAILED - Keep Deposit modal open and let user to re-initiate the payment.
     */
    const verifyPayment = () => {
        setPaymentModalLoading(true);
        setLoaderText('Verifying your payment...');

        const responseHandler: ResponseHandler<PaymentResponse> = {
            onResponseSuccess(response: PaymentResponse) {
                if (response.tender_response.status === TenderStatus.CONFIRMED) {
                    closePaymentModal(true);
                    message.success(response.tender_response.message);
                } else if (response.tender_response.status === TenderStatus.FAILED) {
                    /**
                     * If in case the verify modal is open then we are closing it on payment failure.
                     * We are not closing the Deposit modal because we want user to retry the payment.
                     * */
                    message.error(response.tender_response.message);
                    setVerifyModalVisibility(false);
                } else if (response.tender_response.status === TenderStatus.PENDING) {
                    setVerifyModalVisibility(true);
                    message.warning(response.tender_response.message);
                }
                setPaymentModalLoading(false);
            },
            onResponseFailed() {
                setPaymentModalLoading(false);
                closePaymentModal();
            },
            onResponseError() {
                setPaymentModalLoading(false);
                closePaymentModal();
            },
        };

        putApiCall(VERIFY_DEPOSIT_PAYMENT(deposit?.uuid!, deposit?.payment.uuid!), {}, responseHandler);
    };

    const onReverifyPayment = () => {
        verifyPayment();
        // Initializing
        let haltTime = UPI_VERIFICATION_INTERVAL;
        setVerifyButtonText(`Verify in ${haltTime} sec`);

        const suspendApiCall = setInterval(() => {
            haltTime -= 1;
            setVerifyButtonText(`Verify in ${haltTime} sec`);
        }, 1000);

        setVerifyPaymentLoading(true);
        setTimeout(() => {
            clearInterval(suspendApiCall);
            setVerifyPaymentLoading(false);
            setVerifyButtonText('Verify');

        }, UPI_VERIFICATION_INTERVAL * 1000);
    };

    const navigateToUPIIntentTray = (deepUrl) => {

        const linker = new DeepLinker({
            onExecute() { },
            onIgnored() {
                message.warning('Oops! It seems like you don\'t have any UPI supported application installed on your device', 4);
                setPaymentModalLoading(false);
                linker.destroy();
            },
            onFallback() {
                message.warning('Please click on Pay Now again.');
                setPaymentModalLoading(false);
                linker.destroy();
            },
            onReturn() {
                setUserReturn(true);
                setPaymentModalLoading(false);
                linker.destroy();
            },
        });
        linker.openURL(deepUrl);
    };

    // This method is to initiate collection/deposit and generate deeplink
    const initiateDeposit = () => {

        setLoaderText('Please select a UPI app.');
        setPaymentModalLoading(true);
        const requestBody: DepositRequest = {
            collection_uuids: collectionUuids,
            amount: totalDueAmount,
            tender: {
                type: TenderType.PAYU_UPI,
            },
        };

        const responseHandler: ResponseHandler<DepositResponse> = {
            onResponseSuccess(response: DepositResponse) {
                setDeposit(response);
                navigateToUPIIntentTray(response?.payment.tender_response.data.find(item => item.type === 'DEEP_LINK')?.value);
            },
            onResponseError() {
                setPaymentModalLoading(false);
            },
            onResponseFailed() {
                setPaymentModalLoading(false);
            },
        };

        postApiCall(CREATE_DEPOSIT, requestBody, responseHandler);
    };

    const RowColDataProps = {
        key: 1,
        data: [
            {
                style: { fontSize: '16px' },
                value: 'Total Amount',
                styleName: 'white-space flex-center',
                xsSize: 24,
                mdSize: 12,
            },
            {
                style: { fontWeight: 500, fontSize: '18px' },
                value: `${RUPEE_SYMBOL} ${totalDueAmount?.toFixed(AMOUNT_ROUNDING_SCALE)}/-`,
                styleName: 'white-space flex-center',
                xsSize: 24,
                mdSize: 12,
            },
        ],
    };

    useEffect(() => {
        if (userReturn) {
            verifyPayment();
            setUserReturn(false);
        }
    }, [userReturn]);

    useEffect(() => {
        if (modalVisibility) {
            if (isMobile()) {
                setTimeout(() => {
                    setLoading(false);
                    setPaymentModalVisibility(true);
                }, 2000);
            } else {
                setLoading(false);
                setModalVisibility(false);
                if (handleWebViewAction) {
                    handleWebViewAction();
                }
                if (onPayLater) {
                    onPayLater();
                }
            }
        }
    }, [modalVisibility]);

    return (
        <>
            <Modal
                title={title}
                open={paymentModalVisibility}
                onCancel={() => { closePaymentModal(); }}
                closable={!paymentModalLoading}
                maskClosable={false}
                footer={
                    <div className='flex-center'>
                        {onPayLater &&
                            <Button
                                size='large'
                                onClick={() => {
                                    closePaymentModal();
                                }}
                                disabled={paymentModalLoading}
                            >
                                Pay later
                            </Button>
                        }
                        <Button
                            className='basic-button'
                            size='large'
                            style={{ backgroundColor: 'green' }}
                            disabled={paymentModalLoading || collectionUuids === undefined}
                            onClick={initiateDeposit}
                        >
                            Pay Now
                        </Button>
                    </div>
                }
            >
                <Spin spinning={paymentModalLoading} size={'small'} tip={loaderText}>
                    <Card className="basic-card" hoverable>
                        <RowColData
                            {...RowColDataProps}
                        />
                    </Card>
                </Spin>
            </Modal>
            <Modal
                title='Verification'
                open={verifyModalVisibility}
                closable={!paymentModalLoading}
                okText={verifyButtonText}
                onOk={onReverifyPayment}
                cancelText='Cancel'
                onCancel={toggleVerifyModalVisibility}
                cancelButtonProps={{ disabled: paymentModalLoading }}
                maskClosable={false}
                confirmLoading={verifyPaymentLoading}
            >
                <label className='text-style'>
                    Oh ho! It looks like your recent payment to meraai didn't work.
                    Don't panic, it happens!  <br />Please click on <b>verify</b> if you have made the payment.
                </label>

            </Modal>
        </>
    );
};