export class Field<T> {
    initialValue?: T | undefined;

    value?: T | undefined;

    required?: boolean;

    show?: boolean;

    disabled?: boolean;

    changed?: boolean;

    updateProperties?: UpdateProperties;

    public setInitialValue?(value: T, updateProperties?: UpdateProperties) {
        this.initialValue = value;
        this.value = value;
        this.changed = false;
        this.updateProperties = updateProperties;
    }

    constructor(field: Field<T> | undefined) {
        this.required = field?.required;
        this.show = field?.show;
        this.disabled = field?.disabled;
        this.changed = field?.changed;
    }
}

export enum UpdateRequestGroupingKey {
    BANK_ACCOUNT_DETAILS = 'bank_account_details',
}

export class UpdateProperties {
    text: string;

    requestKey: string;

    groupingKey: UpdateRequestGroupingKey | null;

    constructor(text: string, requestKey: string, groupingKey: UpdateRequestGroupingKey | null = null) {
        this.text = text;
        this.requestKey = requestKey;
        this.groupingKey = groupingKey;
    }
}
