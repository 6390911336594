import { Checkbox, message, Modal } from 'antd';
import React, { useState } from 'react';
import { COLLECTION_UPDATE } from '../../../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { Currency } from '../../../orders/layer/details/hubsAndOutlets/utils';
import { CollectionSource } from '../../CollectionSource';
import { CollectionState } from '../../CollectionState';
import { RejectionModalProps } from './types';
import { ExclamationCircleOutlined } from '@ant-design/icons';



const RejectionModal = (props: RejectionModalProps) => {

    const { collection, getCollections, setActionModalVisible, loading, setLoading, popRejectionPrompt, setPopRejectionPrompt } = props;

    const [isFineBeingImposed, setIsFineBeingImposed] = useState<boolean>();


    const performCollectionRejection = () => {
        setLoading(true);
        const rejectRequestData = {
            state: CollectionState.CANCELLED,
            impose_fine_on_rejection: isFineBeingImposed,
        };
        const endPoint = COLLECTION_UPDATE(collection?.uuid);

        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess: (response: any): void => {
                message.success(response.message, 3, () => { });
                getCollections();
                setActionModalVisible(false);
                setLoading(false);
                setPopRejectionPrompt(false);
            },
            onResponseFailed: (): void => {
                setLoading(false);
            },
            onResponseError: (): void => {
                setLoading(false);
            },
        };

        putApiCall(endPoint, rejectRequestData, responseHandler);
    };

    
    return (
        <Modal
            title={<><ExclamationCircleOutlined/>&ensp; Are you sure about the rejection?</>}
            open = {popRejectionPrompt}
            okText = 'Yes'
            onOk = {performCollectionRejection}
            okButtonProps = {{ disabled: loading }}
            cancelText = 'No'
            onCancel = {() => {setPopRejectionPrompt(false);}}
            cancelButtonProps = {{ disabled: loading } }
            
        >
            <span>
                {CollectionSource[collection?.source!]?.rejectMessage}
            </span>
            {collection.rejection_config?.impose_fine_on_rejection && 
                <div>
                    <Checkbox onChange={(event) => {
                        setIsFineBeingImposed(event.target.checked);
                    }}>
                        If checked, a fine of <Currency value={collection.rejection_config.fine_amount}/> will be imposed 
                    </Checkbox>
                </div>
            }
        </Modal>
    );
};

export default RejectionModal;