import _ from 'lodash';
import { Badge, Button, Col, Drawer, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { UPDATE_PURCHASE_ORDER } from '../../../../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { layout, style } from '../../../../../styles/css/style';
import { Datum } from '../../../../../types/Datum';
import {
    PurchaseOrderDetailResponse,
    UpdatePurchaseOrderItem,
    UpdatePurchaseOrderRequest,
    UpdatePurchaseOrderStateRequest,
} from '../../../../../types/PurchaseOrders';
import { PurchaseOrderState } from '../../../../../types/PurchaseOrderState';
import { StateChangeHandler } from '../../../../../types/StateChange';
import { MetadataUploader } from '../../../../common/metaDataUploader/index';
import { documentConfigs, MetadataDocument } from '../../../../common/metaDataUploader/types';
import { PurchaseOrderDetailsDrawer } from '../purchaseOrderItemReview';
import { PurchaseOrderStateChangeActionablesProps } from './types';
import { purchaseOrderStateChangeHandlers } from './utils';
import { ResponseDrawer } from '../../../../common/templates/responseDrawer';


export const PurchaseOrderStateChangeActionables = (props: PurchaseOrderStateChangeActionablesProps) => {

    const {
        config,
        purchaseOrderUuid,
        purchaseOrderDetails,
        setPurchaseOrderDetails,
        updateAvailableItemQuantity,
    } = props;

    const [selectedStateHandler, setSelectedStateHandler] = useState<StateChangeHandler>();
    const [loading, setLoading] = useState<boolean>(false);
    const [stateChangeDocuments, setStateChangeDocuments] = useState<MetadataDocument[]>([]);

    const [sellerPurchaseOrderDrawerVisibility, setSellerPurchaseOrderDrawerVisibility] = useState<boolean>(false);
    const [shortagesPreviewDrawerVisibility, setShortagesPreviewDrawerVisibility] = useState<boolean>(false);
    const [itemChangedCount, setItemChangedCount] = useState<number>(0);
    const [responseDrawerVisibility, setResponseDrawerVisibility] = useState<boolean>(false);
    const [purchaseOrderDetailsTemp, setPurchaseOrderDetailsTemp] = useState<PurchaseOrderDetailResponse>();

    const constructStyleClass = (filteredOrderStateChangeHandler): string => {
        let styleClass = 'xs:w-max ';
        if (filteredOrderStateChangeHandler.length === 1) {
            styleClass += 'w-full ';
        } else if (filteredOrderStateChangeHandler.length === 2) {
            styleClass += 'w-1/2';
        } else {
            styleClass += 'w-1/3';
        }
        return styleClass;
    };

    const onStateChangeAction = (handler: StateChangeHandler) => {
        setSelectedStateHandler(handler);
        setStateChangeDocuments(handler.mandatoryDocuments ? _.cloneDeep(handler.mandatoryDocuments) : []);
    };

    const resetSelectedStateHandler = () => {
        setSelectedStateHandler(undefined);
        setStateChangeDocuments([]);
    };

    const onResponseDrawerClose = () => {
        setResponseDrawerVisibility(false);
        setPurchaseOrderDetails(purchaseOrderDetailsTemp!);
        resetSelectedStateHandler();
        setLoading(false);
    };

    const updateOrder = async () => {
        setLoading(true);

        const requestBody: UpdatePurchaseOrderRequest = {
            state_request: {
                state: selectedStateHandler?.state,
                data: stateChangeDocuments
                    .filter(document => document.values)
                    .map(document => {
                        const documentConfig = documentConfigs.find(eachConfig => eachConfig.type === document.type);
                        let requestDocument = {
                            name: documentConfig?.datumName,
                            display_name: document.purpose,
                            type: documentConfig?.datumType,
                            function: 'DISPLAY',
                        };
                        if (document.multiple) {
                            requestDocument = { ...requestDocument, values: document.values?.map(item => item.value) } as Datum;
                        } else {
                            requestDocument = { ...requestDocument, value: document.values?.at(0)?.value } as Datum;
                        }

                        return requestDocument;
                    }),
                items: purchaseOrderDetails.items.map((item): UpdatePurchaseOrderItem => {
                    return {
                        item_id: item.id,
                        quantity: item.availableQuantity,
                    };
                }),
            } as UpdatePurchaseOrderStateRequest,
        };

        const endPointOrderAction = UPDATE_PURCHASE_ORDER(purchaseOrderUuid);
        const responseHandler: ResponseHandler<PurchaseOrderDetailResponse> = {
            onResponseSuccess(response: PurchaseOrderDetailResponse): void {
                setResponseDrawerVisibility(true);
                setPurchaseOrderDetailsTemp(response);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        await putApiCall(endPointOrderAction, requestBody, responseHandler);
    };

    const generateActionablesForStateChange = (nextValidStates: string[]) => {
        const filteredPurchaseOrderStateChangeHandler = purchaseOrderStateChangeHandlers.filter((handler: StateChangeHandler) => {
            return nextValidStates?.includes(handler.state) && config.stateChangeConfig.allowedStates?.includes(PurchaseOrderState.valueOf(handler.state)!);
        });

        return filteredPurchaseOrderStateChangeHandler.map((handler: StateChangeHandler, index: number) => {

            return <Col key={index} className={constructStyleClass(filteredPurchaseOrderStateChangeHandler)}>
                <Button
                    className={`text-xs font-semibold xs:font-normal xs:text-base ${handler.styleClass} ${layout.flex.center}`}
                    onClick={() => {
                        setSellerPurchaseOrderDrawerVisibility(true);
                        setItemChangedCount(0);
                        // updateAvailableItemQuantity method is to reset all the available values of each item whenever you open the modal.
                        updateAvailableItemQuantity!(purchaseOrderDetails);
                        onStateChangeAction(handler);
                    }}>
                    <label className={`cursor-pointer whitespace-pre-line ${layout.flex.center} `}>
                        {handler.display}
                    </label>
                </Button>
            </Col >;
        });
    };
    return (
        <>
            {
                purchaseOrderDetails && <Row gutter={[12, 12]} className={`${layout.flex.end} p-3`}>
                    {generateActionablesForStateChange(purchaseOrderDetails?.valid_next_states)}
                </Row>
            }
            <Drawer
                className='meraai-drawer'
                title={selectedStateHandler?.popUpModalMessage}
                open={selectedStateHandler !== undefined}
                closable={false}
                extra={<AiOutlineClose className='cursor-pointer' onClick={resetSelectedStateHandler} />}

                // Prevent Drawer Popup from unwanted closing.
                maskClosable={false}
                keyboard={false}
                destroyOnClose

                footer={
                    <>
                        <Row gutter={[24, 24]} className={`${layout.flex.end} `}>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton} `}
                                    onClick={resetSelectedStateHandler}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton} `}
                                    // Button would be disabled if there are any documents without a value
                                    disabled={
                                        stateChangeDocuments
                                            ?.find(document => !document?.values || document.values?.length === 0) !== undefined || loading
                                    }
                                    onClick={updateOrder}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Badge count={itemChangedCount}>
                        <Button
                            className={`mb-3 ${style.meraaiOrangeButton} `}
                            loading={loading}
                            onClick={() => {
                                setSellerPurchaseOrderDrawerVisibility(true);
                            }}
                        >
                            {selectedStateHandler?.markItemAvailablityButtonText ?? 'Check the accepted items'}
                        </Button>
                    </Badge>
                    <MetadataUploader documents={stateChangeDocuments} updateDocuments={setStateChangeDocuments} />
                </Spin>
            </Drawer >

            <PurchaseOrderDetailsDrawer
                purchaseOrderDetails={purchaseOrderDetails}
                setPurchaseOrderDetails={setPurchaseOrderDetails}
                sellerPurchaseOrderDrawerVisibility={sellerPurchaseOrderDrawerVisibility}
                setSellerPurchaseOrderDrawerVisibility={setSellerPurchaseOrderDrawerVisibility!}
                shortagesPreviewDrawerVisibility={shortagesPreviewDrawerVisibility}
                setShortagesPreviewDrawerVisibility={setShortagesPreviewDrawerVisibility}
                setItemChangedCount={setItemChangedCount}
                selectedStateHandler={selectedStateHandler}
                resetSelectedStateHandler={resetSelectedStateHandler}
            />

            <ResponseDrawer
                type='SUCCESS'
                open={responseDrawerVisibility}
                onClose={onResponseDrawerClose}
                message={<p className='font-semibold'>{purchaseOrderDetailsTemp?.message}</p>}
            />
        </>
    );
};