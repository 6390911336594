/* eslint @typescript-eslint/no-unused-vars: 0 */

import { Button, Col, Drawer, Empty, Row, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { LIST_BRANDS } from '../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../http/HttpClient';
import { Brand, BrandListResponse } from '../orders/listing/ListingData';
import BrandItem from './BrandItem';

export interface BrandListingDrawerProps {
    establishmentUuid: string;
    brandsDrawerVisibility: boolean;
    setBrandsDrawerVisibility: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    onBrandItemClick: (brand: Brand) => void;
}

const BRAND_PAGE_SIZE = 5;

function BrandListingDrawer(props: BrandListingDrawerProps) {

    const [brandsLoading, setBrandsLoading] = useState<boolean>(true);
    const [hasMoreData, setHasMoreData] = useState<boolean>(false);
    const [brandList, setBrandList] = useState<Array<Brand>>([]);
    const brandSearchName = useRef<string>('');
    const currentPage = useRef<number>(0);

    const getBrands = async (clearBrandList?: boolean) => {
        setBrandsLoading(true);
        const brandsListEndPoint = LIST_BRANDS(
            props.establishmentUuid,
            brandSearchName.current,
            currentPage.current,
            BRAND_PAGE_SIZE,
        );

        const responseHandler: ResponseHandler<BrandListResponse> = {
            onResponseSuccess(value: BrandListResponse): void {
                setBrandsLoading(false);
                if (clearBrandList) {
                    setBrandList(value.brands);
                } else {
                    setBrandList([...brandList, ...value.brands]);
                }

                setHasMoreData(value.next_page);
                currentPage.current += 1;
            },
            onResponseFailed(errors: String[]): void {
                setBrandsLoading(false);
            },
            onResponseError(): void {
                setBrandsLoading(false);
            },
        };

        await getApiCall(brandsListEndPoint, responseHandler);
    };

    useEffect(() => {
        currentPage.current = 0;
        getBrands(true);
    }, [props.establishmentUuid]);

    return (
        <Drawer
            headerStyle={{ textAlign: 'center' }}
            title="Brands"
            placement="left"
            onClose={() => props.setBrandsDrawerVisibility(false)}
            open={props.brandsDrawerVisibility}
            style={{ position: 'absolute' }}
            getContainer={false}>
            <Spin spinning={brandsLoading} tip="Loading..." size="small" style={{ height: '100%' }}>
                <Row gutter={[24, 24]} className="flex-center">
                    {brandList.length > 0 ? (
                        brandList.map(brandItem => {
                            return (
                                <Col key={brandItem.id} xs={24} className="flex-justify-center">
                                    <BrandItem brand={brandItem} onBrandItemClick={props.onBrandItemClick} />
                                </Col>
                            );
                        })
                    ) : (
                        <Empty
                            className="main-body-aggregate-empty"
                            image={Empty.PRESENTED_IMAGE_DEFAULT}
                            description={"This seller doesn't have any brand"}
                        />
                    )}
                </Row>

                <Row gutter={[24, 24]} style={{ paddingTop: '20px' }}>
                    {hasMoreData && (
                        <Col xs={24} className="flex-justify-center">
                            <Button
                                className="default-button"
                                size="large"
                                onClick={event => {
                                    getBrands();
                                }}
                                style={{ alignSelf: 'bottom' }}>
                                Load More
                            </Button>
                        </Col>
                    )}
                </Row>
            </Spin>
        </Drawer>
    );
}

export default BrandListingDrawer;
