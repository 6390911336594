/* eslint react/prop-types: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
import React from 'react';
import { Form, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Device, MeraaiTableColumnType, MeraaiTableProps } from './types';
import { ExpandableDataView } from './expandableDataView';
import { ExpandableColumnData } from './expandableDataView/types';
import { useAppSelector } from '../../../../hooks';
import { SearchInput } from '../../SearchInput';
import { IoSearchOutline } from 'react-icons/io5';
import { EditableCell, EditableRow } from './Editable';
import { EditableField } from './EditableField';

export const MeraaiTable = (props: MeraaiTableProps) => {

    const isMobileDevice = useAppSelector((state) => state.sideMenuCollapsed.collapsed);
    const currentDevice = isMobileDevice ? Device.MOBILE : Device.DESKTOP;

    const handleSave = (row) => {
        const newData = [...props.dataSource!];
        const index = newData.findIndex(item => row.id.value === item.id.value);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        if (props.setDataSource) {
            props.setDataSource(newData);
        }
    };

    const getColumns = (): ColumnsType<any> => {
        const finalColumns = props.columns.map(eachColumn => {
            let finalColumn: MeraaiTableColumnType = eachColumn;

            // Editable methods- START
            const fieldEditable = (record) => {
                const isFieldEditableInRecord =
                    // This check is applied to use `each column.dataIndex` as an index type. 
                    eachColumn.dataIndex && typeof eachColumn.dataIndex === 'string' &&

                    (!record[eachColumn.dataIndex].isEditable || record[eachColumn.dataIndex].isEditable(record));

                return eachColumn.editable && isFieldEditableInRecord;
            };

            if (eachColumn.editable) {
                finalColumn = {
                    ...eachColumn,
                    onCell: (record) => ({
                        record,
                        editable: fieldEditable(record),
                        dataIndex: eachColumn.dataIndex,
                        title: eachColumn.title,
                        handleSave,
                    }),
                };
            }
            // Editable methods- END

            if (eachColumn.enableSearch) {
                finalColumn = {
                    ...eachColumn,
                    filterDropdown: () => {
                        return <SearchInput
                            {...props.searchFilter}
                        />;
                    },
                    filterIcon: () => {
                        return <IoSearchOutline />;
                    },
                };
            }

            return finalColumn;
        });

        if (!(props.columns.every((column) => column.rowVisibility?.includes(currentDevice))) && !finalColumns.includes(Table.EXPAND_COLUMN)) {
            finalColumns.push(Table.EXPAND_COLUMN);
        }

        if (isMobileDevice) {
            return finalColumns.filter((column) => column?.rowVisibility?.includes(Device.MOBILE) || column === Table.EXPAND_COLUMN);
        } else {
            return finalColumns.filter((column) => column?.rowVisibility?.includes(Device.DESKTOP) || column === Table.EXPAND_COLUMN);
        }

    };

    const isEditableField = (object: any): boolean => {
        return object && Object.getPrototypeOf(object) === EditableField.prototype;
    };

    const getExpanableColumnData = (record: any): ExpandableColumnData[] => {

        const form = record.form;

        return props.columns.filter((column) => column.expandedRowProps?.visibility === 'ALWAYS' || !column.rowVisibility?.includes(currentDevice)).map((column, index) => {
            const getValue = (): any => {

                let columnValue, columnDataIndex;
                if (column.dataIndex && typeof column.dataIndex === 'string') {
                    columnValue = record[column.dataIndex];
                    columnDataIndex = column.dataIndex;
                } else {
                    columnValue = undefined;
                }

                const save = async () => {
                    try {
                        const values = await form.validateFields();
                        columnValue.value = values[columnDataIndex];
                    } catch (error) {
                        columnValue.value = error.values[columnDataIndex];
                    }
                    handleSave(record);
                };


                if (
                    isEditableField(columnValue) &&
                    props.expandableRowModificationType === 'EDIT' &&
                    !column.actionable &&
                    column.expandedRowProps?.valueFunction === 'COMPONENT'
                ) {

                    form?.setFieldsValue({
                        [columnDataIndex]: columnValue.value,
                    });

                    return <Form.Item
                        valuePropName={columnValue.componentValuePropName}
                        name={columnDataIndex}
                        className={`rounded-lg
                            ${columnValue?.isValueAcceptable!() ? 'editable-cell-value-wrap' : 'editable-cell-value-wrap-error'}
                        `}
                    >
                        {columnValue.component!(null, save, record)}
                    </Form.Item>;
                }

                if (column.render) {
                    return column.render(columnValue, record, index);
                }
                return columnValue;
            };

            const getWidth = () => {
                if (column.mobileWidth === 'FULL') {
                    return 'FULL';
                }
                return 'DEFAULT';
            };

            return {
                title: column.title,
                value: getValue(),
                actionable: column.actionable,
                width: getWidth(),
            } as ExpandableColumnData;
        });
    };

    const assignFormInstance = (record: any) => {
        const [form] = Form.useForm();
        if (Object.isExtensible(record)) {
            record['form'] = form;
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    return (
        <>
            <Table
                {...props}
                columns={getColumns()}
                dataSource={props.dataSource}
                scroll={props.scroll || {
                    x: isMobileDevice ? 'fit-content' : 'max-content',
                }}
                onRow={(record) => {
                    assignFormInstance(record);
                    return {};
                }}
                expandable={props.expandable || {
                    defaultExpandedRowKeys: props.defaultExpandableKey !== undefined ? [props.defaultExpandableKey] : [],
                    columnWidth: 1,
                    showExpandColumn: !props.columns.every((column) => column.rowVisibility?.includes(currentDevice)),
                    expandedRowRender: (record) => {
                        return <ExpandableDataView
                            expandableRowModificationType={props.expandableRowModificationType}
                            data={
                                {
                                    formRef: record.form,
                                    expandableColumnData: getExpanableColumnData(record),
                                }
                            }
                        />;
                    },

                    expandIcon: ({ expanded, onExpand, record }) => expanded ?
                        <UpOutlined
                            className='text-meraai-orange'
                            onClick={(event) => {
                                onExpand(record, event);
                            }}
                        />

                        :

                        <DownOutlined
                            className='text-meraai-orange'
                            onClick={(event) => {
                                onExpand(record, event);
                            }}
                        />,
                }}
                // Editable components
                components={components}
            />
        </>
    );
};