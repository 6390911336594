import { ReactNode } from 'react';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { Role } from '../../../types/Role';
import { UserProfileDetails } from '../profile/types';

export interface ModifyUserProps {
    mode: ModifyUserMode;
    accessibleRoles?: Role[];
    username?: string;

    chosenEstablishment?: {
        uuid?: string;
        type?: EstablishmentType;
    };

    modifyUserDrawerVisibility: boolean;
    setModifyUserDrawerVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRole?: Role | undefined;
    setUserListRequested?: React.Dispatch<React.SetStateAction<boolean>>;
    // Used to stop calling the `getUserDetails` API bcz we are calling it in the user profile section 
    // and passing the data through this prop.
    userProfileDetail?: UserProfileDetails;
}

export interface CreateUserRequestBody {
    mobile_number: string;
    country_code: string;
    email: string;
    first_name: string;
    last_name: string;
    role: Role;
    establishment_uuids: string[];
}

export interface CreateUserResponse {
    username: string;
    first_name: string;
    last_name: string;
    created_at: number;
    country_code: string;
    mobile_number: string;
    email: string;
    active: boolean;
    message: string;
    new_user: boolean;
}

export enum ModifyUserMode {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

export interface ModifyUserModeProperties {
    key: ModifyUserMode;
    buttonText: ReactNode;
    drawerTitle: ReactNode;
    getUserDetails: boolean;
    modifyRole: boolean; //used to hide or disable the component from the form
    modifyEstablishment: boolean; //used to hide or disable the component from the form
}


export const ModifyUserProperties = {
    EDIT: {
        key: ModifyUserMode.EDIT,
        buttonText: 'Update user',
        drawerTitle: 'Edit user details',
        getUserDetails: true,
        modifyRole: false,
        modifyEstablishment: false,
    } as ModifyUserModeProperties,
    CREATE: {
        key: ModifyUserMode.CREATE,
        buttonText: 'Add user',
        drawerTitle: 'Create user',
        getUserDetails: false,
        modifyRole: true,
        modifyEstablishment: true,
    } as ModifyUserModeProperties,
};