/* eslint @typescript-eslint/naming-convention: 0 */
import axios from 'axios';
import { store } from '../store';

const token = store.getState().auth.authToken;

const client = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.API_BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
});

const getApiCall = endPoint => client.get(endPoint);
const postApiCall = (endPoint, body) => client.post(endPoint, body);
const putApiCall = (endPoint, body) => client.put(endPoint, body);
const deleteApiCall = endPoint => client.delete(endPoint);

//TODO naming convention issue, we have to delete this file once we removed all its dependencies.
const status_obj = { applied: 'APPLIED', onboarded: 'ONBOARDED', rejected: 'REJECTED' };
const order_state_obj = {
    billed: 'Billed',
    accepted: 'Accepted',
    outForDelivery: 'Out for Delivery',
    delivered: 'Delivered',
};

// Global Variables and member functions
const getStatus = (val = 'applied') => status_obj[val];

const getOrderState = (val = 'billed') => order_state_obj[val];


export default getApiCall;
export {
    postApiCall,
    putApiCall,
    deleteApiCall,
    getStatus,
    getOrderState,
};
