import { Card } from 'antd';
import React from 'react';
import Title from 'antd/lib/typography/Title';
import { BsFillCheckCircleFill } from 'react-icons/bs';

export interface EstablishmentListItemProps {
    uuid: string;
    name: string;
    address: string;
    isSelectable: boolean;
    selected: boolean;
    toggleEstablishmentSelection: (uuid: string, selected: boolean) => void;
    onItemSelection: () => void;
}

function EstablishmentListItem(props: EstablishmentListItemProps) {
    return (
        <Card
            hoverable
            bodyStyle={{ padding: '0' }}
            style={{
                height: 'fit-content',
                width: '100%',
                margin: '5px',
                flexDirection: 'row',
                border: '1px solid #D9D9D9',
                borderRadius: '10px',
            }}
            onClick={() => {
                props.toggleEstablishmentSelection(props.uuid, !props.selected);
                props.onItemSelection();
            }}
            cover={
                <div className="flex-row-sb-center" style={{ paddingRight: '20px' }}>
                    <div
                        style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            maxWidth: '80%',
                        }}>
                        <Title level={5}>{props.name}</Title>
                        <span>{props.name}</span>
                    </div>
                    {props.selected && <BsFillCheckCircleFill />}
                </div>
            }
        />
    );
}

export default EstablishmentListItem;
