import { PathConfig } from '../components/config/PathConfigService';
import AdminConfigs from '../components/config/roles/AdminConfig';
import DeliverStaffConfigs from '../components/config/roles/DeliveryStaffConfig';
import FinanceConfig from '../components/config/roles/FinanceConfig';
import GroundOpsConfigs from '../components/config/roles/GroundOpsConfig';
import HubOperatorConfigs from '../components/config/roles/HubOperatorConfig';
import HubStaffConfigs from '../components/config/roles/HubStaffConfig';
import SellerConfigs from '../components/config/roles/SellerConfig';
import VendorConfigs from '../components/config/roles/VendorConfig';
import { Path } from '../components/router/Path';
import { Role } from './Role';


export enum UserType {
    ADMIN, AGENT,
}

export interface RoleInfo {
    key: Role;
    // userType is used to define the API that is called for getting the user information
    // agents/me if the user is an agent, and admins/me if the user is an ADMIN
    userType: UserType;
    display: string;
    configs: PathConfig[];
    basePath: Path;
}

export const RoleDataMap = new Map<Role, RoleInfo>(
    [
        [
            Role.ADMIN, {
                key: Role.ADMIN,
                userType: UserType.ADMIN,
                display: 'Admin',
                configs: AdminConfigs,
                basePath: Path.ANALYTICS,
            },
        ],
        [
            Role.VENDOR, {
                key: Role.VENDOR,
                userType: UserType.AGENT,
                display: 'Vendor',
                configs: VendorConfigs,
                basePath: Path.OUTLET_ORDER_LIST,
            },
        ],
        [
            Role.HUB_OPERATOR, {
                key: Role.HUB_OPERATOR,
                userType: UserType.AGENT,
                display: 'Hub operator',
                configs: HubOperatorConfigs,
                basePath: Path.HUB_ORDER_LIST,
            },
        ],
        [
            Role.HUB_STAFF, {
                key: Role.HUB_STAFF,
                userType: UserType.ADMIN,
                display: 'Hub staff',
                configs: HubStaffConfigs,
                basePath: Path.HUB_ORDER_LIST,
            },
        ],
        [
            Role.DELIVERY_STAFF, {
                key: Role.DELIVERY_STAFF,
                userType: UserType.ADMIN,
                display: 'Delivery staff',
                configs: DeliverStaffConfigs,
                basePath: Path.ESTABLISHMENTS,
            },
        ],
        [
            Role.SELLER, {
                key: Role.SELLER,
                userType: UserType.AGENT,
                display: 'Seller',
                configs: SellerConfigs,
                basePath: Path.SELLER_HUB_PURCHASE_ORDER_LIST,
            },
        ],
        [
            Role.GROUND_OPS, {
                key: Role.GROUND_OPS,
                userType: UserType.AGENT,
                display: 'Sales associate',
                configs: GroundOpsConfigs,
                basePath: Path.ESTABLISHMENTS,
            },
        ],
        [
            Role.FINANCE, {
                key: Role.FINANCE,
                userType: UserType.ADMIN,
                display: 'Finance',
                configs: FinanceConfig,
                basePath: Path.FINANCE,
            },
        ],
    ],
);
