import { isArrayEmpty } from '../../../../../../../Utils';
import { ProductImage, ProductProperties } from '../../properties/ProductProperties.';
import { NAME_REGEX } from '../Constant';



export const initializeListProductProperties = () => {
    return new ProductProperties({
        id: { beingUsed: true, required: false },
        active: {
            beingUsed: true,
            required: false,
        },
        name: { beingUsed: true, required: true, validateValue: value => NAME_REGEX.test(value) },
        price: { beingUsed: true, required: true, validateValue: value => value !== '' && value > 0 },
        mrp: { beingUsed: true, required: true, validateValue: value => value !== '' && value > 0 },
        sku: { beingUsed: true, required: false, validateValue: value => NAME_REGEX.test(value) },
        hsn: { beingUsed: true, required: true },
        ean: { beingUsed: true, required: true },
        gst: { beingUsed: true, required: true, validateValue: value => value !== '' && value >= 0 },
        cess: { beingUsed: true, required: true, validateValue: value => value !== '' && value >= 0 },
        brand: {
            beingUsed: true,
            required: true,
            alwaysRenderComponent: true,
        },
        categories: {
            beingUsed: true,
            required: true,
            alwaysRenderComponent: true,
            validateValue: (value) => !isArrayEmpty(value),
        },
        orderQuantityMultiple: { beingUsed: true, required: false, validateValue: value => value == undefined || value >= 0 },
        image: {
            beingUsed: true,
            required: true,
            validateValue: (value: ProductImage) => value?.imageFile?.file !== undefined || value?.imageFile?.url !== undefined,
        },
        fields: { beingUsed: true, required: false },
        metadata: { beingUsed: true, required: false },
        
    });
};
