import { Button, Card, Col, Modal, Row, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { EstablishmentType } from '../../../types/EstablishmentType';
import EstablishmentListItem from './EstablishmentListItem';
import { EstablishmentSelectionMode, SelectableEstablishment } from './EstablishmentsDropDown';

export interface EstablishmentSelectionModalProps {
    visible: boolean;
    loading: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    type: EstablishmentType;
    selectionMode: EstablishmentSelectionMode;
    establishments: SelectableEstablishment[];
    setEstablishments: React.Dispatch<React.SetStateAction<SelectableEstablishment[]>>;
    toggleEstablishmentSelection: (uuid, boolean) => void;
    loadMore: () => void;
    hasMoreData: boolean;
}

function EstablishmentSelectionModal(props: EstablishmentSelectionModalProps) {
    const onItemSelected = () => {
        if (props.selectionMode === EstablishmentSelectionMode.SINGLE_SELECT) {
            props.setVisible(false);
        }
    };

    const getEstablishmentList = () => {
        return props.establishments?.map(item => {
            return (
                <Row key={item.establishment.uuid}>
                    <EstablishmentListItem
                        uuid={item.establishment.uuid}
                        name={item.establishment.name}
                        address={item.establishment.address}
                        isSelectable={props.selectionMode != EstablishmentSelectionMode.SINGLE_SELECT}
                        selected={item.selected}
                        toggleEstablishmentSelection={props.toggleEstablishmentSelection}
                        onItemSelection={onItemSelected}
                    />
                </Row>
            );
        });
    };

    const getSelectedEstablishments = () => {
        return props.establishments
            ?.filter(item => item.selected)
            ?.map(item => {
                return (
                    <Row
                        key={item.establishment.uuid}
                        style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginTop: '5px',
                            marginBottom: '5px',
                        }}>
                        <Card
                            bodyStyle={{ padding: '0' }}
                            hoverable
                            onClick={() => {
                                props.toggleEstablishmentSelection(item.establishment.uuid, false);
                            }}
                            style={{
                                height: 'fit-content',
                                width: '100%',
                                flexDirection: 'row',
                                border: '1px solid #D9D9D9',
                                borderRadius: '5px',
                            }}
                            cover={
                                <div className="flex-row-sb-center" style={{ paddingRight: '10px' }}>
                                    <span
                                        style={{
                                            maxWidth: '80%',
                                            marginTop: '5px',
                                            marginBottom: '5px',
                                            marginLeft: '10px',
                                        }}>
                                        {item.establishment.name}
                                    </span>
                                    <AiFillCloseCircle />
                                </div>
                            }
                        />
                    </Row>
                );
            });
    };

    return (
        <Modal
            bodyStyle={{ overflowY: 'scroll', maxHeight: '500px' }}
            style={{ paddingBottom: '20px' }}
            open={props.visible}
            title={props.type.display}
            width="80%"
            onCancel={() => {
                props.setVisible(false);
            }}
            footer={
                <div style={{ justifyContent: 'center' }}>
                    <Button
                        className="secondary-button"
                        onClick={() => {
                            props.setVisible(false);
                        }}>
                        Close
                    </Button>
                </div>
            }>
            <Spin spinning={props.loading} tip="Loading..." size="small" style={{ height: '100%' }}>
                <Row gutter={[24, 24]}>
                    <Col xs={24} xl={16}>
                        <>{getEstablishmentList()}</>
                        {props.hasMoreData && (
                            <Row className="select-establishment-load-more-button">
                                <Button
                                    className="default-button"
                                    size="large"
                                    onClick={() => {
                                        props.loadMore();
                                    }}
                                    style={{ alignSelf: 'bottom' }}>
                                    Load more
                                </Button>
                            </Row>
                        )}
                    </Col>
                    <Col xs={0} xl={8}>
                        <Row justify="center">
                            <Title level={4}>Selected</Title>
                        </Row>
                        {getSelectedEstablishments()}
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}

export default EstablishmentSelectionModal;
