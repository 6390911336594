import { CartItem } from '../components/cart/CartItem';
import { PurchaseOrderCartItem } from '../components/establishments/layer/details//purchaseOrder/PurchaseOrderCartItem';
import { TenderType } from './Tender';

export interface EstablishmentCart {
    selectedItems: Array<CartItem>;
    selectedItemsCount: number;
    totalAmount: number;
    lastUpdatedAt: number;
}

export interface UpdateProductPayload {
    establishmentUuid: string;
    cartItem: CartItem;
}

export interface PurchaseOrderEstablishmentCart {
    selectedItems: Array<PurchaseOrderCartItem>;
    selectedItemsCount: number;
    lastUpdatedAt: number;
}

export interface PurchaseOrderUpdateProductPayload {
    cartReferenceId: string;
    cartItem: PurchaseOrderCartItem;
}

export interface OrderItemInfo {
    sku: string;
    quantity: number;
}

export interface OrderTender {
    type: TenderType;
}

export interface CreateOrderRequest {
    agent_uuid: string | null;
    items: Array<OrderItemInfo>;
    tender: OrderTender;
    creation_date: number;
    stages: any[];
}

export interface CreateOrderResponse {
    id: number;
    uuid: string;
}

export enum ProductItemType {
    PAGE_LIST = 'PAGE_LIST',
    CART = 'CART',
}
