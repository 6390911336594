import React from 'react';
import CloseableImage from './CloseableImage';
import { DocumentValue } from './MetadataUploader';

export interface DocumentImageRendererProps {
    height?: number;
    width?: number;
    images?: DocumentValue[];
    deleteImage?: (image: string) => void;
}

const DocumentImageRenderer = (props: DocumentImageRendererProps) => {
    const { height = 90, width = 90, images, deleteImage } = props;
    return (
        <>
            {
                images &&
                images.map((image) => {
                    function deleteImageFromList() {
                        if (deleteImage) {
                            deleteImage(image.value);
                        }
                    }
                    return <CloseableImage height={height} width={width} src={image.value} styleClass='p-2' onClose={deleteImageFromList} />;
                })
            }
        </>

    );
};

export default DocumentImageRenderer;