import { Type } from '../../../utilities/datumRenderer/config/type';
import { CategoryResponse, CategoryDetails as CategoryDetails } from '../types';

export interface ModifyCategoryResponse {
    id: number;
    message: string;
}

export interface ModifyCategoryDrawerProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    mode: CategoryMode;
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    categoryDetails?: CategoryDetails;
    categoryResponse?: CategoryResponse;
    categoryStatusUpdate?: boolean; //Activate or deactivate
}

export enum CategoryMode {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
}

export interface CategoryModePropertyType {
    'CREATE': {
        mode: CategoryMode,
        drawerTitle: string,
        okButtonText: string
    },
    'UPDATE': {
        mode: CategoryMode,
        drawerTitle: string,
        okButtonText: string
    },
}

export const CategoryModeProperty: CategoryModePropertyType = {
    'CREATE': {
        mode: CategoryMode.CREATE,
        drawerTitle: 'Add category',
        okButtonText: 'Add category',
    },
    'UPDATE': {
        mode: CategoryMode.UPDATE,
        drawerTitle: 'Edit category',
        okButtonText: 'Save Details',
    },
};

export interface CategoryRequestBody {
    id?: number;
    name?: string;
    description?: string;
    image?: string;
    type?: Type.PRODUCT;
    parent_category_id?: number | null;
    active: boolean;
}