import React from 'react';
import { Button, Modal } from 'antd';
import { SuccessModalProps } from './type';
import { useNavigate } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';

export const SuccessModal = (props: SuccessModalProps) => {
    const navigate = useNavigate();

    return (
        <>
            <Modal
                title={<h3 className='flex-align-center '> <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '30px', paddingRight: '10px' }} /> Success</h3>}
                open={props.successModalVisibility}
                onCancel={() => {
                    props.setSuccessModalVisibility(false);
                }}
                footer={
                    [
                        <Button
                            key='createBrands'
                            onClick={() => {
                                props.setSuccessModalVisibility(false);
                                props.setCreateBrandModalVisibility(true);
                            }}
                        >
                            Add another brand.
                        </Button>,

                        <Button
                            type='primary'
                            key='createProducts'
                            onClick={() => {
                                props.setSuccessModalVisibility(false);
                                navigate('?type=SELLER_HUB_PRODUCTS&sub-type=CREATE');
                            }}
                        >
                            Add Products.
                        </Button>,
                    ]
                }
            >
                <label style={{ fontSize: '16px' }} className='flex-align-center '> Brand created successfully.</label>
            </Modal>
        </>
    );

};