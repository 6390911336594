import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FIREBASE_TOKEN } from '../../Constants';

interface AuthState {
    firebaseToken: string;
}

const initialState: AuthState = {
    firebaseToken: localStorage.getItem(FIREBASE_TOKEN) != null ? localStorage.getItem(FIREBASE_TOKEN)!! : '',
};

const firebaseTokenSlice = createSlice({
    name: 'firebase_token',
    initialState: initialState,
    reducers: {
        updateFirebaseToken(state, action: PayloadAction<string>) {
            state.firebaseToken = action.payload;
            localStorage.setItem(FIREBASE_TOKEN, action.payload);
        },
    },
});

export const { updateFirebaseToken } = firebaseTokenSlice.actions;
export default firebaseTokenSlice.reducer;