import { style } from '../../../../../styles/css/style';
import { StateChangeHandler } from '../../../../../types/StateChange';
import { createDocument } from '../../../../common/MetadataUploader';
import { OrderStates } from '../../../hubsAndOutlets/utils';

// Data Members
export const orderStateChangeHandlers: StateChangeHandler[] = [
    {
        state: OrderStates.HUB_ACCEPTED,
        display: 'Accept',
        styleClass: style.meraaiOrangeButton,
        popUpModalMessage: 'Proceed to accept the order',
        markItemAvailablityButtonText: 'Click here to verify the items',
        itemsModalVisibileByDefault: true,
        itemAvailabilityModalSubscript: 'Review the items and decrease the quantity if any change is needed.',
        inputQuantityColName: 'Acceptable QTY',
    },
    {
        state: OrderStates.HUB_REJECTED,
        display: 'Reject',
        styleClass: style.meraaiDangerButton,
        popUpModalMessage: 'Proceed to reject the order',
        mandatoryDocuments: [createDocument('DROP_DOWN', true, 'Reason', {
            values:
                [
                    'outlet cancellation',
                    'incorrect order placed',
                ], allowCustomInput: true,
        }, false)],
    },
    {
        state: OrderStates.SCHEDULE_DELIVERY,
        display: 'Pack Items',
        styleClass: style.meraaiOrangeButton,
        popUpModalMessage: 'Proceed to acknowledge that you\'ve packed the items',
        markItemAvailablityButtonText: 'Click here to pack items',
        itemsModalVisibileByDefault: true,
        itemAvailabilityModalSubscript: 'Review the items and adjust the quantity not available in the inventory.',
        inputQuantityColName: 'Available QTY',
    },
    {
        state: OrderStates.DELIVERY_CANCELLED,
        display: 'Cancel Delivery',
        styleClass: style.meraaiDangerButton,
        popUpModalMessage: 'Proceed to cancel delivery',
        mandatoryDocuments: [createDocument('DROP_DOWN', true, 'Reason', {
            values:
                [
                    'outlet cancellation',
                    'outlet has been shutdown',
                    'products could not be procured',
                ], allowCustomInput: true,
        }, false)],
    },
    {
        state: OrderStates.OUT_FOR_DELIVERY,
        display: 'Out for Delivery',
        styleClass: style.meraaiOrangeButton,
        markItemAvailablityButtonText: 'Click here to check items',
        popUpModalMessage: 'Proceed to mark the order as out for delivery',
        inputQuantityColName: 'Packed Quantity',
    },
    {
        state: OrderStates.NOT_DELIVERED,
        display: "Couldn't Deliver",
        styleClass: style.meraaiPlainButton,
        popUpModalMessage: 'Proceed to mark the order as not delivered. Delivery can be tried again',
        mandatoryDocuments: [createDocument('DROP_DOWN', true, 'Reason', {
            values:
                [
                    'outlet was closed',
                    'owner was not available',
                    'location was incorrect',
                    'could not reach the outlet',
                ], allowCustomInput: true,
        }, false)],
    },
    {
        state: OrderStates.PAYMENT_COLLECTION_INITIATED,
        display: 'Collect Payment',
        styleClass: style.meraaiOrangeButton,
        popUpModalMessage: 'Proceed to initiate the payment collection',
        markItemAvailablityButtonText: 'Mark shortages in delivery',
        itemAvailabilityModalSubscript: 'Review the items and adjust the quantity that is actually being delivered.',
        inputQuantityColName: 'Delivered QTY',
        itemsModalVisibileByDefault: true,
        itemShortageDocuments: [createDocument('DROP_DOWN', true, 'Reason', {
            values:
                [
                    'outlet rejection',
                    'short delivery',
                ], allowCustomInput: true,
        }, false)],
    },
    {
        state: OrderStates.DELIVERED,
        display: 'Delivered',
        styleClass: style.meraaiOrangeButton,
        popUpModalMessage: 'Proceed to mark the order as delivered',
        markItemAvailablityButtonText: 'Mark shortages in delivery',
        itemAvailabilityModalSubscript: 'Review the items and adjust the quantity that was actually delivered.',
        mandatoryDocuments: [createDocument('IMAGE', true, 'Purchase Order Receipt', undefined, true)],
        inputQuantityColName: 'Delivered QTY',
        itemsModalVisibileByDefault: true,
        itemShortageDocuments: [createDocument('DROP_DOWN', true, 'Reason', {
            values:
                [
                    'outlet rejection',
                    'short delivery',
                ], allowCustomInput: true,
        }, false)],
    },
    {
        state: OrderStates.DELIVERY_REJECTED,
        display: 'Delivery Rejected',
        styleClass: style.meraaiDangerButton,
        popUpModalMessage: 'Proceed to reject delivery',
        mandatoryDocuments: [createDocument('DROP_DOWN', true, 'Reason', {
            values:
                [
                    'outlet rejection',
                ], allowCustomInput: true,
        }, false)],
    },
];
