import { Button, Col, Drawer, message, Row } from 'antd';
import React, { useEffect } from 'react';
import { ProductProductDrawerProps } from './type';
import { v4 as uuidv4 } from 'uuid';
import { Field, FieldVisibility } from '../../../../../../orders/listing/ListingData';
import { Type } from '../../../../../../../utilities/datumRenderer/config/type';
import _ from 'lodash';
import { isArrayEmpty } from '../../../../../../../Utils';
import { ProductField } from './field';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../../../../../styles/css/style';
import { IoMdAdd } from 'react-icons/io';

export const ProductPropertyDrawer = (props: ProductProductDrawerProps) => {

    // productFields state is to maintain as and when the changes occur in the product information.
    const [productFields, setProductFields] = React.useState<Field[] | undefined>([]);
    const [triggerRule, setTriggerRule] = React.useState<boolean>();

    const closeDrawer = () => {
        /**
        * If the user directly closes the drawer
        * we are setting the initial(unchanged) details into setProductFields state
        * from props.selectedProductFields.
        **/
        setProductFields(_.cloneDeep(props.selectedProductFields));
        props.setProductPropertyDrawerVisibility(false);
    };

    useEffect(() => {
        if (!isArrayEmpty(props.selectedProductFields)) {
            /**
             * If fields are present in the props.selectedProductFields, 
             * We are creating a new object from it and setting it into
             * setProductFields state
             * */
            setProductFields(_.cloneDeep(props.selectedProductFields));
            /**
             * Initially, we are not triggering the validation. 
             * Whenever the modal gets opened, we clear the error signs from the fields.
             **/

            setTriggerRule(false);
        }
    }, [props.selectedProductFields]);

    return (
        <>
            <Drawer
                className='meraai-drawer'
                open={props.productPropertyDrawerVisibility}
                title={<h3>Product Information</h3>}
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    closeDrawer();
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className='w-1/3 lg:w-1/5 sm:absolute sm:left-3'>
                                <Button
                                    icon={<IoMdAdd className='mx-1' />}
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={() => {
                                        productFields?.push({
                                            id: uuidv4(),
                                            visibility: [FieldVisibility.DETAILED],
                                            mandatory: false,
                                            elements: [{
                                                id: uuidv4(),
                                                key: undefined,
                                                value: undefined,
                                                value_type: Type.STRING,
                                                deletable: true,
                                            }],
                                        });
                                        setProductFields([...productFields!]);
                                    }}
                                >
                                    Add new row
                                </Button>
                            </Col>
                            <Col className='w-1/3 lg:w-1/5'>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={() => {
                                        closeDrawer();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/3 lg:w-1/5'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    onClick={() => {
                                        /**
                                         * Once the user confirm the details we are updating the fields value
                                         * in the dataSource.
                                         */
                                        const valid = productFields?.every(field => field.elements
                                            ?.every(element => {
                                                /** 
                                                 * If prefix options present, it returns valid_prefix that stores 
                                                 * the return value of ruleValidator() method.
                                                */
                                                if (element.value_prefix_options) {
                                                    if (!element.valid_prefix) {
                                                        return false;
                                                    }
                                                }
                                                /** 
                                                 * If suffix options present, it returns valid_suffix that stores 
                                                 * the return value of ruleValidator() method.
                                                */
                                                if (element.value_suffix_options) {
                                                    if (!element.valid_suffix) {
                                                        return false;
                                                    }
                                                }
                                                
                                                return (element.key || element.valid_key) && element.valid_value;

                                            }));

                                        if (valid) {
                                            props.updateValue('fields', productFields, props.selectedProductId!);
                                            props.setProductPropertyDrawerVisibility(false);
                                        } else {
                                            setTriggerRule(true);
                                            message.destroy();
                                            message.error('Please fill in the required fields');
                                        }
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <>
                    {
                        productFields?.map((eachField, index) => <ProductField
                            key={index}
                            productFields={productFields}
                            setProductFields={setProductFields}
                            field={eachField}
                            triggerRule={triggerRule}
                        />)
                    }
                </>
            </Drawer>
        </>
    );
};