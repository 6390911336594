import React, { ReactElement } from 'react';
import { Input } from 'antd';
import loadash from 'lodash';

export class EditableField<T> {
    beingUsed: boolean;

    initialValue?: T | undefined;

    initialValueFinalized?: boolean;

    value?: T | undefined;

    required?: boolean;

    processingStatus?: boolean;

    component?: (
        ref: React.MutableRefObject<any>,
        saveValue: () => void,
        record?: Record<any, EditableField<any>>,
    ) => ReactElement;

    /**
     * Components such as Input or Select use value as the prop for holding the value. 
     * However, certain components such as Checkbox or Switch use checked. 
     * If your child component is a Checkbox, you need to manually specify the valuePropName as checked 
     * because the default is value (this is why Input/Select components don't need this manual specification).
     */
    componentValuePropName?: string;

    alwaysRenderComponent?: boolean;

    validateValue?: (value: any) => boolean;

    updateProperties?: UpdateProperties;

    createProperties?: CreateProperties;

    isEditable?: (record: Record<any, any>) => boolean;

    public setInitialValue?(value: T, updateProperties?: UpdateProperties, createProperties?: CreateProperties, initialValueFinalized?: boolean) {
        this.initialValue = value;
        this.value = value;
        this.updateProperties = updateProperties;
        this.createProperties = createProperties;
        this.initialValueFinalized = initialValueFinalized ?? true;
    }

    constructor(field: EditableField<T> | undefined) {
        this.required = field?.required;
        this.beingUsed = field?.beingUsed!;
        this.validateValue = field?.validateValue;
        if (field?.component) {
            this.component = field.component;
        } else {
            this.component = (ref, saveValue) => <Input ref={ref} onPressEnter={saveValue} onBlur={saveValue} />;
        }

        if (field?.componentValuePropName) {
            this.componentValuePropName = field?.componentValuePropName;
        } else {
            this.componentValuePropName = 'value';
        }
        this.alwaysRenderComponent = field?.alwaysRenderComponent;
        this.isEditable = field?.isEditable;
    }


    public setProcessingStatus?(status: boolean) {
        this.processingStatus = status;
    }

    public isValueChanged?() {
        return !loadash.isEqual(this.initialValue, this.value);
    }

    public isValueAcceptable?() {
        if (this.value != undefined && this.validateValue) {
            return this.validateValue(this.value);
        }
        if (this.required) {
            if (!this.value) {
                return false;
            }
        }
        return true;
    }
}




// Create products
export enum CreateRequestGroupingKey {
    TAXES = 'taxes',
}
export class CreateProperties {
    text: string;

    requestKey: string;

    groupingKey: CreateRequestGroupingKey | null;

    constructor(text: string, requestKey: string, groupingKey: CreateRequestGroupingKey | null = null) {
        this.text = text;
        this.requestKey = requestKey;
        this.groupingKey = groupingKey;
    }
}

// Update product details
export enum UpdateRequestGroupingKey {
    TAXES = 'taxes',
}
export class UpdateProperties {
    text: string;

    requestKey: string;

    groupingKey: UpdateRequestGroupingKey | null;

    constructor(text: string, requestKey: string, groupingKey: UpdateRequestGroupingKey | null = null) {
        this.text = text;
        this.requestKey = requestKey;
        this.groupingKey = groupingKey;
    }
}
