import { RefObject } from 'react';
import { Establishment } from '../../../Types';

export interface FieldElement {
    id: string;
    key: string | undefined;
    value_type: string;
    value?: string;
    value_prefix?: string;
    value_suffix?: string;
    description?: string;
    value_prefix_options?: string[];
    value_suffix_options?: string[];
    value_options?: string[];
    deletable?: boolean;
    ref?: RefObject<any>;
    valid_key?: boolean;
    valid_value?: boolean;
    valid_prefix?: boolean;
    valid_suffix?: boolean;
}

export interface Field {
    id: string;
    visibility: FieldVisibility[];
    mandatory: boolean;
    elements?: FieldElement[];
    // elementMap is for internal purposes used to construct product fields in sellerHubProductList.
    elementMap?: Map<string, FieldElement>;
}

export interface Brand {
    establishment_uuid?: string;
    id: number;
    name: string;
    priority: number;
    active: boolean;
    image_url: string;
    title?: string;
    description?: string;
    fields?: Field[]
}

export interface Product {
    id: number;
    sku: string;
    ean?: string;
    name: string;
    price: number;
    maximum_retail_price: number;
    brand: string;
    image: string | undefined;
}

export interface BrandListResponse {
    brands: Array<Brand>;
    establishment?: Establishment;
    page_count: number;
    page_size: number;
    next_page: boolean;
}

export interface ListProductsResponse {
    products: Array<Product>;
    establishment: Establishment;
    page_count: number;
    page_size: number;
    next_page: boolean;
}

export enum FieldVisibility {
    SUMMARY = 'SUMMARY',
    DETAILED = 'DETAILED',
}