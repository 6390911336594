import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { HiRefresh } from 'react-icons/hi';
import { setPadding } from '../../styles/css/react/MeraaiCssProperties';
import { Iframe } from '../common/iframe/Iframe';
import PageTopSection from '../common/PageTopSection';
import { MeraaiTabs } from '../common/tabs/MeraaiTabs';
import ConfigContext from '../config/ConfigContext';
import { AnalyticsConfig } from '../config/types/ConfigTypes';


const breadcrumb = [{ name: 'Analytics' }];
interface MetabaseDashboardProps {
    id: string;
}



export const Analytics = () => {
    const config = React.useContext(ConfigContext) as AnalyticsConfig;
    const [render, setRender] = useState<boolean>(false);

    const MetabaseDashboard = (props: MetabaseDashboardProps) => {
        return <>
            <Row>
                <Col
                    xs={24}
                    className='flex-column-end'
                    style={setPadding(10, 10, 10, 10)}
                >
                    <Button
                        className='default-button'
                        icon={<HiRefresh />}
                        onClick={() => {
                            setRender(true);
                        }}
                    >
                        &nbsp; Refresh
                    </Button>
                </Col>
            </Row>
            <Row
                className="main-body-filters"
                style={{ height: '100vh' }}
            >
                <Col
                    xs={24}
                    style={setPadding(10, 10, 10, 10)}
                >
                    <Iframe
                        src={`https://metabase-app-fwv5cmmusa-de.a.run.app/public/dashboard/${props.id}`}
                        width={'100%'}
                        height={'100%'}
                    />
                </Col>
            </Row>
        </>;
    };


    const dashboardPropertyList = [
        {
            name: 'KPIs',
            id: '5638d3ba-f508-4029-8f7b-e21024711a10',
            accessible: config?.showKPIs,
        },
        {
            name: 'MoM Stats',
            id: '15e88991-83ad-42e5-8b54-c75f33f8c07b',
            accessible: config?.showMoM,
        },

    ];

    const analyticsData = dashboardPropertyList.map((property) => {
        return {
            key: property.id,
            name: property.name,
            accessible: property.accessible,
            component: () => <MetabaseDashboard id={property.id} />,
        };
    });

    useEffect(() => {
        if (render) {
            setRender(false);
        }
    }, [render]);

    return (
        <>
            <PageTopSection firstElement={false} menuList={breadcrumb} pageTitle="Analytics" />
            <MeraaiTabs
                tabs={analyticsData}
            />
        </>
    );
};
