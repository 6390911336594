import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CATEGORY_CRUD } from '../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { isArrayEmpty } from '../../../Utils';
import { CategoryDetails, CategoryResponse } from '../types';
import { updateCategories } from '../slice';

export const useCategoryList = (initialValue?: any) => {

    const [categoryResponse, setCategoryResponse] = useState<CategoryResponse>(initialValue);
    const [fetchCategoryList, setFetchCategoryList] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // redux
    const dispatch = useAppDispatch();
    const categoryListResponse = useAppSelector(state => state.category);

    const storeCategoryListIntoRedux = (categoryReponse: CategoryDetails[]) => {
        dispatch(updateCategories(categoryReponse));
    };

    /** Get category list {{API CALL}} */
    const getCategoryList = () => {
        setLoading(true);

        const responseHandler: ResponseHandler<CategoryResponse> = {
            onResponseSuccess(response: CategoryResponse): void {
                setCategoryResponse(response);
                storeCategoryListIntoRedux(response.data!);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        getApiCall(CATEGORY_CRUD, responseHandler);
    };


    useEffect(() => {
        if (fetchCategoryList) {
            getCategoryList();
        }
        setFetchCategoryList(false);
    }, [fetchCategoryList]);

    useEffect(() => {
        if (isArrayEmpty(categoryListResponse.data)) {
            getCategoryList();
        } else {
            setCategoryResponse(categoryListResponse);
        }
    }, []);

    return {
        categoryResponse,
        setCategoryRespone: setCategoryResponse,
        setFetchCategoryList,
        fetching: loading,
    } as const;
};