import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OUTLET_RETURN_CARTS } from '../../../../../../Constants';
import { differenceInDays } from '../../../../../../utilities/Date';
import { ReturnCart, ReturnProductData, ReturnCartStorageDTO } from './type';


export function getFilteredResult(storedObject: ReturnCartStorageDTO): ReturnCartStorageDTO {

    // Removing all the outdated return orders
    const keys = Object.keys(storedObject).filter(
        key => differenceInDays(Date.now(), storedObject[key].lastUpdatedAt) <= 1, // 1 hour
    );
    const finalFilteredObject: ReturnCartStorageDTO = {};
    keys.forEach(key => {
        finalFilteredObject[key] = storedObject[key];
    });
    localStorage.setItem(OUTLET_RETURN_CARTS, JSON.stringify(finalFilteredObject));
    return finalFilteredObject;

}

const initialState: { returnCart: ReturnCartStorageDTO; } = {
    returnCart: localStorage.getItem(OUTLET_RETURN_CARTS) !== null ? getFilteredResult(JSON.parse(localStorage.getItem(OUTLET_RETURN_CARTS)!)) : {},
};

const returnCartSlice = createSlice({
    name: 'returnCart',
    initialState,
    reducers: {
        upsertProductIntoCart(state, action: PayloadAction<ReturnProductData>) {
            const returnProductData = action.payload;

            const establishmentReturnOrder: ReturnCart = state.returnCart[returnProductData.establishmentUuid] ?? {
                selectedProducts: [],
                selectedItemsCount: 0,
                totalAmount: 0,
                lastUpdatedAt: 0,
            };

            const itemIndex = establishmentReturnOrder.selectedProducts.findIndex(product => product.id === returnProductData.productDetailsWithItems.id);

            if (itemIndex === -1) {
                establishmentReturnOrder.selectedProducts.push({
                    id: returnProductData.productDetailsWithItems.id,
                    name: returnProductData.productDetailsWithItems.name,
                    brand_name: returnProductData.productDetailsWithItems.brand_name,
                    returnQuantity: returnProductData.productDetailsWithItems.returnQuantity,
                    items: returnProductData.productDetailsWithItems.items?.map(item => {
                        return item;
                    }) ?? [],
                    productTotalAmount: returnProductData.productDetailsWithItems.productTotalAmount ?? 0,
                });
            } else {
                const existingItem = establishmentReturnOrder.selectedProducts[itemIndex];
                establishmentReturnOrder.selectedItemsCount -= existingItem?.returnQuantity ?? 0;

                establishmentReturnOrder.selectedProducts[itemIndex] = {
                    id: returnProductData.productDetailsWithItems.id,
                    name: returnProductData.productDetailsWithItems.name,
                    brand_name: returnProductData.productDetailsWithItems.brand_name,
                    returnQuantity: returnProductData.productDetailsWithItems.returnQuantity,
                    items: returnProductData.productDetailsWithItems.items?.map(item => {
                        return item;
                    }) ?? [],
                    productTotalAmount: returnProductData.productDetailsWithItems.productTotalAmount ?? 0,
                };
            }
            establishmentReturnOrder.totalAmount = establishmentReturnOrder.selectedProducts.reduce((totalAmount, product) => {
                return totalAmount += product.productTotalAmount ?? 0;
            }, 0);

            establishmentReturnOrder.selectedItemsCount += returnProductData?.productDetailsWithItems?.returnQuantity ?? 0;
            establishmentReturnOrder.lastUpdatedAt = Date.now();

            state.returnCart[returnProductData.establishmentUuid] = establishmentReturnOrder;

            localStorage.setItem(OUTLET_RETURN_CARTS, JSON.stringify(state.returnCart));
        },

        removeProductFromCart(state, action: PayloadAction<{ establishmentUuid: string, productId: number }>) {
            const returnProductData = action.payload;
            const establishmentReturnOrder = state.returnCart[returnProductData.establishmentUuid];
            const selectedProduct = establishmentReturnOrder?.selectedProducts?.find(product => product.id === returnProductData.productId);

            // Selected Items Count
            establishmentReturnOrder.selectedItemsCount -= selectedProduct?.returnQuantity!;

            // Last updated at
            establishmentReturnOrder.lastUpdatedAt = Date.now();

            // Calculating total
            establishmentReturnOrder.totalAmount -= selectedProduct?.productTotalAmount ?? 0;

            // Remove the reset one.
            const updatedSelectedProducts = establishmentReturnOrder.selectedProducts.filter(products => products.id !== returnProductData.productId);

            // Updating state and localstorage
            state.returnCart[returnProductData.establishmentUuid].selectedProducts = updatedSelectedProducts;
            localStorage.setItem(OUTLET_RETURN_CARTS, JSON.stringify(state.returnCart));
        },


        destroyCart(state, action: PayloadAction<string>) {
            const establishmentUuid = action.payload;
            delete state.returnCart[establishmentUuid];
            localStorage.setItem(OUTLET_RETURN_CARTS, JSON.stringify(state.returnCart));
        },
    },
});

export const { upsertProductIntoCart, removeProductFromCart, destroyCart } = returnCartSlice.actions;
export default returnCartSlice.reducer;