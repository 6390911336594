import React, { useEffect } from 'react';
import { Badge, Button, Col, Drawer, Row, Spin } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { ORDER_ITEM_INVENTORY, UPDATE_ORDER } from '../../../../../http/EndPoints';
import { getApiCall, putApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { Datum } from '../../../../../types/Datum';
import {
    OrderInventoryResponse,
    OrderItem,
    UpdateOrderRequest,
    UpdateOrderStateRequest,
    OrderDetailsResponse,
    ValidNextStateProperties,
} from '../../../../../types/Order';
import { StateChangeHandler } from '../../../../../types/StateChange';
import { OrderStates } from '../../../hubsAndOutlets/utils';
import { OrderStateChangeActionablesProps } from './types';

import { orderStateChangeHandlers } from './utils';
import { layout, style } from '../../../../../styles/css/style';
import { AiOutlineClose } from 'react-icons/ai';
import { documentConfigs, MetadataDocument } from '../../../../common/metaDataUploader/types';
import { MetadataUploader } from '../../../../common/metaDataUploader/index';
import { OrderItemAvailabilityDrawer } from '../orderItemReview';
import { ResponseDrawer } from '../../../../common/templates/responseDrawer';

export function OrderStateChangeActionables(props: OrderStateChangeActionablesProps) {
    // Props destructuring 
    const {
        orderUuid,
        orderDetails,
        setOrderDetails,
        config,
        updateStockInOrderItems,
        setIsFetching,
        newChildOrderModal,
    } = props;

    // State variables
    const [loading, setLoading] = useState<boolean>(false);
    const [stateChangeDocuments, setStateChangeDocuments] = useState<MetadataDocument[]>([]);
    const [selectedStateHandler, setSelectedStateHandler] = useState<StateChangeHandler>();
    const [shortagesPreviewDrawerVisibility, setShortagesPreviewDrawerVisibility] = useState<boolean>(false);
    const [orderItemAvailabilityDrawerVisibility, setOrderItemAvailabilityDrawerVisibility] = useState<boolean>(false);
    const [itemChangedCount, setItemChangedCount] = useState<number>(0);
    const [itemShortage, setItemShortage] = useState<boolean>(false);
    const [responseDrawerVisibility, setResponseDrawerVisibility] = useState<boolean>(false);

    /**
     * As soon as the `valid_next_state` value becomes `[]` an empty array the 
     * details page footer gets hidden according to current logic. 
     * component -- `<DetailsPage />`
     * */
    const [orderDetailsTemp, setOrderDetailsTemp] = useState<OrderDetailsResponse>();


    // Member Functions
    const getTitle = (): string => {
        if (orderDetails?.partially_deliverable && selectedStateHandler?.state === OrderStates.SCHEDULE_DELIVERY) {
            return 'Proceed to acknowledge that you’ve packed the items and create a partial delivery order';
        }
        return selectedStateHandler?.popUpModalMessage!;
    };

    const constructStyleClass = (filteredOrderStateChangeHandler): string => {
        let styleClass = 'xs:w-max ';
        if (filteredOrderStateChangeHandler.length === 1) {
            styleClass += 'w-full ';
        } else if (filteredOrderStateChangeHandler.length === 2) {
            styleClass += 'w-1/2';
        } else {
            styleClass += 'w-1/3';
        }
        return styleClass;
    };

    const updateAvailableQuantityInOrderItems = () => {
        orderDetails?.items.forEach((item: OrderItem) => item.availableQuantity = item.actual_quantity = item.deliverable_quantity);
        setOrderDetails(orderDetails);
    };

    const getInventoryItemsAndSetHandler = (handler: StateChangeHandler) => {

        setIsFetching(true);
        const responseHandler: ResponseHandler<OrderInventoryResponse> = {
            onResponseSuccess(response: OrderInventoryResponse): void {
                setIsFetching(false);
                updateStockInOrderItems(response);

                // Checking for available_stock in inventory.
                if (!response.available) {
                    handler = orderStateChangeHandlers.find(item => item.state == OrderStates.DELIVERY_CANCELLED)!;
                    handler = _.cloneDeep(handler);
                    handler.popUpModalMessage = 'None of the items are available in the inventory. Proceed to cancel the delivery.';
                }
                setSelectedStateHandler(handler);
            },
            onResponseFailed(): void {
                setIsFetching(false);
            },
            onResponseError(): void {
                setIsFetching(false);
            },
        };

        getApiCall(ORDER_ITEM_INVENTORY(orderUuid!), responseHandler);

    };

    const onStateChangeAction = (handler: StateChangeHandler) => {
        if (handler.state === OrderStates.SCHEDULE_DELIVERY) {
            getInventoryItemsAndSetHandler(handler);
        } else {
            setSelectedStateHandler(handler);
            updateAvailableQuantityInOrderItems();
        }

        setStateChangeDocuments(handler.mandatoryDocuments ? _.cloneDeep(handler.mandatoryDocuments) : []);
    };

    const resetSelectedStateHandler = () => {
        setSelectedStateHandler(undefined);
        setStateChangeDocuments([]);
        setItemChangedCount(0);
        setItemShortage(false);
    };

    const updateOrder = async () => {
        setLoading(true);

        const requestBody: UpdateOrderRequest = {
            state_request: {
                state: selectedStateHandler?.state,
                data: stateChangeDocuments
                    .filter(document => document.values)
                    .map(document => {
                        const documentConfig = documentConfigs.find(eachConfig => eachConfig.type === document.type);
                        let requestDocument = {
                            name: documentConfig?.datumName,
                            display_name: document.purpose,
                            type: documentConfig?.datumType,
                            function: 'DISPLAY',
                        };
                        if (document.multiple) {
                            requestDocument = { ...requestDocument, values: document.values?.map(item => item.value) } as Datum;
                        } else {
                            requestDocument = { ...requestDocument, value: document.values?.at(document.values.length - 1)?.value } as Datum;
                        }

                        /**
                         * This `if` block is added to differentiate between pre-defined and manually entered reasons.
                         * Let's say the user entered some manual reason, which will pass one more property called 'values' which takes ['custom_input'] as a value.
                         */
                        if (document.dropdownProperties?.isInputCustom) {
                            requestDocument = { ...requestDocument, values: ['custom_input'] } as Datum;
                        }

                        return requestDocument;
                    }),
                items: selectedStateHandler?.requireItemDuringStateChange ? orderDetails.items.filter(item => {
                    if (orderDetails.partially_deliverable) {
                        return item.availableQuantitySource === 'INVENTORY' && item.deliverable;
                    } else {
                        return item.deliverable;
                    }
                }).map(item => {
                    return {
                        item_id: item.id,
                        quantity: item.availableQuantity,
                    };
                }) : undefined,
            } as UpdateOrderStateRequest,
        };

        const endPointOrderAction = UPDATE_ORDER(orderUuid);
        const responseHandler: ResponseHandler<OrderDetailsResponse> = {
            onResponseSuccess(orderDetailsResponse: OrderDetailsResponse): void {
                setResponseDrawerVisibility(true);
                /**
                 * The temp state variable stores the orderDetailsResponse and updates it to 
                 * the original props.setOrderDetails state when the response modal is closed.
                 * */
                setOrderDetailsTemp(orderDetailsResponse);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        await putApiCall(endPointOrderAction, requestBody, responseHandler);
    };

    const generateActionablesForStateChange = (nextValidStates: ValidNextStateProperties[]) => {

        const filteredOrderStateChangeHandler = orderStateChangeHandlers.filter((handler: StateChangeHandler) => {
            return nextValidStates?.some(state => state.state === handler.state)
                && config.stateChangeConfig.allowedStates?.includes(OrderStates.valueOf(handler.state)!);
        });

        return filteredOrderStateChangeHandler.map((handler: StateChangeHandler, index: number) => {

            const requireItem = nextValidStates.find(validState => validState.state === handler.state)?.require_items_during_update;

            handler.requireItemDuringStateChange = requireItem;
            // Updating itemModalVisibiility to false if the require_items_during_update property in orderDetail response is false because we don't want to display the markItemShortageModal bydefault in those cases.
            if (!requireItem) {
                handler.itemsModalVisibileByDefault = false;
            }

            return <Col key={index} className={constructStyleClass(filteredOrderStateChangeHandler)}>
                <Button
                    className={`text-xs font-semibold xs:font-normal xs:text-base ${handler.styleClass} ${layout.flex.center}`}
                    onClick={() => {
                        onStateChangeAction(handler);
                    }}>
                    <label className={`cursor-pointer whitespace-pre-line ${layout.flex.center} `}>
                        {handler.display}
                    </label>
                </Button>
            </Col >;

        });
    };

    const onResponseDrawerClose = () => {
        setResponseDrawerVisibility(false);
        setOrderDetails(orderDetailsTemp!);
        const childOrder = orderDetailsTemp?.child_orders?.find(order => order.new_order);
        if (childOrder) {
            newChildOrderModal(childOrder);
        }
        resetSelectedStateHandler();
        setLoading(false);
    };

    useEffect(() => {
        if (selectedStateHandler?.itemsModalVisibileByDefault) {
            setOrderItemAvailabilityDrawerVisibility(true);
        }
    }, [selectedStateHandler]);

    useEffect(() => {
        if (selectedStateHandler?.itemShortageDocuments) {
            if (itemShortage) {
                setStateChangeDocuments(stateChangeDocuments.concat(selectedStateHandler?.itemShortageDocuments!));
            } else {
                const itemShortageDocIds = selectedStateHandler?.itemShortageDocuments?.map(document => document.id);
                setStateChangeDocuments(stateChangeDocuments.filter(document => !itemShortageDocIds?.includes(document.id)));
            }
        }

    }, [itemShortage]);

    return (
        <>
            {
                orderDetails && <Row gutter={[12, 12]} className={`${layout.flex.end} p-3`}>
                    {generateActionablesForStateChange(orderDetails?.valid_next_states)}
                </Row>
            }
            <Drawer
                className='meraai-drawer'
                title={getTitle()}
                open={selectedStateHandler !== undefined}
                closable={false}
                extra={<AiOutlineClose className='cursor-pointer' onClick={resetSelectedStateHandler} />}

                // Prevent Drawer Popup from unwanted closing.
                maskClosable={false}
                keyboard={false}
                destroyOnClose

                footer={
                    <>
                        <Row gutter={[24, 24]} className={`${layout.flex.end} `}>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w - full ${style.meraaiPlainButton} `}
                                    onClick={resetSelectedStateHandler}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton} `}
                                    // Button would be disabled if there are any documents without a value
                                    disabled={
                                        stateChangeDocuments
                                            ?.find(document => !document?.values || document.values?.length === 0) !== undefined || loading
                                    }
                                    onClick={updateOrder}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Spin spinning={loading}>
                    {
                        selectedStateHandler?.requireItemDuringStateChange &&

                        <Badge count={itemChangedCount}>
                            <Button
                                className={`mb-3 ${style.meraaiOrangeButton} `}
                                loading={loading}
                                onClick={() => {
                                    setOrderItemAvailabilityDrawerVisibility(true);
                                }}
                            >
                                {selectedStateHandler?.markItemAvailablityButtonText}
                            </Button>
                        </Badge>

                    }
                    <MetadataUploader documents={stateChangeDocuments} updateDocuments={setStateChangeDocuments} />
                </Spin>
            </Drawer>

            <OrderItemAvailabilityDrawer
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                selectedStateHandler={selectedStateHandler}
                orderItemAvailabilityDrawerVisibility={orderItemAvailabilityDrawerVisibility}
                setOrderItemAvailabilityDrawerVisibility={setOrderItemAvailabilityDrawerVisibility}
                shortagesPreviewDrawerVisibility={shortagesPreviewDrawerVisibility}
                setShortagesPreviewDrawerVisibility={setShortagesPreviewDrawerVisibility}
                setItemChangedCount={setItemChangedCount}
                setItemShortage={setItemShortage}
                resetSelectedStateHandler={resetSelectedStateHandler}
            />

            <ResponseDrawer
                type='SUCCESS'
                open={responseDrawerVisibility}
                onClose={onResponseDrawerClose}
                message={<p className='font-semibold'>{orderDetailsTemp?.message}</p>}
            />
        </>
    );
}