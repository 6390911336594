import { EstablishmentSaveMode } from '../../../types/EstablishmentSaveMode';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { PurchaseOrderState } from '../../../types/PurchaseOrderState';
import { orderStateChangeHandlers } from '../../orders/layer/utils/orderStateChangeActionables/utils';
import { Path } from '../../router/Path';
import { PathConfig } from '../PathConfigService';
import {
    EstablishmentDetailsConfig,
    EstablishmentsConfig,
    FinanceLayerConfig,
    InventoryConfig,
    OrderDetailsConfig,
    OrderListConfig,
    PurchaseOrderDetailsConfig,
    UserListingConfig,
} from '../types/ConfigTypes';

const HubOperatorConfigs: PathConfig[] = [
    {
        path: Path.HOME,
        accessible: true,
    },
    {
        path: Path.LOGOUT,
        accessible: true,
    },
    {
        path: Path.ORDER_LIST,
        accessible: true,
        config: {
            hubOrders: {
                show: true,
                showOrders: true,
                showPurchaseOrders: true,
            },
            outletOrders: {
                show: false,
            },
            sellerHubOrders: {
                show: false,
                showOrders: false,
                showPurchaseOrders: false,
            },
        } as OrderListConfig,
    },
    {
        path: Path.HUB_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.OUTLET_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.SELLER_HUB_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.ORDER_DETAILS,
        accessible: true,
        config: {
            stateChangeConfig: {
                allowedStates: orderStateChangeHandlers.map(handler => handler.state),
            },
            allowToViewSellerOrders: true,
            allowEditOrderDetails: true,
        } as OrderDetailsConfig,
    },
    {
        path: Path.ORDER_EDIT,
        accessible: true,
    },
    // Purchase Orders
    {
        path: Path.PURCHASE_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.HUB_PURCHASE_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.SELLER_HUB_PURCHASE_ORDER_LIST,
        accessible: false,
    },
    {
        path: Path.PURCHASE_ORDER_DETAILS,
        accessible: true,
        config: {
            show: true,
            stateChangeConfig: {
                allowedStates: [PurchaseOrderState.HUB_RECEIVED],
            },
        } as PurchaseOrderDetailsConfig,
    },
    {
        path: Path.ESTABLISHMENTS,
        accessible: true,
        config: {
            shouldShowHubs: true,
            shouldShowOutlets: true,
            shouldShowSellerHubs: false,
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.HUB_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.OUTLET_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.SELLER_HUB_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: false,
        } as EstablishmentsConfig,
    },
    {
        path: Path.ESTABLISHMENT_DETAILS,
        accessible: true,
        config: {
            shouldShowInfo: true,
            products: {
                sellerHub: {
                    show: true,
                    allowProductCreation: false,
                    allowProductListing: true,
                },
                hub: {
                    show: false,
                    allowProductListing: false,
                },
            },
            users: {
                show: true,
                allowCreation: false,
            },
            showReceivables: true,
            infoConfig: {
                showConfigLayout: true,
                showBrandsDiscountList: false,
                allowBrandDiscountsUpdate: false,
                saveMode: EstablishmentSaveMode.SHOW,
            },
            ordersConfig: {
                listOrders: {
                    show: true,
                    getOrdersOnLaunch: true,
                },
                allowOrderCreation: true,
            },
            allowPurchaseOrderCreation: true,
            allowStatusChange: false,
        } as EstablishmentDetailsConfig,
    },
    {
        path: Path.FINANCE,
        accessible: true,
        config: {
            collections: {
                show: true,
                restrictDateSelection: true,
            },
            receivables: {
                show: true,
            },
            deposits: {
                show: true,
            },
            exportOrders: false,
        } as FinanceLayerConfig,
    },
    {
        path: Path.INVENTORY,
        accessible: true,
        config: {
            show: true,
            products: {
                showListing: true,
                allowUpdate: true,
            },
        } as InventoryConfig,
    },
    {
        path: Path.USER_LISTING,
        accessible: false,
        config: {
            primaryEstablishmentType: EstablishmentType.HUB,
            associatedEstablishmentTypes: [EstablishmentType.HUB],

        } as UserListingConfig,
    },
    {
        path: Path.USER_PROFILE,
        accessible: true,
    },
    {
        path: Path.RETURNS,
        accessible: true,
    },
    {
        path: Path.RETURN_DETAILS,
        accessible: true,
    },
];

export default HubOperatorConfigs;
