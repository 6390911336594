/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */
/* eslint @typescript-eslint/naming-convention: 0 */
/* eslint @typescript-eslint/no-use-before-define: 0 */

import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowDownOutlined, RightOutlined } from '@ant-design/icons';
import { Table, Col, Row, Tag, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { isArrayEmpty, isObjectEmpty } from '../../Utils';
import { APPLICANT_LIST } from '../../http/EndPoints';
import { ApplicantOnboardingDetails, ApplicantListResponse } from '../../types/Applicants';
import { getApiCall, ResponseHandler } from '../../http/HttpClient';

const { Option } = Select;

const statusColors = {
    ONBOARDED: 'cyan',
    APPLIED: 'green',
    REJECTED: 'red',
};

export interface ApplicantTableContent {
    key: string,
    name: string,
    owner: string,
    address: string,
    mobile: string,
    status: string,
    data: ApplicantOnboardingDetails,
}

export enum ApplicantStatus {
    ONBOARDED = 'ONBOARDED',
    APPLIED = 'APPLIED',
    REJECTED = 'REJECTED',
}

const ApplicantList = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const url_status = queryParams.get('status');

    let intPageNo = page ? parseInt(page) : 0;
    let applicantStatus: ApplicantStatus = url_status as ApplicantStatus;
    if (!(intPageNo && applicantStatus)) {
        intPageNo = 1;
        applicantStatus = ApplicantStatus.APPLIED;
    }

    const [status, setStatus] = useState<ApplicantStatus>(applicantStatus as ApplicantStatus);
    const [isFetching, setFetching] = useState<boolean>(false);
    const [tablePageRowSize, settablePageRowSize] = useState<number>(10);
    const [tablePageNumber, setTablePageNumber] = useState<number>(intPageNo);
    const [pageCount, setPageCount] = useState<number>(0);
    const [users, setUsers] = useState<ApplicantOnboardingDetails[]>();

    let tableContent: ApplicantTableContent[] = [];

    function clearTableContent() {
        tableContent = [];
    }

    const tableColumns = [
        {
            title: 'Store Name',
            dataIndex: 'name',
            sorter: true,
            key: 'store_name',
            width: '15%',
            render: (text, record) => (
                <a
                    style={{ color: 'rgba(169, 80, 19, 0.8)', textDecoration: 'underline' }}
                    onClick={() => {
                        navigate('/applicants/detail', {
                            state: {
                                singleUserData: record.data,
                            },
                        });
                    }}>
                    {text}
                </a>
            ),
        },
        {
            title: 'Store Owner',
            dataIndex: 'owner',
            key: 'store_owner',
            width: '20%',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '20%',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            width: '15%',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '15%',
            render: (status, record) => (
                <div className="body-div-tag">
                    <Tag color={statusColors[status]} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                    <RightOutlined
                        onClick={() => {
                            navigate('/applicants/detail', {
                                state: {
                                    singleUserData: record.data,
                                },
                            });
                        }}
                        className="arrow-logo"
                    />
                </div>
            ),
        },
    ];



    useEffect(() => {
        fetchData();
    }, [page, url_status]);

    const fetchData = async () => {
        const endPoint = APPLICANT_LIST(status, intPageNo - 1, tablePageRowSize);

        clearTableContent();
        setTablePageNumber(intPageNo);
        setFetching(true);

        const responseHandler: ResponseHandler<ApplicantListResponse> = {
            onResponseSuccess(value: ApplicantListResponse): void {
                clearTableContent();
                setUsers(value.onboardings ?? []);
                setPageCount(value.page_count);
                setFetching(false);
            },
            onResponseFailed(errors: String[]): void {
                setFetching(false);
            },
            onResponseError(): void {
                setFetching(false);
            },
        };
        getApiCall(endPoint, responseHandler);
    };

    if (!isObjectEmpty(users)) {
        users?.forEach((item) => {
            tableContent.push({
                key: item.uuid,
                name: item.metadata.establishment_name,
                owner: `${item.metadata.agent_first_name} ${item.metadata.agent_last_name}`,
                address: item.metadata.address,
                mobile: item.metadata.mobile_number,
                status: item.status,
                data: item,
            });
        });
    }

    return (
        <>
            <div className="main-title">
                <Row gutter={[24, 24]}>
                    <Col lg={12}>
                        <Title level={3}>Applicants</Title>
                    </Col>
                    <Col style={{ textAlign: 'end' }} lg={8}>
                        <Select
                            suffixIcon={<ArrowDownOutlined />}
                            defaultValue={applicantStatus}
                            className="select_status"
                            onChange={(value: ApplicantStatus) => {
                                // When you change the status from the select dropdown it's value will get updated and page will start from 0
                                setTablePageNumber(1);
                                setStatus(value);

                                // It is used to set the url, so that while changing the status value it goes to the default page value of 1.
                                navigate(`/applicants?status=${value}&page=1`);
                            }}>
                            <Option value={ApplicantStatus.ONBOARDED}>ONBOARDED</Option>
                            <Option value={ApplicantStatus.APPLIED}>APPLIED</Option>
                            <Option value={ApplicantStatus.REJECTED}>REJECTED</Option>
                        </Select>
                    </Col>
                </Row>
            </div>
            <div className="main-body" style={{ padding: 24 }}>
                <Table
                    columns={tableColumns}
                    rowKey={record => record.key}
                    dataSource={!isArrayEmpty(tableContent) ? tableContent : []}
                    loading={isFetching}
                    scroll={{ x: 350 }}
                    pagination={{
                        pageSize: tablePageRowSize,
                        current: tablePageNumber,
                        total: pageCount * tablePageRowSize,

                        onChange: (page, pageSize) => {
                            setTablePageNumber(page);
                            settablePageRowSize(pageSize);
                            navigate(`/applicants?status=${applicantStatus}&page=${page}`);
                        },
                    }}
                    onRow={(record) => {
                        return {
                            onDoubleClick: event => {
                                navigate('/applicants/detail', {
                                    state: {
                                        singleUserData: record.data,
                                    },
                                });
                            },
                        };
                    }}
                />
            </div>
        </>
    );
};

export default ApplicantList;
