/* eslint @typescript-eslint/no-unused-vars: 0 */

import * as React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { UserEstablishmentLayoutProps } from './types';
import { UserEstablishmentProperties } from './utils';
import { DetailsCardProps } from '../../../../common/detailsCard/types';
import ImageRenderer, { ImageFile, imageValidationRule, UploaderTypes } from '../../../../common/ImageRenderer';
import CloseableImage from '../../../../common/CloseableImage';
import { DetailsCard } from '../../../../common/detailsCard';

function UserEstablishmentLayout(props: UserEstablishmentLayoutProps) {
    const { userEstablishmentProperties: userEstProp, setUserEstablishmentProperties: setUserEstProp, form } = props;

    const changeValue = (property: keyof UserEstablishmentProperties, value: any) => {
        const updated = {
            ...userEstProp,
            [property]: { ...userEstProp[property], value, changed: true },
        };
        setUserEstProp(updated);
    };


    // Variables
    const userEstablishmentDetails: DetailsCardProps =
    {
        data: [{
            show: userEstProp.gstin?.show,
            label: 'GSTIN',
            value: <Form.Item
                name="gstin"
                rules={[
                    {
                        required: userEstProp.gstin?.required,
                        message: 'Please enter the gstin number',
                    },
                    {
                        pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: 'GSTIN is not valid',
                    },
                ]}>
                <Input
                    type="text"
                    disabled={userEstProp.gstin?.disabled}
                    placeholder="GSTIN Number"
                    value={userEstProp.gstin?.value}
                    onChange={event => changeValue('gstin', event.currentTarget.value)}
                />
            </Form.Item>,
        },
        {
            show: userEstProp.aadhaar?.show,
            label: 'Aadhaar',
            value: <Form.Item
                name="aadhaar"
                rules={[
                    {
                        required: userEstProp.aadhaar?.required,
                        message: 'Please enter the aadhaar number',
                    },
                    {
                        pattern: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
                        message: 'Aadhaar number is not valid',
                    },
                ]}>
                <InputNumber
                    style={{ width: '100%' }}
                    disabled={userEstProp.aadhaar?.disabled}
                    controls={false}
                    placeholder="Aadhaar Number"
                    maxLength={12}
                    value={userEstProp.aadhaar?.value}
                    onChange={value => {
                        changeValue('aadhaar', value);
                    }}
                />
            </Form.Item>,
        },
        {
            show: userEstProp.aadhaar?.show,
            label: <>
                {userEstProp.aadhaarFrontImage?.value && (
                    <CloseableImage
                        styleClass="padding-top-10px"
                        src={userEstProp.aadhaarFrontImage.value?.url}
                        onClose={() => changeValue('aadhaarFrontImage', undefined)}
                    />
                )}
            </>,
            value: <Form.Item
                name="aadhaarFrontImage"
                rules={[
                    () =>
                        imageValidationRule(userEstProp.aadhaar?.required!, userEstProp.aadhaarFrontImage?.value!),
                ]}>
                <ImageRenderer
                    type={UploaderTypes.UPLOAD_DRAG_AND_DROP}
                    draggerText="Aadhaar Front Image"
                    disabled={userEstProp.aadhaarFrontImage?.disabled}
                    setFileState={(file: ImageFile) => changeValue('aadhaarFrontImage', file)}
                />
            </Form.Item>,
        },
        {
            show: userEstProp.aadhaar?.show,
            label: <>
                {userEstProp.aadhaarBackImage?.value && (
                    <CloseableImage
                        styleClass="padding-top-10px"
                        src={userEstProp.aadhaarBackImage.value?.url}
                        onClose={() => changeValue('aadhaarBackImage', undefined)}
                    />
                )}
            </>,
            value: <Form.Item
                name="aadhaarBackImage"
                rules={[
                    () => imageValidationRule(userEstProp.aadhaar?.required!, userEstProp.aadhaarBackImage?.value!),
                ]}>
                <ImageRenderer
                    type={UploaderTypes.UPLOAD_DRAG_AND_DROP}
                    draggerText="Aadhaar Back Image"
                    disabled={userEstProp.aadhaarBackImage?.disabled}
                    setFileState={(file: ImageFile) => changeValue('aadhaarBackImage', file)}
                />
            </Form.Item>,
        },
        {
            show: userEstProp.pan?.show,
            label: 'Pan Number',
            value: <Form.Item
                name="pan"
                rules={[
                    {
                        required: userEstProp.pan?.required,
                        message: 'Pan number is required',
                    },
                    {
                        pattern: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                        message: 'Pan number is not valid',
                    },
                ]}>
                <Input
                    type="text"
                    placeholder="Pan Number"
                    style={{ width: '100%' }}
                    value={userEstProp.pan?.value}
                    disabled={userEstProp.pan?.disabled}
                    onChange={event => changeValue('pan', event.currentTarget.value)}
                />
            </Form.Item>,
        },
        {
            show: userEstProp.pan?.show,
            label: <>
                {userEstProp.panImage?.value && (
                    <CloseableImage
                        styleClass="padding-top-10px"
                        src={userEstProp.panImage.value?.url}
                        onClose={() => changeValue('panImage', undefined)}
                    />
                )}
            </>,
            value: <Form.Item
                name="panImage"
                rules={[() => imageValidationRule(userEstProp.pan?.required!, userEstProp.panImage?.value!)]}>
                <ImageRenderer
                    type={UploaderTypes.UPLOAD_DRAG_AND_DROP}
                    draggerText="Pan card Image"
                    disabled={userEstProp.panImage?.disabled}
                    setFileState={(file: ImageFile) => changeValue('panImage', file)}
                />
            </Form.Item>,
        }],
    };


    // useEffects
    React.useEffect(() => {
        form.setFieldsValue({
            gstin: userEstProp.gstin?.value,
            aadhaar: userEstProp.aadhaar?.value,
            aadhaarFrontImage: userEstProp.aadhaarFrontImage?.value,
            aadhaarBackImage: userEstProp.aadhaarBackImage?.value,
            pan: userEstProp.pan?.value,
            panImage: userEstProp.panImage?.value,
        });
    }, [userEstProp]);

    return (
        <>
            <DetailsCard
                removePadding
                labelStyleClass='xs:w-full lg:w-1/3'
                valueStyleClass='xs:w-full lg:w-2/3'
                data={
                    userEstablishmentDetails?.data?.filter((datum) => datum?.show !== false)?.map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey font-semibold'>{cardDatum.label}</label>,

                            value: cardDatum.value,
                        };
                    })
                }
            />
        </>
    );
}

export default UserEstablishmentLayout;
