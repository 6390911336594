import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuProps } from 'antd';
import { useAppSelector } from '../../../../hooks';
import { AppSideMenuProps, MenuItemDetails, MenuItemDetailsType } from './types';
import { getIcon, getMenuItemTitle, getSelectedKey } from './utils';
import { PathConfigData } from '../../../config/PathConfigService';

export default function AppSideMenu(props: AppSideMenuProps) {
    const location = useLocation();
    const [selectedTabs, setSelectedTabs] = React.useState<string[]>(location.pathname.split('/') ?? []);

    React.useEffect(() => {
        const split = location.pathname.split('/');
        if (split.length > 1) {
            setSelectedTabs(split);
        }
    }, [location]);

    const currentRole = useAppSelector(state => state.login.role);
    const pageConfigs = new PathConfigData(currentRole);
    const MenuItemsList: MenuProps['items'] = MenuItemDetails.filter(eachItem => eachItem.placement === props.itemPlacement).map(eachItem => {
        if (!pageConfigs.isPathAccessible(eachItem.path!)) {
            return null;
        }

        const finalChildren = eachItem.children?.filter(child => pageConfigs.isPathAccessible(child.path!));

        const menuItem = {
            key: eachItem.key,
            icon: getIcon(eachItem.icon),
            label: getMenuItemTitle(finalChildren?.length! > 0, eachItem.label, eachItem.path, finalChildren?.length!),
        } as MenuItemDetailsType;

        if (finalChildren?.length! > 1) {
            menuItem.children = finalChildren?.map(item => {
                return {
                    key: item.key,
                    icon: getIcon(item.icon),
                    label: <Link className="m-0" to={item?.path!}>
                        {item.label}
                    </Link>,
                };
            }).filter(child => child);
        }

        return menuItem;

    }).filter(item => item);

    return (
        <Menu
            className='side-menu-ul'
            mode="inline"
            defaultSelectedKeys={getSelectedKey(selectedTabs)}
            defaultOpenKeys={[selectedTabs.at(1)!]}
            onClick={props.toggleSider}
            items={MenuItemsList}
        />
    );
}
