import { Badge, Button, Col, Row, Select, Tag, Tooltip } from 'antd';
import { Option } from 'rc-select';
import React, { useEffect, useRef, useState } from 'react';
import { BsDash } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../../hooks';
import { AGGREGATE_ORDERS, ORDER_LIST } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { layout, style } from '../../../../../styles/css/style';
import { EstablishmentType } from '../../../../../types/EstablishmentType';
import { OrderDetailsResponse } from '../../../../../types/Order';
import { addKeyToDataList, formatCurrency, isArrayEmpty } from '../../../../../Utils';
import { getISTFromEpoch } from '../../../../../utilities/Date';
import { EstablishmentsDropDown, EstablishmentSelectionMode, getEncodedStringFromUuidList, getUuidListFromEncodedString } from '../../../../common/establishments/EstablishmentsDropDown';
import { PageBreadcrumbHeader } from '../../../../common/pageBreadcrumbHeader';
import { ListingPage } from '../../../../common/templates/listingPage';
import { DataNotFound } from '../../../../common/templates/dataNotFound';
import { MeraaiTable } from '../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import TimeFilterPicker from '../../../../common/TimeFilterPicker';
import { AggregateDataResponse, OrderListDataResponse, OrderState } from '../../../hubsAndOutlets/types';
import { allOrderStates, defaultOrderState, establishmentProperties, OrderStates, orderStatesMap } from '../../../hubsAndOutlets/utils';
import { OrderListingProps, listingType, orderStatus } from './types';

export const OrderListing = (props: OrderListingProps) => {
    const isMobileDevice = useAppSelector((state) => state.sideMenuCollapsed.collapsed);
    const navigate = useNavigate();

    const [orderListingDetails, setOrderListingDetails] = useState<OrderListDataResponse>();
    const [aggregateOrderDetails, setAggregateOrderDetails] = useState<AggregateDataResponse>();

    const isRendered = useRef<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    const [establishmentUuids, setEstablishmentUuids] = useState<string[] | undefined>(
        props?.selectedEstablishmentUuid ? [props.selectedEstablishmentUuid] : undefined,
    );
    const [selectedOrderState, setSelectedOrderState] = useState<OrderState>();

    const [fromDate, setFromDate] = useState<number>();
    const [toDate, setToDate] = useState<number>();

    const [orderListingDetailsRequested, setOrderListingDetailsRequested] = useState<boolean>(false);
    const [fetchedAggregate, setFetchedAggregate] = useState<boolean>(false);

    // Messages 
    const [bodyResultMsg, setBodyResultMsg] = useState('Search for the data using filters mentioned above.');


    const availableOrderStates = allOrderStates;

    const isOrderListingAllowed = (): boolean => {
        return (
            establishmentUuids != undefined && establishmentUuids.length > 0 &&
            selectedOrderState != undefined &&
            fromDate != undefined &&
            toDate != undefined
        );
    };

    const setStateFromQueryParams = (queryParams: URLSearchParams, param: string, stateSetter: React.Dispatch<React.SetStateAction<any>>) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (!isArrayEmpty(uuidList)) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const pageNo = parseInt(queryParams.get(param)!);
            stateSetter(pageNo);
        } else if (param == 'state') {
            stateSetter(orderStatesMap.get(OrderStates.valueOf(queryParams.get(param)!)!));
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams: URLSearchParams, param: string, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                } else {
                    queryParams.delete(param);
                }
            } else {
                queryParams.set(param, state);
            }
        }
    };

    const setStateFromURLParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'state', setSelectedOrderState);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setStateFromQueryParams(queryParams, 'size', setPageSize);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'state', selectedOrderState?.state);
        setQueryParamsFromState(queryParams, 'page', page);
        setQueryParamsFromState(queryParams, 'size', pageSize);
        /**
          * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
          * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
          * So we are replacing the page with the help of replace property of navigate method.
        * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const onOrderStateChange = (state: string) => {
        setSelectedOrderState(orderStatesMap.get(OrderStates.valueOf(state)!));
    };

    //API CALL - Aggregate amount and no. of orders
    const getAggregate = async () => {
        setLoading(true);
        // Creating an endpoint for OrderList and aggregate data
        const endPointAggregate = AGGREGATE_ORDERS(
            establishmentUuids!!,
            selectedOrderState?.value!!,
            fromDate!!,
            toDate!!,
            listingType,
        );

        const responseHandler: ResponseHandler<AggregateDataResponse> = {
            onResponseSuccess(value: AggregateDataResponse): void {
                if (value.orders || value.amount) {
                    setAggregateOrderDetails(value);
                    setFetchedAggregate(true);
                } else {
                    setAggregateOrderDetails(value);
                    setFetchedAggregate(false);
                    setBodyResultMsg('No orders were created in the specified time period.');
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(endPointAggregate, responseHandler);
    };

    // API CALL - Order details
    const getOrders = async () => {
        setLoading(true);
        const endPointOrderList = ORDER_LIST(
            establishmentUuids!!,
            orderStatus,
            selectedOrderState?.value!!,
            fromDate!!,
            toDate!!,
            listingType,
            page!! - 1,
            pageSize,
        );
        const responseHandler: ResponseHandler<OrderListDataResponse> = {
            onResponseSuccess(value: OrderListDataResponse): void {
                if (value.orders && value.orders.length > 0) {
                    setOrderListingDetails(value);
                    setLoading(true);
                } else {
                    setOrderListingDetails(undefined);
                    setFetchedAggregate(false);
                    setBodyResultMsg('No orders were created in the specified time period.');
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
                setFetchedAggregate(false);
            },
            onResponseError(): void {
                setLoading(false);
                setFetchedAggregate(false);
                setBodyResultMsg('Something went wrong!');
            },
        };

        await getApiCall(endPointOrderList, responseHandler);
    };

    const getOrdersAndSetURLParams = () => {
        getOrders();
        setUrlParamsFromState();
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Invoice no.',
            dataIndex: 'invoice_no',
            render: (invoiceNo: string) => invoiceNo ? <label className='font-semibold text-meraai-black'>{invoiceNo}</label> : <label className='text-meraai-lightgrey'>Not generated yet</label>,
        },
        {
            title: 'Order no.',
            dataIndex: 'receipt_no',
        },
        {
            title: establishmentProperties.find(property => property.type === props.type)?.title,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: OrderDetailsResponse) => record[establishmentProperties.find(property => property.type === props.type)?.propertyName!].name,
        },
        {
            title: 'User Name',
            render: (_, record: OrderDetailsResponse) => `${record.agent.firstname} ${record.agent.lastname}`,
        },
        {
            title: 'Payment',
            dataIndex: 'payment_type',
            render: (paymentType) => paymentType || <BsDash />,
        },
        {
            title: 'Amount',
            dataIndex: 'final_amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (amount) => <label className='font-semibold'>{formatCurrency(amount)}</label>,
        },
        {
            title: 'Billed On',
            dataIndex: 'confirmed_at',
            rowVisibility: [Device.DESKTOP],
            render: (confirmed_at) => getISTFromEpoch(confirmed_at),
        },
        {
            title: 'State Updated On',
            dataIndex: 'state_updated_at',
            rowVisibility: [Device.DESKTOP],
            render: (state_updated_at) => getISTFromEpoch(state_updated_at),
        },
        {
            title: 'State',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'state',
            render: (state: string, record: OrderDetailsResponse) => {

                const StateTag = () => <Tag
                    className={`w-full py-2 ${layout.flex.spaceEvenly}`}
                    color={
                        orderStatesMap.has(OrderStates.valueOf(state)!) ?
                            orderStatesMap.get(OrderStates.valueOf(state)!)!.color
                            :
                            defaultOrderState.color
                    }
                >
                    <label className={`cursor-pointer whitespace-pre-line ${layout.flex.center} `}>
                        {orderStatesMap.has(OrderStates.valueOf(state)!) ?
                            orderStatesMap.get(OrderStates.valueOf(state)!)!.display
                            :
                            state}
                    </label>
                </Tag>;

                return (

                    <Col className='w-full'>
                        {
                            record.partially_deliverable ?

                                <Badge.Ribbon
                                    color='meraai-brown'
                                    text={
                                        <Tooltip title='This order is partially deliverable'>
                                            <label className='cursor-help text-sm'>PD</label>
                                        </Tooltip>
                                    }
                                >
                                    <StateTag />
                                </Badge.Ribbon>

                                :

                                <StateTag />
                        }
                    </Col >
                );
            },
        },
        {
            title: isMobileDevice ? '' : 'Action',
            dataIndex: 'uuid',
            mobileWidth: 'FULL',
            rowVisibility: [Device.DESKTOP],
            render: (uuid) => <Button
                className={`w-full ${style.meraaiOrangeButton}`}
                onClick={() => {
                    navigate(`/order/${uuid}`);
                }}
            >
                {isMobileDevice ? 'View Order Details' : 'View'}
            </Button>,
        },

    ];

    useEffect(() => {
        // If the establishmentUuid is set, it signifies that something is present in the state.
        if (!isArrayEmpty(establishmentUuids)) {
            setUrlParamsFromState();
        }
    }, [establishmentUuids]);

    // This runs whenever a page change happens.
    useEffect(() => {
        if (isRendered.current) {
            if (!aggregateOrderDetails) {
                getAggregate();
            }
            getOrdersAndSetURLParams();
        } else {
            isRendered.current = true;
        }
    }, [page]);

    // This useEffect gets called when the GetOrders button is clicked
    useEffect(() => {
        if (orderListingDetailsRequested) {
            if (page == 1) {
                getAggregate();
                getOrdersAndSetURLParams();
            } else {
                setPage(1);
            }
            setOrderListingDetailsRequested(false);
        }
    }, [orderListingDetailsRequested]);

    useEffect(() => {
        setStateFromURLParams();
    }, []);

    return (
        <>
            <ListingPage
                breadcrumb={
                    <>
                        <PageBreadcrumbHeader items={[
                            'Orders',
                            EstablishmentType.valueOf(props.type)?.display,
                        ]} />
                    </>
                }
                header={
                    <>
                        <Row>
                            <Col className={'p-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/6'}>
                                <EstablishmentsDropDown
                                    disabled={props.selectedEstablishmentUuid !== undefined}
                                    setLoading={setLoading}
                                    type={EstablishmentType.valueOf(props.type)!}
                                    setSelectedUuids={setEstablishmentUuids}
                                    selectedUuids={establishmentUuids}
                                    selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                                    fetchEstablishmentList={true}
                                />
                            </Col>

                            <Col className={'p-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/6'}>
                                <Select
                                    value={selectedOrderState ? selectedOrderState.state : undefined}
                                    placeholder="Select state"
                                    className="filter-drop-down"
                                    onChange={onOrderStateChange}>
                                    {/* States are included in the options only if includeInFilter is true. Example, for the state SELLER_PARTIALLY_ACCEPTED,
                                            includeInFilter is false because it is covered in the API response of Accepted By Seller(s) */}
                                    {availableOrderStates
                                        .filter(state => state.includeInFilter)
                                        .map(state => (
                                            <Option value={state.state} key={state.state}>
                                                {state.display}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>

                            <Col className={'p-2 w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/4'}>
                                <TimeFilterPicker
                                    enableFilterPicker
                                    fromDateEpoch={fromDate!}
                                    toDateEpoch={toDate!}
                                    setFromDateEpoch={setFromDate}
                                    setToDateEpoch={setToDate}
                                />
                            </Col>

                            <Col className={'w-full p-2 sm:w-1/2 lg:w-2/5 xl:w-1/6'}>
                                <Button
                                    className={`p-0 w-full ${style.meraaiOrangeButton} ${layout.flex.center}`}
                                    size="large"
                                    onClick={() => {
                                        setOrderListingDetailsRequested(true);
                                    }}
                                    disabled={!isOrderListingAllowed()}>
                                    Get Orders
                                </Button>
                            </Col>
                            {
                                aggregateOrderDetails &&

                                <Col className={'p-2 w-full sm:w-1/2 lg:w-2/5 xl:w-1/4 flex flex-row'}>
                                    <Col className={`w-1/2 ${layout.flexCol.center}`}>
                                        <h3 className='text-xl m-0 text-meraai-orange'>{formatCurrency(aggregateOrderDetails?.amount ?? 0)}</h3>
                                        <label className='text-sm font-bold'>Total Amount</label>
                                    </Col>

                                    <Col className={`w-1/2 ${layout.flexCol.center}`}>
                                        <h3 className='text-xl m-0 text-meraai-orange'>{aggregateOrderDetails?.orders ?? 0}</h3>
                                        <label className='text-sm font-bold'>No. of Orders</label>
                                    </Col>
                                </Col>
                            }
                        </Row>
                    </>
                }
                body={
                    <>
                        {
                            fetchedAggregate ?

                                <MeraaiTable
                                    loading={loading}
                                    dataSource={addKeyToDataList(orderListingDetails?.orders!)}
                                    columns={columns}
                                    pagination={{
                                        pageSize,
                                        current: page,
                                        total: orderListingDetails?.page_count! * pageSize,
                                        onChange: (pageNo, size) => {
                                            setLoading(true);
                                            setPage(pageNo);
                                            setPageSize(size);
                                            if (page === pageNo) {
                                                setOrderListingDetailsRequested(true);
                                            }
                                        },
                                    }}
                                />

                                :

                                <DataNotFound loading={loading} title={bodyResultMsg} />
                        }
                    </>
                }
            />
        </>
    );
};