import React from 'react';
import { InputNumber, Popconfirm } from 'antd';
import { useState, useEffect } from 'react';
import { GET_PURCHASED_BRAND_LIST, GET_PURCHASE_LIST } from '../../../../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../../../../http/HttpClient';
import { PurchasedBrandResponse, PurchasedProductListProps, PurchasedProductResponse, PurchaseProductDetails } from '../../types';
import { Device, MeraaiTableColumnType } from '../../../../../../../common/templates/meraaiTable/types';
import { ProductNameInfo, Quantity } from '../../../../../../../orders/layer/details/hubsAndOutlets/utils';
import { getISTFromEpoch } from '../../../../../../../../utilities/Date';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';
import { SelectedProductWithItems } from '../../../slice/type';
import { isArrayEmpty } from '../../../../../../../../Utils';
import { IoIosArrowDown } from 'react-icons/io';
import { OptionalTitle } from '../../../../../../../common/TableTitle';
import { returnQuantityEnterPress } from '../../../Util';
import { BiReset } from 'react-icons/bi';
import lodash from 'lodash';
import { removeProductFromCart } from '../../../slice';

export const usePurchasedProductList = (props: PurchasedProductListProps) => {
    const returns = lodash.cloneDeep(useAppSelector(state => state.returnOrder));
    const dispatch = useAppDispatch();

    // State variables
    const [loading, setLoading] = useState<boolean>(false);
    const [purchasedProductResponse, setPurchasedProductResponse] = useState<PurchasedProductResponse>();
    const [selectedPurchaseProduct, setSelectedPurchaseProduct] = useState<PurchaseProductDetails>();
    const [purchaseProductMap] = useState<Map<number, SelectedProductWithItems>>(new Map());

    // pagiantion
    const [pageNo, setPageNo] = useState<number>(0);
    const [size, setSize] = useState<number>(10);

    // Brand list
    const [purchasedBrandResponse, setPurchasedBrandResponse] = useState<PurchasedBrandResponse>();

    const [searhProductName, setSearchProductName] = useState<string>();
    const [brandId, setBrandId] = useState<number>();

    const [fetchPurchasedProductList, setFetchPurchasedProductList] = useState<boolean>(true);

    // Initiate return order 
    const [initiateReturnDrawerVisibility, setInitiateReturnDrawerVisibility] = useState<boolean>(false);


    // Methods 
    const setInitialValue = (response: PurchasedProductResponse): PurchasedProductResponse => {
        const establishmentReturnProducts = returns.returnCart[props.establishmentUuid]?.selectedProducts;
        if (isArrayEmpty(establishmentReturnProducts)) {
            response?.purchases.forEach((datum) => {
                datum.returnQuantity = 0;
            });
        } else {
            purchaseProductMap.clear();
            establishmentReturnProducts.forEach(product => purchaseProductMap.set(product.id, product));

            const updatedProductList = response?.purchases.map((purchaseProduct) => {
                if (purchaseProductMap.has(purchaseProduct.product_id)) {
                    purchaseProduct.returnQuantity = purchaseProductMap.get(purchaseProduct.product_id)?.returnQuantity ?? 0;
                } else {
                    purchaseProduct.returnQuantity = 0;
                }
                return purchaseProduct;
            });

            response.purchases = updatedProductList;

        }
        return response;
    };

    const getPurchasedProductList = () => {

        setLoading(true);

        const responseHandler: ResponseHandler<PurchasedProductResponse> = {
            onResponseSuccess(response: PurchasedProductResponse): void {
                setPurchasedProductResponse(setInitialValue(response));
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        getApiCall(GET_PURCHASE_LIST(props.establishmentUuid, pageNo, size, searhProductName, brandId), responseHandler);
    };

    const getPurchasedBrandList = () => {
        setLoading(true);

        const responseHandler: ResponseHandler<PurchasedBrandResponse> = {
            onResponseSuccess(response: PurchasedBrandResponse): void {
                setPurchasedBrandResponse(response);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        getApiCall(GET_PURCHASED_BRAND_LIST(props.establishmentUuid), responseHandler);
    };

    const changeReturnableQuantityForAnItem = (updatedProduct: PurchaseProductDetails, returnQuantity: number) => {
        updatedProduct.returnQuantity = returnQuantity;
        setPurchasedProductResponse({ ...purchasedProductResponse! });

    };

    const removeProductFromLocal = (productId: number) => {
        dispatch(removeProductFromCart({ establishmentUuid: props.establishmentUuid, productId: productId }));
    };

    // columns
    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (name, record: PurchaseProductDetails) => <ProductNameInfo name={name} brand={record.brand_name} />,

        },
        {
            title: 'Latest Purchase date',
            dataIndex: 'latest_purchase_date',
            rowVisibility: [Device.DESKTOP],
            render: latest_purchase_date => <label>{getISTFromEpoch(latest_purchase_date)}</label>,
        },
        {
            title: 'Life time Purchase quantity',
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: quantity => <Quantity quantity={quantity} />,
        },
        {
            title: <OptionalTitle title='Return quantity' description='The quantity to be returned and must be less than or equal to the quantity eligible for return' />,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: PurchaseProductDetails) => <div className='flex items-center'>

                <InputNumber
                    key={record.id}
                    value={record.returnQuantity}
                    controls={false}
                    min={0}
                    max={record.quantity}
                    onPressEnter={returnQuantityEnterPress}
                    onBlur={event => {

                        const returnQuantity = parseInt(event.target.value);
                        const isReturnQuantityChanged = returnQuantity !== record.returnQuantity;
                        const isReturnQuantityInRange = returnQuantity >= 0 && returnQuantity <= record.quantity;

                        if (isReturnQuantityChanged && isReturnQuantityInRange) {
                            changeReturnableQuantityForAnItem(record, returnQuantity);
                            setSelectedPurchaseProduct(record);
                        }
                    }}
                />
                <label className='pl-5'>{record.returnQuantity > 1 ? 'Units' : 'Unit'}</label>
                {
                    record.returnQuantity > 0 ? <IoIosArrowDown className='cursor-pointer ml-3' onClick={() => {
                        setSelectedPurchaseProduct(record);
                    }} /> : <></>
                }
            </div>,
        },
        {
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: PurchaseProductDetails) => {
                return <>
                    <Popconfirm
                        title='Reset?'
                        disabled={!(record.returnQuantity > 0)}
                        className=''
                        onConfirm={() => {
                            removeProductFromLocal(record.product_id);
                            setFetchPurchasedProductList(true);
                        }}>
                        <BiReset className={`${record.returnQuantity > 0 ? 'cursor-pointer text-meraai-grey ' : 'cursor-not-allowed text-meraai-lightgrey '} text-2xl`} />

                    </Popconfirm>

                </>;
            },
        },
    ];


    // UseEffects 
    useEffect(() => {
        getPurchasedBrandList();
    }, []);

    useEffect(() => {
        if (fetchPurchasedProductList) {
            getPurchasedProductList();
        }
        setFetchPurchasedProductList(false);
    }, [fetchPurchasedProductList]);


    return {

        loading,
        purchasedProductResponse,
        columns,
        searhProductName,
        setSearchProductName,
        setFetchPurchasedProductList,
        brandId,
        setBrandId,
        purchasedBrandResponse,
        selectedPurchaseProduct,
        setSelectedPurchaseProduct,
        savedReturnOrders: returns.returnCart[props.establishmentUuid],
        initiateReturnDrawerVisibility,
        setInitiateReturnDrawerVisibility,
        setInitialValue,
        pageNo,
        size,
        setPageNo,
        setSize,
    };
};