import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { RoleDataMap } from '../../../types/RoleData';
import { PathConfigData } from '../../config/PathConfigService';
import { Path } from '../../router/Path';

export const PurchaseOrderLayer = () => {
    const navigate = useNavigate();
    const currentRole = useAppSelector(state => state.login.role);
    const pageConfigs = new PathConfigData(currentRole);

    useEffect(() => {
        // Navigating user to Hubs tabs present under Purchase Order.
        navigate(
            pageConfigs.isPathAccessible(Path.HUB_PURCHASE_ORDER_LIST) ? Path.HUB_PURCHASE_ORDER_LIST :
                pageConfigs.isPathAccessible(Path.SELLER_HUB_PURCHASE_ORDER_LIST) ? Path.SELLER_HUB_PURCHASE_ORDER_LIST :
                    RoleDataMap.get(currentRole)?.basePath!,
        );
    }, []);

    return (<></>);
};