import { Carousel, Col, Row } from 'antd';
import React from 'react';
import { MeraaiLogo } from '../../../../images/MeraaiLogo';
import { layout, style } from '../../../../styles/css/style';
import { carouselContent } from './utils';
import { LoginPageLayoutProps } from './types';

const loginBanner = require('./assets/loginBanner.jpg');

export const LoginPageLayout = (props: LoginPageLayoutProps) => {

    return (
        <Row className='w-full h-screen flex content-start'>

            <Col className='w-full h-1/2 md:w-1/2 md:h-full'>
                <div className='relative w-full h-full'>
                    <img className='absolute object-cover w-full h-full' src={loginBanner} alt="meraai login banner" />
                    <Carousel autoplay className='!absolute bottom-0 md:bottom-20 w-full'>
                        {
                            carouselContent.map((eachItem) => <div className='mb-10 md:mb-20'>
                                <h1 className='text-lg xs:text-xl lg:text-2xl xl:text-3xl text-white text-left mx-[8%]'>{eachItem.title}</h1>
                                <h3 className='text-white text-left mx-[8%] flex items-end'>
                                    {eachItem.subTitle}
                                </h3>
                            </div>)
                        }
                    </Carousel>
                </div>

            </Col>

            <Col className={`w-full md:w-1/2 ${layout.flexCol.center}`}>
                <Row className={'w-full lg:w-5/6 xl:w-3/4 2xl:w-2/3 3xl:w-1/2 px-6 xs:px-10 sm:px-20 md:px-5 pt-10 md:pt-0'}>
                    <Col className='w-full'>
                        {
                            props.logo ?? <MeraaiLogo
                                width='12rem'
                                iconColor={style.colors.meraaiBrown}
                                titleColor={style.colors.meraaiOrange}
                            />
                        }
                    </Col>

                    <Col className='w-full pt-5 pb-8'>
                        {typeof props.title === 'string' ?
                            <h1 className='font-semibold m-0'>{props.title}</h1> : props.title}
                        {typeof props.subTitle === 'string' ?
                            <h4 className='text-lg'>{props.subTitle}</h4> : props.subTitle}
                    </Col>

                    <Col className='w-full'>
                        {props.body}
                    </Col>
                    <Col className='w-full'>
                        {props.footer}
                    </Col>
                </Row>
            </Col>

        </Row>
    );
};