import React, { ReactNode } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { layout } from '../../../../styles/css/style';
export const getIcon = (icon?: React.ReactNode) => {
    if (icon) {
        return <Avatar className={`m-0 ${layout.flex.center}`} src={icon} />;
    }
    return <div />;
};

export const getMenuItemTitle = (hasChildren: boolean, title?: ReactNode, path?: string, finalChildrenCount?: number) => {
    if (hasChildren && finalChildrenCount! > 1) {
        return <label className="cursor-pointer">{title}</label>;
    }
    return <Link to={path!}> {title} </Link>;
};

export const getSelectedKey = (value: string[]): string[] => {
    if (!value) { return []; }

    if (value.length === 3) {
        return [`${value.at(2)}-${value.at(1)}`];
    } else {
        return [`${value.at(1)}`];
    }

};