import React from 'react';
import { useParams } from 'react-router-dom';
import ConfigContext from '../config/ConfigContext';
import { EstablishmentDetailsConfig } from '../config/types/ConfigTypes';
import { MeraaiTabs } from '../common/tabs/MeraaiTabs';
import { EstablishmentType } from '../../types/EstablishmentType';
import CreateOrder from '../cart/CreateOrder';
import OrderBody from '../orders/hubsAndOutlets/OrderBody';
import { TimeFilter } from '../../utilities/Date';
import { CREATE } from '../../types/OrderCreationMode';
import { CreatePurchaseOrder } from './layer/details/purchaseOrder/CreatePurchaseOrder';
import UserListing from '../user/listing';
import { BrandList } from './layer/details/brands/list';
import { CreateEstablishment } from './layer/details/information';
import { HubProductList } from './layer/details/products/hub';
import { SellerHubProduct } from './layer/details/products/sellerHub/list';
import { ListingPage } from '../common/templates/listingPage';
import { PageBreadcrumbHeader } from '../common/pageBreadcrumbHeader';
import OutletReceivables from './layer/details/receivables/OutletReceivables';
import { PurchasedProductList } from './layer/details/returns/list';


enum Tab {
    INFO = 'INFO',
    USERS = 'USERS',
    ORDERS = 'ORDERS',
    RECEIVABLES = 'RECEIVABLES',
    LIST_ORDERS = 'LIST_ORDERS',
    CREATE_ORDER = 'CREATE_ORDER',
    HUB_PRODUCTS = 'HUB_PRODUCTS',
    SELLER_HUB_PRODUCTS = 'SELLER_HUB_PRODUCTS',
    CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER',
    BRANDS = 'BRANDS',
    RETURNS = 'RETURNS',
}

function EstablishmentDetailsLayer() {
    const { establishmentUuid, eType } = useParams();
    const config = React.useContext(ConfigContext) as EstablishmentDetailsConfig;
    const [establishmentName, setEstablishmentName] = React.useState<string>();
    return (
        <>
            <ListingPage
                breadcrumb={
                    <PageBreadcrumbHeader items={[
                        EstablishmentType.valueOf(eType!)?.display,
                        establishmentName,
                    ]} />
                }
                body={
                    <>
                        <MeraaiTabs
                            hideDefaultStyle
                            tabs={[
                                {
                                    key: Tab.INFO,
                                    name: 'Information',
                                    accessible: config.shouldShowInfo,
                                    component: () => (
                                        <CreateEstablishment setEstablishmentName={setEstablishmentName} mode={config.infoConfig?.saveMode} uuid={establishmentUuid} />
                                    ),
                                },
                                {
                                    key: Tab.USERS,
                                    name: 'Users',
                                    accessible: config?.users.show,
                                    component: (active: boolean) => <UserListing
                                        active={active}
                                        chosenEstablishment={
                                            {
                                                uuid: establishmentUuid,
                                                type: EstablishmentType.valueOf(eType!),
                                            }
                                        }
                                    />,
                                },
                                {
                                    key: Tab.HUB_PRODUCTS,
                                    name: 'Products',
                                    accessible: config.products.hub.show && EstablishmentType.HUB.type === eType,
                                    component: () => <HubProductList />,
                                },
                                {
                                    key: Tab.CREATE_PURCHASE_ORDER,
                                    name: 'Purchase Order',
                                    accessible: config.allowPurchaseOrderCreation! && EstablishmentType.HUB.type === eType,
                                    component: () => <CreatePurchaseOrder />,
                                },
                                {
                                    key: Tab.BRANDS,
                                    name: 'Brands',
                                    accessible: config?.brands?.allowListing && EstablishmentType.SELLER_HUB.type === eType,
                                    component: () => <BrandList />,
                                },
                                {
                                    key: Tab.SELLER_HUB_PRODUCTS,
                                    name: 'Products',
                                    accessible: config?.products.sellerHub.show && config.products.sellerHub.allowProductListing && EstablishmentType.SELLER_HUB.type === eType,
                                    component: () => <SellerHubProduct config={config} />,

                                },
                                {
                                    key: Tab.ORDERS,
                                    name: 'Orders',
                                    accessible:
                                        config?.ordersConfig != undefined && eType === EstablishmentType.OUTLET.type,
                                    subTabs: [
                                        {
                                            key: Tab.LIST_ORDERS,
                                            name: 'List',
                                            accessible:
                                                config?.ordersConfig?.listOrders?.show === true &&
                                                eType === EstablishmentType.OUTLET.type,
                                            component: (active: boolean) => (
                                                <OrderBody
                                                    active={active}
                                                    type={EstablishmentType.OUTLET.type}
                                                    selectedEstablishmentUuid={establishmentUuid}
                                                    states={config.ordersConfig?.listOrders?.allowedOrderStates}
                                                    getOrdersOnLaunch={config.ordersConfig?.listOrders?.getOrdersOnLaunch}
                                                    fromDate={TimeFilter.TODAY.timeRange().fromDate?.valueOf()}
                                                    toDate={TimeFilter.TODAY.timeRange().toDate?.valueOf()}
                                                />
                                            ),
                                        },
                                        {
                                            key: Tab.CREATE_ORDER,
                                            name: 'Create',
                                            accessible:
                                                config?.ordersConfig?.allowOrderCreation === true &&
                                                eType === EstablishmentType.OUTLET.type,
                                            component: () => (
                                                <CreateOrder establishmentUuid={establishmentUuid ?? ''} mode={CREATE} />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    key: Tab.RECEIVABLES,
                                    name: 'Receivables',
                                    accessible: config?.showReceivables && eType === EstablishmentType.OUTLET.type,
                                    component: (active: boolean) => (
                                        <OutletReceivables active={active} establishmentUuid={establishmentUuid ?? ''} />
                                    ),
                                },
                                {
                                    key: Tab.RETURNS,
                                    name: 'Returns',
                                    accessible: config.returns?.outlet.showList && eType === EstablishmentType.OUTLET.type,
                                    component: (active: boolean) => (
                                        <PurchasedProductList active={active} config={config.returns} establishmentUuid={establishmentUuid ?? ''} />
                                    ),
                                },
                            ]}
                        />
                    </>
                }
            />

        </>
    );
}

export default EstablishmentDetailsLayer;
