import { ReactNode } from 'react';

export interface DataNotFoundProps {
    title?: ReactNode;
    message?: ReactNode;
    loading?: boolean;
}

export const defaultTitle = 'Search for the data using filters mentioned above.';
export const defaultMessage = '';
