import React from 'react';
import { Button, Col, Drawer, Image, InputNumber, Row } from 'antd';
import { PurchaseOrderItem } from '../../../../../types/PurchaseOrders';
import { PurchaseOrderDetailsDrawerProps } from './types';
import { Currency, ProductNameInfo, Quantity, TotalSummary } from '../../../../orders/layer/details/hubsAndOutlets/utils';
import { PurchaseOrderState } from '../../../../../types/PurchaseOrderState';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../../../styles/css/style';
import { addKeyToDataList } from '../../../../../Utils';
import { MeraaiTable } from '../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import _ from 'lodash';

export const PurchaseOrderDetailsDrawer = (props: PurchaseOrderDetailsDrawerProps) => {
    const {
        sellerPurchaseOrderDrawerVisibility,
        setSellerPurchaseOrderDrawerVisibility,
        setPurchaseOrderDetails,
        purchaseOrderDetails,
        shortagesPreviewDrawerVisibility,
        setShortagesPreviewDrawerVisibility,
        setItemChangedCount,
        selectedStateHandler,
        resetSelectedStateHandler,
    } = props;

    const changeAvailableQuantityForAnItem = (item: PurchaseOrderItem, availableQuantity: number) => {
        if (availableQuantity >= 0 && availableQuantity <= item.ordered_quantity) {
            item.availableQuantity = availableQuantity;
        } else if (!availableQuantity) {
            item.availableQuantity = 0;
        }

        setPurchaseOrderDetails(_.cloneDeep(purchaseOrderDetails));
        setItemChangedCount(purchaseOrderDetails?.items.filter(productItem => productItem.availableQuantity !== productItem.quantity)?.length!);
    };

    const computeTotalAmount = (): number => {
        if (purchaseOrderDetails) {
            return purchaseOrderDetails.items.map((item: PurchaseOrderItem) => item.price_per_unit * item.availableQuantity!).reduce((totalAmount, amount) => totalAmount + amount, 0);
        } else {
            return 0;
        }
    };

    const orderItemsTotal = {
        data: [{
            label: <label className='font-semibold'>Subtotal</label>,
            value: <Currency styleClass='pl-4' value={computeTotalAmount()} />,
        },
        {
            label: <label className='font-semibold'>No of Items</label>,
            value: <Quantity styleClass='pl-4' quantity={purchaseOrderDetails?.items.filter((item: PurchaseOrderItem) => item.availableQuantity > 0)?.length} />,
        }],
        divider: true,
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image_url',
            rowVisibility: [Device.DESKTOP],
            render: image => <Image width={30} height={30} src={image} />,
        },
        {
            title: 'Name',
            width: 200,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (__, record: PurchaseOrderItem) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'Ordered QTY',
            dataIndex: 'ordered_quantity',
            rowVisibility: [Device.DESKTOP],
            render: ordered_quantity => <Quantity quantity={ordered_quantity} />,
        },
        {
            title: PurchaseOrderState.valueOf(purchaseOrderDetails?.state!)?.purchaseOrderQuantityTitles?.previousState,
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP],
            render: quantity => <Quantity quantity={quantity} />,
        },
        {
            title: PurchaseOrderState.valueOf(purchaseOrderDetails?.state!)?.purchaseOrderQuantityTitles?.currentState,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (__, record: PurchaseOrderItem) => {
                return (
                    shortagesPreviewDrawerVisibility ? <Quantity quantity={record.availableQuantity} />
                        : <InputNumber
                            key={record.id}
                            value={record.availableQuantity}
                            controls={false}
                            min={0}
                            max={record.ordered_quantity}
                            placeholder="Item count"
                            onBlur={event => {
                                changeAvailableQuantityForAnItem(record, parseInt(event.target.value));
                            }}
                        />
                );
            },
        },
    ];


    return (<>

        <Drawer
            className='meraai-drawer'
            open={sellerPurchaseOrderDrawerVisibility}
            title={
                <>
                    <h3>Review Items</h3>
                    <span className='text-sm'>{selectedStateHandler?.itemAvailabilityModalSubscript || 'Review the items and adjust the quantity that was actually delivered.'}</span>
                </>
            }
            extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                setSellerPurchaseOrderDrawerVisibility(false);
                resetSelectedStateHandler();
            }} />}
            closable={false}

            destroyOnClose
            maskClosable={false}
            keyboard={false}
            footer={
                <>
                    <Row gutter={[24, 24]} className={`${layout.flex.end}`}>
                        <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                            <Button
                                className={`w-full ${style.meraaiPlainButton}`}
                                onClick={() => {
                                    setSellerPurchaseOrderDrawerVisibility(false);
                                    resetSelectedStateHandler();
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                            <Button
                                className={`w-full ${style.meraaiOrangeButton}`}
                                onClick={() => {
                                    setSellerPurchaseOrderDrawerVisibility(false);
                                    if (purchaseOrderDetails?.items.some((item: PurchaseOrderItem) => item.availableQuantity !== item.quantity)) {
                                        setShortagesPreviewDrawerVisibility(true);
                                    }
                                }}
                            >
                                Proceed
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        >
            <>
                <MeraaiTable
                    columns={columns}
                    dataSource={addKeyToDataList(purchaseOrderDetails?.items!)}
                    pagination={false}
                    size='small'
                />
                <TotalSummary {...orderItemsTotal} />
            </>
        </Drawer>

        <Drawer
            className='meraai-drawer'
            open={shortagesPreviewDrawerVisibility}
            title={<h3 className="title">Shortages</h3>}

            extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                setShortagesPreviewDrawerVisibility(false);
                setSellerPurchaseOrderDrawerVisibility(true);
            }} />}
            closable={false}

            destroyOnClose
            maskClosable={false}
            keyboard={false}

            footer={
                <div className={`${layout.flex.end}`}>
                    <Button
                        className={`w-1/4 ${style.meraaiOrangeButton}`}
                        onClick={() => {
                            setShortagesPreviewDrawerVisibility(false);
                        }}>
                        Proceed
                    </Button>
                </div>

            }
        >
            <>
                <MeraaiTable
                    columns={columns}
                    dataSource={addKeyToDataList(purchaseOrderDetails?.items.filter(product => product.availableQuantity !== product.quantity)!)}
                    pagination={false}
                    size='small'
                />
                <TotalSummary {...orderItemsTotal} />
            </>
        </Drawer>
    </>);
};