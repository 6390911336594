export const ACCEPTED_FILE_FORMAT = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
];

export type CsvColumn = {
    columnName: string,
    dataKey: string,
};

export const ACCEPTED_CSV_COLUMNS: CsvColumn[] = [
    {
        columnName: 'name',
        dataKey: 'name',
    }, {
        columnName: 'sku',
        dataKey: 'sku',
    }, {
        columnName: 'hsn',
        dataKey: 'hsn',
    }, {
        columnName: 'ean',
        dataKey: 'ean',
    }, {
        columnName: 'price',
        dataKey: 'price',
    }, {
        columnName: 'mrp',
        dataKey: 'mrp',
    }, {
        columnName: 'gst',
        dataKey: 'gst',
    }, {
        columnName: 'cess',
        dataKey: 'cess',
    }, {
        columnName: 'brand',
        dataKey: 'brand',
    }, {
        columnName: 'carton_capacity',
        dataKey: 'orderQuantityMultiple',
    },
];
export const ACCEPTED_FILES = '.csv, .xlsx, .xls';

export const NAME_REGEX = /^[ A-Za-z\/\'0-9!@#\$%\^\&*\)\(+=._-]+$/;
