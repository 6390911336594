export const layout = {
    flex: {
        center: 'flex items-center justify-center',
        spaceBetween: 'flex justify-between items-center',
        spaceEvenly: 'flex justify-evenly items-center',
        start: 'flex justify-start items-center',
        end: 'flex justify-end items-center',
    },
    flexCol: {
        center: 'flex-column items-center justify-center',
        start: 'flex-column items-center justify-start',
        spaceBetween: 'flex-column items-center justify-between',
        spaceEvenly: 'flex-column items-center justify-evenly',
        spaceAround: 'flex-column items-center justify-around',
    },
};

export const style = {

    infoIcon: 'ml-3 text-meraai-lightgrey cursor-pointer hover:text-meraai-orange text-lg',
    colors: {
        white: '#FFFFFF',
        meraaiOrange: '#F69626',
        meraaiBrown: '#6E3B20',
        meraaiRed: '#CB3420',
    },
    meraaiPlainButton: `text-meraai-orange border-1 border-meraai-orange 
    hover:text-meraai-orange h-[38px] w-full
    bg-white hover:bg-orange-100
    focus:text-meraai-orange focus:outline-none focus:bg-orange-100 ${layout.flex.center}`,

    meraaiOrangeButton: `text-white hover:text-white h-[38px] w-full
    bg-meraai-orange hover:bg-orange-300
    focus:text-white focus:outline-none focus:bg-orange-300 ${layout.flex.center}`,

    meraaiDangerButton: `text-white hover:text-white h-[38px] w-full
    bg-meraai-red hover:bg-red-500
    focus:text-white focus:bg-red-500 ${layout.flex.center}`,

};
