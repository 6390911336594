/* eslint no-console: 0 */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { FIREBASE_TOKEN } from '../../Constants';
import { REGISTER_FIREBASE_TOKEN } from '../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../http/HttpClient';
import { OpenNotification } from '../common/notifications/Notifications';


export interface RegisterFirebaseTokenRequest {
    token: string
}

export interface RegisterFirebaseTokenResponse {
    message: string
}

const firebaseConfig = JSON.parse(window.__RUNTIME_CONFIG__.FIREBASE_CONFIG);

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


const registerFirebaseToken = async (token: string) => {

    const request = {
        token: token,
    } as RegisterFirebaseTokenRequest;
    const responseHandler: ResponseHandler<RegisterFirebaseTokenResponse> = {
        onResponseSuccess(): void {
            localStorage.setItem(FIREBASE_TOKEN, token);
        },
        onResponseFailed(): void {
        },
        onResponseError(): void {
        },
    };
    await putApiCall(REGISTER_FIREBASE_TOKEN, request, responseHandler);

};

export const checkAndSetFirebaseToken = async (currentStoredToken: string) => {

    const serviceWorkerRegistration = await navigator
        .serviceWorker
        .register(`/firebase-messaging-service-worker.js?config=${window.__RUNTIME_CONFIG__.FIREBASE_CONFIG}`);

    return getToken(messaging, { vapidKey: window.__RUNTIME_CONFIG__.VAPID_KEY, serviceWorkerRegistration: serviceWorkerRegistration! }).then((token) => {
        if (token) {
            if (token != currentStoredToken) {
                registerFirebaseToken(token);
            }

            onMessage(messaging, (payload) => {
                if (payload.data) {
                    OpenNotification(payload?.notification?.title! ?? '', payload?.notification?.body! ?? '', 'success');
                }
            });

        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
};