import React from 'react';
import { AMOUNT_ROUNDING_SCALE, InputType, RegexFormat, RUPEE_SYMBOL } from './Constants';
import { v4 as uuidv4 } from 'uuid';
import { Establishment } from './Types';
import { Typography } from 'antd';
import { BsDash } from 'react-icons/bs';

export function checkInputType(input: String): InputType {
    if (input.match(RegexFormat.REGEX_EMAIL)) {
        return InputType.EMAIL;
    }
    if (input.match(RegexFormat.REGEX_MOBILE)) {
        return InputType.MOBILE_NUMBER;
    }
    return InputType.NA;
}
/*
Ensure that we modify the existing object and not create new objects
*/
export function addKeyToDataList(data: Array<any>) {
    if (!data) {
        return data;
    }
    data.forEach((datum) => {
        if (Object.isExtensible(datum) && datum.key === undefined) {
            datum.key = uuidv4();
        }
    });
    return data;
}

export const isObjectEmpty = value => {
    return Object.prototype.toString.call(value) === '[object Object]' && JSON.stringify(value) === '{}';
};

export const isArrayEmpty = value => {

    if (value && value.length > 0) {
        // the array is defined and has at least one element
        return false;
    }
    return true;
};

export const areTwoArraysEqual = (array1, array2) => {
    if (array1?.length === array2?.length) {
        return array1?.every((element, index) => {
            if (element === array2[index]) {
                return true;
            }

            return false;
        });
    }
    return false;
};


export const getContactInfo = (establishment?: Establishment) => {
    if (!establishment) {
        return;
    }
    let contact = '';
    if (establishment.contact) {
        if (establishment.contact.mobile_number) {
            contact = `${establishment.contact.mobile_number}`;
        }
        if (establishment.contact.name) {
            if (contact !== '') {
                contact = contact + ' - ';
            }
            contact = contact + ` ${establishment.contact.name} `;
        }
    }
    return contact;
};

export const formatCurrency = (amount: number): string => {
    if (amount) {
        return `${RUPEE_SYMBOL} ${amount.toFixed(AMOUNT_ROUNDING_SCALE)}`;
    } else {
        return `${RUPEE_SYMBOL} 0`;
    }
};


export const MeraaiLabel = ({ children }: { children: any }) => {
    return <Typography.Paragraph className='!m-0 cursor-pointer' ellipsis={{ rows: 1, tooltip: children }
    }> {children && children !== '' ? children : <BsDash />}</Typography.Paragraph >;

};