import { EstablishmentProperties, OrderState as OrderStateProperty } from './types';

export enum OrderStates {
    ALL = 'ALL',
    BILLED = 'BILLED',
    HUB_ACCEPTED = 'HUB_ACCEPTED',
    HUB_REJECTED = 'HUB_REJECTED',
    SELLER_ORDER_GENERATED = 'SELLER_ORDER_GENERATED',
    SELLER_ACCEPTED = 'SELLER_ACCEPTED',
    SELLER_REJECTED = 'SELLER_REJECTED',
    SELLER_DISPATCHED = 'SELLER_DISPATCHED',
    HUB_RECEIVED = 'HUB_RECEIVED',
    OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
    NOT_DELIVERED = 'NOT_DELIVERED',
    PAYMENT_COLLECTION_INITIATED = 'PAYMENT_COLLECTION_INITIATED',
    PAYMENT_COLLECTED = 'PAYMENT_COLLECTED',
    DELIVERED = 'DELIVERED',
    SELLER_PARTIALLY_ACCEPTED = 'SELLER_PARTIALLY_ACCEPTED',
    SCHEDULE_DELIVERY = 'SCHEDULE_DELIVERY',
    DELIVERY_CANCELLED = 'DELIVERY_CANCELLED',
    DELIVERY_REJECTED = 'DELIVERY_REJECTED',
    DEFAULT = 'DEFAULT',
}

export namespace OrderStates {
    const reverseMap = new Map<string, OrderStates>();
    Object.keys(OrderStates).forEach((s: OrderStates) => {
        reverseMap.set(s.toString(), s);
    });
    export function valueOf(str: string): OrderStates | undefined {
        return reverseMap.get(str);
    }
}

export const allOrderStates: OrderStateProperty[] = [
    { state: OrderStates.ALL, value: '', display: 'All', color: '', includeInFilter: true },
    {
        state: OrderStates.BILLED,
        value: 'BILLED',
        display: 'Billed',
        color: 'blue',
        includeInFilter: true,
    },
    {
        state: OrderStates.HUB_ACCEPTED,
        value: 'HUB_ACCEPTED',
        display: 'Accepted by Hub',
        color: 'gold',
        includeInFilter: true,
    },
    {
        state: OrderStates.HUB_REJECTED,
        value: 'HUB_REJECTED',
        display: 'Rejected by Hub',
        color: 'volcano',
        includeInFilter: true,
    },
    {
        state: OrderStates.SELLER_ORDER_GENERATED,
        value: 'SELLER_ORDER_GENERATED',
        display: 'Sent to seller(s)',
        color: 'cyan',
        includeInFilter: false,
    },
    {
        state: OrderStates.SELLER_PARTIALLY_ACCEPTED,
        value: 'SELLER_ACCEPTED',
        display: 'Accepted by few sellers',
        color: 'orange',
        includeInFilter: false,
    },
    {
        state: OrderStates.SELLER_ACCEPTED,
        value: 'SELLER_ACCEPTED',
        display: 'Accepted by seller(s)',
        color: 'lime',
        includeInFilter: false,
    },
    {
        state: OrderStates.SELLER_REJECTED,
        value: 'SELLER_REJECTED',
        display: 'Check inventory',
        color: 'red',
        includeInFilter: false,
    },
    {
        state: OrderStates.SELLER_DISPATCHED,
        value: 'SELLER_DISPATCHED',
        display: 'Dispatched from seller',
        color: 'gold',
        includeInFilter: false,
    },
    {
        state: OrderStates.HUB_RECEIVED,
        value: 'HUB_RECEIVED',
        display: 'Received by Hub',
        color: 'geekblue',
        includeInFilter: true,
    },
    {
        state: OrderStates.DELIVERY_CANCELLED,
        value: 'DELIVERY_CANCELLED',
        display: 'Delivery cancelled',
        color: 'red',
        includeInFilter: true,
    },
    {
        state: OrderStates.SCHEDULE_DELIVERY,
        value: 'SCHEDULE_DELIVERY',
        display: 'Items packed',
        color: 'gold',
        includeInFilter: true,
    },
    {
        state: OrderStates.OUT_FOR_DELIVERY,
        value: 'OUT_FOR_DELIVERY',
        display: 'Out for Delivery',
        color: 'orange',
        includeInFilter: true,
    },
    {
        state: OrderStates.NOT_DELIVERED,
        value: 'NOT_DELIVERED',
        display: 'Not Delivered',
        color: 'magenta',
        includeInFilter: true,
    },
    {
        state: OrderStates.DELIVERY_REJECTED,
        value: 'DELIVERY_REJECTED',
        display: 'Delivery Rejected',
        color: 'red',
        includeInFilter: true,
    },
    {
        state: OrderStates.PAYMENT_COLLECTION_INITIATED,
        value: 'PAYMENT_COLLECTION_INITIATED',
        display: 'Payment Collection Initiated',
        color: 'geekblue',
        includeInFilter: true,
    },
    {
        state: OrderStates.PAYMENT_COLLECTED,
        value: 'PAYMENT_COLLECTED',
        display: 'Payment Collected',
        color: 'green',
        includeInFilter: true,
    },
    {
        state: OrderStates.DELIVERED,
        value: 'DELIVERED',
        display: 'Delivered',
        color: 'green',
        includeInFilter: true,
    },
];

export const orderStatesMap: Map<OrderStates, OrderStateProperty> = allOrderStates.reduce((map: Map<OrderStates, OrderStateProperty>, orderState: OrderStateProperty) => {
    map.set(orderState.state, orderState);
    return map;
}, new Map<OrderStates, OrderStateProperty>());

export const defaultOrderState: OrderStateProperty = {
    state: OrderStates.DEFAULT,
    value: null,
    display: '',
    color: 'geekblue',
};

export const establishmentProperties: EstablishmentProperties[] = [
    {
        type: 'HUB',
        title: 'Outlet',
        propertyName: 'agent_establishment',
    },
    {
        type: 'OUTLET',
        title: 'Hub',
        propertyName: 'establishment',
    },
];
