
export enum BrandSaveModeType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
}

export class BrandSaveMode {
    static readonly CREATE = new BrandSaveMode(BrandSaveModeType.CREATE, 'Create', 'Add Brand', false, false);

    static readonly UPDATE = new BrandSaveMode(BrandSaveModeType.UPDATE, 'Update', 'Update Brand', true, true);

    private constructor(
        public readonly mode: BrandSaveModeType,
        public readonly saveButtonText: string,
        public readonly modalTitle: string,
        public readonly initializeFormFields: boolean,
        public readonly showActiveToggle: boolean,

    ) { }
}
