import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Badge, Card, Col, Row } from 'antd';
import { BrandCardProps } from './type';
import { ReadMore } from '../../../../../common/ReadMore';
import { BsDashLg } from 'react-icons/bs';
import './style.less';

const styleProperties = { cursor: 'pointer', color: '#626262', padding: '10px 20px' };
const readMoreStyle = { padding: '10px 20px', color: '#676767', fontSize: '15px' };
const titleStyle = { padding: '0px 20px', fontSize: '14px' };
const nameStyle = { padding: '10px 20px', textDecoration: 'underline' };


export const BrandCard = (props: BrandCardProps) => {

    const InnerCard = ({ brand, setSelectedBrand }: BrandCardProps) => {
        return (
            <>
                <Card
                    style={{
                        width: '100%',
                        border: '1px solid #D9D9D9',
                        borderRadius: '10px',
                    }}
                    actions={[
                        <EditOutlined key="edit" onClick={() => {
                            setSelectedBrand(brand);
                        }} />,
                    ]}
                    bodyStyle={{ padding: '10px' }}
                >

                    <Card.Meta
                        className='flex-col-center brand-card-details'
                        avatar={<img style={{ borderRadius: '50%', width: '100%' }} src={brand.image_url} width={80} height={80} />}
                        title={
                            <Row>
                                <Col xs={24}>
                                    <h3 className='remove-margin fit-text' style={nameStyle}>
                                        {brand.name}
                                    </h3>
                                </Col>
                                <Col xs={24}>
                                    {
                                        <span className='flex-row-left-align' style={titleStyle}>
                                            {brand.title ? brand.title : <BsDashLg />}
                                        </span>
                                    }
                                </Col>
                            </Row>
                        }
                        description={
                            brand.description ?
                                <ReadMore style={readMoreStyle}>
                                    {brand.description}
                                </ReadMore> : <p style={styleProperties}> <BsDashLg /> </p>
                        }
                    />

                </Card>


            </>
        );
    };


    return (
        <Col xs={24} md={12} xl={8} className='flex-justify-center inactive-brand-card'>
            {
                props.brand.active ? <InnerCard brand={props.brand} setSelectedBrand={props.setSelectedBrand} /> : <Badge.Ribbon text="Deactivated" color="#8d8d8d">
                    <InnerCard brand={props.brand} setSelectedBrand={props.setSelectedBrand} />
                </Badge.Ribbon>
            }
        </Col>
    );
};