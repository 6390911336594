import { Role } from './Role';

export class EstablishmentType {
    static readonly HUB = new EstablishmentType('HUB', 'Hubs', 'HUB_OPERATOR', [Role.HUB_OPERATOR, Role.HUB_STAFF, Role.DELIVERY_STAFF, Role.GROUND_OPS]);

    static readonly OUTLET = new EstablishmentType('OUTLET', 'Outlets', 'VENDOR', [Role.VENDOR, Role.GROUND_OPS]);

    static readonly SELLER_HUB = new EstablishmentType('SELLER_HUB', 'Seller Hubs', 'SELLER', [Role.SELLER]);

    private constructor(
        public readonly type: string,
        public readonly display: any,
        public readonly agentType: string,
        public readonly userRoles: Role[],
    ) { }

    static valueOf(type: string): EstablishmentType | undefined {
        if (type === 'HUB') return EstablishmentType.HUB;
        if (type === 'OUTLET') return EstablishmentType.OUTLET;
        if (type === 'SELLER_HUB') return EstablishmentType.SELLER_HUB;
    }

    toString() {
        return this.type;
    }
}
