import { Path } from '../../router/Path';
import { PathConfig } from '../PathConfigService';
import {
    FinanceLayerConfig,
    OrderDetailsConfig,
    PurchaseOrderDetailsConfig,
    UserListingConfig,
} from '../types/ConfigTypes';

const FinanceConfig: PathConfig[] = [
    {
        path: Path.HOME,
        accessible: true,
    },
    {
        path: Path.LOGOUT,
        accessible: true,
    },
    {
        path: Path.ORDER_DETAILS,
        accessible: true,
        config: {
            stateChangeConfig: {
                allowedStates: [],
            },
            allowToViewSellerOrders: false,
            allowEditOrderDetails: false,
        } as OrderDetailsConfig,
    },
    {
        path: Path.PURCHASE_ORDER_DETAILS,
        accessible: false,
        config: {
            show: false,
            stateChangeConfig: {
                allowedStates: [],
            },
            paymentConfig: {
                allowMakePayment: true,
            },
        } as PurchaseOrderDetailsConfig,
    },
    {
        path: Path.FINANCE,
        accessible: true,
        config: {
            exportOrders: true,
            showPurchaseOrders: true,
        } as FinanceLayerConfig,
    },
    {
        path: Path.USER_LISTING,
        accessible: false,
        config: {
            primaryEstablishmentType: null,
            associatedEstablishmentTypes: null,

        } as UserListingConfig,
    },
    {
        path: Path.USER_PROFILE,
        accessible: true,
    },
];

export default FinanceConfig;
