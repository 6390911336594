/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */
/* eslint @typescript-eslint/no-unused-expressions: 0 */

import { Divider, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ESTABLISHMENT_LIST } from '../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { Establishment, EstablishmentDataResponse } from '../../../Types';
import { EstablishmentType } from '../../../types/EstablishmentType';
import EstablishmentSelectionModal from './EstablishmentSelectionModal';

const { Option } = Select;

export enum EstablishmentSelectionMode {
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
}

export interface SelectableEstablishment {
    establishment: Establishment;
    selected: boolean;
}

interface EstablishmentsDropDownProps {
    disabled?: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    type: EstablishmentType;
    /**
     * if SelectionMode is SINGLE_SELECT, selectedUuids has to be a string else, it'll be an array
     */
    selectedUuids?: string | string[];
    setSelectedUuids: React.Dispatch<React.SetStateAction<string | string[] | undefined>>;
    selectionMode?: EstablishmentSelectionMode;
    fetchEstablishmentList: boolean | undefined;
    disableDefaultSelection?: boolean;
    onBlur?: () => void;
    onChange?: (value) => void;
    onFocus?: () => void;
    setModalVisibility?: React.Dispatch<React.SetStateAction<boolean>>
}

export function getUuidListFromEncodedString(encodedUuids: string): string[] | undefined {
    if (encodedUuids) {
        const decodedUuids = decodeURIComponent(encodedUuids);
        const uuidList = JSON.parse(decodedUuids);
        return uuidList;
    }
    return undefined;
}

export function getEncodedStringFromUuidList(uuidList: string[]): string {
    const establishmentListJson = JSON.stringify(uuidList);
    const encodedList = encodeURIComponent(establishmentListJson);
    return encodedList;
}

export function EstablishmentsDropDown(props: EstablishmentsDropDownProps) {
    const {
        setLoading,
        type,
        disableDefaultSelection = false,
        selectionMode = EstablishmentSelectionMode.MULTI_SELECT,
    } = props;

    const [selectableEstablishments, setSelectableEstablishments] = useState<SelectableEstablishment[]>();
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [showSelectionModal, setShowSelectionModal] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(-1);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const isSelectedEstablishmentSet = useRef<boolean>(false);
    const [establishmentListRequested, setEstablishmentRequested] = useState<boolean>(false);

    const get = async () => {
        currentPage == -1 ? setLoading(true) : setLoadingMore(true);
        const endPointEstablishments = ESTABLISHMENT_LIST(
            type.type,
            currentPage + 1,
            Math.max(
                selectionMode == EstablishmentSelectionMode.SINGLE_SELECT ? 1 : props.selectedUuids?.length!!,
                10,
            ),
            props.selectedUuids ?? [],
        );

        const responseHandler: ResponseHandler<EstablishmentDataResponse> = {
            onResponseSuccess(value: EstablishmentDataResponse): void {
                setLoading(false);
                setLoadingMore(false);
                setCurrentPage(currentPage + 1);
                setHasMoreData(value.next_page);
                const establishments: SelectableEstablishment[] = value.establishments.map(item => {
                    return {
                        establishment: item,
                        selected: props.selectedUuids?.includes(item.uuid)!!,
                    };
                });
                const selectableEstablishmentList: SelectableEstablishment[] = [...(selectableEstablishments ?? []), ...establishments];
                setSelectableEstablishments(selectableEstablishmentList);

                if (
                    !disableDefaultSelection &&
                    selectableEstablishmentList.length === 1 &&
                    !props.selectedUuids
                ) {
                    if (selectionMode === EstablishmentSelectionMode.SINGLE_SELECT) {
                        props.setSelectedUuids(selectableEstablishmentList.at(0)?.establishment.uuid);
                    } else {
                        props.setSelectedUuids([selectableEstablishmentList.at(0)?.establishment.uuid!]);
                    }
                }
            },
            onResponseFailed(): void {
                setLoading(false);
                setLoadingMore(false);
                setSelectableEstablishments([]);
            },
            onResponseError(): void {
                setLoading(false);
                setLoadingMore(false);
                setSelectableEstablishments([]);
            },
        };

        await getApiCall(endPointEstablishments, responseHandler);
    };

    useEffect(() => {
        if (hasMoreData && props.fetchEstablishmentList) {
            get();
        } else {
            setCurrentPage(-1);
            setSelectableEstablishments(undefined);
            setEstablishmentRequested(true);
        }
    }, [props.fetchEstablishmentList, props.type]);

    useEffect(() => {
        if (establishmentListRequested) {
            get();
            setEstablishmentRequested(false);
        }
    }, [establishmentListRequested]);

    useEffect(() => {
        if (!isSelectedEstablishmentSet.current && props.selectedUuids && selectableEstablishments) {
            isSelectedEstablishmentSet.current = true;
            selectableEstablishments?.forEach(
                item => (item.selected = props.selectedUuids?.includes(item.establishment.uuid)!!),
            );
            setSelectableEstablishments([...selectableEstablishments]);
        }
        const selectedEstablishmentUuids = selectableEstablishments
            ?.filter(item => item.selected)
            ?.map(item => item.establishment.uuid);
        if (selectedEstablishmentUuids != undefined && selectedEstablishmentUuids.length > 0) {
            if (EstablishmentSelectionMode.SINGLE_SELECT === selectionMode) {
                props.setSelectedUuids(selectedEstablishmentUuids[0]);
                if (props.onChange) {
                    props.onChange(selectedEstablishmentUuids[0]);
                }
            } else {
                props.setSelectedUuids(selectedEstablishmentUuids);
                if (props.onChange) {
                    props.onChange(selectedEstablishmentUuids);
                }
            }
        } else {
            if (EstablishmentSelectionMode.SINGLE_SELECT === selectionMode) {
                props.setSelectedUuids(undefined);
                if (props.onChange) {
                    props.onChange(undefined);
                }
            } else {
                props.setSelectedUuids(selectedEstablishmentUuids);
                if (props.onChange) {
                    props.onChange(selectedEstablishmentUuids);
                }
            }
        }
    }, [selectableEstablishments]);

    const setEstablishmentSelected = (uuid: string | string[], selected: boolean) => {
        isSelectedEstablishmentSet.current = true;
        const newList = [...(selectableEstablishments ?? [])];
        if (selected) {
            newList.forEach(item => (item.selected = false));
        }
        if (typeof uuid === 'string') {
            uuid = [uuid];
        }
        uuid.forEach(id => {
            newList.find(item => item.establishment.uuid === id)!!.selected = selected;
        });

        setSelectableEstablishments(newList);
    };

    useEffect(() => {
        if (props.setModalVisibility) {
            props.setModalVisibility(showSelectionModal);
        }
        if (!showSelectionModal && props.onBlur) {
            props.onBlur();
        }
    }, [showSelectionModal]);

    const setEstablishmentSelectedThroughModal = (uuid: string, selected: boolean) => {
        isSelectedEstablishmentSet.current = true;
        const newList = [...(selectableEstablishments ?? [])];
        if (selected && selectionMode === EstablishmentSelectionMode.SINGLE_SELECT) {
            newList.forEach(item => (item.selected = false));
        }
        newList.find(item => item.establishment.uuid === uuid)!!.selected = selected;
        setSelectableEstablishments(newList);
    };

    return (
        <>
            {
                <>
                    {selectionMode === EstablishmentSelectionMode.SINGLE_SELECT && (
                        <Select
                            className="remove-select-background-tag"
                            showArrow
                            value={selectableEstablishments ? props.selectedUuids : undefined}
                            style={{ width: '100%' }}
                            placement="bottomLeft"
                            disabled={props.disabled}
                            placeholder={`Select ${props.type.display}`}
                            onChange={(uuid: string) => setEstablishmentSelected(uuid, true)}
                            showSearch={false}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Row
                                        style={{ padding: '0 8px 4px' }}
                                        justify="center"
                                        onClick={() => {
                                            setShowSelectionModal(true);
                                        }}>
                                        <Link to="">Show More</Link>
                                    </Row>
                                </>
                            )}>
                            {selectableEstablishments?.map((selectableEstablishment, index) => (
                                <Option value={selectableEstablishment.establishment.uuid} key={index}>
                                    {selectableEstablishment.establishment.name}
                                </Option>
                            ))}
                        </Select>
                    )}
                    {selectionMode === EstablishmentSelectionMode.MULTI_SELECT && (
                        <Select
                            mode="multiple"
                            showArrow
                            maxTagCount={1}
                            maxTagTextLength={8}
                            className="remove-select-background-tag"
                            value={
                                selectableEstablishments && typeof props.selectedUuids === 'object'
                                    ? props.selectedUuids
                                    : undefined
                            }
                            style={{ width: '100%' }}
                            placement="bottomLeft"
                            disabled={props.disabled}
                            placeholder={`Select ${props.type.display}`}
                            onChange={(uuids: string[]) => setEstablishmentSelected(uuids, true)}
                            showSearch={false}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Row
                                        style={{ padding: '0 8px 4px' }}
                                        justify="center"
                                        onClick={() => {
                                            setShowSelectionModal(true);
                                        }}>
                                        <Link to="">Show More</Link>
                                    </Row>
                                </>
                            )}>
                            {selectableEstablishments?.map((selectableEstablishment, index) => (
                                <Option value={selectableEstablishment.establishment.uuid} key={index}>
                                    {selectableEstablishment.establishment.name}
                                </Option>
                            ))}
                        </Select>
                    )}

                    <EstablishmentSelectionModal
                        visible={showSelectionModal}
                        loading={loadingMore}
                        setVisible={setShowSelectionModal}
                        type={type}
                        selectionMode={selectionMode!!}
                        establishments={selectableEstablishments ?? []}
                        setEstablishments={setSelectableEstablishments}
                        toggleEstablishmentSelection={setEstablishmentSelectedThroughModal}
                        loadMore={get}
                        hasMoreData={hasMoreData}
                    />
                </>
            }
        </>
    );
}
