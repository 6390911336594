import React, { useState } from 'react';
import { Layout } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import { SideNavBarLayout } from './sideNavBar/layout';
import { AppLayoutProps } from './types';
import { MobileViewPageHeader } from '../common/templates/mobileView/pageHeader';
import { updateCollapsedState } from '../common/slice/SideMenuSlice';
import { useAppDispatch } from '../../hooks';

function AppLayout(props: AppLayoutProps) {
    /**
     * collapsed and broken are properties used to show/hide the sidebar and the hamburger icon.
     * In the Desktop site view collapsed is set to false which means that the side bar will be shown
     * and isn't collapsed. We toggle is when hamburger icon is clicked on.
     * Broken is a property which is set to true as soon as we switch to mobile view. It's defined via
     * the breakpoint property of Sider.
     * Rules applied : 
     * 1) In mobile view (broken == true) and if sider is open (collapsed == false), then footer is not shown.
     * 2) Clicking on an item of menu triggers the toggleSider method.
     * 3) Hamburger is only shown when broken is true.
     */
    const dispatch = useAppDispatch();

    const [collapsed, setCollapsed] = useState(false);
    const [broken, setBroken] = useState(false);

    const toggleSider = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Layout>
            <Sider
                className='side-menu bg-meraai-brown h-screen fixed top-0 bottom-0 left-0 overflow-auto select-none z-[100]'
                breakpoint="lg"
                defaultCollapsed={true}
                collapsedWidth={broken && !collapsed ? '90%' : '0'}
                width={broken ? '100%' : '270px'}
                onBreakpoint={isBroken => {
                    setBroken(isBroken);
                    if (isBroken) {
                        setCollapsed(true);
                    }
                    dispatch(updateCollapsedState(isBroken));
                }}
            >
                <SideNavBarLayout broken={broken} toggleSider={toggleSider} />
            </Sider>
            <Layout className="section">
                {/* This style ensures that the content display is hidden when sider is opened in mobile view */}
                <Content className={`main  ${broken ? 'ml-[0px]' : 'ml-[270px]'} `}>
                    <>
                        <MobileViewPageHeader visible={broken && collapsed} toggleSider={toggleSider} />
                        {props.content}
                    </>

                </Content>
            </Layout>
        </Layout>
    );
}

export default AppLayout;
