export enum Role {
    ADMIN = 'ADMIN',
    VENDOR = 'VENDOR',
    HUB_OPERATOR = 'HUB_OPERATOR',
    DELIVERY_STAFF = 'DELIVERY_STAFF',
    HUB_STAFF = 'HUB_STAFF',
    SELLER = 'SELLER',
    GROUND_OPS = 'GROUND_OPS',
    FINANCE = 'FINANCE',
}


export namespace Role {
    const reverseMap = new Map<string, Role>();
    Object.keys(Role).forEach((s: Role) => {
        reverseMap.set(s.toString(), s);
    });
    export function valueOf(str: string): Role | undefined {
        return reverseMap.get(str);
    }

    export const ALL_ROLES = Object.values(Role);
}


