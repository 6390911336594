import { SmileOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Image, Row, Skeleton, Tag, Timeline } from 'antd';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DOWNLOAD_PURCHASE_INVOICE, DOWNLOAD_PURCHASE_ORDER_DETAILS, PURCHASE_ORDER_DETAILS } from '../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { layout, style } from '../../../../styles/css/style';
import { BrandsAggregateQunatity, PurchaseOrderCreateType, PurchaseOrderDetailResponse, PurchaseOrderItem } from '../../../../types/PurchaseOrders';
import { PurchaseOrderState } from '../../../../types/PurchaseOrderState';
import { addKeyToDataList, getContactInfo, isArrayEmpty } from '../../../../Utils';
import { getISTFromEpoch } from '../../../../utilities/Date';
import { PageBreadcrumbHeader } from '../../../common/pageBreadcrumbHeader';
import { DetailsPage } from '../../../common/templates/detailsPage';
import { ClockCircleOutlined } from '@ant-design/icons';
import { OrderStateTimelineDetails } from '../../../../types/PurchaseOrders';
import Tooltip from 'antd/es/tooltip';
import { TbDownload } from 'react-icons/tb';
import { DetailsCard } from '../../../common/detailsCard';
import { DetailsCardProps } from '../../../common/detailsCard/types';
import { MeraaiTable } from '../../../common/templates/meraaiTable';
import { Currency, ProductNameInfo, Quantity } from '../../../orders/layer/details/hubsAndOutlets/utils';
import { CartonRenderer } from '../../../orders/layer/details/sellerHubs';
import { BsDash } from 'react-icons/bs';
import ConfigContext from '../../../config/ConfigContext';
import { PurchaseOrderDetailsConfig } from '../../../config/types/ConfigTypes';
import { PurchaseOrderPaymentModal } from '../../../finance/purchase_orders/PurchaseOrderPaymentModal';
import { PaymentStatus } from '../../../finance/purchase_orders/PaymentStatus';
import { PurchaseOrderStateChangeActionables } from '../utils/purchaseOrderStateChangeActionables';
import { Device, MeraaiTableColumnType } from '../../../common/templates/meraaiTable/types';
import { AiOutlineClose, AiOutlineDown } from 'react-icons/ai';
import { StateMetadataRendererV2 } from '../../../common/templates/stateMetadataRenderer';
import { StateMetaDatum } from '../../../../Types';
import { FaInfoCircle } from 'react-icons/fa';
import { StateMetadataRenderer } from '../../../common/StateMetadataRenderer';

export const PurchaseOrderDetails = () => {

    const queryParams = new URLSearchParams(location.search);
    const config = React.useContext(ConfigContext) as PurchaseOrderDetailsConfig;
    const { purchaseOrderUuid } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetailResponse>();
    const [searchName, setSearchName] = useState<string>('');
    const [paymentModalVisibility, setPaymentModalVisibility] = useState<boolean>(false);
    const [statusTrackerVisibility, setStatusTrackerVisibility] = useState<boolean>(false);
    const [selectedStateMetaDatum, setSelectedStateMetaDatum] = useState<StateMetaDatum>();

    const GetPurchaseOrderDetailsTitle = () => {
        if (purchaseOrderDetails?.invoice_no) {
            return <div> {`Invoice Number #${purchaseOrderDetails?.invoice_no}`}
                <Tooltip title={purchaseOrderDetails?.manual ? PurchaseOrderCreateType.CUSTOM : PurchaseOrderCreateType.AGGREGATE}>
                    <FaInfoCircle className={`${style.infoIcon}`} />
                </Tooltip>
            </div>;
        }
        return <></>;
    };

    const getStateMetadata = (state: PurchaseOrderState) => {
        return purchaseOrderDetails?.state_metadata.find(item => PurchaseOrderState.valueOf(item.state) === state);
    };

    const stateTimelineItemMap = new Map<string, OrderStateTimelineDetails>();
    stateTimelineItemMap.set('Ordered', {
        color: undefined,
        icon: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        display: 'Ordered ',
        amountKey: 'ordered_amount',
        state: PurchaseOrderState.GENERATED,
        stateMetadata: getStateMetadata(PurchaseOrderState.GENERATED),
    });
    stateTimelineItemMap.set('Accepted', {
        color: 'green',
        icon: undefined,
        display: 'Accepted ',
        amountKey: 'accepted_amount',
        state: PurchaseOrderState.GENERATED,
        stateMetadata: getStateMetadata(PurchaseOrderState.GENERATED),
    });
    stateTimelineItemMap.set('Dispatched', {
        color: undefined,
        icon: undefined,
        display: 'Dispatched ',
        amountKey: 'dispatched_amount',
        state: PurchaseOrderState.DISPATCHED,
        stateMetadata: getStateMetadata(PurchaseOrderState.DISPATCHED),
    });
    stateTimelineItemMap.set('Received', {
        color: '#00CCFF',
        icon: <SmileOutlined style={{ fontSize: '16px' }} />,
        display: 'Received ',
        amountKey: 'received_amount',
        state: PurchaseOrderState.HUB_RECEIVED,
        stateMetadata: getStateMetadata(PurchaseOrderState.HUB_RECEIVED),
    });


    const updateAvailableItemQuantity = (purchaseOrder: PurchaseOrderDetailResponse) => {
        purchaseOrder.items.forEach(eachItem => eachItem.availableQuantity = eachItem.quantity);
        setPurchaseOrderDetails(purchaseOrder);
    };

    const getPurchaseOrderDetails = () => {
        setLoading(true);

        const endpoint = PURCHASE_ORDER_DETAILS(purchaseOrderUuid);

        const responseHandler: ResponseHandler<PurchaseOrderDetailResponse> = {
            onResponseSuccess: (response: PurchaseOrderDetailResponse) => {
                setLoading(false);
                updateAvailableItemQuantity(response);
            },
            onResponseFailed: (): void => {
                setLoading(false);
            },
            onResponseError: (): void => {
                setLoading(false);
            },
        };
        getApiCall(endpoint, responseHandler);
    };

    const PurchaseOrderDownloadables = () => {
        const downloadPurchaseOrderDetailsInCsv = async () => {
            setLoading(true);
            const responseHandler: ResponseHandler<any> = {
                onResponseSuccess(data: any): void {
                    if (data) {
                        download(
                            new Blob([data]),
                            `purchase-order-${purchaseOrderDetails?.invoice_no}.csv`,
                            'application/csv',
                        );
                    }
                    setLoading(false);
                },
                onResponseFailed(): void {
                    setLoading(false);
                },
                onResponseError(): void {
                    setLoading(false);
                },
            };

            await getApiCall(
                DOWNLOAD_PURCHASE_ORDER_DETAILS(purchaseOrderUuid!),
                responseHandler,
                {},
                { responseType: 'blob' },
            );
        };

        const downloadPurchaseOrderInvoice = async () => {
            setLoading(true);
            const responseHandler: ResponseHandler<any> = {
                onResponseSuccess(data: any): void {
                    if (data) {
                        download(
                            new Blob([data]),
                            `purchase-order-${purchaseOrderDetails?.invoice_no}.pdf`,
                            'application/pdf',
                        );
                    }
                    setLoading(false);
                },
                onResponseFailed(): void {
                    setLoading(false);
                },
                onResponseError(): void {
                    setLoading(false);
                },
            };

            await getApiCall(
                DOWNLOAD_PURCHASE_INVOICE(purchaseOrderUuid!),
                responseHandler,
                {},
                { responseType: 'blob' },
            );
        };


        return <Row className={`w-full ${layout.flex.end}`} gutter={[12, 12]}>
            <Col className={'w-full sm:w-full xl:w-1/2'}>
                <Tooltip title="Downloads a csv report">
                    <Button
                        className={`${layout.flex.center} ${style.meraaiPlainButton}`}
                        onClick={downloadPurchaseOrderDetailsInCsv}
                    >
                        <div className={`${layout.flex.center}`}>
                            <TbDownload />
                            <label className='whitespace-pre-line text-xs xs:text-sm px-1 font-semibold'>Report</label>
                        </div>
                    </Button>
                </Tooltip>
            </Col>
            <Col className={'w-full sm:w-full xl:w-1/2'}>
                <Tooltip title="Downloads a pdf invoice">
                    <Button
                        className={`${layout.flex.center} ${style.meraaiOrangeButton}`}
                        onClick={downloadPurchaseOrderInvoice}
                    >
                        <div className={`${layout.flex.center}`}>
                            <TbDownload />
                            <label className='whitespace-pre-line text-xs xs:text-sm px-1 font-semibold'>Invoice</label>
                        </div>
                    </Button>
                </Tooltip>
            </Col>
        </Row>;
    };


    const getOrderDateTime = (state: PurchaseOrderState, format?: 'DATETIME' | 'DATE' | 'TIME') => {

        const timestamp = purchaseOrderDetails?.state_metadata.filter(eachItem => PurchaseOrderState.valueOf(eachItem.state) === state)?.map((item) => item.timestamp);

        if (!isArrayEmpty(timestamp)) {
            return getISTFromEpoch(timestamp?.at(0)!, format);
        } else {
            return `${'Order is not yet '} ${state.display}`;
        }

    };

    // Establishment Details
    const EstablishmentDetails = () => {
        const detailsCardData: DetailsCardProps[] = [
            {
                title: 'Hub details',
                data: [{
                    label: 'Name',
                    value: purchaseOrderDetails?.hub.name,
                },
                {
                    label: 'Address',
                    value: purchaseOrderDetails?.hub.address,
                },
                {
                    label: 'Contact',
                    value: getContactInfo(purchaseOrderDetails?.hub!),
                }],
                divider: true,
            },
            {
                title: 'Seller Hub Details',
                data: [{
                    label: 'Name',
                    value: purchaseOrderDetails?.establishment.name,
                },
                {
                    label: 'Address',
                    value: purchaseOrderDetails?.establishment.address,
                },
                {
                    label: 'Contact',
                    value: getContactInfo(purchaseOrderDetails?.establishment!) || 'Not found',
                }],
            },
        ];

        return <>
            {
                detailsCardData?.map((datum, index) => <DetailsCard
                    key={index}
                    title={
                        <label className='text-xl font-semibold'>
                            {datum.title}
                        </label>
                    }
                    data={
                        datum?.data?.map((cardDatum) => {
                            return {
                                label: <label className='text-meraai-grey'>{cardDatum.label}</label>,

                                value: <label className='font-semibold'>
                                    {cardDatum.value}
                                </label>,
                            };
                        })
                    }
                    divider={datum.divider}

                />)
            }
        </>;
    };

    // Purchase Order Details Item List
    const PurchaseOrderItemsTable = () => {
        const purchaseOrderItemColumns: MeraaiTableColumnType[] = [
            {
                title: 'Image',
                dataIndex: 'image_url',
                render: image => <Image width={30} height={30} src={image} />,
            },
            {
                title: 'Name',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                width: 200,
                render: (_, record: PurchaseOrderItem) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
            },
            {
                title: 'Ordered QTY',
                dataIndex: 'ordered_quantity',
                rowVisibility: [Device.DESKTOP],
                render: ordered_quantity => <Quantity quantity={ordered_quantity} />,
            },
            {
                title: 'Accepted QTY',
                dataIndex: 'accepted_quantity',
                render: accepted_quantity => <Quantity quantity={accepted_quantity} />,
            },
            {
                title: 'Dispatched QTY',
                dataIndex: 'dispatched_quantity',
                render: dispatched_quantity => <Quantity quantity={dispatched_quantity} />,
            },
            {
                title: 'Received QTY',
                dataIndex: 'received_quantity',
                render: received_quantity => <Quantity quantity={received_quantity} />,
            },
            {
                title: 'Carton(s)',
                rowVisibility: [Device.DESKTOP],
                render: (_, item: PurchaseOrderItem) => <CartonRenderer quantity={item.quantity} orderQuantityMultiple={item.order_quantity_multiple} />,
            },
            {
                title: 'Total Price',
                dataIndex: 'total_amount',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                render: amount => <Currency value={amount} />,
            },
        ];

        const getItemsColumns = () => {
            return purchaseOrderItemColumns.filter((eachItem) => purchaseOrderDetails && !PurchaseOrderState.valueOf(purchaseOrderDetails.state)?.excludedDataIndicesForItemsTable?.includes(eachItem.dataIndex?.toString()!));
        };

        return <Card title='Items summary'>
            <div className='max-h-[80vh] overflow-auto'>
                <MeraaiTable
                    columns={getItemsColumns()}
                    dataSource={addKeyToDataList(purchaseOrderDetails?.items!)}
                    pagination={false}
                    searchFilter={{
                        value: searchName,
                        hideSearchButton: true,
                        searchPlaceholder: 'Search name',
                        setSearchText: setSearchName,
                    }}
                />
            </div>
        </Card>;
    };

    // Brand wise quantity
    const BrandWiseQuantity = () => {

        const getBrandWithQuantities = (): BrandsAggregateQunatity[] => {
            const brandWithQuantities = purchaseOrderDetails?.items.reduce((brandItemQuantity, { brand_name: brand, quantity: quantity }) => {
                if (!brandItemQuantity[brand]) {
                    brandItemQuantity[brand] = 0;
                }
                brandItemQuantity[brand] += quantity;
                return brandItemQuantity;
            }, {});
            const aggregateOrderDetailsData = brandWithQuantities && Object.entries(brandWithQuantities)?.map(([key, value], index): BrandsAggregateQunatity => {
                return {
                    key: index,
                    brand_name: key,
                    quantity: value,
                };
            });

            return aggregateOrderDetailsData!;
        };

        const brandWiseQuantity =
        {
            title: 'Brand Quantity',
            data: getBrandWithQuantities()?.map((eachItem) => {
                return {
                    label: eachItem.brand_name,
                    value: <Quantity quantity={eachItem.quantity} />,
                };
            }),
        };

        return <Card>
            <DetailsCard
                title={
                    <label className='text-xl font-semibold'>
                        {brandWiseQuantity.title}
                    </label>
                }
                data={
                    brandWiseQuantity.data?.map((datum) => {
                        return {
                            label: <label className='text-meraai-grey'>{datum.label}</label>,

                            value: <label className='font-semibold'>
                                {datum.value}
                            </label>,
                        };
                    })
                }
            />
        </Card>;
    };

    // Purchase order tracking history 
    const PurchaseOrderTrackingHistory = () => {
        return <Card title='Order tracking history'>
            <Timeline>
                {
                    Array.from(stateTimelineItemMap.values())?.map((timelineItem, index) => {
                        return purchaseOrderDetails && purchaseOrderDetails[timelineItem.amountKey] && <Timeline.Item key={index} color='green'>
                            <Row>
                                {/* State and Date Info*/}
                                <Col className='w-1/2 sm:w-1/3 px-3'>
                                    <Col className='w-full text-sm'>
                                        {getOrderDateTime(timelineItem.state, 'DATE')}
                                    </Col>
                                    <Col className='w-full text-md font-semibold'>

                                        {
                                            isArrayEmpty(timelineItem.stateMetadata?.data) ?
                                                timelineItem.display
                                                :
                                                <div className='flex items-center'>
                                                    <label>{timelineItem.display}</label>
                                                    <Tooltip
                                                        placement="top"
                                                        arrowPointAtCenter
                                                        title={timelineItem.stateMetadata?.data?.map((item, itemIndex) =>
                                                            <>
                                                                <label key={itemIndex}>{`${itemIndex + 1}) ${item.display_name}`}</label>
                                                                <br />
                                                            </>,
                                                        )}
                                                    >
                                                        <AiOutlineDown
                                                            className='cursor-pointer mx-3'
                                                            onClick={() => {
                                                                setSelectedStateMetaDatum(timelineItem.state);
                                                                setStatusTrackerVisibility(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>

                                        }

                                    </Col>
                                </Col>

                                {/* Amount Info */}
                                <Col className='sm:w-1/3 px-3 hidden sm:block'>
                                    <Col className='w-full text-md font-semibold items-center flex'>
                                        {
                                            timelineItem.amountKey ?
                                                <Currency value={purchaseOrderDetails[timelineItem.amountKey]} />
                                                :
                                                <BsDash />
                                        }
                                    </Col>
                                </Col>

                                {/* Time & status tracker info*/}
                                <Col className='w-1/2 sm:w-1/3 px-3'>
                                    <Col className={`w-full text-sm font-medium ${layout.flex.end}`} >
                                        {getOrderDateTime(timelineItem.state, 'TIME')}
                                    </Col>
                                </Col>

                            </Row>

                        </Timeline.Item>;
                    })!
                }
            </Timeline>
        </Card>;
    };

    const Tags = () => {
        return (
            <Row className='py-1'>
                <Col>
                    {
                        purchaseOrderDetails?.state ?
                            <Tag className='px-5 py-1' color={PurchaseOrderState.valueOf(purchaseOrderDetails.state!)?.tagColor}
                            >
                                {PurchaseOrderState.valueOf(purchaseOrderDetails.state!)?.display}
                            </Tag>
                            : <></>
                    }
                </Col>

            </Row>
        );
    };

    const PaymentDetails = (): JSX.Element => {

        return purchaseOrderDetails?.payment_status_metadata ?
            <Card className='px-6' title='Payment details'>
                <StateMetadataRenderer stateMetadata={purchaseOrderDetails.payment_status_metadata} />
            </Card> : <></>;
    };

    useEffect(() => {
        getPurchaseOrderDetails();
    }, []);

    return (
        <>
            <DetailsPage
                loading={loading}
                header={
                    purchaseOrderDetails ?
                        <Row gutter={[12, 12]}>
                            <Col className={'w-full xs:w-2/3 sm:w-3/4'}>
                                <PageBreadcrumbHeader items={['Purchase Orders', <GetPurchaseOrderDetailsTitle />]} />
                                <Tags />
                            </Col>
                            <Col className={`w-full xs:w-1/3 sm:w-1/4  ${layout.flex.end}`}>
                                <PurchaseOrderDownloadables />
                            </Col>
                        </Row>
                        :
                        <Skeleton active paragraph={{ rows: 2 }} />

                }

                body={
                    {
                        left: <>
                            {
                                purchaseOrderDetails ?
                                    <>
                                        <PurchaseOrderItemsTable />
                                        <BrandWiseQuantity />
                                        <PaymentDetails />
                                        <PurchaseOrderTrackingHistory />
                                    </>
                                    :
                                    <Skeleton className='p-10' active paragraph={{ rows: 10 }} />
                            } </>,
                        right: <>
                            {
                                purchaseOrderDetails ?
                                    <EstablishmentDetails />
                                    :
                                    <Skeleton className='p-10' active paragraph={{ rows: 10 }} />
                            }


                        </>,
                    }
                }

                footer={
                    <>
                        {
                            config.paymentConfig?.allowMakePayment &&
                                queryParams.get('make-payment') == 'true' &&
                                PaymentStatus.valueOf(purchaseOrderDetails?.payment_status!)?.isPaymentAllowed ?

                                <div className={`p-3 ${layout.flex.end}`}>
                                    <Button
                                        className={`w-max  ${style.meraaiOrangeButton}`}
                                        onClick={() => {
                                            setPaymentModalVisibility(true);
                                        }}>
                                        Make payment
                                    </Button>
                                </div>

                                :

                                <PurchaseOrderStateChangeActionables
                                    config={config}
                                    purchaseOrderDetails={purchaseOrderDetails!}
                                    setPurchaseOrderDetails={setPurchaseOrderDetails}
                                    purchaseOrderUuid={purchaseOrderUuid!}
                                    updateAvailableItemQuantity={updateAvailableItemQuantity}
                                />
                        }
                    </>
                }
                footerVisibility={!isArrayEmpty(purchaseOrderDetails?.valid_next_states)}
            />
            <PurchaseOrderPaymentModal
                purchaseOrderUuid={purchaseOrderUuid!}
                purchaseOrderDetails={purchaseOrderDetails}
                setPurchaseOrderDetails={setPurchaseOrderDetails}
                paymentModalVisibility={paymentModalVisibility}
                setPaymentModalVisibility={setPaymentModalVisibility}
            />

            <Drawer
                className='meraai-drawer'
                title='Status Tracker'
                open={statusTrackerVisibility}
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    setStatusTrackerVisibility(false);
                }} />}
                closable={false}
                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    onClick={() => {
                                        setStatusTrackerVisibility(false);
                                    }}
                                >
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                {
                    <StateMetadataRendererV2 stateMetadata={purchaseOrderDetails?.state_metadata!} defaultVisibleKey={selectedStateMetaDatum?.state!} />
                }
            </Drawer>
        </>
    );
};