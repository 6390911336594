import { Field } from '../../../../../types/Field';
import { ImageFile } from '../../../../common/ImageRenderer';

export class UserProperties {
    firstName?: Field<string>;

    lastName?: Field<string>;

    mobileNumber?: Field<number>;

    userImage?: Field<ImageFile>;

    constructor(userProperties: UserProperties) {
        this.firstName = new Field(userProperties.firstName);
        this.lastName = new Field(userProperties.lastName);
        this.mobileNumber = new Field(userProperties.mobileNumber);
        this.userImage = new Field(userProperties.userImage);
    }

    public setValues?(establishment: EstablishmentResponse) {
        this.firstName?.setInitialValue?.(establishment.agent_first_name);
        this.lastName?.setInitialValue?.(establishment.agent_last_name);
        this.mobileNumber?.setInitialValue?.(establishment.mobile_number);
        this.userImage?.setInitialValue?.({ url: establishment.agent_image });
    }
}