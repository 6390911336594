import { AutoComplete, Button, Col, Drawer, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { GET_USER_DETAILS, POST_CREATE_USER, UPDATE_USER_DETAILS } from '../../../http/EndPoints';
import { getApiCall, postApiCall, putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { Role } from '../../../types/Role';
import { RoleDataMap } from '../../../types/RoleData';
import { EstablishmentsDropDown, EstablishmentSelectionMode } from '../../common/establishments/EstablishmentsDropDown';
import { PathConfigData } from '../../config/PathConfigService';
import { UserListingConfig } from '../../config/types/ConfigTypes';
import { ModifyUserProps, CreateUserResponse, ModifyUserProperties, ModifyUserMode } from './types';
import { REGEX } from '../../../Constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { countryCodeList, handleEmailAutoComplete } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../router/Path';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../styles/css/style';
import { ResponseDrawer } from '../../common/templates/responseDrawer';
import { UserProfileDetails } from '../profile/types';
import lodash from 'lodash';

export const ModifyUser = (props: ModifyUserProps) => {

    // States
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [establishmentUuids, setEstablishmentUuids] = useState<string[]>();
    const [userDetails, setUserDetails] = useState<CreateUserResponse>();
    const [selectedRole, setSelectedRole] = useState<Role>();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string[]>([]);
    const [responseDrawerVisibility, setResponseDrawerVisibility] = useState<boolean>(false);

    const [userProfileDetails, setUserProfleDetails] = useState<UserProfileDetails>();

    let roleConfigDetails = new PathConfigData(selectedRole!).getConfigForPath(Path.USER_LISTING) as UserListingConfig;

    // Create user {{ API CALL }} 
    const createUser = (value) => {

        setLoading(true);
        const responseHandler: ResponseHandler<CreateUserResponse> = {
            onResponseSuccess(response: CreateUserResponse): void {
                setLoading(false);
                setUserDetails(response);
                setResponseDrawerVisibility(true);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        postApiCall(POST_CREATE_USER, value, responseHandler);
    };

    function closeDrawerAndResetComponent() {
        form.resetFields();
        props.setModifyUserDrawerVisibility(false);
        setEstablishmentUuids([]);
        setSelectedRole(undefined);
        setResponseDrawerVisibility(false);
        if (props.setUserListRequested) {
            props.setUserListRequested(true);
        }
    }

    // Get user details {{ API CALL }}
    const getUserDetails = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<UserProfileDetails> = {
            onResponseSuccess(response: UserProfileDetails): void {
                setUserProfleDetails(response);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        getApiCall(GET_USER_DETAILS(props.username), responseHandler);
    };

    // Edit/Update user details {{ API CALL }} 
    const saveUserDetails = (value: UserProfileDetails) => {
        setLoading(true);

        /**
         * If you make any optional input field empty/blank then form will pass its value as null.
         * Bcz our backend doesn't update null values into the tables.
         * We are converting null values into empty string.
         * */
        Object.keys(value).forEach(key => {
            if (value[key] === null) {
                value[key] = '';
            }
        });

        const responseHandler: ResponseHandler<UserProfileDetails> = {
            onResponseSuccess(response: UserProfileDetails): void {
                setUserProfleDetails({ ...response });
                setLoading(false);
                setResponseDrawerVisibility(true);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        putApiCall(UPDATE_USER_DETAILS(props.username), value, responseHandler);

    };

    const handleSubmit = (value) => {
        if (ModifyUserProperties[props.mode].key === ModifyUserMode.CREATE) {
            createUser(value);
        } else {
            saveUserDetails(value);
        }
    };

    const getResponseDrawerType = () => {
        if (ModifyUserProperties[props.mode].key === ModifyUserMode.CREATE && !userDetails?.new_user) {
            return 'WARNING';
        } else {
            return 'SUCCESS';
        }
    };

    const getResponseDrawerMsg = (): string => {
        if (userDetails?.message) {
            return userDetails?.message;
        } else if (ModifyUserProperties[props.mode].key === ModifyUserMode.EDIT) {
            return 'User has been updated';
        } else {
            return '';
        }
    };

    useEffect(() => {
        if (props.modifyUserDrawerVisibility) {
            form.setFieldsValue({ role: props.selectedRole });
            setSelectedRole(props.selectedRole);
            if (props.chosenEstablishment) {
                setEstablishmentUuids([props.chosenEstablishment?.uuid!]);
            }
            //`props.mode`: Only Edit mode has the access to fetch the user details 
            if (ModifyUserProperties[props.mode].getUserDetails) {
                if (props.userProfileDetail) {
                    setUserProfleDetails(props.userProfileDetail);
                } else {
                    getUserDetails();
                }
            }
        }

    }, [props.modifyUserDrawerVisibility]);

    useEffect(() => {

        if (userProfileDetails) {
            form.setFieldsValue({
                first_name: userProfileDetails.first_name,
                last_name: userProfileDetails.last_name,
                mobile_number: userProfileDetails.mobile_number,
                email: userProfileDetails.email,
            });
        }
    }, [userProfileDetails]);

    return (
        <>
            <Drawer
                className='meraai-drawer'
                open={props.modifyUserDrawerVisibility}
                title={<h3>{ModifyUserProperties[props.mode].drawerTitle}</h3>}
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    props.setModifyUserDrawerVisibility(false);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[24, 24]} className={`${layout.flex.end}`}>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={() => {
                                        props.setModifyUserDrawerVisibility(false);
                                    }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    htmlType='submit'
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={loading}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                >
                                    {ModifyUserProperties[props.mode].buttonText}
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <>
                    <Spin spinning={loading} tip='Please wait, Your device is not a superman!'>
                        <Form
                            form={form}
                            name='userForm'
                            onFinish={handleSubmit}
                            scrollToFirstError
                            layout='vertical'
                        >

                            {/* Name */}
                            <Form.Item
                                required
                                label={<label className='font-semibold'>Name</label>}
                            >
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item
                                            className='m-0 '
                                            name="first_name"
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        return new Promise((resolve, reject) => {
                                                            if (!value) {
                                                                reject('Please enter first name.');
                                                            }
                                                            if (REGEX.USER_NAME.test(value)) {
                                                                resolve(true);
                                                            } else {
                                                                reject('Please enter a valid first name.');
                                                            }
                                                        });
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input className='h-[40px]' placeholder="First name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            className='m-0'
                                            name="last_name"
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        return new Promise((resolve, reject) => {
                                                            if (!value) {
                                                                reject('Please enter last name.');
                                                            }
                                                            if (REGEX.USER_NAME.test(value)) {
                                                                resolve(true);
                                                            } else {
                                                                reject('Please enter a valid last name.');
                                                            }
                                                        });
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input className='h-[40px]' placeholder="Last name" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>

                            {/* Mobile Number */}
                            <Form.Item
                                name="mobile_number"
                                label={<label className='font-semibold'>Mobile</label>}
                                tooltip={{
                                    title: 'It is mandatory to provide either mobile number or email.',
                                    icon: <InfoCircleOutlined />,
                                }}
                                rules={[{
                                    validator(_, value) {
                                        return new Promise((resolve, reject) => {

                                            if (value) {
                                                // Check if the mobile number is valid or not. 
                                                if (REGEX.MOBILE_NUMBER.test(value)) {
                                                    resolve(true);
                                                } else {
                                                    reject('Please enter a valid mobile number.');
                                                }
                                            }
                                            resolve(true);
                                        });
                                    },
                                }]}
                            >
                                <InputNumber
                                    className='h-[40px] w-full'
                                    controls={false}
                                    addonBefore={countryCodeList}
                                    maxLength={10}
                                />
                            </Form.Item>

                            {/* Email */}
                            <Form.Item
                                name="email"
                                label={<label className='font-semibold'>E-mail</label>}
                                tooltip={{
                                    title: 'It is mandatory to provide either mobile number or email.',
                                    icon: <InfoCircleOutlined />,
                                }}
                                rules={[
                                    {
                                        validator(_, value) {
                                            return new Promise((resolve, reject) => {

                                                // Check if both email and mobile numbers are empty. 
                                                if (!value && !form.getFieldValue('mobile_number')) {
                                                    reject('Please enter either your mobile number or e-mail.');
                                                }

                                                // If mobile_number is valid, then email is not required that's why we are resolving it here.
                                                if (!value) {
                                                    resolve(true);
                                                } else {
                                                    if (REGEX.EMAIL.test(value)) {
                                                        resolve(true);
                                                    } else {
                                                        reject('Please enter a valid e-mail.');
                                                    }
                                                }

                                            });
                                        },
                                    },
                                ]}
                            >
                                <AutoComplete
                                    onSearch={(emailValue) => {
                                        setEmail(handleEmailAutoComplete(emailValue));
                                    }}
                                    placeholder="someone@example.com"
                                >
                                    {email.map((emailVal: string) => (
                                        <AutoComplete.Option key={emailVal} value={emailVal}>
                                            {emailVal}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            </Form.Item>

                            {/* Roles */}
                            <Form.Item
                                name="role"
                                label={<label className='font-semibold'>Role</label>}
                                rules={[{ required: true, message: 'Please select role.' }]}
                            >
                                <Select
                                    placeholder="Select Role"
                                    value={selectedRole}
                                    disabled={!ModifyUserProperties[props.mode].modifyRole}
                                    onChange={(role) => {
                                        setSelectedRole(role);
                                    }}
                                >
                                    {
                                        props.accessibleRoles?.map(role => <Select.Option key={RoleDataMap.get(role)?.key} value={RoleDataMap.get(role)?.key} >{RoleDataMap.get(role)?.display}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>


                            {/* Establishment dropdown */}
                            {
                                roleConfigDetails?.primaryEstablishmentType && ModifyUserProperties[props.mode].modifyEstablishment &&

                                <Form.Item
                                    name="establishment_uuids"
                                    label={
                                        <label className='font-semibold'>
                                            {roleConfigDetails.primaryEstablishmentType?.display}
                                        </label>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: `Please select ${roleConfigDetails.primaryEstablishmentType?.display?.toLowerCase()}.`,
                                        },
                                    ]}

                                >
                                    <EstablishmentsDropDown
                                        disabled={lodash.isObject(props.chosenEstablishment)}
                                        setLoading={setLoading}
                                        type={props.chosenEstablishment?.type ?? roleConfigDetails.primaryEstablishmentType}
                                        selectedUuids={establishmentUuids}
                                        setSelectedUuids={setEstablishmentUuids}
                                        selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                                        fetchEstablishmentList={true}
                                    />

                                </Form.Item>
                            }
                        </Form>
                    </Spin>
                </>
            </Drawer>

            <ResponseDrawer
                type={getResponseDrawerType()}
                message={getResponseDrawerMsg()}
                open={responseDrawerVisibility}
                onClose={() => {
                    if (getResponseDrawerType() === 'SUCCESS') {
                        closeDrawerAndResetComponent();
                    } else {
                        setResponseDrawerVisibility(false);
                    }
                }}
                body={
                    getResponseDrawerType() === 'SUCCESS' ?
                        <Button
                            className={`m-2 w-1/5 ${style.meraaiOrangeButton}`}
                            onClick={() => {
                                closeDrawerAndResetComponent();
                                navigate(`/users/${userDetails?.username ?? props.username}`, { replace: true });
                            }}
                        >
                            View Profile
                        </Button> : <></>
                }
            />
        </>
    );

};