import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Row, Skeleton, Spin, Switch } from 'antd';
import { PageBreadcrumbHeader } from '../../common/pageBreadcrumbHeader';
import { ListingPage } from '../../common/templates/listingPage';
import { UserProfileDetails } from './types';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { PathConfigData } from '../../config/PathConfigService';
import { UserListingConfig, UserProfileConfig } from '../../config/types/ConfigTypes';
import { Path } from '../../router/Path';
import { GET_USER_DETAILS } from '../../../http/EndPoints';
import { useParams } from 'react-router-dom';
import { Role } from '../../../types/Role';
import { FileIdentifier, FileUploadResponse, upload } from '../../../utilities/UploadFile';
import { RcFile } from 'antd/lib/upload';
import ConfigContext from '../../config/ConfigContext';
import ImageRenderer, { ImageFile, UploaderTypes } from '../../common/ImageRenderer';
import { BiEditAlt } from 'react-icons/bi';
import { TbCameraPlus } from 'react-icons/tb';
import { layout } from '../../../styles/css/style';
import { DetailsCard } from '../../common/detailsCard';
import { MeraaiTab, MeraaiTabs } from '../../common/tabs/MeraaiTabs';
import { EstablishmentType } from '../../../types/EstablishmentType';
import UserEstablishmentDetails from './establishment';
import { isArrayEmpty } from '../../../Utils';
import { RoleDataMap } from '../../../types/RoleData';
import { DEFAULT_USER_PROFILE } from './Constant';
import { updateUserProfilePictureOrStatus } from '../utils';
import { ModifyUser } from '../createAndUpdate';
import { ModifyUserMode } from '../createAndUpdate/types';
import { MdModeEdit } from 'react-icons/md';

export const UserProfile = () => {
    const { username } = useParams();
    const config = React.useContext(ConfigContext) as UserProfileConfig;

    const [loading, setLoading] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<UserProfileDetails>();
    const [userActive, setUserActive] = useState<boolean>();
    const [roleConfig, setRoleConfig] = useState<UserListingConfig>();
    const [selectedImageFile, setSelectedImageFile] = useState<RcFile>();
    const [modifyUserDrawerVisibility, setModifyUserDrawerVisibility] = useState<boolean>(false);
    const [userListRequested, setUserListRequested] = useState<boolean>(true);

    const [tabs, setTabs] = useState<MeraaiTab[]>([]);

    const generateTabs = () => {
        if (!userDetails) {
            return;
        }

        let tempTabs: MeraaiTab[] = [];
        if (roleConfig && roleConfig.associatedEstablishmentTypes) {
            roleConfig.associatedEstablishmentTypes.forEach((establishment: EstablishmentType) => {

                tempTabs.push({
                    key: establishment.type!,
                    name: establishment.display,
                    accessible: true,

                    component: active =>

                        <UserEstablishmentDetails
                            config={config}
                            active={active}
                            establishmentType={establishment}
                            username={username}
                            setLoading={setLoading}
                            userDetails={userDetails}
                        />,
                });
            });
        }
        setTabs(tempTabs);
    };

    const getUserDetails = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<UserProfileDetails> = {
            onResponseSuccess(response: UserProfileDetails): void {
                setUserDetails(response);
                setUserActive(response.active);
                setLoading(false);
                const pageConfig: PathConfigData = new PathConfigData(Role.valueOf(response.role!)!);
                setRoleConfig(pageConfig.getConfigForPath(Path.USER_LISTING) as UserListingConfig);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        getApiCall(GET_USER_DETAILS(username), responseHandler);
    };

    const userProfileUpdateResponseHandler: ResponseHandler<UserProfileDetails> = {
        onResponseSuccess: function (response: UserProfileDetails): void {
            setUserDetails(response);
            setLoading(false);
        },
        onResponseFailed() {
            setLoading(false);
        },
        onResponseError() {
            setLoading(false);
        },
    };

    const uploadProfilePictureAndSetState = () => {

        setLoading(true);

        const responseHandler: ResponseHandler<FileUploadResponse> = {
            onResponseSuccess(response: FileUploadResponse): void {
                setLoading(false);
                setUserDetails({ ...userDetails, image_url: response.url });
                updateUserProfilePictureOrStatus(
                    {
                        username: username,
                        requestBody: { image_url: response.url },
                        responseHandler: userProfileUpdateResponseHandler,
                    });
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        upload('IMAGE', selectedImageFile!, responseHandler, FileIdentifier.PROFILE);
    };

    const userData =
    {
        title: RoleDataMap.get(Role.valueOf(userDetails?.role!)!)?.display,
        data: [{
            label: 'Name',
            value: <>{`${userDetails?.first_name} ${userDetails?.last_name}`}</>,
        },
        {
            label: 'Phone number',
            value: userDetails?.mobile_number ? <>{`${userDetails?.country_code} ${userDetails?.mobile_number}`}</> : undefined,
        },
        {
            label: 'Email',
            value: userDetails?.email,
        }],
        divider: true,
    };

    useEffect(() => {
        generateTabs();
    }, [userDetails]);

    useEffect(() => {
        if (selectedImageFile) {
            uploadProfilePictureAndSetState();
        }
    }, [selectedImageFile]);

    useEffect(() => {
        if (userListRequested) {
            getUserDetails();
        }
        setUserListRequested(false);
    }, [userListRequested]);

    return (
        <Spin spinning={loading} tip='Loading...' className='h-screen'>
            <ListingPage
                breadcrumb={
                    <>
                        <PageBreadcrumbHeader items={[
                            'User',
                            userDetails?.first_name,
                        ]} />
                    </>
                }
                body={
                    userDetails ?
                        <Row gutter={[0, 24]}>
                            <Col className='w-full'>
                                <Card
                                    className='shadow-lg'
                                    bodyStyle={{ height: '100%' }}
                                    hoverable
                                >
                                    <Row className='h-full'>
                                        <Col className={`w-full md:w-1/6 profile-photo ${layout.flexCol.spaceEvenly}`}>

                                            <Image
                                                className='rounded-[50%] w-[150px]'
                                                src={userDetails?.image_url ?? DEFAULT_USER_PROFILE}
                                            />



                                            {
                                                config.editProfile &&

                                                <ImageRenderer
                                                    styleClass='pt-5'
                                                    uploadText={userDetails?.image_url ? 'Change' : 'Add'}
                                                    uploadIcon={userDetails?.image_url ? <BiEditAlt /> : <TbCameraPlus />}
                                                    setFileState={(file: ImageFile) => {
                                                        setSelectedImageFile(file.file);
                                                    }}
                                                    type={UploaderTypes.UPLOAD_PNG}
                                                />
                                            }

                                        </Col>
                                        <Col className='w-full md:w-4/6'>
                                            <div className='w-full xl:w-4/6'>
                                                <DetailsCard
                                                    title={
                                                        <div className={`w-full ${layout.flex.center} md:flex md:justify-start`}>
                                                            <label className='text-meraai-orange rounded-lg py-2 px-3 text-lg font-medium border border-solid border-meraai-orange'>
                                                                {userData?.title}
                                                            </label>

                                                            <MdModeEdit className='text-xl mx-3 text-meraai-orange' onClick={() => {
                                                                setModifyUserDrawerVisibility(true);
                                                            }}>
                                                                Edit
                                                            </MdModeEdit>
                                                        </div>
                                                    }
                                                    data={
                                                        userData.data.filter(datum => datum.value).map((datum) => {
                                                            return {
                                                                label: <label className='text-meraai-grey font-semibold text-lg'>{datum.label}</label>,

                                                                value: <label className='text-lg font-semibold'>
                                                                    {datum.value}
                                                                </label>,
                                                            };
                                                        })
                                                    }
                                                />

                                            </div>

                                        </Col>
                                        <Col className={`w-full md:w-1/6 ${layout.flexCol.start}`}>
                                            {
                                                config.editProfile && <Switch
                                                    className='bg-meraai-orange'
                                                    checkedChildren={<label className='font-semibold text-sm'>Active</label>}
                                                    unCheckedChildren={<label className='font-semibold text-sm'>Inactive</label>}
                                                    checked={userActive}
                                                    onChange={(checked: boolean) => {
                                                        setUserActive(checked);
                                                        updateUserProfilePictureOrStatus(
                                                            {
                                                                username: username,
                                                                requestBody: { active: checked },
                                                                responseHandler: userProfileUpdateResponseHandler,
                                                            });
                                                    }}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            {
                                !isArrayEmpty(tabs) ? <Col className='w-full'>
                                    <Card hoverable className='shadow-lg h-full'>
                                        <MeraaiTabs
                                            tabs={tabs}
                                            hideDefaultStyle={true}
                                        />
                                    </Card>
                                </Col> : <></>
                            }

                        </Row> : <Skeleton active paragraph={{ rows: 8 }} />
                }
            />
            {/* Edit User */}
            <ModifyUser
                userProfileDetail={userDetails}
                mode={ModifyUserMode.EDIT}
                username={username}
                setUserListRequested={setUserListRequested}
                selectedRole={Role.valueOf(userDetails?.role!)}
                modifyUserDrawerVisibility={modifyUserDrawerVisibility}
                setModifyUserDrawerVisibility={setModifyUserDrawerVisibility}
            />
        </Spin>
    );
};