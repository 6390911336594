import { Button, Col, Drawer, Form, Input, InputNumber, Row } from 'antd';
import React, { useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../../../../../styles/css/style';
import { ProductMetadataDrawerProps } from './type';

export const ProductMetadataDrawer = (props: ProductMetadataDrawerProps) => {

    const { onProceed, productMetadata, ...restProsp } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.open && productMetadata) {
            form.setFieldsValue(productMetadata);
        } else {
            form.resetFields();
        }
    }, [props.open]);

    return (
        <>
            <Drawer
                {...restProsp}
                className='meraai-drawer'
                title={<h3>Metadata</h3>}
                extra={<AiOutlineClose className='cursor-pointer' onClick={(event) => {
                    if (props.onClose) {
                        props.onClose(event);
                    }
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                        <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                            <Button
                                className={`w-full ${style.meraaiPlainButton}`}
                                onClick={(event) => {
                                    if (props.onClose) {
                                        props.onClose(event);
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                            <Button
                                htmlType='submit'
                                className={`w-full ${style.meraaiOrangeButton}`}
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                Proceed
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    form={form}
                    name='userForm'
                    onFinish={onProceed}
                    layout='vertical'
                >
                    {/* App display label */}

                    <Form.Item
                        label={<label className='font-semibold'>App display label</label>}
                        name="app_display_label"
                    >
                        <Input className='h-[40px]' placeholder="Ex. 100gms, 10% off" />
                    </Form.Item>


                    {/* Days until returnable */}

                    <Form.Item
                        label={<label className='font-semibold'>Days until returnable</label>}
                        name="days_until_returnable"
                    >
                        <InputNumber
                            id='days-until-returable'
                            addonAfter={'day(s)'}
                            controls={false}
                            className={`${layout.flex.center} h-[40px] w-full`}
                            placeholder="Ex. 30, 90"
                            min={0}
                            max={365}
                        />
                    </Form.Item>

                </Form>
            </Drawer>
        </>
    );
}; 