import { Button, Col, Empty, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BRAND_LIST } from '../../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../../http/HttpClient';
import { areTwoArraysEqual, isArrayEmpty } from '../../../../../../Utils';
import { SearchInput } from '../../../../../common/SearchInput';
import { Brand, BrandListResponse } from '../../../../../orders/listing/ListingData';
import { CreateBrand } from '../create';
import { UpdateBrand } from '../update';
import { BrandCard } from './BrandCard';
import { BrandStatusType, LocationState } from './type';

export const BrandList = () => {

    const { establishmentUuid } = useParams();
    const location = useLocation();

    // Brand list states
    const [page, setPage] = useState<number>(0);
    const [searchName, setSearchName] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [previousBrandStatus, setPreviousBrandStatus] = useState<boolean[]>([true]);
    const [brandStatus, setBrandStatus] = useState<boolean[]>([true]);
    const [focus, setFocus] = useState<boolean>(false);
    const [brandList, setBrandList] = useState<BrandListResponse>();
    const [brandListRequested, setBrandListRequested] = useState<boolean>(true);

    const state = location.state as LocationState;

    // Create brand states
    const [createBrandModalVisibility, setCreateBrandModalVisibility] = useState<boolean>(false);

    // Update brand states
    const [updateBrandModalVisibility, setUpdateBrandModalVisibility] = useState<boolean>(false);
    const [selectedBrand, setSelectedBrand] = useState<Brand>();


    // Methods
    const getBrandStatus = (): boolean | undefined => {
        if (!brandStatus) {
            return undefined;
        }

        if (brandStatus.length === (Object.keys(BrandStatusType).length || 0)) {
            return undefined;
        }

        return brandStatus[0];
    };

    const getBrandList = () => {

        setLoading(true);

        const responseHandler: ResponseHandler<BrandListResponse> = {
            onResponseSuccess(response: BrandListResponse): void {
                if (page === 0) {
                    setBrandList(response);
                } else {
                    const updatedBrandList = brandList?.brands.concat(response.brands);
                    setBrandList({ ...brandList!, brands: updatedBrandList!, next_page: response.next_page });
                }
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        getApiCall(BRAND_LIST(establishmentUuid!, searchName!, getBrandStatus(), page, 6), responseHandler);
    };


    const brandNameSearch = {
        value: searchName,
        searchButtonOnClick: () => {
            setPage(0);
            setBrandListRequested(true);
        },
        setSearchText: setSearchName,
        searchPlaceholder: 'Brand Name',
    };


    useEffect(() => {
        if (brandListRequested) {
            setBrandListRequested(false);
            getBrandList();
        }
    }, [brandListRequested]);

    useEffect(() => {
        if (state?.createBrand) {
            setCreateBrandModalVisibility(true);
        }
    }, []);

    // Update brand useEffect 
    useEffect(() => {
        if (selectedBrand) {
            setUpdateBrandModalVisibility(true);
        }
    }, [selectedBrand]);

    return (
        <>
            <Spin spinning={loading} tip='Loading...'>
                <Row gutter={[24, 24]} className='main-body-filters'>

                    <Col xs={24} md={5}>
                        <SearchInput {...brandNameSearch} />
                    </Col>
                    <Col xs={24} md={5}>
                        <Select
                            placeholder="Please select status"
                            style={{ width: '100%' }}
                            mode="multiple"
                            showArrow
                            showSearch={false}
                            value={brandStatus}
                            onBlur={() => {
                                setFocus(false);
                                if (!areTwoArraysEqual(brandStatus, previousBrandStatus)) {
                                    setPreviousBrandStatus(brandStatus);
                                    setBrandListRequested(true);
                                }
                            }}
                            onFocus={() => {
                                setFocus(true);
                            }}
                            onChange={(newValue: boolean[]) => {
                                setBrandStatus(newValue);
                                if (!focus) {
                                    setPreviousBrandStatus(newValue);
                                    setBrandListRequested(true);
                                }
                            }}
                        >
                            <Select.Option value={true} key={BrandStatusType.ACTIVE}> {BrandStatusType.ACTIVE} </Select.Option>
                            <Select.Option value={false} key={BrandStatusType.INACTIVE}> {BrandStatusType.INACTIVE} </Select.Option>
                        </Select>
                    </Col>
                    <Col xs={0} md={9}></Col>

                    <Col xs={24} md={5} className='flex-center'>

                        <Button
                            style={{ width: '70%' }}
                            className='default-success-button flex-center'
                            size='large'
                            onClick={() => {
                                // Create a new brand
                                setCreateBrandModalVisibility(true);
                            }}
                        >
                            Add a brand
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className='mt-5 flex-justify-center'>
                    {
                        brandList?.brands?.length! > 0 ? brandList?.brands.map((brand: Brand, index) => {
                            return <BrandCard
                                key={index}
                                brand={brand}
                                setSelectedBrand={setSelectedBrand}
                            />;
                        })
                            :
                            <Col xs={24}>
                                <Empty
                                    className="main-body-aggregate-empty"
                                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                                    description='No brands found.'
                                />
                            </Col>
                    }
                </Row>
                {
                    !isArrayEmpty(brandList?.brands) && brandList?.next_page && <Row>
                        <Col xs={24} style={{ marginTop: 25 }}>
                            <div className='flex-center'>
                                <Button
                                    type='primary'
                                    style={{ cursor: 'pointer' }}
                                    loading={loading}
                                    onClick={() => {
                                        setPage(previousPage => previousPage + 1);
                                        setBrandListRequested(true);
                                    }}
                                >
                                    Load more
                                </Button>
                            </div>
                        </Col>
                    </Row>
                }

            </Spin>
            <CreateBrand
                establishmentUuid={establishmentUuid}
                setBrandListRequested={setBrandListRequested}
                createBrandModalVisibility={createBrandModalVisibility}
                setCreateBrandModalVisibility={setCreateBrandModalVisibility}
            />
            <UpdateBrand
                brandList={brandList}
                setBrandList={setBrandList}
                selectedBrand={selectedBrand!}
                setSelectedBrand={setSelectedBrand}
                updateBrandModalVisibility={updateBrandModalVisibility}
                setUpdateBrandModalVisibility={setUpdateBrandModalVisibility}
            />
        </>
    );

};