/* eslint @typescript-eslint/no-use-before-define: 0 */

import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { RcFile } from 'antd/lib/upload';
import { ProductProperties } from '../../../properties/ProductProperties.';
import { initializeCreateProductProperties } from '../utils';
import { Brand } from '../../../../../../../orders/listing/ListingData';
import { OpenNotification } from '../../../../../../../common/notifications/Notifications';
import { CreateProductDetails } from '../../../../../../../../types/Product';
import { areTwoArraysEqual } from '../../../../../../../../Utils';

export const ExcelRendererComponent = (
    fileObj: RcFile,
    dataSource: ProductProperties[],
    setDataSource: React.Dispatch<React.SetStateAction<ProductProperties[]>>,
    setLastRecordKey: React.Dispatch<React.SetStateAction<number>>,
    CSVAcceptableColumns: Array<string | number>,
    setProductFile: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    brandList: Brand[],
) => {
    ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
            OpenNotification("Something's went wrong", err, 'error');
        } else {
            if (dataSource.length > 0) {
                setDataSource([]);
                setLastRecordKey(0);
            }
            const newRows: ProductProperties[] = [];
            const headers = resp.rows.shift();

            if (!IsFileValid(headers)) {
                return false;
            }

            const productRows: CreateProductDetails[] = resp.rows.map((product, index) => {
                if (product && product.length > 0) {
                    const productRow = {} as CreateProductDetails;
                    productRow.id = index;
                    productRow.image = '';

                    product.forEach((value, valueIndex) => {
                        productRow[headers[valueIndex]] = value;
                    });
                    setLastRecordKey(index + 1);
                    return productRow;
                }
            });

            productRows.forEach((row: CreateProductDetails) => {
                if (row) {

                    const brandIndex = brandList.findIndex((brand: Brand) => brand.name.toLowerCase() === row.brand.toLowerCase(),
                    );

                    if (brandIndex === -1) {
                        /**
                         * If the brand name mentioned in the excel file is not matching with any brand of the seller  
                         * then make it blank.
                         * */
                        row.brand = '';
                    } else {
                        row.brand = brandList[brandIndex].name;
                    }
                    const product = initializeCreateProductProperties();
                    product.setValuesForProductCreation!(row);
                    newRows.push(product);
                }
            });
            if (newRows.length === 0) {
                OpenNotification('No data found', 'Please make sure you have data in your excel file.', 'error');
                return false;
            }
            setDataSource(newRows);
            setProductFile([{ name: fileObj.name }]);
        }
    });

    const IsFileValid = (headers: any) => {
        if (!areTwoArraysEqual(headers, CSVAcceptableColumns)) {
            OpenNotification('Format Not Matched', 'Please check the header sequence of your file', 'error');
            return false;
        }
        return true;
    };
};
