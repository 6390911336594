import { Button, Checkbox, Col, InputNumber, List, Modal, Radio, RadioChangeEvent, Row, Spin } from 'antd';
import { FLOW, FLOWOPTIONS, InventoryFlow, InventoryProduct } from '../../types/Inventory';
import { setWidth } from '../../styles/css/react/MeraaiCssProperties';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect } from 'react';
import _ from 'lodash';

interface UpdateInventoryModalProps {
    loading: boolean;
    selectedItem: InventoryProduct | undefined;
    setSelectedItem: React.Dispatch<React.SetStateAction<InventoryProduct>>;
    updateInventoryModalVisibility: boolean;
    setUpdateInventoryModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    updateInventoryProductStock: () => void;
}

export const UpdateInventoryModal = (props: UpdateInventoryModalProps) => {
    const {
        loading,
        selectedItem,
        setSelectedItem,
        updateInventoryModalVisibility: updateProductModalVisibility,
        setUpdateInventoryModalVisibility: setUpdateInventoryModalVisibility,
        updateInventoryProductStock,
    } = props;



    const updateStockInSelectedItem = (updatedStockValue) => {
        selectedItem!.diff_stock = updatedStockValue || 1;
        setSelectedItem(_.cloneDeep(selectedItem!));
    };

    const updateStockFlowInSelectedItem = (updatedStockFlow) => {
        selectedItem!.flow = updatedStockFlow;
        selectedItem!.flowOption = undefined;
        selectedItem!.add_to_free_stock = false;
        setSelectedItem(_.cloneDeep(selectedItem!));
    };

    const updateStockFlowOptionInSelectedItem = (updatedStockFlowOption: FLOWOPTIONS | undefined) => {
        
        selectedItem!.flowOption = updatedStockFlowOption;
        
        if (selectedItem!.flowOption === FLOWOPTIONS.FREE_STOCK) {
            selectedItem!.add_to_free_stock = true;
        } else {
            selectedItem!.add_to_free_stock = false;
        }
        setSelectedItem(_.cloneDeep(selectedItem!));
    };


    /**
     * If the selected item changes, which basically means a new item is selected, or, if there's a change 
     * in the flow, in such cases, flow option will be updated in the item, only if the flow option is checked 
     * by default.
     */
    useEffect(() => {
        InventoryFlow.getStockFlowsOptions(selectedItem?.flow!).forEach(option => {
            if (option.checked) {
                updateStockFlowOptionInSelectedItem(FLOWOPTIONS.valueOf(option.value.toString()));
            }
        });
    }, [selectedItem?.id, selectedItem?.flow]);

    const data = [
        {
            display: <h4>Name :</h4>,
            value: <label>{selectedItem?.name}</label>,
        },
        {
            display: <h4>Available Stock :</h4>,
            value: <label>{selectedItem?.available_stock}</label>,
        },
        {
            display: <h4>Flow :</h4>,
            value: InventoryFlow.getStockFlows().map((flow, index) => {
                return <Radio
                    key={index}
                    onChange={(event: RadioChangeEvent) => {
                        updateStockFlowInSelectedItem(event.target.value);
                    }}
                    defaultChecked={selectedItem?.flow == FLOW.INWARD}
                    checked={flow.value == selectedItem?.flow}
                    value={flow.value}
                >
                    {flow.label()}
                </Radio>;

            }),
        },
        {
            display: <h4>{InventoryFlow[selectedItem?.flow!]?.stockColDisplay} :</h4>,
            value: <InputNumber
                key={selectedItem?.id}
                value={selectedItem?.diff_stock}
                type="number"
                min={1}
                max={1000000}
                className="order-page-form-input-tag"
                placeholder="No. of units."
                onChange={value => {
                    updateStockInSelectedItem(value);
                }}
            />,
        },
        {
            display: <h4>Purpose :</h4>,
            value: InventoryFlow.getStockFlowsOptions(selectedItem?.flow!).map((option, index) => {
                return <Checkbox
                    className='remove-margin'
                    key={index}
                    onChange={(event: CheckboxChangeEvent) => {
                        updateStockFlowOptionInSelectedItem(event.target.checked ? FLOWOPTIONS.valueOf(event.target.value)! : undefined);
                    }}
                    checked={option.value == selectedItem?.flowOption}
                    value={option.value}
                >
                    {option.label(selectedItem?.diff_stock, selectedItem?.name)}
                </Checkbox>;
            }),
        },

    ];


    return (

        <Modal
            title='Update Inventory'
            open={updateProductModalVisibility}
            onCancel={() => setUpdateInventoryModalVisibility(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={
                <div className='flex-center'>
                    <Button
                        className='default-button'
                        onClick={() => {
                            updateInventoryProductStock();
                        }}
                    >
                        Update
                    </Button>
                </div>

            }
        >
            <Spin spinning={loading} size='small' tip='Updating inventory...'>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        !item.value || (Array.isArray(item.value) && item.value.length == 0) ?
                            <></> :
                            <List.Item>
                                <Row style={setWidth('100%')} >
                                    <Col xs={12} className='flex-row-sb-center'>
                                        {item.display}

                                    </Col>
                                    <Col xs={12}>
                                        {item.value}
                                    </Col>

                                </Row>
                            </List.Item>
                    )}
                />
            </Spin>
        </Modal>
    );
};