import { Badge, Button, Col, Empty, Form, Input, message, Row, Spin, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { MdOutlineSort } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { LIST_PRODUCTS_BY_BRAND, LIST_PRODUCTS_BY_NAME, ORDER_DETAILS } from '../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../http/HttpClient';
import { setFontSize, setMargin } from '../../styles/css/react/MeraaiCssProperties';
import { ProductItemType } from '../../types/Cart';
import { EDIT, OrderCreationMode } from '../../types/OrderCreationMode';
import { OrderDetailsResponse } from '../../types/Order';
import PageTopSection from '../common/PageTopSection';
import TabLayout from '../common/TabLayout';
import { Brand, ListProductsResponse, Product } from '../orders/listing/ListingData';
import BrandListingDrawer from './BrandListingDrawer';
import Cart from './Cart';
import { CartItem } from './CartItem';
import { clearEstablishmentData, updateSkuItem } from './CartSlice';
import ProductList from './ProductList';
import { ProductListingType } from '../../types/Product';

const breadcrumb = [{ name: 'list' }, { name: 'Details' }, { name: 'Update' }];
export const PRODUCT_PAGE_SIZE = 20;

export interface CreateOrderProps {
    establishmentUuid?: string;
    mode: OrderCreationMode
}

function getCartItemFromProductItem(establishmentUuid: string, product: Product): CartItem {
    return new CartItem({
        id: product.id,
        sku: product.sku,
        name: product.name,
        quantity: 0,
        mrp: product.maximum_retail_price,
        price_per_unit: product.price,
        image: product.image,
        amount: 0,
        establishment_uuid: establishmentUuid,
    });
}

function CreateOrder(props: CreateOrderProps) {

    const { orderUuid } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Hooks
    const [selectedBrand, setSelectedBrand] = useState<Brand>();
    const [orderDetails, setOrderDetails] = useState<OrderDetailsResponse>();
    const [brandsDrawerVisibility, setBrandsDrawerVisibility] = useState<boolean>(props.mode.defaultBrandsDrawerVisibility);
    const [cartModalVisible, setCartModalVisible] = useState<boolean>(props.mode.defaultCartModalVisibility);
    const [productName, setProductName] = useState<string>('');
    const [productsLoading, setProductsLoading] = useState<boolean>(false);
    const [productsList, setProductsList] = useState<Array<Product>>([]);
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const currentListingType = useRef<ProductListingType>();
    const currentProductName = useRef<string>();
    const [establishmentUuid, setEstablishmentUuid] = useState<string>();


    const cartDataForEstablishment = useAppSelector(state => state.cart.cart[establishmentUuid!]) ?? {
        selectedItems: [],
        selectedItemsCount: 0,
        totalAmount: 0,
        lastUpdatedAt: 0,
    };
    type SelectedItemsAsObjectProperties = {
        [id: number]: CartItem;
    };
    const selectedItemsAsObject: SelectedItemsAsObjectProperties = {};
    cartDataForEstablishment.selectedItems.forEach(item => (selectedItemsAsObject[item.id!] = new CartItem(item)));

    const finalProductList: Array<CartItem> = productsList.map(productItem => {
        if (productItem.id in selectedItemsAsObject) {
            return selectedItemsAsObject[productItem.id];
        }
        return getCartItemFromProductItem(establishmentUuid!, productItem);
    });

    const shouldGetProducts = useRef<boolean>(false);

    const getProducts = async () => {
        if (!shouldGetProducts.current) {
            return;
        }
        setProductsLoading(true);

        const productsListEndPoint = currentListingType.current === ProductListingType.BRAND ? LIST_PRODUCTS_BY_BRAND(
            establishmentUuid!,
            selectedBrand?.id.toString(),
            page - 1,
            PRODUCT_PAGE_SIZE,
        ) : LIST_PRODUCTS_BY_NAME(
            establishmentUuid!,
            selectedBrand?.id.toString(),
            productName,
            page - 1,
            PRODUCT_PAGE_SIZE,
        );

        const responseHandler: ResponseHandler<ListProductsResponse> = {
            onResponseSuccess(value: ListProductsResponse): void {
                setProductsLoading(false);
                if (value.products.length === 0) {
                    message.info('No products found', 3);
                }
                setProductsList(value.products);
                setPageCount(value.page_count);
            },
            onResponseFailed(): void {
                setProductsLoading(false);
            },
            onResponseError(): void {
                setProductsLoading(false);
            },
        };

        await getApiCall(productsListEndPoint, responseHandler);
    };

    const onBrandItemClick = (brand: Brand) => {
        setBrandsDrawerVisibility(false);
        if (selectedBrand?.id != brand.id || productName !== '') {
            currentListingType.current = ProductListingType.BRAND;
            setProductName('');
            shouldGetProducts.current = true;
            setSelectedBrand(brand);
            setPage(1);
        }
    };

    const searchProductsByName = () => {
        setBrandsDrawerVisibility(false);
        currentProductName.current = productName;
        currentListingType.current = ProductListingType.NAME;
        shouldGetProducts.current = true;
        if (page !== 1) {
            setPage(1);
        } else {
            getProducts();
        }
    };

    const updateCartWithOrderedItems = (orderDetail: OrderDetailsResponse) => {

        dispatch(clearEstablishmentData(orderDetail.agent_establishment.uuid));
        orderDetail.items.filter(item => item.deliverable).forEach((item) => {
            let cartItem: CartItem = {
                key: item.product_id,
                id: item.product_id,
                sku: item.sku,
                name: item.name,
                quantity: item.deliverable_quantity,
                mrp: item.maximum_retail_price_per_unit,
                price_per_unit: item.price_per_unit,
                image: item.image,
                amount: item.amount,
                establishment_uuid: orderDetail.agent_establishment.uuid,
            };
            cartItem = new CartItem(cartItem);
            dispatch(
                updateSkuItem({
                    establishmentUuid: cartItem.establishment_uuid!,
                    cartItem: cartItem,
                }),
            );
        });
    };

    const getOrderDetails = async () => {
        setProductsLoading(true);

        const endPointOrderDetails = ORDER_DETAILS(orderUuid!);
        const responseHandler: ResponseHandler<OrderDetailsResponse> = {
            onResponseSuccess(orderDetailsResponse: OrderDetailsResponse): void {
                // Storing the whole object in orderDetails state
                setOrderDetails(orderDetailsResponse);
                setEstablishmentUuid(orderDetailsResponse.agent_establishment.uuid);
                updateCartWithOrderedItems(orderDetailsResponse);

                // Disabled screen loader
                setProductsLoading(false);
            },
            onResponseFailed(): void {
                setProductsLoading(false);
            },
            onResponseError(): void {
                setProductsLoading(false);
            },
        };
        await getApiCall(endPointOrderDetails, responseHandler);
    };

    useEffect(() => {
        getProducts();
    }, [selectedBrand, page]);

    useEffect(() => {
        if (props.mode === EDIT) {
            getOrderDetails();
        } else {
            setEstablishmentUuid(props.establishmentUuid!);
        }
    }, []);

    return (
        <>
            {
                props.mode === EDIT &&
                <PageTopSection
                    firstElement={false}
                    menuList={breadcrumb}
                    pageTitle={
                        <>{'Update Order'} &nbsp;
                            <Tooltip title={'View Order Details'}>
                                <a
                                    style={setFontSize('18px')}
                                    onClick={() => {
                                        navigate(`/order/${orderUuid}`, { replace: true });
                                    }}
                                >
                                    #{orderDetails?.receipt_no}
                                </a>
                            </Tooltip>
                        </>
                    }
                />
            }
            <div className={props.mode === EDIT ? 'main-body' : ''}>
                <TabLayout>
                    <div className="filter-drawer-div" style={setMargin(0, 20, 0, 20)}>
                        {/* Brands and cart buttons */}
                        <Row gutter={[24, 24]} className="flex-end-padding">
                            <Col lg={4}>
                                <Button
                                    icon={<MdOutlineSort color="#7E4D2B" />}
                                    size="middle"
                                    className="secondary-button"
                                    onClick={() => setBrandsDrawerVisibility(true)}>
                                    &nbsp; Brands
                                </Button>
                            </Col>

                            <Col lg={3}>
                                <Badge count={cartDataForEstablishment.selectedItems.length}>
                                    <Button
                                        icon={<FaShoppingCart color="#F69620" />}
                                        size="middle"
                                        className="default-button"
                                        onClick={() => setCartModalVisible(true)}>
                                        &nbsp;&nbsp;Cart
                                    </Button>
                                </Badge>
                            </Col>
                        </Row>

                        {/* Search products section */}
                        <Row>
                            <Col xl={24} xs={24}>
                                <Form
                                    className="create-product-form"
                                    initialValues={{ remember: true }}
                                    onFinish={() => {
                                        searchProductsByName();
                                    }}
                                    onFinishFailed={() => { }}
                                    autoComplete="off">
                                    <Form.Item className="create-product-form-item1">
                                        <Input
                                            className="create-product-form-input-tag"
                                            placeholder="Search products by name"
                                            value={productName}
                                            onChange={event => setProductName(event.currentTarget.value)}
                                        />
                                    </Form.Item>

                                    <Form.Item className="create-product-form-item2">
                                        <Button
                                            className="create-product-form-item2-button"
                                            size="large"
                                            htmlType="submit"
                                            disabled={productName === currentProductName.current}>
                                            Get Products
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                        {/* Listing of all the products that comes form an API */}
                        <Row className="create-order-product-list">
                            <Col xl={24} xs={24}>
                                <Spin spinning={productsLoading}>
                                    {finalProductList.length ? (
                                        <Row className="flex-center">
                                            {selectedBrand && (
                                                <Col>
                                                    <Tag color="cyan" className="flex-align-center" style={{ fontSize: '15px' }}>
                                                        {'Selected brand '} &ensp;
                                                        <b>{selectedBrand?.name} </b> &ensp;
                                                        <Tooltip title="Remove Brand" className="pointer">
                                                            <TiDelete
                                                                style={{ fontSize: '20px' }}
                                                                onClick={() => {
                                                                    setSelectedBrand(undefined);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Tag>
                                                </Col>
                                            )}
                                            <Col>
                                                <ProductList
                                                    rowElementsCount={4}
                                                    elementType={ProductItemType.PAGE_LIST}
                                                    productsListLoading={productsLoading!!}
                                                    listingData={finalProductList}
                                                    pagination={{
                                                        pageSize: PRODUCT_PAGE_SIZE,
                                                        current: page,
                                                        total: pageCount! * PRODUCT_PAGE_SIZE,
                                                        onChange: pageClickedOn => {
                                                            setPage(pageClickedOn);
                                                        },
                                                    }}
                                                    styleClass={props.mode.productListStyleClass}
                                                    height="62vh"
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Empty
                                            className="main-body-aggregate-empty"
                                            image={Empty.PRESENTED_IMAGE_DEFAULT}
                                            description={
                                                productsLoading
                                                    ? 'We are trying our best to get the product(s), please wait...'
                                                    : 'Search product(s)'
                                            }
                                        />
                                    )}
                                </Spin>
                            </Col>
                        </Row>
                        {
                            establishmentUuid && <BrandListingDrawer
                                establishmentUuid={establishmentUuid!}
                                brandsDrawerVisibility={brandsDrawerVisibility}
                                setBrandsDrawerVisibility={setBrandsDrawerVisibility}
                                onBrandItemClick={onBrandItemClick}
                            />
                        }

                        <Cart
                            productsLoading={productsLoading}
                            setOrderDetails={setOrderDetails}
                            orderUuid={orderUuid!}
                            mode={props.mode}
                            establishmentUuid={establishmentUuid!}
                            cartModalVisible={cartModalVisible}
                            setCartModalVisible={setCartModalVisible}
                            setBrandsDrawerVisibility={setBrandsDrawerVisibility}
                        />
                    </div>
                </TabLayout>
            </div>

        </>

    );
}

export default CreateOrder;
