import { InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { postApiCall, ResponseHandler } from '../../../../../../../../http/HttpClient';
import { Device, MeraaiTableColumnType } from '../../../../../../../common/templates/meraaiTable/types';
import { Currency, ProductNameInfo, Quantity } from '../../../../../../../orders/layer/details/hubsAndOutlets/utils';
import { PurchaseProductDetails } from '../../../list/types';
import { CreateReturnOrderRequest, InitiateReturnOrderDrawerProps } from '../../types';
import { CREATE_RETURN_ORDER } from '../../../../../../../../http/EndPoints';
import { useNavigate } from 'react-router-dom';
import { ResponseMessage } from '../../../../../../../../Types';
import { useAppDispatch } from '../../../../../../../../hooks';
import { destroyCart } from '../../../slice';
import { OptionalTitle } from '../../../../../../../common/TableTitle';
import { SelectedProductWithItems } from '../../../slice/type';

export const useInitiateReturnOrder = (props: InitiateReturnOrderDrawerProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // State variables
    const [returnProductList, setReturnProductList] = useState<SelectedProductWithItems[] | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [createReturnOrderResponse, setCreateReturnOrderResponse] = useState<ResponseMessage>();

    const [updateReturnableProducList, setUpdateReturnableProducList] = useState<boolean>();

    // Methods
    const computeTotalAmount = (): number => {
        return props.savedReturnOrders?.totalAmount ?? 0;
    };

    const getProductTotalAmount = (record: PurchaseProductDetails): number => {
        return props.savedReturnOrders?.selectedProducts?.find(product => product.id === record.id)?.productTotalAmount ?? 0;
    };


    const getProductItems = (productId: number) => {
        return props.savedReturnOrders.selectedProducts.find(selectedProduct => selectedProduct.id === productId)?.items;
    };


    const constructRequestBody = (): CreateReturnOrderRequest => {
        return {

            establishment_uuid: props.establishmentUuid,
            products: returnProductList?.map(product => {
                return {
                    id: product.id,
                    quantity: product.returnQuantity ?? 0,
                    items: getProductItems(product.id)?.map(item => {
                        return {
                            order_item_id: item.order_item_id,
                            quantity: item.returnQuantity,
                            price_per_unit: item.price_per_unit,
                        };
                    })!,
                };
            })!,
            total_amount: props.savedReturnOrders.totalAmount,
        };
    };

    const initiateCreateReturnOrder = () => {
        setLoading(true);

        const responseHandler: ResponseHandler<ResponseMessage> = {
            onResponseSuccess(response: ResponseMessage): void {
                setCreateReturnOrderResponse(response);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        postApiCall(CREATE_RETURN_ORDER, constructRequestBody(), responseHandler);
    };

    const onResponseDrawerClose = () => {
        // reset loacalstorage data of the current establishment
        dispatch(destroyCart(props.establishmentUuid));
        setCreateReturnOrderResponse(undefined);
        props.onSuccess();
    };


    const updateReturnableProductListOnCart = () => {
        setUpdateReturnableProducList(!updateReturnableProducList);
    };


    // column variable
    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: PurchaseProductDetails) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: <OptionalTitle title='Return quantity' description='The quantity to be returned and must be less than or equal to the quantity eligible for return' />,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: PurchaseProductDetails) => <div>
                <InputNumber
                    disabled
                    key={record.id}
                    value={record.returnQuantity}
                    controls={false}
                    min={0}
                    max={record.quantity}
                />
                <label className='pl-5'>{record.returnQuantity > 1 ? 'Units' : 'Unit'}</label>
            </div>,
        },
        {
            title: 'Total Price',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: PurchaseProductDetails) => <Currency value={getProductTotalAmount(record)} />,
        },
    ];

    const returnProductsSummary = {
        data: [{
            label: <label className='font-semibold'>Subtotal</label>,
            value: <Currency styleClass='pl-4' value={computeTotalAmount()} />,
        },
        {
            label: <label className='font-semibold'>No of Items</label>,
            value: <Quantity styleClass='pl-4' quantity={props.savedReturnOrders?.selectedItemsCount} />,
        }],
        divider: true,
    };

    // useEffects
    useEffect(() => {
        setReturnProductList(
            // Initializing cart product list from the localstorage.
            props.savedReturnOrders?.selectedProducts,
        );
    }, [props.open, updateReturnableProducList]);

    return {

        loading,
        columns,
        returnProductList,
        returnProductsSummary,
        initiateCreateReturnOrder,
        navigate,
        createReturnOrderResponse,
        onResponseDrawerClose,
        updateReturnableProductListOnCart,

    };

};