import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_DETAILS } from '../Constants';
import { UserDetails } from '../Types';

interface UserState {
    userDetails: UserDetails | null;
}

const initialState: UserState = {
    userDetails: localStorage.getItem(USER_DETAILS) !== null ? JSON.parse(localStorage.getItem(USER_DETAILS)!!) : null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserDetails(state, action: PayloadAction<UserDetails>) {
            state.userDetails = action.payload;
            localStorage.setItem(USER_DETAILS, JSON.stringify(action.payload));
        },
    },
});

export const { updateUserDetails } = userSlice.actions;
export default userSlice.reducer;
