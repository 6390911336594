import { Form, InputNumber } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { GET_BRANDS_DISCOUNTS } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { DetailsCard } from '../../../../common/detailsCard';
import { DetailsCardProps } from '../../../../common/detailsCard/types';
import { BrandDiscountListResponse, BrandDiscountProperty, BrandDiscountsLayoutProps } from './types';


const BrandDiscountLayout = (props: BrandDiscountsLayoutProps) => {

    // State variables
    const [loading, setLoading] = useState<boolean>();
    const isInitialized = useRef<boolean>(false);

    // Methods
    const getBrandsWithDiscounts = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<BrandDiscountListResponse> = {
            onResponseSuccess(value: BrandDiscountListResponse): void {
                isInitialized.current = true;
                const brandDiscountProperties = value.brands.map(item => {
                    const property = {};
                    Object.keys(item).forEach(key => {
                        if (key != 'discount_percentage') {
                            property[key] = item[key];
                        } else {
                            property[key] = { initialValue: item[key] };
                        }
                    });
                    return property!! as BrandDiscountProperty;
                });
                props.setBrandDiscounts(brandDiscountProperties);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        getApiCall(GET_BRANDS_DISCOUNTS(props.establishmentUuid), responseHandler);
    };

    const changeValue = (brandId: number, value: number) => {
        const indexOfitemToUpdate = props.brandDiscounts?.findIndex(item => item.id === brandId);
        if (indexOfitemToUpdate != -1) {
            const itemToUpdate = props.brandDiscounts.at(indexOfitemToUpdate)!!;
            itemToUpdate!!.discount_percentage = {
                ...itemToUpdate?.discount_percentage,
                value,
                changed: true,
            };
            const updated = props.brandDiscounts;
            updated[indexOfitemToUpdate] = itemToUpdate;
            props.setBrandDiscounts(updated);
        }
    };

    // Variables
    const brandDiscountDetails: DetailsCardProps =
    {
        data: props.brandDiscounts?.map(item => {
            return {
                show: true,
                label: item.name,
                value: <Form.Item
                    name={item.name}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (!value || value >= 0 || value < 100) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error('Please provide a valid discount percentage'),
                                );
                            },
                        }),
                    ]}>
                    <InputNumber
                        disabled={!props.updateAllowed}
                        style={{ width: '100%' }}
                        controls={false}
                        type="number"
                        maxLength={3}
                        max={100}
                        min={0}
                        addonAfter={'%'}
                        placeholder={item.name}
                        defaultValue={
                            item.discount_percentage.value ?? item.discount_percentage.initialValue
                        }
                        onChange={value => changeValue(item.id, value)}
                    />
                </Form.Item>,
            };
        }),
    };


    // useEffects
    useEffect(() => {
        if (props.visible && !isInitialized.current) {
            getBrandsWithDiscounts();
        }
    }, [props.visible]);


    return (
        <>

            <DetailsCard
                loading={loading}
                removePadding
                labelStyleClass='xs:w-full lg:w-1/3'
                valueStyleClass='xs:w-full lg:w-1/3'
                data={
                    brandDiscountDetails?.data?.filter((datum) => datum?.show !== false).map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey font-semibold'>{cardDatum.label}</label>,

                            value: cardDatum.value,
                        };
                    })
                }
            />
        </>
    );
};

export default BrandDiscountLayout;
