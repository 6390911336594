import React from 'react';

enum OrderModeType {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

export class OrderCreationMode {

    type: OrderModeType;

    productListStyleClass: string;

    defaultBrandsDrawerVisibility: boolean;

    defaultCartModalVisibility: boolean;

    cartModalFooter?: React.ReactNode;

    cartPaymentNoteMessage: (tenderType: string) => string;


    constructor(mode: OrderCreationMode) {
        this.type = mode.type;
        this.productListStyleClass = mode.productListStyleClass;
        this.defaultBrandsDrawerVisibility = mode.defaultBrandsDrawerVisibility;
        this.defaultCartModalVisibility = mode.defaultCartModalVisibility;
        this.cartPaymentNoteMessage = mode.cartPaymentNoteMessage;
    }
}

export const CREATE: OrderCreationMode = new OrderCreationMode({
    type: OrderModeType.CREATE,
    productListStyleClass: 'padding-small-10px',
    defaultBrandsDrawerVisibility: true,
    defaultCartModalVisibility: false,
    cartPaymentNoteMessage: (tenderType) => `Payment is going to be done via ${tenderType}`,
});

export const EDIT: OrderCreationMode = new OrderCreationMode({
    type: OrderModeType.EDIT,
    productListStyleClass: 'padding-small-10px meraai-border',
    defaultBrandsDrawerVisibility: false,
    defaultCartModalVisibility: true,
    cartPaymentNoteMessage: () => 'The difference in amount will either be adjusted or refunded.',
});

