import { Breadcrumb } from 'antd';
import ApplicantList from './ApplicantList';
// import BodyTopContent from '../BodyTopContent'

function ApplicantListBody() {
    return (
        <>
            <Breadcrumb className="main-nav">
                <Breadcrumb.Item>..</Breadcrumb.Item>
                <Breadcrumb.Item>Applicants</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
            </Breadcrumb>

            {/* <BodyTopContent/> */}

            <ApplicantList />
        </>
    );
}

export default ApplicantListBody;
