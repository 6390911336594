
import React from 'react';
import './style.less';
import { Option } from 'rc-select';
import Title from 'antd/lib/typography/Title';
import OrderIcon from '../../../images/OrderIcon';
import AmountIcon from '../../../images/AmountIcon';
import { useEffect, useRef, useState } from 'react';
import { getISTFromEpoch } from '../../../utilities/Date';
import { OrderDetailsResponse } from '../../../types/Order';
import { useLocation, useNavigate } from 'react-router-dom';
import TimeFilterPicker from '../../common/TimeFilterPicker';
import { ArrowDownOutlined, RightOutlined } from '@ant-design/icons';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { AGGREGATE_ORDERS, ORDER_LIST } from '../../../http/EndPoints';
import { AMOUNT_ROUNDING_SCALE, RUPEE_SYMBOL } from '../../../Constants';
import { Col, Row, Select, Button, Table, Tag, message, Spin, Empty, Tooltip } from 'antd';
import {
    EstablishmentsDropDown,
    EstablishmentSelectionMode,
    getEncodedStringFromUuidList,
    getUuidListFromEncodedString,
} from '../../common/establishments/EstablishmentsDropDown';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { addKeyToDataList, isArrayEmpty } from '../../../Utils';
import { AggregateDataResponse, OrderBodyProps, OrderListDataResponse, OrderState } from './types';
import { allOrderStates, defaultOrderState, establishmentProperties, OrderStates, orderStatesMap } from './utils';
import { BsDash } from 'react-icons/bs';


function OrderBody(props: OrderBodyProps) {
    const orderStatus = 'CONFIRMED';
    const listingType = 'SOURCED';

    const navigate = useNavigate();
    const location = useLocation();

    const availableOrderStates = props.states
        ? allOrderStates.filter(orderState => props.states?.includes(orderState.state))
        : allOrderStates;

    const isRendered = useRef<boolean>(false);
    const [selectedOrderState, setSelectedOrderState] = useState<OrderState | undefined>(
        props.getOrdersOnLaunch ? availableOrderStates.at(0) : undefined,
    );
    const [orders, setOrders] = useState<OrderDetailsResponse[]>();
    const [ordersRequested, setOrdersRequested] = useState<boolean>(false);
    const [establishmentUuids, setEstablishmentUuids] = useState<string[] | undefined>(
        props?.selectedEstablishmentUuid ? [props.selectedEstablishmentUuid] : undefined,
    );
    const [fromDate, setFromDate] = useState<number | undefined>(props.fromDate);
    const [toDate, setToDate] = useState<number | undefined>(props.toDate);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(true);
    const [aggregateAmount, setAggregateAmount] = useState<number>();
    const [aggregateOrderCount, setAggregateOrderCount] = useState<string>();
    const [pageCount, setPageCount] = useState<number>();
    const [page, setPage] = useState<number>(props.getOrdersOnLaunch === true ? 1 : 0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [bodyResultMsg, setBodyResultMsg] = useState('Search for the data using filters mentioned above.');
    const [fetchedAggregate, setFetchedAggregate] = useState<boolean>(false);
    // If selectedEstablishmentUuid is passed in the props, then when set fetchEstablishmentList as true
    const [fetchEstablishmentList, setFetchEstablishmentList] = useState<boolean>(!!props?.selectedEstablishmentUuid);

    const setStateFromQueryParams = (queryParams: URLSearchParams, param: string, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const pageNo = parseInt(queryParams.get(param)!);
            stateSetter(pageNo);
        } else if (param == 'state') {
            stateSetter(orderStatesMap.get(OrderStates.valueOf(queryParams.get(param)!)!));
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams: URLSearchParams, param: string, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                }
            } else {
                queryParams.set(param, state);
            }
        }
    };

    const setStateFromURLParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setFetchEstablishmentList(true);
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'state', setSelectedOrderState);
        setStateFromQueryParams(queryParams, 'page', setPage);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'state', selectedOrderState?.state);
        setQueryParamsFromState(queryParams, 'page', page);
        /**
          * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
          * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
          * So we are replacing the page with the help of replace property of navigate method.
        * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const onOrderStateChange = (state: string) => {
        setSelectedOrderState(orderStatesMap.get(OrderStates.valueOf(state)!));
    };

    // Aggregate amount and no. of orders details
    const getAggregate = async () => {
        setIsFetching(true);
        // Creating an endpoint for OrderList and aggregate data
        const endPointAggregate = AGGREGATE_ORDERS(
            establishmentUuids!!,
            selectedOrderState?.value!!,
            fromDate!!,
            toDate!!,
            listingType,
        );

        const responseHandler: ResponseHandler<AggregateDataResponse> = {
            onResponseSuccess(value: AggregateDataResponse): void {
                if (value.orders || value.amount) {
                    setAggregateAmount(value.amount);
                    setAggregateOrderCount(value.orders);
                    setFetchedAggregate(true);
                } else {
                    message.warning('No orders were created in the specified time period.', 3);
                    setAggregateAmount(value.amount);
                    setAggregateOrderCount(value.orders);
                    setFetchedAggregate(false);
                    setBodyResultMsg('No orders were created in the specified time period.');
                }
                setIsFetching(false);
            },
            onResponseFailed(): void {
                setIsFetching(false);
            },
            onResponseError(): void {
                setIsFetching(false);
            },
        };

        await getApiCall(endPointAggregate, responseHandler);
    };

    // Order details
    const getOrders = async () => {
        setIsTableDataLoading(true);
        const endPointOrderList = ORDER_LIST(
            establishmentUuids!!,
            orderStatus,
            selectedOrderState?.value!!,
            fromDate!!,
            toDate!!,
            listingType,
            page!! - 1,
            pageSize,
        );
        const responseHandler: ResponseHandler<OrderListDataResponse> = {
            onResponseSuccess(value: OrderListDataResponse): void {
                if (value.orders && value.orders.length > 0) {
                    setOrders(value.orders);
                    setPageCount(value.page_count);
                    setFetchedAggregate(true);
                } else {
                    setOrders([]);
                    setFetchedAggregate(false);
                    setBodyResultMsg('No orders were created in the specified time period.');
                }
                setIsTableDataLoading(false);
            },
            onResponseFailed(): void {
                setIsTableDataLoading(false);
                setFetchedAggregate(false);
            },
            onResponseError(): void {
                setIsTableDataLoading(false);
                setFetchedAggregate(false);
                setBodyResultMsg("Something's went wrong!");
            },
        };

        await getApiCall(endPointOrderList, responseHandler);
    };
    // **************************** API CALL END ****************************

    const getOrdersAndSetURLParams = () => {
        getOrders();
        setUrlParamsFromState();
    };

    const isOrderListingAllowed = (): boolean => {
        return (
            establishmentUuids != undefined &&
            selectedOrderState != undefined &&
            fromDate != undefined &&
            toDate != undefined
        );
    };

    const columns = [
        {
            title: 'Invoice no.',
            dataIndex: 'invoice_no',
            render: (invoiceNo: string) => <Title level={5}>{invoiceNo ? invoiceNo : 'Not generated yet'}</Title>,
        },
        {
            title: 'Order no.',
            dataIndex: 'receipt_no',
        },
        {
            title: establishmentProperties.find(property => property.type === props.type)?.title,
            render: (_, record: OrderDetailsResponse) => record[establishmentProperties.find(property => property.type === props.type)?.propertyName!].name,
        },
        {
            title: 'Agent Name',
            render: (_, record: OrderDetailsResponse) => `${record.agent.firstname} ${record.agent.lastname}`,
        },
        {
            title: 'Payment',
            dataIndex: 'payment_type',
            render: (paymentType) => paymentType || <BsDash />,

        },
        {
            title: 'Amount',
            dataIndex: 'final_amount',
            render: (amount) => `${RUPEE_SYMBOL} ${(amount).toFixed(AMOUNT_ROUNDING_SCALE)}`,
        },
        {
            title: 'Billed On',
            dataIndex: 'confirmed_at',
            render: (confirmed_at) => getISTFromEpoch(confirmed_at),
        },
        {
            title: 'State Updated On',
            dataIndex: 'state_updated_at',
            render: (state_updated_at) => getISTFromEpoch(state_updated_at),
        },
        {
            title: 'State',
            dataIndex: 'state',
            render: (state: string, record: OrderDetailsResponse) => (
                <div className="body-div-tag">
                    {
                        record.partially_deliverable && <div
                            className='flex-center custom-rounded-div'
                        >
                            <Tooltip title='This order is partially deliverable'>
                                <label className='bold-label' style={{ color: '#985524', padding: '5px', fontSize: '12px', cursor: 'help' }}>PD</label>
                            </Tooltip>

                        </div>
                    }
                    <Tag
                        className="body-orders-tag"
                        color={
                            orderStatesMap.has(OrderStates.valueOf(state)!) ?
                                orderStatesMap.get(OrderStates.valueOf(state)!)!.color
                                :
                                defaultOrderState.color
                        }
                    >
                        {
                            orderStatesMap.has(OrderStates.valueOf(state)!) ?
                                orderStatesMap.get(OrderStates.valueOf(state)!)!.display
                                :
                                state
                        }
                    </Tag>
                    <RightOutlined
                        onClick={() => {
                            navigate(`/order/${record.uuid}`);
                        }}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (props.active && props.getOrdersOnLaunch) {
            getOrders();
            getAggregate();
        }
    }, [props.active]);

    /**
     * This runs when there's a tab which between the components list OrderBody is a part of.
     * Whenever this is active, we change the url params to include the data that is in the state.
     */
    useEffect(() => {
        if (props.active) {
            /**
             * If the establishmentUuid is set, it signifies that something is present in the state.
             */
            if (establishmentUuids) {
                setUrlParamsFromState();
            } else {
                setStateFromURLParams();
            }
        }
    }, [props.active, establishmentUuids]);

    /**
     * This runs whenever a page change happens.
     */
    useEffect(() => {
        if (isRendered.current) {
            if (!aggregateAmount) {
                getAggregate();
            }
            getOrdersAndSetURLParams();
        } else {
            isRendered.current = true;
        }
    }, [page]);

    /**
     * This useEffect gets called when the GetOrders button is clicked
     */
    useEffect(() => {
        if (ordersRequested) {
            if (page == 1) {
                getAggregate();
                getOrdersAndSetURLParams();
            } else {
                setPage(1);
            }
            setOrdersRequested(false);
        }
    }, [ordersRequested]);

    // JSX START
    return (
        <Spin spinning={isFetching} tip="Loading..." size="small">
            <Row gutter={[24, 24]} className="main-body-filters">
                <Col xs={24} sm={12} xl={5}>
                    <EstablishmentsDropDown
                        disabled={props.selectedEstablishmentUuid != undefined}
                        setLoading={setIsFetching}
                        type={EstablishmentType.valueOf(props.type)!}
                        setSelectedUuids={setEstablishmentUuids}
                        selectedUuids={establishmentUuids}
                        selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                        fetchEstablishmentList={fetchEstablishmentList}
                    />
                </Col>

                <Col xs={24} sm={12} xl={5}>
                    <Select
                        suffixIcon={<ArrowDownOutlined />}
                        value={selectedOrderState ? selectedOrderState.state : undefined}
                        placeholder="Select state"
                        className="filter-drop-down"
                        onChange={onOrderStateChange}>
                        {/* States are included in the options only if includeInFilter is true. Example, for the state SELLER_PARTIALLY_ACCEPTED,
                includeInFilter is false because it is covered in the API response of Accepted By Seller(s) */}
                        {availableOrderStates
                            .filter(state => state.includeInFilter)
                            .map(state => (
                                <Option value={state.state} key={state.state}>
                                    {state.display}
                                </Option>
                            ))}
                    </Select>
                </Col>
                <Col xs={24} sm={24} md={12} lg={24} xl={10}>
                    <TimeFilterPicker
                        enableFilterPicker
                        fromDateEpoch={fromDate!}
                        toDateEpoch={toDate!}
                        setFromDateEpoch={setFromDate}
                        setToDateEpoch={setToDate}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={24} xl={4} className='flex-center'>
                    <Button
                        style={{ width: '100%' }}
                        className="default-button flex-center"
                        size="large"
                        onClick={() => {
                            setOrdersRequested(true);
                        }}
                        disabled={!isOrderListingAllowed()}>
                        Get Orders
                    </Button>
                </Col>
            </Row>

            {fetchedAggregate && (
                <div className="main-body-aggregate">
                    <Row className="main-body-aggregate-row" gutter={[0, 24]}>
                        <Col xs={24} sm={11} md={10} lg={9} xl={8} className="main-body-aggregate-order-summary">
                            <Row>
                                <Col xs={12} md={10} lg={8} xl={8}>
                                    <AmountIcon />
                                </Col>
                                <Col xs={12} md={14} lg={16} xl={16}>
                                    <Title level={3}>{`${RUPEE_SYMBOL} ${aggregateAmount}`}</Title>
                                    <Title level={5}>Total Amount</Title>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={11} md={10} lg={9} xl={8} className="main-body-aggregate-order-summary">
                            <Row>
                                <Col xs={12} md={10} lg={8} xl={8}>
                                    <OrderIcon />
                                </Col>
                                <Col xs={12} md={14} lg={16} xl={16}>
                                    <Title level={3}> {aggregateOrderCount} </Title>
                                    <Title level={5}>No. of Orders</Title>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}

            <Row className="main-body-content">
                {fetchedAggregate ? (
                    <Table
                        className="main-body-content-table"
                        loading={isTableDataLoading}
                        dataSource={addKeyToDataList(orders!)}
                        columns={columns}
                        scroll={{ x: 350 }}
                        pagination={{
                            pageSize,
                            current: page,
                            total: pageCount!! * pageSize,
                            onChange: (pageNo, size) => {
                                setIsTableDataLoading(true);
                                setPage(pageNo);
                                setPageSize(size);
                            },
                        }}
                    />
                ) : (
                    <Empty
                        className="main-body-aggregate-empty"
                        image={Empty.PRESENTED_IMAGE_DEFAULT}
                        description={bodyResultMsg}
                    />
                )}
            </Row>
        </Spin>
    );
}

export default OrderBody;