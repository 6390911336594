import { Avatar, Col, Divider, Row } from 'antd';
import React, { useEffect } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { USER_DETAILS } from '../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { MeraaiLogo } from '../../../../images/MeraaiLogo';
import { UserDetails } from '../../../../Types';
import { updateUserDetails } from '../../../../user/UserSlice';
import AppSideMenu from '../items';
import { SideNavBarLayoutProps } from './types';
import { layout, style } from '../../../../styles/css/style';
import { AiOutlineClose } from 'react-icons/ai';
import { softLogout } from '../../../authentication/logout';


export const SideNavBarLayout = (props: SideNavBarLayoutProps) => {

    const userId = useAppSelector(state => state.login.loginInput);

    const userDetails = useAppSelector(state => state.user.userDetails);
    const authToken = useAppSelector(state => state.auth.authToken);
    const userRole = useAppSelector(state => state.login.role);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userDetails == null && authToken.length > 0) {
            const responseHandler: ResponseHandler<UserDetails> = {
                onResponseSuccess(value: UserDetails): void {
                    dispatch(updateUserDetails(value));
                },
            };
            getApiCall(USER_DETAILS(userRole), responseHandler);
        }
    }, []);

    return (
        <div className='flex-column justify-between h-full'>
            <Row className={`pl-8 pt-6 pb-3 ${layout.flex.spaceBetween}`}>
                <Col>
                    <Link to="/">
                        <MeraaiLogo
                            width='8rem'
                            iconColor={style.colors.meraaiOrange}
                            titleColor={style.colors.white}
                        />
                    </Link>
                </Col>
                {
                    props.broken && <Col className={`w-1/6 ${layout.flex.center}`}>
                        <AiOutlineClose
                            className={`text-white text-3xl ${layout.flex.center}`}
                            onClick={props.toggleSider}
                        />
                    </Col>
                }

            </Row>
            <Row className='mt-5 overflow-y-auto overflow-x-hidden h-screen'>
                <Col xs={24} className={`${layout.flexCol.spaceBetween}`}>
                    {/* Menu */}
                    <AppSideMenu itemPlacement={'TOP'} toggleSider={props.toggleSider} />
                    <AppSideMenu itemPlacement={'BOTTOM'} toggleSider={props.toggleSider} />
                </Col>
            </Row>
            <Row className='bg-meraai-brown'>
                <Col xs={24}>

                    <Divider plain className='bg-white my-2' />

                    <Row className="w-full sm:w-1/2 lg:w-full h-max pb-3">

                        <Col xs={6} className={layout.flex.center}>
                            <Avatar className="text-white" src={userDetails?.image_url} />
                        </Col>

                        <Col xs={13}>
                            <Row>
                                <Col xs={24} className='text-white'>
                                    {`${userDetails?.first_name} ${userDetails?.last_name}`}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} className='text-white'>
                                    <label className='text-[13px]' >
                                        {`${userId}`}
                                    </label>

                                </Col>
                            </Row>
                        </Col>

                        <Col xs={5} className={`${layout.flex.center}`}>
                            <Avatar className={`text-white cursor-pointer ${layout.flex.center}`} src={<FiLogOut className='text-[18px]' />}
                                onClick={softLogout}
                            />
                        </Col>

                    </Row>
                </Col>
            </Row>
        </div>
    );
};