import { Button, Card, Checkbox, Col, Collapse, Empty, message, Modal, Row, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdSettings } from 'react-icons/md';
import { AMOUNT_ROUNDING_SCALE, RUPEE_SYMBOL } from '../../../Constants';
import { COLLECTION_LIST } from '../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { setWidth } from '../../../styles/css/react/MeraaiCssProperties';
import { StateMetaDatum } from '../../../Types';
import { CollectionResponse, CollectionDetail } from '../../../types/FinanceCredit';
import { isMobile } from '../../common/MobileDetect';
import RowColData from '../../common/RowColData';
import { StateMetadataRenderer } from '../../common/StateMetadataRenderer';
import TimeFilterPicker from '../../common/TimeFilterPicker';
import { PayuIntent } from '../../payments/upi/PayuIntent';
import { CollectionComponentConfig } from '../CollectionState';
import { CaretRightOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { FcExpand } from 'react-icons/fc';
import { getISTFromEpoch } from '../../../utilities/Date';
import { CollectionSource } from '../CollectionSource';

const okModalButtonStyle = { borderColor: '#7e4d2b', backgroundColor: '#7e4d2b' };
const cancelModalButtonStyle = { display: 'none' };
const gridStyle: React.CSSProperties = {
    width: isMobile() ? '100%' : '31%',
    textAlign: 'left',
    margin: '1%',
    cursor: 'pointer',
    paddingTop: '5px',
    borderRadius: '20px',
};

interface DepositsProps {
    active: boolean
}

interface PaymentDeposit { value: boolean, display: string }
enum PaymentDepositType {
    ONLINE = 'Deposit Online',
    OFFLINE = 'Deposit at the Hub',
}
export const Deposits = (props: DepositsProps) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [bodyResultMsg, setBodyResultMsg] = useState('Search for the data using filters mentioned above.');
    const [collectionListResponse, setCollectionListResponse] = useState<CollectionResponse>();
    const [stateMetadataModalVisibility, setStateMetadataModalVisibility] = useState<boolean>(false);
    const [collectionListRequested, setCollectionListRequested] = useState<boolean>(false);
    const [paymentModalVisibility, setPaymentModalVisibility] = useState<boolean>(false);
    const [collectionStatusTracker, setCollectionStatusTracker] = useState<StateMetaDatum[]>();
    const [selectedState, setSelectedState] = useState<CollectionComponentConfig>();
    const [allChecked, setAllChecked] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>();
    const [fromDate, setFromDate] = useState<number>();
    const [toDate, setToDate] = useState<number>();
    const [page, setPage] = useState<number>(0);

    const paymentDepositState: PaymentDeposit[] = [
        { value: false, display: PaymentDepositType.OFFLINE },
        { value: true, display: PaymentDepositType.ONLINE },
    ];

    // METHODS
    const setStateFromQueryParams = (queryParams, param, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'state') {
            stateSetter(CollectionComponentConfig.valueOf(queryParams.get(param)));
        } else if (['page', 'pageSize', 'from-date', 'to-date'].includes(param)) {
            const values = parseInt(queryParams.get(param));
            if (values) {
                stateSetter(values);
            }
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams, param, state) => {
        if (state) {
            if (param === 'state' && !CollectionComponentConfig.valueOf(state)?.config.depositConfig?.dateFilterSelectionRequired) {
                queryParams.delete('from-date');
                queryParams.delete('to-date');
            }
            queryParams.set(param, state);
        }
    };

    const setStateFromUrlParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'state', setSelectedState);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'size', setPageSize);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setCollectionListRequested(true);
    };

    const setUrlParamsFromState = () => {
        if (!props.active) {
            return;
        }
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'state', selectedState?.config.depositConfig?.state);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'size', pageSize);
        setQueryParamsFromState(queryParams, 'page', page);

        navigate(`?${queryParams.toString()}`);
    };

    const getSelfCollectionList = () => {
        if (!selectedState) {
            return;
        }
        setLoading(true);
        const responseHandler: ResponseHandler<CollectionResponse> = {
            onResponseSuccess(response: CollectionResponse): void {

                response.collections.forEach((collection) => {
                    collection.isChecked = collection.payment_deposit_allowed;
                });

                setCollectionListResponse(response);
                setAllChecked(true);
                if (!(response.collections.length > 0)) {
                    setBodyResultMsg('No collections found');
                }
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        getApiCall(COLLECTION_LIST([], selectedState?.config.depositConfig?.state!, fromDate, toDate, page, pageSize, true), responseHandler);
    };

    const setStateAndResetDateFilter = (state) => {
        setSelectedState(CollectionComponentConfig.valueOf(state));
        setPageSize(CollectionComponentConfig.valueOf(state)?.config.depositConfig?.pageSize);
        setFromDate(undefined);
        setToDate(undefined);
        setBodyResultMsg('Search for the data using filters mentioned above.');
    };

    const disableGetCollections = (): boolean => {
        if (selectedState && !selectedState?.config.depositConfig?.dateFilterSelectionRequired) {
            return false;
        }
        if (fromDate && toDate) {
            return false;
        }
        return true;
    };

    const toggleCollectionSelection = (checked: boolean, uuid: string) => {
        if (collectionListResponse) {

            const checkedCollection = collectionListResponse.collections.find(collection => collection.uuid === uuid);
            if (checkedCollection) {
                checkedCollection.isChecked = checked;
            }

            setCollectionListResponse({ ...collectionListResponse!, collections: [...collectionListResponse.collections] });

            const paymentAllowedCollections = collectionListResponse.collections.filter(collection => collection.payment_deposit_allowed);
            if (paymentAllowedCollections.length > 0) {
                if (paymentAllowedCollections.every(collection => collection.isChecked)) {
                    setAllChecked(true);
                } else {
                    setAllChecked(false);
                }
            } else {
                setAllChecked(false);
            }
        }
    };

    const onPaymentCompletion = () => {
        setCollectionListRequested(true);
    };

    const displayPayAllOption = (collectionList: CollectionDetail[], checkboxVisibility: boolean) => {
        if (checkboxVisibility && collectionList.length > 0) {
            return (
                <Checkbox
                    className='round-checkbox'
                    checked={allChecked}
                    onChange={(event) => {
                        setAllChecked(event.target.checked);
                        if (collectionListResponse) {
                            collectionListResponse.collections.forEach(collection => {
                                if (collection.payment_deposit_allowed) {
                                    collection['isChecked'] = event.target.checked;
                                }
                            });
                            setCollectionListResponse({ ...collectionListResponse, collections: [...collectionListResponse.collections] });
                        }
                    }}
                >
                    <label>
                        Pay All
                    </label>
                </Checkbox >
            );
        }

    };

    const getCollectionListInCardView = (collectionList: CollectionDetail[], payment: PaymentDeposit) => {
        return (
            <Card headStyle={{ padding: 0 }} style={{ textAlign: 'center' }} bodyStyle={{ padding: 0 }} extra={displayPayAllOption(collectionList, payment.value)}>
                {collectionList.map((collection) => {
                    let rowColDataProps = {
                        key: collection.uuid,
                        data: [{
                            value: collection.payment_deposit_allowed && <Checkbox
                                className='round-checkbox'
                                checked={collection.isChecked}
                                onChange={(event) => {
                                    toggleCollectionSelection(event.target.checked, collection.uuid);
                                }}
                            >
                                Pay
                            </Checkbox>,
                            styleName: 'white-space',
                            xsSize: 16,
                        },
                        {
                            value:
                                <MdSettings
                                    className="action-col-button-info"
                                    style={{ fontSize: '18px' }}
                                    onClick={() => {
                                        const itemStatusDetails = collection.state_metadata.sort(function (a, b) {
                                            return a.timestamp! - b.timestamp!;
                                        });
                                        if (itemStatusDetails.length) {
                                            setCollectionStatusTracker(itemStatusDetails);
                                        }
                                        setStateMetadataModalVisibility(true);
                                    }}
                                >
                                </MdSettings>,
                            styleName: 'white-space flex-column-end',
                            xsSize: 8,
                        },
                        {
                            value: <b>Store Name</b>,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: `${collection.establishment.name}`,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: <b>Tender</b>,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: `${collection.tender.name}`,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: <b>Amount</b>,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: `${RUPEE_SYMBOL} ${collection.amount.toFixed(AMOUNT_ROUNDING_SCALE)}/-`,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: <b>Collected at</b>,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: `${getISTFromEpoch(collection.created_at)}`,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: <b>Source</b>,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        {
                            value: `${CollectionSource[collection.source].display}`,
                            styleName: 'white-space',
                            xsSize: 12,
                        },
                        ],
                    };

                    return (
                        <Card.Grid key={collection.uuid} style={gridStyle}>
                            <RowColData
                                {...rowColDataProps}
                            />
                        </Card.Grid>
                    );
                })}
            </Card >
        );
    };

    const initializeDefaultStateProperties = () => {
        if (!selectedState) {
            setSelectedState(CollectionComponentConfig.INITIATED);
            setPageSize(CollectionComponentConfig.INITIATED.config.depositConfig?.pageSize);
        }
    };

    const handleWebViewAction = () => {
        message.destroy();
        message.warning('This payment can only be done via mobile devices.');
    };

    useEffect(() => {
        if (collectionListResponse) {
            setUrlParamsFromState();
        }
    }, [collectionListResponse]);

    useEffect(() => {
        if (props.active) {
            initializeDefaultStateProperties();
            if (selectedState) {
                setUrlParamsFromState();
            } else {
                setStateFromUrlParams();
            }
        }
    }, [props.active]);

    useEffect(() => {
        if (collectionListRequested) {
            getSelfCollectionList();
            setCollectionListRequested(false);
        }
    }, [collectionListRequested]);

    useEffect(() => {
        setStateFromUrlParams();
    }, []);

    return (
        <Spin spinning={loading} tip={'Loading...'} size='default'>
            <Space direction='vertical' size={'small'}>
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={6}>
                        <Select
                            placeholder="Select Payment Status"
                            style={setWidth('100%')}
                            value={selectedState?.config.depositConfig?.display}
                            onChange={setStateAndResetDateFilter}
                        >
                            {
                                Object.values(CollectionComponentConfig).filter((type: CollectionComponentConfig) => type.config.depositConfig?.display).map((type: CollectionComponentConfig, index) => <Select.Option
                                    key={index}
                                    value={type.config.depositConfig?.state}
                                >
                                    {type.config.depositConfig?.display}
                                </Select.Option>)
                            }
                        </Select>
                    </Col>
                    <Col xs={24} md={14} >
                        <TimeFilterPicker
                            enableFilterPicker={selectedState?.config.depositConfig?.dateFilterSelectionRequired!}
                            fromDateEpoch={fromDate!}
                            toDateEpoch={toDate!}
                            setFromDateEpoch={setFromDate}
                            setToDateEpoch={setToDate}
                        />
                    </Col>
                    <Col xs={24} md={4} className='flex-justify-center'>
                        <Button
                            className="default-button"
                            size="large"
                            disabled={disableGetCollections()}
                            onClick={() => {
                                setCollectionListRequested(true);
                            }}>
                            Get Collections
                        </Button>
                    </Col>
                </Row>
                {collectionListResponse &&
                    collectionListResponse.collections.length > 0 &&
                    collectionListResponse.collections[0].state === selectedState?.config.depositConfig?.state ?
                    <>
                        {/* Collection Cards */}
                        {paymentDepositState.filter(depositState => {
                            //depositState.value is same as (depositState.display === paymentDepositType.ONLINE) 
                            if (depositState.value) {
                                return selectedState.config.depositConfig?.allowPayment;
                            } else {
                                return true;
                            }
                        }).map((payment: PaymentDeposit, index: number) => {

                            const collectionList: CollectionDetail[] = collectionListResponse.collections.filter(collection => collection.payment_deposit_allowed === payment.value);

                            return <Row key={index}>
                                <Col xs={24}>
                                    <Row gutter={[0, 24]}>
                                        <Col xs={24}>
                                            {
                                                collectionList.length > 0 &&
                                                    selectedState.config.depositConfig?.wrapIntoCollapsiblePanel ? <Collapse
                                                        bordered={false}
                                                        defaultActiveKey={[PaymentDepositType.ONLINE]}
                                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                    >
                                                        <Collapse.Panel key={payment.display} header={payment.display} style={{ padding: '0px' }}>
                                                            {selectedState.config.depositConfig.allowPayment && getCollectionListInCardView(collectionList, payment)}
                                                        </Collapse.Panel>
                                                    </Collapse> : getCollectionListInCardView(collectionList, payment)
                                            }
                                        </Col>
                                        {
                                            collectionListResponse.next_page &&
                                            <Col xs={24}>
                                                <div className="flex-center">
                                                    <Button
                                                        className='flex-center'
                                                        type='default'
                                                        size="large"
                                                        loading={loading}
                                                        icon={<FcExpand />}
                                                        onClick={() => {
                                                            const newPageSize = (typeof pageSize === 'string' ?
                                                                parseInt(pageSize) : pageSize!) + (selectedState.config.depositConfig?.pageSize!);

                                                            setPageSize(newPageSize);
                                                            setCollectionListRequested(true);
                                                        }}
                                                        style={{ alignSelf: 'bottom' }}>
                                                        &nbsp; Load more
                                                    </Button>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>;
                        })}
                        {/* Make Payment Button  */}
                        {selectedState?.config.depositConfig.allowPayment && <Row>
                            <Col xs={24}>
                                <div className='flex-center'>
                                    <Button
                                        className='default-success-button'
                                        size='large'
                                        disabled={!collectionListResponse.collections.some(collection => collection.isChecked)}
                                        onClick={() => {
                                            setLoading(true);
                                            setPaymentModalVisibility(true);
                                        }}
                                    >
                                        Make Payment
                                    </Button>
                                    <>
                                        &nbsp; {RUPEE_SYMBOL}<b style={{ fontSize: '16px' }}>{collectionListResponse.collections.filter(collection => collection.isChecked).reduce((totalAmount, collection) => totalAmount + collection.amount, 0).toFixed(AMOUNT_ROUNDING_SCALE)} /-</b>
                                    </>
                                </div>
                            </Col>
                        </Row>}
                    </> : <Empty
                        className="main-body-aggregate-empty"
                        image={Empty.PRESENTED_IMAGE_DEFAULT}
                        description={bodyResultMsg} />
                }
            </Space>

            <PayuIntent
                setLoading={setLoading}
                collectionUuids={collectionListResponse?.collections.filter(collection => collection.isChecked).map(collection => collection.uuid)!}
                totalDueAmount={collectionListResponse?.collections.filter(collection => collection.isChecked).reduce((totalAmount, collection) => totalAmount + collection.amount, 0)!}
                modalVisibility={paymentModalVisibility}
                setModalVisibility={setPaymentModalVisibility}
                onPaymentCompletion={onPaymentCompletion}
                handleWebViewAction={handleWebViewAction}
            />

            <Modal
                title="Status Tracker"
                className="responsive-modal"
                open={stateMetadataModalVisibility}
                destroyOnClose
                cancelButtonProps={{ style: cancelModalButtonStyle }}
                okButtonProps={{ style: okModalButtonStyle }}
                onOk={() => setStateMetadataModalVisibility(false)}
                onCancel={() => setStateMetadataModalVisibility(false)}>
                {collectionStatusTracker ? (
                    <StateMetadataRenderer stateMetadata={collectionStatusTracker} />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="No Data" />
                )}
            </Modal>
        </Spin>
    );
};