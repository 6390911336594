export class CartItem {
    key?: number;

    id: number;

    sku: string;

    name: string;

    quantity: number;

    mrp: number;

    price_per_unit: number;

    image: string | undefined;

    amount: number;

    establishment_uuid: string;

    public constructor(item: CartItem) {
        this.key = item.id;
        this.id = item.id;
        this.sku = item.sku;
        this.name = item.name;
        this.quantity = item.quantity;
        this.mrp = item.mrp;
        this.price_per_unit = item.price_per_unit;
        this.image = item.image;
        this.amount = item.amount;
        this.establishment_uuid = item.establishment_uuid;
    }

    public setQuantityValue?(quantity: number) {
        this.quantity = quantity;
    }

    public setAmountValue?(quantity) {
        this.amount = this.price_per_unit! * quantity;
    }
}
