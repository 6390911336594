import { Col, Row, Spin } from 'antd';
import React from 'react';
import { DetailsPageProps } from './types';

export const DetailsPage = (props: DetailsPageProps) => {
    return (
        <Spin spinning={props.loading} > <Row className={`${props.height === 'FIT_TO_CONTENT' ? 'h-full' : 'h-screen'} p-2`}> <Col className={'w-full h-full flex-column justify-between'}>

            {/* Page Header */}
            {
                props.header !== undefined ? <Row className='w-full py-2 px-2 xs:px-5 bg-white'>
                    <Col className='w-full'>
                        {props.header}
                    </Col>
                </Row> : <></>
            }


            {/* Page Main Content */}
            <Row className={`w-full h-full p-2 overflow-auto ${props.body?.styleClass}`}>
                <Col className='w-full h-full md:w-2/3 p-2'>
                    <div className='w-full h-full bg-white overflow-auto'>
                        {props.body?.left}
                    </div>
                </Col>
                <Col className='w-full h-full md:w-1/3 p-2'>
                    <div className='w-full h-full bg-white overflow-auto'>
                        {props.body?.right}
                    </div>
                </Col>
            </Row>

            {
                props.footerVisibility &&
                /* Page Footer */
                < Row className='w-full p-2'>
                    <Col className='w-full bg-white'>
                        {props.footer}
                    </Col>
                </Row>

            }

        </Col> </Row > </Spin >
    );
};