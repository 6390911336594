import 'antd/dist/antd.css';
import React from 'react';
import { useEffect } from 'react';
import InternetConnectionError from './components/common/InternetConnectionError';
import { checkAndSetFirebaseToken } from './components/notifications/Firebase';
import { updateFirebaseToken } from './components/notifications/firebaseTokenSlice';
import AppRoutes from './components/router/AppRoutes';
import { useAppDispatch, useAppSelector } from './hooks';

function App() {

    const currentToken = useAppSelector((state) => state.firebaseToken.firebaseToken);
    const loggedIn = useAppSelector((state) => state.login.loggedIn);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (loggedIn) {
            checkAndSetFirebaseToken(currentToken);
        }
    }, [loggedIn]);

    useEffect(() => {
        if (currentToken != '') {
            dispatch(updateFirebaseToken(currentToken));
        }
    }, [currentToken]);

    return <>{navigator.onLine ? <AppRoutes /> : <InternetConnectionError />}</>;
}

export default App;
