import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURCHASE_ORDER_CART } from '../../../../../Constants';
import { PurchaseOrderEstablishmentCart, PurchaseOrderUpdateProductPayload } from '../../../../../types/Cart';
import { differenceInDays } from '../../../../../utilities/Date';

interface CartState {
    cart: { [key: string]: PurchaseOrderEstablishmentCart };
}

export function getFilteredResult(storedObject: { [key: string]: PurchaseOrderEstablishmentCart }): {
    [key: string]: PurchaseOrderEstablishmentCart;
} {
    const keys = Object.keys(storedObject).filter(
        key => differenceInDays(Date.now(), storedObject[key].lastUpdatedAt) <= 3,
    );
    const finalFilteredObject: { [key: string]: PurchaseOrderEstablishmentCart } = {};
    keys.forEach(key => {
        finalFilteredObject[key] = storedObject[key];
    });
    localStorage.setItem(PURCHASE_ORDER_CART, JSON.stringify(finalFilteredObject));
    return finalFilteredObject;
}

const initialState: CartState = {
    cart: localStorage.getItem(PURCHASE_ORDER_CART) != null ? getFilteredResult(JSON.parse(localStorage.getItem(PURCHASE_ORDER_CART)!!)) : {},
};

const purchaseOrderCartSlice = createSlice({
    name: 'purchase_order_cart',
    initialState,
    reducers: {
        updatePurchaseOrderCartItem(state, action: PayloadAction<PurchaseOrderUpdateProductPayload>) {
            const updateData = action.payload;
            updateData.cartItem.quantity = Number.isNaN(updateData.cartItem.quantity) ? 0 : updateData.cartItem.quantity;
            const establishmentCartItem: PurchaseOrderEstablishmentCart = state.cart[updateData.cartReferenceId!] ?? {
                selectedItems: [],
                selectedItemsCount: 0,
                lastUpdatedAt: 0,
            };
            const itemIndex = establishmentCartItem.selectedItems.findIndex(item => item.id === updateData.cartItem.id);
            if (itemIndex === -1) {
                establishmentCartItem.selectedItems.push(updateData.cartItem);
            } else {
                const existingItem = establishmentCartItem.selectedItems[itemIndex];
                establishmentCartItem.selectedItemsCount -= existingItem.quantity!;
                establishmentCartItem.selectedItems[itemIndex] = updateData.cartItem;
            }
            if (updateData.cartItem.quantity === 0) {
                const index = itemIndex === -1 ? establishmentCartItem.selectedItems.length - 1 : itemIndex;
                establishmentCartItem.selectedItems.splice(index, 1);
            }
            establishmentCartItem.selectedItemsCount += updateData.cartItem.quantity!;
            establishmentCartItem.lastUpdatedAt = Date.now();
            state.cart[updateData.cartReferenceId!] = establishmentCartItem;
            localStorage.setItem(PURCHASE_ORDER_CART, JSON.stringify(state.cart));
        },

        clearPurchaseOrderCart(state, action: PayloadAction<string>) {
            const establishmentUuid = action.payload;
            delete state.cart[establishmentUuid];
            localStorage.setItem(PURCHASE_ORDER_CART, JSON.stringify(state.cart));
        },
    },
});

export const { updatePurchaseOrderCartItem, clearPurchaseOrderCart } = purchaseOrderCartSlice.actions;
export default purchaseOrderCartSlice.reducer;
