import { CreateProperties, CreateRequestGroupingKey, EditableField, UpdateProperties, UpdateRequestGroupingKey } from '../../../../../common/templates/meraaiTable/EditableField';
import { CreateProductDetails, ListProductDetail, Metadata } from '../../../../../../types/Product';
import { ImageFile } from '../../../../../common/ImageRenderer';
import { Field } from '../../../../../orders/listing/ListingData';

export interface ProductImage {
    webUrl?: string, imageFile?: ImageFile
}

export class ProductProperties {
    key?: number;

    id?: EditableField<number>;

    existing?: EditableField<boolean>;

    active?: EditableField<boolean>;

    name: EditableField<string>;

    price: EditableField<number>;

    mrp: EditableField<number>;

    sku: EditableField<string>;

    hsn?: EditableField<string>;

    ean?: EditableField<string>;

    gst?: EditableField<number>;

    cess?: EditableField<number>;

    brand: EditableField<string>;

    categories: EditableField<number[] | undefined>;

    orderQuantityMultiple: EditableField<number | undefined>;

    image: EditableField<ProductImage>;

    fields?: EditableField<Field[] | undefined>;

    metadata?: EditableField<Metadata | undefined>;

    constructor(productProperties: ProductProperties) {

        this.id = new EditableField(productProperties.id);
        this.existing = new EditableField(productProperties.existing);
        this.active = new EditableField(productProperties.active);
        this.name = new EditableField(productProperties.name);
        this.price = new EditableField(productProperties.price);
        this.mrp = new EditableField(productProperties.mrp);
        this.sku = new EditableField(productProperties.sku);
        this.hsn = new EditableField(productProperties.hsn);
        this.ean = new EditableField(productProperties.ean);
        this.gst = new EditableField(productProperties.gst);
        this.cess = new EditableField(productProperties.cess);
        this.brand = new EditableField(productProperties.brand);
        this.categories = new EditableField(productProperties.categories);
        this.orderQuantityMultiple = new EditableField(productProperties.orderQuantityMultiple);
        this.image = new EditableField(productProperties.image);
        this.fields = new EditableField(productProperties.fields);
        this.metadata = new EditableField(productProperties.metadata);

    }

    public static getFilteredProperties?(productProperties: ProductProperties): EditableField<any>[] {
        return Object.keys(productProperties)
            .filter(productPropertyKey => productProperties[productPropertyKey].beingUsed)
            .map(productPropertyKey => productProperties[productPropertyKey]);
    }

    public setValuesForProductCreation?(product: CreateProductDetails) {
        this.key = product.id;
        this.id?.setInitialValue?.(product.id);
        this.name?.setInitialValue?.(product.name, undefined, new CreateProperties('Name', 'name', null));
        this.price?.setInitialValue?.(product.price, undefined, new CreateProperties('Price', 'price', null));
        this.mrp?.setInitialValue?.(product.mrp, undefined, new CreateProperties('MRP', 'maximum_retail_price', null));
        this.sku?.setInitialValue?.(product.sku, undefined, new CreateProperties('SKU', 'sku', null));
        this.hsn?.setInitialValue?.(product.hsn, undefined, new CreateProperties('HSN', 'hsn', null));
        this.ean?.setInitialValue?.(product.ean, undefined, new CreateProperties('Barcode', 'ean', null));

        this.gst?.setInitialValue?.(
            product.gst,
            undefined, new CreateProperties('Gst', 'GST', CreateRequestGroupingKey.TAXES));

        this.cess?.setInitialValue?.(
            product.cess,
            undefined, new CreateProperties('Cess', 'CESS', CreateRequestGroupingKey.TAXES));

        this.orderQuantityMultiple?.setInitialValue?.(
            product.carton_capacity,
            undefined, new CreateProperties('Carton count', 'order_quantity_multiple', null));

        this.brand?.setInitialValue?.(product.brand, undefined, new CreateProperties('Brand', 'brand', null));
        this.categories?.setInitialValue?.(product.categories?.map(category => category?.id)!, undefined, new CreateProperties('Categories', 'categories', null));
        this.image?.setInitialValue?.({ webUrl: product.image }, undefined, new CreateProperties('Image', 'image_url', null));
        this.fields?.setInitialValue?.(undefined, undefined, new CreateProperties('Info', 'fields', null));
        this.metadata?.setInitialValue?.(undefined, undefined, new CreateProperties('Metadata', 'metadata', null));

    }

    public setSellerProductListValues?(product: ListProductDetail) {
        this.key = product.id;
        this.id?.setInitialValue?.(product.id);
        this.active?.setInitialValue?.(product.active, new UpdateProperties('Active', 'active', null));
        this.name?.setInitialValue?.(product.name, new UpdateProperties('Name', 'name', null));
        this.price?.setInitialValue?.(product.price, new UpdateProperties('Price', 'price', null));
        this.mrp?.setInitialValue?.(
            product.maximum_retail_price,
            new UpdateProperties('MRP', 'maximum_retail_price', null),
        );
        this.sku?.setInitialValue?.(product.sku, new UpdateProperties('SKU', 'sku', null));
        this.hsn?.setInitialValue?.(product.hsn, new UpdateProperties('HSN', 'hsn', null));
        this.ean?.setInitialValue?.(product.ean, new UpdateProperties('Barcode', 'ean', null));
        this.gst?.setInitialValue?.(product.gst, new UpdateProperties('Gst', 'GST', UpdateRequestGroupingKey.TAXES));
        this.cess?.setInitialValue?.(
            product.cess,
            new UpdateProperties('Cess', 'CESS', UpdateRequestGroupingKey.TAXES),
        );
        this.orderQuantityMultiple?.setInitialValue?.(product.order_quantity_multiple,
            new UpdateProperties('Carton count', 'order_quantity_multiple', null));
        this.brand?.setInitialValue?.(product.brand, new UpdateProperties('Brand', 'brand', null));
        this.categories?.setInitialValue?.(product.categories?.map(category => category?.id)!, new UpdateProperties('Categories', 'categories', null));
        this.image?.setInitialValue?.({ imageFile: { url: product.image } }, new UpdateProperties('Image', 'image', null));
        this.fields?.setInitialValue?.(product.fields, new UpdateProperties('Info', 'fields', null), undefined, false);
        this.metadata?.setInitialValue?.(product.metadata, new UpdateProperties('Metadata', 'metadata', null), undefined, false);

    }

    public setHubProductListValues?(product: ListProductDetail) {
        this.key = product.id;
        this.id?.setInitialValue?.(product.id, new UpdateProperties('Product Id', 'product_id'));
        this.existing?.setInitialValue?.(product.existing == undefined ? true : product.existing);
        this.active?.setInitialValue?.(product.active, new UpdateProperties('Active', 'active'));
        this.name?.setInitialValue?.(product.name);
        this.price?.setInitialValue?.(product.price, new UpdateProperties('Price', 'price'));
        this.mrp?.setInitialValue?.(product.maximum_retail_price);
        this.orderQuantityMultiple?.setInitialValue?.(product.order_quantity_multiple);
        this.sku?.setInitialValue?.(product.sku);
        this.hsn?.setInitialValue?.(product.hsn);
        this.ean?.setInitialValue?.(product.ean);
        this.gst?.setInitialValue?.(product.gst);
        this.cess?.setInitialValue?.(product.cess);
        this.brand?.setInitialValue?.(product.brand);
        this.image?.setInitialValue?.({ webUrl: product.image });
    }
}
