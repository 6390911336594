import React from 'react';
import { style } from '../../../../../styles/css/style';
import { PurchaseOrderState } from '../../../../../types/PurchaseOrderState';
import { StateChangeHandler } from '../../../../../types/StateChange';
import { createDocument } from '../../../../common/metaDataUploader/utils';

export const purchaseOrderStateChangeHandlers: StateChangeHandler[] = [
    {
        state: PurchaseOrderState.DISPATCHED.state,
        display: 'Dispatch',
        styleClass: style.meraaiOrangeButton,
        popUpModalMessage: 'Proceed to dispatch the order',
        itemAvailabilityModalSubscript: <>Review the items and adjust the quantity that you can fulfil.</>,
        itemShortagesModalSubscript: <>These are all the items which cannot be fulfilled completely. Click on <b>Proceed</b> to continue and <b>X</b> if the items have to be re-reviewed.</>,
        inputQuantityColName: 'Available QTY',
    },
    {
        state: PurchaseOrderState.HUB_RECEIVED.state,
        display: 'Received by Hub',
        styleClass: style.meraaiOrangeButton,
        popUpModalMessage: 'Proceed to mark the order as received',
        mandatoryDocuments: [createDocument('IMAGE', true, 'PoD receipt', undefined, true)],
        itemAvailabilityModalSubscript: <>Review the items and adjust it according to the quantity that you have received.</>,
        itemShortagesModalSubscript: <>These are all the items which haven't been fully received. Click on <b>Proceed</b> to continue and <b>X</b> if the items have to be re-reviewed.</>,
    },
];