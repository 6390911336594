import { Button, Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { InputType } from '../../../../Constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { USER_LOGIN, USER_VERIFY } from '../../../../http/EndPoints';
import { postApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { style } from '../../../../styles/css/style';
import { checkInputType } from '../../../../Utils';
import { LoginPageLayout } from '../../../common/templates/login';
import { Path } from '../../../router/Path';
import { updateAuthToken } from '../../slice';
import { updateLoginSuccessfulData } from '../slice';
import { LoginResponse } from '../types';

export const VerifyLogin = () => {

    const loginInput = useAppSelector(state => state.login.loginInput);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>();

    const getVerfyOtpRequestBody = () => {
        return checkInputType(loginInput) === InputType.MOBILE_NUMBER ? {
            country_code: '+91',
            mobile_number: loginInput,
            otp: otp,
        } :
            {
                email: loginInput,
                otp: otp,
            };
    };

    const verifyOtp = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<LoginResponse> = {
            onResponseSuccess(value: LoginResponse): void {
                message.destroy();
                message.success(value.message);
                dispatch(updateAuthToken(value.token));
                dispatch(updateLoginSuccessfulData(value));
                setLoading(false);
                navigate(Path.HOME);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        postApiCall(USER_VERIFY, getVerfyOtpRequestBody(), responseHandler);

    };

    const getSendOtpRequestBody = () => {
        return checkInputType(loginInput!) === InputType.MOBILE_NUMBER ?
            {
                country_code: '+91',
                mobile_number: loginInput,
            } :
            {
                email: loginInput,
            };
    };

    const sendOtp = () => {
        setLoading(true);

        const responseHandler: ResponseHandler<LoginResponse> = {
            onResponseSuccess(value: LoginResponse) {
                message.destroy();
                message.success(`${value.message}`);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        postApiCall(USER_LOGIN, getSendOtpRequestBody(), responseHandler);
    };

    useEffect(() => {
        if (otp?.length === 4) {
            verifyOtp();
        }
    }, [otp]);

    return (
        <LoginPageLayout
            title='Enter verification code'
            subTitle={<>
                <h4 className='m-0'>We have sent a verification code to <span className='text-meraai-orange'>{loginInput}</span></h4>
                <p className='m-0 text-sm font-semibold'>
                    Noticed a typo?
                    <a className='text-meraai-brown hover:text-meraai-brown hover:underline'
                        onClick={() => {
                            // Restrict user to access this page once he went back. `replace: true`
                            navigate(Path.LOGIN, { replace: true });
                        }}> Fix it now!
                    </a>
                </p>
            </>}
            body={
                <>
                    <Form
                        onFinish={verifyOtp}
                        layout='vertical'
                    >
                        <Form.Item
                            name="email_mobile"
                            label={<label className='text-sm font-semibold'>Enter verification code</label>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your code.',
                                },
                            ]}
                        >
                            <OtpInput
                                className='mr-6 text-4xl border-solid border-1 border-stone-200 rounded-lg'
                                isInputNum
                                isInputSecure
                                shouldAutoFocus
                                inputStyle={{ border: '1px solid #ffffff' }}
                                value={otp}
                                onChange={enteredOtp => {
                                    setOtp(enteredOtp);
                                }}
                                numInputs={4}
                                separator={<span />}
                            />
                        </Form.Item>

                        <a className='text-meraai-brown hover:text-meraai-brown hover:underline'
                            onClick={sendOtp}
                        >
                            Resend Code
                        </a>

                        <Form.Item>
                            <Button loading={loading} className={`mt-5 ${style.meraaiOrangeButton}`} htmlType="submit">
                                Verify
                            </Button>
                        </Form.Item>
                    </Form>
                </>

            }
        />
    );
};