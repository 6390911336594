/* eslint @typescript-eslint/naming-convention: 0 */

import { Field, UpdateProperties } from '../../../../../types/Field';

export class ConfigProperties {
    mrpDiscountPercentage?: Field<number>;

    creditLimit?: Field<number>;

    // This field is just for viewing purposes.
    credit?: Field<number>;

    creditCyclePeriod?: Field<number>;

    constructor(configProperties: ConfigProperties) {
        this.mrpDiscountPercentage = new Field(configProperties.mrpDiscountPercentage);
        this.creditLimit = new Field(configProperties.creditLimit);
        this.credit = new Field(configProperties.credit);
        this.creditCyclePeriod = new Field(configProperties.creditCyclePeriod);
    }

    public setValues?(establishment: EstablishmentResponse) {
        const mrp_discount_percentage = establishment.metadata?.mrp_discount_percentage;
        this.mrpDiscountPercentage?.setInitialValue?.(
            mrp_discount_percentage,
            new UpdateProperties('Mrp Discount %', 'mrp_discount_percentage'),
        );
        this.creditLimit?.setInitialValue?.(
            establishment.credit_limit,
            new UpdateProperties('Credit Limit', 'credit_limit'),
        );
        this.credit?.setInitialValue?.(establishment.credit);
        this.creditCyclePeriod?.setInitialValue?.(
            establishment.credit_cycle_period_in_days,
            new UpdateProperties('Credit Cycle Period (in days)', 'credit_cycle_period_in_days'),
        );
    }
}