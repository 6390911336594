/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */

import { Table, Modal, Empty, Row } from 'antd';
import * as React from 'react';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ReceivableListResponse, ReceivableResponse, RequestedByPropsType } from '../../../types/FinanceCredit';
import { RUPEE_SYMBOL } from '../../../Constants';
import { StateMetadataRenderer } from '../../common/StateMetadataRenderer';
import { Establishment, UserDatum } from '../../../Types';
import { getISTFromEpoch } from '../../../utilities/Date';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { addKeyToDataList } from '../../../Utils';

type ReceivablesListProps = {
    page: number;
    setPage: (val: number) => void;
    receivablesResponse: ReceivableListResponse;
};

function ReceivablesList(props: ReceivablesListProps) {
    const { page, setPage, receivablesResponse } = props;

    function RequestedBy(props: RequestedByPropsType) {
        return (
            <>
                <span>{props.name}</span>
                <br />
                <span>{props.contact_info}</span>
            </>
        );
    }

    const columns = [
        {
            title: 'Invoice No',
            width: '18%',
            render: (_, record: ReceivableResponse) => {
                if (record.reference_type === 'ORDER') {
                    return <Link to={`/order/${record.reference_id}`}>{record.reference_number}</Link>;
                }
                return record.reference_number;
            },
        },
        {
            title: 'Outlet',
            dataIndex: 'establishment',
            render: (establishment: Establishment) => (
                <Link to={`/establishments/${establishment.uuid}/${EstablishmentType.OUTLET.type}?type=info`}>
                    {establishment.name}
                </Link>
            ),
        },
        {
            title: 'Vendor Name',
            width: '15%',
            render: (user: UserDatum) => <RequestedBy name={user.name} contact_info={user.contact_info} />,
            dataIndex: 'user',
        },
        {
            title: 'Delivery date',
            dataIndex: 'received_date',
            render: (receivedDate: number) => getISTFromEpoch(receivedDate),
        },
        {
            title: 'Due date',
            dataIndex: 'due_date',
            render: (dueDate: number) => getISTFromEpoch(dueDate),
        },
        {
            title: 'Amount',
            render: (amount: number) => `${RUPEE_SYMBOL} ${amount}`,
            dataIndex: 'amount',
        },
        {
            title: 'Total Due',
            render: (outstanding_amount: number) => `${RUPEE_SYMBOL} ${outstanding_amount}`,
            dataIndex: 'outstanding_amount',
        },
        {
            title: '',
            dataIndex: 'uuid',
            width: '10%',
            render: (_, record: ReceivableResponse) => (
                <div className="action-col">
                    <MdSettings
                        className="action-col-button-info"
                        onClick={() => {
                            Modal.info({
                                title: 'Status Tracker',
                                content: <StateMetadataRenderer stateMetadata={record.state_metadata} />,
                            });
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <Row className="main-body-content">
            {receivablesResponse ? (
                <Table
                    className="default-table"
                    columns={columns}
                    dataSource={addKeyToDataList(receivablesResponse?.receivables)}
                    scroll={{ y: 900, x: 900 }}
                    pagination={{
                        pageSize: receivablesResponse.page_size,
                        current: page,
                        total: receivablesResponse.page_count!! * receivablesResponse.page_size,
                        onChange: (page) => {
                            if (page) {
                                setPage(page);
                            }
                        },
                    }}
                />
            ) : (
                <Empty
                    className="main-body-aggregate-empty"
                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                    description='Search for the data using filters mentioned above.'
                />
            )}
        </Row>
    );
}

export default ReceivablesList;
