import { createSlice } from '@reduxjs/toolkit';
import { CategoryResponse } from '../types';

const initialState: CategoryResponse = {
    data: [],
};

const categoryReducer = createSlice({
    name: 'category', //Type will be category/updateCategories
    initialState,
    // Name of the funciton will be same for the action
    reducers: {
        updateCategories(state, action) {
            state.data = action.payload;
        },
    },
});

export const { updateCategories } = categoryReducer.actions;
export default categoryReducer.reducer;
