import { Button, Col, Empty, Row, Select, Spin, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { RUPEE_SYMBOL } from '../../../Constants';
import { AlignType } from 'rc-table/lib/interface';
import { PURCHASE_ORDER_LIST } from '../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { PurchaseOrderListResponse, SummarisedPurchaseOrders } from '../../../types/PurchaseOrders';
import { EstablishmentsDropDown, EstablishmentSelectionMode, getEncodedStringFromUuidList, getUuidListFromEncodedString } from '../../common/establishments/EstablishmentsDropDown';
import TimeFilterPicker from '../../common/TimeFilterPicker';
import { PaymentStatus } from './PaymentStatus';
import { RightOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { addKeyToDataList, isArrayEmpty } from '../../../Utils';


interface FinancePurchaseOrderProps {
    active: boolean;
}

export const FinancePurchaseOrder = (props: FinancePurchaseOrderProps) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { Option } = Select;
    const [page, setPage] = useState<number>(0);
    const [pageSize] = useState<number>(10);
    const [toDate, setToDate] = useState<number>();
    const [fromDate, setFromDate] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [sellerHubUuids, setSellerHubUuids] = useState<string[]>();
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<PaymentStatus>();
    const [purchaseOrderList, setPurchaseOrderList] = useState<PurchaseOrderListResponse>();
    const [purchaseOrderRequested, setPurchaseOrderRequested] = useState<boolean>(false);

    const isRendered = useRef<boolean>(false);

    // URL to state
    const setQueryParamsFromState = (queryParams: URLSearchParams, param: string, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                } else {
                    queryParams.delete(param);
                }
            } else {
                queryParams.set(param, state);
            }
        } else {
            if (['page', 'from-date', 'to-date'].includes(param)) {
                queryParams.delete(param);
            }
        }
    };
    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        if (!props.active) {
            return;
        }
        setQueryParamsFromState(queryParams, 'e-uuids', sellerHubUuids);
        setQueryParamsFromState(queryParams, 'payment-status', selectedPaymentStatus?.key);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'page', page);
        /**
          * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
          * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
          * So we are replacing the page with the help of replace property of navigate method.
        * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    // State to URL
    const setStateFromQueryParams = (queryParams: URLSearchParams, param: string, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const pageNo = parseInt(queryParams.get(param)!);
            stateSetter(pageNo);
        } else if (param == 'payment-status') {
            stateSetter(PaymentStatus.valueOf(queryParams.get(param)!));
        } else {
            stateSetter(queryParams.get(param));
        }
    };
    const setStateFromURLParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setSellerHubUuids);
        setStateFromQueryParams(queryParams, 'payment-status', setSelectedPaymentStatus);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'page', setPage);
    };


    // API calls
    const getPurchaseOrderList = () => {
        if (!selectedPaymentStatus) {
            return;
        }

        setLoading(true);
        const responseHandler: ResponseHandler<PurchaseOrderListResponse> = {
            onResponseSuccess(response: PurchaseOrderListResponse): void {
                setPurchaseOrderList(response);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        // State is not required in this API call, which is why we have kept the first argument as undefined.
        getApiCall(PURCHASE_ORDER_LIST(undefined, sellerHubUuids ?? [], fromDate, toDate, page - 1, pageSize, selectedPaymentStatus?.key), responseHandler);
    };

    const disableGetPurchaseOrder = (): boolean => {
        if (!selectedPaymentStatus) {
            return true;
        }
        if (selectedPaymentStatus.enableDateFilter) {
            if (!fromDate || !toDate) {
                return true;
            }
        }

        return false;
    };

    const setStateAndResetDateValues = (value: string) => {
        setSelectedPaymentStatus(PaymentStatus.valueOf(value));
        setFromDate(undefined);
        setToDate(undefined);
    };

    const columns = [
        {
            title: 'Invoice no.',
            dataIndex: 'invoice_no',
            width: 'wrap-content',
            render: (invoice_no) => <label>{invoice_no ?? 'Not Generated Yet'}</label>,
        },
        {
            title: 'Seller Hub',
            width: 'wrap-content',
            render: (_, purchaseOrder: SummarisedPurchaseOrders) => <label>{purchaseOrder.establishment.name}</label>,

        },
        {
            title: 'Amount',
            width: 'wrap-content',
            dataIndex: 'amount',
            render: (amount) => <span>{`${RUPEE_SYMBOL} ${amount}`}</span>,
        },
        {
            title: 'Payment Status',
            width: 'wrap-content',
            dataIndex: 'payment_status',
            render: (payment_status: string) => <label>{payment_status}</label>,
        },
        {
            align: 'center' as AlignType,
            width: 'wrap-content',
            render: (_, purchaseOrder: SummarisedPurchaseOrders) => <a>
                <RightOutlined
                    onClick={() => {
                        navigate(`/purchase-order/${purchaseOrder.uuid}?make-payment=${selectedPaymentStatus?.isPaymentAllowed}`);
                    }}
                />
            </a>,
        },
    ];

    useEffect(() => {
        if (isRendered.current) {
            setUrlParamsFromState();
            getPurchaseOrderList();
        } else {
            isRendered.current = true;
        }
    }, [page]);

    useEffect(() => {
        setStateFromURLParams();
    }, []);

    useEffect(() => {
        if (props.active) {
            setUrlParamsFromState();
        }
    }, [props.active]);

    useEffect(() => {
        if (!purchaseOrderRequested) {
            return;
        }
        if (page == 1) {
            setUrlParamsFromState();
            getPurchaseOrderList();
        } else {
            setPage(1);
        }
        setPurchaseOrderRequested(false);
    }, [purchaseOrderRequested]);



    return (
        <Spin spinning={loading}>
            <Row gutter={[24, 24]} className='main-body-filters'>
                <Col xs={24} md={5} className='flex-center'>
                    <EstablishmentsDropDown
                        setLoading={setLoading}
                        disableDefaultSelection={true}
                        type={EstablishmentType.SELLER_HUB}
                        selectedUuids={sellerHubUuids!}
                        setSelectedUuids={setSellerHubUuids}
                        fetchEstablishmentList={props.active}
                        selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                    />
                </Col>
                <Col xs={24} md={5} className='flex-center'>
                    <Select
                        placeholder="Select Payment Status"
                        value={selectedPaymentStatus?.display}
                        style={{ width: '100%' }}
                        onChange={setStateAndResetDateValues}
                    >
                        {Object.values(PaymentStatus).map((type, index) => <Option value={type.key} key={index}>
                            {type.display}
                        </Option>)}
                    </Select>
                </Col>
                <Col xs={24} md={8}>
                    <TimeFilterPicker
                        enableFilterPicker={selectedPaymentStatus?.enableDateFilter!}
                        fromDateEpoch={fromDate!}
                        toDateEpoch={toDate!}
                        setFromDateEpoch={setFromDate}
                        setToDateEpoch={setToDate}
                    />
                </Col>
                <Col xs={24} md={6} className='flex-center'>
                    <Button
                        className="default-button"
                        size="large"
                        disabled={disableGetPurchaseOrder()}
                        onClick={() => {
                            setPurchaseOrderRequested(true);
                        }}>
                        Get Purchase order
                    </Button>
                </Col>
            </Row>
            <Row className='main-body-content'>
                <Col xs={24}>
                    {
                        purchaseOrderList && purchaseOrderList.purchase_orders?.length! > 0 ? <Table
                            columns={columns}
                            scroll={{ x: '700px' }}
                            dataSource={addKeyToDataList(purchaseOrderList.purchase_orders)}
                            pagination={{
                                pageSize: purchaseOrderList.page_size,
                                current: page,
                                total: purchaseOrderList.page_count!! * purchaseOrderList.page_size,
                                onChange: (currentPage) => {
                                    if (currentPage) {
                                        setPage(currentPage);
                                    }
                                },
                            }}
                        /> : <Empty
                            className="main-body-aggregate-empty"
                            image={Empty.PRESENTED_IMAGE_DEFAULT}
                            description={
                                purchaseOrderList ? 'No data found!' : 'Search for the data using filters mentioned above.'
                            }
                        />
                    }
                </Col>
            </Row>
        </Spin>
    );
};