import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { layout } from '../../../../styles/css/style';
import { EmptyIcon } from './assests/EmptyIcon';
import { DataNotFoundProps, defaultMessage, defaultTitle } from './types';


export const DataNotFound = (props: DataNotFoundProps) => {
    return (
        <>
            <Row className='h-full'>
                <Col className={`w-full ${layout.flexCol.center}`} >
                    {
                        props.loading ?
                            <Skeleton paragraph={{ rows: 10 }} active />
                            :
                            <>
                                <Row>
                                    <Col className={`w-full  ${layout.flex.center}`}>
                                        <EmptyIcon />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={`w-full text-center  ${layout.flex.center}`}>
                                        <h3> {props.title || defaultTitle}</h3>
                                    </Col>
                                    <Col className={`w-full  ${layout.flex.center}`}>
                                        <p> {props.message || defaultMessage}</p>
                                    </Col>
                                </Row>
                            </>}
                </Col>
            </Row>
        </>
    );
};