import { Button, Col, Drawer, Row, Timeline, Tooltip } from 'antd';
import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsDash } from 'react-icons/bs';
import { FaInfoCircle } from 'react-icons/fa';
import { layout, style } from '../../../../styles/css/style';
import { StateMetaDatum } from '../../../../Types';
import { isArrayEmpty } from '../../../../Utils';
import { getISTFromEpoch } from '../../../../utilities/Date';
import { expandedRowRender } from '../../StateMetadataRenderer';
import { StateMetadataRendererProps } from './types';

export const StateMetadataRendererV2 = (props: StateMetadataRendererProps) => {

    const [stateMetadatum, setStateMetadatum] = useState<StateMetaDatum>();

    return <>
        <Timeline>
            {
                props.stateMetadata.map((datum, index) => {

                    return <Timeline.Item color='green' key={index}>
                        <Row>

                            {/* State and Date Info*/}
                            <Col className='w-1/2 sm:w-1/3 px-3'>
                                <Col className='w-full text-sm'>
                                    {getISTFromEpoch(datum.timestamp!, 'DATE')}
                                </Col>
                                <Col className='w-full text-md font-semibold'>
                                    {
                                        isArrayEmpty(datum.data) ?
                                            datum.display
                                            :
                                            <a
                                                className='text-meraai-orange underline hover:underline hover:text-meraai-orange'
                                                onClick={() => {
                                                    setStateMetadatum(datum);
                                                }}
                                            >
                                                {datum.display}
                                            </a>
                                    }
                                </Col>
                            </Col>

                            {/* User Info */}
                            <Col className='sm:w-1/3 px-3 hidden sm:block'>
                                <Col className='w-full text-sm font-medium text-meraai-lightgrey'>
                                    User
                                </Col>
                                <Col className='w-full text-md font-semibold items-center flex'>

                                    {
                                        datum.user?.name ?
                                            <>
                                                <label>{datum.user?.name}</label>
                                                <Tooltip
                                                    title={`Role: ${datum.user?.role} ${datum.user?.contact_info ?? ''}`}
                                                    placement='right'>
                                                    <FaInfoCircle className={`${style.infoIcon}`} />
                                                </Tooltip>
                                            </>

                                            :

                                            <BsDash />
                                    }

                                </Col>

                            </Col>

                            {/* Time Info*/}
                            <Col className='w-1/2 sm:w-1/3 px-3'>
                                <Col className={`w-full text-sm font-medium ${layout.flex.end}`} >
                                    {getISTFromEpoch(datum.timestamp!, 'TIME')}
                                </Col>
                            </Col>

                        </Row>
                    </Timeline.Item>;

                })
            }
        </Timeline>

        <Drawer
            className='meraai-drawer'
            title={stateMetadatum?.display}
            open={stateMetadatum !== undefined}
            extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                setStateMetadatum(undefined);
            }} />}
            closable={false}
            destroyOnClose
            maskClosable={false}
            keyboard={false}
            footer={
                <>
                    <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                        <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                            <Button
                                className={`w-full ${style.meraaiOrangeButton}`}
                                onClick={() => {
                                    setStateMetadatum(undefined);
                                }}
                            >
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        >
            {
                stateMetadatum ?
                    expandedRowRender(stateMetadatum) : <></>
            }
        </Drawer>
    </>;

};