import { Col, Row } from 'antd';
import React from 'react';
import { QUANTITY_SYMBOL } from '../../../../../Constants';
import { SkuInfoProps } from '../../../../../Types';
import { formatCurrency } from '../../../../../Utils';
import { DetailsCard } from '../../../../common/detailsCard';
import { DetailsCardProps } from '../../../../common/detailsCard/types';
import { ProductNameInfoProps } from './types';

export const ProductNameInfo = (props: ProductNameInfoProps) => {
    return (
        <Row className='flex-column'>
            <Col className='w-full font-semibold'> {props.name} </Col>
            <Col className='w-max px-2 py-0.5 text-sm text-meraai-orange bg-meraai-lightorange'> {props.brand} </Col>
        </Row>
    );
};

export const Quantity = (props: { quantity?: number | string, styleClass?: string }) => {
    return <>
        <label className={`font-semibold ${props.styleClass}`}>{QUANTITY_SYMBOL} {props.quantity ?? '0'}</label>
    </>;
};

export const Currency = (props: { value: number, styleClass?: string }) => {
    return <label className={`text-meraai-brown font-semibold ${props.styleClass}`}>{formatCurrency(props.value)}</label>;
};

export const TotalSummary = (details: DetailsCardProps) => {
    return <Row>
        <Col className='w-0 xs:w-1/2 sm:w-2/3'></Col>
        <Col className='w-4/6 xs:w-1/2 sm:w-1/3'>
            <DetailsCard
                labelStyleClass='!w-1/2'
                valueStyleClass='!w-1/2'
                data={
                    details.data?.map((orderItem) => {
                        return {
                            label: orderItem.label,
                            value: orderItem.value,
                        };
                    })
                }
                divider={true}
                removePadding={true}
            />
        </Col>
    </Row>;
};

export function SkuInfo(props: SkuInfoProps) {
    return (
        <>
            <span className='font-semibold'>{props.name}</span>
            <br />
            <span className='text-sm'>{props.sku}</span>
        </>
    );
}