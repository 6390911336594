import { Badge, Button, Col, Empty, Form, Input, message, Row, Spin, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks';
import { LIST_HUB_PRODUCTS } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { EstablishmentType } from '../../../../../types/EstablishmentType';
import { ProductListingType } from '../../../../../types/Product';
import BrandListingDrawer from '../../../../cart/BrandListingDrawer';
import { PRODUCT_PAGE_SIZE } from '../../../../cart/CreateOrder';
import { EstablishmentsDropDown, EstablishmentSelectionMode } from '../../../../common/establishments/EstablishmentsDropDown';
import { Brand, ListProductsResponse, Product } from '../../../../orders/listing/ListingData';
import { HubProductListingType } from '../products/hub/types';
import { PurchaseOrderCart } from './PurchaseOrderCart';
import { PurchaseOrderCartItem } from './PurchaseOrderCartItem';
import { PurchaseOrderProductList } from './PurchaseOrderProductList';

type SelectedItemsAsObjectProperties = {
    [id: number]: PurchaseOrderCartItem;
};

function getCartItemFromProductItem(referenceId: string, product: Product): PurchaseOrderCartItem {
    return new PurchaseOrderCartItem({
        key: product.id,
        id: product.id,
        brand: product.brand,
        ean: product.ean!,
        name: product.name,
        quantity: 0,
        image: product.image,
        reference_id: referenceId,
    });
}

export const CreatePurchaseOrder = () => {

    // Hooks
    const { establishmentUuid: hubUuid } = useParams();

    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [sellerHubUuid, setSellerUuid] = useState<string>();
    const [productName, setProductName] = useState<string>('');
    const [selectedBrand, setSelectedBrand] = useState<Brand>();
    const [cartModalVisible, setCartModalVisible] = useState<boolean>(false);
    const [fetchEstablishmentList] = useState<boolean>(true);
    const [brandsDrawerVisibility, setBrandsDrawerVisibility] = useState<boolean>(false);
    const [productListResponse, setProductListResponse] = useState<ListProductsResponse>();
    const [cartReferenceId, setCartReferenceId] = useState<string>();

    const currentProductName = useRef<string>();
    const shouldGetProducts = useRef<boolean>(false);
    const currentListingType = useRef<ProductListingType>();
    const productListMessage = useRef<string>('Search for the data using filters mentioned above.');


    useEffect(() => {
        // We are creating a unique id in order to store the cart data for each individual hud+sellerhub combination  
        setCartReferenceId(hubUuid! + '-' + sellerHubUuid);
    }, [sellerHubUuid]);

    // Method
    const cartDataForEstablishment = useAppSelector(state => {
        if (!cartReferenceId) {
            return {
                selectedItems: [],
                selectedItemsCount: 0,
                lastUpdatedAt: 0,
            };
        }
        return state.purchaseOrderCart.cart[cartReferenceId!];
    });

    const selectedItemsAsObject: SelectedItemsAsObjectProperties = {};
    cartDataForEstablishment?.selectedItems.forEach((item: PurchaseOrderCartItem) => (selectedItemsAsObject[item.id!] = new PurchaseOrderCartItem(item)));
    let finalProductList: PurchaseOrderCartItem[] = [];
    if (productListResponse) {
        finalProductList = productListResponse?.products.map(productItem => {
            if (productItem.id in selectedItemsAsObject) {
                return selectedItemsAsObject[productItem.id];
            }
            return getCartItemFromProductItem(cartReferenceId!, productItem);
        });
    }


    const getProducts = async () => {
        if (!shouldGetProducts.current) {
            return;
        }
        setLoading(true);

        const productsListEndPoint = LIST_HUB_PRODUCTS(
            hubUuid!,
            selectedBrand?.id.toString()!,
            HubProductListingType.EXISTING,
            sellerHubUuid!,
            page - 1,
            PRODUCT_PAGE_SIZE,
            productName,
        );

        const responseHandler: ResponseHandler<ListProductsResponse> = {
            onResponseSuccess(value: ListProductsResponse): void {
                setLoading(false);
                if (value.products.length === 0) {
                    message.info('No products found', 3);
                }
                setProductListResponse(value);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(productsListEndPoint, responseHandler);
    };


    const onBrandItemClick = (brand: Brand) => {
        setBrandsDrawerVisibility(false);
        if (selectedBrand?.id != brand.id || productName !== '') {
            currentListingType.current = ProductListingType.BRAND;
            setProductName('');
            shouldGetProducts.current = true;
            setSelectedBrand(brand);
            setPage(1);
        }
    };

    const searchProductsByName = () => {
        setBrandsDrawerVisibility(false);
        currentProductName.current = productName;
        currentListingType.current = ProductListingType.NAME;
        shouldGetProducts.current = true;
        if (page !== 1) {
            setPage(1);
        } else {
            getProducts();
        }
    };


    useEffect(() => {
        getProducts();
    }, [selectedBrand, page]);

    return (
        <>
            <div className="filter-drawer-div">
                <Spin spinning={loading}>
                    <Row gutter={[24, 24]} className="flex-row-right-align main-body-filters margin-top-10px">
                        <Col xs={24} md={6} xl={4} className="flex-center">
                            <EstablishmentsDropDown
                                setLoading={setLoading}
                                disableDefaultSelection={true}
                                type={EstablishmentType.SELLER_HUB}
                                selectedUuids={sellerHubUuid!}
                                setSelectedUuids={setSellerUuid}
                                fetchEstablishmentList={fetchEstablishmentList}
                                selectionMode={EstablishmentSelectionMode.SINGLE_SELECT}
                            />
                        </Col>

                        <Col xs={24} md={8} xl={3} className="flex-center">
                            <Button disabled={!sellerHubUuid} className="default-button" size='large' onClick={() => {
                                setBrandsDrawerVisibility(true);
                            }}>
                                Get Brands
                            </Button>
                        </Col>

                        <Col xs={24} md={8} xl={10} className="flex-center">
                            <Badge count={cartDataForEstablishment?.selectedItems.length}>
                                <Button
                                    icon={<FaShoppingCart color="#F69620" />}
                                    size="middle"
                                    className="default-button"
                                    onClick={() => setCartModalVisible(true)}>
                                    &nbsp;&nbsp;Cart
                                </Button>
                            </Badge>
                        </Col>
                    </Row>

                    {/* Search products section */}
                    <Row>
                        <Col xl={24} xs={24}>
                            {
                                sellerHubUuid &&
                                <Form
                                    className="create-product-form"
                                    initialValues={{ remember: true }}
                                    onFinish={() => {
                                        searchProductsByName();
                                    }}
                                    onFinishFailed={() => { }}
                                    autoComplete="off">
                                    <Form.Item className="create-product-form-item1">
                                        <Input
                                            className="create-product-form-input-tag"
                                            placeholder="Search products by name"
                                            value={productName}
                                            onChange={event => setProductName(event.currentTarget.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item className="create-product-form-item2">
                                        <Tooltip
                                            title={selectedBrand?.name === undefined ? 'Please click on get brands and select a brand.' : undefined}
                                        >
                                            <Button
                                                className="create-product-form-item2-button"
                                                size="large"
                                                htmlType="submit"
                                                disabled={selectedBrand?.name === undefined || productName === currentProductName.current}
                                            >
                                                Get Products
                                            </Button>
                                        </Tooltip>
                                    </Form.Item>
                                </Form>
                            }
                        </Col>
                    </Row>
                    <Row gutter={[0, 24]} className="main-body-content margin-top-10px">
                        {
                            selectedBrand && <Col xs={24} className='flex-center'>
                                <Tag color="cyan" className="flex-align-center" style={{ fontSize: '15px' }}>
                                    {'Selected brand '} &nbsp;
                                    <b>{selectedBrand?.name}</b>
                                </Tag>
                            </Col>
                        }

                        <Col xs={24}>
                            {productListResponse && productListResponse.products.length > 0 ? (
                                <PurchaseOrderProductList
                                    dataSource={finalProductList}
                                    height="62vh"
                                    pagination={{
                                        pageSize: PRODUCT_PAGE_SIZE,
                                        current: page,
                                        total: productListResponse.page_count * PRODUCT_PAGE_SIZE,
                                        onChange: pageClickedOn => {
                                            setPage(pageClickedOn);
                                        },
                                    }}
                                />
                            ) : (
                                <Empty
                                    className="main-body-aggregate-empty"
                                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                                    description={productListMessage.current}
                                />
                            )}
                        </Col>
                    </Row>
                </Spin>

                {sellerHubUuid && (
                    <BrandListingDrawer
                        brandsDrawerVisibility={brandsDrawerVisibility}
                        setBrandsDrawerVisibility={setBrandsDrawerVisibility}
                        establishmentUuid={typeof sellerHubUuid === 'string' ? sellerHubUuid! : ''}
                        onBrandItemClick={onBrandItemClick}
                    />
                )}

                <PurchaseOrderCart
                    sellerHubUuid={sellerHubUuid!}
                    hubUuid={hubUuid!}
                    productsLoading={loading}
                    cartReferenceId={cartReferenceId!}
                    cartModalVisible={cartModalVisible}
                    setCartModalVisible={setCartModalVisible}
                    setBrandsDrawerVisibility={setBrandsDrawerVisibility}
                />
            </div>
        </>

    );
};