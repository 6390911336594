/* eslint @typescript-eslint/no-unused-expressions: 0 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IS_LOGGED_IN, USER_ID, USER_ROLE } from '../../../../Constants';
import { Role } from '../../../../types/Role';
import { LoginResponse } from '../types';

interface LoginState {
    loginInput: String;
    loggedIn: Boolean;
    role: Role;
}

const initialState: LoginState = {
    loginInput: localStorage.getItem(USER_ID) != null ? localStorage.getItem(USER_ID)!! : '',
    loggedIn: localStorage.getItem(IS_LOGGED_IN) == 'true',
    role: localStorage.getItem(USER_ROLE) !== null ? Role[localStorage.getItem(USER_ROLE)!!] : Role.VENDOR,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        updateLoginInput(state, action: PayloadAction<string>) {
            state.loginInput = action.payload;
            localStorage.setItem(USER_ID, action.payload);
        },

        updateLoginState(state, action: PayloadAction<boolean>) {
            state.loggedIn = action.payload;
            action.payload ? localStorage.setItem(IS_LOGGED_IN, 'true') : localStorage.setItem(IS_LOGGED_IN, 'false');
        },

        updateLoginSuccessfulData(state, action: PayloadAction<LoginResponse>) {
            state.loggedIn = true;
            state.role = action.payload.role;
            localStorage.setItem(IS_LOGGED_IN, 'true');
            localStorage.setItem(USER_ROLE, action.payload.role);
        },
    },
});

export const { updateLoginInput, updateLoginState, updateLoginSuccessfulData } = loginSlice.actions;
export default loginSlice.reducer;
