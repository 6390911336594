/* eslint react/prop-types: 0 */
import { useLocation, useNavigate } from 'react-router-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Image, Row, Tabs, Skeleton, message, Modal, Spin, InputNumber } from 'antd';
import Title from 'antd/lib/typography/Title';
import {
    deleteApiCall,
    getStatus,
} from '../../utilities/Constant';
import Map from '../map/Map';
import { EstablishmentType } from '../../types/EstablishmentType';

import { putApiCall, ResponseHandler } from '../../http/HttpClient';
import { APPLICANT_ONBOARD, APPLICANT_REJECT } from '../../http/EndPoints';
import { isObjectEmpty } from '../../Utils';
import { ApplicantOnboardingDetails, BrandDiscount, OnboardingRequest } from '../../types/Applicants';
import { RUPEE_SYMBOL } from '../../Constants';
import { EstablishmentsDropDown, EstablishmentSelectionMode } from '../common/establishments/EstablishmentsDropDown';

const statusList = {
    APPLIED: true,
    ONBOARDED: false,
    REJECTED: false,
};

type LocationState = {
    singleUserData: ApplicantOnboardingDetails;
};

const ApplicantDetails = () => {
    const { TabPane } = Tabs;
    const { confirm } = Modal;
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedHubUuid, setSelectedHubUuid] = useState<string>();
    const [onboardModalVisibility, setOnboardModalVisibility] = useState<boolean>(false);
    const [userDetail, setUserDetail] = useState<ApplicantOnboardingDetails>();
    const [spinner, setSpinner] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [brandDiscounts, setBrandDiscounts] = useState<BrandDiscount[]>([]);
    const [creditLimit, setCreditLimit] = useState<number>();

    /**
   ** Checking visibility of the action button.
   ** If applicant status is APPLIED then only show onbaord and reject button.
   * */
    const checkStatusToHideButtons = status => {
        if (status == getStatus('onboarded')) message.success('Applicant is already onboarded', 3, () => { });
        else if (status === getStatus('rejected')) message.error('Applicant is already rejected', 3, () => { });

        return statusList[status];
    };

    /** ************ Setting data to state from useLocation ************* */
    const setDataToUserDetails = () => {
        if (location.state != null) {
            const state = location.state as LocationState;
            if (!isObjectEmpty(state?.singleUserData)) {
                setUserDetail(state?.singleUserData);
                setBrandDiscounts(state?.singleUserData?.metadata?.brands);
                setCreditLimit(state?.singleUserData?.metadata?.credit_limit ?? 0);
                setShowBtn(checkStatusToHideButtons(state.singleUserData.status));
            }
        } else {
            message.error('Something went wrong', 3, () => { });
        }
        setSpinner(false);
    };


    const onboardUser = () => {
        setSpinner(true);
        const requestBody: OnboardingRequest = {
            sourcing_establishments: [
                {
                    uuid: selectedHubUuid!,
                    priority: 1,
                },
            ],
            brands: brandDiscounts?.map((item) => {
                return { brand_id: item.id, discount_percentage: item.discount };
            }),
            credit_limit: creditLimit ?? 0,
        };

        const responseHandler: ResponseHandler<{}> = {
            onResponseSuccess: function (): void {
                message.success('Onboarded Successfully', 3, () => {
                    navigate('/applicants');
                });
                setOnboardModalVisibility(false);
                setSpinner(false);
            },
            onResponseFailed: function (): void {
                setSpinner(false);
            },
            onResponseError: function (): void {
                setSpinner(false);
            },
        };
        putApiCall(APPLICANT_ONBOARD(userDetail?.uuid), requestBody, responseHandler);
    };

    const rejectUser = url => {
        deleteApiCall(url)
            .then(() => {
                message.success('Hey! Rejected Successfully.', 2, () => {
                    navigate('/applicants');
                });
                setSpinner(false);
            })
            .catch(err => {
                if (err.response) {
                    setSpinner(false);
                } else {
                    setSpinner(false);
                }
            });
    };

    const onboardModal = () => {
        setOnboardModalVisibility(true);
    };


    const rejectModal = () => {
        confirm({
            title: 'Reject this user?',
            icon: <ExclamationCircleOutlined />,
            content: 'This user will get removed from Applied Users list',
            okText: 'Yes',
            okType: 'danger',
            okButtonProps: {
                disabled: false,
            },
            cancelText: 'No',
            onOk() {
                setSpinner(true);
                const endPoint = APPLICANT_REJECT(userDetail?.uuid);
                return rejectUser(endPoint);
            },
            onCancel() { },
        });
    };

    useEffect(() => {
        setSpinner(true);
        setDataToUserDetails();
    }, []);

    const updateDiscountPercentageForBrand = (id: number, discountPercentage: number) => {
        brandDiscounts.find((item) => item.id == id)!!.discount = discountPercentage;
        setBrandDiscounts(brandDiscounts);
    };

    const getBrandDiscountItem = (item: BrandDiscount) => {
        return (
            <Row key={item.id} gutter={[24, 24]} style={{ marginBottom: '15px' }}>
                <Col xl={12} xs={12}>{item.name}</Col>
                <Col xl={6} xs={12} >
                    <InputNumber
                        maxLength={3}
                        max={100}
                        min={0}
                        controls={false}
                        style={{ width: '80%' }}
                        placeholder="MRP Discount Percentage"
                        defaultValue={item.discount}
                        onChange={value => updateDiscountPercentageForBrand(item.id, value)} />
                    <span style={{ marginLeft: '10px' }}>%</span>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Spin spinning={spinner} tip="Loading..." size="small" style={{ height: '100%' }}>
                <div className="main-title">
                    <Row>
                        <Col span={12}>
                            <Title level={3}>Applicant Information</Title>
                        </Col>
                        <Col style={{ textAlign: 'end' }} span={8} />
                        <Col span={4} />
                    </Row>
                </div>

                <div className="main-body">
                    <Tabs defaultActiveKey="1">
                        <TabPane key="1">
                            <Card className="main-body-card" title="Store Details">
                                <Row className="main-body-card-item">
                                    <Col span={12}>
                                        <Row>
                                            <Col span={12}>Name</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.establishment_name
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>Address</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.address
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>Pincode</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.pin_code
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} style={{ alignItems: 'center' }}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton.Image />
                                        ) : (
                                            <Image width={350} src={userDetail?.metadata.establishment_image_url} />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="main-body-card-item">
                                    <Col span={6} className="location-div">
                                        Location
                                    </Col>
                                    <Col span={16} style={{ width: '150px', height: '350px' }}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton active paragraph={{ rows: 1 }} />
                                        ) : (
                                            <Map
                                                center={{
                                                    latitude: userDetail?.metadata.latitude,
                                                    longitude: userDetail?.metadata.longitude,
                                                }}
                                                zoomLevel={17}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                            <Card className="main-body-card" title="Owner's Details" style={{}}>
                                <Row className="main-body-card-item">
                                    <Col span={12}>
                                        <Row>
                                            <Col span={12}>Name</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    `${userDetail?.metadata.agent_first_name} ${userDetail?.metadata.agent_last_name}`
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>Contact</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.mobile_number
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>GST Number</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.gst_in
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} className="profile-photo-div" style={{ alignItems: 'center' }}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton.Image />
                                        ) : (
                                            <Image
                                                className="profile-photo"
                                                style={{ borderRadius: '50%', width: '150px' }}
                                                width={200}
                                                src={userDetail?.metadata.agent_image_url}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="main-body-card-item">
                                    <Col span={6}>Pan Number</Col>
                                    <Col span={6}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton active paragraph={{ rows: 0 }} />
                                        ) : (
                                            userDetail?.metadata.pan_number
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} />
                                    <Col span={6}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton.Image />
                                        ) : (
                                            <Image
                                                className="profile-photo"
                                                style={{}}
                                                width={200}
                                                src={userDetail?.metadata.pan_image_url}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="main-body-card-item">
                                    <Col span={6}>Aadhaar Number</Col>
                                    <Col span={6}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton active paragraph={{ rows: 0 }} />
                                        ) : (
                                            userDetail?.metadata.aadhaar_number
                                        )}
                                    </Col>
                                </Row>
                                <Row className="main-body-card-item">
                                    <Col span={6} />
                                    <Col span={6}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton.Image />
                                        ) : (
                                            <Image
                                                className="profile-photo"
                                                style={{}}
                                                width={200}
                                                src={userDetail?.metadata.aadhaar_front_image_url}
                                            />
                                        )}
                                    </Col>
                                    <Col span={6}>
                                        {isObjectEmpty(userDetail) ? (
                                            <Skeleton.Image />
                                        ) : (
                                            <Image
                                                className="profile-photo"
                                                style={{}}
                                                width={200}
                                                src={userDetail?.metadata.aadhaar_back_image_url}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                            <Card className="main-body-card" title="Bank Details" style={{}}>
                                <Row className="main-body-card-item">
                                    <Col span={12}>
                                        <Row className="main-body-card-sub-item">
                                            <Col span={12}>Account Number </Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.bank_account_details.account_number
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="main-body-card-sub-item">
                                            <Col span={12}>IFSC Code</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.bank_account_details.ifsc_code
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="main-body-card-sub-item">
                                            <Col span={12}>Account Holder Name</Col>
                                            <Col span={12}>
                                                {isObjectEmpty(userDetail) ? (
                                                    <Skeleton active paragraph={{ rows: 0 }} />
                                                ) : (
                                                    userDetail?.metadata.bank_account_details.account_holder_name
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>

                            <Card className="main-body-card" title={brandDiscounts ? 'Brand Discounts & Credit Limit' : 'Credit Limit'}>
                                {
                                    brandDiscounts?.map((item) => {
                                        return getBrandDiscountItem(item);
                                    })
                                }
                                <Row gutter={[24, 24]} style={{ marginBottom: '10px' }}>
                                    <Col xl={12} xs={12}>Credit Limit</Col>
                                    <Col xl={6} xs={12} >
                                        <span style={{ marginRight: '10px' }}>{RUPEE_SYMBOL}</span>
                                        <InputNumber
                                            maxLength={6}
                                            max={100000}
                                            min={0}
                                            controls={false}
                                            style={{ width: '80%' }}
                                            placeholder="Credit Limit"
                                            value={creditLimit}
                                            onChange={value => setCreditLimit(value)} />
                                    </Col>
                                </Row>

                                {showBtn ? (
                                    <Row className="main-body-card-item">
                                        <Col span={12} className="onboard-button-div">
                                            <Button
                                                className="accept-button"
                                                size="large"
                                                onClick={() => {
                                                    onboardModal();
                                                }}>
                                                Onboard
                                            </Button>
                                        </Col>
                                        <Col span={12} className="reject-button-div">
                                            <Button
                                                className="default-danger-button"
                                                size="large"
                                                onClick={() => {
                                                    rejectModal();
                                                }}>
                                                Reject
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}

                            </Card>

                        </TabPane>
                    </Tabs>
                </div>
            </Spin>
            <Modal
                title='Hub list'
                className='resposive-modal'
                open={onboardModalVisibility}
                onOk={onboardUser}
                okText='Proceed'
                okButtonProps={{ disabled: selectedHubUuid === undefined }}
                onCancel={() => {
                    setSelectedHubUuid(undefined);
                    setOnboardModalVisibility(false);
                }}
            >
                <Spin spinning={spinner}>
                    <EstablishmentsDropDown
                        setLoading={setSpinner}
                        type={EstablishmentType.HUB}
                        setSelectedUuids={setSelectedHubUuid}
                        selectedUuids={selectedHubUuid}
                        selectionMode={EstablishmentSelectionMode.SINGLE_SELECT}
                        fetchEstablishmentList
                    />
                </Spin>
            </Modal>
        </>
    );
};

export default ApplicantDetails;
