import React from 'react';
import { Form } from 'antd';
import { CREATE_BRAND } from '../../../../../../http/EndPoints';
import { postApiCall, ResponseHandler } from '../../../../../../http/HttpClient';
import { CreateBrandProps } from './type';
import { BrandModal } from '../common/BrandModal';
import { SuccessModal } from './successModal';
import { BrandSaveMode } from '../common/BrandSaveMode';
import { Brand } from '../../../../../orders/listing/ListingData';

export const CreateBrand = (props: CreateBrandProps) => {


    const [createBrandForm] = Form.useForm();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [successModalVisibility, setSuccessModalVisibility] = React.useState<boolean>(false);


    const onModalClose = () => {
        props.setCreateBrandModalVisibility(false);
    };


    const closeModalAndResetForm = () => {
        onModalClose();
        createBrandForm.resetFields();
    };


    const createBrand = (brandDetails: Brand) => {

        setLoading(true);

        brandDetails.establishment_uuid = props.establishmentUuid;

        const responseHandler: ResponseHandler<Brand> = {
            onResponseSuccess(): void {
                closeModalAndResetForm();
                setLoading(false);
                setSuccessModalVisibility(true);
                props.setBrandListRequested(true);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        postApiCall(CREATE_BRAND, brandDetails, responseHandler);
    };

    return (
        <>
            <BrandModal
                mode={BrandSaveMode.CREATE}

                brandModalVisibility={props.createBrandModalVisibility}

                loading={loading}
                setLoading={setLoading}

                brandForm={createBrandForm}
                onFormSubmit={createBrand}

                onModalClose={onModalClose}
            />

            <SuccessModal
                successModalVisibility={successModalVisibility}
                setSuccessModalVisibility={setSuccessModalVisibility}
                setCreateBrandModalVisibility={props.setCreateBrandModalVisibility}
            />
        </>
    );
};