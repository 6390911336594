import { Button, Card, Col, Collapse, Image, message, Modal, Row, Skeleton, Switch, Tag, Tooltip } from 'antd';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { TbDownload } from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import { DOWNLOAD_BILL, DOWNLOAD_ORDER_DETAILS, ORDER_DETAILS, UPDATE_ORDER } from '../../../../../http/EndPoints';
import { getApiCall, putApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { layout, style } from '../../../../../styles/css/style';
import { MiniOrder, OrderDetailsResponse, OrderInventoryResponse, OrderItem, Payments, Source } from '../../../../../types/Order';
import { DetailsCard } from '../../../../common/detailsCard';
import { PageBreadcrumbHeader } from '../../../../common/pageBreadcrumbHeader';
import { DetailsPage } from '../../../../common/templates/detailsPage';
import ConfigContext from '../../../../config/ConfigContext';
import { OrderDetailsConfig } from '../../../../config/types/ConfigTypes';
import { CheckCircleTwoTone, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { defaultOrderState, OrderStates, orderStatesMap } from '../../../hubsAndOutlets/utils';
import { BillType } from './types';
import { MeraaiTable } from '../../../../common/templates/meraaiTable';
import { addKeyToDataList, formatCurrency, isArrayEmpty } from '../../../../../Utils';
import { MdEdit, MdLocationOn } from 'react-icons/md';
import { StateMetaDatum } from '../../../../../Types';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import { FaInfoCircle } from 'react-icons/fa';
import { SellerOrderList } from './sellerOrders';
import { getISTFromEpoch } from '../../../../../utilities/Date';
import { OrderStateChangeActionables } from '../../utils/orderStateChangeActionables';
import { DataNotFound } from '../../../../common/templates/dataNotFound';
import { Currency, Quantity, ProductNameInfo } from './utils';
import { OrderPaymentCollection } from '../../../hubsAndOutlets/OrderPaymentCollection';
import { DetailsCardProps } from '../../../../common/detailsCard/types';
import { StateMetadataRendererV2 } from '../../../../common/templates/stateMetadataRenderer';
import { StateMetadataRenderer } from '../../../../common/StateMetadataRenderer';

export const OrderDetails = () => {
    // Hooks
    const config = React.useContext(ConfigContext) as OrderDetailsConfig;
    const orderItemStockQuantityMap = new Map<number, number>();

    const navigate = useNavigate();
    const { orderUuid } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [orderDetails, setOrderDetails] = useState<OrderDetailsResponse>();
    const [itemStatusTracker, setitemStatusTracker] = useState<StateMetaDatum[]>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [searchName, setSearchName] = useState<string>('');

    // Methods
    const getOrderDetailsResponseHandler = (response) => {
        Modal.error({
            title: 'Oops!',
            content: response[0],
            okText: 'Go back',
            onOk: () => {
                navigate(-1);
            },
        });
    };

    // GET ORDER DETAILS {API CALL}
    const getOrderDetails = async () => {
        setLoading(true);

        const endPointOrderDetails = ORDER_DETAILS(orderUuid!);
        const responseHandler: ResponseHandler<OrderDetailsResponse> = {
            onResponseSuccess(orderDetailsResponse: OrderDetailsResponse): void {
                // Storing the whole object in orderDetails state
                setOrderDetails(orderDetailsResponse);
                // Disabled screen loader
                setLoading(false);
            },
            onResponseFailed(errors: string[]): void {
                getOrderDetailsResponseHandler(errors);
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        await getApiCall(endPointOrderDetails, responseHandler, {}, {}, true);
    };

    const downloadBill = async (type: BillType) => {
        setLoading(true);
        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess(data: any): void {
                if (data) {
                    download(
                        new Blob([data]),
                        BillType.getFileName(type, orderDetails!),
                        'application/pdf',
                    );
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(DOWNLOAD_BILL(orderUuid!, type), responseHandler, {}, { responseType: 'blob' });
    };

    const downloadOrderDetailsInCsv = async () => {
        setLoading(true);
        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess(data: any): void {
                if (data) {
                    download(new Blob([data]), `order-${orderDetails?.invoice_no}.csv`, 'application/csv');
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(DOWNLOAD_ORDER_DETAILS(orderUuid!), responseHandler, {}, { responseType: 'blob' });
    };

    const updateStockInOrderItems = (inventoryResponse: OrderInventoryResponse) => {

        inventoryResponse.items.forEach((eachItem) => {
            orderItemStockQuantityMap.set(eachItem.item_id, eachItem.available_stock);
        });

        orderDetails?.items.forEach((item: OrderItem) => {
            if (orderItemStockQuantityMap.has(item.id)) {
                item.availableQuantity = item.actual_quantity = orderItemStockQuantityMap.get(item.id)!;
                item.availableQuantitySource = 'INVENTORY';
            } else {
                item.availableQuantity = item.actual_quantity = 0;
            }
        });

    };

    const newChildOrderModal = (childOrder: MiniOrder): void => {

        Modal.confirm({
            title: childOrder?.source_display,
            cancelButtonProps: { hidden: true },
            className: 'center-modal-button',
            closable: true,
            closeIcon: <CloseOutlined twoToneColor="#52c41a" />,
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            content: `Order no ${childOrder?.receipt_no} has been generated`,
            okText: 'Open',
            okButtonProps: { disabled: childOrder?.uuid === undefined },
            onOk() {
                navigate(`/order/${childOrder.uuid}`);
            },
        });
    };

    const GetOrderDetailTitle = (property?: { showReceiptNo?: boolean }) => {
        if (orderDetails?.invoice_no && !property?.showReceiptNo) {
            return <div> {`${orderDetails?.invoice_no}`} </div>;
        } else {
            return <div className='font-semibold'> Order Number {` #${orderDetails?.receipt_no}`} </div>;
        }
    };

    const Tags = () => {
        return (
            <Row className='py-1'>
                <Col>
                    {
                        orderDetails?.state ?
                            <Tag className='px-5 py-1' color={
                                orderStatesMap.has(OrderStates.valueOf(orderDetails.state)!) ?
                                    orderStatesMap.get(OrderStates.valueOf(orderDetails.state)!)!.color
                                    :
                                    defaultOrderState.color}
                            >
                                {orderStatesMap.get(OrderStates.valueOf(orderDetails.state)!)?.display}
                            </Tag>
                            : <></>
                    }
                    {
                        orderDetails?.source && orderDetails.source !== Source.BILLING ?
                            <Tag className='px-5 py-1' color="orange">
                                {orderDetails.source_display}
                            </Tag>
                            : <></>
                    }
                    {
                        orderDetails?.partially_deliverable &&
                        <Tag className='px-5 py-1' color="geekblue">
                            Partially Deliverable
                        </Tag>
                    }
                </Col>

            </Row>
        );
    };

    const OrderDownloadables = () => {
        return (
            <Row gutter={[12, 12]} className={`w-full xl:w-2/3 ${layout.flex.end}`}>
                <Col className={`${orderDetails?.invoice_no ? ' w-1/3' : 'w-1/2'} sm:w-max`}>
                    <Tooltip title="Downloads a csv report">
                        <Button
                            className={`${layout.flex.center} ${style.meraaiPlainButton}`}
                            onClick={downloadOrderDetailsInCsv}
                        >
                            <div className={`${layout.flex.center}`}>
                                <TbDownload />
                                <label className='whitespace-pre-line text-xs xs:text-sm px-1 font-semibold'>Report</label>
                            </div>

                        </Button>
                    </Tooltip>
                </Col>

                <Col className={`${orderDetails?.invoice_no ? 'w-1/3' : 'w-1/2'} sm:w-max`}>
                    <Button
                        className={`${layout.flex.center} ${style.meraaiPlainButton}`}
                        onClick={() => downloadBill(BillType.PACKING_LIST)}
                    >
                        <div className={`${layout.flex.center}`}>
                            <TbDownload />
                            <label className='whitespace-pre-line text-xs xs:text-sm px-1 font-semibold'>Packing List</label>
                        </div>

                    </Button>
                </Col>

                {orderDetails?.invoice_no &&
                    <Col className='w-1/3 sm:w-max'>
                        <Button
                            className={`${layout.flex.center} ${style.meraaiOrangeButton}`}
                            onClick={() => downloadBill(BillType.INVOICE)}
                        >
                            <label className='whitespace-pre-line text-xs xs:text-sm px-1 font-semibold'>Download</label>
                        </Button>
                    </Col>
                }
            </Row>
        );
    };

    // Partial delivery
    const PartialDeliverableSwitch = (props: { accessible: boolean, defaultValue: boolean }) => {
        const [switchLoading, setSwitchLoading] = useState<boolean>(false);

        const updateOrder = (value) => {
            setSwitchLoading(true);
            const requestBody = { 'partially_deliverable': value };

            const responseHandler: ResponseHandler<OrderDetailsResponse> = {
                onResponseSuccess(response: OrderDetailsResponse): void {
                    message.destroy();
                    message.success(response.message);
                    setOrderDetails({ ...response });
                    setSwitchLoading(false);
                },
                onResponseFailed() {
                    setSwitchLoading(false);
                },
                onResponseError() {
                    setSwitchLoading(false);
                },
            };
            putApiCall(UPDATE_ORDER(orderDetails?.uuid!), requestBody, responseHandler);
        };

        const getTitle = (value: boolean): string => {
            if (value) {
                return 'Few items are present in the inventory making the order partially deliverable. Toggle to disallow partial delivery';
            } else {
                return 'The toggle would enable you to deliver the items that are already present in the inventory or have been received from the seller(s)';
            }
        };

        return (
            props.accessible ? <div className='flex-align-center'>
                <label className='font-semibold'>Partial deliverability</label>

                <Tooltip title={getTitle(props.defaultValue)} placement='right'>
                    <FaInfoCircle className={`${style.infoIcon}`} />
                </Tooltip> &ensp;

                <Switch
                    style={{ backgroundColor: '#389e0d' }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={props.defaultValue}
                    loading={switchLoading}
                    onChange={updateOrder}
                />
            </div> : <></>
        );
    };

    // Seller Order List
    const SellerOrderListView = (): JSX.Element => {
        return (
            config.allowToViewSellerOrders &&
                orderDetails?.state_metadata.some(eachItem => [
                    OrderStates.SELLER_ORDER_GENERATED,
                    OrderStates.SELLER_PARTIALLY_ACCEPTED,
                    OrderStates.SELLER_ACCEPTED,
                    OrderStates.SELLER_REJECTED,
                ].includes(OrderStates.valueOf(eachItem.state)!)) ?

                <Card>
                    <Collapse expandIconPosition='end'>
                        <Collapse.Panel key='sellerOrder' className='font-semibold' header='View Seller Order(s)'>
                            <SellerOrderList
                                orderUuid={orderUuid}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </Card>

                :

                <></>
        );
    };

    const OrderDetailSummaryView = (): JSX.Element => {
        const orderDetailSummary =
        {
            title: 'Order Details',
            data: [{
                label: 'Billing Date',
                value: getISTFromEpoch(orderDetails?.confirmed_at!),
            },
            {
                label: 'Invoice Date',
                value: getISTFromEpoch(parseInt(orderDetails?.invoice_date!)),
            },
            {
                label: 'Billed Amount',
                value: formatCurrency(orderDetails?.amount!),
            },
            {
                label: 'Final Amount',
                value: formatCurrency(orderDetails?.final_amount!),
            }].filter(eachItem => eachItem.value && eachItem.value !== 'Invalid date'),
        };

        return <Card>
            <DetailsCard
                title={
                    <label className='text-xl font-semibold'>
                        {orderDetailSummary.title}
                    </label>
                }
                data={
                    orderDetailSummary.data.map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey'>{cardDatum.label}</label>,

                            value: <label className='font-semibold'>
                                {cardDatum.value}
                            </label>,
                        };
                    })
                }
            />
        </Card>;
    };

    const ParentAndChildOrderView = () => {

        const getRelatedOrders = (): MiniOrder[] => {

            if (orderDetails?.child_orders?.length! > 0) {
                return orderDetails?.child_orders!;

            } else if (orderDetails?.parent_order) {
                return [orderDetails?.parent_order];
            }
            return [];
        };

        if (isArrayEmpty(getRelatedOrders())) {
            return <></>;
        }

        const getTitle = (): string => {
            return orderDetails?.source === Source.PARTIAL_DELIVERY ? 'Parent Order' : 'Child Orders';
        };

        const childOrderColumns: MeraaiTableColumnType[] = [
            {
                title: 'Receipt No',
                dataIndex: 'receipt_no',
                rowVisibility: [Device.DESKTOP],
                render: (receipt_no: string, record: MiniOrder) => <a
                    className='font-semibold text-black hover:text-black'
                    target="_blank"
                    href={`/order/${record.uuid}`}
                >
                    {receipt_no}
                </a>,
            },
            {
                title: 'Source',
                dataIndex: 'source_display',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                render: (source_display) => <label>{source_display}</label>,
            },
            {
                title: 'Billed on',
                dataIndex: 'confirmed_at',
                render: (confirmed_at) => <label>{getISTFromEpoch(confirmed_at)}</label>,
            },
            {
                title: 'Created at',
                dataIndex: 'created_at',
                render: (created_at) => <label>{getISTFromEpoch(created_at)}</label>,
            },
            {
                title: 'Navigate',
                dataIndex: 'uuid',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                render: (uuid) => <a
                    className='text-meraai-orange hover:text-meraai-orange'
                    target="_blank"
                    href={`/order/${uuid}`}
                >
                    View order details
                </a>,
            },
        ];

        return (
            <Card title={getTitle()}>
                <MeraaiTable
                    columns={childOrderColumns}
                    dataSource={addKeyToDataList(getRelatedOrders())}
                    pagination={false}
                />
            </Card>
        );
    };

    const EditOrderButton = (props: { permission: boolean }) => {
        return props.permission ? <Tooltip title='Edit Order'>
            <MdEdit className='mx-3 cursor-pointer text-lg hover:text-xl' onClick={() => {
                navigate('edit', { replace: true });
            }} />
        </Tooltip> : <></>;
    };

    const orderItemColumns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            rowVisibility: [Device.DESKTOP],
            render: (_, record: OrderItem) => <Image width={30} height={30} src={record.image} />,
        },
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            enableSearch: true,
            filteredValue: [searchName],
            onFilter(value: any, record) {
                return record.name.toLowerCase().includes(value.toLowerCase());
            },
            render: (_, record: OrderItem) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'QTY',
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: quantity => <Quantity quantity={quantity} />,
        },
        {
            title: 'Rejected Quantity',
            dataIndex: 'rejected_quantity',
            render: rejected_quantity => <Quantity quantity={rejected_quantity} />,
        },
        {
            title: 'Price',
            dataIndex: 'price_per_unit',
            render: price => <Currency value={price} />,
        },
        {
            title: 'Total Price',
            dataIndex: 'amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: amount => <Currency value={amount} />,
        },
        {
            dataIndex: 'status',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: OrderItem) => (
                <MdLocationOn
                    className='text-meraai-orange text-2xl cursor-pointer'
                    onClick={() => {
                        const itemStatusDetails = record.state_metadata.sort(function (a, b) {
                            return a.timestamp! - b.timestamp!;
                        });
                        if (itemStatusDetails.length) {
                            setitemStatusTracker(itemStatusDetails);
                        }
                        setIsModalVisible(true);
                    }}
                />
            ),
        },
    ];

    const EstablishmentDetails = (): JSX.Element => {
        const detailsCardData: DetailsCardProps[] = [
            {
                title: 'Hub details',
                data: [{
                    label: 'Name',
                    value: orderDetails?.establishment.name,
                },
                {
                    label: 'Address',
                    value: orderDetails?.establishment.address,
                }],
                divider: true,
            },
            {
                title: 'Vendor details',
                data: [{
                    label: 'Name',
                    value: `${orderDetails?.agent.firstname} ${orderDetails?.agent.lastname}`,
                },
                {
                    label: 'Outlet Name',
                    value: orderDetails?.agent_establishment.name,
                },
                {
                    label: 'Address',
                    value: orderDetails?.agent_establishment.address,
                }],
                divider: false,
            },
        ];

        return <>
            {
                detailsCardData.map((datum, index) => <DetailsCard
                    key={index}
                    title={
                        <label className='text-xl font-semibold'>
                            {datum.title}
                        </label>
                    }
                    data={
                        datum?.data?.map((cardDatum) => {
                            return {
                                label: <label className='text-meraai-grey'>{cardDatum.label}</label>,

                                value: <label className='font-semibold'>
                                    {cardDatum.value}
                                </label>,
                            };
                        })
                    }
                    divider={datum.divider}
                />)
            }
        </>;
    };

    const PaymentDetails = (): JSX.Element => {

        const paymentColumns: MeraaiTableColumnType[] = [
            {
                title: 'Tender',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                render: (_, record: Payments) => <label>{record.name}</label>,
            },
            {
                title: 'Amount',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                render: (_, record: Payments) => <label>{record.amount}</label>,
            },
            {
                title: 'Refunded Amount',
                rowVisibility: [Device.DESKTOP, Device.MOBILE],
                render: (_, record: Payments) => <label>{record.refunded_amount}</label>,
            },
        ];

        return orderDetails?.payments ?
            <>
                <Card className='px-5' title="Payment details">
                    <MeraaiTable
                        columns={paymentColumns}
                        dataSource={addKeyToDataList(orderDetails.payments)}
                        pagination={false}
                    />
                </Card>
            </>
            : <></>;
    };

    // UseEffect 
    useEffect(() => {
        if (orderUuid) {
            getOrderDetails();
        }
    }, [orderUuid]);

    return (
        <>
            <DetailsPage
                loading={loading}
                header={

                    orderDetails ?

                        <Row gutter={[0, 24]}>
                            <Col className={'w-full sm:w-1/2 xl:w-1/3'}>
                                {/* Instead of order number, invoice number should be visible if it is present. */}
                                <div className={`${layout.flex.start}`}>
                                    <PageBreadcrumbHeader items={['Orders', <GetOrderDetailTitle />]} />
                                    <EditOrderButton permission={config.allowEditOrderDetails && orderDetails?.state === OrderStates.HUB_RECEIVED} />
                                </div>
                                {
                                    orderDetails.invoice_no ? <GetOrderDetailTitle showReceiptNo={true} /> : <></>
                                }
                                {
                                    orderDetails?.allow_changing_partial_deliverability ?

                                        <PartialDeliverableSwitch
                                            accessible={orderDetails?.allow_changing_partial_deliverability!}
                                            defaultValue={orderDetails?.partially_deliverable!}
                                        /> : <></>
                                }

                                <Tags />
                            </Col>

                            <Col className={`w-full sm:w-1/2 xl:w-2/3 ${layout.flex.end}`}>
                                <OrderDownloadables />
                            </Col>
                        </Row>

                        : <Skeleton active paragraph={{ rows: 2 }} />

                }

                body={
                    {
                        left:
                            orderDetails ? <>
                                <Card title='Items summary'>
                                    <div className='max-h-[80vh] overflow-auto'>
                                        <MeraaiTable
                                            columns={orderItemColumns}
                                            dataSource={addKeyToDataList(orderDetails?.items!)}
                                            pagination={false}
                                            searchFilter={{
                                                value: searchName,
                                                hideSearchButton: true,
                                                searchPlaceholder: 'Search name',
                                                setSearchText: setSearchName,
                                            }}
                                        />
                                    </div>
                                </Card>

                                <OrderDetailSummaryView />

                                <PaymentDetails />
                                <ParentAndChildOrderView />

                                <SellerOrderListView />

                                <Card title='Order tracking history'>
                                    <StateMetadataRendererV2 stateMetadata={orderDetails.state_metadata} />
                                </Card>
                            </>
                                :
                                <Skeleton active paragraph={{ rows: 10 }} />
                        ,

                        right:
                            orderDetails ? <>
                                <EstablishmentDetails />
                            </> :
                                <Skeleton className='p-10' active paragraph={{ rows: 10 }} />,
                    }
                }
                footer={
                    <>
                        <Col className={`w-full ${layout.flex.center}`}>
                            <OrderPaymentCollection
                                setLoading={setLoading}
                                orderUuid={orderUuid}
                                orderDetails={orderDetails}
                                getOrderDetails={getOrderDetails}
                            />
                        </Col>
                        <OrderStateChangeActionables
                            orderUuid={orderUuid!!}
                            orderDetails={orderDetails!!}
                            setOrderDetails={setOrderDetails}
                            newChildOrderModal={newChildOrderModal}
                            config={config}
                            updateStockInOrderItems={updateStockInOrderItems}
                            setIsFetching={setLoading}
                        />
                    </>
                }
                footerVisibility={orderDetails?.valid_next_states.length! > 0}
            />

            <Modal
                title='Status Tracker'
                open={isModalVisible}
                cancelButtonProps={{ className: 'hidden' }}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
            >
                {
                    itemStatusTracker ? <StateMetadataRenderer stateMetadata={itemStatusTracker} />
                        : <DataNotFound title='No Records Found' />
                }
            </Modal>

        </>
    );
};