/* eslint react/prop-types: 0 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { EditableField } from './EditableField';
import { BsDash } from 'react-icons/bs';

const EditableContext = React.createContext<any>(null);

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    record: Record<any, EditableField<any>>;
    handleSave: (record: any) => void;
}

export function EditableRow({ index, ...props }) {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
}
export function EditableCell({
    title,
    editable,
    dataIndex,
    record,
    handleSave,
    children,
    ...restProps
}: EditableCellProps) {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef?.current?.focus();
        }

    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex].value,
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            record[dataIndex].value = values[dataIndex];
        } catch (error) {
            record[dataIndex].value = error.values[dataIndex];
        }
        handleSave(record);
    };

    let childNode = children;

    if (record && record[dataIndex]?.alwaysRenderComponent) {
        form.setFieldsValue({
            [dataIndex]: record[dataIndex].value,
        });
    }

    if (editable) {
        childNode =
            editing || record[dataIndex]?.alwaysRenderComponent ? (
                <Form.Item
                    valuePropName={record[dataIndex]?.componentValuePropName}
                    style={{
                        margin: 0,
                        width: '100px',
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: record[dataIndex]?.required,
                            message: 'Required',
                        },
                    ]}>
                    {record[dataIndex].component!(inputRef, save, record)}
                </Form.Item>
            ) : (
                <Input
                    style={{
                        margin: 0,
                        width: '100px',
                    }}
                    className={
                        record[dataIndex]?.isValueAcceptable!()
                            ? 'editable-cell-value-wrap'
                            : 'editable-cell-value-wrap-error'
                    }
                    onFocus={toggleEdit}
                    defaultValue={record[dataIndex]?.value === '' || record[dataIndex]?.value === undefined
                        ? '-'
                        : record[dataIndex]?.value}
                >

                </Input>
            );
    } else {
        if (record && !record[dataIndex]?.value) {
            childNode = <BsDash />;
        }
    }

    return <td {...restProps}>{childNode}</td>;
}
