import { Button, Checkbox, Col, Drawer, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../styles/css/style';
import { isArrayEmpty } from '../../../Utils';
import { useCategoryList } from '../../category/hooks';
import { CategoryDetails } from '../../category/types';
import { SearchInput } from '../SearchInput';
import { DataNotFound } from '../templates/dataNotFound';
import { SelectCategoryListProps } from './types';

export const SelectCategoryList = (props: SelectCategoryListProps) => {

    const SELECT_OPTION_LIMIT = 4;
    const SELECT_OPTION_HEIGHT = 32;

    //State variables
    const { categoryResponse, fetching } = useCategoryList();
    const [categoryDrawerVisibility, setCategoryDrawerVisibility] = useState<boolean>(false);
    const [categoryList, setCategoryList] = useState<CategoryDetails[]>();
    const [sortedCategoryList, setSortedCategoryList] = useState<CategoryDetails[]>();
    const [categoryName, setCategoryName] = useState<string>('');

    // Methods

    const sortCategoryList = (categoryDetailsList: CategoryDetails[]): CategoryDetails[] => {
        // Sorting checked -> unchecked 

        let finalSortedCategoryList: CategoryDetails[] = [], selected: CategoryDetails[] = [], unselected: CategoryDetails[] = [];

        categoryDetailsList?.forEach((category) => {
            if (props.value?.includes(category.id)) {
                selected.push({ ...category, checked: true });
            } else {
                unselected.push({ ...category, checked: false });
            }

            finalSortedCategoryList = [...selected, ...unselected];
        });
        return finalSortedCategoryList;
    };


    // UseEffects
    useEffect(() => {
        if (categoryResponse) {
            const finalSortedList = sortCategoryList(categoryResponse?.data!);
            setSortedCategoryList(finalSortedList);
            setCategoryList(finalSortedList);
        }
    }, [categoryResponse]);

    useEffect(() => {

        // Updating the checked property of categorydetails.
        if (categoryDrawerVisibility) {
            const finalCategoryList = categoryList?.map((category) => {
                if (props.value?.includes(category.id)) {
                    return { ...category, checked: true };
                } else {
                    return { ...category, checked: false };
                }
            });
            setSortedCategoryList(finalCategoryList);
            setCategoryList(finalCategoryList);
        }
    }, [categoryDrawerVisibility]);

    return (
        <>
            <Select
                {...props}
                placeholder='Select Categories'
                showArrow
                listHeight={SELECT_OPTION_HEIGHT * SELECT_OPTION_LIMIT}
                showSearch={false}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <div className='p-2'>
                            {
                                categoryResponse?.data?.length! > SELECT_OPTION_LIMIT &&
                                <label
                                    className={`text-meraai-orange px-2 cursor-pointer ${layout.flex.start}`}
                                    onClick={() => {
                                        setCategoryDrawerVisibility(true);
                                    }}
                                >
                                    View more...
                                </label>
                            }

                        </div>
                    </>
                )}
            >
                {
                    categoryList?.filter(category => category.active).map((category: CategoryDetails, index) => <>

                        <Select.Option
                            key={index}
                            value={category.id}
                        >
                            {category.name}
                        </Select.Option>

                    </>)
                }
            </Select>

            <Drawer
                className='meraai-drawer'
                open={categoryDrawerVisibility}
                title={<h3>Select Category</h3>}
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    if (props.onCancel) {
                        props.onCancel();
                    }
                    setCategoryDrawerVisibility(false);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        if (props.onCancel) {
                                            props.onCancel();
                                        }
                                        setCategoryDrawerVisibility(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={props.loading}
                                    onClick={() => {
                                        if (props.onOk) {
                                            props.onOk();
                                        }
                                        setCategoryDrawerVisibility(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Spin spinning={fetching} size='default'>
                    <Row gutter={[12, 12]}>

                        <Col className='w-full'>
                            <SearchInput
                                value={categoryName}
                                enableAutoSearch
                                hideSearchButton
                                setSearchText={setCategoryName}
                                searchPlaceholder={'Search Category'}
                                searchButtonOnClick={(value) => {
                                    setCategoryList(sortedCategoryList?.filter(category => category.name.toLowerCase().includes(value.toLowerCase())));
                                }}
                            />
                        </Col>

                        <Col className='w-full'>
                            {
                                isArrayEmpty(categoryList) ?
                                    <DataNotFound title='No category found' />
                                    :
                                    <Checkbox.Group className='w-full' defaultValue={props.value ?? props.defaultValue} onChange={(checkedValues: number[]) => {
                                        props.setSelectedCategories(checkedValues);
                                    }}>
                                        {
                                            categoryList?.filter(category => category.active).map((category, index) => <>

                                                <Row
                                                    key={index}
                                                    className={`py-5 ${layout.flex.spaceBetween}`}
                                                >

                                                    <Col className='w-1/2'>

                                                        <label
                                                            htmlFor={String(index)}
                                                            className={`cursor-pointer block w-full ${category.checked ? 'font-bold' : ''}`}
                                                        >
                                                            {category.name}
                                                        </label>

                                                    </Col>

                                                    <Col className={`w-1/2 ${layout.flex.end}`}>

                                                        <Checkbox
                                                            key={index}
                                                            id={String(index)}
                                                            className={`w-full ${layout.flex.end}`}
                                                            value={category.id}
                                                            onChange={(value) => {
                                                                category.checked = value.target.checked;
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                            </>)
                                        }
                                    </Checkbox.Group>
                            }
                        </Col>

                    </Row>
                </Spin>
            </Drawer>
        </>

    );
};