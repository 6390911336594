import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from '../../../Constants';

interface AuthState {
    authToken: String;
}

const initialState: AuthState = {
    authToken: localStorage.getItem(AUTH_TOKEN) != null ? localStorage.getItem(AUTH_TOKEN)!! : '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuthToken(state, action: PayloadAction<string>) {
            state.authToken = action.payload;
            localStorage.setItem(AUTH_TOKEN, action.payload);
        },
    },
});

export const { updateAuthToken } = authSlice.actions;
export default authSlice.reducer;
