export const blurredBackground = {
    backdropFilter: 'blur(20px)',
    backgroundColor: '#01223770',
};

export const defaultButton = {
    backgroundColor: '#7E4D2B',
    border: '#7E4D2B 1px solid',
};

export const setPadding = (top = 0, right = 0, bottom = 0, left = 0): React.CSSProperties => {
    return {
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
    };
};

export const setMargin = (top = 0, right = 0, bottom = 0, left = 0): React.CSSProperties => {
    return {
        margin: `${top}px ${right}px ${bottom}px ${left}px`,
    };
};

export const setWidth = (value: string = '0%'): React.CSSProperties => {
    return {
        width: `${value}`,
    };
};

export const setFontSize = (value: string = '0px'): React.CSSProperties => {
    return {
        fontSize: `${value}`,
    };
};