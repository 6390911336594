import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { RoleDataMap } from '../types/RoleData';
import { useAppSelector } from '../hooks';


function HomePage() {
    const navigate = useNavigate();
    const currentRole = useAppSelector(state => state.login.role);
    useEffect(() => {
        const path = RoleDataMap.get(currentRole)?.basePath!;
        navigate(path);
    });
    return <></>;
}


export default HomePage;
