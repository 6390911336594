import { uuidv4 } from '@firebase/util';
import { Button, Col, message, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import TextArea from 'antd/lib/input/TextArea';
import React, { ReactNode } from 'react';
import { CgSoftwareUpload } from 'react-icons/cg';
import { layout, style } from '../../../styles/css/style';
import { documentConfigs, DocumentProps, DocumentType, DropDownProperties, MetadataDocument } from './types';

export const isPurposeValid = (document: MetadataDocument): boolean => {
    return document?.purpose?.length! >= 3;
};

export const createDocument = (
    type: DocumentType,
    required: boolean,
    purpose: string | undefined,
    dropdownProperties?: DropDownProperties,
    multiple?: boolean,
): MetadataDocument => {
    if (!documentConfigs.find(config => config.type === type)) {
        message.error(`Invalid File type ${type}`);
        throw new Error(`Invalid File type ${type}`);
    }

    return {
        id: uuidv4(),
        type,
        required,
        purpose,
        dropdownProperties,
        multiple,
    };
};

export function ImageUploader({ document, uploadFile, displayValue }: DocumentProps) {
    return (
        <ImgCrop
            modalTitle="Edit Image. Only the part which is in the box will be selected"
            rotate
            minZoom={0.5}
            shape="rect"
            cropperProps={{
                restrictPosition: false,
            }}>
            <Upload
                accept=".png, .jpg, .jpeg"
                disabled={!isPurposeValid(document)}
                name="file"
                multiple={false}
                beforeUpload={file => uploadFile!(document, file)}
            >
                <Button
                    className={`${style.meraaiPlainButton}`}
                    disabled={!isPurposeValid(document)}
                >
                    <div className={`${layout.flex.center}`}>
                        <CgSoftwareUpload className='mr-2 text-xl' />
                        <label>{displayValue}</label>
                    </div>

                </Button>
            </Upload>
        </ImgCrop>
    );
}

export function DocUploader({ document, uploadFile, displayValue }: DocumentProps) {
    return (
        <Upload
            accept=".pdf"
            disabled={!isPurposeValid(document)}
            multiple={false}
            fileList={[]}
            beforeUpload={file => uploadFile!(document, file)}>

            <Button
                className={`${style.meraaiPlainButton}`}
                disabled={!isPurposeValid(document)}
            >
                <div className={`${layout.flex.center}`}>
                    <CgSoftwareUpload className='mr-2 text-xl' />
                    <label>{displayValue}</label>
                </div>

            </Button>

        </Upload>
    );
}

export function TextEditor({ document, setValueInDocument }: DocumentProps) {
    return (
        <TextArea
            value={document.values?.at[0]}
            placeholder="Add your remarks"
            rows={3}
            style={{ resize: 'none' }}
            disabled={!isPurposeValid(document)}
            onChange={event => {
                if (setValueInDocument) {
                    setValueInDocument(document, event.currentTarget.value);
                }
            }}
        />
    );
}

export const MeraaiFormItem = (props: { label?: ReactNode, value?: ReactNode, document?: MetadataDocument }) => {
    return <Row gutter={[0, 12]}>
        {
            props.document?.required &&

            <Col className='w-full'>
                {props.label}
            </Col>
        }
        <Col className='w-full'>
            {props.value}
        </Col>
    </Row>;
};