import { Button, Col, Empty, message, Modal, Radio, Row, Skeleton, Spin, Tag } from 'antd';
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { IoTrashBin } from 'react-icons/io5';
import { TiShoppingCart } from 'react-icons/ti';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AMOUNT_ROUNDING_SCALE, RUPEE_SYMBOL } from '../../Constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { CREATE_ORDER, EDIT_ORDER } from '../../http/EndPoints';
import { postApiCall, ResponseHandler } from '../../http/HttpClient';
import { CreateOrderRequest, CreateOrderResponse, OrderItemInfo, ProductItemType } from '../../types/Cart';
import { clearEstablishmentData } from './CartSlice';
import ProductList from './ProductList';
import { TenderType, TenderTypeProperties } from '../../types/Tender';
import { CartItem } from './CartItem';
import { CREATE, EDIT, OrderCreationMode } from '../../types/OrderCreationMode';
import { MdOutlineAddShoppingCart } from 'react-icons/md';
import { OrderDetailsResponse } from '../../types/Order';

export interface CartProps {
    productsLoading: boolean;
    setOrderDetails: React.Dispatch<React.SetStateAction<OrderDetailsResponse | undefined>>;
    orderUuid: string;
    mode: OrderCreationMode;
    establishmentUuid: string;
    cartModalVisible: boolean;
    setCartModalVisible: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setBrandsDrawerVisibility: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}


function getCartOrderItems(cartItems: Array<CartItem>): Array<OrderItemInfo> {
    return cartItems.map(item => {
        return { sku: item.sku!, quantity: item.quantity! };
    });
}

function Cart(props: CartProps) {

    const navigate = useNavigate();

    const [orderCreationInProgress, setOrderCreationInProgress] = useState<boolean>(false);
    const [postOrderCreationModalVisibility, setPostOrderCreationModalVisibility] = useState<boolean>(false);
    const [orderResponse, setOrderResponse] = useState<CreateOrderResponse>();
    const [selectedTenderType, setSelectedTenderType] = useState<TenderType>();

    const dispatch = useAppDispatch();


    const cartData = useAppSelector(state => state.cart.cart[props.establishmentUuid]) ?? {
        selectedItems: [],
        selectedItemsCount: 0,
        totalAmount: 0,
        lastUpdatedAt: 0,
    };


    const createOrder = async () => {
        setOrderCreationInProgress(true);

        const createOrderEndPoint = CREATE_ORDER(props.establishmentUuid);

        const createOrderRequest: CreateOrderRequest = {
            agent_uuid: null,
            items: getCartOrderItems(cartData.selectedItems),
            tender: { type: selectedTenderType! },
            creation_date: Date.now(),
            stages: [],
        };

        const responseHandler: ResponseHandler<CreateOrderResponse> = {
            onResponseSuccess(value: CreateOrderResponse): void {
                setOrderResponse(value);
                props.setCartModalVisible(false);
                setOrderCreationInProgress(false);
                setPostOrderCreationModalVisibility(true);
                dispatch(clearEstablishmentData(props.establishmentUuid));
                setSelectedTenderType(undefined);
            },
            onResponseFailed(): void {
                setOrderCreationInProgress(false);
            },
            onResponseError(): void {
                setOrderCreationInProgress(false);
            },
        };

        await postApiCall(createOrderEndPoint, createOrderRequest, responseHandler);
    };

    const performClearCart = () => {
        Modal.confirm({
            title: 'Delete all items ?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action will clear your cart items.',
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => {
                props.setCartModalVisible(false);
                dispatch(clearEstablishmentData(props.establishmentUuid));
            },
        });
    };

    const updateResponseHandleModal = (response) => {
        Modal.error({
            title: 'Cannot update!',
            content: response[0],
        });
    };

    const updateOrder = async () => {
        setOrderCreationInProgress(true);

        const createOrderEndPoint = EDIT_ORDER(props.orderUuid);

        const updateOrderRequest = {
            items: getCartOrderItems(cartData.selectedItems),
        };

        const responseHandler: ResponseHandler<OrderDetailsResponse> = {
            onResponseSuccess(value: OrderDetailsResponse): void {
                message.success('Order has been updated successfully');
                props.setOrderDetails(value);
                props.setCartModalVisible(false);
                setOrderCreationInProgress(false);
                dispatch(clearEstablishmentData(props.establishmentUuid));
                navigate(`/order/${value.uuid}`, { replace: true });
            },
            onResponseFailed(errors: String[]): void {
                updateResponseHandleModal(errors);
                setOrderCreationInProgress(false);
            },
            onResponseError(): void {
                setOrderCreationInProgress(false);
            },
        };

        await postApiCall(createOrderEndPoint, updateOrderRequest, responseHandler, {}, {}, true);
    };


    const getFooter = (mode: OrderCreationMode) => {
        if (mode === CREATE) {
            return (<div>
                <Button
                    onClick={() => {
                        performClearCart();
                    }}
                    disabled={cartData.selectedItems.length == 0}>
                    <div className="flex-align-center">
                        <IoTrashBin /> &ensp; Clear Cart
                    </div>
                </Button>
                <Button
                    className="default-button align-right"
                    onClick={createOrder}
                    disabled={cartData.selectedItems.length == 0 || !selectedTenderType}>
                    Create Order
                </Button>
            </div>);
        } else if (mode === EDIT) {
            return (<div>
                <Button
                    onClick={() => {
                        props.setCartModalVisible(false);
                        props.setBrandsDrawerVisibility(true);
                    }}
                    disabled={cartData.selectedItems.length == 0}>
                    <div className="flex-align-center">
                        <MdOutlineAddShoppingCart /> &ensp; Add new product(s)
                    </div>
                </Button>
                <Button
                    className="default-button align-right"
                    onClick={() => {
                        updateOrder();
                    }}
                    disabled={cartData.selectedItems.length == 0}>
                    Update Order
                </Button>
            </div>);
        }
    };


    return (
        <>
            <Modal
                className="responsive-modal"
                title={
                    <Row gutter={[12, 12]} className="flex-center flex-column-mid">
                        <Col xs={24} sm={12} className="flex-align-center">
                            <TiShoppingCart /> &ensp; <span className="bold-label">Cart</span>
                        </Col>

                        <Col xs={24} sm={12}>
                            {selectedTenderType && <Tag className="disclaimer" color="cyan">
                                {props.mode.cartPaymentNoteMessage(TenderTypeProperties[selectedTenderType].display)}
                            </Tag>}
                        </Col>
                    </Row>
                }
                open={props.cartModalVisible}
                onCancel={() => {
                    props.setCartModalVisible(false);
                }}
                onOk={() => props.setCartModalVisible(false)}
                maskClosable={false}
                footer={getFooter(props.mode)}>
                {
                    props.productsLoading ? <Skeleton active /> : <Spin spinning={orderCreationInProgress} tip="Loading..." size="small">
                        <Row>
                            <Col xs={24}>
                                {cartData.selectedItems.length <= 0 ? (
                                    <Empty
                                        style={{ height: '150px' }}
                                        description={
                                            <>
                                                <h3 style={{ color: 'red' }}>Oops! Your cart is empty!</h3>
                                                <div className="flex-column-mid">
                                                    <label>Looks like you haven't added anything to your cart yet</label>
                                                </div>
                                            </>
                                        }
                                        className="meraai-border"
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                ) : (
                                    <ProductList
                                        rowElementsCount={2}
                                        elementType={ProductItemType.CART}
                                        productsListLoading={false}
                                        listingData={cartData.selectedItems}
                                        pagination={false}
                                        styleClass="meraai-border"
                                        height="35vh"
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className='padding-top-10px flex-row-sb-center' gutter={[24, 0]}>
                            <Col xs={10}>
                                <Row className="flex-row-sb-center">
                                    <Col>
                                        <span className="item-total-text">Subtotal :</span>
                                    </Col>
                                    <Col>
                                        <span className="bold-label">{`${RUPEE_SYMBOL} ${cartData.totalAmount.toFixed(AMOUNT_ROUNDING_SCALE)}`}</span>
                                    </Col>
                                </Row>
                                <Row className="flex-row-sb-center">
                                    <Col>
                                        <span className="item-total-text">No of items :</span>
                                    </Col>
                                    <Col>
                                        <span className="bold-label">{`x ${cartData.selectedItemsCount}`}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={7.5}>
                                <Col xs={24}><label className="item-total-text">Payment Mode :</label></Col>
                                <Col xs={24} className='padding-top-10px'>
                                    <div>
                                        <Radio.Group value={selectedTenderType} onChange={(value) => {
                                            setSelectedTenderType(value.target.value);
                                        }}>
                                            <Radio value={TenderTypeProperties.CREDIT.type}>{TenderTypeProperties.CREDIT.display}</Radio>
                                            <Radio value={TenderTypeProperties.PAY_ON_DELIVERY.type}>{TenderTypeProperties.PAY_ON_DELIVERY.display}</Radio>
                                        </Radio.Group>
                                    </div>
                                </Col>
                            </Col>
                        </Row>

                    </Spin>
                }
            </Modal>
            {
                // After the order is created successfully popup this modal
                orderResponse && (
                    <Modal
                        className="success-modal"
                        open={postOrderCreationModalVisibility}
                        title={<h3 className="title">Order placed successfully. </h3>}
                        onCancel={() => {
                            setPostOrderCreationModalVisibility(false);
                        }}
                        footer={[
                            <Button
                                onClick={() => {
                                    props.setCartModalVisible(false);
                                    setPostOrderCreationModalVisibility(false);
                                }}>
                                Create a new order
                            </Button>,
                            <Button type="primary" onClick={() => navigate(`/order/${orderResponse?.uuid}`)}>
                                Check order details
                            </Button>,
                        ]}
                        maskClosable={false}>
                        <label className="content">
                            - The order no is <span>{`${orderResponse?.id}`}</span>
                        </label>
                    </Modal>
                )
            }
        </>
    );
}

export default Cart;
