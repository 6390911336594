import React, { useState } from 'react';
interface ReadMoreProps { children, style }

const styleProperties = { cursor: 'pointer', color: '#626262' };

export const ReadMore = (props: ReadMoreProps) => {

    const text = props.children;
    const [isReadMore, setIsReadMore] = useState(true);


    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };


    return (
        <p style={props.style}>
            {isReadMore ? text?.slice(0, 20) : text}

            <span onClick={toggleReadMore}>
                {
                    text?.length > 20 && <b style={styleProperties}>{isReadMore ? '... (read more)' : '(read less)'}</b>
                }
            </span>
        </p>
    );
};