import { Button, Col, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { RoleDataMap } from '../../../types/RoleData';
import { areTwoArraysEqual } from '../../../Utils';
import { SearchInput } from '../../common/SearchInput';
import {
    EstablishmentsDropDown,
    EstablishmentSelectionMode,
} from '../../common/establishments/EstablishmentsDropDown';
import { PathConfigData } from '../../config/PathConfigService';
import { EstablishmentDetailsConfig, UserListingConfig } from '../../config/types/ConfigTypes';
import { FilterProps } from './types';
import { Path } from '../../router/Path';
import { style } from '../../../styles/css/style';
import ConfigContext from '../../config/ConfigContext';
import { ModifyUser } from '../createAndUpdate';
import { ModifyUserMode } from '../createAndUpdate/types';

export const Filter = (props: FilterProps) => {
    const config = React.useContext(ConfigContext) as EstablishmentDetailsConfig;
    const roleConfig = new PathConfigData(props.selectedRole!).getConfigForPath(Path.USER_LISTING) as UserListingConfig;

    const [focus, setFocus] = useState<boolean>(false);
    const [roleChange, setRoleChange] = useState<boolean>(true);
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);
    const fetchEstablishmentList = useRef<boolean>(true);
    const [modifyUserDrawerVisibility, setModifyUserDrawerVisibility] = useState<boolean>(false);

    function selectRoleByDefault() {
        if (props.chosenEstablishment?.type?.userRoles?.length === 1) {
            props.setSelectedRole(props.chosenEstablishment?.type?.userRoles[0]);
        }
    }

    useEffect(() => {
        if (props.chosenEstablishment) {
            props.setAccessibleRoles(props.chosenEstablishment?.type?.userRoles!);
            selectRoleByDefault();
        }
    }, []);


    useEffect(() => {
        // Update establishment uuid 
        if (props.chosenEstablishment) {
            props.setEstablishmentUuids([props.chosenEstablishment?.uuid!]);
        }
        setRoleChange(true);
    }, [props.selectedRole]);

    return (
        <>
            <Row>

                <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>
                    <Select
                        style={{ width: '100%' }}
                        value={props.selectedRole}
                        optionLabelProp="label"
                        placeholder={
                            <>
                                🥷
                                &nbsp; Select Role
                            </>
                        }
                        onChange={(role) => {
                            props.resetFilter();
                            props.setSelectedRole(role);
                            props.setUserListRequested(true);
                        }}
                    >
                        {
                            props.accessibleRoles.map(role => <Select.Option
                                key={RoleDataMap.get(role)?.key}
                                value={RoleDataMap.get(role)?.key}
                                label={<>
                                    🥷
                                    &nbsp;
                                    {RoleDataMap.get(role)?.display}
                                </>}
                            >
                                {RoleDataMap.get(role)?.display}
                            </Select.Option>)
                        }
                    </Select>
                </Col>

                <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>
                    {/**
                     * We are wrapping <Input/> into <Form/> with the help of <SearchInput/> component. 
                     * This is because we need a functionality that if the user hits ENTER button it should able to call the listing API.
                     * Also, Note that your button component must have this property - << htmlType='submit' >>
                     **/}
                    <SearchInput
                        {...props.nameSearch}
                    />
                </Col>

                {
                    roleConfig?.primaryEstablishmentType &&

                    <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>

                        <EstablishmentsDropDown
                            disabled={props.chosenEstablishment ? true : !props.selectedRole}
                            setLoading={props.setLoading}
                            type={props.chosenEstablishment?.type ?? roleConfig.primaryEstablishmentType}
                            setSelectedUuids={props.setEstablishmentUuids}
                            selectedUuids={props.establishmentUuids}
                            selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                            fetchEstablishmentList={fetchEstablishmentList.current}
                            setModalVisibility={setModalVisibility}
                            onBlur={() => {
                                setFocus(false);
                                if (!areTwoArraysEqual(props.establishmentUuids, props.prevEstablishmentUuids)) {
                                    props.setPrevEstablishmentUuids(props.establishmentUuids);
                                    props.setUserListRequested(true);
                                }
                            }}
                            onFocus={() => {
                                setFocus(true);
                                setRoleChange(false);
                            }}
                            onChange={() => {
                                /**
                                 * If establishment dropdown is not focused.
                                 * If establishment selection modal is closed.
                                 * If this onChange method rendering is not happening through role change.  
                                 * Then only we are calling the user list API.
                                 */
                                if (!focus && !modalVisibility && !roleChange) {
                                    props.setPrevEstablishmentUuids(props.establishmentUuids);
                                    props.setUserListRequested(true);
                                }
                            }}
                        />
                    </Col>
                }
                {
                    (props.chosenEstablishment ? config.users?.allowCreation : true) &&
                    <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0 lg:absolute lg:right-0'>
                        <Button
                            className={`w-full ${style.meraaiOrangeButton}`}
                            size='large'
                            onClick={() => {
                                setModifyUserDrawerVisibility(true);
                            }}
                        >
                            {'Create User'}
                        </Button>
                    </Col>
                }

            </Row>

            {/* Create User */}
            <ModifyUser
                mode={ModifyUserMode.CREATE}
                accessibleRoles={props.accessibleRoles}
                chosenEstablishment={props.chosenEstablishment}
                modifyUserDrawerVisibility={modifyUserDrawerVisibility}
                setModifyUserDrawerVisibility={setModifyUserDrawerVisibility}
                selectedRole={props.selectedRole}
                setUserListRequested={props.setUserListRequested}
            />
        </>
    );
};  