import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COLLAPSED_SIDE_MENU } from '../../../Constants';

interface SideMenuCollapsedState {
    collapsed: boolean;
}

const initialState: SideMenuCollapsedState = {
    collapsed: localStorage.getItem(COLLAPSED_SIDE_MENU) === 'true' ? true : false,
};

const sideMenuSlice = createSlice({
    name: 'side_menu_collapsed',
    initialState,
    reducers: {
        updateCollapsedState(state, action: PayloadAction<boolean>) {
            state.collapsed = action.payload;
            localStorage.setItem(COLLAPSED_SIDE_MENU, action.payload?.toString());
        },
    },
});

export const { updateCollapsedState } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;
