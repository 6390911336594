/* eslint react/prop-types: 0 */
import { Badge, Button, Card, Col, Divider, Drawer, Form, Input, Radio, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaChevronCircleDown, FaMapMarkerAlt } from 'react-icons/fa';
import { IoAdd } from 'react-icons/io5';
import { MdOutlineSort, MdSearch } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { ESTABLISHMENTS } from '../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../http/HttpClient';
import Warehouse from '../../../../images/Warehouse';
import { layout, style } from '../../../../styles/css/style';
import { EstablishmentDataResponse } from '../../../../Types';
import { isArrayEmpty } from '../../../../Utils';
import { PageBreadcrumbHeader } from '../../../common/pageBreadcrumbHeader';
import { DataNotFound } from '../../../common/templates/dataNotFound';
import { ListingPage } from '../../../common/templates/listingPage';
import ConfigContext from '../../../config/ConfigContext';
import { EstablishmentsConfig } from '../../../config/types/ConfigTypes';

import { EstablishmentListingProps, EstablishmentSearchParams } from './types';

export const EstablishmentListing = (props: EstablishmentListingProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filterForm] = Form.useForm();
    const config = React.useContext(ConfigContext) as EstablishmentsConfig;

    // States
    const [loading, setLoading] = useState<boolean>(true);

    const [establishmentList, setEstablishmentList] = useState<EstablishmentDataResponse>();

    const [establishmentSearchParams, setEstablishmentSearchParams] = useState<EstablishmentSearchParams>({});
    const [page, setPage] = useState<number>(0);
    const [filterDrawerVisibile, setFilterDrawerVisible] = useState<boolean>(false);


    // Methods

    /** URL search params **/
    const setSearchParamsFromQueryParams = (search: string) => {
        const queryParams = new URLSearchParams(search);

        setEstablishmentSearchParams({
            name: queryParams.has('name') ? queryParams.get('name')!! : '',
            sort: queryParams.has('sort') ? queryParams.get('sort')!! : 'updatedAt',
            sortDirection: queryParams.has('sortDirection') ? queryParams.get('sortDirection')!! : 'desc',
            active: queryParams.has('active') && queryParams.get('active') ? queryParams.get('active')?.split(',') : ['true'],
        });
    };

    const setQueryParamsFromSearchParams = (
        queryParams: URLSearchParams,
        searchParams: EstablishmentSearchParams,
        param: keyof EstablishmentSearchParams,
    ) => {
        if (searchParams[param]) {
            const value = searchParams[param];
            queryParams.set(param, Array.isArray(value) ? value.join(',') : value!);
        } else {
            queryParams.delete(param);
        }
    };

    const setURLFromSearchParams = (searchParams: EstablishmentSearchParams) => {
        const existingParams = new URLSearchParams(location.search);
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromSearchParams(queryParams, searchParams, 'name');
        setQueryParamsFromSearchParams(queryParams, searchParams, 'sort');
        setQueryParamsFromSearchParams(queryParams, searchParams, 'sortDirection');
        setQueryParamsFromSearchParams(queryParams, searchParams, 'active');

        if (existingParams.toString() != queryParams.toString()) {
            navigate(`?${queryParams.toString()}`, { replace: true });
        }
    };

    /** GET ESTABLISHMENT LIST **/
    const getEstablishmentList = (searchParams: EstablishmentSearchParams) => {
        setLoading(true);
        const responseHandler: ResponseHandler<EstablishmentDataResponse> = {
            onResponseSuccess(value: EstablishmentDataResponse): void {
                if (page == 0) {
                    setEstablishmentList(value);
                } else {
                    const updatedEstablishments = establishmentList?.establishments.concat(value.establishments);
                    setEstablishmentList({ ...establishmentList!, establishments: updatedEstablishments ?? [] });
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        getApiCall(ESTABLISHMENTS(props.type, page, searchParams), responseHandler);
    };

    const navigateToMapLocation = (lat: number, lng: number) => {
        window.open(`https://maps.google.com?q=${lat},${lng}`);
    };

    const EstablishmentList = (): JSX.Element => {

        const EstablishmentCard = ({ establishment }) => {
            return <Card
                className={`w-full h-[300px] p-3 ${layout.flexCol.center} hover:bg-meraai-orange hover:text-white`}
                hoverable
                bodyStyle={{ padding: '0', height: '100%', width: '100%' }}
                style={{
                    border: '1px solid #D9D9D9',
                    borderRadius: '10px',
                }}
                onClick={() => {
                    navigate(
                        `/establishments/${establishment.uuid}/${establishment.type}?type=INFO`,
                    );
                }}
            >
                <Row className={`w-full h-full ${layout.flex.center}`}>
                    <Col className={`w-full h-full ${layout.flexCol.spaceAround}`}>
                        <Row className={`w-full ${layout.flex.center}`}>
                            <Col>
                                <Warehouse />
                            </Col>
                        </Row>
                        <Row className={`w-full ${layout.flex.center}`}>
                            <Col className={`w-full ${layout.flex.center}`}>
                                <Typography.Paragraph className='text-center text-xl font-semibold px-5' ellipsis={{ rows: 1, tooltip: establishment.name }}>
                                    {establishment.name}
                                </Typography.Paragraph>
                            </Col>
                            <Col className={`w-full ${layout.flex.center}`}>
                                <Typography.Paragraph className='!m-0 text-center' ellipsis={{ rows: 2, tooltip: establishment.address }}>
                                    {establishment.address}
                                </Typography.Paragraph>
                            </Col>
                        </Row>

                        <Row className={`w-full ${layout.flex.center}`}>
                            <Divider className='my-6' />

                            <Col className={`w-full ${layout.flex.center}`}>
                                <Button
                                    className={`${layout.flex.center}`}
                                    icon={<FaMapMarkerAlt className='mr-2' />}
                                    onClick={() =>
                                        navigateToMapLocation(
                                            establishment.latitude,
                                            establishment.longitude,
                                        )
                                    }>
                                    {'Navigate'}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>;
        };

        return (
            isArrayEmpty(establishmentList?.establishments) || loading ?
                <DataNotFound loading={loading} title='No establishments found' />
                :
                <Row className='flex w-full h-full'>
                    {
                        establishmentList?.establishments.map((establishment, index) => <Col key={index} className='w-full xs:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 p-2'>
                            {
                                establishment.active ? <EstablishmentCard establishment={establishment} /> : <Badge.Ribbon text="Deactivated">
                                    <EstablishmentCard establishment={establishment} />
                                </Badge.Ribbon>
                            }

                        </Col>)
                    }
                </Row>

        );
    };

    const LoadMore = (): JSX.Element => {
        const fetchEstablishments = () => {
            setPage(page + 1);
        };

        return establishmentList?.next_page ? (
            <div className={`${layout.flex.center}`}>
                <FaChevronCircleDown className='cursor-pointer'
                    width={32}
                    height={24}
                    onClick={fetchEstablishments}
                />
            </div>
        ) : <></>;
    };


    const toggleFilterDrawerVisibility = () => {
        setFilterDrawerVisible(!filterDrawerVisibile);
    };

    const handleSearch = (searchParams) => {
        setPage(0);
        //This triggers a use-effect which helps with the search.
        setEstablishmentSearchParams(searchParams);
        toggleFilterDrawerVisibility();
    };


    const HeaderComponents = () => {
        return <Row gutter={[12, 12]}>
            <Col className='w-full xs:w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6'>
                <Button
                    className={`w-full ${style.meraaiPlainButton}`}
                    icon={<MdOutlineSort />}
                    size='large'
                    onClick={toggleFilterDrawerVisibility}>
                    &nbsp; {'Filter and Sort'}
                </Button>
            </Col>
            <Col className='w-full xs:w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6'>
                {config.permitEstablishmentCreation && (
                    <Button
                        className={`${style.meraaiOrangeButton}`}
                        icon={<IoAdd />}
                        size="middle"
                        onClick={() => navigate(`/establishments/create/${props.type}`)}>
                        &nbsp; {`Add New ${props.type.display}`}
                    </Button>
                )}
            </Col>
        </Row>;
    };


    // useEffects
    useEffect(() => {

        if (!isArrayEmpty(Object.keys(establishmentSearchParams))) {
            setURLFromSearchParams(establishmentSearchParams);
        }
        setSearchParamsFromQueryParams(location.search);
    }, [props.type]);

    useEffect(() => {

        if (!isArrayEmpty(Object.keys(establishmentSearchParams))) {
            setURLFromSearchParams(establishmentSearchParams);
            filterForm.setFieldsValue(establishmentSearchParams);
            getEstablishmentList(establishmentSearchParams);
        }
    }, [establishmentSearchParams]);

    useEffect(() => {

        if (!isArrayEmpty(Object.keys(establishmentSearchParams)) || !page) {
            getEstablishmentList(establishmentSearchParams);
        }
    }, [page]);

    return (
        <>
            <ListingPage
                breadcrumb={

                    <PageBreadcrumbHeader items={[
                        'Establishment',
                        props.type.display,
                    ]} />

                }
                header={
                    <HeaderComponents />
                }
                body={
                    <>
                        <EstablishmentList />
                        <LoadMore />
                    </>
                }
            />

            <Drawer
                title="Filter and Sort"
                className="meraai-drawer"
                onClose={toggleFilterDrawerVisibility}
                open={filterDrawerVisibile}
                getContainer={false}
            >
                <Form form={filterForm} onFinish={handleSearch} layout="vertical">

                    <Form.Item name="name" label="Name" initialValue="">
                        <Input placeholder="Search by name" />
                    </Form.Item>

                    <Form.Item name="active" label="Status">
                        <Select mode="multiple" showSearch={false}>
                            <Select.Option value="true">Active</Select.Option>
                            <Select.Option value="false">Inactive</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="sort" label="Sort By">
                        <Select placeholder="Sort by" className="sort-by-dropdown">
                            <Select.Option key="updatedAt" value="updatedAt">
                                Update Time
                            </Select.Option>
                            <Select.Option key="createdAt" value="createdAt">
                                Onboarding Time
                            </Select.Option>
                            <Select.Option key="name" value="name">
                                Name
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="sortDirection" label="Sort Direction">
                        <Radio.Group
                            options={[
                                { label: 'Ascending', value: 'asc' },
                                { label: 'Descending', value: 'desc' },
                            ]}
                        />
                    </Form.Item>

                    <div className={`${layout.flex.center}`}>
                        <Button
                            className={`w-1/3 ${style.meraaiOrangeButton}`}
                            htmlType="submit"
                            icon={<MdSearch className='mr-1' />}
                            size="large"
                        >
                            {'Search'}
                        </Button>
                    </div>

                </Form>
            </Drawer>
        </>
    );
};