/* eslint  @typescript-eslint/no-shadow: 0 */
import React, { useEffect } from 'react';
import { Button, Col, Collapse, Form, message, Modal, Row, Spin, Switch } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks';
import { APPLY_AND_ONBOARD, ESTABLISHMENT_UPDATE, GET_ESTABLISHMENT } from '../../../../../http/EndPoints';
import { getApiCall, postApiCall, putApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { EstablishmentType } from '../../../../../types/EstablishmentType';
import { ImageFile } from '../../../../common/ImageRenderer';
import ConfigContext from '../../../../config/ConfigContext';
import { EstablishmentDetailsConfig } from '../../../../config/types/ConfigTypes';
import { getInitialStates } from '../../../EstablishmentInitialStates';
import BankDetailLayout from '../../layouts/bankAccount';
import BrandDiscountLayout from '../../layouts/brandDiscounts';
import { ConfigDetails } from '../../layouts/config';
import EstablishmentLayout from '../../layouts/establishment';
import UserEstablishmentLayout from '../../layouts/userEstablishment';
import UserLayout from '../../layouts/user';
import { breadcrumb, collapsibleKeys, CreateEstablishmentProps, CreateEstablishmentResponse, LayoutProperties, OnboardingRequest } from './types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { EstablishmentSaveMode } from '../../../../../types/EstablishmentSaveMode';
import { ResponseMessage } from '../../../../../Types';
import PageTopSection from '../../../../common/PageTopSection';
import EstablishmentUpdatePreviewDrawer from '../../drawer';
import { BsShopWindow } from 'react-icons/bs';
import { EstablishmentProperties } from '../../layouts/establishment/utils';
import { UserProperties } from '../../layouts/user/utils';
import { UserEstablishmentProperties } from '../../layouts/userEstablishment/utils';
import { BankAccountProperties } from '../../layouts/bankAccount/utils';
import { ConfigProperties } from '../../layouts/config/utils';
import { BrandDiscountProperty } from '../../layouts/brandDiscounts/types';
import { layout, style } from '../../../../../styles/css/style';
import { LocationState } from '../brands/list/type';

export const CreateEstablishment = (props: CreateEstablishmentProps) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const { eType } = useParams();
    const currentRole = useAppSelector(state => state.login.role);

    const [establishment, setEstablishment] = useState<EstablishmentResponse>();
    const [establishmentType, setEstablishmentType] = useState<EstablishmentType>();
    const [establishmentProperties, setEstablishmentProperties] = useState<EstablishmentProperties>({});

    const [userProperties, setUserProperties] = useState<UserProperties>({});
    const [userEstablishmentProperties, setUserEstablishmentProperties] = useState<UserEstablishmentProperties>({});
    const [bankAccountProperties, setBankAccountProperties] = useState<BankAccountProperties>({});
    const [configProperties, setConfigProperties] = useState<ConfigProperties>({});

    const [brandDiscountList, setBrandDiscountsList] = useState<Array<BrandDiscountProperty>>([]);
    const [brandDiscountsListExpanded, setBrandDiscountsListExpanded] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [layoutProperties, setLayoutProperties] = useState<LayoutProperties>({
        showEstablishmentLayout: true,
        showUserDetailsLayout: true,
        showUserEstablishmentDetailsLayout: true,
        showBankDetailsLayout: true,
        showConfigDetailsLayout: true,
    });
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
    const [visibleSaveModal, setVisibleSaveModal] = useState<boolean>(false);

    const [establishmentUuid, setEstablishmentUuid] = useState<string>();

    const establishmentDetailsConfig = useContext(ConfigContext) as EstablishmentDetailsConfig;

    const handleCancel = () => {
        setIsDrawerVisible(false);
    };

    const appendFileToFormData = (formData: FormData, requestKey: string, fileValue: ImageFile) => {
        formData.append(
            requestKey,
            new Blob([fileValue.file as any]),
            // If file name is not present for some reason then send requestKey as the file name.
            fileValue.name || requestKey,
        );
    };

    const saveData = () => {
        setLoading(true);
        const formData = new FormData();
        const onboardingRequest: OnboardingRequest = {
            establishment_name: establishmentProperties.establishmentName?.value,
            agent_first_name: userProperties.firstName?.value,
            agent_last_name: userProperties.lastName?.value,
            latitude: establishmentProperties.latitude?.value,
            longitude: establishmentProperties.longitude?.value,
            address: establishmentProperties.address?.value,
            pin_code: establishmentProperties.pinCode?.value,
            country_code: '+91',
            mobile_number: userProperties.mobileNumber?.value,
            aadhaar_number: userEstablishmentProperties.aadhaar?.value,
            pan_number: userEstablishmentProperties.pan?.value,
            establishment_type: establishmentType?.type,
            agent_type: establishmentType?.agentType,
            state_code: establishmentProperties.stateCode?.value,
            gst_in: userEstablishmentProperties.gstin?.value,
            bank_account_details: {},
            sourcing_establishments: [],
        };

        if (bankAccountProperties.accountNumber?.value) {
            onboardingRequest.bank_account_details = {
                account_number: bankAccountProperties.accountNumber?.value,
                ifsc_code: bankAccountProperties.ifsc?.value,
                account_holder_name: bankAccountProperties.accountHolderName?.value,
            };
        }

        if (establishmentProperties.sourcingHubUuid?.value) {
            onboardingRequest.sourcing_establishments.push({
                uuid: establishmentProperties.sourcingHubUuid.value?.key,
                priority: 1,
            });
        }
        const request = JSON.stringify(onboardingRequest);
        formData.append(
            'request',
            new Blob([request], {
                type: 'application/json',
            }),
        );

        if (userEstablishmentProperties.aadhaarFrontImage?.value) {
            appendFileToFormData(formData, 'aadhaar_front_image', userEstablishmentProperties.aadhaarFrontImage?.value);
        }
        if (userEstablishmentProperties.aadhaarBackImage?.value) {
            appendFileToFormData(formData, 'aadhaar_back_image', userEstablishmentProperties.aadhaarBackImage?.value);
        }
        if (userEstablishmentProperties.panImage?.value) {
            appendFileToFormData(formData, 'pan_image', userEstablishmentProperties.panImage?.value);
        }
        if (establishmentProperties.establishmentImage?.value) {
            appendFileToFormData(formData, 'establishment_image', establishmentProperties.establishmentImage?.value);
        }
        if (userProperties.userImage?.value) {
            appendFileToFormData(formData, 'agent_image', userProperties.userImage?.value);
        }

        const responseHandler: ResponseHandler<CreateEstablishmentResponse> = {
            onResponseSuccess(response: CreateEstablishmentResponse): void {
                setEstablishmentUuid(response.establishment_uuid);
                setVisibleSaveModal(true);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        postApiCall(APPLY_AND_ONBOARD, formData, responseHandler);
    };

    const updateData = () => {
        setIsDrawerVisible(true);
    };

    const handleFormValidationFailure = data => {
        if (data?.errorFields) {
            message.error('Validation has failed. Please correct the data and try again');
        }
    };

    const setInitialProperties = (establishmentType: EstablishmentType, establishment?: EstablishmentResponse) => {
        const initialStates = getInitialStates(props.mode!!, currentRole, establishmentDetailsConfig);

        // Initializing each layout with their respective properties
        const establishmentProperties = initialStates.get(establishmentType)?.establishmentProperties!!;
        const userProperties = initialStates.get(establishmentType)?.userProperties!!;
        const userEstablishmentProperties = initialStates.get(establishmentType)?.userEstablishmentProperties!!;
        const bankAccountProperties = initialStates.get(establishmentType)?.bankAccountProperties!!;
        const configProperties = initialStates.get(establishmentType)?.configProperties!!;
        const layoutProperties = initialStates.get(establishmentType)?.layoutProperties!!;

        if (establishment) {
            establishmentProperties.setValues?.(establishment);
            userProperties.setValues?.(establishment);
            userEstablishmentProperties.setValues?.(establishment);
            bankAccountProperties.setValues?.(establishment);
            configProperties.setValues?.(establishment);
        }

        setEstablishmentProperties(establishmentProperties);
        setUserProperties(userProperties);
        setUserEstablishmentProperties(userEstablishmentProperties);
        setBankAccountProperties(bankAccountProperties);
        setConfigProperties(configProperties);
        setLayoutProperties(layoutProperties);
    };

    const getEstablishmentAndSetInitialProperties = (uuid: string) => {
        const responseHandler: ResponseHandler<EstablishmentResponse> = {
            onResponseSuccess(establishment: EstablishmentResponse): void {
                setEstablishment(establishment);
                setInitialProperties(establishmentType!!, establishment);
                setLoading(false);
                if (props.setEstablishmentName) {
                    props.setEstablishmentName(establishment.name);
                }
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        getApiCall(GET_ESTABLISHMENT(uuid), responseHandler);
    };

    const resetAllProperties = () => {
        Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: 'Clicking on yes will reset your data.',
            okText: 'Yes',
            okType: 'danger',
            onOk() {
                return setInitialProperties(establishmentType!!);
            },
            onCancel() { },
        });
    };

    const propertiesChanged = (
        properties: EstablishmentProperties | BankAccountProperties | UserEstablishmentProperties | ConfigProperties,
        changed,
    ) => {
        Object.keys(properties)
            .filter(key => properties[key]?.changed)
            .forEach(() => changed.push('changed'));
    };

    const checkifBrandDiscountPercentageChanged = changed => {
        return brandDiscountList
            .filter(item => item.discount_percentage.initialValue != item.discount_percentage.value)
            .forEach(() => changed.push('changed'));
    };

    const checkIfPropertiesChanged = () => {
        const changed: any = [];
        propertiesChanged(establishmentProperties, changed);
        propertiesChanged(bankAccountProperties, changed);
        propertiesChanged(userEstablishmentProperties, changed);
        propertiesChanged(configProperties, changed);
        checkifBrandDiscountPercentageChanged(changed);
        return changed;
    };

    const onCollapsibleKeyChanged = (key: string | string[]) => {
        setBrandDiscountsListExpanded(
            key === collapsibleKeys.brandDiscountsLayout || key?.includes(collapsibleKeys.establishmentLayout) === true,
        );
    };

    const changeEstablishmentStatus = (checked: boolean) => {
        if (!establishment) {
            return;
        }
        establishment.active = checked;
        setEstablishment({ ...establishment });
        setLoading(true);
        const endPoint = ESTABLISHMENT_UPDATE(establishment?.uuid!);
        const formData = new FormData();
        const requestString = JSON.stringify({
            active: checked,
        });

        formData.append('request',
            new Blob([requestString], {
                type: 'application/json',
            }));

        const responseHandler: ResponseHandler<ResponseMessage> = {
            onResponseSuccess() {
                message.success(`${establishment.name} has been ${establishment.active ? 'activated' : 'deactivated'}`);
                setLoading(false);
            },
            onResponseFailed() {
                establishment.active = !checked;
                setEstablishment({ ...establishment });
                setLoading(false);

            },
            onResponseError() {
                establishment.active = !checked;
                setEstablishment({ ...establishment });
                setLoading(false);
            },
        };

        putApiCall(endPoint, formData, responseHandler);
    };

    const userPresent = (): boolean => {
        if (
            Object.entries(userProperties).every((property) => {
                // key at index 0 
                if (property.at(0) === 'userImage') {
                    return property.at(1)?.initialValue?.url === undefined;
                }
                // Value at index 1 
                return property.at(1)?.initialValue === undefined;
            }) && props.mode !== EstablishmentSaveMode.CREATE
        ) {
            return false;
        }
        return true;

    };


    useEffect(() => {
        if (eType) {
            setEstablishmentType(EstablishmentType.valueOf(eType!));
        } else {
            setEstablishmentType(EstablishmentType.HUB);
        }
    }, []);

    useEffect(() => {
        if (establishmentType) {
            if (props.mode === EstablishmentSaveMode.UPDATE || props.mode === EstablishmentSaveMode.SHOW) {
                getEstablishmentAndSetInitialProperties(props.uuid!!);
            } else {
                setInitialProperties(establishmentType);
            }
        }
    }, [establishmentType]);

    return (
        <>
            {
                props.mode === EstablishmentSaveMode.CREATE && <PageTopSection firstElement={false} menuList={breadcrumb} pageTitle={`Create ${EstablishmentType.valueOf(eType!)?.display}`} />

            }

            <div className={props.mode === EstablishmentSaveMode.CREATE ? 'main-body' : ''}>
                {props.mode === EstablishmentSaveMode.UPDATE && establishmentDetailsConfig.allowStatusChange &&
                    <div className='flex-justify-end' style={{ marginBottom: '15px' }}>
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive"
                            className='bg-meraai-orange'
                            checked={establishment?.active} onClick={changeEstablishmentStatus} />
                    </div>
                }
                <div className={`flex-align-center ${props.mode === EstablishmentSaveMode.CREATE ? 'main-body-tabs padding-top-25px' : ''}`}>
                    <Form
                        style={{ width: '100%' }}
                        onFinish={props.mode?.mode === EstablishmentSaveMode.UPDATE.mode ? updateData : saveData}
                        onFinishFailed={handleFormValidationFailure}
                        form={form}
                        validateTrigger="onBlur">
                        <Spin spinning={loading}>
                            <Collapse
                                expandIconPosition="end"
                                accordion
                                defaultActiveKey={collapsibleKeys.establishmentLayout}
                                onChange={key => {
                                    onCollapsibleKeyChanged(key);
                                }}>
                                {layoutProperties.showEstablishmentLayout && (
                                    <Collapse.Panel
                                        header={`${establishmentType?.display} Details`}
                                        key={collapsibleKeys.establishmentLayout}
                                        forceRender>
                                        <EstablishmentLayout
                                            establishmentProperties={establishmentProperties}
                                            setEstablishmentProperties={setEstablishmentProperties}
                                            establishmentType={establishmentType}
                                            setLoading={setLoading}
                                            form={form}
                                            mode={props.mode!!}
                                        />
                                    </Collapse.Panel>
                                )}
                                {(layoutProperties.showUserDetailsLayout ||
                                    layoutProperties.showUserEstablishmentDetailsLayout) && (
                                    <Collapse.Panel header="User Details" key={collapsibleKeys.userLayout} forceRender>
                                        {layoutProperties.showUserDetailsLayout && (
                                            userPresent() ?
                                                <UserLayout
                                                    userProperties={userProperties}
                                                    setUserProperties={setUserProperties}
                                                    form={form}
                                                /> : <h3 className={`text-meraai-orange ${layout.flex.center}`}>Could not find an active user. Please create one.</h3>
                                        )}
                                        {layoutProperties.showUserEstablishmentDetailsLayout && (
                                            <UserEstablishmentLayout
                                                userEstablishmentProperties={userEstablishmentProperties}
                                                setUserEstablishmentProperties={setUserEstablishmentProperties}
                                                form={form}
                                            />
                                        )}
                                    </Collapse.Panel>
                                )}
                                {layoutProperties.showBankDetailsLayout && (
                                    <Collapse.Panel
                                        header="Bank Account Details"
                                        key={collapsibleKeys.bankLayout}
                                        forceRender>
                                        <BankDetailLayout
                                            bankAccountProperties={bankAccountProperties}
                                            setBankAccountProperties={setBankAccountProperties}
                                            form={form}
                                        />
                                    </Collapse.Panel>
                                )}
                                {layoutProperties.showConfigDetailsLayout && (
                                    <Collapse.Panel
                                        header="Additional Details"
                                        key={collapsibleKeys.configLayout}
                                        forceRender>
                                        <ConfigDetails
                                            configProperties={configProperties}
                                            setConfigProperties={setConfigProperties}
                                            form={form}
                                        />
                                    </Collapse.Panel>
                                )}

                                {layoutProperties.showBrandDiscounts && (
                                    <Collapse.Panel
                                        header="Brand Discounts"
                                        key={collapsibleKeys.brandDiscountsLayout}
                                        forceRender>
                                        <BrandDiscountLayout
                                            visible={brandDiscountsListExpanded}
                                            establishmentUuid={props.uuid ?? ''}
                                            brandDiscounts={brandDiscountList}
                                            setBrandDiscounts={setBrandDiscountsList}
                                            updateAllowed={
                                                establishmentDetailsConfig.infoConfig?.allowBrandDiscountsUpdate!!
                                            }
                                        />
                                    </Collapse.Panel>
                                )}
                            </Collapse>

                            {props.mode?.showSaveButton && (
                                <Row gutter={[12, 12]} className={`pt-5 w-full ${layout.flex.end}`}>
                                    {
                                        props.mode?.showResetButton &&
                                        <Col className={'w-1/2 xs:w-1/3 md:w-1/4 xl:w-1/5 2xl:w-1/6'}>
                                            <Button
                                                className={`w-full ${style.meraaiDangerButton}`}
                                                size="large"
                                                onClick={resetAllProperties}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    }
                                    <Col className={'w-1/2 xs:w-1/3 md:w-1/4 xl:w-1/5 2xl:w-1/6'}>
                                        <Button
                                            className={`w-full ${style.meraaiOrangeButton}`}
                                            htmlType="submit"
                                            size="large"
                                            disabled={!checkIfPropertiesChanged().includes('changed')}
                                        >
                                            {props.mode?.saveButtonText}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Spin>
                    </Form>
                    <EstablishmentUpdatePreviewDrawer
                        isDrawerVisible={isDrawerVisible}
                        setIsDrawerVisible={setIsDrawerVisible}
                        handleCancel={handleCancel}
                        getEstablishmentAndSetInitialProperties={getEstablishmentAndSetInitialProperties}
                        establishmentProperties={establishmentProperties}
                        userEstablishmentProperties={userEstablishmentProperties}
                        bankAccountProperties={bankAccountProperties}
                        configProperties={configProperties}
                        brandsDiscountList={brandDiscountList}
                        uuid={props.uuid}
                    />

                    <Modal
                        open={visibleSaveModal}
                        width="30%"
                        title="Success"
                        className="establishment-layout-create-modal"
                        onCancel={() => {
                            setVisibleSaveModal(false);
                        }}
                        footer={
                            [
                                establishmentType === EstablishmentType.SELLER_HUB && < Button
                                    className="establishment-layout-create-modal-button-create-new"
                                    onClick={() => {
                                        setVisibleSaveModal(false);
                                        setInitialProperties(establishmentType!!);
                                        navigate(`/establishments/${establishmentUuid}/SELLER_HUB?type=BRANDS`, {
                                            state: {
                                                createBrand: true,
                                            } as LocationState,
                                        });
                                    }}>
                                    Add a brand
                                </Button>,
                                <Button
                                    className="establishment-layout-create-modal-button-list-establishments"
                                    onClick={() => {
                                        setVisibleSaveModal(false);
                                        navigate(`/establishments/${establishmentUuid}/${establishmentType?.type}?type=INFO`);
                                    }}>
                                    View
                                </Button>,
                            ]
                        }>
                        <BsShopWindow /> The establishment has been onboarded
                    </Modal>
                </div>
            </div >
        </>
    );
};