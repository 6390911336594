import { Button, Col, Image, Row, Select, Switch } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LIST_HUB_PRODUCTS, UPDATE_HUB_PRODUCTS } from '../../../../../../http/EndPoints';
import { getApiCall, postApiCall, ResponseHandler } from '../../../../../../http/HttpClient';
import { layout, style } from '../../../../../../styles/css/style';
import { ResponseMessage } from '../../../../../../Types';
import { EditableField } from '../../../../../common/templates/meraaiTable/EditableField';
import { EstablishmentType } from '../../../../../../types/EstablishmentType';
import { ProductListResponse } from '../../../../../../types/Product';
import { isArrayEmpty } from '../../../../../../Utils';
import { SelectBrandList } from '../../../../../brands';
import { EstablishmentsDropDown, EstablishmentSelectionMode, getEncodedStringFromUuidList, getUuidListFromEncodedString } from '../../../../../common/establishments/EstablishmentsDropDown';
import { OpenNotification } from '../../../../../common/notifications/Notifications';
import { MandatoryTitle, OptionalTitle } from '../../../../../common/TableTitle';
import { DataNotFound } from '../../../../../common/templates/dataNotFound';
import { ListingPage } from '../../../../../common/templates/listingPage';
import { MeraaiTable } from '../../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../../common/templates/meraaiTable/types';
import { ResponseDrawer } from '../../../../../common/templates/responseDrawer';
import { Currency, Quantity } from '../../../../../orders/layer/details/hubsAndOutlets/utils';
import { Brand } from '../../../../../orders/listing/ListingData';
import { ProductImage, ProductProperties } from '../properties/ProductProperties.';
import { HubProductListing, HubProductListingType } from './types';

export const HubProductList = () => {

    // Fetching the establishmentUuid from the URL
    const { establishmentUuid } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const firstRender = useRef<boolean>(true);

    // Loading and pagination
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(20);

    // Brand
    const [selectedBrand, setSelectedBrand] = useState<Brand>();

    // Hub Product
    const [productListResponse, setProductListResponse] = useState<ProductListResponse>();
    const [productList, setProductList] = useState<ProductProperties[]>([]);

    const [hubProductListingType, setHubProductListingType] = useState<HubProductListingType>(
        HubProductListing.EXISTING_ON_TOP.key,
    );
    const [productListMessage, setProductListMessage] = useState<string>('Search for the data using filters mentioned above.');
    const [productUpdateResponse, setProductUpdateResponse] = useState<string>();
    // Seller 
    const [sellerUuid, setSellerUuid] = useState<string>();
    const [requestProductList, setRequestProductList] = useState<boolean>(false);
    // Methods
    // URL Params
    const setStateFromQueryParams = (queryParam, param, stateSetter) => {
        if (!queryParam.has(param)) {
            return;
        }
        if (param == 'e-uuid') {
            const encodedUuid = queryParam.get(param);
            if (encodedUuid) {
                const uuid = getUuidListFromEncodedString(encodedUuid);
                if (uuid?.length! > 0) {
                    stateSetter(uuid);
                }
            }
        } else if (['page', 'pageSize'].includes(param)) {
            const values = parseInt(queryParam.get(param));
            if (values) {
                stateSetter(values);
            }
        } else if (param == 'brand') {
            const brandId = parseInt(queryParam.get(param));
            stateSetter({ ...selectedBrand, id: brandId });
        } else {
            stateSetter(queryParam.get(param));
        }
    };

    const setQueryParamsFromState = (queryParam, param, state) => {
        if (state) {
            if (param == 'e-uuid') {
                if (!isArrayEmpty(state)) {
                    const encodedUuid = getEncodedStringFromUuidList(state);
                    queryParam.set(param, encodedUuid);
                } else {
                    queryParam.delete(param);
                }
            } else {
                queryParam.set(param, state);
            }
        } else {
            queryParam.delete(param);
        }
    };

    const setStateFromUrlParams = () => {
        setStateFromQueryParams(queryParams, 'e-uuid', setSellerUuid);
        setStateFromQueryParams(queryParams, 'brand', setSelectedBrand);
        setStateFromQueryParams(queryParams, 'sortby', setHubProductListingType);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setStateFromQueryParams(queryParams, 'size', setSize);
        setRequestProductList(true);
    };

    const setUrlParamsFromState = () => {
        setQueryParamsFromState(queryParams, 'e-uuid', sellerUuid);
        setQueryParamsFromState(queryParams, 'brand', selectedBrand?.id);
        setQueryParamsFromState(queryParams, 'sortby', hubProductListingType);
        setQueryParamsFromState(queryParams, 'page', page);
        setQueryParamsFromState(queryParams, 'size', size);

        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const initializeListProductProperties = () => {
        return new ProductProperties({
            id: { beingUsed: true },
            active: {
                beingUsed: true,
                component: (ref, saveValue, record) => {
                    if (record!.existing.value) {
                        return (
                            <Switch
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                                ref={ref}
                                className={`${layout.flex.center} bg-meraai-orange`}
                                onChange={saveValue}
                            />
                        );
                    }
                    return (
                        <Button
                            onClick={() => {
                                record!.existing.value = true;
                                setProductList(previousProductList => [...previousProductList]);
                            }}>
                            Add
                        </Button>
                    );
                },
                componentValuePropName: 'checked',
                alwaysRenderComponent: true,
            },
            name: { beingUsed: false },
            price: {
                beingUsed: true,
                required: true,
                validateValue: value => value > 0,
                isEditable: (record: ProductProperties) => record.existing?.value ?? false,
            },
            mrp: { beingUsed: false },
            sku: { beingUsed: false },
            hsn: { beingUsed: false },
            ean: { beingUsed: false },
            gst: { beingUsed: false },
            cess: { beingUsed: false },
            brand: { beingUsed: false },
            categories: { beingUsed: false },
            orderQuantityMultiple: { beingUsed: false },
            image: { beingUsed: false },
        });
    };

    const getProductList = () => {
        setLoading(true);

        const responseHandler: ResponseHandler<ProductListResponse> = {
            onResponseSuccess: (response: ProductListResponse) => {
                setLoading(false);
                setProductListResponse(response);
                if (!(response.products.length > 0)) {
                    setProductListMessage('No Products Found');
                }
                const productListData = response?.products.map(productItem => {
                    const product = initializeListProductProperties();
                    product.setHubProductListValues!(productItem);
                    return product;
                });
                setProductList(productListData!);
            },
            onResponseFailed: () => {
                setLoading(false);
            },
            onResponseError: () => {
                setLoading(false);
            },
        };

        getApiCall(
            LIST_HUB_PRODUCTS(
                establishmentUuid!,
                selectedBrand?.id!,
                hubProductListingType,
                sellerUuid!,
                page - 1,
                size,
            ),
            responseHandler,
        );
    };

    const getProductListAllowed = (): boolean => {
        if (_.isString(sellerUuid) && _.isObject(selectedBrand)) {
            return true;
        }
        return false;
    };

    // Update product price 
    const performUpdateProduct = () => {

        const getRequestBody = () => {
            const hubProducts = productList
                .map(product => {
                    const request = {};
                    ProductProperties.getFilteredProperties!(product).forEach((field) => {
                        if ((field.isValueChanged!() && field.updateProperties)) {
                            const { updateProperties } = field;
                            if (field.isValueAcceptable!()) {
                                request[updateProperties.requestKey] = field.value;
                            }
                        }
                    });
                    /**
                     * We are excluding all those object which only contains product_id property.
                     * Because the above "IF" condition will add every key property of an object into the request object.
                     * Which we dont want until and unless we have any other property updated of that object.
                     */
                    if (Object.keys(request).length > 0) {
                        if (!request[product.id?.updateProperties?.requestKey!]) {
                            request[product.id?.updateProperties?.requestKey!] = product.id?.value;
                        }
                        return request;
                    }
                    return null;
                })
                .filter(request => request != null);
            return { hub_products: hubProducts };
        };

        const requestBody = getRequestBody();
        if (requestBody.hub_products.length == 0) {
            OpenNotification('Cannot proceed', 'Please make sure that the changes are correct', 'error');
            return;
        }
        setLoading(true);
        const requestHandler: ResponseHandler<ResponseMessage> = {
            onResponseSuccess: (response: ResponseMessage) => {
                setProductUpdateResponse(response.message);
                setLoading(false);
            },
            onResponseFailed: () => {
                setLoading(false);
            },
            onResponseError: () => {
                setLoading(false);
            },
        };
        postApiCall(UPDATE_HUB_PRODUCTS(establishmentUuid), requestBody, requestHandler);
    };

    const isProductUpdated = (): boolean => {
        // Checking for price, existing and active properties.
        return productList.some(product => product.existing?.initialValue !== product.existing?.value || product.price.initialValue !== product.price.value || product.active?.initialValue !== product.active?.value);
    };

    // Editable table columns
    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image',
            rowVisibility: [Device.DESKTOP],
            render: (image: EditableField<ProductImage>) => <Image src={image?.value?.webUrl} width={50} height={40} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 250,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: name => <label>{name.value}</label>,
        },
        {
            title: 'Sku',
            dataIndex: 'sku',
            render: sku => <label>{sku.value}</label>,
        },
        {
            title: 'Ean',
            dataIndex: 'ean',
            rowVisibility: [Device.DESKTOP],
            render: ean => <label>{ean.value}</label>,
        },
        {
            title: 'Hsn',
            dataIndex: 'hsn',
            render: hsn => <label>{hsn.value}</label>,
        },
        {
            title: 'Gst',
            dataIndex: 'gst',
            rowVisibility: [Device.DESKTOP],
            render: gst => <label>{gst.value}</label>,
        },
        {
            title: 'Cess',
            dataIndex: 'cess',
            render: cess => <label>{cess.value}</label>,
        },
        {
            title: 'Mrp',
            dataIndex: 'mrp',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: mrp => <Currency value={mrp.value} />,
        },
        {
            title: <MandatoryTitle title='Price' description='Price should always be less than or equal to the MRP' />,
            dataIndex: 'price',
            editable: true,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
        },
        {
            title: <OptionalTitle title='Carton capacity' description='No. of units in a carton' />,
            dataIndex: 'orderQuantityMultiple',
            rowVisibility: [Device.DESKTOP],
            render: orderQuantityMultiple => <Quantity quantity={orderQuantityMultiple.value} />,
        },
        {
            dataIndex: 'active',
            editable: true,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
        },
    ];

    useEffect(() => {
        if (firstRender.current) {
            if (queryParams.get('e-uuid')) {
                setStateFromUrlParams();
            }
            firstRender.current = false;
            return;
        }
        getProductList();
    }, [page, requestProductList]);

    useEffect(() => {
        if (productListResponse) {
            setUrlParamsFromState();
        }
    }, [productListResponse]);

    return (
        <div className='overflow-auto h-screen'>
            <ListingPage
                hideBreadcrumb={true}
                topRowStyleClass='!px-0'
                bottomRowStyleClass='!px-0'
                header={
                    <>
                        <Row>
                            <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>
                                <EstablishmentsDropDown
                                    setLoading={setLoading}
                                    type={EstablishmentType.SELLER_HUB}
                                    selectedUuids={sellerUuid!}
                                    setSelectedUuids={setSellerUuid}
                                    selectionMode={EstablishmentSelectionMode.SINGLE_SELECT}
                                    fetchEstablishmentList
                                    onChange={() => {
                                    }}
                                />
                            </Col>
                            <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>
                                <SelectBrandList
                                    selectedBrand={selectedBrand!}
                                    setSelectedBrand={setSelectedBrand}
                                    className='w-full'
                                    eUuid={sellerUuid!}
                                    disabled={sellerUuid === undefined}
                                />
                            </Col>
                            <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>
                                <Select
                                    className='w-full'
                                    placeholder="Select Product Listing"
                                    defaultValue={HubProductListingType.EXISTING_ON_TOP}
                                    onChange={value => setHubProductListingType(value)}>
                                    {Object.values(HubProductListing).map((type, index) => (
                                        <Select.Option key={index} value={type.key}>
                                            {type.display}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col className='w-full xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-5 pb-5 lg:pb-0'>
                                <Button
                                    className={`${style.meraaiOrangeButton}`}
                                    size='large'
                                    disabled={!getProductListAllowed()}
                                    onClick={() => {
                                        getProductList();
                                    }}
                                >
                                    Get Products
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                body={
                    isArrayEmpty(productList) ?

                        < DataNotFound loading={loading} title={productListMessage} />
                        :
                        <Row>
                            <Col className='w-full'>
                                <MeraaiTable
                                    loading={loading}
                                    columns={columns}
                                    dataSource={productList}
                                    setDataSource={setProductList}
                                    pagination={{
                                        pageSize: size,
                                        current: page,
                                        total: productListResponse?.page_count! * size,
                                        onChange: currentPage => {
                                            // TODO We have to add a validation, If the user has changed something in the current page data of the table then  we won't let him change the page number directly.
                                            // will have to warn him with the help of modal popup before proceeding toward next or perivous page.
                                            setPage(currentPage);
                                        },
                                    }}
                                />
                            </Col>
                            <Col className={`w-full ${layout.flex.end}`}>
                                {
                                    isProductUpdated() && <Button
                                        className={`w-full xs:w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 ${style.meraaiOrangeButton} mt-5`}
                                        size='large'
                                        disabled={!(productList.length > 0)}
                                        onClick={() => performUpdateProduct()}
                                    >
                                        Update
                                    </Button>

                                }

                            </Col>
                        </Row>
                }
            />
            <ResponseDrawer
                type={'SUCCESS'}
                message={productUpdateResponse}
                open={productUpdateResponse !== undefined}
                onClose={() => {
                    setProductUpdateResponse(undefined);
                    getProductList();
                }}
            />
        </div>
    );
};