/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */
/* eslint @typescript-eslint/naming-convention: 0 */
/* eslint @typescript-eslint/no-throw-literal: 0 */

import { Button, Card, Col, Input, InputNumber, message, Row, Select, Spin, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import ImgCrop from 'antd-img-crop';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import { ResponseHandler } from '../../http/HttpClient';
import FieldLayout from '../common/FieldLayout';
import DocumentImageRenderer from './DocumentImageRenderer';
import DocumentRenderer from '../DocumentRenderer';
import { FileIdentifier, FileUploadResponse, upload } from '../../utilities/UploadFile';

type documentType = 'IMAGE' | 'DOC' | 'REMARK' | 'DROP_DOWN' | 'AMOUNT' | 'TRANSACTION_ID';

const multipleValuesDocTypes: documentType[] = ['IMAGE', 'DOC'];

interface DocumentConfig {
    global: boolean;
    type: documentType;
    display: string;
    datumName: string;
    datumType: string;
}

export interface DropDownProperties {
    values: Array<string>,
    allowCustomInput: boolean,
    isInputCustom?: boolean,
}

export interface DocumentValue {
    name?: string;
    value: string;
}

export const documentConfigs: DocumentConfig[] = [
    {
        global: true,
        type: 'IMAGE',
        display: 'Image',
        datumName: 'image',
        datumType: 'IMAGE_URL',
    },
    {
        global: true,
        type: 'DOC',
        display: 'Document',
        datumName: 'file',
        datumType: 'FILE_URL',
    },
    {
        global: true,
        type: 'REMARK',
        display: 'Remarks',
        datumName: 'remark',
        datumType: 'STRING',
    },
    {
        global: true,
        type: 'DROP_DOWN',
        display: 'Options',
        datumName: 'option',
        datumType: 'STRING',
    },
    {
        global: false,
        type: 'AMOUNT',
        display: 'Amount',
        datumName: 'amount',
        datumType: 'FLOAT',
    },
    {
        global: false,
        type: 'TRANSACTION_ID',
        display: 'Transaction ID',
        datumName: 'transactionId',
        datumType: 'STRING',
    },
];

export interface MetadataDocument {
    id: string;
    type: documentType; // Should be equal to one of the fileTypes in DocumentConfig
    name?: string; // name of the document
    purpose?: string;
    values?: DocumentValue[]; // For images and files, this is a url, while for text it is a string
    required: boolean;
    dropdownProperties?: DropDownProperties;
    multiple?: boolean;
    maxFiles?: number;
}

const isPurposeValid = (document: MetadataDocument): boolean => {
    return document?.purpose?.length! >= 3;
};



interface DocumentProps {
    document: MetadataDocument;
    displayValue?: string;
    setValueInDocument?: (document: MetadataDocument, value: string) => void;
    uploadFile?: (document: MetadataDocument, file: RcFile) => void;
}

export interface MetadataUploaderProps {
    documents: MetadataDocument[];
    updateDocuments: React.Dispatch<React.SetStateAction<MetadataDocument[]>>;
}

export const createDocument = (
    type: documentType,
    required: boolean,
    purpose: string | undefined,
    dropdownProperties?: DropDownProperties,
    multiple?: boolean,
): MetadataDocument => {
    if (!documentConfigs.find(config => config.type === type)) {
        message.error(`Invalid File type ${type}`);
        throw `Invalid File type ${type}`;
    }

    return {
        id: uuidv4(),
        type,
        required,
        purpose,
        dropdownProperties,
        multiple,
    };
};

const { TextArea } = Input;

function ImageUploader({ document, uploadFile, displayValue }: DocumentProps) {
    return (
        <ImgCrop
            modalTitle="Edit Image. Only the part which is in the box will be selected"
            rotate
            minZoom={0.5}
            shape="rect"
            cropperProps={{
                restrictPosition: false,
            }}>
            <Upload
                accept=".png, .jpg, .jpeg"
                disabled={!isPurposeValid(document)}
                name="file"
                multiple={false}
                beforeUpload={file => uploadFile!(document, file)}>
                <Button disabled={!isPurposeValid(document)}>
                    {displayValue}
                </Button>
            </Upload>
        </ImgCrop>
    );
}

function DocUploader({ document, uploadFile, displayValue }: DocumentProps) {
    return (
        <Upload
            accept=".pdf"
            disabled={!isPurposeValid(document)}
            multiple={false}
            fileList={[]}
            beforeUpload={file => uploadFile!(document, file)}>
            <Button disabled={!isPurposeValid(document)}>{displayValue}</Button>
        </Upload>
    );
}

function TextEditor({ document, setValueInDocument }: DocumentProps) {
    return (
        <TextArea
            placeholder="Add your remarks"
            disabled={!isPurposeValid(document)}
            value={document.values?.at[0]}
            onChange={event => {
                setValueInDocument!(document, event.currentTarget.value);
            }}
        />
    );
}

export function MetadataUploader(props: MetadataUploaderProps) {
    const { documents, updateDocuments } = props;
    const [loading, setLoading] = React.useState<boolean>(false);

    const addDocument = (type: documentType) => {
        updateDocuments([createDocument(type, false, undefined, undefined, multipleValuesDocTypes.includes(type)), ...documents]);
    };

    const removeDocument = (document: MetadataDocument) => {
        updateDocuments(documents.filter(doc => doc.id !== document.id));
    };

    const setTypeInDocument = (document: MetadataDocument, type: documentType) => {
        document.type = type;
        document.values = undefined;
        if (!multipleValuesDocTypes.includes(type)) {
            document.multiple = false;
        }
        updateDocuments([...documents]);
    };

    const setPurposeInDocument = (document: MetadataDocument, purpose: string) => {
        document.purpose = purpose;
        updateDocuments([...documents]);
    };

    const removeValueFromDocument = (document: MetadataDocument, value: string | undefined) => {
        if (value) {
            document.values = document.values?.filter(item => item.value !== value);
        }
        updateDocuments([...documents!!]);
    };

    const setValueInDocument = (document: MetadataDocument, value: string | undefined, name?: string) => {
        if (value) {
            if (!document.values || !document.multiple) {
                document.values = [];
            }
            document.values.push({ name: name ?? document.name, value: value });
        }
        updateDocuments([...documents!!]);
    };

    const setNameInDocument = (document: MetadataDocument, name: string) => {
        document.name = name;
        updateDocuments([...documents!!]);
    };

    const uploadFile = (document: MetadataDocument, file: RcFile): Promise<string> => {
        setLoading(true);
        setNameInDocument(document, file.name);
        const responseHandler: ResponseHandler<FileUploadResponse> = {
            onResponseSuccess(response: FileUploadResponse): void {
                setValueInDocument(document, response.url);
                setLoading(false);
            },
            onResponseFailed(errors: String[]): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        upload(document.type, file, responseHandler, FileIdentifier.OPERATIONS);
        return Promise.reject('NO ACTION');
    };



    function isUploadAllowed(document: MetadataDocument): boolean {
        const maxValues = document.multiple ? document.maxFiles ?? 20 : 1;
        return document?.values != undefined && document.values?.length > 0 && document.values?.length < maxValues;
    }

    function updateDropdownProperty(document: MetadataDocument, isInputCustom: boolean) {
        if (document.dropdownProperties) {
            document.dropdownProperties.isInputCustom = isInputCustom;
            updateDocuments([...documents]);
        }
    }

    return (
        <>
            <a onClick={() => addDocument('IMAGE')} className="flex-align-center">
                <AiOutlinePlusSquare size={20} style={{ paddingRight: '3px' }} /> Add supporting images/docs/remarks
            </a>
            <Spin spinning={loading}>
                <div className="scroll-view-400y metadata-uploader">
                    {documents?.map((document: MetadataDocument, index: number) => {
                        return (
                            <Card key={index} className="basic-card" hoverable>
                                {!document?.required && (
                                    <IoMdClose
                                        color="#A9A9A9"
                                        size={20}
                                        onClick={() => removeDocument(document)}
                                        style={{
                                            cursor: 'pointer',
                                            marginTop: '2px',
                                            marginRight: '2px',
                                            float: 'right',
                                        }}
                                    />
                                )}
                                <div style={{ paddingTop: '20px', paddingRight: '24px' }}>
                                    {
                                        !document?.required && <>
                                            <FieldLayout>
                                                <span>Type</span>
                                                <Select
                                                    className="filter-drop-down"
                                                    value={document?.type}
                                                    onChange={type => setTypeInDocument(document, type)}
                                                    disabled={document?.required}>
                                                    {documentConfigs
                                                        .filter(documentConfig =>
                                                            documentConfig?.type === 'DROP_DOWN'
                                                                ? document?.dropdownProperties?.values ?? false
                                                                : documentConfig?.global,
                                                        )
                                                        .map(documentConfig => (
                                                            <Select.Option
                                                                key={documentConfig.type}
                                                                value={documentConfig.type}>
                                                                {documentConfig.display}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </FieldLayout>

                                            <FieldLayout>
                                                <span>Purpose</span>
                                                <Input
                                                    value={document?.purpose}
                                                    disabled={document?.required}
                                                    maxLength={25}
                                                    placeholder={`Purpose of the ${document?.type.toLowerCase()}`}
                                                    onChange={event =>
                                                        setPurposeInDocument(document, event.currentTarget.value)
                                                    }
                                                />
                                            </FieldLayout>
                                        </>
                                    }


                                    {document?.type === 'IMAGE' && (
                                        <>
                                            <FieldLayout>
                                                <Row>{document?.required && document?.purpose}</Row>
                                                <Row key={document.id} gutter={[24, 24]}>
                                                    {
                                                        (!document.values || document.values?.length == 0) &&
                                                        <Col xl={12} xs={24}>
                                                            <ImageUploader document={document} uploadFile={uploadFile} displayValue={'Upload an Image'} />
                                                        </Col>
                                                    }
                                                    {
                                                        isUploadAllowed(document) &&
                                                        <Col xl={12} xs={24}>
                                                            <ImageUploader document={document} uploadFile={uploadFile} displayValue={'Upload More'} />
                                                        </Col>

                                                    }
                                                </Row>

                                            </FieldLayout>
                                            <Row className="flex-justify-center">
                                                {document.values && (
                                                    <DocumentImageRenderer height={90} width={90} images={document.values} deleteImage={(image: string) => removeValueFromDocument(document, image)} />
                                                )}
                                            </Row>
                                        </>

                                    )}

                                    {document?.type === 'DOC' && (
                                        <>
                                            <FieldLayout>
                                                <Row>{document?.required && document?.purpose}</Row>
                                                <Row key={document.id} gutter={[24, 24]}>
                                                    {
                                                        (!document.values || document.values?.length == 0) &&
                                                        <Col xl={12} xs={24}>
                                                            <DocUploader document={document} uploadFile={uploadFile} displayValue={'Upload Document'} />
                                                        </Col>
                                                    }
                                                    {
                                                        isUploadAllowed(document) &&
                                                        <Col xl={12} xs={24}>
                                                            <DocUploader document={document} uploadFile={uploadFile} displayValue={'Upload More'} />
                                                        </Col>

                                                    }
                                                </Row>
                                            </FieldLayout>
                                            <Row className="flex-justify-center">
                                                {document.values && (
                                                    <DocumentRenderer documents={document.values} deleteDocument={(documentValue: string) => removeValueFromDocument(document, documentValue)} />
                                                )}
                                            </Row>
                                        </>
                                    )}

                                    {document?.type === 'REMARK' && (
                                        <FieldLayout>
                                            <span>{document?.required && document?.purpose}</span>
                                            <TextEditor document={document} setValueInDocument={setValueInDocument} />
                                        </FieldLayout>
                                    )}

                                    {document?.type === 'DROP_DOWN' && (
                                        <Row gutter={[20, 20]}>
                                            <Col xs={24}>
                                                <FieldLayout styleClass='flex-center'>
                                                    <span>{document?.required && document?.purpose}</span>
                                                    <Select
                                                        className="filter-drop-down"
                                                        placeholder="Select reason"
                                                        defaultValue={document?.values?.at(0)?.value}
                                                        onChange={value => {
                                                            setValueInDocument(document, value);

                                                            if (document.dropdownProperties?.allowCustomInput) {
                                                                if (value === 'other') {
                                                                    updateDropdownProperty(document, true);
                                                                    removeValueFromDocument(document, value);
                                                                } else {
                                                                    updateDropdownProperty(document, false);
                                                                }
                                                            }
                                                        }}>
                                                        {document.dropdownProperties?.values?.map((dropdownValue, index) => (
                                                            <Select.Option key={index} value={dropdownValue}>
                                                                {dropdownValue}
                                                            </Select.Option>
                                                        ))}
                                                        {
                                                            document.dropdownProperties?.allowCustomInput && <Select.Option key={document.dropdownProperties?.values?.length} value={'other'}>
                                                                {'other'}
                                                            </Select.Option>
                                                        }

                                                    </Select>
                                                </FieldLayout>
                                            </Col>
                                            {
                                                document.dropdownProperties?.isInputCustom && <Col xs={24}>
                                                    <TextArea
                                                        style={{ resize: 'none' }}
                                                        placeholder="Enter a reason"
                                                        onBlur={(event) => {
                                                            if (event.currentTarget.value.trim() !== '') {
                                                                setValueInDocument(document, event.currentTarget.value.trim());
                                                            } else {
                                                                removeValueFromDocument(document, document.values?.at(0)?.value.trim());
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                    )}

                                    {document?.type === 'AMOUNT' && (
                                        <FieldLayout
                                            styleClass='flex-center'
                                        >
                                            <span>{document?.required && document?.purpose}</span>
                                            <InputNumber
                                                type='number'
                                                style={{ width: '100%' }}
                                                controls={false}
                                                disabled={!document?.required}
                                                maxLength={25}
                                                placeholder={'Enter the amount'}
                                                onChange={amount =>
                                                    setValueInDocument(document, amount.toString())
                                                }
                                            />
                                        </FieldLayout>
                                    )}

                                    {document?.type === 'TRANSACTION_ID' && (
                                        <FieldLayout>
                                            <span>{document?.required && document?.purpose}</span>
                                            <Input
                                                style={{ width: '100%' }}
                                                disabled={!document?.required}
                                                maxLength={25}
                                                placeholder={'Enter transaction id'}
                                                onChange={event =>
                                                    setValueInDocument(document, event?.currentTarget.value)
                                                }
                                            />
                                        </FieldLayout>
                                    )}
                                </div>
                            </Card>
                        );
                    })}
                </div>
            </Spin>
        </>
    );
}
