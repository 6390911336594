import * as React from 'react';

function Warehouse() {
    return (
        <svg id="Group_28137" data-name="Group 28137" xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 81.899 81.899">
            <circle id="Ellipse_3360" data-name="Ellipse 3360" cx="40.95" cy="40.95" r="40.95" transform="translate(0 0)" fill="#fff7eb" />
            <g id="Group_28136" data-name="Group 28136" transform="translate(14.787 18.2)">
                <path id="Path_24112" data-name="Path 24112" d="M1937.156,381.514v1.607q0,10.518,0,21.036c0,3.453-1.283,4.554-4.9,4.074V388.177H1896.7v19.957c-3.382.821-5.016-.783-4.973-3.59.107-7.132.035-14.267.035-21.4v-1.772c-.706,0-1.293.017-1.879,0a1.344,1.344,0,0,1-1.479-1.527,1.32,1.32,0,0,0,0-.243c-.384-2.321.488-3.584,2.774-4.562,7.406-3.169,14.688-6.627,22.006-10a2.788,2.788,0,0,1,2.541-.008q11.642,5.359,23.317,10.647a2.225,2.225,0,0,1,1.5,2.326C1940.517,381.064,1940.559,381.064,1937.156,381.514ZM1914.4,381.5h2.059c2.43,0,2.4,0,2.307-2.475-.031-.782-.306-1.062-1.083-1.044-1.735.038-3.471.011-5.207.01-2.511,0-2.5,0-2.3,2.591.051.655.276.944.953.925C1912.217,381.474,1913.307,381.5,1914.4,381.5Zm-13.5,0c.647,0,1.294.009,1.94,0,2.689-.046,2.376.453,2.425-2.478.014-.806-.3-1.057-1.074-1.04-1.7.037-3.4-.011-5.092.015-2.689.042-2.4-.572-2.428,2.585-.006.684.3.935.956.923C1898.715,381.486,1899.806,381.5,1900.9,381.5Zm27.069,0v0c.647,0,1.293.009,1.94,0,2.56-.042,2.313.438,2.36-2.434.014-.883-.357-1.1-1.16-1.083-1.7.041-3.4-.006-5.092.017-2.6.036-2.32-.473-2.338,2.412,0,.081-.032.2.008.237.287.291.571.781.891.81C1925.7,381.56,1926.835,381.5,1927.967,381.5Z" transform="translate(-1888.316 -364.723)" fill="#f69626" />
                <path id="Path_24113" data-name="Path 24113" d="M2023.434,509.778c.055.632.094,1.071.14,1.6h1.635c.054-.456.109-.923.187-1.584a12.78,12.78,0,0,1,2.337.045,1.307,1.307,0,0,1,.8.955c.074,2.106.037,4.216.038,6.325,0,.742-.447.983-1.1.984-2.027,0-4.055,0-6.083.008-.746,0-1.14-.325-1.14-1.089q0-3.042,0-6.083c0-.8.363-1.2,1.191-1.164C2022.075,509.8,2022.716,509.778,2023.434,509.778Z" transform="translate(-1988.144 -474.468)" fill="#f69626" />
                <path id="Path_24114" data-name="Path 24114" d="M1942.135,509.773c.114-.086.144-.128.178-.132,2.972-.306,3.022-.261,3.022,2.685,0,1.46-.018,2.919,0,4.379.014.881-.385,1.238-1.254,1.225-1.905-.028-3.812-.05-5.716,0-1.082.026-1.414-.454-1.385-1.471.05-1.782.065-3.569,0-5.35-.047-1.193.44-1.629,1.581-1.52.514.049,1.036.008,1.615.008l.16,1.607h1.635Z" transform="translate(-1925.137 -474.284)" fill="#f69626" />
                <path id="Path_24115" data-name="Path 24115" d="M1961.022,468.156c.06.622.1,1.056.152,1.576h1.632l.191-1.4c.082-.048.147-.118.218-.125,2.911-.273,2.911-.272,2.911,2.617,0,1.458-.024,2.916.007,4.373.019.854-.277,1.274-1.191,1.258-1.984-.033-3.969-.024-5.953,0-.823.008-1.166-.346-1.154-1.172.027-1.862.066-3.728-.012-5.588-.049-1.162.346-1.664,1.521-1.549C1959.86,468.2,1960.386,468.156,1961.022,468.156Z" transform="translate(-1940.906 -442.945)" fill="#f69626" />
                <path id="Path_24116" data-name="Path 24116" d="M2002.676,468.24l.163,1.585h1.625l.226-1.582a10.245,10.245,0,0,1,2.171.044,1.4,1.4,0,0,1,.921.938c.084,2.1.084,4.212,0,6.315a1.346,1.346,0,0,1-.915.941c-2.1.086-4.21.037-6.316.047-.825,0-1.009-.475-1-1.183.013-1.944.03-3.889-.014-5.832-.021-.946.347-1.342,1.287-1.28C2001.416,468.272,2002.017,468.24,2002.676,468.24Z" transform="translate(-1972.471 -443.025)" fill="#f69626" />
                <path id="Path_24117" data-name="Path 24117" d="M1981.84,509.775l.215,1.622h1.628l.2-1.455c.09-.054.157-.129.228-.132,3.492-.146,2.854-.049,2.888,2.67.019,1.459-.021,2.919.012,4.378.018.8-.25,1.236-1.1,1.232q-3.04-.014-6.08,0c-.844,0-1.12-.426-1.108-1.224.029-1.9.054-3.811-.005-5.715-.034-1.088.421-1.467,1.447-1.382C1980.683,509.811,1981.207,509.775,1981.84,509.775Z" transform="translate(-1956.72 -474.466)" fill="#f69626" />
            </g>
        </svg>

    );
}

export default Warehouse;
