import { Button, Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SELLER_HUB_ORDER_LIST_PAGE } from '../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { style } from '../../../../../styles/css/style';
import { EstablishmentType } from '../../../../../types/EstablishmentType';
import { HubWiseSellerOrderAggregate, HubWiseSellerOrderAggregateList } from '../../../../../types/SellerOrder';
import { addKeyToDataList, formatCurrency } from '../../../../../Utils';
import { EstablishmentsDropDown, EstablishmentSelectionMode, getEncodedStringFromUuidList, getUuidListFromEncodedString } from '../../../../common/establishments/EstablishmentsDropDown';
import { PageBreadcrumbHeader } from '../../../../common/pageBreadcrumbHeader';
import { DataNotFound } from '../../../../common/templates/dataNotFound';
import { ListingPage } from '../../../../common/templates/listingPage';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import { SellerHubOrderDetails } from '../../details/sellerHubs';

export const SellerHubOrderListing = () => {

    // States
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const [sellerHubUuid, setSellerHubUuid] = useState<string>();
    const [sellerHubListResponseMessage, setSellerHubListResponseMessage] = useState(
        'Search for the data using filters mentioned above.',
    );
    const [sellerHubOrderResponse, setSellerHubOrderResponse] = useState<HubWiseSellerOrderAggregateList>();
    const [getOrders, setGetOrders] = useState<boolean>(false);

    // Methods
    const getHubWiseSellerOrderAggregate = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<HubWiseSellerOrderAggregateList> = {
            onResponseSuccess(response: HubWiseSellerOrderAggregateList): void {
                setLoading(false);
                if (!(response.hub_wise_aggregate.length > 0)) {
                    setSellerHubListResponseMessage(' Oops! No orders found.');
                }
                setSellerHubOrderResponse(response);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        getApiCall(SELLER_HUB_ORDER_LIST_PAGE(sellerHubUuid), responseHandler);

    };
    const setQueryParamsFromState = (queryParams, param, state) => {
        if (state) {
            if (param == 'e-uuids') {
                const encodedList = getEncodedStringFromUuidList(state);
                queryParams.set(param, encodedList);
            } else {
                queryParams.set(param, state);
            }
        }
    };
    const setStateFromQueryParams = (queryParams: URLSearchParams, param: string, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList) {
                    stateSetter(uuidList);
                    setGetOrders(true);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const pageNo = parseInt(queryParams.get(param)!);
            stateSetter(pageNo);
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setStateFromURLParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setSellerHubUuid);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setStateFromQueryParams(queryParams, 'size', setPageSize);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', sellerHubUuid);
        setQueryParamsFromState(queryParams, 'page', page);
        setQueryParamsFromState(queryParams, 'size', pageSize);

        navigate(`?${queryParams.toString()}`, { replace: true });
    };


    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Hub Name',
            width: 350,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            align: 'center',
            render: (_, record: HubWiseSellerOrderAggregate) => <label>{record?.hub?.name}</label>,
        },
        {
            title: 'Items',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            align: 'center',
            render: (_, record: HubWiseSellerOrderAggregate) => <label>{record?.aggregate.products.length === 1 ? '1 Item' : record?.aggregate.products.length + ' Items'}</label>,
        },
        {
            title: 'Amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            align: 'center',
            render: (_, record: HubWiseSellerOrderAggregate) => <label>{formatCurrency(record?.aggregate?.amount)}</label>,
        },
    ];

    useEffect(() => {
        setStateFromURLParams();
    }, []);

    useEffect(() => {
        if (sellerHubUuid) {
            getHubWiseSellerOrderAggregate();
        }
    }, [getOrders]);

    useEffect(() => {
        if (sellerHubUuid) {
            setUrlParamsFromState();
        }
    }, [page]);

    return (
        <ListingPage
            breadcrumb={
                <>
                    <PageBreadcrumbHeader items={[
                        'Orders',
                        'Seller hubs',
                    ]} />
                </>
            }
            header={
                <Row gutter={[24, 24]}>
                    <Col className='w-full sm:w-1/3 lg:w-1/4 xl:w-1/5'>
                        <EstablishmentsDropDown
                            setLoading={setLoading}
                            type={EstablishmentType.SELLER_HUB}
                            selectedUuids={sellerHubUuid!}
                            setSelectedUuids={setSellerHubUuid}
                            fetchEstablishmentList={true}
                            selectionMode={EstablishmentSelectionMode.SINGLE_SELECT}
                        />
                    </Col>

                    <Col className='w-full sm:w-1/3 lg:w-1/4 xl:w-1/5'>
                        <Button
                            className={`w-full ${style.meraaiOrangeButton}`}
                            size='large'
                            disabled={!sellerHubUuid}
                            onClick={() => {
                                setUrlParamsFromState();
                                getHubWiseSellerOrderAggregate();
                            }}
                        >
                            Get Orders
                        </Button>
                    </Col>
                </Row>
            }
            body={

                sellerHubOrderResponse?.hub_wise_aggregate.length! > 0 ?
                    <>
                        <Table
                            columns={columns}
                            dataSource={addKeyToDataList(sellerHubOrderResponse?.hub_wise_aggregate!)}
                            scroll={{ x: 'fit-content' }}
                            expandIconColumnIndex={50}
                            pagination={{
                                pageSize: pageSize,
                                current: page,
                                onChange: (pageNo, size) => {
                                    setPage(pageNo);
                                    setPageSize(size);
                                },
                            }}
                            expandable={{
                                showExpandColumn: true,
                                expandedRowRender: (record: HubWiseSellerOrderAggregate) => <SellerHubOrderDetails
                                    key={record?.hub?.uuid}
                                    sellerHubUuid={sellerHubUuid}
                                    selectedSellerOrder={record}
                                    getHubWiseSellerOrderAggregate={getHubWiseSellerOrderAggregate}
                                />,
                                expandIcon: ({ expanded, onExpand, record }) => <Button
                                    className={`${style.meraaiOrangeButton}`}
                                    onClick={(event) => {
                                        onExpand(record, event);
                                    }}
                                >
                                    {expanded ? 'Hide' : 'View'}
                                </Button>,
                            }}
                        />
                    </> :
                    <DataNotFound loading={loading} title={sellerHubListResponseMessage} />

            }
        />
    );
};