import React from 'react';
import Lottie from 'react-lottie';
import { ResponseDrawerProps } from './types';
import { Button, Col, Drawer, Row } from 'antd';
import { layout, style } from '../../../../styles/css/style';
import { FAILURE_TITLE, SUCCESS_TITLE, WARNING_TITLE } from './constant';
import * as successLottie from './assets/okTickLottie.json';
import * as warningLottie from './assets/warningLottie.json';
import * as failureLottie from './assets/failureLottie.json';

export const ResponseDrawer = (props: ResponseDrawerProps) => {

    const getAnimationData = {
        'SUCCESS': {
            lottie: successLottie,
            title: SUCCESS_TITLE,
        },
        'FAILURE': {
            lottie: failureLottie,
            title: FAILURE_TITLE,
        },
        'WARNING': {
            lottie: warningLottie,
            title: WARNING_TITLE,
        },
        'INFO': undefined,
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: getAnimationData[props.type!]?.lottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <>
            <Drawer
                className='meraai-drawer'
                open={props.open}
                onClose={() => {
                    if (props.onClose) {
                        props.onClose();
                    }
                }}
                destroyOnClose
                footer={
                    props.footer
                }
            >
                <Row className={`h-full w-full ${layout.flex.center}`}>
                    <Col className='w-full'>
                        <Row className='p-6'>
                            <Col className={`w-full  ${layout.flex.center}`}>
                                <Lottie options={defaultOptions}
                                    height={150}
                                    width={'max-content'}
                                    className={`${layout.flex.center}`}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className={`w-full text-center  ${layout.flex.center}`}>
                                {props.title ?? <h2>{getAnimationData[props.type]?.title}</h2>}
                            </Col>
                            <Col className={`w-full font-semibold text-center ${layout.flex.center}`}>
                                {props.message}
                            </Col>
                            <Col className={`mt-6 w-full text-center ${layout.flex.center}`}>

                                <Button
                                    className={`m-2 w-1/2 xs:w-1/3 md:w-1/4 lg:w-1/5 ${style.meraaiOrangeButton}`}
                                    onClick={props.onClose}
                                >
                                    Continue
                                </Button>
                                {props.body}

                            </Col>

                        </Row>

                    </Col>

                </Row>
            </Drawer>
        </>
    );
};