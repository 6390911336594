import { Card } from 'antd';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { DocumentValue } from './common/MetadataUploader';

export interface DocumentRendererProps {
    documents?: DocumentValue[];
    deleteDocument?: (document: string) => void;
}

const DocumentRenderer = (props: DocumentRendererProps) => {
    const { documents, deleteDocument } = props;
    return (
        <>
            {
                documents &&
                documents.map((document) => {
                    function deleteDocumentFromList() {
                        if (deleteDocument) {
                            deleteDocument(document.value);
                        }
                    }
                    return (
                        <Card bodyStyle={{ padding: '0px' }}
                            style={{
                                height: 'fit-content',
                                flexDirection: 'row',
                                border: '1px solid #D9D9D9',
                                borderRadius: '5px',
                                marginTop: '5px',
                                marginBottom: '5px',
                                paddingLeft: '5px',
                            }}>
                            <a href={document.value} style={{ width: 'fit-content', textOverflow: 'ellipsis' }} >{document.name}</a>
                            <AiFillCloseCircle size={20} color="red" onClick={deleteDocumentFromList} />
                        </Card>
                    );
                })

            }

        </>

    );
};

export default DocumentRenderer;