import { notification } from 'antd';
import { IconType } from 'antd/lib/notification';
import { ReactNode } from 'react';

const key = 'updatable';

export const OpenNotification = (notificationTitle: ReactNode, description: ReactNode, type: IconType) => {
    notification.open({
        key,
        type,
        duration: 3,
        message: notificationTitle,
        description,
    });
};
