import { Button, Col, Divider, Drawer, Form, Input, Row, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FiUpload } from 'react-icons/fi';
import { CATEGORY_CRUD } from '../../../http/EndPoints';
import { postApiCall, putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { layout, style } from '../../../styles/css/style';
import { Type } from '../../../utilities/datumRenderer/config/type';
import { FileIdentifier, FileUploadResponse, upload } from '../../../utilities/UploadFile';
import CloseableImage from '../../common/CloseableImage';
import ImageRenderer, { ImageFile, imageValidationRule, UploaderTypes } from '../../common/ImageRenderer';
import { ResponseDrawer } from '../../common/templates/responseDrawer';
import { CategoryDetails } from '../types';
import { CategoryModeProperty, CategoryRequestBody, CategoryMode, ModifyCategoryDrawerProps, ModifyCategoryResponse } from './types';

export const ModifyCategoryDrawer = (props: ModifyCategoryDrawerProps) => {

    // State Variables
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [image, setImage] = useState<ImageFile>();
    const [selectedParentCategory, setSelectedParentCategory] = useState<CategoryDetails>();
    const [responseMessage, setResponseMessage] = useState<ModifyCategoryResponse>();

    // Methods

    const uploadImage = (imageFile: ImageFile) => {
        setLoading(true);
        const responseHandler: ResponseHandler<FileUploadResponse> = {
            onResponseSuccess(categoryImage: FileUploadResponse): void {
                setImage(categoryImage);
                setLoading(false);
                if (categoryImage.url.length > 0) {
                    form.setFieldValue('image', categoryImage.url);
                    form.setFields([
                        {
                            name: 'image',
                            errors: [],
                        },
                    ]);
                }

            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        upload('IMAGE', imageFile.file!, responseHandler, FileIdentifier.CATEGORY);

    };

    /** Create new category {{API CALL}}*/
    const createCategory = (requestBody: CategoryRequestBody) => {

        setLoading(true);
        const responseHandler: ResponseHandler<ModifyCategoryResponse> = {
            onResponseSuccess(response: ModifyCategoryResponse): void {
                setResponseMessage(response);
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };

        postApiCall(CATEGORY_CRUD, requestBody, responseHandler);
    };

    /** Update category details {{API CALL}}*/
    const updateCategory = (value: CategoryRequestBody) => {

        const requestBody = value;
        requestBody.id = props.categoryDetails?.id;

        props.setLoading(true);
        setLoading(true);
        const responseHandler: ResponseHandler<ModifyCategoryResponse> = {
            onResponseSuccess(response: ModifyCategoryResponse): void {
                setResponseMessage(response);
                props.setLoading(false);
                setLoading(false);
            },
            onResponseFailed() {
                props.setLoading(false);
                setLoading(false);
            },
            onResponseError() {
                props.setLoading(false);
                setLoading(false);
            },
        };

        putApiCall(CATEGORY_CRUD, requestBody, responseHandler);

    };


    const handleSubmit = (value) => {
        if (CategoryModeProperty[props.mode].mode === CategoryMode.CREATE) {
            createCategory(value);
        } else {
            updateCategory(value);
        }
    };

    const resetFormAndCloseDrawer = () => {
        form.resetFields();
        setImage(undefined);
        setSelectedParentCategory(undefined);
        props.onClose();
    };
    // UseEffects
    useEffect(() => {
        if (props.open) {
            if (props.mode === CategoryMode.CREATE) {
                form.setFieldValue('type', Type.PRODUCT);
            } else if (props.mode === CategoryMode.UPDATE) {
                setImage({ url: props.categoryDetails?.image_url });
                form.setFieldsValue({
                    name: props.categoryDetails?.name,
                    type: Type.PRODUCT,
                    parent_category_id: props.categoryDetails?.parent_category_id,
                    description: props.categoryDetails?.description,
                });
            }
        }

    }, [props.open]);

    useEffect(() => {
        if (props.categoryStatusUpdate !== undefined) {
            updateCategory({ active: props.categoryStatusUpdate });
        }
    }, [props.categoryStatusUpdate]);
    return (
        <>
            <Drawer
                className='meraai-drawer'
                title={CategoryModeProperty[props.mode]?.drawerTitle}
                open={props.open}
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    resetFormAndCloseDrawer();
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[12, 12]} className={`${layout.flex.end}`}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    disabled={loading}
                                    onClick={() => {
                                        resetFormAndCloseDrawer();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Button
                                    htmlType='submit'
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={loading}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <Spin spinning={loading} tip='Please wait, Your device is not a superman!'>
                    <Form
                        form={form}
                        name='userForm'
                        onFinish={handleSubmit}
                        scrollToFirstError
                        layout='vertical'
                    >


                        {/*Category Name */}
                        <Form.Item
                            label={<label className='font-semibold'>Category Name</label>}
                            name="name"
                            rules={[{ required: true, message: 'Please enter category name' }]}
                        >
                            <Input className='h-[40px]' placeholder="Category name" />
                        </Form.Item>


                        {/* Category image */}
                        <Form.Item
                            name="image"
                            label={<label className='font-semibold'>Category image</label>}
                            rules={[() => imageValidationRule(true, image!)]}
                        >
                            <Row gutter={[12, 12]}>
                                {
                                    image?.url ? <Col className={`w-full xs:w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 ${layout.flex.center} border border-dashed rounded-lg border-meraai-lightgrey`} >
                                        <CloseableImage
                                            styleClass='p-3'
                                            src={image?.url!}
                                            width={100}
                                            height={100}
                                            onClose={() => {
                                                setImage(undefined);
                                            }}
                                        />
                                    </Col> : <></>
                                }
                                <Divider type='vertical' className={'h-auto m-1'} />
                                <Col className={`w-full xs:w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 h-[125px] ${layout.flex.center} border border-dashed rounded-lg border-meraai-lightgrey`}>
                                    <ImageRenderer
                                        styleClass='p-3'
                                        size='large'
                                        cropperShape='round'
                                        uploadIcon={<FiUpload className='mr-3' />}
                                        uploadText={<label className='cursor-pointer'>{image ? 'Replace' : 'Upload'}</label>}
                                        type={UploaderTypes.UPLOAD_PNG}
                                        setFileState={(file: ImageFile) => {
                                            uploadImage(file);
                                        }}
                                    />
                                </Col>
                            </Row>


                        </Form.Item>

                        <Row gutter={[12, 12]}>
                            <Col className='w-full sm:w-1/2'>
                                {/* Type */}
                                <Form.Item
                                    name="type"
                                    label={<label className='font-semibold'>Type</label>}
                                    rules={[{ required: true, message: 'Please select type.' }]}
                                >
                                    <Select
                                        placeholder="Select type"
                                    >
                                        <Select.Option key={Type.PRODUCT} value={Type.PRODUCT} >Product</Select.Option>
                                    </Select>
                                </Form.Item>

                            </Col>


                            <Col className='w-full sm:w-1/2'>

                                {/* Parent_category */}
                                <Form.Item
                                    name="parent_category_id"
                                    label={<label className='font-semibold'>Parent category</label>}
                                >
                                    <Select
                                        allowClear
                                        placeholder="Select parent category"
                                        value={selectedParentCategory?.id}
                                        onChange={(categoryId) => {
                                            setSelectedParentCategory(props.categoryResponse?.data?.find(category => category?.id === categoryId));
                                        }}
                                    >
                                        {
                                            props.categoryResponse?.data?.map((category, index) => <Select.Option key={index} value={category.id} >{category.name}</Select.Option>)
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>


                        {/* Description */}
                        <Form.Item
                            name="description"
                            label={<label className='font-semibold'>Description</label>}
                            rules={[{ required: true, message: 'Please select description.' }]}
                        >
                            <TextArea
                                placeholder='Add the description'
                                rows={3}
                                style={{ resize: 'none' }}
                            >

                            </TextArea>
                        </Form.Item>
                    </Form>
                </Spin>

            </Drawer>


            <ResponseDrawer
                type='SUCCESS'
                open={responseMessage !== undefined}
                onClose={() => {
                    resetFormAndCloseDrawer();
                    setResponseMessage(undefined);
                    props.onSuccess();
                }}
                message={<p className='font-semibold text-center'>{responseMessage?.message}</p>}
            />
        </>
    );
};