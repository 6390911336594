import { TenderStatus } from '../../../types/Tender';

export class PaymentStatus {

    static readonly PENDING = new PaymentStatus(TenderStatus.PENDING, 'Pending', false, true);

    static readonly PAID = new PaymentStatus(TenderStatus.PAID, 'Paid', true, false);


    public constructor(
        public readonly key: TenderStatus,
        public readonly display: string,
        public readonly enableDateFilter: boolean,
        public readonly isPaymentAllowed: boolean,
    ) { }


    static valueOf(type: string): PaymentStatus | undefined {
        if (type === TenderStatus.PAID) return PaymentStatus.PAID;
        if (type === TenderStatus.PENDING) return PaymentStatus.PENDING;
    }

}
