import lodash from 'lodash';
import { InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { GET_RETURNABLE_LOG } from '../../../../../../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../../../../../../http/HttpClient';
import { getISTFromEpoch } from '../../../../../../../../utilities/Date';
import { Device, MeraaiTableColumnType } from '../../../../../../../common/templates/meraaiTable/types';
import { Currency, Quantity } from '../../../../../../../orders/layer/details/hubsAndOutlets/utils';
import { ProductInvoiceListProps, ReturnableLogDetails, ReturnableLogResponse } from '../../types';
import { useAppDispatch } from '../../../../../../../../hooks';
import { removeProductFromCart, upsertProductIntoCart } from '../../../slice';
import { layout } from '../../../../../../../../styles/css/style';
import { OptionalTitle } from '../../../../../../../common/TableTitle';
import { returnQuantityEnterPress } from '../../../Util';

export const useProductInvoiceList = (props: ProductInvoiceListProps) => {

    const dispatch = useAppDispatch();

    const { establishmentUuid, selectedPurchaseProduct, savedPurchaseProduct } = props;

    // State variables
    const [loading, setLoading] = useState<boolean>(false);

    const [returnableLogResponse, setReturnableLogResponse] = useState<ReturnableLogResponse>();
    const [productInvoiceListMap] = useState<Map<number, ReturnableLogDetails>>(new Map());

    // Methods
    const computeTotalAmount = (): number => {
        return returnableLogResponse?.data?.reduce((totalAmt: number, currentValue: ReturnableLogDetails) => {
            return totalAmt += currentValue.price_per_unit * currentValue.returnQuantity;
        }, 0) ?? 0;
    };

    const computeTotalNumberOfItems = (): number => {
        return returnableLogResponse?.data?.reduce((returnableItemCount: number, currentValue: ReturnableLogDetails) => {
            return returnableItemCount += currentValue.returnQuantity;
        }, 0) ?? 0;
    };

    const setInitialValue = (response: ReturnableLogResponse): ReturnableLogResponse => {
        if (!savedPurchaseProduct) {
            response?.data?.forEach((datum) => {
                datum.returnQuantity = 0;
            });
        } else {
            productInvoiceListMap.clear();
            savedPurchaseProduct?.items?.forEach(item => productInvoiceListMap.set(item.order_item_id, item));

            const updatedProductInvoiceList = response?.data?.map((productInvoice) => {

                if (productInvoiceListMap.has(productInvoice.order_item_id)) {
                    productInvoice.returnQuantity = productInvoiceListMap.get(productInvoice.order_item_id)?.returnQuantity ?? 0;
                } else {
                    productInvoice.returnQuantity = 0;
                }
                return productInvoice;
            });

            response.data = updatedProductInvoiceList!;

        }
        return response;
    };

    const getProductInvoiceList = () => {

        setLoading(true);

        const responseHandler: ResponseHandler<ReturnableLogResponse> = {
            onResponseSuccess(response: ReturnableLogResponse): void {
                setReturnableLogResponse(setInitialValue(response));
                setLoading(false);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        getApiCall(GET_RETURNABLE_LOG(establishmentUuid, selectedPurchaseProduct?.id!), responseHandler);
    };

    const changeReturnableQuantityForAnItem = (record: ReturnableLogDetails, returnQuantity: number) => {
        if (returnQuantity >= 0 && returnQuantity <= record.returnable_quantity) {
            record.returnQuantity = returnQuantity;
        } else if (!returnQuantity) {
            record.returnQuantity = 0;
        }

        setReturnableLogResponse(lodash.cloneDeep(returnableLogResponse));
    };

    const resetProductValueFromLocal = (productId: number) => {
        dispatch(removeProductFromCart({ establishmentUuid: props.establishmentUuid, productId: productId }));
        if (props.onSuccess) {
            props.onSuccess();
        }
    };

    const storeReturnableDetailsIntoLocal = (dataValid: boolean) => {

        if (dataValid) {

            const getReturnableItems = (): ReturnableLogDetails[] | undefined => {
                return returnableLogResponse?.data.filter(datum => datum.returnQuantity > 0);
            };

            // Save into local
            dispatch(upsertProductIntoCart({
                establishmentUuid: establishmentUuid,
                productDetailsWithItems: { ...selectedPurchaseProduct!, items: getReturnableItems()!, productTotalAmount: computeTotalAmount() },
            }));

            if (props.onSuccess) {
                props.onSuccess();
            }

        } else {
            //  Take care of this from the place where the custom hook is implemented. 
            if (props.onFailureStoreIntoLocal) {
                props.onFailureStoreIntoLocal();
            }
        }

    };

    // columns
    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Invoice No',
            dataIndex: 'invoice_no',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (invoice_no) => <label>{invoice_no}</label>,

        },
        {
            title: 'Delivery date',
            dataIndex: 'delivery_date',
            rowVisibility: [Device.DESKTOP],
            render: (delivery_date) => <label>{getISTFromEpoch(delivery_date)}</label>,

        },
        {
            title: 'Price per unit',
            dataIndex: 'price_per_unit',
            rowVisibility: [Device.DESKTOP],
            render: price_per_unit => <Currency value={price_per_unit} />,
        },
        {
            title: 'Returnable quantity',
            dataIndex: 'returnable_quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: returnable_quantity => <Quantity quantity={returnable_quantity} />,
        },
        {
            title: <OptionalTitle title='Return quantity' description='The quantity to be returned and must be less than or equal to the quantity eligible for return' />,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnableLogDetails) => {
                return (
                    <div className={`${layout.flex.spaceEvenly}`}>
                        <InputNumber
                            key={record.order_item_id}
                            controls={false}
                            value={record.returnQuantity}
                            min={0}
                            max={record.returnable_quantity}
                            onPressEnter={returnQuantityEnterPress}
                            onBlur={event => {
                                changeReturnableQuantityForAnItem(record, parseInt(event.currentTarget.value));
                                if (props.inputOnBlur) {
                                    props.inputOnBlur(event);
                                }
                            }}
                        />
                        <label className='pl-5'>{record.returnQuantity > 1 ? 'Units' : 'Unit'}</label>
                    </div>

                );
            },
        },
        {
            title: 'Total Price',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnableLogDetails) => <Currency value={record.price_per_unit * record.returnQuantity} />,
        },
    ];

    const productInvoiceSummary = {
        data: [{
            label: <label className='font-semibold'>Subtotal</label>,
            value: <Currency styleClass='pl-4' value={computeTotalAmount()} />,
        },
        {
            label: <label className='font-semibold'>No of Items</label>,
            value: <Quantity styleClass='pl-4' quantity={computeTotalNumberOfItems()} />,
        }],
        divider: true,
    };

    // UseEffects
    useEffect(() => {
        if (props.open && selectedPurchaseProduct) {
            getProductInvoiceList();
        } else {
            setReturnableLogResponse({ data: savedPurchaseProduct?.items! });
        }
    }, [selectedPurchaseProduct]);

    return {

        columns,
        returnableLogResponse,
        loading,
        storeReturnableDetailsIntoLocal,
        productInvoiceSummary,
        resetProductValueFromLocal,

    };
};