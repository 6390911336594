import { Col, Row } from 'antd';
import React from 'react';
import { HiMenu } from 'react-icons/hi';
import { MeraaiLogo } from '../../../../../images/MeraaiLogo';
import { layout, style } from '../../../../../styles/css/style';
import { MobileViewPageHeaderProps } from './types';

export const MobileViewPageHeader = (props: MobileViewPageHeaderProps) => {
    return (
        <>
            {
                props.visible ?

                    <Row className='h-[60px] w-full bg-meraai-brown'>
                        <Col className={`w-1/6 ${layout.flex.center}`}>
                            <HiMenu className='text-white text-3xl'
                                onClick={() => {
                                    props.toggleSider();
                                }} />
                        </Col>
                        <Col className={`w-5/6 ${layout.flex.start}`}>
                            <MeraaiLogo
                                width='9rem'
                                iconColor={style.colors.meraaiOrange}
                                titleColor={style.colors.white}
                            />
                        </Col>
                    </Row>

                    :

                    <></>
            }

        </>
    );
};