import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { IoSearchOutline } from 'react-icons/io5';
import { layout } from '../../styles/css/style';

export interface SearchInputProps {

    value?: string | number | readonly string[] | undefined;
    searchButtonOnClick?: (value?: any) => void;
    searchPlaceholder?: string | undefined;
    setSearchText?: React.Dispatch<React.SetStateAction<string | undefined>>;
    hideSearchButton?: boolean;
    enableAutoSearch?: boolean;
    disable?: boolean;
}

export const SearchInput = (props: SearchInputProps) => {

    if (props.enableAutoSearch) {
        useEffect(() => {

            if (props?.value) {
                const timerId = setTimeout(() => {
                    // make a request after 1 second since there's no typing 
                    if (props.searchButtonOnClick) {
                        props.searchButtonOnClick(props?.value);
                    }
                }, 1000);

                return () => {
                    clearTimeout(timerId);
                };
            }
        }, [props?.value]);
    }


    return (
        <Form className={`${layout.flex.center} w-full`}>
            <Form.Item className='m-0 w-full'>
                <Input
                    autoFocus
                    allowClear
                    disabled={props.disable}
                    suffix={
                        !props.hideSearchButton && <Button
                            type='link'
                            className={`${layout.flex.center}`}
                            icon={<IoSearchOutline />}
                            htmlType='submit'
                            onClick={() => {
                                if (props.searchButtonOnClick) {
                                    props.searchButtonOnClick(props?.value);
                                }
                            }}
                        />
                    }
                    value={props?.value}
                    placeholder={props.searchPlaceholder}
                    className={'w-full py-[3px] px-[10px]'}
                    size="large"
                    onChange={(event) => {
                        if (props.setSearchText) {
                            if (event.target.value.length === 0 && props.searchButtonOnClick) {
                                props.searchButtonOnClick(event.target.value);
                            }
                            props.setSearchText(event.target.value);
                        }
                    }}
                />
            </Form.Item>
        </Form>
    );
};