import React, { useEffect, useState } from 'react';
import { getDatumConfig } from './config';
import { ValueComponentProps } from './config/type';
import { DatumRendererProps, RendererType } from './type';

export const DatumRenderer = <T extends unknown>(props: DatumRendererProps<T>) => {

    const [blur, setBlur] = useState<boolean>(false);
    const [rulePassed, setRulePassed] = useState<boolean | undefined>(undefined);

    // This useEffect triggers the rules when a user clicks on confirm button.
    useEffect(() => {
        if (props.triggerRule === true && props.ruleValidator) {
            setRulePassed(props.ruleValidator());
        } else {
            setRulePassed(undefined);
        }
    }, [props.triggerRule]);

    // This useEffect triggers the rules when a user loses focus from the particular field component.
    useEffect(() => {

        if (blur && props.ruleValidator) {
            setRulePassed(props.ruleValidator());
        }
        setBlur(false);

    }, [blur]);

    return (
        <>
            {
                props.rendererType === RendererType.component ?

                    getDatumConfig(props.type).valueComponent!(
                        {
                            value: props.datumValue,
                            record: props.record,
                            onBlur: props.onBlur,
                            onChange: props.onChange,
                            setBlur: setBlur,
                            rulePassed: rulePassed,
                            placeholder: props.placeholder,
                        } as ValueComponentProps,
                        props.config,
                    )

                    :

                    props.datumValue ? getDatumConfig(props.type).valueRenderer!(props.datumValue) : getDatumConfig(props.type).valueComponent!(
                        {
                            value: props.datumValue,
                            record: props.record,
                            onBlur: props.onBlur,
                            onChange: props.onChange,
                            setBlur: setBlur,
                            rulePassed: rulePassed,
                            placeholder: props.placeholder,
                        } as ValueComponentProps,
                        props.config,

                    )
            }
        </>
    );
};