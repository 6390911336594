import React, { useEffect, useState } from 'react';
import { GET_ORDER_PAYMENT_COLLECTION_DATA } from '../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import CollectionClearanceModal, { CollectionModalMode } from '../../payments/collections/CollectionClearanceModal';
import { CollectionSourceType } from '../../finance/CollectionSource';
import { OrderPaymentCollectionProps, OrderPaymentCollectionResponse } from './types';
import { OrderStates } from './utils';

export const OrderPaymentCollection = (props: OrderPaymentCollectionProps) => {
    const [orderPaymentCollectionDetails, setOrderPaymentCollectionDetails] = useState<OrderPaymentCollectionResponse>();
    const [tenderModalVisibility, setTenderModalVisibility] = useState<boolean>(false);


    const getOrderPaymentCollectionData = () => {

        const responseHandler: ResponseHandler<OrderPaymentCollectionResponse> = {
            onResponseSuccess(response: OrderPaymentCollectionResponse): void {
                setOrderPaymentCollectionDetails(response);
                setTenderModalVisibility(true);
            },
        };

        getApiCall(GET_ORDER_PAYMENT_COLLECTION_DATA(props.orderUuid!), responseHandler);
    };


    useEffect(() => {
        if (props.orderDetails?.state === OrderStates.PAYMENT_COLLECTION_INITIATED) {
            getOrderPaymentCollectionData();
        }
    }, [props?.orderDetails?.state]);

    return (
        <>
            {
                props.orderDetails?.state === OrderStates.PAYMENT_COLLECTION_INITIATED &&
                <a
                    className='underline text-meraai-brown hover:text-meraai-brown'
                    onClick={() => { setTenderModalVisibility(true); }}
                >
                    Collect Payment
                </a>
            }

            {
                orderPaymentCollectionDetails && <CollectionClearanceModal
                    setLoading={props.setLoading}
                    orderUuid={props.orderUuid}
                    clearanceModalVisible={tenderModalVisibility}
                    setClearanceModalVisible={setTenderModalVisibility}
                    clearanceAmounts={{
                        defaultValue: orderPaymentCollectionDetails?.amount,
                        totalOutstanding: orderPaymentCollectionDetails?.amount,
                        overdue: orderPaymentCollectionDetails?.amount,
                    }}
                    mode={CollectionModalMode.PENDING_COLLECTION}
                    tenders={orderPaymentCollectionDetails?.tenders}
                    collectionSource={CollectionSourceType.ORDER}
                    refreshPage={() => {
                        props.getOrderDetails();
                    }}
                />
            }
        </>
    );
};
