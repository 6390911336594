import { Button, Card, Col, Collapse, Drawer, InputNumber, message, Modal, Row, Skeleton, Spin, Table } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_RETURN_ORDER_DETAILS, GET_RETURN_ORDER_REFUNDS } from '../../../http/EndPoints';
import { getApiCall, putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { layout, style } from '../../../styles/css/style';
import { addKeyToDataList, getContactInfo } from '../../../Utils';
import { DetailsCard } from '../../common/detailsCard';
import { DetailsCardProps } from '../../common/detailsCard/types';
import { PageBreadcrumbHeader } from '../../common/pageBreadcrumbHeader';
import { DetailsPage } from '../../common/templates/detailsPage';
import { MeraaiTable } from '../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../common/templates/meraaiTable/types';
import { StateMetadataRendererV2 } from '../../common/templates/stateMetadataRenderer';
import { Currency, ProductNameInfo, Quantity } from '../../orders/layer/details/hubsAndOutlets/utils';
import { ReturnOrderApprovalRequest, ReturnOrderChangeData, ReturnOrderDataDocument, ReturnOrderDetailsResponse, ReturnOrderItem, ReturnOrderItemChangeData, ReturnOrderItemsForProduct, ReturnOrderRefundData, ReturnOrderRefundDataResponse } from './types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ExpandableConfig } from 'antd/lib/table/interface';
import lodash from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import { ResponseDrawer } from '../../common/templates/responseDrawer';
import { OptionalTitle } from '../../common/TableTitle';

export const ReturnOrderDetails = () => {
    const navigate = useNavigate();
    const { returnOrderUuid } = useParams();
    const [loading, setLoading] = useState<boolean>();
    const [returnOrderDetails, setReturnOrderDetails] = useState<ReturnOrderDetailsResponse>();
    const [returnOrderDetailsInitialMap, setReturnOrderDetailsInitialMap] = useState<Map<number, ReturnOrderItem>>();
    const [returnOrderItemsUpdateDrawerVisibility, setReturnOrderItemsUpdateDrawerVisibility] = useState<boolean>();
    const [returnOrderExtrasDrawerVisibility, setReturnOrderExtrasDrawerVisibility] = useState<{ visibility: boolean, rejection: boolean }>();
    const [returnOrderStateUpdateData, setReturnOrderStateUpdateData] = useState<Map<number, ReturnOrderItem>>();
    const [productIdToInwardsQuantityMap, setProductIdToInwardsQuantityMap] = useState<Map<number, number>>(new Map<number, number>());
    const [selectedProductId, setSelectedProductId] = useState<number>();
    const [selectedProductIdForDrawer, setSelectedProductIdForDrawer] = useState<number>();
    const [returnOrdernExtrasData, setReturnOrdernExtrasData] = useState<ReturnOrderDataDocument>();
    const [returnOrderRefundData, setReturnOrderRefundData] = useState<ReturnOrderRefundData[]>();
    const [returnOrderRefundDataLoading, setReturnOrderRefundDataLoading] = useState<boolean>();
    const [responseDrawerData, setResponseDrawerData] = useState<{ visibility: boolean, type: 'SUCCESS' | 'FAILURE' | 'WARNING' | 'INFO', message: string }>();

    const geReturntOrderDetailsResponseHandler = (response) => {
        Modal.error({
            title: 'Oops!',
            content: response[0],
            okText: 'Go back',
            onOk: () => {
                navigate(-1);
            },
        });
    };

    const getMapForReturnOrderItems = (data: ReturnOrderDetailsResponse): Map<number, ReturnOrderItem> => {
        const map = new Map<number, ReturnOrderItem>();
        data.items.forEach(item => {
            map.set(item.product_id, item);
        });
        return map;
    };

    const getReturnOrderDetails = async () => {
        setLoading(true);

        const returnOrderDetailsEndPoint = GET_RETURN_ORDER_DETAILS(returnOrderUuid!);
        const responseHandler: ResponseHandler<ReturnOrderDetailsResponse> = {
            onResponseSuccess(response: ReturnOrderDetailsResponse): void {
                // Storing the whole object in orderDetails state
                setReturnOrderDetails(response);
                setReturnOrderDetailsInitialMap(getMapForReturnOrderItems(lodash.cloneDeep(response)));
                setReturnOrderStateUpdateData(getMapForReturnOrderItems(lodash.cloneDeep(response)));
                // Disabled screen loader
                setLoading(false);
            },
            onResponseFailed(errors: string[]): void {
                geReturntOrderDetailsResponseHandler(errors);
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };
        await getApiCall(returnOrderDetailsEndPoint, responseHandler);
    };

    const getReturnOrderRefundDetails = async () => {
        setReturnOrderRefundDataLoading(true);

        const returnOrderRefundDetailsEndPoint = GET_RETURN_ORDER_REFUNDS(returnOrderUuid!);
        const responseHandler: ResponseHandler<ReturnOrderRefundDataResponse> = {
            onResponseSuccess(response: ReturnOrderRefundDataResponse): void {
                setReturnOrderRefundData(response.data);
                setReturnOrderRefundDataLoading(false);
            },
            onResponseFailed(): void {
                setReturnOrderRefundDataLoading(false);
            },
            onResponseError(): void {
                setReturnOrderRefundDataLoading(false);
            },
        };
        await getApiCall(returnOrderRefundDetailsEndPoint, responseHandler);
    };

    const getReceivedQuantity = (record: ReturnOrderItem): number => {
        return record.order_items.reduce((quantity, item) => quantity + item.quantity, 0);
    };

    const getReturnOrderApprovalItems = (): ReturnOrderChangeData[] => {
        return Array.from(returnOrderStateUpdateData?.values() ?? []).map(item => {
            return {
                id: item.id,
                quantity: getReceivedQuantity(item),
                inwards_quantity: productIdToInwardsQuantityMap.get(item.product_id) ?? 0,
                order_items: item.order_items.map(orderItem => {
                    return {
                        order_item_id: orderItem.order_item_id,
                        quantity: orderItem.quantity,
                    } as ReturnOrderItemChangeData;
                }),
            } as ReturnOrderChangeData;
        });
    };

    const sendOrderApprovalRequest = async (accept: boolean) => {
        setLoading(true);
        const returnOrderDetailsEndPoint = GET_RETURN_ORDER_DETAILS(returnOrderUuid!);
        const request = {
            state_request: {
                state: accept ? 'ACCEPTED' : 'REJECTED',
                data: returnOrdernExtrasData ? [returnOrdernExtrasData] : [],
                items: getReturnOrderApprovalItems() ?? [],
            },
        } as ReturnOrderApprovalRequest;
        const responseHandler: ResponseHandler<ReturnOrderDetailsResponse> = {
            onResponseSuccess(response: ReturnOrderDetailsResponse): void {
                setReturnOrderDetails(response);
                setLoading(false);
                setResponseDrawerData({ visibility: true, type: 'SUCCESS', message: response.message ?? 'Return order has been accepted and payment has been adjusted' });
            },
            onResponseFailed(errors: string[]): void {
                setLoading(false);
                setResponseDrawerData({ visibility: true, type: 'FAILURE', message: errors[0] });
            },
            onResponseError(): void {
                setLoading(false);
                setResponseDrawerData({ visibility: true, type: 'FAILURE', message: 'Something went wrong!' });
            },
        };
        await putApiCall(returnOrderDetailsEndPoint, request, responseHandler);
    };

    const returnOrderItemColumns: MeraaiTableColumnType[] = [
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItem) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'Return Quantity',
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (quantity, record: ReturnOrderItem) =>
                <>
                    {
                        (record.inwards_quantity || record.placed_quantity) ?
                            <OptionalTitle title={<Quantity quantity={quantity} />} description={
                                <>
                                    {record.placed_quantity != undefined &&
                                        <>
                                            <label>{`Placed: ${record.placed_quantity ?? 0}`}</label>
                                            <br />
                                        </>}

                                    {record.inwards_quantity != undefined &&
                                        <label>{`Inward: ${record.inwards_quantity ?? 0}`}</label>}
                                </>
                            } /> : <Quantity quantity={quantity} />
                    }

                </>

            ,
        },
        {
            title: 'Amount',
            dataIndex: 'total_amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: amount => <Currency value={amount} />,
        },
        Table.EXPAND_COLUMN,
    ];

    const EstablishmentDetails = (): JSX.Element => {
        const detailsCardData: DetailsCardProps[] = [
            {
                title: 'Hub details',
                data: [{
                    label: 'Name',
                    value: returnOrderDetails?.source_establishment.name,
                },
                {
                    label: 'Address',
                    value: returnOrderDetails?.source_establishment.address,
                }],
                divider: true,
            },
            {
                title: 'Outlet details',
                data: [{
                    label: 'Name',
                    value: returnOrderDetails?.sink_establishment.name,
                },
                {
                    label: 'Contact',
                    value: getContactInfo(returnOrderDetails?.sink_establishment),
                },
                {
                    label: 'Address',
                    value: returnOrderDetails?.sink_establishment.address,
                }],
                divider: false,
            },
        ];

        return <>
            {
                detailsCardData.map((datum, index) => <DetailsCard
                    key={index}
                    title={
                        <label className='text-xl font-semibold'>
                            {datum.title}
                        </label>
                    }
                    data={
                        datum?.data?.map((cardDatum) => {
                            return {
                                label: <label className='text-meraai-grey'>{cardDatum.label}</label>,

                                value: <label className='font-semibold'>
                                    {cardDatum.value}
                                </label>,
                            };
                        })
                    }
                    divider={datum.divider}
                />)
            }
        </>;
    };

    const updateOrderItemQuantityForAProduct = (orderItemId: number, itemQuantity: number) => {
        if (returnOrderStateUpdateData) {
            const selectedProduct = returnOrderStateUpdateData.get(selectedProductIdForDrawer!);
            const returnableQuantity = selectedProduct?.quantity ?? 0;
            const sumOFOtherProductItems = selectedProduct!.order_items.reduce(({ quantity, totalAmount }, item) => {
                if (item.order_item_id !== orderItemId) {
                    return { quantity: quantity + item.quantity, totalAmount: totalAmount + (item.price_per_unit * item.quantity) };
                }
                return { quantity: quantity, totalAmount: totalAmount };
            }, { quantity: 0, totalAmount: 0 });
            if (sumOFOtherProductItems.quantity + itemQuantity <= returnableQuantity) {
                const selectedOrderItemForProduct = selectedProduct!.order_items.find(item => item.order_item_id === orderItemId);
                selectedProduct!.total_amount = sumOFOtherProductItems!.totalAmount + (itemQuantity * selectedOrderItemForProduct!.price_per_unit);
                selectedOrderItemForProduct!.quantity = itemQuantity;
            } else {
                message.error('The items cannot exceed the returnable quantity');
            }
            setReturnOrderStateUpdateData(lodash.cloneDeep(returnOrderStateUpdateData));
        }
    };

    const isQuantityChangedForReturnOrder = (): boolean => {

        let result: boolean = false;

        if (returnOrderDetailsInitialMap && returnOrderStateUpdateData) {
            Array.from(returnOrderDetailsInitialMap ? returnOrderDetailsInitialMap.values() : []).forEach(item => {
                const updatedProductItem = returnOrderStateUpdateData?.get(item.product_id);
                if (getReceivedQuantity(updatedProductItem!) !== item.quantity) {
                    result = true;
                    return;
                }
            });
        }
        return result;
    };

    const isInwardsQuantityCorrect = (): boolean => {

        let result: boolean = true;

        if (productIdToInwardsQuantityMap) {
            Array.from(productIdToInwardsQuantityMap.keys()).forEach(productId => {
                if (productIdToInwardsQuantityMap!.get(productId)! > getReceivedQuantity(returnOrderStateUpdateData?.get(productId)!)) {
                    result = false;
                    return;
                }
            });
        }
        return result;
    };

    const approveReturnOrder = () => {
        if (!isInwardsQuantityCorrect()) {
            message.error('Please check the inwards quantity for the products');
        } else if (isQuantityChangedForReturnOrder()) {
            setReturnOrderExtrasDrawerVisibility({ visibility: true, rejection: false });
        } else {
            sendOrderApprovalRequest(true);
        }
    };

    const rejectReturnOrder = () => [
        setReturnOrderExtrasDrawerVisibility({ visibility: true, rejection: true }),
    ];

    const getInwardsQuantityForAProduct = (record: ReturnOrderItem): number => {
        return productIdToInwardsQuantityMap?.get(record.product_id) ?? 0;
    };

    const setInwardsQuantityForAProduct = (productId: number, quantity: number) => {
        if (productIdToInwardsQuantityMap) {

            productIdToInwardsQuantityMap.set(productId, quantity);
            setProductIdToInwardsQuantityMap(lodash.cloneDeep(productIdToInwardsQuantityMap));
        }
    };

    const getAmountForOrderItem = (record: ReturnOrderItemsForProduct): number => {
        return record.price_per_unit * record.quantity;
    };

    const returnOrderItemUpdateColumns: MeraaiTableColumnType[] = [
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItem) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'Return Quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'quantity',
            render: (quantity) => <Quantity quantity={quantity} />,
        },

        {
            title: 'Received Quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItem) => <InputNumber
                controls={false}
                value={getReceivedQuantity(record)}
                disabled
                min={0}
                max={record.quantity}
                placeholder="Item count" />,
        },
        {
            title: <OptionalTitle title='Inwards Quantity' description='This quantity will be added back to the hub inventory.' />,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItem) => <InputNumber
                controls={false}
                value={getInwardsQuantityForAProduct(record)}
                min={0}
                max={getReceivedQuantity(record)}
                placeholder="Item count"
                onBlur={event => {
                    const value = parseInt(event.target.value);
                    const quantity = Number.isNaN(value) ? 0 : value;
                    setInwardsQuantityForAProduct(record.product_id, quantity < 0 ? 0 : quantity);
                }} />,
        },

        {
            title: 'Amount',
            dataIndex: 'total_amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: amount => <Currency value={amount} />,
        },
        Table.EXPAND_COLUMN,
    ];

    const getReturnLogForAProductColumns = (): MeraaiTableColumnType[] => {
        return [{
            title: 'Invoice No',
            dataIndex: 'invoice_no',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
        },
        {
            title: 'Price',
            dataIndex: 'price_per_unit',
            rowVisibility: [Device.DESKTOP],
            render: amount => <Currency value={amount} />,
        },

        {
            title: 'Returnable Quantity',
            dataIndex: 'returnable_quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: quantity => <Quantity quantity={quantity} />,
        },
        {
            title: 'Returned Quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItemsForProduct) => <InputNumber
                controls={false}
                value={record.quantity}
                min={0}
                max={record.returnable_quantity}
                placeholder="Item count"
                onBlur={event => {
                    const value = parseInt(event.target.value);
                    const quantity = Number.isNaN(value) ? 0 : value;
                    updateOrderItemQuantityForAProduct(record.order_item_id, quantity < 0 ? 0 : quantity);
                }} />,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItemsForProduct) => <Currency value={getAmountForOrderItem(record)} />,
        },
        ];
    };

    const getDisplayReturnLogForAProductColumns = (): MeraaiTableColumnType[] => {
        return [{
            title: 'Invoice No',
            dataIndex: 'invoice_no',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
        },
        {
            title: 'Price',
            dataIndex: 'price_per_unit',
            rowVisibility: [Device.DESKTOP],
            render: amount => <Currency value={amount} />,
        },
        {
            title: 'Quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'quantity',
            render: (quantity) => <Quantity quantity={quantity} />,
        },
        {
            title: 'Amount',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record: ReturnOrderItemsForProduct) => <Currency value={record.price_per_unit * record.quantity} />,
        },
        ];
    };

    const getReturnableItemsOfAProduct = (record: ReturnOrderItem): ReturnOrderItemsForProduct[] => {
        return returnOrderStateUpdateData?.get(record.product_id)?.order_items ?? [];
    };

    const getReturnedItemsOfAProduct = (record: ReturnOrderItem): ReturnOrderItemsForProduct[] => {
        return returnOrderDetailsInitialMap?.get(record.product_id)?.order_items ?? [];
    };

    const getTableOfProductsForReturns = (editable: boolean): ExpandableConfig<any> => {
        return {
            columnWidth: 1,
            onExpand(expanded, record: ReturnOrderItem) {
                if (expanded) {
                    if (editable) {
                        setSelectedProductIdForDrawer(record.product_id);
                    } else {
                        setSelectedProductId(record.product_id);
                    }

                } else {
                    if (editable) {
                        setSelectedProductIdForDrawer(undefined);
                    } else {
                        setSelectedProductId(undefined);
                    }
                }
            },
            expandedRowKeys: [(editable ? selectedProductIdForDrawer : selectedProductId) ?? ''],

            expandedRowRender: (record: ReturnOrderItem) => {

                return <>
                    <Card className='border border-meraai-lightgrey'>
                        <MeraaiTable
                            columns={editable ? getReturnLogForAProductColumns() : getDisplayReturnLogForAProductColumns()}
                            dataSource={editable ? getReturnableItemsOfAProduct(record) : getReturnedItemsOfAProduct(record)}
                            scroll={{ x: 'fit-content' }}
                            pagination={false}
                        />
                    </Card>

                </>;
            },

            expandIcon: ({ expanded, onExpand, record }) => expanded ?
                <UpOutlined
                    className='text-meraai-orange'
                    onClick={(event) => {
                        onExpand(record, event);
                    }}
                />

                :

                <DownOutlined
                    className='text-meraai-orange'
                    onClick={(event) => {
                        onExpand(record, event);
                    }}
                />,
        };
    };

    const getViewForRefundStatus = (data: ReturnOrderRefundData): JSX.Element => {

        return (
            <>

                <Row className={`p-3 w-full ${layout.flex.spaceBetween}`}>
                    <Col> <label className='w-1/2 text-meraai-grey font-semibold'>Status</label> </Col>
                    <Col> <label className={`w-1/2 ${data.state === 'ACCEPTED' ? 'text-green-700' : 'text-meraai-orange'}`}>{data.state}</label> </Col>

                </Row>
                {
                    data.refunds && data.refunds.length > 0 &&
                    <Card bodyStyle={{ padding: 0 }} className={'w-full border border-meraai-lightgrey'}>
                        {
                            data.refunds.map(item => {
                                return <DetailsCard
                                    data={[
                                        {
                                            label: <label className='text-meraai-grey font-semibold'>Payment Method</label>,
                                            value: item.method,
                                        },
                                        {
                                            label: <label className='text-meraai-grey font-semibold'>Amount</label>,
                                            value: <Currency value={item.amount} />,
                                        },
                                    ]}

                                />;
                            })
                        }

                    </Card>
                }
            </>
        );
    };

    const checkAndGetRefundData = () => {
        if (!returnOrderRefundData) {
            getReturnOrderRefundDetails();
        }
    };

    useEffect(() => {
        if (returnOrderUuid) {
            getReturnOrderDetails();
        }
    }, [returnOrderUuid]);

    const getTotalRefundableAmount = (): number => {
        return Array.from(returnOrderStateUpdateData?.values() ?? []).reduce((amount, item) => amount + item.total_amount, 0);
    };

    return (
        <>
            <DetailsPage
                loading={loading}
                header={

                    returnOrderDetails ?

                        <Row gutter={[0, 24]}>
                            <Col className={'w-full'}>
                                <div className={`${layout.flex.start}`}>
                                    <PageBreadcrumbHeader items={[`Return Order / ${returnOrderDetails.sink_establishment.name}`]} />
                                </div>
                            </Col>
                        </Row>

                        : <Skeleton active paragraph={{ rows: 1 }} />

                }

                body={
                    {
                        left:
                            returnOrderDetails ? <>
                                <Card title='Items summary'>
                                    <div className='max-h-[80vh] overflow-auto'>
                                        <MeraaiTable
                                            columns={returnOrderItemColumns}
                                            dataSource={addKeyToDataList(returnOrderDetails.items)}
                                            pagination={false}
                                            rowKey={(record: ReturnOrderItem) => record.product_id}
                                            expandable={getTableOfProductsForReturns(false)}
                                        />
                                    </div>
                                </Card>

                                {
                                    returnOrderDetails && returnOrderDetails.state === 'ACCEPTED' &&
                                    <Card>
                                        <Collapse expandIconPosition='end' onChange={(key: string[]) => { if (key.length > 0 && key[0] === 'refund') { checkAndGetRefundData(); } }}>
                                            <Collapse.Panel key='refund' className='font-semibold' header='View Refund Status'>
                                                {
                                                    returnOrderRefundDataLoading ? <Skeleton loading={returnOrderRefundDataLoading} ></Skeleton> : returnOrderRefundData?.map(data => {
                                                        return getViewForRefundStatus(data);
                                                    })
                                                }
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Card>
                                }


                                <Card title='Order tracking history'>
                                    <StateMetadataRendererV2 stateMetadata={returnOrderDetails.state_metadata} />
                                </Card>
                            </>
                                :
                                <Skeleton active paragraph={{ rows: 10 }} />
                        ,

                        right:
                            returnOrderDetails ? <>
                                <EstablishmentDetails />
                            </> :
                                <Skeleton className='p-10' active paragraph={{ rows: 10 }} />,
                    }
                }
                footer={
                    <>
                        <Row className={`w-full ${layout.flex.end}`}>
                            <Col className={`p-3 w-full xs:w-1/2 md:w-1/3 lg:w-1/6 ${layout.flex.end}`}>
                                <Button
                                    className={`text-xs font-semibold xs:font-normal xs:text-base ${style.meraaiOrangeButton} ${layout.flex.center}`}
                                    onClick={() => {
                                        setReturnOrderItemsUpdateDrawerVisibility(true);
                                    }}>
                                    <label className={`cursor-pointer whitespace-pre-line ${layout.flex.center} `}>
                                        Accept
                                    </label>
                                </Button>
                            </Col>
                            <Col className={`p-3 w-full xs:w-1/2 md:w-1/3 lg:w-1/6 ${layout.flex.end}`}>
                                <Button
                                    className={`text-xs font-semibold xs:font-normal xs:text-base ${style.meraaiDangerButton} ${layout.flex.center}`}
                                    onClick={() => {
                                        rejectReturnOrder();
                                    }}>
                                    <label className={`cursor-pointer whitespace-pre-line ${layout.flex.center} `}>
                                        Reject
                                    </label>
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                footerVisibility={(returnOrderDetails?.valid_next_states?.length ?? 0) > 0}
            />

            <Drawer
                className='meraai-drawer'
                open={returnOrderItemsUpdateDrawerVisibility}
                title={
                    <>
                        <h3>Approve return</h3>
                    </>
                }
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    setReturnOrderItemsUpdateDrawerVisibility(false);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[24, 24]} className={`pb-3 pt-1 ${layout.flex.end} `}>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.start}`}>
                                <span className="item-total-text">Total refund amount :</span>
                            </Col>
                            <Col className={`w-1/2 sm:w-1/3 lg:w-1/4 ${layout.flex.end}`}>
                                <Currency value={getTotalRefundableAmount()} />
                            </Col>
                        </Row>

                        <Row gutter={[24, 24]} className={`${layout.flex.end}`}>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={() => {
                                        setReturnOrderItemsUpdateDrawerVisibility(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    onClick={() => {
                                        approveReturnOrder();
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>


                }
            >
                <Spin spinning={loading}>

                    <div className='return-order-details'>
                        <MeraaiTable
                            columns={returnOrderItemUpdateColumns}
                            dataSource={returnOrderStateUpdateData ? Array.from(returnOrderStateUpdateData.values()) : []}
                            pagination={false}
                            size='small'
                            rowKey={(record: ReturnOrderItem) => record.product_id}
                            expandable={getTableOfProductsForReturns(true)}
                        />
                    </div>

                </Spin>
            </Drawer>

            <Drawer
                className='meraai-drawer'
                open={returnOrderExtrasDrawerVisibility?.visibility}
                title={
                    <>
                        {
                            returnOrderExtrasDrawerVisibility?.rejection ? <h3>Reject return order</h3> : <h3>Accept return</h3>
                        }
                    </>
                }
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    setReturnOrderItemsUpdateDrawerVisibility(false);
                    setReturnOrderExtrasDrawerVisibility({ ...returnOrderExtrasDrawerVisibility!, visibility: false });
                }} />}
                closable={false}


                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[24, 24]} className={`${layout.flex.end}`}>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={() => {
                                        setReturnOrderExtrasDrawerVisibility({ ...returnOrderExtrasDrawerVisibility!, visibility: false });
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    disabled={!returnOrdernExtrasData?.value}
                                    onClick={() => {
                                        setReturnOrderItemsUpdateDrawerVisibility(false);
                                        setReturnOrderExtrasDrawerVisibility({ ...returnOrderExtrasDrawerVisibility!, visibility: false });
                                        sendOrderApprovalRequest(!returnOrderExtrasDrawerVisibility!.rejection);
                                    }}
                                >
                                    {returnOrderExtrasDrawerVisibility?.rejection ? 'Reject' : 'Proceed'}
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <>
                    <Row className='pb-3'> <label> {returnOrderExtrasDrawerVisibility?.rejection ? 'Why reject the return order?' : 'Why did you update the return order?'} </label> </Row>
                    <Row>
                        <TextArea
                            value={returnOrdernExtrasData?.value}
                            placeholder={returnOrderExtrasDrawerVisibility?.rejection ? 'Why reject the return order?' : 'Why did you update the return order?'}
                            rows={8}
                            style={{ resize: 'none' }}
                            onChange={(event) => {
                                setReturnOrdernExtrasData(
                                    {
                                        name: 'remark',
                                        display_name: 'Remark',
                                        type: 'STRING',
                                        function: 'DISPLAY',
                                        value: event.currentTarget.value,
                                    },
                                );
                            }}
                        />
                    </Row>
                </>
            </Drawer>

            <ResponseDrawer
                type={responseDrawerData?.type ?? 'SUCCESS'}
                message={responseDrawerData?.message}
                open={responseDrawerData?.visibility ?? false}
                onClose={() => {
                    setReturnOrderItemsUpdateDrawerVisibility(false);
                    setResponseDrawerData({ ...responseDrawerData!, visibility: false });
                }}
            />

        </>
    );
};