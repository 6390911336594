import { Badge, Button, Card, Col, Row, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { InfoCircleOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { ElementPropertyKey, FieldProps as ProductFieldProps, FieldRecord } from './type';
import { v4 as uuidv4 } from 'uuid';
import { DatumRenderer } from '../../../../../../../../utilities/datumRenderer';
import { OptionType, RendererType } from '../../../../../../../../utilities/datumRenderer/type';
import { Type } from '../../../../../../../../utilities/datumRenderer/config/type';
import { FieldElement } from '../../../../../../../orders/listing/ListingData';

const cardStyle = {
    border: '1px solid #D9D9D9',
    borderRadius: '10px',
    width: '100%', height: 'max-content',
    margin: '20px 0px',
};

export const ProductField = (props: ProductFieldProps) => {

    const [latestElement, setLatestElement] = useState<FieldElement>();
    const ref = useRef<any>(null);

    useEffect(() => {
        latestElement?.ref?.current.focus();
    }, [latestElement]);

    const InnerCard = ({ field, productFields, setProductFields, triggerRule }: ProductFieldProps) => {

        const updateValue = (value, record: FieldRecord) => {
            // Updating the productFields state.
            record.element[record.propertyKey] = value;
        };

        return <Card style={cardStyle}>
            <>
                {
                    !field.mandatory && productFields.length > 1 && <Row>
                        <Col xs={24} className='flex-row-right-align'>
                            <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                const filteredFields = productFields?.filter(productField => productField.id !== field.id);
                                setProductFields([...filteredFields]);
                            }} />
                        </Col>
                    </Row>
                }

                <Row className='flex-center'>
                    {
                        field.visibility.map((eachValue, index) => <Col key={index}>
                            <Tag color="#e29a3d">{eachValue}</Tag>
                        </Col>)
                    }
                </Row>
                <Row>
                    <Col xs={24}>
                        {
                            field?.elements?.map((element) => {
                                // One element is one Inner card 
                                return <Card key={element.id} style={cardStyle}>

                                    {/* This row includes: key, prefix (if any), value, suffix (if any) */}
                                    <Row gutter={[24, 24]}>

                                        {/* START : Key */}
                                        <Col xs={6}>
                                            <div className='flex-row-left-align'>
                                                <label>
                                                    <DatumRenderer
                                                        triggerRule={triggerRule}
                                                        ruleValidator={() => {
                                                            if (field.mandatory || element.deletable) {
                                                                if (element.key) {
                                                                    element.valid_key = true;
                                                                    return true;
                                                                } else {
                                                                    element.valid_key = false;
                                                                    return false;
                                                                }
                                                            } else {
                                                                element.valid_key = true;
                                                                return true;
                                                            }
                                                        }}
                                                        key={element.id}
                                                        type={element.value_type}
                                                        datumValue={element.key}
                                                        onBlur={updateValue}
                                                        record={
                                                            {
                                                                element: element,
                                                                propertyKey: ElementPropertyKey.KEY,
                                                            } as FieldRecord
                                                        }
                                                        config={element.deletable ? { ref: element.ref } : {}}
                                                        rendererType={
                                                            element.deletable ? RendererType.component : element.key ? RendererType.value : RendererType.component}
                                                    />
                                                </label> &ensp;
                                                {
                                                    element.description ? <Tooltip title={element.description}>
                                                        <InfoCircleOutlined style={{ cursor: 'help' }} />
                                                    </Tooltip> : <></>
                                                }
                                            </div>
                                        </Col>
                                        {/* END : Key */}


                                        {/* START : Prefix */}
                                        <Col xs={4}>
                                            {
                                                element.value_prefix_options ?
                                                    <DatumRenderer
                                                        triggerRule={triggerRule}
                                                        ruleValidator={() => {
                                                            if (field.mandatory || element.deletable) {
                                                                if (element.value_prefix) {
                                                                    element.valid_prefix = true;
                                                                    return true;
                                                                } else {
                                                                    element.valid_prefix = false;
                                                                    return false;
                                                                }
                                                            } else {
                                                                element.valid_prefix = true;
                                                                return true;
                                                            }
                                                        }}
                                                        key={element.id}
                                                        type={Type.OPTIONS}
                                                        datumValue={element.value_prefix}
                                                        record={
                                                            {
                                                                element: element,
                                                                propertyKey: ElementPropertyKey.VALUE_PREFIX,
                                                            } as FieldRecord
                                                        }
                                                        config={{ options: element.value_prefix_options } as OptionType}
                                                        onChange={updateValue}
                                                        rendererType={RendererType.component}
                                                    /> : <></>
                                            }
                                        </Col>
                                        {/* END : Prefix */}


                                        {/* START : Value */}
                                        <Col xs={10}>
                                            <DatumRenderer
                                                triggerRule={triggerRule}
                                                ruleValidator={() => {
                                                    if (field.mandatory || element.deletable) {
                                                        if (element.value) {
                                                            element.valid_value = true;
                                                            return true;
                                                        } else {
                                                            element.valid_value = false;
                                                            return false;
                                                        }
                                                    } else {
                                                        element.valid_value = true;
                                                        return true;
                                                    }
                                                }}
                                                key={element.id}
                                                type={element.value_type}
                                                datumValue={element.value}
                                                record={
                                                    {
                                                        element: element,
                                                        propertyKey: ElementPropertyKey.VALUE,
                                                    } as FieldRecord
                                                }
                                                config=
                                                    {
                                                        element.value_type === Type.OPTIONS
                                                            ? { options: element.value_options } as OptionType : {}
                                                    }
                                                onBlur={updateValue}
                                                rendererType={RendererType.component}
                                            />
                                        </Col>
                                        {/* END : Value */}


                                        {/* START : Suffix */}
                                        <Col xs={4}>
                                            {
                                                element.value_suffix_options ?
                                                    <DatumRenderer
                                                        triggerRule={triggerRule}
                                                        ruleValidator={() => {
                                                            if (field.mandatory || element.deletable) {
                                                                if (element.value_suffix) {
                                                                    element.valid_suffix = true;
                                                                    return true;
                                                                } else {
                                                                    element.valid_suffix = false;
                                                                    return false;
                                                                }
                                                            } else {
                                                                element.valid_suffix = true;
                                                                return true;
                                                            }
                                                        }}
                                                        key={element.id}
                                                        type={Type.OPTIONS}
                                                        datumValue={element.value_suffix}
                                                        record={
                                                            {
                                                                element: element,
                                                                propertyKey: ElementPropertyKey.VALUE_SUFFIX,
                                                            } as FieldRecord
                                                        }
                                                        config={{ options: element.value_suffix_options } as OptionType}
                                                        onChange={updateValue}
                                                        rendererType={RendererType.component}
                                                    /> : <></>
                                            }
                                        </Col>
                                        {/* END : Suffix */}

                                        {/* Delete element button */}
                                        {
                                            element.deletable && field?.elements?.length! > 1 && <Col xs={24} className='flex-row-right-align'>
                                                <DeleteOutlined
                                                    style={{ color: 'red', fontSize: '18px' }}
                                                    onClick={() => {
                                                        const filteredElements = field?.elements?.filter(fieldElement => fieldElement.id !== element.id);

                                                        field.elements = filteredElements;
                                                        setProductFields([...productFields]);
                                                    }} />
                                            </Col>
                                        }
                                    </Row>

                                </Card>;
                            })
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col xs={24} className='flex-row-right-align'>
                        <Button
                            type='default'
                            onClick={() => {
                                field.elements?.forEach((element) => {
                                    element.ref = undefined;
                                });
                                const element = {
                                    id: uuidv4(),
                                    key: undefined,
                                    value: undefined,
                                    value_type: Type.STRING,
                                    deletable: true,
                                    ref: ref,
                                };
                                field.elements?.push(element);
                                setProductFields([...productFields]);
                                setLatestElement(element);
                            }}
                        >
                            Add Element
                        </Button>

                    </Col>
                </Row>
            </>
        </Card>;
    };

    return (

        props.field.mandatory ?

            <Badge.Ribbon text="Mandatory" color="#744f30" placement='start'>

                <InnerCard
                    key={props.field.id}
                    field={props.field}
                    productFields={props.productFields}
                    setProductFields={props.setProductFields}
                    triggerRule={props.triggerRule}
                />

            </Badge.Ribbon>

            :

            <InnerCard
                key={props.field.id}
                field={props.field}
                productFields={props.productFields}
                setProductFields={props.setProductFields}
                triggerRule={props.triggerRule}
            />

    );
};