import React, { useEffect, useState } from 'react';
import { BsDash, BsThreeDots } from 'react-icons/bs';
import { style, layout } from '../../styles/css/style';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Button, Col, Image, Menu, Row, Switch } from 'antd';
import { ListingPage } from '../common/templates/listingPage';
import { MeraaiTable } from '../common/templates/meraaiTable';
import { PageBreadcrumbHeader } from '../common/pageBreadcrumbHeader';
import { Device, MeraaiTableColumnType } from '../common/templates/meraaiTable/types';
import { CategoryDetails } from './types';
import { addKeyToDataList, isArrayEmpty } from '../../Utils';
import { ModifyCategoryDrawer } from './modifyCategoryDrawer';
import { CategoryMode } from './modifyCategoryDrawer/types';
import { DataNotFound } from '../common/templates/dataNotFound';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useCategoryList } from './hooks';


export const Category = () => {

    // State variables
    const { categoryResponse, fetching, setFetchCategoryList } = useCategoryList();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<CategoryDetails>();
    const [categoryMode, setCategoryMode] = useState<CategoryMode>();
    const [modifyCategoryDrawerVisibility, setModifyCategoryDrawerVisibility] = useState<boolean>(false);
    const [categoryStatusUpdate, setCategoryStatusUpdate] = useState<boolean>();
    const [searchName, setSearchName] = useState<string>();


    // Methods

    const onClickMenuOption = (event: MenuInfo, id) => {
        if (event.key === 'edit') {
            // Onclick edit
            setSelectedCategory(categoryResponse?.data?.find(category => category?.id === id));
            setCategoryMode(CategoryMode.UPDATE);
        }
    };

    const getParentCategory = (parentId) => {
        return categoryResponse?.data?.find(category => category?.id === parentId)?.name ?? <BsDash />;
    };

    // Column variable
    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image_url',
            rowVisibility: [Device.DESKTOP],
            render: (image_url) => <Image src={image_url} width={40} height={40} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            enableSearch: true,
            filteredValue: searchName ? [searchName] : undefined,
            onFilter(value: any, record) {
                return record.name.toLowerCase().includes(value.toLowerCase());
            },
            render: (name) => <label>{name}</label>,
        },
        {
            title: 'Type',
            rowVisibility: [Device.DESKTOP],
            render: () => <label>{'Product'}</label>,
        },
        {
            title: 'Parent category',
            dataIndex: 'parent_category_id',
            rowVisibility: [Device.DESKTOP],
            render: (parent_category_id) => <label>{getParentCategory(parent_category_id)}</label>,
        },
        {
            title: 'Description ',
            dataIndex: 'description',
            rowVisibility: [Device.DESKTOP],
            render: (description) => <label>{description}</label>,
        },
        {
            title: 'Hierarchy ',
            dataIndex: 'path',
            rowVisibility: [Device.DESKTOP],
            render: (path) => <label>{path}</label>,
        },
        {
            title: 'Action',
            dataIndex: 'active',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            filters: [
                { text: 'Activated', value: true },
                { text: 'Deactivated', value: false },
            ],
            onFilter: (value: boolean, record: CategoryDetails) => record.active === value,
            render: (active, record: CategoryDetails) => <div className={`${layout.flex.center}`}>
                <Switch
                    loading={fetching}
                    className={`bg-meraai-orange ${layout.flex.center}`}
                    checked={active}
                    onChange={(value) => {
                        setSelectedCategory(record);
                        setCategoryStatusUpdate(value);
                    }}
                />
                <label className={`ml-3 ${active ? '' : 'text-meraai-lightgrey'} font-semibold`}>
                    {active ? 'Active' : 'Inactive'}
                </label>

            </div>,
        },
        {
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'id',
            render: (id) => {

                const menuItems: ItemType[] = [
                    {
                        key: 'options',
                        icon: <BsThreeDots />,
                        children: [{
                            key: 'edit',
                            label: 'Edit',
                        }],
                    },
                ];

                return <div className={`${layout.flex.center}`}>
                    <Menu
                        mode='inline'
                        className={`bg-[#ffffff00] m-0 !w-max !h-[10px] ${layout.flex.center}`}
                        inlineCollapsed
                        onClick={(event) => {
                            onClickMenuOption(event, id);
                        }}
                        items={menuItems} />
                </div>;
            },
        },
    ];


    useEffect(() => {
        if (categoryMode) {
            setModifyCategoryDrawerVisibility(true);
        } else {
            setModifyCategoryDrawerVisibility(false);
        }
    }, [categoryMode]);


    return (
        <>
            <ListingPage
                breadcrumb={
                    <PageBreadcrumbHeader items={[
                        'Categories',
                    ]} />
                }
                header={
                    <Row className={`${layout.flex.end}`}>
                        <Col className='w-1/6'>
                            <Button
                                className={`w-full  ${style.meraaiOrangeButton}`}
                                onClick={() => {
                                    setCategoryMode(CategoryMode.CREATE);
                                }}
                            >
                                Add category
                            </Button>
                        </Col>
                    </Row>
                }

                body={
                    isArrayEmpty(categoryResponse?.data) ?
                        <DataNotFound loading={loading || fetching} title='No categories found' />
                        :
                        <MeraaiTable
                            loading={loading || fetching}
                            columns={columns}
                            dataSource={addKeyToDataList(categoryResponse?.data!)}
                            searchFilter={{
                                value: searchName,
                                hideSearchButton: true,
                                searchPlaceholder: 'Search name',
                                setSearchText: setSearchName,
                            }}
                        />
                }
            />

            <ModifyCategoryDrawer
                setLoading={setLoading}
                mode={categoryMode!}
                open={modifyCategoryDrawerVisibility}
                onClose={() => {
                    // useEffect will close this modal
                    setCategoryMode(undefined);
                }}
                categoryResponse={categoryResponse}
                categoryDetails={selectedCategory}
                onSuccess={() => {
                    setFetchCategoryList(true);
                    setCategoryStatusUpdate(undefined);
                }}
                categoryStatusUpdate={categoryStatusUpdate}
            />

        </>

    );
};