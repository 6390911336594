/* eslint @typescript-eslint/no-unused-vars: 0 */
import React from 'react';
import { DatePicker, Image, Input, InputNumber, Select } from 'antd';
import { getISTFromEpoch, TIMEZONE } from '../../Date';
import { BaseType, DateType, ImageType, OptionType } from '../type';
import moment from 'moment-timezone';
import { ImAttachment } from 'react-icons/im';
import TextArea from 'antd/lib/input/TextArea';
import { DatumConfig, Type, ValueComponentProps } from './type';
import ImageRenderer, { ImageFile, UploaderTypes } from '../../../components/common/ImageRenderer';
import CloseableImage from '../../../components/common/CloseableImage';

export const datumRendererMap = new Map<Type, DatumConfig>(
    [
        [
            Type.DATE, {
                type: Type.DATE,
                valueRenderer: (value) => getISTFromEpoch(parseInt(value)),
                valueComponent: (props: ValueComponentProps, config: DateType) => (
                    <DatePicker
                        placeholder={props.placeholder ?? 'Select a date'}
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        disabledDate={
                            config?.restriction
                                ? current =>
                                    /**
                                           * All the future dates will be returning true according to the first condition mentioned below which will mark all of them as disabled.
                                           * OR
                                           * All the dates that are found between
                                           * initiate date and current date will be marked as enabled because we have fliped the condition by adding a not operator.
                                           * */
                                    current.isAfter(moment()) ||
                                    !current.isBetween(moment(config.startDate), moment().add(config.backDatedSelectionInDays - 1, 'day'))
                                : current =>
                                    // Future dates will be disabled for all the Roles
                                    current.isAfter(moment())
                        }
                        format="YYYY-MM-DD hh:mm"
                        showTime={{ defaultValue: moment(), format: 'hh:mm A', use12Hours: true }}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event.target.value, props.record);
                            }
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                        }}
                    />
                ),
                valueConverter: (date: any) => moment(date).tz(TIMEZONE).valueOf(),
            },
        ],

        [
            Type.FILE_URL, {
                type: Type.FILE_URL,
                valueRenderer: (value: string) => (
                    <a href={value} className="flex-align-center">
                        <ImAttachment size={15} style={{ paddingRight: '3px' }} /> {'File'}
                    </a>
                ),
                valueComponent: () => <></>,
            },
        ],

        [
            Type.IMAGE_URL, {
                type: Type.IMAGE_URL,
                valueRenderer: (url: string) => <Image height="90px" width="90px" src={url} preview />,
                valueComponent: (_, config: ImageType) => <div className='flex-column-start flex-align-center'>
                    <ImageRenderer
                        disabled={config.loading}
                        setFileState={(file: ImageFile) => {
                            config.uploadImage(file);
                        }}
                        size='middle'
                        type={UploaderTypes.UPLOAD_PNG}
                    />
                    <CloseableImage
                        styleClass='padding-top-10px'
                        src={config.imageUrl?.url}
                        width={90}
                        height={90}
                        onClose={() => {
                            config.setImage(undefined);
                        }}
                    />
                </div>,
            },
        ],

        [
            Type.REMARK, {
                type: Type.REMARK,
                valueRenderer: (text: string) => <span> {text} </span>,
                valueComponent: () => <></>,
            },
        ],

        [
            Type.DEFAULT, {
                type: Type.DEFAULT,
                valueRenderer: value => value,
                valueComponent: (props: ValueComponentProps, config: BaseType) => (
                    <Input
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        placeholder={props.placeholder ?? 'Enter a string'}
                        defaultValue={props.value}
                        style={{
                            width: '192px',
                        }}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event.target.value, props.record);
                            }
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                        }}
                    />
                ),
                valueConverter: (value: string) => value,
            },
        ],

        [
            Type.STRING, {
                type: Type.STRING,
                valueRenderer: (value: string) => <span> {value} </span>,
                valueComponent: (props: ValueComponentProps, config: BaseType) => (
                    <Input
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        placeholder={props.placeholder ?? 'Enter a string'}
                        defaultValue={props.value}
                        style={{
                            width: '100%',
                            height: '40px',
                        }}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event.target.value, props.record);
                            }
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                        }}
                    />

                ),
                valueConverter: (value: string) => value,
            },
        ],

        [
            Type.MOBILE_NUMBER, {
                type: Type.MOBILE_NUMBER,
                valueRenderer: (value: string) => <span> {value} </span>,
                valueComponent: (props: ValueComponentProps, config: BaseType) => (
                    <Input
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        style={{
                            width: '192px',
                        }}
                        placeholder={props.placeholder ?? 'Enter a mobile number'}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event.target.value, props.record);
                            }
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                        }}
                    />
                ),
                valueConverter: (value: string) => value,
            },
        ],

        [
            Type.NUMBER, {
                type: Type.NUMBER,
                valueRenderer: (value: string) => <span> {value} </span>,
                valueComponent: (props: ValueComponentProps, config: BaseType) => (
                    <InputNumber
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        type={'number'}
                        controls={false}
                        className='input-height-100'
                        placeholder={props.placeholder ?? 'Enter a number'}
                        defaultValue={props.value}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event.target.value, props.record);
                            }
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                        }}
                    />
                ),
            },
        ],

        [
            Type.TEXT, {
                type: Type.TEXT,
                valueRenderer: (value: string) => <span> {value} </span>,
                valueComponent: (props: ValueComponentProps, config: BaseType) => (
                    <TextArea
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        showCount
                        maxLength={300}
                        defaultValue={props.value}
                        style={{ height: 120 }}
                        placeholder={props.placeholder ?? 'Enter a string'}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event.target.value, props.record);
                            }
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                        }}
                    />
                ),
            },
        ],

        [
            Type.OPTIONS, {
                type: Type.OPTIONS,
                valueRenderer: (value: string) => <span> {value} </span>,
                valueComponent: (props: ValueComponentProps, config: OptionType) => (
                    <Select
                        status={props.rulePassed === false ? 'error' : undefined}
                        ref={config?.ref}
                        style={{ width: '100%' }}
                        defaultValue={props.value}
                        onBlur={() => {
                            if (props.setBlur) {
                                props.setBlur(true);
                            }
                        }}
                        onChange={(val) => {
                            if (props.onChange) {
                                props.onChange(val, props.record);
                            }
                            if (props.onBlur) {
                                props.onBlur(val, props.record);
                            }
                        }}
                        placeholder={props.placeholder ?? 'Select a value'}
                    >
                        {
                            config?.options?.map((option) => {
                                return <Select.Option key={option}>
                                    {option}
                                </Select.Option>;
                            })
                        }
                    </Select>
                ),
            },
        ],
    ],
);

export const getDatumConfig = (type: string): DatumConfig => {
    return datumRendererMap.get(Type[type] ?? Type.DEFAULT)!;
};
export const formatValue = (type: Type, data: any) => {
    return getDatumConfig(type).valueConverter!(data);
};