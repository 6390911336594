export enum Path {
    LOGIN = '/login',
    LOGIN_VERIFY = '/verify-otp',

    HOME = '/',
    ANALYTICS = '/analytics',

    APPLICANTS = '/applicants',
    APPLICANTS_DETAIL = '/applicants/detail',

    ORDER_LIST = '/orders',
    HUB_ORDER_LIST = '/orders/hubs',
    OUTLET_ORDER_LIST = '/orders/outlets',
    SELLER_HUB_ORDER_LIST = '/orders/seller-hubs',
    ORDER_DETAILS = '/order/:orderUuid',
    ORDER_EDIT = '/order/:orderUuid/edit',

    PURCHASE_ORDER_LIST = '/purchase-orders',
    HUB_PURCHASE_ORDER_LIST = '/purchase-orders/hubs',
    SELLER_HUB_PURCHASE_ORDER_LIST = '/purchase-orders/seller-hubs',
    PURCHASE_ORDER_DETAILS = '/purchase-order/:purchaseOrderUuid',

    ESTABLISHMENTS = '/establishments',
    HUB_ESTABLISHMENT_LIST = '/establishments/hubs',
    OUTLET_ESTABLISHMENT_LIST = '/establishments/outlets',
    SELLER_HUB_ESTABLISHMENT_LIST = '/establishments/seller-hubs',

    CREATE_ESTABLISHMENTS = '/establishments/create/:eType',
    ESTABLISHMENT_DETAILS = '/establishments/:establishmentUuid/:eType',

    FINANCE = '/finance',

    INVENTORY = '/inventory',
    CATEGORY = '/categories',
    RETURNS = '/returns',
    RETURN_DETAILS = '/returns/:returnOrderUuid',

    USER_LISTING = '/users',
    USER_PROFILE = '/users/:username',

    NOTIFICATIONS = '/',
    SETTINGS = '/',
    LOGOUT = '/login',
}
