import { Card, Col, Divider, Row, Skeleton } from 'antd';
import React from 'react';
import { DetailsCardProps } from './types';

export const DetailsCard = (props: DetailsCardProps) => {
    return (
        <>
            {
                props.loading ? <Skeleton active paragraph={{ rows: 5 }} /> :
                    <Card bodyStyle={{ padding: props.removePadding ? 0 : undefined }}>
                        <Col className='w-full pb-4'>
                            {
                                props.title ? <Row>
                                    <Col className='w-full pb-4'>
                                        {props.title}
                                    </Col>
                                </Row> : <></>
                            }

                            {
                                props.data?.map((datum, index) => {
                                    return <Row key={index} className='py-1'>
                                        <Col className={`w-full xs:w-1/2 ${props.labelStyleClass}`}>
                                            {datum.label}
                                        </Col>
                                        <Col className={`w-full xs:w-1/2 ${props.valueStyleClass}`}>
                                            {datum.value}
                                        </Col>
                                    </Row>;
                                })
                            }
                        </Col>
                    </Card>
            }
            {
                props.divider && <Divider className='m-0 my-2 text-[#E3E3E3]' />
            }
        </>
    );
};