import { Button, Result } from 'antd';
import React from 'react';

function InternetConnectionError() {
    return (
        <Result
            status="warning"
            title="There is some issue with your internet connection."
            extra={
                <Button
                    type="primary"
                    key="retry"
                    onClick={() => {
                        window.location.reload();
                    }}>
                    Retry
                </Button>
            }
        />
    );
}

export default InternetConnectionError;
