/* eslint @typescript-eslint/no-unused-vars: 0 */
import { Button, Col, Empty, Form, Image, Input, message, Row, Spin, Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineSort } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { GET_INVENTORY_PRODUCT_LIST, UPDATE_INVENTORY_PRODUCT } from '../../http/EndPoints';
import { getApiCall, putApiCall, ResponseHandler } from '../../http/HttpClient';
import { setMargin, setPadding } from '../../styles/css/react/MeraaiCssProperties';
import { EstablishmentType } from '../../types/EstablishmentType';
import BrandListingDrawer from '../cart/BrandListingDrawer';
import { PRODUCT_PAGE_SIZE } from '../cart/CreateOrder';
import { EstablishmentsDropDown, EstablishmentSelectionMode } from '../common/establishments/EstablishmentsDropDown';
import { AlignType } from 'rc-table/lib/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { addKeyToDataList } from '../../Utils';
import { FLOW, InventoryProduct, InventoryProductListResponse } from '../../types/Inventory';
import { UpdateInventoryModal } from './UpdateInventoryModal';
import { Brand } from '../orders/listing/ListingData';
import { InventoryConfig } from '../config/types/ConfigTypes';
import _ from 'lodash';
import { OptionalTitle } from '../common/TableTitle';
import { IoIosRefresh } from 'react-icons/io';
import { SkuInfo } from '../orders/layer/details/hubsAndOutlets/utils';

interface UpdateRequestBody {
    id: number,
    flow: FLOW,
    diff_stock: number,
    add_to_free_stock: boolean,
    affect_blocked_stock?: boolean,
}

interface InventoryProductListProps {
    config: InventoryConfig
}

export const InventoryProductList = (props: InventoryProductListProps) => {
    const { config } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchEstablishmentList, setFetchEstablishmentList] = useState<boolean>(true);
    const [hubEstablishmentUuid, setHubEstablishmentUuid] = useState<string>();
    const [brandsDrawerVisibility, setBrandsDrawerVisibility] = useState<boolean>(true);
    const [productName, setProductName] = useState<string>('');
    const currentProductName = useRef<string>();
    const shouldGetProducts = useRef<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [productsList, setProductsList] = useState<InventoryProduct[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<Brand>();
    const [selectedItem, setSelectedItem] = useState<InventoryProduct>();
    const [updateInventoryModalVisibility, setUpdateInventoryModalVisibility] = useState<boolean>(false);


    const getProducts = async () => {
        if (!shouldGetProducts.current) {
            return;
        }
        setLoading(true);

        const productsListEndPoint =
            GET_INVENTORY_PRODUCT_LIST(
                hubEstablishmentUuid!,
                productName,
                selectedBrand?.id.toString(),
                page - 1,
                PRODUCT_PAGE_SIZE,
            );

        const responseHandler: ResponseHandler<InventoryProductListResponse> = {
            onResponseSuccess(value: InventoryProductListResponse): void {
                setLoading(false);
                if (value.products.length === 0) {
                    message.info('No products found', 3);
                }
                setProductsList(value.products);
                setPageCount(value.page_count);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(productsListEndPoint, responseHandler);
    };

    const onBrandItemClick = (brand: Brand) => {
        setBrandsDrawerVisibility(false);
        if (selectedBrand?.id != brand.id || productName !== '') {
            setProductName('');
            shouldGetProducts.current = true;
            setSelectedBrand(brand);
            setPage(1);
        }
    };

    const searchProductsByName = () => {
        setBrandsDrawerVisibility(false);
        currentProductName.current = productName;
        shouldGetProducts.current = true;
        if (page !== 1) {
            setPage(1);
        } else {
            getProducts();
        }
    };

    const initializeItemUpdatedStockAndSetSelectedItem = (record: InventoryProduct) => {
        record.diff_stock = 1;
        record.add_to_free_stock = false;
        record.flow = FLOW.INWARD;
        setSelectedItem(record);
        setUpdateInventoryModalVisibility(true);
    };

    const makeUpdateRequestBody = (): UpdateRequestBody => {
        return {
            id: selectedItem?.id!,
            flow: selectedItem?.flow!,
            diff_stock: selectedItem?.diff_stock!,
            add_to_free_stock: selectedItem?.add_to_free_stock!,
        };
    };

    const updateInventoryProductStock = () => {

        setLoading(true);
        const responseHandler: ResponseHandler<InventoryProduct> = {
            onResponseSuccess(value: InventoryProduct): void {
                message.success(value.message);
                setUpdateInventoryModalVisibility(false);
                const index = productsList.findIndex(product => product.id === value.id);
                if (index != -1) {
                    const product = productsList[index];
                    product.stock = value.stock;
                    product.available_stock = value.available_stock;
                    product.free_stock = value.free_stock;
                }
                setProductsList(_.cloneDeep(productsList!));
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        putApiCall(UPDATE_INVENTORY_PRODUCT(hubEstablishmentUuid!), makeUpdateRequestBody(), responseHandler);
    };

    let columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            align: 'center' as AlignType,
            render: (image) => <Image width={30} height={30} src={image} />,
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            align: 'center' as AlignType,
            render: (brand) => `${brand}`,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center' as AlignType,
            render: (__, record: InventoryProduct) => <SkuInfo name={record.name} sku={record.sku} />,
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            align: 'center' as AlignType,
            render: (stock) => `${stock}`,
        },
        {
            title: <OptionalTitle title='Blocked stock' description='This is the stock that has been packed and has not been sent out for delivery' />,
            dataIndex: 'blocked_stock',
            align: 'center' as AlignType,
            render: (blocked_stock) => `${blocked_stock}`,
        },
        {
            title: <OptionalTitle title='Buffer stock' description='This is the stock that will be used while accepting the upcoming orders. If an inwards of stock happens and the demand is not present, it then becomes a part of the buffer stock. Buffer stock is inclusive of the total stock' />,
            dataIndex: 'free_stock',
            align: 'center' as AlignType,
            render: (free_stock) => `${free_stock}`,
        },
        {
            title: <OptionalTitle title='Demand' description='It is an aggregation of the current demand that needs to be fulfilled.' />,
            dataIndex: 'required_stock',
            align: 'center' as AlignType,
            render: (required_stock) => `${required_stock}`,
        },
        {
            title: <>
                <label>Available stock</label> &nbsp;
                <Tooltip title="Available stock is stock minus blocked stock">
                    <ExclamationCircleOutlined className="pointer" />
                </Tooltip>
            </>,
            dataIndex: 'available_stock',
            align: 'center' as AlignType,
            render: (available_stock) => `${available_stock}`,
        },
    ];

    // In order to update inventory stock you'll need a permisssion.
    if (config.products.allowUpdate) {
        columns.push({
            title: '',
            dataIndex: 'update',
            align: 'center' as AlignType,
            render: (__, record: InventoryProduct) => <>
                <Button
                    className='default-success-button'
                    onClick={() => {
                        initializeItemUpdatedStockAndSetSelectedItem(record);
                    }}
                >
                    Make changes
                </Button>
            </>,
        });
    }

    const resetPreviousEstablishmentStates = () => {
        setSelectedBrand(undefined);
    };

    useEffect(() => {
        getProducts();
    }, [selectedBrand, page]);

    useEffect(() => {
        resetPreviousEstablishmentStates();
    }, [hubEstablishmentUuid]);

    return (
        <>
            <div className="filter-drawer-div">
                <Row style={{ paddingTop: '30px' }} gutter={[24, 24]} className='main-body-filters'>
                    <Col xs={0} sm={12} md={16} xl={19}></Col>
                    <Col xs={12} sm={6} md={4} xl={2} className='flex-center' style={{ padding: '10px 10px' }}>
                        <Button
                            icon={<MdOutlineSort color="#7E4D2B" />}
                            size="middle"
                            className="secondary-button"
                            disabled={hubEstablishmentUuid == undefined}
                            onClick={() => setBrandsDrawerVisibility(true)}>
                            &nbsp; Brands
                        </Button>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3} className='flex-center' style={{ padding: '10px 10px' }}>
                        <Button
                            size='middle'
                            disabled={!(productsList?.length > 0)}
                            icon={<IoIosRefresh />}
                            onClick={() => {
                                getProducts();
                            }}
                        >
                            &nbsp; Refresh
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className='flex-center main-body-filters'>
                    <Col xs={24} md={6} >
                        <EstablishmentsDropDown
                            setLoading={setLoading}
                            type={EstablishmentType.HUB}
                            selectedUuids={hubEstablishmentUuid!}
                            setSelectedUuids={setHubEstablishmentUuid}
                            fetchEstablishmentList={fetchEstablishmentList}
                            selectionMode={EstablishmentSelectionMode.SINGLE_SELECT}
                        />
                    </Col>
                    {
                        hubEstablishmentUuid && <BrandListingDrawer
                            establishmentUuid={hubEstablishmentUuid}
                            brandsDrawerVisibility={brandsDrawerVisibility}
                            setBrandsDrawerVisibility={setBrandsDrawerVisibility}
                            onBrandItemClick={onBrandItemClick}
                        />
                    }

                    <Col xs={24} md={14}>
                        <Form
                            className="create-product-form display-initial"
                            initialValues={{ remember: true }}
                            onFinish={() => {
                                searchProductsByName();
                            }}
                            autoComplete="off">
                            <Row gutter={[24, 24]}>
                                <Col xs={24} sm={16} md={16}>
                                    <Form.Item className="create-product-form-item1 remove-margin full-width">
                                        <Input
                                            className="create-product-form-input-tag"
                                            placeholder="Search products by name"
                                            value={productName}
                                            disabled={hubEstablishmentUuid == undefined}
                                            onChange={event => setProductName(event.currentTarget.value)}
                                        />
                                    </Form.Item>

                                </Col>
                                <Col xs={24} sm={8} md={8} className='flex-align-center'>
                                    <Form.Item className="create-product-form-item2 remove-margin ">
                                        <Button
                                            className="create-product-form-item2-button"
                                            size="large"
                                            htmlType="submit"
                                            disabled={productName === currentProductName.current || hubEstablishmentUuid == undefined}
                                        >
                                            Get Products
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className='main-body-content' style={{ margin: '0px 20px' }}>
                    <Col xs={24}>
                        <Spin spinning={loading}>
                            {productsList.length ? (
                                <Row className="flex-center" gutter={[0, 16]}>
                                    {selectedBrand && (
                                        <Col>
                                            <Tag color="cyan" className="flex-align-center" style={{ fontSize: '16px' }}>
                                                {'Selected brand'} &nbsp;
                                                <b>{selectedBrand?.name} </b> &ensp;
                                                <Tooltip title="Remove Brand" className="pointer">
                                                    <TiDelete
                                                        style={{ fontSize: '20px' }}
                                                        onClick={() => {
                                                            setSelectedBrand(undefined);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Tag>
                                        </Col>
                                    )}
                                    <Col xs={24}>
                                        <Table
                                            columns={columns}
                                            style={setMargin(0, 25, 10, 25)}
                                            dataSource={addKeyToDataList(productsList)}
                                            scroll={{ x: '800px', y: '450px' }}
                                            pagination={{
                                                pageSize: PRODUCT_PAGE_SIZE,
                                                current: page,
                                                total: pageCount! * PRODUCT_PAGE_SIZE,
                                                onChange: currentPage => {
                                                    setPage(currentPage);
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Empty
                                    className="main-body-aggregate-empty"
                                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                                    description={loading ? 'We are trying our best to get the product(s), please wait...' : 'Search product(s)'}
                                />
                            )}
                        </Spin>
                    </Col>
                </Row>
            </div>

            <UpdateInventoryModal
                loading={loading}
                selectedItem={selectedItem!}
                setSelectedItem={setSelectedItem}
                updateInventoryModalVisibility={updateInventoryModalVisibility}
                setUpdateInventoryModalVisibility={setUpdateInventoryModalVisibility}
                updateInventoryProductStock={updateInventoryProductStock}
            />
        </>
    );

};