/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-shadow: 0 */

import { matchPath } from 'react-router-dom';
import { Role } from '../../types/Role';
import { RoleDataMap } from '../../types/RoleData';
import { Path } from '../router/Path';
import { BaseConfig } from './types/ConfigTypes';

export interface BasePageConfig {
    allowedRoles: Role[];
}

export interface PathConfig {
    path: Path;
    accessible?: boolean;
    config?: BaseConfig | null;
}

export class PathConfigData {
    private configMap: Map<Path, PathConfig> = new Map();

    constructor(currentRole: Role) {
        RoleDataMap.get(currentRole)?.configs?.forEach((pageConfig: PathConfig) => {
            this.configMap.set(pageConfig.path, pageConfig);
        });       
    }

    getConfigForPath(path: string): BaseConfig {
        const pathType: Path | undefined = this.getActualPath(path); 
        if (!pathType) {
            return {} as BaseConfig;
        }       
        return this.configMap.get(pathType)?.config ?? ({} as BaseConfig);
    }

    isPathAccessible(path: string): Boolean {
        const pathType: Path | undefined  = this.getActualPath(path); 
        if (!pathType) {
            return false;
        }
        return this.configMap.get(pathType)?.accessible ?? false;
    }

    /*
    This returns the actual path linked to the path in the string.
    For example if the path is /order/12345632,
    the actual path link would be Path.ORDER_DETAILS i.e. /order/:orderUuid
    */
    private getActualPath(path: string): Path | undefined {
        const pathList: Path[] = Object.keys(Path).map(name => Path[name as keyof typeof Path]);
        const pathType = pathList.find(pathType => matchPath({ path: pathType }, path));
        return pathType;
    }
}
