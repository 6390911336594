import { Establishment, StateMetaDatum } from '../Types';
import { OrderItem } from './Order';

export interface UpdateSellerOrderRequest {
    state_request: UpdateStateRequest | undefined;
}

export interface UpdateStateRequest {
    items: ItemState[];
}

export interface ItemState {
    key: number;
    item_id: number;
    state: string;
}

export interface SellerHubOrderProps {
    type: string;
    active: Boolean;
}

export interface SellerHubAggregateResponse {
    orders: number;
    amount: number;
    products: ProductAggregate[];
}

export interface ProductAggregate {
    product_id: number;
    name: string;
    brand_name: string;
    image_url: string;
    sku: string;
    ean: string;
    quantity: number;
    available_quantity?: number;
    order_quantity_multiple?: number;
    price_per_unit: number;
    total_amount: number;
}

export interface SellerOrders {
    orders: SellerOrder[];
    page_count: number;
    page_size: number;
    next_page: boolean;
}
export interface SellerOrder {
    uuid: string;
    order_invoice_no: string;
    outlet: Establishment;
    hub: Establishment;
    state: string;
    items: OrderItem[];
    state_metadata: StateMetaDatum[];
    created_at: number;
    amount: number;
    purchase_order_generated: boolean;
}

export type SellerHubOrderTableData = {
    key: string;
    invoiceNo: string;
    hubName: string;
    amount: string;
    createdAt: string;
    state: string;
};

export interface HubWiseSellerOrderAggregateList {
    hub_wise_aggregate: HubWiseSellerOrderAggregate[];
}

export interface HubWiseSellerOrderAggregate {
    hub: Establishment;
    uuids: string[];
    aggregate: SellerOrderAggregate;
}

interface SellerOrderAggregate {
    amount: number;
    products: ProductAggregate[];
}

export interface SellerOrderAggregateApprovalResponse {
    state: string,
    message: string,
    purchase_order_uuid: string,
}


export enum SellerOrderStates {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}


export interface SellerOrderApprovalRequestBody {
    hub_uuid: string,
    state: SellerOrderStates,
    uuids: string[],
    products?: SellerOrderProduct[],
    total_amount?: number,
}

export interface SellerOrderProduct {
    product_id: number,
    quantity: number,
    total_amount: number
}
