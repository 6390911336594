import { TablePaginationConfig } from 'antd';
import React from 'react';
import { ProductItemType } from '../../types/Cart';
import { CartItem } from './CartItem';
import ProductTableViewList from './ProductTableViewList';

export interface ProductListingProps {
    rowElementsCount: number;
    elementType: ProductItemType;
    productsListLoading: boolean;
    listingData: Array<CartItem>;
    pagination: false | TablePaginationConfig;
    styleClass: string;
    height: string;
}

function ProductList(props: ProductListingProps) {
    return (
        <div>
            <ProductTableViewList
                dataSource={props.listingData}
                pagination={props.pagination}
                styleClass={props.styleClass}
                height={props.height}
            />
        </div>
    );
}

export default ProductList;
