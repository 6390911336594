import { ColumnType, TableProps } from 'antd/lib/table';
import { SearchInputProps } from '../../SearchInput';

export enum Device {
    'MOBILE',
    'DESKTOP',
}

export interface MeraaiTableProps extends TableProps<any> {
    columns: MeraaiTableColumnType[];
    searchFilter?: SearchInputProps;
    setDataSource?: React.Dispatch<React.SetStateAction<any>>;
    expandableRowModificationType?: 'SHOW' | 'EDIT';
    defaultExpandableKey?: string | number;
}

export interface MeraaiTableColumnType extends ColumnType<any> {
    rowVisibility?: Device[];
    mobileWidth?: 'DEFAULT' | 'FULL';
    editable?: boolean;
    onCell?: any;
    actionable?: boolean; // if actionable is true then such components will render at the bottom
    // TODO Rename 
    expandedRowProps?: {
        visibility?: 'ALWAYS',
        valueFunction?: 'RENDER' | 'COMPONENT',
    }
    enableSearch?: boolean;
}
