import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row, Select, Button, Spin } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Option } from 'rc-select';
import { useLocation, useNavigate } from 'react-router-dom';
import TimeFilterPicker, { DateFilterParam, DateFilterSort } from '../../common/TimeFilterPicker';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { RECEIVABLES_LIST } from '../../../http/EndPoints';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { ReceivableListResponse } from '../../../types/FinanceCredit';
import {
    EstablishmentsDropDown,
    EstablishmentSelectionMode,
    getEncodedStringFromUuidList,
    getUuidListFromEncodedString,
} from '../../common/establishments/EstablishmentsDropDown';
import ReceivablesList from './ReceivablesList';
import { isArrayEmpty } from '../../../Utils';
import { ReceivableState, CollectionComponentConfig } from '../CollectionState';

interface ReceivablesProps {
    active: boolean;
}

function Receivables(props: ReceivablesProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const pageSize = 50;
    const isRendered = React.useRef(false);
    const [receivablesRequested, setReceivablesRequested] = useState<boolean>(false);
    const [receivablesResponse, setReceivablesResponse] = React.useState<ReceivableListResponse>();
    const [enableDateFilterPicker, setEnableDateFilterPicker] = useState<boolean | undefined | null>(false);
    const [receivableState, setReceivableState] = useState<ReceivableState>();
    const [establishmentUuids, setEstablishmentUuids] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<number>();
    const [toDate, setToDate] = useState<number>();
    const [page, setPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFilterParam, setSelectedFilterParam] = useState<DateFilterParam>();
    const [selectedDateSort, setSelectedDateSort] = useState<DateFilterSort>('desc');
    const [fetchEstablishmentList, setFetchEstablishmentList] = useState<boolean>();

    const getReceivables = () => {
        setLoading(true);
        const receivablesEndPoint = RECEIVABLES_LIST(
            establishmentUuids!,
            receivableState!,
            fromDate,
            toDate,
            selectedFilterParam!,
            selectedDateSort,
            page - 1,
            pageSize,
        );

        const responseHandler: ResponseHandler<ReceivableListResponse> = {
            onResponseSuccess(response: ReceivableListResponse): void {
                setReceivablesResponse(response);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        getApiCall(receivablesEndPoint, responseHandler);
    };

    const setStateFromQueryParams = (queryParams, param, stateSetter) => {
        if (!queryParams.has(param)) {
            return;
        }
        if (param == 'e-uuids') {
            const encodedUuids = queryParams.get(param);
            if (encodedUuids) {
                const uuidList = getUuidListFromEncodedString(encodedUuids);
                if (uuidList) {
                    stateSetter(uuidList);
                }
            }
        } else if (['page', 'from-date', 'to-date'].includes(param)) {
            const value = parseInt(queryParams.get(param));
            if (value) {
                stateSetter(value);
            }
        } else if (param == 'filter-param' && queryParams.has('state')) {
            stateSetter(
                CollectionComponentConfig.valueOf(queryParams.get('state'))?.config.receivablesConfig?.filterParams?.find(filterParam => filterParam.value === queryParams.get('filter-param')),
            );
        } else if (param == 'state') {
            stateSetter(CollectionComponentConfig.valueOf(queryParams.get(param))?.config.receivablesConfig?.state);
        } else {
            stateSetter(queryParams.get(param));
        }
    };

    const setQueryParamsFromState = (queryParams, param, state) => {
        if (state) {
            if (param == 'e-uuids') {
                if (!isArrayEmpty(state)) {
                    const encodedList = getEncodedStringFromUuidList(state);
                    queryParams.set(param, encodedList);
                }
            } else {
                queryParams.set(param, state);
            }
        }
    };

    const setStateFromUrlParams = () => {
        const queryParams = new URLSearchParams(location.search);
        setStateFromQueryParams(queryParams, 'e-uuids', setEstablishmentUuids);
        setFetchEstablishmentList(true);
        setStateFromQueryParams(queryParams, 'state', setReceivableState);
        setStateFromQueryParams(queryParams, 'from-date', setFromDate);
        setStateFromQueryParams(queryParams, 'to-date', setToDate);
        setStateFromQueryParams(queryParams, 'filter-param', setSelectedFilterParam);
        setStateFromQueryParams(queryParams, 'filter-sort', setSelectedDateSort);

        setStateFromQueryParams(queryParams, 'page', setPage);
    };

    const setUrlParamsFromState = () => {
        const queryParams = new URLSearchParams(location.search);
        setQueryParamsFromState(queryParams, 'e-uuids', establishmentUuids);
        setQueryParamsFromState(queryParams, 'state', receivableState);
        setQueryParamsFromState(queryParams, 'from-date', fromDate);
        setQueryParamsFromState(queryParams, 'to-date', toDate);
        setQueryParamsFromState(queryParams, 'filter-param', selectedFilterParam?.value);
        setQueryParamsFromState(queryParams, 'filter-sort', selectedDateSort);
        setQueryParamsFromState(queryParams, 'page', page);

        /**
         * This setUrlParamsFromState() method executes when user navigates to this component and if there is any value present in the state, It will set the URL from the state values.  
         * While setting the URL we don't want a new entry to be created in the browser history stack for the same page, 
         * So we are replacing the page with the help of replace property of navigate method.
         * */
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    const isListingAllowed = (): boolean => {
        let listingAllowed = establishmentUuids?.length > 0 && receivableState != undefined;
        if (CollectionComponentConfig.valueOf(receivableState!)?.config.receivablesConfig?.dateFilterSelectionRequired) {
            listingAllowed = listingAllowed && fromDate != undefined && toDate != undefined;
        }
        return listingAllowed;
    };

    const getReceivablesAndUpdateURLParams = () => {
        getReceivables();
        setUrlParamsFromState();
    };

    useEffect(() => {
        if (props.active) {
            /**
             * If the establishmentUuid is set, it signifies that something is present in the state.
             */
            if (establishmentUuids) {
                setUrlParamsFromState();
            } else {
                setStateFromUrlParams();
            }
        }
    }, [props.active, establishmentUuids]);

    useEffect(() => {
        if (receivableState) {
            const timeFilterSelectionRequired = CollectionComponentConfig.valueOf(receivableState!)?.config.receivablesConfig?.dateFilterSelectionRequired;
            setEnableDateFilterPicker(timeFilterSelectionRequired);
            if (!timeFilterSelectionRequired) {
                setFromDate(undefined);
                setToDate(undefined);
            }
            const filterParams = CollectionComponentConfig.valueOf(receivableState)?.config.receivablesConfig?.filterParams;
            // If selected filter param is not found in the list of filter parameters for state, pick the first one from the list.
            if (!filterParams?.find(param => param.value === selectedFilterParam?.value)) {
                setSelectedFilterParam(CollectionComponentConfig.valueOf(receivableState)?.config.receivablesConfig?.filterParams?.at(0));
                setSelectedDateSort('desc');
            }
        }
    }, [receivableState]);

    useEffect(() => {
        if (isRendered.current) {
            getReceivablesAndUpdateURLParams();
        } else {
            isRendered.current = true;
        }
    }, [page]);

    useEffect(() => {
        if (!receivablesRequested) {
            return;
        }

        if (page == 1) {
            getReceivablesAndUpdateURLParams();
        } else {
            setPage(1);
        }
        setReceivablesRequested(false);
    }, [receivablesRequested]);

    return (
        <Spin spinning={loading} tip="Loading..." size="small">
            <Row gutter={[24, 24]} className="main-body-filters">
                <Col xs={24} sm={12} xl={5}>
                    <EstablishmentsDropDown
                        setLoading={setLoading}
                        type={EstablishmentType.HUB}
                        setSelectedUuids={setEstablishmentUuids}
                        selectedUuids={establishmentUuids}
                        selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                        fetchEstablishmentList={fetchEstablishmentList}
                    />
                </Col>
                <Col xs={24} sm={12} xl={5}>
                    <Select
                        suffixIcon={<ArrowDownOutlined />}
                        value={receivableState ?? undefined}
                        placeholder="Select state"
                        className="filter-drop-down"
                        onChange={value => {
                            setReceivableState(value);
                        }}>
                        {
                            Object.values(CollectionComponentConfig).filter((type: CollectionComponentConfig) => type.config.receivablesConfig?.display).map((type: CollectionComponentConfig, index) => <Option
                                key={index}
                                value={type.config.receivablesConfig?.state}
                            >
                                {type.config.receivablesConfig?.display}
                            </Option>)
                        }
                    </Select>
                </Col>
                <Col lg={10} md={24}>
                    <TimeFilterPicker
                        enableFilterPicker={enableDateFilterPicker!}
                        fromDateEpoch={fromDate!}
                        toDateEpoch={toDate!}
                        setFromDateEpoch={setFromDate}
                        setToDateEpoch={setToDate}
                        dateFilterOptions={CollectionComponentConfig.valueOf(receivableState!)?.config.receivablesConfig?.filterParams}
                        dateFilterParam={selectedFilterParam}
                        setDateFilterParam={setSelectedFilterParam}
                        dateFilterSort={selectedDateSort}
                        setDateFilterSort={setSelectedDateSort}
                    />
                </Col>
                <Col lg={4} md={24}>
                    <Button
                        className="default-button"
                        size="large"
                        disabled={!isListingAllowed()}
                        onClick={() => {
                            setReceivablesRequested(true);
                        }}>
                        Get Receivables
                    </Button>
                </Col>
            </Row>

            <ReceivablesList page={page} setPage={setPage} receivablesResponse={receivablesResponse!} />
        </Spin >
    );
}

export default Receivables;
