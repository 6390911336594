import * as React from 'react';

function AmountIcon() {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="url(#paint0_linear_136_172)"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.7276 12.1072C16.1714 11.916 16.6927 11.9843 17.0635 12.2821L19.1667 13.9722L21.2708 12.2821C21.7396 11.906 22.4271 11.906 22.8958 12.2821L25 13.9722L27.1042 12.2821C27.5729 11.906 28.2604 11.906 28.7292 12.2821L30.8333 13.9722L32.9375 12.2821C33.3073 11.9843 33.8281 11.916 34.2708 12.1072C34.7135 12.2985 35 12.7144 35 13.1719V35.8289C35 36.2879 34.7135 36.703 34.2708 36.8934C33.8281 37.0838 33.3073 37.0155 32.9375 36.7176L30.8333 35.0281L28.7292 36.7176C28.2604 37.0936 27.5729 37.0936 27.1042 36.7176L25 35.0281L22.8958 36.7176C22.4271 37.0936 21.7396 37.0936 21.2708 36.7176L19.1667 35.0281L17.0635 36.7176C16.6927 37.0155 16.1714 37.0838 15.7276 36.8934C15.2842 36.703 15 36.2879 15 35.8289V13.1719C15 12.7144 15.2842 12.2984 15.7276 12.1072ZM20 19.0315C19.5396 19.0315 19.1667 19.3831 19.1667 19.8128C19.1667 20.2425 19.5396 20.594 20 20.594H30C30.4583 20.594 30.8333 20.2425 30.8333 19.8128C30.8333 19.3831 30.4583 19.0315 30 19.0315H20ZM20 29.9693H30C30.4583 29.9693 30.8333 29.6178 30.8333 29.1881C30.8333 28.7584 30.4583 28.4068 30 28.4068H20C19.5396 28.4068 19.1667 28.7584 19.1667 29.1881C19.1667 29.6178 19.5396 29.9693 20 29.9693ZM20 23.7191C19.5396 23.7191 19.1667 24.0707 19.1667 24.5004C19.1667 24.9301 19.5396 25.2817 20 25.2817H30C30.4583 25.2817 30.8333 24.9301 30.8333 24.5004C30.8333 24.0707 30.4583 23.7191 30 23.7191H20Z"
                fill="#A95013"
            />
            <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_136_172)" fillOpacity="0.65" />
            <defs>
                <linearGradient
                    id="paint0_linear_136_172"
                    x1="39"
                    y1="50"
                    x2="-11.5"
                    y2="15.5"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A95013" />
                    <stop offset="1" stopColor="#A95013" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default AmountIcon;
