import { Role } from '../types/Role';
import { EstablishmentType } from '../types/EstablishmentType';
import { DateFilterParam, DateFilterSort } from '../components/common/TimeFilterPicker';
import { PurchaseOrderState } from '../types/PurchaseOrderState';
import { CollectionState, ReceivableState } from '../components/finance/CollectionState';
import { FileIdentifier } from '../utilities/UploadFile';
import { TenderStatus } from '../types/Tender';
import { CollectionSourceType } from '../components/finance/CollectionSource';
import { RoleDataMap, UserType } from '../types/RoleData';
import { EstablishmentSearchParams } from '../components/establishments/layer/listing/types';
import { HubProductListingType } from '../components/establishments/layer//details/products/hub/types';

export const APPLICANT_LIST = (status, apiPageNumber, tablePageRowSize) =>
    `/onboards/?status=${status}&page=${apiPageNumber}&size=${tablePageRowSize}`;
export const APPLICANT_REJECT = uuid => `/onboards/${uuid}`;
export const APPLICANT_ONBOARD = uuid => `/onboards/${uuid}/onboard`;
export const USER_VERIFY = '/users/verify';
export const USER_LOGIN = '/users/login';
export const USER_DETAILS = (role: Role) =>
    RoleDataMap.get(role)?.userType === UserType.AGENT ? 'agents/me' : '/admins/me';
export const ESTABLISHMENT_LIST = (
    type,
    pageNumber?: number,
    pageSize?: number,
    establishmentUuids?: string | string[],
): string => {
    let basePath = `establishments/?type=${type}&active=true&page=${pageNumber ?? 0}&size=${pageSize || 10}`;
    if (establishmentUuids) {
        if (typeof establishmentUuids === 'string') {
            establishmentUuids = [establishmentUuids];
        }
        establishmentUuids.forEach(item => (basePath = basePath.concat(`&uuid=${item}`)));
    }
    return basePath;
};

export const AGGREGATE_ORDERS = (
    establishmentUuids: string[],
    orderState: string,
    fromDate: number,
    toDate: number,
    type: string,
): string => {
    let basePath = `orders/establishments/aggregate/?fromDate=${fromDate}&state=${orderState}&toDate=${toDate}&type=${type}`;
    establishmentUuids.forEach(item => (basePath = basePath.concat(`&establishment-uuid=${item}`)));
    return basePath;
};

export const ORDER_LIST = (
    establishmentUuids: string[],
    status: string,
    orderState: string,
    fromDate: number,
    toDate: number,
    type: string,
    page: number,
    size: number,
): string => {
    let basePath = `orders/establishments/?status=${status}&type=${type}&page=${page}&size=${size}`;
    if (orderState) {
        basePath += `&state=${orderState}`;
    }
    if (fromDate && toDate) {
        basePath += `&fromDate=${fromDate}&toDate=${toDate}`;
    }
    establishmentUuids.forEach(item => (basePath = basePath.concat(`&establishment-uuid=${item}`)));
    return basePath;
};

export const ORDER_ITEM_INVENTORY = (establishmentUuid: string) => `/orders/${establishmentUuid}/inventory`;
export const ORDER_DETAILS = (orderUuid: string) => `/orders/${orderUuid}`;
export const UPDATE_ORDER = (orderUuid: string) => `/orders/${orderUuid}`;

export const GET_ORDER_PAYMENT_COLLECTION_DATA = (orderUuid: string) => `orders/${orderUuid}/payment-collection-data`;
export const INITIATE_CLEARANCE = '/collections';

export const VIEW_SELLER_ORDERS = (orderUuid: string) => `/seller-orders/order/${orderUuid}`;

export const PURCHASE_ORDER_LIST = (
    state: PurchaseOrderState | undefined,
    establishmentUuids: string[],
    fromDate: number | undefined,
    toDate: number | undefined,
    page: string | number = 0,
    pageSize: string | number = 10,
    paymentStatus: TenderStatus | undefined = undefined,
): string => {

    let basePath = `/purchase-orders/?page=${page}&size=${pageSize}`;
    if (state) {
        basePath += `&state=${state.state}`;
    }
    if (fromDate !== undefined && toDate !== undefined) {
        basePath = (`${basePath}&fromDate=${fromDate}&toDate=${toDate}`);
    }
    establishmentUuids?.forEach(item => (basePath = basePath.concat(`&establishmentUuid=${item}`)));
    if (paymentStatus) {
        basePath += `&payment-status=${paymentStatus}`;
    }
    return basePath;
};

export const CREATE_PURCHASE_ORDER = '/purchase-orders/';
export const PURCHASE_ORDER_DETAILS = purchaseOrderUuid => `/purchase-orders/${purchaseOrderUuid}?version=v2`;
export const UPDATE_PURCHASE_ORDER = (purchaseOrderUuid: string) => `/purchase-orders/${purchaseOrderUuid}?version=v2`;

export const ESTABLISHMENTS = (
    establishmentType: EstablishmentType,
    page: number,
    searchParams: EstablishmentSearchParams = {},
    size: number = 6,
) => {
    let url = `/establishments/?type=${establishmentType.type}&page=${page}&size=${size}`;
    if (Object.keys(searchParams).length !== 0) {
        if (searchParams.name) {
            url += `&name=${searchParams.name}`;
        }
        if (searchParams.sort) {
            url += `&sort=${searchParams.sort},${searchParams.sortDirection}`;
        }
        if (searchParams.active?.length === 1) {
            url += `&active=${searchParams.active[0]}`;
        }
    } else {
        url += '&active=true';
    }
    return url;
};
export const APPLY_AND_ONBOARD = '/onboards/apply-and-onboard';
export const ESTABLISHMENT_UPDATE = (e_uuid: string) => `/establishments/${e_uuid}`;
export const GET_ESTABLISHMENT = (uuid: string) => `/establishments/${uuid}`;

export const SELLER_ORDERS_AGGREGATE = (establishmentUuid, state, fromDate, toDate) => {
    let url = `seller-orders/establishments/${establishmentUuid}/aggregate/?state=${state}`;
    const dateFilter = `&fromDate=${fromDate}&toDate=${toDate}`;
    if (state !== 'PLACED') {
        url += dateFilter;
    }
    return url;
};

export const SELLER_HUB_ORDER_LIST = (state, establishmentUuid, page, size, fromDate, toDate) => {
    let url = `/seller-orders/?state=${state}&establishmentUuid=${establishmentUuid}&page=${page}&size=${size}`;
    const dateFilter = `&fromDate=${fromDate}&toDate=${toDate}`;
    if (state !== 'PLACED') {
        url += dateFilter;
    }
    return url;
};

export const SELLER_HUB_ORDER_LIST_PAGE = (establishmentUuid) => `/seller-orders/establishments/${establishmentUuid}/hub-aggregate`;
export const SELLER_HUB_ORDER_APPROVAL = (establishmentUuid) => `/seller-orders/establishments/${establishmentUuid}/approval`;

export const UPDATE_SELLER_ORDER = (uuid: string) => `/seller-orders/${uuid}`;
export const DOWNLOAD_BILL = (orderUuid: string, type?: string) => `/orders/${orderUuid}/bill?type=${type}`;

export const DOWNLOAD_ORDER_DETAILS = (orderUuid: string) => `/orders/${orderUuid}/csv`;
export const DOWNLOAD_PURCHASE_ORDER_DETAILS = (purchaseOrderUuid: string) =>
    `/purchase-orders/${purchaseOrderUuid}/csv`;

export const DOWNLOAD_PURCHASE_INVOICE = (purchaseOrderUuid: string) =>
    `/purchase-orders/${purchaseOrderUuid}/bill`;

export const COLLECTION_LIST = (establishmentUuids: string[], state: CollectionState, fromDate, toDate, page, size, self?: boolean, sources?: CollectionSourceType[]): string => {
    let basePath = `/collections/?state=${state}&page=${page}&size=${size}`;
    if (!['REQUEST_FOR_COLLECTION', 'INITIATED'].includes(state)) {
        basePath = basePath.concat(`&fromDate=${fromDate}&toDate=${toDate}`);
    }
    if (establishmentUuids && establishmentUuids.length > 0) {
        establishmentUuids.forEach(item => (basePath = basePath.concat(`&establishment_uuid=${item}`)));
    }
    if (sources) {
        basePath += `&source=${sources.map(source => source).join(',')}`;
    }
    if (self) {
        basePath += `&self=${self}`;
    }
    return basePath;
};

export const COLLECTION_UPDATE = collectionUuid => `/collections/${collectionUuid}`;
export const COLLECTION_APPROVE = (collectionUuid, collectionPaymentUuid) =>
    `/sources/collection/${collectionUuid}/payments/${collectionPaymentUuid}/verify`;

export const CREATE_DEPOSIT = '/deposits/';
export const VERIFY_DEPOSIT_PAYMENT = (collectionDepositUuid: string, collectionDepositPaymentUuid: string) => `/sources/deposit/${collectionDepositUuid}/payments/${collectionDepositPaymentUuid}/verify`;

export const RECEIVABLES_LIST = (
    establishmentUuids: string[],
    state: ReceivableState,
    fromDate: number | undefined,
    toDate: number | undefined,
    dateFilterParam: DateFilterParam,
    dateSort: DateFilterSort,
    page: number,
    size: number,
) => {
    let basePath = `/credits/receivables?state=${state}&page=${page}&size=${size}`;
    if (![ReceivableState.DUE_TODAY, ReceivableState.OVERDUE].includes(state)) {
        basePath = basePath.concat(
            `&from_date=${fromDate}&to_date=${toDate}&date_filter_param=${dateFilterParam?.value}&sort=${dateFilterParam?.value},${dateSort}`,
        );
    }
    establishmentUuids.forEach(item => (basePath = basePath.concat(`&establishment_uuid=${item}`)));
    return basePath;
};

export const UPLOAD_FILE = (fileIdentifer: FileIdentifier, fileType: string) =>
    `/files/?file-identifier=${fileIdentifer}&file-type=${fileType}`;

export const LIST_BRANDS = (establishmentUuid: string, brandSearchName: string, pageNumber: number, pageSize: number) => {
    let baseUrl = `/establishments/${establishmentUuid}/products/brands?type=SOURCED`;
    if (brandSearchName) {
        baseUrl += `&name=${brandSearchName}`;
    }
    if (pageNumber !== undefined) {
        baseUrl += `&page=${pageNumber}`;
    }
    if (pageSize !== undefined) {
        baseUrl += `&size=${pageSize}`;
    }
    return baseUrl;
};


export const LIST_PRODUCTS_BY_BRAND = (
    establishmentUuid: string,
    brandId: string | undefined,
    pageNumber: number,
    pageSize: number,
) =>
    `/establishments/${establishmentUuid}/products/?brand_id=${brandId ?? ''
    }&active=true&page=${pageNumber}&size=${pageSize}`;

export const LIST_PRODUCTS_BY_NAME = (
    establishmentUuid: string,
    brandId: string | undefined,
    productName: string,
    pageNumber: number,
    pageSize: number,
) =>
    `/establishments/${establishmentUuid}/products/?brand_id=${brandId ?? ''}&name=${productName ?? ''
    }&active=true&page=${pageNumber}&size=${pageSize}`;

export const LIST_PRODUCT = (establishmentUuid: string, pageNumber: number, pageSize: number, brandId?: number | number[]) => {
    let url = `/establishments/${establishmentUuid}/products/?page=${pageNumber}&size=${pageSize}`;
    if (brandId !== undefined) {
        if (Array.isArray(brandId)) {
            (brandId as number[]).forEach((id) => {
                url += `&brand_id=${id}`;
            });
        } else {
            url += `&brand_id=${brandId}`;
        }
    }
    return url;
};
export const UPDATE_PRODUCT = (establishmentUuid, productId) =>
    `/establishments/${establishmentUuid}/products/${productId}`;
export const UPDATE_HUB_PRODUCTS = hubUuid => `/establishments/${hubUuid}/hub-products/`;

export const LIST_HUB_PRODUCTS = (hubUuid: string, brandId: string | number, hubProductListingType: HubProductListingType, sellerHuUuid: string, page, pageSize, productName?: string) => {
    let url = `establishments/${hubUuid}/products/hub?brand_id=${brandId}&type=${hubProductListingType}&seller_hub_uuid=${sellerHuUuid}&page=${page}&size=${pageSize}`;
    if (productName) {
        url += `&name=${productName}`;
    }
    return url;
};


export const CREATE_ORDER = (establishmentUuid: string) => `/orders/establishments/${establishmentUuid}/automate`;
export const EDIT_ORDER = (orderUuid) => `/orders/${orderUuid}/edit`;
export const CREATE_BULK_ORDER = (uuid: string) => `/establishments/${uuid}/products/create`;
export const DOWNLOAD_PRODUCT_SAMPLE_FILE = establishmentUuid =>
    `/establishments/${establishmentUuid}/products/bulk/sample`;

export const GET_RECEIVABLES_DATA = (establishmentUuid: string) =>
    `/credits/establishments/${establishmentUuid}/passbook?page=0&size=1`;
export const INITIATE_CREDIT_CLEARANCE = (establishmentUuid: string) =>
    `/credits/establishments/${establishmentUuid}/initiate-clearance`;
export const GET_BRANDS_DISCOUNTS = (establishmentUuid: string) => `/establishments/${establishmentUuid}/brands`;

export const DOWNLOAD_FINANCE_ORDER_REPORT = (hubUuids: string[], fromDate: number, toDate: number, filterType: string) => {

    let url = `/finance/orders?from-date=${fromDate}&to-date=${toDate}&filter-type=${filterType}`;
    if (hubUuids && hubUuids.length > 0) {
        hubUuids.forEach(uuid => {
            url = `${url}&h-uuid=${uuid}`;
        });
    }
    return url;
};
export const GET_INVENTORY_PRODUCT_LIST = (establishmentUuid, productSearchName, brandId, page, size) => {
    let url = `/establishments/${establishmentUuid}/products/inventory?active=true&type=SOURCED`;
    if (productSearchName) {
        url += `&name=${productSearchName}`;
    }
    if (brandId) {
        url += `&brand_id=${brandId}`;
    }
    if (page !== undefined) {
        url += `&page=${page}&size=${size ?? 10}`;
    }
    return url;
};

export const UPDATE_INVENTORY_PRODUCT = (establishmentUuid: string) => `/establishments/${establishmentUuid}/products/inventory`;
export const REGISTER_FIREBASE_TOKEN = '/users/register-fcm-token';

// Users Endpoint 
export const GET_USER_LIST = (role: Role, page: number, size: number, firstName?: string, establishmentUuids?: string[]) => {
    let URL = `/users/?role=${role}&page=${page}&size=${size}`;
    if (firstName) {
        URL += `&first-name=${firstName}`;
    }
    if (establishmentUuids && establishmentUuids.length > 0) {
        establishmentUuids.forEach(item => (URL += `&establishment-uuid=${item}`));
    }
    return URL;
};

export const POST_CREATE_USER = '/users/';

export const GET_USER_DETAILS = (username) => `/users/${username}`;
export const GET_USER_ESTABLISHMENT_DETAILS = (username: string, establishmentType: EstablishmentType, page: number, size: number) => `/users/${username}/establishments/${establishmentType.type}?page=${page}&size=${size}`;
export const UPATE_ESTABLISHMENT = (username) => `/users/${username}/establishments/`;
export const UPDATE_USER_DETAILS = (username) => `/users/${username}/`;

// Seller brand API
export const BRAND_LIST = (establishmentUuid: string, searchName: string | undefined, active: boolean | undefined, page: number, size: number, includeFields?: boolean) => {
    let url = `/brands/?establishment-uuid=${establishmentUuid}&page=${page}&size=${size ?? 6}`;
    if (searchName) {
        url += `&name=${searchName}`;
    }
    if (active !== undefined) {
        url += `&active=${active}`;
    }
    if (includeFields) {
        url += '&include-fields=true';
    }
    return url;
};

export const CREATE_BRAND = '/brands/';
export const UPDATE_BRAND = (brandId: number) => `/brands/${brandId}`;

export const CATEGORY_CRUD = '/categories';

// Returns
export const GET_PURCHASE_LIST = (establishmentUuid: string, pageNo: number, size: number, productName?: string, brandId?: number) => {
    let url = `/product-purchases/?establishment-uuid=${establishmentUuid}`;
    if (productName) {
        url += `&name=${productName}`;
    } if (brandId !== undefined) {
        url += `&brand-id=${brandId}`;
    } if (pageNo !== undefined) {
        url += `&page=${pageNo}&size=${size}`;
    }

    return url;
};

export const GET_PURCHASED_BRAND_LIST = (establishmentUuid: string) => `/product-purchases/brands?establishment-uuid=${establishmentUuid}`;

export const GET_RETURNABLE_LOG = (establishmentUuid: string, productId: number) => {
    let url = `/product-purchases/returnable-data?establishment-uuid=${establishmentUuid}`;

    // 0 and undefined is not allowed
    if (productId) {
        url += `&product-id=${productId}`;
    }
    return url;
};

export const CREATE_RETURN_ORDER = '/return-orders/';
export const GET_RETURN_ORDERS = ( establishmentUuids : string[], state: string, pageNumber: number, pageSize: number, fromDate?: number, toDate?: number ) : string => {
    let basePath = `/return-orders/?establishment-uuid=${establishmentUuids.join(',')}&state=${state}&page=${pageNumber}&size=${pageSize}`;
    basePath = basePath.concat(fromDate ? `&from-date=${fromDate}` : '').concat(toDate ? `&to-date=${toDate}` : '');
    return basePath;
};

export const GET_RETURN_ORDER_DETAILS = ( uuid: string ) : string => {
    return `/return-orders/${uuid}`;
};

export const GET_RETURN_ORDER_REFUNDS = ( uuid: string ) : string => {
    return `/return-orders/${uuid}/refunds`;
};

export const LOGOUT = '/users/logout';