import { Breadcrumb } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

type AppBreadcrumbProps = {
    firstElement: boolean;
    menuList: MenuList[];
    pageTitle: React.ReactNode;
};

type MenuList = {
    name: string;
};

function PageTopSection(props: AppBreadcrumbProps) {
    return (
        <>
            <Breadcrumb className="main-nav">
                {props.firstElement ? undefined : <Breadcrumb.Item>..</Breadcrumb.Item>}
                {props.menuList.map((eachMenu, index) => (
                    <Breadcrumb.Item key={index}>{eachMenu.name}</Breadcrumb.Item>
                ))}
            </Breadcrumb>

            <div className="main-title">
                <Title level={3} className='flex-align-center'>{props.pageTitle}</Title>
            </div>
        </>
    );
}

export default PageTopSection;
