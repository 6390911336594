import { Form } from 'antd';
import React, { useEffect } from 'react';
import { ResponseHandler } from '../../../http/HttpClient';
import { TenderConfig } from '../../../types/Tender';
import { DatumRenderer } from '../../../utilities/datumRenderer';
import { Type } from '../../../utilities/datumRenderer/config/type';
import { DateType, ImageType, RendererType } from '../../../utilities/datumRenderer/type';
import { FileIdentifier, FileUploadResponse, upload } from '../../../utilities/UploadFile';
import { ImageFile } from '../../common/ImageRenderer';

type CollectionModalProps = {
    displayName: string;
    name: string;
    value: any;
    type: string;
    config?: TenderConfig;
    initiateDate?: number;
    restrictDateSelecton?: boolean;
    editable?: boolean;
    updateConfig?: (config: TenderConfig, value: any) => void;
    rendererType?: RendererType
};

function CollectionModalRow(props: CollectionModalProps) {

    const [imageUrl, setImageUrl] = React.useState<FileUploadResponse>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const getConfig = (type: string) => {

        if (type === Type.DATE) {
            return {
                startDate: props.initiateDate,
                restriction: props.restrictDateSelecton,
                futureDateSelectionAbility: false,
                backDatedSelectionInDays: 4,
            } as DateType;

        } else if (type === Type.IMAGE_URL) {


            return {
                loading: loading,
                imageUrl: imageUrl,
                setImage: setImageUrl,

                uploadImage(imageFile: ImageFile) {
                    setLoading(true);
                    const responseHandler: ResponseHandler<FileUploadResponse> = {
                        onResponseSuccess(chequeImage: FileUploadResponse): void {
                            setImageUrl(chequeImage);
                            setLoading(false);

                            if (props.updateConfig && props.config) {
                                props.updateConfig(props.config, chequeImage.url);
                            }
                        },
                        onResponseFailed() {
                            setLoading(false);
                        },
                        onResponseError() {
                            setLoading(false);
                        },
                    };
                    upload('IMAGE', imageFile.file!, responseHandler, FileIdentifier.OPERATIONS);
                },

            } as ImageType;

        } else {
            return {};
        }
    };

    const getRendererType = () => {
        if (props.rendererType) {
            return props.rendererType;
        } else {
            return props.value ? RendererType.value : RendererType.component;
        }
    };

    const getValue = () => {

        return <DatumRenderer
            type={props.type}
            datumValue={props.value}
            record={null}
            config={getConfig(props.type)}
            rendererType={getRendererType()}
            onChange={(value) => {
                if (props.updateConfig && props.config) {
                    props.updateConfig(props.config, value.target.value);
                }
            }}
        />;
    };


    useEffect(() => {
        if (imageUrl === undefined) {
            // If the user removes the image after upload then we are setting its value as undefined.
            if (props.updateConfig && props.config) {
                props.updateConfig(props.config, undefined);
            }
        }
    }, [imageUrl]);
    return (
        <Form.Item className="transaction-action-modal-card-row" label={props.displayName} name={props.name}>
            {getValue()}
        </Form.Item>
    );
}

export default CollectionModalRow;
