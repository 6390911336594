import { Button, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { TbMail } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { style } from '../../../styles/css/style';
import { Path } from '../../router/Path';
import { checkInputType } from '../../../Utils';
import { InputType } from '../../../Constants';
import { USER_LOGIN } from '../../../http/EndPoints';
import { postApiCall, ResponseHandler } from '../../../http/HttpClient';
import { LoginResponse } from './types';
import { LoginPageLayout } from '../../common/templates/login';
import { updateLoginInput } from './slice';

export const Login = () => {

    const authToken = useAppSelector(currentState => currentState.auth.authToken);

    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [userLoginInput, setUserLoginInput] = useState<string>();

    const getRequestBody = () => {
        return checkInputType(userLoginInput!) === InputType.MOBILE_NUMBER ?
            {
                country_code: '+91',
                mobile_number: userLoginInput,
            } :
            {
                email: userLoginInput,
            };
    };

    const sendOtp = () => {
        setLoading(true);

        const responseHandler: ResponseHandler<LoginResponse> = {
            onResponseSuccess(value: LoginResponse) {
                message.destroy();
                message.success(value.message);
                dispatch(updateLoginInput(userLoginInput!));
                setLoading(false);
                navigate(Path.LOGIN_VERIFY);
            },
            onResponseFailed() {
                setLoading(false);
            },
            onResponseError() {
                setLoading(false);
            },
        };
        postApiCall(USER_LOGIN, getRequestBody(), responseHandler);
    };

    useEffect(() => {
        if (authToken) {
            navigate(Path.HOME);
        }
        const urlParams = new URLSearchParams(location.search);
        const forcedLogoutParam = urlParams.get('forcedLogout');
        const forcedLogout = forcedLogoutParam === 'true';
        if (forcedLogout) {
            //{ replace: true }- Once the user successfully logs out, Restrict his access to previous pages. .
            message.destroy();
            message.info('Access has been denied. Please login to continue', 3, () => { navigate(Path.LOGOUT, { replace: true }); });
        }
        const logout = urlParams.get('success');
        if (logout === 'true') {
            //{ replace: true }- Once the user successfully logs out, Restrict his access to previous pages. .
            message.destroy();
            message.success('Logged out successfully', 3, () => { navigate(Path.LOGOUT, { replace: true }); });
        }
    }, []);
    return (
        <LoginPageLayout
            title='Login'
            subTitle='Welcome back! Please enter your details.'
            body={
                <Form
                    onFinish={sendOtp}
                    layout='vertical'
                >
                    <Form.Item
                        name="email_mobile"
                        label={<label className='text-lg font-semibold'>Email / Mobile number</label>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Email / Mobile number',
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            placeholder="Enter email or mobile number"
                            prefix={<TbMail className='text-meraai-lightgrey my-2 mr-3' />}
                            onChange={(event) => {
                                setUserLoginInput(event.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} className={`${style.meraaiOrangeButton}`} htmlType="submit">
                            <b>Login</b>
                        </Button>
                    </Form.Item>
                </Form>
            }
        />
    );
};