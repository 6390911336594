import { Field, FieldElement } from '../../../../../../../orders/listing/ListingData';

export interface FieldProps {
    productFields: Field[];
    setProductFields: React.Dispatch<React.SetStateAction<Field[] | undefined>>;
    field: Field;
    triggerRule?: boolean
}

export enum ElementPropertyKey {
    KEY = 'key',
    VALUE = 'value',
    VALUE_PREFIX = 'value_prefix',
    VALUE_SUFFIX = 'value_suffix',

}
export interface FieldRecord {
    element: FieldElement;
    propertyKey: ElementPropertyKey;
}