import { EstablishmentSaveMode } from '../../../../../types/EstablishmentSaveMode';

export const breadcrumb = [{ name: 'Establishment' }, { name: 'List' }, { name: 'Create' }];

export type LayoutProperties = {
    showEstablishmentLayout?: boolean | true;
    showUserDetailsLayout?: boolean | true;
    showUserEstablishmentDetailsLayout?: boolean | true;
    showBankDetailsLayout?: boolean | true;
    showConfigDetailsLayout?: boolean | true;
    showBrandDiscounts?: boolean | true;
};

export type BankDetails = {
    account_number?: string;
    ifsc_code?: string;
    account_holder_name?: string;
};

export type SourcingEstablishments = {
    uuid?: string;
    priority?: number;
};
export type OnboardingRequest = {
    establishment_name?: string;
    agent_first_name?: string;
    agent_last_name?: string;
    latitude?: number;
    longitude?: number;
    address?: string;
    pin_code?: string;
    country_code?: string;
    mobile_number?: number;
    aadhaar_number?: number;
    pan_number?: string;
    establishment_type?: string;
    agent_type?: string;
    state_code?: string;
    gst_in?: string;
    bank_account_details?: BankDetails | {};
    sourcing_establishments: SourcingEstablishments[];
};

export interface CreateEstablishmentResponse {
    establishment_uuid: string,
    agent_uuid: string,
}

export const collapsibleKeys = {
    establishmentLayout: 'establishmentLayout',
    userLayout: 'userLayout',
    bankLayout: 'bankLayout',
    configLayout: 'configLayout',
    brandDiscountsLayout: 'brandDiscountsLayout',
};

export interface CreateEstablishmentProps {
    mode?: EstablishmentSaveMode;
    uuid?: string;
    setEstablishmentName?: React.Dispatch<React.SetStateAction<string | undefined>>;
}
