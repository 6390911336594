import React, { useState } from 'react';
import { Button, Card, Col, Image, message, Row, Tooltip } from 'antd';
import { QUANTITY_SYMBOL } from '../../../../../Constants';
import { HubWiseSellerOrderAggregate, ProductAggregate, SellerOrderAggregateApprovalResponse, SellerOrderApprovalRequestBody, SellerOrderProduct, SellerOrderStates } from '../../../../../types/SellerOrder';
import { addKeyToDataList, formatCurrency, getContactInfo } from '../../../../../Utils';
import { DetailsPage } from '../../../../common/templates/detailsPage';
import { MeraaiTable } from '../../../../common/templates/meraaiTable';
import { SellerHubOrderDetailsProps } from './types';
import { DetailsCard } from '../../../../common/detailsCard';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import { postApiCall, ResponseHandler } from '../../../../../http/HttpClient';
import { SELLER_HUB_ORDER_APPROVAL } from '../../../../../http/EndPoints';
import { layout, style } from '../../../../../styles/css/style';
import { ProductNameInfo } from '../hubsAndOutlets/utils';
import { ResponseDrawer } from '../../../../common/templates/responseDrawer';
import { SellerHubAggregateOrderDrawer } from './drawer';
import { useNavigate } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';

export const CartonRenderer = ({ quantity, orderQuantityMultiple }: { quantity: number, orderQuantityMultiple?: number }) => {
    if (orderQuantityMultiple && orderQuantityMultiple > 0) {
        const noOfCartons = Math.floor(quantity / orderQuantityMultiple);
        const noOfExtraUnits = quantity % orderQuantityMultiple;

        return (
            <Tooltip
                className={'cursor-pointer flex items-center'}
                placement='top'
                title={`Carton capacity: ${orderQuantityMultiple} Units`}
            >
                <div>
                    {QUANTITY_SYMBOL}&nbsp;{noOfCartons}{noOfExtraUnits > 0 && ` + ${noOfExtraUnits} unit(s)`}&ensp;

                    <FaInfoCircle className={`${style.infoIcon}`} />
                </div>
            </Tooltip>
        );
    } else {
        return (
            <span>NA</span>
        );
    }
};



export const SellerHubOrderDetails = (props: SellerHubOrderDetailsProps) => {

    // States
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [rejectionDrawerVisibility, setRejectionDrawerVisibility] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<HubWiseSellerOrderAggregate>();

    const [hubWiseSellerOrderAggregateList, setHubWiseSellerOrderAggregateList] = useState<SellerOrderAggregateApprovalResponse>();

    // Preview Modal states
    const [confirmationPreviewDrawerVisibility, setConfirmationPreviewDrawerVisibility] = useState<boolean>(false);

    // Response Drawer 
    const [responseDrawerVisibility, setResponseDrawerVisibility] = useState<boolean>(false);

    // Methods
    const computeTotalAmount = (): number => {
        if (selectedOrder) {
            return selectedOrder?.aggregate.products.map(product => product.price_per_unit * product.available_quantity!).reduce((totalAmount, amount) => totalAmount + amount, 0);
        } else {
            return 0;
        }
    };

    const updateSeletedOrder = (orderDetails: HubWiseSellerOrderAggregate) => {
        orderDetails?.aggregate.products.forEach(product => {
            product.available_quantity = product.quantity;
        });
        setSelectedOrder(orderDetails);
    };
    const renderApprovalButtons = (orderDetails: HubWiseSellerOrderAggregate) => {
        return <Row gutter={[12, 0]} key={orderDetails.hub.uuid} className={`${layout.flex.end} p-3`}>
            <Col className='w-1/2 sm:w-1/3 md:w-1/4 xl:w-1/6'>
                <Button
                    size='large'
                    className={`w-full ${style.meraaiDangerButton} ${layout.flex.center}`}
                    onClick={() => {
                        setRejectionDrawerVisibility(true);
                        setSelectedOrder(orderDetails);
                    }}
                >
                    Reject
                </Button>
            </Col>

            <Col className='w-1/2 sm:w-1/3 md:w-1/4 xl:w-1/6'>
                <Button
                    size='large'
                    className={`w-full ${style.meraaiOrangeButton} ${layout.flex.center}`}
                    onClick={() => {
                        setShowPreviewModal(true);
                        updateSeletedOrder(orderDetails);
                    }}
                >
                    Accept
                </Button>
            </Col>
        </Row>;
    };

    const makeApiRequestBody = (state: SellerOrderStates): SellerOrderApprovalRequestBody => {

        let requestBody: SellerOrderApprovalRequestBody = {
            hub_uuid: selectedOrder?.hub.uuid || '',
            state: state,
            uuids: selectedOrder?.uuids || [],
        };

        if (state === SellerOrderStates.ACCEPTED) {

            requestBody.products = selectedOrder?.aggregate.products.map((product): SellerOrderProduct => {

                return {
                    product_id: product.product_id,
                    quantity: product.available_quantity!,
                    total_amount: product.available_quantity! * product.price_per_unit!,
                };
            }, {});

            requestBody.total_amount = computeTotalAmount();
        }
        return requestBody;
    };

    const handleSellerHubOrderApproval = (state: SellerOrderStates) => {
        setLoading(true);
        const responseHandler: ResponseHandler<SellerOrderAggregateApprovalResponse> = {
            onResponseSuccess(response: SellerOrderAggregateApprovalResponse): void {
                setHubWiseSellerOrderAggregateList(response);
                setLoading(false);
                setConfirmationPreviewDrawerVisibility(false);
                setShowPreviewModal(false);
                setRejectionDrawerVisibility(false);
                if (response?.state == SellerOrderStates.ACCEPTED) {
                    setResponseDrawerVisibility(true);
                } else {
                    message.success(response?.message);
                    props.getHubWiseSellerOrderAggregate();
                }
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        postApiCall(SELLER_HUB_ORDER_APPROVAL(props.sellerHubUuid), makeApiRequestBody(state), responseHandler);
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image_url',
            render: image => <Image width={30} height={30} src={image} />,
        },
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            width: 200,
            render: (_, record: ProductAggregate) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'QTY',
            dataIndex: 'quantity',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: quantity => `${QUANTITY_SYMBOL} ${quantity}`,
        },
        {
            title: 'Carton(s)',
            rowVisibility: [Device.DESKTOP],
            render: (_, aggregate: ProductAggregate) => <CartonRenderer quantity={aggregate.quantity} orderQuantityMultiple={aggregate.order_quantity_multiple} />,
        },
        {
            title: 'Total Price',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            dataIndex: 'total_amount',
            render: amount => <label>{formatCurrency(amount)}</label>,
        },
    ];

    const sellerHubDetails =
    {
        title: 'Hub details',
        data: [{
            label: 'Name',
            value: props.selectedSellerOrder?.hub?.name,
        },
        {
            label: 'Address',
            value: props.selectedSellerOrder?.hub?.address,
        },
        {
            label: 'Contact',
            value: getContactInfo(props.selectedSellerOrder?.hub!),
        }],
        divider: true,
    };

    return (
        <>
            <DetailsPage
                loading={false}
                height={'FIT_TO_CONTENT'}
                body={
                    {
                        styleClass: '!p-0',
                        left: <>
                            <Card title='Items summary'>
                                <div className='max-h-[50vh] overflow-auto'>
                                    <MeraaiTable
                                        columns={columns}
                                        dataSource={addKeyToDataList(props.selectedSellerOrder?.aggregate.products!)}
                                        pagination={false}
                                    />
                                </div>
                            </Card>
                        </>,
                        right: <>
                            <div className='max-h-[50vh] overflow-auto'>
                                <DetailsCard
                                    title={
                                        <label className='text-xl font-semibold'>
                                            {sellerHubDetails.title}
                                        </label>
                                    }
                                    data={
                                        sellerHubDetails.data.map((datum) => {
                                            return {
                                                label: <label className='text-meraai-grey'>{datum.label}</label>,

                                                value: <label className='font-semibold'>
                                                    {datum.value}
                                                </label>,
                                            };
                                        })
                                    }
                                />
                            </div>
                        </>,
                    }
                }
                footer={
                    <>
                        {renderApprovalButtons(props.selectedSellerOrder!)}
                    </>
                }
                footerVisibility={true}
            />

            <SellerHubAggregateOrderDrawer
                loading={loading}
                selectedOrder={selectedOrder!}
                setSelectedOrder={setSelectedOrder}
                computeTotalAmount={computeTotalAmount}
                sellerOrderDrawerVisibility={showPreviewModal}
                setSellerOrderItemDrawerVisibility={setShowPreviewModal}
                confirmationPreviewDrawerVisibility={confirmationPreviewDrawerVisibility}
                setConfirmationPreviewDrawerVisibility={setConfirmationPreviewDrawerVisibility}
                handleSellerHubOrderApproval={handleSellerHubOrderApproval}
                rejectionDrawerVisibility={rejectionDrawerVisibility}
                setRejectionDrawerVisibility={setRejectionDrawerVisibility}
            />

            <ResponseDrawer
                type={'SUCCESS'}
                message={hubWiseSellerOrderAggregateList?.message}
                open={responseDrawerVisibility}
                onClose={() => {
                    setResponseDrawerVisibility(false);
                    props.getHubWiseSellerOrderAggregate();
                }}
                body={
                    <Button
                        className={`m-2 w-1/5 ${style.meraaiOrangeButton}`}
                        onClick={() => {
                            navigate(`/purchase-order/${hubWiseSellerOrderAggregateList?.purchase_order_uuid}`);
                        }}
                    >
                        View Details
                    </Button>
                }
            />
        </>
    );
}; 