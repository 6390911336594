import React from 'react';
import { Button, Collapse, Drawer, Image, Spin } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { FcExpand } from 'react-icons/fc';
import { ESTABLISHMENT_UPDATE } from '../../../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../../../http/HttpClient';
import { layout, style } from '../../../../styles/css/style';
import { ResponseMessage } from '../../../../Types';
import { UpdateProperties } from '../../../../types/Field';
import { MeraaiTable } from '../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../common/templates/meraaiTable/types';
import { ResponseDrawer } from '../../../common/templates/responseDrawer';
import { BrandDiscountProperty } from '../layouts/brandDiscounts/types';
import { BrandDiscountPercentageListUpdateRequest, EstablishmentUpdatePreviewDrawerProps } from './types';


function EstablishmentUpdatePreviewDrawer(props: EstablishmentUpdatePreviewDrawerProps) {
    const {
        handleCancel,
        getEstablishmentAndSetInitialProperties,
        establishmentProperties,
        userEstablishmentProperties,
        bankAccountProperties,
        configProperties,
        brandsDiscountList,
        isDrawerVisible,
        setIsDrawerVisible,
        uuid,
    } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [responseMessage, setResponseMessage] = React.useState<string>();
    const addToChangedProperties = (properties, changedProperties) => {
        Object.keys(properties)
            .filter(key => properties[key]?.changed)
            .forEach(key => changedProperties.push(properties[key]));
    };
    const valuesTextColor = {
        initialValue: 'red-text',
        value: 'green-text',
    };

    const getChangedProperties = () => {
        let changedProperties: Array<any> = [];
        addToChangedProperties(establishmentProperties, changedProperties);
        addToChangedProperties(userEstablishmentProperties, changedProperties);
        addToChangedProperties(bankAccountProperties, changedProperties);
        addToChangedProperties(configProperties, changedProperties);
        return changedProperties;
    };

    const getBrandDiscountsChangeList = (): BrandDiscountProperty[] => {
        return brandsDiscountList.filter(
            item =>
                item.discount_percentage.value != undefined &&
                item.discount_percentage.initialValue != item.discount_percentage.value,
        );
    };

    const getValue = (record, value) => {
        if (record[value] && typeof record[value] === 'object' && 'url' in record[value]) {
            return <Image src={record[value].url} preview width="25%" height="25%" />;
        }
        if (record[value] && typeof record[value] === 'object' && 'key' in record[value]) {
            return <span className={valuesTextColor[value]}>{record[value].val}</span>;
        }

        return <span className={valuesTextColor[value]}>{record[value]}</span>;
    };

    const orderUpdateModalPreviewcolumns: MeraaiTableColumnType[] = [
        {
            title: 'Name',
            key: 'name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record) => <span>{record?.updateProperties?.text}</span>,
        },
        {
            title: 'Previous value',
            key: 'previousValue',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record) => getValue(record, 'initialValue'),
        },
        {
            title: 'Updated value',
            key: 'updatedValue',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record) => getValue(record, 'value'),
        },
    ];

    const brandListUpdateModalPreviewcolumns: MeraaiTableColumnType[] = [
        {
            title: 'Brand Name',
            key: 'brand_name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record) => <span>{record?.name}</span>,
        },
        {
            title: 'Previous %',
            key: 'previousValue',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record) => (
                <span className={valuesTextColor.initialValue}>{record?.discount_percentage?.initialValue}</span>
            ),
        },
        {
            title: 'Updated %',
            key: 'updatedValue',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (_, record) => (
                <span className={valuesTextColor.value}>{record?.discount_percentage?.value}</span>
            ),
        },
    ];

    const updateEstablishmentProperties = () => {
        const changedProperties = getChangedProperties();
        const changedBrandsDiscountList = getBrandDiscountsChangeList();

        setLoading(true);

        let request = {};
        let formData = new FormData();
        if (changedProperties.length || changedBrandsDiscountList.length > 0) {
            changedProperties.forEach(property => {
                const updateProperties = property.updateProperties as UpdateProperties;
                if (!updateProperties) {
                    return;
                }
                if (property.value && typeof property.value === 'object' && 'url' in property.value) {
                    formData.append(
                        updateProperties.requestKey,
                        new Blob([property.value.file as any]),
                        property.value.name,
                    );
                } else if (!updateProperties.groupingKey) {
                    request[updateProperties.requestKey] = property.value;
                } else {
                    if (!request[updateProperties.groupingKey]) {
                        request[updateProperties.groupingKey] = {};
                    }
                    request[updateProperties.groupingKey][updateProperties.requestKey] = property.value;
                }
            });

            if (request.hasOwnProperty('sourcing_establishments')) {
                const seUuid = request['sourcing_establishments']['key'];
                request['sourcing_establishments'] = [
                    {
                        uuid: seUuid,
                        priority: 1,
                    },
                ];
            }

            if (changedBrandsDiscountList.length > 0) {
                const requestParams = changedBrandsDiscountList.map(item => {
                    return {
                        brand_id: item.id,
                        discount_percentage: item.discount_percentage.value,
                    } as BrandDiscountPercentageListUpdateRequest;
                });
                request['brand_discounts'] = requestParams;
            }

            const requestString = JSON.stringify(request);

            formData.append(
                'request',
                new Blob([requestString], {
                    type: 'application/json',
                }),
            );
        }

        const endPoint = ESTABLISHMENT_UPDATE(uuid!);
        const responseHandler: ResponseHandler<ResponseMessage> = {
            onResponseSuccess(response: ResponseMessage): void {
                setResponseMessage(response.message);
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        putApiCall(endPoint, formData, responseHandler);
    };

    return (

        <>
            <Drawer
                className='meraai-drawer'
                open={isDrawerVisible}
                title={<h3>Updated fields</h3>}

                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    handleCancel();
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}

                footer={
                    <div className={`${layout.flex.end}`}>
                        <Button
                            loading={loading}
                            className={`w-full xs:w-1/2 md:w-1/3 lg:w-1/4 ${style.meraaiOrangeButton}`}
                            onClick={() => {
                                updateEstablishmentProperties();
                            }}>
                            Confirm
                        </Button>
                    </div>

                }
            >
                <Spin className='overflow-auto' spinning={loading} tip='Loading...'>
                    {(getChangedProperties().length > 0 || getBrandDiscountsChangeList().length == 0) && (
                        <MeraaiTable
                            className='mb-10'
                            columns={orderUpdateModalPreviewcolumns}
                            dataSource={getChangedProperties()}
                            pagination={false}
                        />
                    )}
                    {getBrandDiscountsChangeList().length > 0 && (
                        <Collapse
                            style={{ width: '100%' }}
                            expandIconPosition="end"
                            expandIcon={FcExpand}
                            accordion
                            defaultActiveKey='brandDiscounts'>
                            <Collapse.Panel header="Brand Discounts" key='brandDiscounts' forceRender>
                                <MeraaiTable
                                    columns={brandListUpdateModalPreviewcolumns}
                                    dataSource={getBrandDiscountsChangeList()}
                                    pagination={false}
                                />
                            </Collapse.Panel>
                        </Collapse>
                    )}
                </Spin>
            </Drawer>

            <ResponseDrawer
                type={'SUCCESS'}
                message={responseMessage}
                open={responseMessage !== undefined}
                onClose={() => {
                    setResponseMessage(undefined);
                    setIsDrawerVisible(false);
                    getEstablishmentAndSetInitialProperties(uuid!);
                }}
            />
        </>

    );
}

export default EstablishmentUpdatePreviewDrawer;
