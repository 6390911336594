import { Button, Col, Row, Select, Switch } from 'antd';
import lodash from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductProperties } from '../../properties/ProductProperties.';
import { v4 as uuidv4 } from 'uuid';
import { initializeListProductProperties } from './utils';
import { CreateProducts } from '../create';
import { SellerHubProductProps } from './types';
import { ProductPropertyDrawer } from '../fieldsDrawer';
import { getApiCall, putApiCall, ResponseHandler } from '../../../../../../../http/HttpClient';
import { Metadata, ProductListResponse } from '../../../../../../../types/Product';
import { Brand, BrandListResponse, Field, FieldElement, FieldVisibility } from '../../../../../../orders/listing/ListingData';
import { BRAND_LIST, LIST_PRODUCT, UPDATE_PRODUCT } from '../../../../../../../http/EndPoints';
import { Type } from '../../../../../../../utilities/datumRenderer/config/type';
import { UpdateProperties, UpdateRequestGroupingKey } from '../../../../../../common/templates/meraaiTable/EditableField';
import { OpenNotification } from '../../../../../../common/notifications/Notifications';
import { ResponseMessage } from '../../../../../../../Types';
import { Device, MeraaiTableColumnType } from '../../../../../../common/templates/meraaiTable/types';
import { MandatoryTitle, OptionalTitle } from '../../../../../../common/TableTitle';
import { isArrayEmpty, MeraaiLabel } from '../../../../../../../Utils';
import ImageRenderer, { ImageFile, UploaderTypes } from '../../../../../../common/ImageRenderer';
import CloseableImage from '../../../../../../common/CloseableImage';
import { SelectCategoryList } from '../../../../../../common/selectCategory';
import { layout, style } from '../../../../../../../styles/css/style';
import { ListingPage } from '../../../../../../common/templates/listingPage';
import { SelectBrandList } from '../../../../../../brands';
import { ResponseDrawer } from '../../../../../../common/templates/responseDrawer';
import { MeraaiTable } from '../../../../../../common/templates/meraaiTable';
import { DataNotFound } from '../../../../../../common/templates/dataNotFound';
import { ProductMetadataDrawer } from '../metadataDrawer';

export const SellerHubProduct = (props: SellerHubProductProps) => {
    // establishment ID form URL
    const { establishmentUuid } = useParams();

    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    // State variables
    const firstRender = useRef<boolean>(true);

    // Loading & pagination
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(20);

    // Product
    const [productResponse, serProductResponse] = useState<ProductListResponse>();
    const [productList, setProductList] = useState<ProductProperties[]>([]);
    const [selectedField, setSelectedField] = useState<Field[]>();
    const [selectedProductId, setSelectedProductId] = useState<number>();
    const [selectedMetadata, setSelectedMetadata] = useState<Metadata>();
    const [productPropertyDrawerVisibility, setProductPropertyDrawerVisibility] = useState<boolean>(false);
    const [responseDetails, setResponseDetails] = useState<{
        type: 'SUCCESS' | 'FAILURE' | 'WARNING' | 'INFO';
        message: string,
    }>();
    const [productCreatedFlag, setProductCreatedFlag] = useState<boolean>(false);
    const [metadataDrawerVisibility, setMetadataDrawerVisibility] = useState<boolean>(false);

    // Brand
    const [selectedBrand, setSelectedBrand] = useState<Brand>();
    const [brandList, setBrandList] = useState<Brand[]>();

    // Category 
    const [selectedCategories, setSelectedCategories] = useState<number[]>();

    // Create products
    const [createProductsVisibility, setCreateProductsVisibility] = useState<boolean>(false);

    // Methods

    // URL params
    const setStateFromQueryParams = (queryParam, param, stateSetter) => {
        if (!queryParam.has(param)) {
            return;
        } else if (['page', 'pageSize'].includes(param)) {
            const values = parseInt(queryParam.get(param));
            if (values) {
                stateSetter(values);
            }
        } else if (param == 'brand') {
            const brandId = parseInt(queryParam.get(param));
            stateSetter({ ...selectedBrand, id: brandId });
        } else {
            stateSetter(queryParam.get(param));
        }
    };

    const setQueryParamsFromState = (queryParam, param, state) => {
        if (state) {
            queryParam.set(param, state);
        } else {
            queryParam.delete(param);
        }
    };

    const setStateFromUrlParams = () => {
        setStateFromQueryParams(queryParams, 'brand', setSelectedBrand);
        setStateFromQueryParams(queryParams, 'page', setPage);
        setStateFromQueryParams(queryParams, 'size', setSize);
    };

    const setUrlParamsFromState = () => {
        setQueryParamsFromState(queryParams, 'brand', selectedBrand?.id);
        setQueryParamsFromState(queryParams, 'page', page);
        setQueryParamsFromState(queryParams, 'size', size);

        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    /** get brand list {{API CALL}} */
    const getBrandList = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<BrandListResponse> = {
            onResponseSuccess(response: BrandListResponse): void {
                setBrandList(response.brands);
            },
            onResponseFailed(errors: string[]) {
                setResponseDetails({ message: errors[0], type: 'WARNING' });
                setLoading(false);
            },
            onResponseError() {
                setResponseDetails({ message: 'Something went wrong!', type: 'WARNING' });
                setLoading(false);
            },
        };

        getApiCall(BRAND_LIST(establishmentUuid!, undefined, undefined, 0, 100, true), responseHandler);
    };

    const constructProductFields = (selectedProductFieldMap: Map<string, Field>, selectedBrandFields: Field[]): Field[] => {

        /**
         * Pushing product fields into brand fields because 
         * product fields can have some additional manually added fields 
         * which brand fields will not have. 
         */
        selectedProductFieldMap.forEach(function (productField, productFieldId) {
            const brandFieldValue = selectedBrandFields.find(brandField => brandField.id === productFieldId);
            if (!brandFieldValue) {
                selectedBrandFields.push({ ...productField });
            } else {
                productField.elementMap?.forEach((productElement, productElementId) => {
                    const brandElementValue = brandFieldValue.elements?.find(brandElement => brandElement.id === productElementId);
                    if (!brandElementValue) {
                        brandFieldValue.elements?.push({ ...productElement });
                    }
                });
            }
        });


        const finalFields = selectedBrandFields.map(field => {

            if (selectedProductFieldMap.has(field.id)) {

                const productField = selectedProductFieldMap.get(field.id);
                const finalElements = field.elements?.map((element) => {

                    const productFieldElement = productField?.elementMap?.get(element.id);
                    // value_prefix
                    if (productFieldElement?.value_prefix) {
                        if (!element.value_prefix_options?.includes(productFieldElement?.value_prefix)) {
                            element.value_prefix_options?.push(productFieldElement?.value_prefix);
                        }
                        element['value_prefix'] = productFieldElement?.value_prefix;
                        element.valid_prefix = true;
                    }
                    // value_suffix
                    if (productFieldElement?.value_suffix) {
                        if (!element.value_suffix_options?.includes(productFieldElement?.value_suffix)) {
                            element.value_suffix_options?.push(productFieldElement?.value_suffix);
                        }
                        element['value_suffix'] = productFieldElement?.value_suffix;
                        element.valid_suffix = true;
                    }
                    // value
                    if (productFieldElement?.value) {
                        element['value'] = productFieldElement?.value;
                        element.valid_value = true;
                    }

                    if (element.value_type === Type.OPTIONS && element.value_options) {

                        if (!element.value_options?.includes(productFieldElement?.value!)) {
                            element.value_suffix_options?.push(productFieldElement?.value!);
                        }
                    }

                    if (!field.mandatory) {
                        element.valid_value = true;
                    }
                    // key
                    if (productFieldElement?.key) {
                        element.valid_key = true;
                    }

                    return element;
                });

                return { ...field, elements: finalElements };
            } else {
                return { ...field };
            }
        });

        return finalFields;
    };
    const constructProductFieldsMap = (fieldList: Field[]): Map<string, Field> => {

        return new Map<string, Field>(
            fieldList?.map(field => [
                field.id,
                {
                    ...field,
                    elementMap: new Map<string, FieldElement>(
                        field.elements?.map(element => [

                            element.id,
                            element,
                        ]),
                    ),
                },
            ]),
        );
    };

    /** Update record */
    const updateValue = (property: keyof ProductProperties, value: any, key: number) => {
        const product = productList!.find(productProperty => productProperty.id?.value === key);
        product![property]!['value'] = value;
        setProductList([...productList]);
    };

    /** Check if any record has been update */
    const hasRecordChanged = (record: ProductProperties) => {
        return ProductProperties.getFilteredProperties!(record).some(item => item.isValueChanged!());
    };

    /** Create request body */
    const makeUpdateRequest = (record: ProductProperties) => {
        const formData = new FormData();
        const request = {};
        ProductProperties.getFilteredProperties!(record).forEach(field => {
            if (field.isValueChanged!()) {
                const { updateProperties } = field;

                if (updateProperties?.groupingKey) {
                    if (updateProperties?.groupingKey === UpdateRequestGroupingKey.TAXES) {
                        if (!request[updateProperties?.groupingKey]) {
                            request[updateProperties?.groupingKey] = [];
                        }
                        request[updateProperties?.groupingKey].push({
                            type: updateProperties?.requestKey,
                            percentage: field.value,
                        });
                    }
                } else if (updateProperties?.requestKey === 'image') {
                    formData.append(updateProperties?.requestKey, new Blob([field.value?.imageFile?.file as any]), field.value?.imageFile?.name);
                } else if (updateProperties?.requestKey === 'categories') {

                    // If initialValue is not present in value that means it has been removed.
                    const REMOVE = field.initialValue.filter((categoryId) => !field.value.includes(categoryId));

                    // If value is not present in initialValue that means it has been added.
                    const ADD = field.value.filter((categoryId) => !field.initialValue.includes(categoryId));

                    request[updateProperties?.requestKey] = [...REMOVE, ...ADD]?.map((categoryId) => {
                        return {
                            category_id: categoryId,
                            operation: ADD.includes(categoryId) ? 'ADD' : 'REMOVE',
                        };
                    });

                } else {
                    //This check is to signify that if orderQuantityMultiple (carton_capacity) is emptied, then
                    //send it in the data as 0. On the backend side if orderQuantityMultiple comes in as 0, it is marked
                    //as null
                    if (updateProperties?.requestKey === 'order_quantity_multiple' && field.value?.trim() === '') {
                        request[updateProperties?.requestKey] = 0;
                    } else {
                        request[updateProperties?.requestKey!] = field.value ?? null;
                    }
                }
            }
        });
        const requestBody = JSON.stringify(request);

        formData.append(
            'request',
            new Blob([requestBody], {
                type: 'application/json',
            }),
        );

        return formData;
    };

    /** Update product property {{API CALL}} */
    const performUpdateProduct = (product: ProductProperties) => {
        if (!ProductProperties.getFilteredProperties!(product).every(property => property.isValueAcceptable!())) {
            OpenNotification('Cannot Proceed', 'Please check the highlighted values.', 'error');
            return;
        }

        setLoading(true);

        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess: (response: ResponseMessage): void => {
                setResponseDetails({ message: response.message, type: 'SUCCESS' });
                ProductProperties.getFilteredProperties!(product).forEach(property => {
                    property.setInitialValue!(property.value, property.updateProperties);
                });
                setProductList([...productList]);
                setLoading(false);
            },
            onResponseFailed: () => {
                setLoading(false);
            },
            onResponseError: () => {
                setLoading(false);
            },
        };
        putApiCall(UPDATE_PRODUCT(establishmentUuid, product.id?.value), makeUpdateRequest(product), responseHandler);
    };

    const getProductList = () => {
        setLoading(true);
        const responseHandler: ResponseHandler<ProductListResponse> = {
            onResponseSuccess: (response: ProductListResponse) => {
                serProductResponse(response);
                const productListData = response?.products.map(productItem => {
                    const product = initializeListProductProperties();
                    product.setSellerProductListValues!(productItem);
                    return product;
                });
                setProductList(productListData!);
                setUrlParamsFromState();
                setLoading(false);
            },
            onResponseFailed: () => {
                setLoading(false);
            },
            onResponseError: () => {
                setLoading(false);
                // Handled globally
            },
        };

        getApiCall(LIST_PRODUCT(establishmentUuid!, page - 1, size, selectedBrand?.id), responseHandler);
    };

    /** Table column variable */
    const columns: MeraaiTableColumnType[] = [
        {
            title: <MandatoryTitle title='Name' />,
            dataIndex: 'name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: name => <MeraaiLabel>{name.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='SKU' />,
            dataIndex: 'sku',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: sku => <MeraaiLabel>{sku.value}</MeraaiLabel>,
        },
        {
            title: (
                <div className="flex-row-sb-center">
                    <MandatoryTitle title='HSN' />
                </div>
            ),
            dataIndex: 'hsn',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: hsn => <MeraaiLabel>{hsn.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='Barcode' />,
            dataIndex: 'ean',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: ean => <MeraaiLabel>{ean.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='Price' />,
            dataIndex: 'price',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: price => <MeraaiLabel>{price.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='MRP' />,
            dataIndex: 'mrp',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: mrp => <MeraaiLabel>{mrp.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='GST' />,
            dataIndex: 'gst',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: gst => <MeraaiLabel>{gst.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='CESS' />,
            dataIndex: 'cess',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: cess => <MeraaiLabel>{cess.value}</MeraaiLabel>,
        },
        {
            title: <OptionalTitle title='Carton Capacity' description='No. of units that would be sent in a carton. 0 or empty would mean, carton size has not been standardized' />,
            dataIndex: 'orderQuantityMultiple',
            rowVisibility: [Device.DESKTOP],
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: orderQuantityMultiple => <MeraaiLabel>{orderQuantityMultiple.value}</MeraaiLabel>,
        },
        {
            title: <MandatoryTitle title='Brand' />,
            dataIndex: 'brand',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render(brand, record: ProductProperties) {
                const sortedBrandList = brandList?.sort((firstValue, secondValue) => Number(secondValue.active) - Number(firstValue.active));

                return <Select
                    className='w-full'
                    placeholder='Select Brand'
                    defaultValue={brand?.value}
                    onChange={(brandName) => {
                        updateValue('brand', brandName, record.id?.value!);
                        updateValue('fields', undefined, record.id?.value!);
                    }}>
                    {
                        sortedBrandList?.map((eachBrand: Brand) => {
                            return <Select.Option disabled={!eachBrand.active} key={eachBrand.id} value={eachBrand.name}>
                                {eachBrand.name}
                            </Select.Option>;
                        })
                    }
                </Select>;
            },
        },
        {
            title: <MandatoryTitle title='Image' />,
            dataIndex: 'image',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render: (image, record: ProductProperties) => (
                <div className="image-uploader-small">
                    <ImageRenderer
                        size='middle'
                        uploadText={image.isValueAcceptable!() ? 'Replace' : 'Upload'}
                        styleClass={`mr-5 ${image.isValueAcceptable!() ? '' : 'border border-solid border-red-500 rounded-lg'}`}
                        setFileState={(file: ImageFile) => {
                            updateValue('image', { imageFile: file }, record.id?.value!);
                        }}
                        type={UploaderTypes.UPLOAD_PNG}
                    />
                    <CloseableImage
                        src={record.image.value?.imageFile?.url}
                        onClose={() => {
                            updateValue('image', { imageFile: new ImageFile() }, record.id?.value!);
                        }}
                        width={50}
                        height={50}
                    />
                </div>
            ),
        },
        {
            title: <MandatoryTitle title='Category' />,
            dataIndex: 'categories',
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render(categories, record: ProductProperties) {
                const updateCategoryValueAndSetState = (categoryIdList) => {
                    updateValue('categories', categoryIdList, record.id?.value!);
                    setSelectedCategories(categoryIdList);
                };

                return <SelectCategoryList
                    status={`${categories.isValueAcceptable!() ? '' : 'error'}`}
                    virtual={false}
                    mode='multiple'
                    className='w-full'
                    maxTagCount={1}
                    maxTagTextLength={7}
                    value={categories.value}
                    setSelectedCategories={setSelectedCategories}
                    onChange={(categoryIdList) => {
                        updateCategoryValueAndSetState(categoryIdList);
                    }}
                    onOk={() => {
                        /** Called when the user clicks the save button */
                        updateValue('categories', selectedCategories, record.id?.value!);
                    }}
                />;
            },
        },
        {
            title: 'Active',
            dataIndex: 'active',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            expandedRowProps: {
                valueFunction: 'RENDER',
            },
            render: (active, record) => <>
                <Switch
                    className={`bg-meraai-orange ${layout.flex.center}`}
                    checked={active.value}
                    onChange={(value) => {
                        updateValue('active', value, record.id?.value!);
                        performUpdateProduct(record);
                    }}
                />
            </>,
        },
        {
            dataIndex: 'info',
            actionable: true,
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render: (__, record: ProductProperties) => {
                // Finding the currently selected brand for the product row on which the user has just clicked.
                const brandFields = brandList?.find(brand => brand.name == record.brand.value)?.fields ?? [{
                    id: uuidv4(),
                    visibility: [FieldVisibility.DETAILED],
                    mandatory: true,
                    elements: [{
                        id: uuidv4(),
                        key: undefined,
                        value: undefined,
                        value_type: Type.STRING,
                        deletable: true,
                    }],
                }];
                const productFields = record.fields?.value;
                const productId = record.id?.value;

                return <Button
                    className={`w-full ${style.meraaiPlainButton}`}
                    onClick={() => {
                        if (isArrayEmpty(productFields)) {
                            setSelectedField(lodash.cloneDeep(brandFields));

                        } else if (!record.fields?.initialValueFinalized) {
                            const finalFieldList = constructProductFields(
                                constructProductFieldsMap(lodash.cloneDeep(productFields!)),
                                lodash.cloneDeep(brandFields),
                            );

                            if (record.fields?.setInitialValue) {
                                record.fields?.setInitialValue(finalFieldList, new UpdateProperties('Info', 'fields', null), undefined, true);
                            }
                            setSelectedField(finalFieldList);
                        } else {
                            setSelectedField(productFields);
                        }

                        // Will use `selectedProductId` to fetch or update the details of the selected product property. 
                        setSelectedProductId(productId);
                        setProductPropertyDrawerVisibility(true);
                    }}
                >
                    {isArrayEmpty(productFields) ? 'Add more info' : 'Modify info'}
                </Button>;
            }
            ,
        },
        {
            title: <OptionalTitle title='Info' description='The information present here is shown on the app' />,
            dataIndex: 'info',
            actionable: true,
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'RENDER',
            },
            render: (_, record: ProductProperties) => {

                const productMetadata = record.metadata?.value;
                const productId = record.id?.value;

                return <Button
                    className={`w-full ${style.meraaiPlainButton}`}
                    onClick={() => {

                        // Will use `selectedProductId` to fetch or update the details of the selected product property.
                        setSelectedProductId(productId);
                        setSelectedMetadata(productMetadata);
                        setMetadataDrawerVisibility(true);
                    }}
                >
                    {Object.values(productMetadata ?? {}).every(metadatum => !metadatum) ? 'Add metadata' : 'Modify metadata'}
                </Button>;
            },
        },
        {
            dataIndex: 'action',
            actionable: true,
            expandedRowProps: {
                visibility: 'ALWAYS',
                valueFunction: 'COMPONENT',
            },
            render: (_, record: ProductProperties) => <>
                <Button
                    className={`w-full ${style.meraaiOrangeButton}`}
                    disabled={!hasRecordChanged(record)}
                    onClick={() => performUpdateProduct(record)}
                >
                    Update
                </Button>
            </>,
        },
    ];


    // UseEffects
    useEffect(() => {
        getBrandList();
    }, []);

    useEffect(() => {
        if (brandList) {
            getProductList();
        }
    }, [brandList]);

    useEffect(() => {
        if (firstRender.current) {
            if (queryParams.get('brand')) {
                setStateFromUrlParams();
            }
            firstRender.current = false;
            return;
        }

        getProductList();

    }, [page]);

    return (
        <>

            <ListingPage
                hideBreadcrumb
                topRowStyleClass='!p-0'
                header={
                    <Row gutter={[12, 12]}>
                        <Col className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'>
                            {/* Brand filter for seller hub product listing*/}
                            <SelectBrandList
                                maxTagCount={1}
                                showArrow
                                showSearch={false}
                                eUuid={establishmentUuid!}
                                selectedBrand={selectedBrand!}
                                setSelectedBrand={setSelectedBrand}
                                className='w-full'
                                disabled={establishmentUuid === undefined}
                            />
                        </Col>
                        <Col className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'>
                            {/* Brand filter for seller hub product listing*/}
                            <Button
                                className={`${style.meraaiPlainButton}`}
                                disabled={selectedBrand === undefined}
                                onClick={() => {
                                    getProductList();
                                }}
                            >
                                Show products
                            </Button>
                        </Col>
                        {
                            props.config.products.sellerHub.allowProductCreation && <Col className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 sm:absolute sm:right-0'>
                                {/* Add product Drawer */}
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    onClick={() => {
                                        setCreateProductsVisibility(true);
                                    }}
                                >
                                    Add Products
                                </Button>
                            </Col>
                        }

                    </Row>
                }
                body={
                    isArrayEmpty(productList) ?

                        <DataNotFound loading={loading} title={responseDetails?.message ?? 'No products found.'} />

                        :

                        <div className='seller-hub-product-table'>
                            <MeraaiTable
                                expandableRowModificationType='EDIT'
                                loading={loading}
                                columns={columns}
                                dataSource={productList}
                                setDataSource={setProductList}
                                pagination={{
                                    pageSize: size,
                                    current: page,
                                    total: productResponse?.page_count! * size,
                                    onChange: (pageNo, pageSize) => {
                                        setPage(pageNo);
                                        setSize(pageSize);
                                    },
                                }}
                            />
                        </div>
                }
            />

            {/* Add/Modify product properties drawer */}
            <ProductPropertyDrawer
                /**
                 * selectedProductId : Since we are using updateValue method in
                 * ProductPropertyModal, we need this id to update the dataSource state
                 * when user clicks on the confirm button.
                **/
                selectedProductId={selectedProductId!}
                selectedProductFields={selectedField}
                updateValue={updateValue!}
                productPropertyDrawerVisibility={productPropertyDrawerVisibility}
                setProductPropertyDrawerVisibility={setProductPropertyDrawerVisibility}
            />

            <CreateProducts
                open={createProductsVisibility}
                setProductCreatedFlag={setProductCreatedFlag}
                onClose={() => {
                    setCreateProductsVisibility(false);
                    if (productCreatedFlag) {
                        getProductList();
                        setProductCreatedFlag(false);
                    }
                }}
                brandList={brandList}
            />

            <ProductMetadataDrawer
                open={metadataDrawerVisibility}
                productMetadata={selectedMetadata}
                onClose={() => {
                    setMetadataDrawerVisibility(false);
                    setSelectedProductId(undefined);
                    setSelectedMetadata(undefined);
                }}
                onProceed={(values) => {
                    setMetadataDrawerVisibility(false);
                    updateValue('metadata', values, selectedProductId!);
                }}
            />


            <ResponseDrawer
                type={responseDetails?.type!}
                message={responseDetails?.message}
                open={responseDetails !== undefined}
                onClose={() => {
                    setResponseDetails(undefined);
                    setSelectedProductId(undefined);
                    setSelectedMetadata(undefined);
                }}
            />
        </>
    );
};
