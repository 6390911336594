import * as React from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import ImageRenderer, { ImageFile, imageValidationRule, UploaderTypes } from '../../../../common/ImageRenderer';
import CloseableImage from '../../../../common/CloseableImage';
import { UserLayoutProps } from './types';
import { UserProperties } from './utils';
import { DetailsCard } from '../../../../common/detailsCard';
import { DetailsCardProps } from '../../../../common/detailsCard/types';


function UserLayout(props: UserLayoutProps) {
    const { userProperties: userProp, setUserProperties: setUserProp, form } = props;

    const changeValue = (property: keyof UserProperties, value: any) => {
        const updatedEstP = {
            ...userProp,
            [property]: { ...userProp[property], value },
        };
        setUserProp(updatedEstP);
    };

    // Variables
    const userDetails: DetailsCardProps =
    {
        data: [{
            show: userProp.firstName?.show && userProp.lastName?.show,
            label: 'Name',
            value: <Row gutter={[12, 0]}>
                <Col className='w-full sm:w-1/2'>
                    <Form.Item
                        name="firstName"
                        rules={[
                            {
                                required: userProp.firstName?.required,
                                message: 'Please enter the first name',
                            },
                        ]}>
                        <Input
                            type="text"
                            placeholder="First Name"
                            value={userProp.firstName?.value}
                            disabled={userProp.firstName?.disabled}
                            onChange={event => changeValue('firstName', event.currentTarget.value)}
                        />
                    </Form.Item>
                </Col>
                <Col className='w-full sm:w-1/2'>
                    <Form.Item
                        name="lastName"
                        rules={[
                            {
                                required: userProp.lastName?.required,
                                message: 'Please enter the last name',
                            },
                        ]}>
                        <Input
                            type="text"
                            placeholder="Last Name"
                            value={userProp.lastName?.value}
                            disabled={userProp.lastName?.disabled}
                            onChange={event => changeValue('lastName', event.currentTarget.value)}
                        />
                    </Form.Item>
                </Col>
            </Row>,
        },
        {
            show: userProp.mobileNumber?.show,
            label: 'Mobile number',
            value: <Form.Item
                name="mobileNumber"
                rules={[
                    {
                        required: userProp.mobileNumber?.required,
                        message: 'Please enter the mobile number',
                    },
                    {
                        pattern: /^[6-9]\d{9}$/gi,
                        message: 'Mobile number is not valid',
                    },
                ]}>
                <InputNumber
                    placeholder="Enter mobile number"
                    maxLength={10}
                    disabled={userProp.lastName?.disabled}
                    style={{ width: '100%' }}
                    controls={false}
                    onChange={value => {
                        changeValue('mobileNumber', value);
                    }}
                />
            </Form.Item>,
        },
        {
            show: userProp.userImage?.show,
            label: <>
                <span className='font-semibold'>User's Picture</span>
                {userProp.userImage?.value && (
                    <CloseableImage
                        closeable={!userProp.userImage?.disabled}
                        styleClass="padding-top-10px"
                        src={userProp.userImage.value?.url}
                        onClose={() => changeValue('userImage', undefined)}
                    />
                )}
            </>,
            value: <Form.Item
                name="userImage"
                rules={[() => imageValidationRule(userProp.userImage?.required!, userProp.userImage?.value!)]}>
                <ImageRenderer
                    type={UploaderTypes.UPLOAD_DRAG_AND_DROP}
                    setFileState={(file: ImageFile) => changeValue('userImage', file)}
                    disabled={userProp.userImage?.disabled}
                    cropperShape="round"
                    cropperTitle="Edit Image. Ensure the face comes within the circular area"
                />
            </Form.Item>,
        }],
    };

    // useEffects
    React.useEffect(() => {
        form.setFieldsValue({
            firstName: userProp.firstName?.value,
            lastName: userProp.lastName?.value,
            mobileNumber: userProp.mobileNumber?.value,
            userImage: userProp.userImage?.value,
        });
    }, [userProp]);

    return (
        <>
            <DetailsCard
                removePadding
                labelStyleClass='xs:w-full lg:w-1/3'
                valueStyleClass='xs:w-full lg:w-2/3'
                data={
                    userDetails?.data?.filter((datum) => datum?.show !== false).map((cardDatum) => {
                        return {
                            label: <label className='text-meraai-grey font-semibold'>{cardDatum.label}</label>,

                            value: cardDatum.value
                            ,
                        };
                    })
                }
            />
        </>
    );
}

export default UserLayout;
