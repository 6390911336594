import { Badge, Image } from 'antd';
import * as React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

interface CloseableImageProps {
    width?: string | number;
    height?: string | number;
    styleClass?: string;
    src: string | undefined;
    onClose: () => void;
    closeable?: boolean;
}

function CloseableImage(props: CloseableImageProps) {
    return props.src ? (
        <div className={props.styleClass ?? ''}>
            {
                props.closeable === false ?
                    <Image height={props.height} width={props.width} src={props.src} />

                    :

                    <Badge
                        className='cursor-pointer'
                        count={<AiFillCloseCircle size={20} color="red" onClick={props.onClose} />}
                    >
                        <Image height={props.height} width={props.width} src={props.src} />
                    </Badge>


            }

        </div>
    ) : (
        <></>
    );
}

export default CloseableImage;
