import { EstablishmentSaveMode } from '../../../types/EstablishmentSaveMode';
import { PurchaseOrderState } from '../../../types/PurchaseOrderState';
import { orderStateChangeHandlers } from '../../orders/layer/utils/orderStateChangeActionables/utils';
import { purchaseOrderStateChangeHandlers } from '../../purchaseOrder/layer/utils/purchaseOrderStateChangeActionables/utils';
import { Path } from '../../router/Path';
import { PathConfig } from '../PathConfigService';
import {
    AnalyticsConfig,
    EstablishmentDetailsConfig,
    EstablishmentsConfig,
    FinanceLayerConfig,
    InventoryConfig,
    OrderDetailsConfig,
    OrderListConfig,
    PurchaseOrderDetailsConfig,
    UserListingConfig,
    UserProfileConfig,
} from '../types/ConfigTypes';

const AdminConfigs: PathConfig[] = [
    {
        path: Path.HOME,
        accessible: true,
    },
    {
        path: Path.LOGOUT,
        accessible: true,
    },
    {
        path: Path.APPLICANTS,
        accessible: true,
    },
    {
        path: Path.APPLICANTS_DETAIL,
        accessible: true,
    },
    // Orders
    {
        path: Path.ORDER_LIST,
        accessible: true,
        config: {
            hubOrders: {
                show: true,
                showOrders: true,
                showPurchaseOrders: true,
            },
            outletOrders: {
                show: true,
            },
            sellerHubOrders: {
                show: true,
                showOrders: true,
                showPurchaseOrders: true,
            },
        } as OrderListConfig,
    },
    {
        path: Path.HUB_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.OUTLET_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.SELLER_HUB_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.ORDER_DETAILS,
        accessible: true,
        config: {
            stateChangeConfig: {
                allowedStates: orderStateChangeHandlers.map(handler => handler.state),
            },
            allowToViewSellerOrders: true,
            allowEditOrderDetails: true,
        } as OrderDetailsConfig,
    },
    {
        path: Path.ORDER_EDIT,
        accessible: true,
    },
    // Purchase Orders
    {
        path: Path.PURCHASE_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.HUB_PURCHASE_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.SELLER_HUB_PURCHASE_ORDER_LIST,
        accessible: true,
    },
    {
        path: Path.PURCHASE_ORDER_DETAILS,
        accessible: true,
        config: {
            show: true,
            stateChangeConfig: {
                allowedStates: purchaseOrderStateChangeHandlers.map(handler => PurchaseOrderState.valueOf(handler.state)),
            },
            paymentConfig: {
                allowMakePayment: true,
            },
        } as PurchaseOrderDetailsConfig,
    },

    // Add Establishment 
    {
        path: Path.ESTABLISHMENTS,
        accessible: true,
        config: {
            shouldShowHubs: true,
            shouldShowOutlets: true,
            shouldShowSellerHubs: true,
            permitEstablishmentCreation: true,
        } as EstablishmentsConfig,

    },
    {
        path: Path.HUB_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: true,
        } as EstablishmentsConfig,
    },
    {
        path: Path.OUTLET_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: true,
        } as EstablishmentsConfig,
    },
    {
        path: Path.SELLER_HUB_ESTABLISHMENT_LIST,
        accessible: true,
        config: {
            permitEstablishmentCreation: true,
        } as EstablishmentsConfig,
    },
    // Create Establishment 
    {
        path: Path.CREATE_ESTABLISHMENTS,
        accessible: true,
    },
    {
        path: Path.ESTABLISHMENT_DETAILS,
        accessible: true,
        config: {
            shouldShowInfo: true,
            brands: {
                allowListing: true,
                allowCreation: true,
                allowUpdation: true,
            },
            products: {
                sellerHub: {
                    show: true,
                    allowProductCreation: true,
                    allowProductListing: true,
                },
                hub: {
                    show: true,
                    allowProductListing: true,
                },
            },
            users: {
                show: true,
                allowCreation: true,
            },
            showReceivables: true,
            infoConfig: {
                showConfigLayout: true,
                showBrandsDiscountList: true,
                allowBrandDiscountsUpdate: true,
                saveMode: EstablishmentSaveMode.UPDATE,
            },
            ordersConfig: {
                listOrders: {
                    show: true,
                    getOrdersOnLaunch: true,
                },
                allowOrderCreation: true,
            },
            allowPurchaseOrderCreation: true,
            allowStatusChange: true,
            returns: {
                outlet: {
                    showList: true,
                    placeReturn: true,
                },
            },
        } as EstablishmentDetailsConfig,
    },

    // Finance
    {
        path: Path.FINANCE,
        accessible: true,
        config: {
            collections: {
                show: true,
                restrictDateSelection: false,
            },
            receivables: {
                show: true,
            },
            deposits: {
                show: true,
            },
            exportOrders: true,
            showPurchaseOrders: true,
        } as FinanceLayerConfig,
    },
    {
        path: Path.INVENTORY,
        accessible: true,
        config: {
            show: true,
            products: {
                showListing: true,
                allowUpdate: true,
            },
        } as InventoryConfig,
    },
    {
        path: Path.CATEGORY,
        accessible: true,
    },
    {
        path: Path.RETURNS,
        accessible: true,
    },
    {
        path: Path.RETURN_DETAILS,
        accessible: true,
    },
    {
        path: Path.ANALYTICS,
        accessible: true,
        config: {
            showKPIs: true,
            showMoM: true,
        } as AnalyticsConfig,
    },
    {
        path: Path.USER_LISTING,
        accessible: true,
        config: {
            primaryEstablishmentType: null,
            associatedEstablishmentTypes: null,
        } as UserListingConfig,
    },
    {
        path: Path.USER_PROFILE,
        accessible: true,
        config: {
            editProfile: true,
            mapEstablishments: true,
        } as UserProfileConfig,
    },

];

export default AdminConfigs;
