import MobileDetect from 'mobile-detect';

let mobileDetect = new MobileDetect(window.navigator.userAgent);

export const isMobile = (): boolean => {
    if (mobileDetect.os() === 'iOS') {
        return true;
    }
    if (mobileDetect.os() === 'AndroidOS') {
        return true;
    }
    if (mobileDetect.os() === 'BlackBerryOS') {
        return true;
    }
    return false;
};