import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './components/authentication/login/slice';
import authReducer from './components/authentication/slice';
import userReducer from './user/UserSlice';
import cartReducer from './components/cart/CartSlice';
import purchaseOrderCartReducer from './components/establishments/layer/details/purchaseOrder/PurchaseOrderCartSlice';
import firebaseTokenReducer from './components/notifications/firebaseTokenSlice';
import sideMenuCollapsedReducer from './components/common/slice/SideMenuSlice';
import categoryReducer from './components/category/slice';
import returnOrderReducer from './components/establishments/layer/details/returns/slice';

export const store = configureStore({
    reducer: {
        login: loginReducer,
        auth: authReducer,
        user: userReducer,
        cart: cartReducer,
        purchaseOrderCart: purchaseOrderCartReducer,
        firebaseToken: firebaseTokenReducer,
        sideMenuCollapsed: sideMenuCollapsedReducer,
        category: categoryReducer,
        returnOrder: returnOrderReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
