import { Button, Card, message, Space, Spin, Typography } from 'antd';
import download from 'downloadjs';
import { GoAlert } from 'react-icons/go';
import React, { ReactNode, useEffect, useState } from 'react';
import { DOWNLOAD_FINANCE_ORDER_REPORT } from '../../../http/EndPoints';
import { getApiCall, ResponseHandler } from '../../../http/HttpClient';
import { EstablishmentType } from '../../../types/EstablishmentType';
import { differenceInDays, getISTFromEpoch } from '../../../utilities/Date';
import { EstablishmentsDropDown, EstablishmentSelectionMode } from '../../common/establishments/EstablishmentsDropDown';
import TimeFilterPicker, { DateFilterParam, TimeFilterMode } from '../../common/TimeFilterPicker';


interface OrderExporterProps {
    active: boolean;
}


type ExporterParams = {
    title: ReactNode,
    subTitle: ReactNode,
    filePrefix: string,
};


const filterOptions = [
    {
        label: 'Invoice date',
        value: 'INVOICE',
        additionalInfo: {
            title: 'Exported CSV file has invoices as well as credit notes',
            subTitle: 'Primary purpose: Taxation',
            filePrefix: 'meraai-invoices-credit-notes',
        } as ExporterParams,
    },
    {
        label: 'Order date',
        value: 'ORDER',
        additionalInfo: {
            title: 'Exported CSV file has orders in their current state. Includes all types of rejections.',
            subTitle: 'Primary purpose: Revenue',
            filePrefix: 'meraai-orders',
        } as ExporterParams,
    },
] as DateFilterParam[];

export const OrderExporter = (props: OrderExporterProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedEstablishmentUuids, setSelectedEstablishmentUuids] = useState<string[]>([]);
    const [fromDateEpoch, setFromDateEpoch] = useState<number>();
    const [toDateEpoch, setToDateEpoch] = useState<number>();
    const [warningAlertOnDateFilterSize, setWarningAlertOnDateFilterSize] = useState<boolean>(false);

    const [selectedFilterParam, setSelectedFilterParam] = useState<DateFilterParam>(filterOptions[0]);


    const downloadCSV = async () => {
        setLoading(true);
        const responseHandler: ResponseHandler<any> = {
            onResponseSuccess(data: any): void {
                if (data && data.size > 0) {
                    download(new Blob([data]), `${selectedFilterParam.additionalInfo.filePrefix}-${getISTFromEpoch(fromDateEpoch!)}-${getISTFromEpoch(toDateEpoch!)}.csv`, 'application/csv');
                } else {
                    message.warning('No data found for the selected parameters');
                }
                setLoading(false);
            },
            onResponseFailed(): void {
                setLoading(false);
            },
            onResponseError(): void {
                setLoading(false);
            },
        };

        await getApiCall(DOWNLOAD_FINANCE_ORDER_REPORT(selectedEstablishmentUuids, fromDateEpoch!, toDateEpoch!, selectedFilterParam.value), responseHandler, {}, { responseType: 'blob' });
    };

    useEffect(() => {
        if (fromDateEpoch && toDateEpoch) {
            setWarningAlertOnDateFilterSize(differenceInDays(toDateEpoch, fromDateEpoch) > 60);
        }
    }, [fromDateEpoch, toDateEpoch]);

    const WarningAlertTitle = () => {
        return (
            <div className="flex-align-center">
                <GoAlert color='#BE2525' style={{ marginRight: 4 }} />
                <span>Exporting large amount of data</span>
            </div>
        );
    };

    return (
        <Spin spinning={loading}>
            <div className='flex-justify-center'>
                <Card
                    className='default-card'
                    size='small'
                    title={
                        <div style={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '14px', wordBreak: 'break-word', whiteSpace: 'normal' }}>{selectedFilterParam.additionalInfo.title}</span>
                            <span style={{ fontWeight: 'normal', fontSize: '10px' }} >{selectedFilterParam.additionalInfo.subTitle}</span>
                            <Typography.Text style={{ fontWeight: 'normal', fontSize: '12px' }} >Hub Selection is not mandatory</Typography.Text>
                        </div>
                    }
                >
                    <Space direction='vertical' size='middle' style={{ display: 'flex', justifyContent: 'center' }}>
                        <EstablishmentsDropDown
                            selectionMode={EstablishmentSelectionMode.MULTI_SELECT}
                            disableDefaultSelection={true}
                            type={EstablishmentType.HUB}
                            setLoading={setLoading}
                            setSelectedUuids={setSelectedEstablishmentUuids}
                            fetchEstablishmentList={props.active}
                        />

                        <TimeFilterPicker
                            fromDateEpoch={fromDateEpoch}
                            toDateEpoch={toDateEpoch}
                            setFromDateEpoch={setFromDateEpoch}
                            setToDateEpoch={setToDateEpoch}
                            timeFilterMode={TimeFilterMode.DATE}
                            dateFilterOptions={filterOptions}
                            dateFilterParam={selectedFilterParam}
                            setDateFilterParam={setSelectedFilterParam}
                            enableFilterPicker
                        />
                        <div className='flex-justify-center'>
                            <Button
                                className='default-button'
                                size='middle'
                                onClick={downloadCSV}
                                disabled={fromDateEpoch == undefined || toDateEpoch == undefined}
                            >
                                Export CSV
                            </Button>
                        </div>
                    </Space>
                </Card>
            </div>
            {warningAlertOnDateFilterSize &&
                <div className='flex-justify-center'>
                    <Card
                        className='default-card'
                        style={{ backgroundColor: '#FAEBD7' }}
                        size='small'
                        title={<WarningAlertTitle />}
                    >
                        You're about to export a large amount of data, that may take a while to export.
                        Consider picking a smaller date range.
                    </Card>
                </div>}
        </Spin>
    );
};