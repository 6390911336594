import { LOGOUT } from '../../../http/EndPoints';
import { putApiCall, ResponseHandler } from '../../../http/HttpClient';
import { ResponseMessage } from '../../../Types';
import { Path } from '../../router/Path';

export function clearDataAndCallLogoutApi(navigateTo: string) {
    const responseHandler: ResponseHandler<ResponseMessage> = {
        onResponseSuccess(): void {
            localStorage.clear();
            window.location.replace(navigateTo);
        },
        onResponseFailed(): void {
        },
        onResponseError(): void {
        },
    };
    putApiCall(LOGOUT, {}, responseHandler);
}

export function softLogout() {
    clearDataAndCallLogoutApi(`${Path.LOGOUT}?success=true`);
}


export function clearLocalStorageAndLogout() {
    localStorage.clear();
    window.location.replace(`${Path.LOGOUT}?forcedLogout=true`);
}