/* eslint @typescript-eslint/no-shadow: 0 */

import { Role } from '../../types/Role';
import { EstablishmentSaveMode } from '../../types/EstablishmentSaveMode';
import { EstablishmentType } from '../../types/EstablishmentType';
import { EstablishmentDetailsConfig } from '../config/types/ConfigTypes';
import { LayoutProperties } from './layer/details/information/types';
import { EstablishmentProperties } from './layer/layouts/establishment/utils';
import { UserProperties } from './layer/layouts/user/utils';
import { UserEstablishmentProperties } from './layer/layouts/userEstablishment/utils';
import { BankAccountProperties } from './layer/layouts/bankAccount/utils';
import { ConfigProperties } from './layer/layouts/config/utils';

interface InitialStates {
    establishmentProperties?: EstablishmentProperties;
    userProperties?: UserProperties;
    userEstablishmentProperties?: UserEstablishmentProperties;
    bankAccountProperties?: BankAccountProperties;
    configProperties?: ConfigProperties;
    layoutProperties?: LayoutProperties;
}

export const getInitialStates = (
    establishmentMode: EstablishmentSaveMode,
    currentUserRole: Role,
    establishmentDetailsConfig: EstablishmentDetailsConfig,
): Map<EstablishmentType, InitialStates> => {
    const initialStatesMap = new Map<EstablishmentType, InitialStates>();

    const shouldShowConfigLayoutForOutlets =
        establishmentDetailsConfig?.infoConfig?.showConfigLayout && establishmentMode !== EstablishmentSaveMode.CREATE;

    const shouldShowBrandDiscountListForOutlets =
        establishmentDetailsConfig?.infoConfig?.showBrandsDiscountList &&
        establishmentMode != EstablishmentSaveMode.CREATE;

    /* If the value of mode is SHOW, we are disabling all the fields.
     * This mode is coming from EstablishmentDetailsLayer
     * According to its user role we are setting up the mode.
     * So, If you are logged in as an ADMIN, you can create and update details
     * Rest all are only allowed to see their respective details
     */
    const disabled = establishmentMode === EstablishmentSaveMode.SHOW;

    initialStatesMap.set(EstablishmentType.OUTLET, {
        establishmentProperties: new EstablishmentProperties({
            establishmentName: { required: true, show: true, changed: false, disabled },
            establishmentImage: { required: true, show: true, changed: false, disabled },
            address: { required: true, show: true, changed: false, disabled },
            stateCode: { required: true, show: true, changed: false, disabled },
            pinCode: { required: true, show: true, changed: false, disabled },
            latitude: { required: true, show: true, changed: false, disabled },
            longitude: { required: true, show: true, changed: false, disabled },
            sourcingHubUuid: { required: false, show: true, changed: false, disabled },
        }),
        userProperties: new UserProperties({
            firstName: {
                required: true,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            lastName: {
                required: true,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            mobileNumber: {
                required: true,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            userImage: {
                required: false,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
        }),
        userEstablishmentProperties: new UserEstablishmentProperties({
            gstin: { required: false, show: true, changed: false, disabled },
            aadhaar: { required: false, show: true, changed: false, disabled },
            aadhaarFrontImage: { required: false, show: true, changed: false, disabled },
            aadhaarBackImage: { required: false, show: true, changed: false, disabled },
            pan: { required: false, show: true, changed: false, disabled },
            panImage: { required: false, show: true, changed: false, disabled },
        }),
        bankAccountProperties: new BankAccountProperties({
            accountNumber: { required: false, show: true, changed: false, disabled },
            accountHolderName: { required: false, show: true, changed: false, disabled },
            ifsc: { required: false, show: true, changed: false, disabled },
        }),
        configProperties: new ConfigProperties({
            mrpDiscountPercentage: { required: false, show: true, changed: false, disabled },
            creditLimit: { required: false, show: true, changed: false, disabled },
            creditCyclePeriod: { required: false, show: true, changed: false, disabled },
        }),
        layoutProperties: {
            showEstablishmentLayout: true,
            showUserDetailsLayout: true,
            showUserEstablishmentDetailsLayout: true,
            showBankDetailsLayout: true,
            showConfigDetailsLayout: shouldShowConfigLayoutForOutlets,
            showBrandDiscounts: shouldShowBrandDiscountListForOutlets,
        },
    });

    initialStatesMap.set(EstablishmentType.HUB, {
        establishmentProperties: new EstablishmentProperties({
            establishmentName: { required: true, show: true, changed: false, disabled },
            establishmentImage: { required: true, show: true, changed: false, disabled },
            address: { required: true, show: true, changed: false, disabled },
            stateCode: { required: true, show: true, changed: false, disabled },
            pinCode: { required: true, show: true, changed: false, disabled },
            latitude: { required: true, show: true, changed: false, disabled },
            longitude: { required: true, show: true, changed: false, disabled },
            sourcingHubUuid: { required: false, show: false },
        }),
        userProperties: new UserProperties({
            firstName: {
                required: true,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            lastName: {
                required: true,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            mobileNumber: {
                required: true,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            userImage: {
                required: false,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
        }),
        userEstablishmentProperties: new UserEstablishmentProperties({
            gstin: { required: false, show: false, changed: false, disabled },
            aadhaar: { required: false, show: false, changed: false, disabled },
            aadhaarFrontImage: { required: false, show: false, changed: false, disabled },
            aadhaarBackImage: { required: false, show: false, changed: false, disabled },
            pan: { required: false, show: false, changed: false, disabled },
            panImage: { required: false, show: false, changed: false, disabled },
        }),
        bankAccountProperties: new BankAccountProperties({
            accountNumber: { required: false, show: false, changed: false, disabled },
            accountHolderName: { required: false, show: false, changed: false, disabled },
            ifsc: { required: false, show: false, changed: false, disabled },
        }),
        configProperties: new ConfigProperties({
            mrpDiscountPercentage: { required: false, show: true, changed: false, disabled },
            creditLimit: { required: false, show: true, changed: false, disabled },
            creditCyclePeriod: { required: false, show: true, changed: false, disabled },
        }),
        layoutProperties: {
            showEstablishmentLayout: true,
            showUserDetailsLayout: true,
            showUserEstablishmentDetailsLayout: false,
            showBankDetailsLayout: false,
            showConfigDetailsLayout: false,
            showBrandDiscounts: false,
        },
    });

    initialStatesMap.set(EstablishmentType.SELLER_HUB, {
        establishmentProperties: new EstablishmentProperties({
            establishmentName: { required: true, show: true, changed: false, disabled },
            establishmentImage: { required: true, show: true, changed: false, disabled },
            address: { required: true, show: true, changed: false, disabled },
            stateCode: { required: true, show: true, changed: false, disabled, value: 'KA' },
            pinCode: { required: true, show: true, changed: false, disabled },
            latitude: { required: true, show: true, changed: false, disabled },
            longitude: { required: true, show: true, changed: false, disabled },
            sourcingHubUuid: { required: false, show: false },
        }),
        userProperties: new UserProperties({
            firstName: {
                required: false,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            lastName: {
                required: false,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            mobileNumber: {
                required: false,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
            userImage: {
                required: false,
                show: true,
                changed: false,
                disabled: disabled || establishmentMode === EstablishmentSaveMode.UPDATE,
            },
        }),
        userEstablishmentProperties: new UserEstablishmentProperties({
            gstin: { required: false, show: true, changed: false, disabled },
            aadhaar: { required: false, show: true, changed: false, disabled },
            aadhaarFrontImage: { required: false, show: true, changed: false, disabled },
            aadhaarBackImage: { required: false, show: true, changed: false, disabled },
            pan: { required: false, show: true, changed: false, disabled },
            panImage: { required: false, show: true, changed: false, disabled },
        }),
        bankAccountProperties: new BankAccountProperties({
            accountNumber: { required: false, show: false, changed: false, disabled },
            accountHolderName: { required: false, show: false, changed: false, disabled },
            ifsc: { required: false, show: false, changed: false, disabled },
        }),
        configProperties: new ConfigProperties({
            mrpDiscountPercentage: { required: false, show: true, changed: false, disabled },
        }),
        layoutProperties: {
            showEstablishmentLayout: true,
            showUserDetailsLayout: true,
            showUserEstablishmentDetailsLayout: true,
            showBankDetailsLayout: false,
            showConfigDetailsLayout: false,
            showBrandDiscounts: false,
        },
    });


    const updateDisabled = (
        field:
        | EstablishmentProperties
        | UserProperties
        | UserEstablishmentProperties
        | BankAccountProperties
        | ConfigProperties,
        establishmentDetailsConfig: EstablishmentDetailsConfig,
    ) => {
        Object.keys(field).forEach((key: any) => {
            if (!field[key].disabled) {
                field[key].disabled = !establishmentDetailsConfig.infoConfig?.selectiveUpdateAllowedFields.includes(key);
            }
        });
    };

    if (establishmentDetailsConfig.infoConfig?.selectiveUpdateAllowedFields) {
        initialStatesMap.forEach((initialStates: InitialStates) => {
            updateDisabled(initialStates.establishmentProperties!!, establishmentDetailsConfig);
            updateDisabled(initialStates.userEstablishmentProperties!!, establishmentDetailsConfig);
            updateDisabled(initialStates.userProperties!!, establishmentDetailsConfig);
            updateDisabled(initialStates.bankAccountProperties!!, establishmentDetailsConfig);
            updateDisabled(initialStates.configProperties!!, establishmentDetailsConfig);
        });
    }

    return initialStatesMap;
};

