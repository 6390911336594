import { ReturnOrderState, ReturnOrderStateProperty } from './types';

export const allReturnOrderStates: ReturnOrderStateProperty[] = [
    {
        state: ReturnOrderState.PLACED,
        display: 'Placed',
        color: 'gold',
    },
    {
        state: ReturnOrderState.ACCEPTED,
        display: 'Accepted',
        color: 'green',
    },
    {
        state: ReturnOrderState.REJECTED,
        display: 'Rejected',
        color: 'red',
    },
];

export const returnOrderStatesMap: Map<ReturnOrderState, ReturnOrderStateProperty> = allReturnOrderStates.reduce((map: Map<ReturnOrderState, ReturnOrderStateProperty>, orderState: ReturnOrderStateProperty) => {
    map.set(orderState.state, orderState);
    return map;
}, new Map<ReturnOrderState, ReturnOrderStateProperty>());


export const getPropertyFromState = (state: string): ReturnOrderStateProperty => {
    const returnOrderState = Object.values(ReturnOrderState).find(item => item == state);
    return returnOrderStatesMap.get(returnOrderState ?? ReturnOrderState.PLACED)!;
};

export const getReturnOrderStateOptions = () : { state: string, display: string }[] => {
    return allReturnOrderStates.map(item => 
        ({ state: item.state, display: item.display }),
    );
};