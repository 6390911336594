import React from 'react';
import { Form, Select } from 'antd';
import { UserProfileDetails } from './profile/types';
import { putApiCall, ResponseHandler } from '../../http/HttpClient';
import { UPDATE_USER_DETAILS } from '../../http/EndPoints';


export const countryCodeList = (
    <Form.Item name="country_code" initialValue={'+91'} noStyle>
        <Select className='w-max'>
            <Select.Option value="+91">+91</Select.Option>
        </Select>
    </Form.Item>
);

export const handleEmailAutoComplete = (emailValue: string) => {
    let finalEmail: string[] = [];
    if (!emailValue || emailValue.indexOf('@') >= 0) {
        finalEmail = [];
    } else {
        finalEmail = ['meraai.co', 'gmail.com', 'yahoo.com'].map(domain => `${emailValue}@${domain}`);
    }
    return finalEmail;
};

export interface UpdateUserProfilePictureOrStatusProps {
    username?: string;
    requestBody?: UserProfileDetails;
    responseHandler: ResponseHandler<UserProfileDetails>;
}

export const updateUserProfilePictureOrStatus = (props: UpdateUserProfilePictureOrStatusProps) => {

    putApiCall(UPDATE_USER_DETAILS(props.username), props.requestBody, props.responseHandler);

};