import { EstablishmentType } from '../../../types/EstablishmentType';
import { Role } from '../../../types/Role';
import { SearchInputProps } from '../../common/SearchInput';

export interface UserListingProps {
    active: boolean;
    chosenEstablishment?: {
        uuid: string | undefined;
        type: EstablishmentType | undefined;
    }
}

export interface User {
    username: string
    first_name: string
    last_name: string
    created_at: number
    mobile_number: number
    email: string
    active: boolean
}

export interface UserListResponse {
    users: User[],
    page_count: number,
    page_size: number,
    next_page: boolean
}

export interface FilterProps {

    chosenEstablishment?: {
        uuid?: string;
        type?: EstablishmentType;
    };

    accessibleRoles: Role[];
    setAccessibleRoles: React.Dispatch<React.SetStateAction<Role[]>>;


    setPage: React.Dispatch<React.SetStateAction<number>>;
    resetFilter: () => void;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRole: Role | undefined;
    setSelectedRole: React.Dispatch<React.SetStateAction<Role | undefined>>;
    setUserListRequested: React.Dispatch<React.SetStateAction<boolean>>;
    establishmentUuids: string[];
    setEstablishmentUuids: React.Dispatch<React.SetStateAction<string[]>>;
    prevEstablishmentUuids: string[];
    setPrevEstablishmentUuids: React.Dispatch<React.SetStateAction<string[]>>;
    nameSearch: SearchInputProps
    userListDetails: UserListResponse | undefined
}


export enum UserStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}
export interface UserStatusPropertyType {
    'ACTIVE': {
        status: boolean;
        color: string,
        display: UserStatus;
    },
    'INACTIVE': {
        status: boolean;
        color: string,
        display: UserStatus;
    }
}

export const userStatusProperties: UserStatusPropertyType = {
    'ACTIVE': {
        status: true,
        color: 'green',
        display: UserStatus.ACTIVE,
    },
    'INACTIVE': {
        status: false,
        color: 'red',
        display: UserStatus.INACTIVE,
    },
};