
import React from 'react';
import { Button, Col, Drawer, Image, InputNumber, Row } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { layout, style } from '../../../../../styles/css/style';
import { OrderItem } from '../../../../../types/Order';
import { addKeyToDataList } from '../../../../../Utils';
import { MeraaiTable } from '../../../../common/templates/meraaiTable';
import { Device, MeraaiTableColumnType } from '../../../../common/templates/meraaiTable/types';
import { Currency, ProductNameInfo, Quantity } from '../../details/hubsAndOutlets/utils';
import { OrderItemAvailabilityModalProps } from './types';


export const OrderItemAvailabilityDrawer = (props: OrderItemAvailabilityModalProps) => {

    const {
        orderItemAvailabilityDrawerVisibility,
        setOrderItemAvailabilityDrawerVisibility,
        orderDetails,
        setOrderDetails,
        shortagesPreviewDrawerVisibility,
        setShortagesPreviewDrawerVisibility,
        selectedStateHandler,
        setItemChangedCount,
        setItemShortage,
        resetSelectedStateHandler,
    } = props;

    const changeAvailableQuantityForAnItem = (item: OrderItem, availableQuantity: number) => {
        if (availableQuantity >= 0 && availableQuantity <= item.deliverable_quantity) {
            item.availableQuantity = availableQuantity;
        } else if (!availableQuantity) {
            item.availableQuantity = 0;
        }
        const itemChangedCount = orderDetails?.items.filter(productItem => productItem.availableQuantity !== productItem.actual_quantity)!.length!;
        setOrderDetails({ ...orderDetails! });

        if (itemChangedCount > 0) {
            setItemShortage(true);
        } else {
            setItemShortage(false);
        }
        setItemChangedCount(itemChangedCount);

    };

    const getOrderItems = () => {
        if (orderDetails?.partially_deliverable) {
            return addKeyToDataList(orderDetails?.items.filter((item) => item.deliverable && item.availableQuantitySource === 'INVENTORY')!);
        } else {
            return addKeyToDataList(orderDetails?.items.filter((item) => item.deliverable)!);
        }
    };

    const columns: MeraaiTableColumnType[] = [
        {
            title: 'Image',
            dataIndex: 'image',
            render: image => <Image width={30} height={30} src={image} />,
        },
        {
            title: 'Name',
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            width: 200,
            render: (__, record: OrderItem) => <ProductNameInfo name={record.name} brand={record.brand_name} />,
        },
        {
            title: 'Quantity',
            dataIndex: 'deliverable_quantity',
            rowVisibility: [Device.DESKTOP],
            render: deliverable_quantity => <Quantity quantity={deliverable_quantity} />,
        },
        {
            title: selectedStateHandler?.inputQuantityColName,
            rowVisibility: [Device.DESKTOP, Device.MOBILE],
            render: (__, record: OrderItem) => {
                return (
                    shortagesPreviewDrawerVisibility ? <Quantity quantity={record.availableQuantity} />
                        : <InputNumber
                            key={record.id}
                            controls={false}
                            value={record.availableQuantity}
                            min={0}
                            max={record.deliverable_quantity}
                            placeholder="Item count"
                            onBlur={event => {
                                changeAvailableQuantityForAnItem(record, parseInt(event.target.value));
                            }}
                        />
                );
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'deliverable_quantity',
            rowVisibility: [Device.DESKTOP],
            render: (__, record: OrderItem) => <Currency value={record.price_per_unit * record.availableQuantity} />,
        },
    ];

    return (
        <>
            <Drawer
                className='meraai-drawer'
                open={orderItemAvailabilityDrawerVisibility}
                title={
                    <>
                        <h3>Review Items</h3>
                        <span className='text-sm'>{selectedStateHandler?.itemAvailabilityModalSubscript || 'Review the items and adjust the quantity that was actually delivered.'}</span>
                    </>
                }
                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    setOrderItemAvailabilityDrawerVisibility(false);
                    resetSelectedStateHandler();
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}
                footer={
                    <>
                        <Row gutter={[24, 24]} className={`${layout.flex.end}`}>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiPlainButton}`}
                                    onClick={() => {
                                        setOrderItemAvailabilityDrawerVisibility(false);
                                        resetSelectedStateHandler();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='w-1/2 sm:w-1/3 lg:w-1/4'>
                                <Button
                                    className={`w-full ${style.meraaiOrangeButton}`}
                                    onClick={() => {
                                        setOrderItemAvailabilityDrawerVisibility(false);
                                        if (orderDetails?.items.some((item: OrderItem) => item.availableQuantity !== item.actual_quantity)) {
                                            setShortagesPreviewDrawerVisibility(true);
                                        }
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            >
                <>
                    <MeraaiTable
                        columns={columns}
                        dataSource={getOrderItems()}
                        pagination={false}
                        size='small'
                    />

                </>
            </Drawer>

            <Drawer
                className='meraai-drawer'
                open={shortagesPreviewDrawerVisibility}
                title={<h3>Shortages</h3>}

                extra={<AiOutlineClose className='cursor-pointer' onClick={() => {
                    setShortagesPreviewDrawerVisibility(false);
                    setOrderItemAvailabilityDrawerVisibility(true);
                }} />}
                closable={false}

                destroyOnClose
                maskClosable={false}
                keyboard={false}

                footer={
                    <div className={`w-full ${layout.flex.end}`}>
                        <Button
                            className={`w-full xs:w-1/2 md:w-1/3 lg:w-1/4 ${style.meraaiOrangeButton}`}
                            onClick={() => {
                                setShortagesPreviewDrawerVisibility(false);
                            }}>
                            Proceed
                        </Button>
                    </div>

                }
            >
                <>
                    <MeraaiTable
                        columns={columns}
                        dataSource={addKeyToDataList(orderDetails?.items.filter(product => product.availableQuantity !== product.actual_quantity)!)}
                        pagination={false}
                        size='small'
                    />
                </>

            </Drawer>

        </>
    );
};